// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-title {
  font-size: 20px;
  font-weight: 600;
  color: #989898;
  padding: 2px 0;
  margin-bottom: unset;
}

.point-form {
  top: 4px !important;
  left: -120px !important;
  position: relative !important;
}`, "",{"version":3,"sources":["webpack://./src/app/content/pages/contacts/client-management/client-fidelity/fidelity-history/fidelity-history.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,cAAA;EACA,oBAAA;AACF;;AAEA;EACE,mBAAA;EACA,uBAAA;EACA,6BAAA;AACF","sourcesContent":[".card-title {\r\n  font-size: 20px;\r\n  font-weight: 600;\r\n  color: #989898;\r\n  padding: 2px 0;\r\n  margin-bottom: unset;\r\n}\r\n\r\n.point-form {\r\n  top: 4px !important;\r\n  left: -120px !important;\r\n  position: relative !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

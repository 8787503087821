import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {ProfessionComponent} from './profession.component';
import {MatButtonModule} from '@angular/material/button';
import {MatTableModule} from '@angular/material/table';
import {TranslateModule} from '@ngx-translate/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {NgbPaginationModule, NgbTooltipModule, NgbTypeaheadModule} from '@ng-bootstrap/ng-bootstrap';
import {ReactiveFormsModule} from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ProfessionFormModalComponent} from './profession-form-modal.component';
import {MatLegacyDialogModule} from '@angular/material/legacy-dialog';
import {SharedModule} from '../../../../shared/shared.module';
import {MatIconModule} from "@angular/material/icon";

const routes: Routes = [
  {path: '', component: ProfessionComponent}
];

@NgModule({
  declarations: [ProfessionComponent, ProfessionFormModalComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatButtonModule,
    ReactiveFormsModule,
    MatTableModule,
    TranslateModule,
    MatPaginatorModule,
    NgbPaginationModule,
    MatProgressSpinnerModule,
    NgbTypeaheadModule,
    SharedModule.forChild(),
    MatLegacyDialogModule,
    NgbTooltipModule,
    MatIconModule
  ]
})
export class ProfessionModule {
}

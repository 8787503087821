import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {  MatSnackBar} from '@angular/material/snack-bar';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Product} from '../../../../../shared/models/product';
import {ProductMgmService} from '../../../../../shared/services/product-mgm.service';
import {SweetAlertService} from '../../../../../shared/services/sweet-alert.service';
import {TranslateService} from '@ngx-translate/core';
import {MovementType} from '../../../../../shared/enum/movement-type';

@Component({
  selector: 'app-movement-management-form',
  template: `
      <h1 mat-dialog-title *ngIf="!data.editMode">
          {{'MOVEMENT_FORM.ADD_PRODUCT' | translate}}
      </h1>
      <h1 mat-dialog-title *ngIf="data.editMode">
          {{'MOVEMENT_FORM.EDIT_PRODUCT' | translate}}
      </h1>
      <hr>
      <div mat-dialog-content>
          <form class="form" [formGroup]="productForm" (ngSubmit)="save()" id="productForm">
              <div class="row col-12">
                  <div class="form-group col-lg-5 col-md-5 col-sm-6">
                      <label>{{'MOVEMENT_FORM.PRODUCT' | translate}}</label>
                      <ng-select [items]="productsList" appendTo="body"
                                 bindValue="id" [typeahead]="productSearch$"
                                 [clearable]="false"
                                 bindLabel="description"
                                 placeholder="{{'BUTTONS.CHOOSE' | translate}}"
                                 class="ng-select-product ng-select-sm"
                                 [virtualScroll]="true"
                                 formControlName="productId"
                                 [ngClass]="{ 'is-invalid': submitted && productForm.get('productId').invalid }">
                      </ng-select>
                  </div>
                  <div class="form-group col-lg-2 col-md-2 col-sm-6">
                      <label>{{'MOVEMENT_FORM.IVA' | translate}}</label>
                      <div class="input-group input-group-sm">
                          <input type="number" placeholder="{{'MOVEMENT_FORM.IVA' | translate}}" readonly
                                 class="form-control form-control-sm" formControlName="iva">
                      </div>
                  </div>
                  <div class="form-group col-lg-2 col-md-2 col-sm-6">
                      <label>{{'MOVEMENT_FORM.QUANTITY' | translate}} </label>
                      <div class="input-group input-group-sm">
                          <input type="number" placeholder="{{'MOVEMENT_FORM.QUANTITY' | translate}}" min="1"
                                 class="form-control form-control-sm" formControlName="quantity">
                      </div>
                  </div>
                  <div class="form-group col-lg-3 col-md-3 col-sm-6">
                      <label>{{paymentType | translate}}</label>
                      <div class="input-group input-group-sm">
                          <input type="tel"
                                 placeholder="{{paymentType | translate}}"
                                 [options]="{prefix:''}" currencyMask class="form-control form-control-sm" formControlName="purchaseCost">
                          <div class="input-group-append">
                              <div class="input-group-text">
                                  €
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="form-group col-lg-2 col-md-2 col-sm-6" *ngIf="data?.type=='SUPPLIER_LOAD'">
                      <label>{{'MOVEMENT_FORM.GIFT' | translate}} </label>
                      <div class="input-group input-group-sm">
                          <input type="number" placeholder="{{'MOVEMENT_FORM.QUANTITY' | translate}}" min="1"
                                 class="form-control form-control-sm" formControlName="gift">
                      </div>
                  </div>
              </div>
          </form>
      </div>
      <hr>
      <div mat-dialog-actions class="d-flex justify-content-end">
          <div>
              <button mat-button class="cancel-button"
                      (click)="dialogRef.close(addedElement)"><mat-icon>close</mat-icon>{{'BUTTONS.CLOSE' | translate}}</button>
            <span class="button-space" ></span>
              <button mat-flat-button class="confirm-button" [disabled]="productForm.invalid"
                      form="addForm"
                      (click)="save()"><mat-icon>save</mat-icon>{{'BUTTONS.SAVE' | translate}}
              </button>
          </div>
      </div>
  `
})
export class MovementProductFormComponent implements OnInit {
  productForm: UntypedFormGroup;
  submitted = false;
  addedElement = false;
  addMultipleCheckbox = new UntypedFormControl(false);
  productsList: Product[] = [];
  productsPage = 1;
  unsubscribe$ = new Subject();
  paymentType = 'MOVEMENT_FORM.PURCHASE_COAST';
  productSearch$ = new Subject<string>();

  constructor(
    public dialogRef: MatDialogRef<MovementProductFormComponent>,
    private sweetAlertService: SweetAlertService,
    private translate: TranslateService,
    private snackBar: MatSnackBar, private productService: ProductMgmService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit(): void {
    this.loadProducts();
    this.productSearch$.pipe(debounceTime(500)).subscribe(term => {
      if (!term) {
        this.loadProducts();
        return;
      }
      if (term.length > 1) {
        this.loadProducts(term);
      }
    });
    this.paymentType = !(MovementType.CUSTOMER_SALES === this.data.type || MovementType.VARIOUS_DISCHARGE === this.data.type) ?
      'MOVEMENT_FORM.PURCHASE_COAST' : 'MOVEMENT_FORM.SALES_PRICE';
    this.productForm = new UntypedFormGroup({
      id: new UntypedFormControl(),
      productId: new UntypedFormControl(null, Validators.required),
      prodCode: new UntypedFormControl(),
      description: new UntypedFormControl(),
      iva: new UntypedFormControl(0),
      quantity: new UntypedFormControl(null, Validators.required),
      gift: new UntypedFormControl(0),
      purchaseCost: new UntypedFormControl(null, Validators.required),
      costPrice: new UntypedFormControl(null, Validators.required),
      price: new UntypedFormControl(null, Validators.required),
    });
    if (this.data.editMode) {
      this.productForm.patchValue(this.data.product);
      this.productForm.get('productId').disable();
    }
    this.productForm.get('productId').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if (c) {
        const product = this.productsList.filter(u => u.id === c).shift();
        this.productForm.get('description').setValue(product.description);
        this.productForm.get('quantity').setValue(1);
        this.productForm.get('prodCode').setValue(product.prodCode);
        this.productForm.get('iva').setValue(0);
        if (this.data.type === MovementType.CUSTOMER_SALES) {
          this.productForm.get('purchaseCost').setValue(product.price);
        } else {
          this.productForm.get('purchaseCost').setValue(product.purchasePrice);
        }
        this.productForm.get('costPrice').setValue(product.purchasePrice);
        this.productForm.get('price').setValue(product.price);
        if (product.ivaValue) {
          this.productForm.get('iva').setValue(product.ivaValue);
        }
      }
    });
  }

  loadProducts(term?) {
    this.productService.getLazyProductList({page: 1, pageSize: 10, textSearch: term, activeDetailSale: true}).subscribe(d => {
      this.productsList = [...d.data];
    });
  }

  save() {
    this.submitted = true;
    if (!this.productForm.valid) {
      this.sweetAlertService.notification(this.translate.instant('DIALOG.INSERT_ALL_MANDATORY_INFORMATION')).then(e => {
      });
      return;
    }
    if (this.productForm.valid) {
      this.dialogRef.close(this.productForm.getRawValue());
    }
  }

}

import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SmsCompaign} from 'src/app/shared/models/sms-compaign';
import {BreadcrumbService} from 'src/app/core/services/breadcrumb.service';
import {Client} from 'src/app/shared/models/client';
import {ClientMgmService} from 'src/app/shared/services/client-mgm.service';
import {SmsService} from 'src/app/shared/services/sms-service.service';
import moment from 'moment';
import {CampaignStatus} from 'src/app/shared/enum/campaignStatus';
import {SweetAlertService} from 'src/app/shared/services/sweet-alert.service';
import {TranslateService} from '@ngx-translate/core';
import {debounceTime} from 'rxjs/operators';
import {SmsCredits} from 'src/app/shared/models/sms-credits';
import {UserResponse} from 'src/app/shared/models/user-response';
import {DateTimeAdapter} from 'nxt-pick-datetime';
import {WhatsappServiceService} from "../../../../shared/services/whatsapp-service.service";
import {WhatsappMessage} from "../../../../shared/models/WhatsappMessage";
import {HttpClient} from "@angular/common/http";


@Component({
  selector: 'app-sms-compaign',
  templateUrl: './sms-compaign.component.html',
  styleUrls: ['./sms-compaign.component.scss']
})
export class SmsCompaignComponent implements OnInit {
  @ViewChild('iconDisplay') displayElement: ElementRef;
  @ViewChild('emoji') emoji: ElementRef;

  searchFormControl = new UntypedFormControl(null);
  messageSize: number = 153;
  messageSizeWhatsapp: number = 1024;
  message: string = '';
  compaign: SmsCompaign;
  compaignForm: UntypedFormGroup;
  clients: Client[] = [];
  loadClients = false;
  clientPage = 1;
  dialogRef: MatDialogRef<any, any>;
  public page = 1;
  public totalRecords: number;
  public pageSize = 10;
  loading = true;
  compaigns: SmsCompaign[];
  firstCall = true;
  disableSave = false;
  orderType = 'DESC';
  allClients = false;
  credits: UserResponse;
  resending = false;
  searchValue = null;
  showTestInputCampaign: boolean = false;
  prefixList: any[] = [];
  isWhatsAppEnabled: boolean;
  columns = [
    'SMS.SMS_TEMPLATE',
    'SMS.WhatsApp_TEMPLATE',
    'SMS.CREATION_DATE',
    'SMS.SCHEDULED_AT'
  ];
  showAdditionalTextAreaCampaign: boolean = false;

  constructor(private dialog: MatDialog,
              private breadcrumbService: BreadcrumbService,
              private fb: UntypedFormBuilder,
              private sweetAlertService: SweetAlertService,
              private translate: TranslateService,
              private clientService: ClientMgmService,
              private smsService: SmsService,
              private dateTimeAdapter: DateTimeAdapter<any>,
              private matSnackBar: MatSnackBar,
              private translateService: TranslateService,
              private whatsappService: WhatsappServiceService,
              private http: HttpClient) {

  }

  toggled: boolean = false;
  name = 'World';
  content = '';

  handleSelection(event) {
    console.log(event);
    console.log(event.char);
  }

  ngOnInit() {
    this.checkWhatsAppExistence();
    this.dateTimeAdapter.setLocale(this.translate.currentLang);
    this.translate.onLangChange.subscribe(lang => {
      this.dateTimeAdapter.setLocale(this.translate.currentLang);
    });
    this.sendBreadCrumb();
    this.smsService.getCredits().subscribe(r => {
      this.credits = r;
    });
    this.compaignForm = this.fb.group({
      id: new UntypedFormControl(),
      templateCampaign: new UntypedFormControl(''),
      sendCampaignAt: new UntypedFormControl(null, Validators.required),
      clientIds: [],
      status: new UntypedFormControl(),
      sendSms: new UntypedFormControl(true),
      sendWhatsApp: new UntypedFormControl(false),
      whatsAppTemplateCampaign: new UntypedFormControl(''),
      messageTemplateCampaign: new UntypedFormControl(''),
      templateNameCampaign: new UntypedFormControl(null),
      languageCodeCampaign: new UntypedFormControl({value: 'it', disabled: true}),
      phoneNumberCampaign: new UntypedFormControl('', Validators.pattern('^[0-9]{8}[0-9]*')),
      mobilePrefix: new UntypedFormControl('+39', [Validators.required]),
      baseurl: [''],
      token: [''],
      phoneNumberId: [''],
    });

    const otherFormValues = this.whatsappService.getOtherFormValues();
    console.log('otherFormValues:', otherFormValues);
    this.compaignForm.patchValue({
      baseurl: otherFormValues.baseurl,
      token: otherFormValues.token,
      phoneNumberId: otherFormValues.phoneNumberId,
    });

    this.getCompaigns({page: 1, pageSize: this.pageSize});
    this.searchFormControl.valueChanges.pipe(debounceTime(500)).subscribe(s => {
      this.searchValue = s;
      this.page = 1;
      this.getCompaigns({page: 1, pageSize: this.pageSize, textSearch: s});
    });

    this.http.get<any[]>('/assets/TEL_PREFIX.json').subscribe(d => this.prefixList = d.map(e => {
      e.label = `${e.country}: ${e.prefix}`;
      return e;
    }));

  }

  addNewCompaign(content) {
    this.allClients = false;
    this.getLazyClient({page: this.clientPage, pageSize: 10, textSearch: this.searchValue});
    // Réinitialiser les champs spécifiques avant d'ouvrir le dialogue
    this.compaignForm.get('languageCodeCampaign').setValue('it');
    this.compaignForm.get('templateCampaign').setValue(''.padEnd(0, ' '));
    this.compaignForm.get('whatsAppTemplateCampaign').setValue(''.padEnd(0, ' '));
    this.compaignForm.get('sendSms').setValue(true);
    this.compaignForm.get('mobilePrefix').setValue('+39');
    this.dialogRef = this.dialog.open(content, {
      autoFocus: false,
      maxHeight: '90vh',
      width: '75%'
    });
    this.dialogRef.afterClosed().subscribe(d => {
      this.compaignForm.reset();
      this.getCompaigns({page: 1, pageSize: this.pageSize});
    });
  }

  selectAllClients() {
    this.loadClients = true;
    this.clients = [];
    this.clientService.getClients().subscribe(r => {
      console.log(r);
      r.forEach(c => {
        c.firstName = c.firstName + ' ' + c.lastName;
      });
      this.clients = r;
      this.clientPage = 1;
      this.allClients = true;
      this.loadClients = false;
      this.compaignForm.get('clientIds').setValue(this.clients.map(({id}) => id));
    });
  }

  sendBreadCrumb(): void {
    this.breadcrumbService.sendBreadcrumb(['SMS_WhatsApp', 'COMPAIGNS']);
  }

  getLazyClient(request) {
    request.confirmed = true;
    request.noMarketingCheck = true;
    if (!this.allClients) {
      this.loadClients = true;
      this.clientService.getLazyClientList(request).subscribe(d => {
        d.data.forEach(c => {
          c.firstName = c.firstName + ' ' + c.lastName;
        });
        this.clients = [...this.clients, ...d.data];
        this.clientPage++;
        this.loadClients = false;
      });
    }
  }

  clear() {
    this.reset();
    this.getLazyClient({page: this.clientPage, pageSize: 10, textSearch: this.searchValue});
  }

  reset() {
    this.clients = [];
    this.allClients = false;
    this.clientPage = 1;
    this.searchValue = null;
  }

  open() {
    this.reset();
    this.getLazyClient({page: this.clientPage, pageSize: 10, textSearch: this.searchValue});
  }

  addDate() {
    this.compaignForm.get('templateCampaign').setValue(this.compaignForm.get('templateCampaign').value + ' {dd.MM.yyyy} ');
  }

  addTime() {
    this.compaignForm.get('templateCampaign').setValue(this.compaignForm.get('templateCampaign').value + ' {HH:mm} ');
  }

  addFirstName() {
    this.compaignForm.get('templateCampaign').setValue(this.compaignForm.get('templateCampaign').value + ' {First Name} ');
  }

  addLastName() {
    this.compaignForm.get('templateCampaign').setValue(this.compaignForm.get('templateCampaign').value + ' {Last Name} ');
  }

  addWhatsAppFirstName() {
    this.compaignForm.get('whatsAppTemplateCampaign').setValue(this.compaignForm.get('whatsAppTemplateCampaign').value + ' {First Name} ');
  }

  addWhatsAppLastName() {
    this.compaignForm.get('whatsAppTemplateCampaign').setValue(this.compaignForm.get('whatsAppTemplateCampaign').value + ' {Last Name} ');
  }


  schedule() {
    this.compaignForm.get('sendCampaignAt').setValidators(Validators.required);
    this.compaignForm.get('sendCampaignAt').updateValueAndValidity();
    if (!this.compaignForm.valid) {
      this.sweetAlertService.notification(this.translate.instant('DIALOG.INSERT_ALL_MANDATORY_INFORMATION')).then(e => {
      });
      return;
    }
    this.disableSave = true;
    this.compaignForm.get('sendCampaignAt').setValue(moment(this.compaignForm.get('sendCampaignAt').value).format('YYYY-MM-DDTHH:mm:ss'));
    console.log(this.compaignForm.get('sendCampaignAt').value);
    this.smsService.addNewCompaign(this.compaignForm.getRawValue()).subscribe(d => {
      this.disableSave = false;
      this.dialogRef.close();
      this.getCompaigns({page: 1, pageSize: this.pageSize});
    }, error => {
      this.disableSave = false;
    });
  }


  sendNow() {
    this.compaignForm.get('sendCampaignAt').clearValidators();
    this.compaignForm.get('sendCampaignAt').updateValueAndValidity();

    if (!this.compaignForm.valid) {
      this.sweetAlertService.notification(this.translate.instant('DIALOG.INSERT_ALL_MANDATORY_INFORMATION')).then(e => {
      });
      return;
    }
    console.log('send');
    this.disableSave = true;
    this.smsService.sendImmediateCampaignSMS(this.compaignForm.getRawValue()).subscribe(r => {
      this.matSnackBar.open(this.translateService.instant('SMS.SUCCESS'), 'Ok', {
        duration: 5000,
        panelClass: 'white-snackbar',
      });
      this.disableSave = false;
      this.dialogRef.close();
    }, e => {
      this.disableSave = false;
      this.matSnackBar.open(this.translateService.instant('SMS.FAILED'), 'Ok', {
        duration: 5000,
        panelClass: 'white-snackbar',
      });
      this.dialogRef.close();
    });
  }


  getCompaigns(request) {
    this.loading = true;
    return this.smsService.getCompaigns(request).subscribe(data => {
      this.compaigns = data.data;
      console.log(this.compaigns);
      this.firstCall = false;
      this.totalRecords = data.totalRecords;
    });
  }

  pageChange(page: number) {
    this.page = page;
    this.getCompaigns({page, pageSize: this.pageSize});
  }

  updateStatus(comp: SmsCompaign) {
    if (comp.status === CampaignStatus.SCHEDULED) {
      comp.status = CampaignStatus.CANCELED;
    } else if (comp.status === CampaignStatus.CANCELED) {
      comp.status = CampaignStatus.SCHEDULED;
    } else if (comp.statusWhatsApp === CampaignStatus.SCHEDULED) {
      comp.statusWhatsApp = CampaignStatus.CANCELED;
    } else if (comp.statusWhatsApp === CampaignStatus.CANCELED) {
      comp.statusWhatsApp = CampaignStatus.SCHEDULED;
    }

    this.smsService.updateCampaignStatus({
      id: comp.id,
      status: comp.status,
      whatsappStatus: comp.statusWhatsApp
    }).subscribe(r => {
      this.getCompaigns({page: 1, pageSize: this.pageSize});
    });
  }

  delete(id: number) {
    this.sweetAlertService.warning(this.translate.instant('SMS.DO_YOU_WANT_TO_DELETE_CAMPAIGN'))
      .then(res => {
        if (res.value) {
          this.smsService.deleteCompaign(id).subscribe(d => {
            if (d.status === 200) {
              this.getCompaigns({page: 1, pageSize: this.pageSize});
              //snackbar success
            }
          }, err => {
            if (err.status === 500) {
              /*  this.showSnackBar({
                  text: `${element.firstName} ${element.lastName}`,
                  actionIcon: 'failed',
                  actionMsg: this.translate.instant('DIALOG.CANNOT_DELETE')
                });*/
              //snackbar failed
            }
          });
        }
      });
  }

  edit(content, comp: SmsCompaign) {
    this.allClients = false;
    this.getLazyClient({page: this.clientPage, pageSize: 10, textSearch: this.searchValue});
    this.compaignForm.patchValue(comp);
    this.compaignForm.get('clientIds').setValue(comp.clients.map(({id}) => id));

    this.dialogRef = this.dialog.open(content, {
      autoFocus: false,
      maxHeight: '90vh'
    });
    this.dialogRef.afterClosed().subscribe(d => {
      this.compaignForm.reset();
    });
  }


  sortRows(c: string) {
    const sort = c.substr(4);
    console.log(sort);
    if (this.orderType === 'ASC') {
      this.orderType = 'DESC';
    } else {
      this.orderType = 'ASC';
    }
    switch (sort) {
      case 'CAMPAIGN_ID':
        this.getCompaigns({
          page: 1, pageSize: this.pageSize, sort: {
            attribute: 'id',
            direction: this.orderType
          }
        });
        break;
      case 'SMS_TEMPLATE':
        this.getCompaigns({
          page: 1, pageSize: this.pageSize, sort: {
            attribute: 'templateCampaign',
            direction: this.orderType
          }
        });
        break;
      case 'WhatsApp_TEMPLATE':
        this.getCompaigns({
          page: 1, pageSize: this.pageSize, sort: {
            attribute: 'templateCampaign',
            direction: this.orderType
          }
        });
        break;
      case 'CREATION_DATE':
        this.getCompaigns({
          page: 1, pageSize: this.pageSize, sort: {
            attribute: 'createdAt',
            direction: this.orderType
          }
        });
        break;
      case 'SCHEDULED_AT':
        this.getCompaigns({
          page: 1, pageSize: this.pageSize, sort: {
            attribute: 'sendCampaignAt',
            direction: this.orderType
          }
        });
        break;
      case 'STATUS':
        this.getCompaigns({
          page: 1, pageSize: this.pageSize, sort: {
            attribute: 'status',
            direction: this.orderType
          }
        });
        break;
    }
  }


  getReceiversNumber(comp: SmsCompaign) {
    return comp.smsCampaignClients.filter(e => e.resent === false).length;
  }


  searchClients(event) {
    this.reset();
    this.searchValue = event.term;
    this.getLazyClient({page: this.clientPage, pageSize: 10, textSearch: this.searchValue});
  }

  addEmoji(evt) {
    const content = [
      this.compaignForm.get('whatsAppTemplateCampaign').value,
      evt.emoji.native].join('');
    this.compaignForm.get('whatsAppTemplateCampaign').setValue(content);
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const clickedElement = event.target as HTMLElement;
    if (clickedElement && (clickedElement.tagName === 'TEXTAREA' || clickedElement.tagName === 'DIV')) {
      this.toggled = false;
    }
  }

  modifyTemplateCampaign() {
    this.showAdditionalTextAreaCampaign = true;
    let originalTemplate = this.compaignForm.get('whatsAppTemplateCampaign').value;
    this.whatsappService.modifyTemplateBirthday(originalTemplate).subscribe(
      (response) => {
        console.log('Modification du modèle réussie :', response);
        this.compaignForm.get('messageTemplateCampaign').setValue(response)

        //this.updateTextarea(response);
      },
      (error) => {
        console.error('Erreur lors de la modification du modèle :', error);
      }
    );
  }

  sendMessageCampaign() {
    const mobilePrefix = this.compaignForm.get('mobilePrefix').value;
    const phoneNumber = this.compaignForm.get('phoneNumberCampaign').value;
    const recipient = `${mobilePrefix}${phoneNumber}`;
    const templateName = this.compaignForm.get('templateNameCampaign').value;
    const languageCode = this.compaignForm.get('languageCodeCampaign').value;
    const extractedParameters = this.extractParametersFromTemplate(this.compaignForm.get('whatsAppTemplateCampaign').value);
    //const baseurl =this.compaignForm.get('baseurl').value;
    const baseurl = 'https://graph.facebook.com/v18.0';
    const phoneNumberId = this.compaignForm.get('phoneNumberId').value;
    const token = this.compaignForm.get('token').value;

    const whatsappConfiguration = {
      baseurl: baseurl,
      phoneNumberId: phoneNumberId,
      token: token,
    };

    const whatsappMessage: WhatsappMessage = {
      recipient: recipient,
      templateName: templateName,
      languageCode: languageCode,
      textParameters: extractedParameters,
      // @ts-ignore
      configuration: whatsappConfiguration,

    };

    this.whatsappService.sendWhatsAppMessage(whatsappMessage).subscribe(
      (response) => {
        if (response.errorCode == null) {
          this.sweetAlertService.success(this.translateService.instant('SMS.MESSAGE_TEST')).then(e => {
          });
        } else {
          this.sweetAlertService.danger(this.translateService.instant('SMS.MESSAGE_TEST_FAILED') + ' : ' + '{ Codice di errore : ' + response.errorCode + ' ' + ', Messaggio di errore : ' + response.errorMsg + '}').then(e => {
          });
        }
      },
      (error) => {

      }
    );
  }

  extractParametersFromTemplate(template: string): string[] {
    const parameters: string[] = [];

    if (template.includes('{First Name}')) {
      parameters.push('{First Name}');
    }
    if (template.includes('{Last Name}')) {
      parameters.push('{Last Name}');
    }

    return parameters;
  }


  checkWhatsAppExistence() {
    this.whatsappService.getConfiguration().subscribe(r => {
      if (r && r.token != null) {
        this.isWhatsAppEnabled = true;
        this.compaignForm.get('sendWhatsApp').enable();
      } else {
        this.isWhatsAppEnabled = false;
        this.compaignForm.get('sendWhatsApp').disable();
      }


    });

  }


  confirmAndCloseDialog(): void {
    if (this.compaignForm.dirty) {
      this.sweetAlertService.showConfirmationAlert()
        .then((confirmation) => {
          if (confirmation) {
            this.dialogRef.close();

          }
        });
    } else {
      this.dialogRef.close();
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.compaignForm.dirty) {
      $event.returnValue = true;
    }
  }
}

import {Component, HostListener, OnInit} from '@angular/core';
import {TranslationLoaderService} from '../../../../core/services/translation-loader.service';
import * as Swal from 'sweetalert2';
import {ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, NavigationStart, Router, RouterState} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../../../shared/services/user.service';
import {ProfileShowFormDialogComponent} from '../../../../content/layout/components/header/profile-show-form-dialog/profile-show-form-dialog.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {AuthService} from '../../../../shared/services/auth-jwt.service';
import {DateAdapter} from '@angular/material/core';
import {fromEvent, Observable} from "rxjs";
import {SweetAlertService} from "../../../../shared/services/sweet-alert.service";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  user: any;
  navData: NavigationMenu[];
  homeUrl = '/';
  showGroupName;
  isOwner: boolean;
  collapsed = true;
  dropdownPosition: string;
  isOnline: boolean = navigator.onLine;
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  constructor(
    public translationLoaderService: TranslationLoaderService,
    public translate: TranslateService,
    public sweetAlertService: SweetAlertService,
    public userService: UserService,
    private authService: AuthService,
    private router: Router,
    private matDialog: MatDialog,
    private dateAdapter: DateAdapter<any>,
  ) {
  }

  ngOnInit() {
    const x = window.matchMedia('(max-width: 700px)');
    this.setDropDownPosition(x); // Call listener function at run time
    this.user = this.userService.getUser();
    this.isOwner = this.userService.hasAnyAuthority('OWNER');
    if (this.router.url.startsWith('/admin/owner')) {
      this.navData = OWNER_MENU;
    } else if (this.router.url.startsWith('/admin')) {
      this.navData = SUPER_ADMIN_MENU;
      this.showGroupName = true;
    }
    this.userService.fetch().subscribe(u => {
      if (u.profile === 'OWNER') {
        this.homeUrl = '/admin/owner';
      }
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.startsWith('/admin/owner')) {
          this.showGroupName = false;
          this.navData = OWNER_MENU;
        } else if (event.url.startsWith('/admin')) {
          this.showGroupName = true;
          this.navData = SUPER_ADMIN_MENU;
        }
      }
    });

    // Créez des observables pour les événements en ligne/hors ligne
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    // Abonnez-vous aux observables pour mettre à jour isOnline
    this.onlineEvent.subscribe(() => {
      this.isOnline = true;
    });

    this.offlineEvent.subscribe(() => {
      this.isOnline = false;
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    const x = window.matchMedia('(max-width: 700px)');
    this.setDropDownPosition(x); // Call listener function at run time
  }

  setLanguage(lang): void {
    this.translationLoaderService.setLanguage(lang);
    this.dateAdapter.setLocale(lang);
  }

  setDropDownPosition(x) {
    if (x.matches) {
      this.dropdownPosition = 'bottom';
    } else {
      this.dropdownPosition = 'bottom-right';
    }
  }

  alertBeforeGoHome() {
    this.sweetAlertService.showConfirmationAlert().then(e => {
      if (e) {
        this.router.navigate([this.homeUrl], {replaceUrl: true});
      }
    });
  }

  logout() {
    this.authService.logout().subscribe(e => {
    });
  }

  onProfileClick() {
    this.matDialog.open(ProfileShowFormDialogComponent, {
      width: '500px',
      disableClose: true,
      data: this.user
    });
  }
}

const OWNER_MENU: NavigationMenu[] = [
  {
    url: 'owner/update-version/list',
    translation: 'HEADER.WHAT_NEW',
    icon: 'fas fa-bell'
  },
  {
    url: 'owner/mail-config',
    translation: 'HEADER.CONFIG',
    icon: 'fas fa-key'
  },
  {
    url: 'owner/vanity-clients',
    translation: 'HEADER.VANITY_CLIENT',
    icon: 'ft-user'
  },
  {
    url: 'owner/types-of-activities',
    translation: 'HEADER.TYPES_OF_ACTIVITIES',
    icon: 'ft-activity'
  },
  {
    url: 'owner/license-config',
    translation: 'HEADER.LICENSE_CONFIG',
    icon: 'fas fa-key'
  },
];
const SUPER_ADMIN_MENU: NavigationMenu[] = [,
  {
    url: 'configurations',
    translation: 'HEADER.CONFIGURATIONS',
    icon: 'ft-settings'
  },
  {
    url: 'roles',
    translation: 'HEADER.MANAGE_ROLES',
    icon: 'ft-folder'
  },
  {
    url: 'operators',
    translation: 'HEADER.MANAGE_OPERATORS',
    icon: 'ft-users'
  }
];


export class NavigationMenu {
  url: string;
  translation: string;
  icon: string;
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {RoleFormMgmComponent} from './role-form-mgm/role-form-mgm.component';
import {RoleService} from '../../../../../shared/services/role.service';
import {UntypedFormArray, UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {  MatSnackBar} from '@angular/material/snack-bar';
import * as Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';
import {OwnerService} from '../../../owner/services/owner.service';
import _ from 'lodash';
import {OPERATOR_MODULES} from './operator-modules';
import {SweetAlertService} from "../../../../../shared/services/sweet-alert.service";

@Component({
  selector: 'app-role-mgm',
  templateUrl: './role-mgm.component.html',
  styleUrls: ['./role-mgm.component.scss']
})
export class RoleMgmComponent implements OnInit, OnDestroy {
  /*modules = [
    {label: 'Dashboard', value: 'DASHBOARD'},
    {label: 'Planning', value: 'PLANNING'},
    {label: 'SIDENAV.CONTACTS', value: 'CONTACTS'},
    {label: 'SIDENAV.PROD_SERVICE', value: 'PRODUCT_AND_SERVICES'},
    {label: 'SIDENAV.MOVEMENT_AND_STOCK', value: 'MOVEMENT_AND_STOCK'},
    {label: 'Sms', value: 'SMS'},
    {label: 'SIDENAV.PROMOTION', value: 'PROMO'},
    {label: 'Report', value: 'REPORT'},
    {label: 'SIDENAV.CONFIGURATIONS', value: 'CONFIGURATION_TABLE'},
    {label: 'SIDENAV.PAYMENT', value: 'PAYMENT'},
    {label: 'SIDENAV.SALES', value: 'SALES'},
    {label: 'SIDENAV.ADMINISTRATION', value: 'ADMINISTRATION'},
    {label: 'SIDENAV.FIRST_NOTE', value: 'FIRST_NOTE'},
  ];*/
  modules =  _.cloneDeep(OPERATOR_MODULES);
  roles: any[] = [];
  authoritiesFormArray: UntypedFormArray;
  authorities;
  selectedRole;
  disableAuthoritySave = true;
  unsubscribe = new Subject();
  saveLoading = false;
  authoritiesLoading = false;
  rolesLoading = false;
  selectedRoleIndex;

  constructor(
    private matDialog: MatDialog,
    private roleService: RoleService,
    private matSnackBar: MatSnackBar,
    private translate: TranslateService,
    private ownerService: OwnerService,
    private sweetAlertService: SweetAlertService,
  ) {
  }

  ngOnInit() {
    this.rolesLoading = true;
    this.ownerService.getCurrentGroupLicenseModules().subscribe(u => {
      this.modules.forEach(t => {
        if (!t.children) {
          if (u && !u.some(v => t.value === v)) {
            t.disabled = true;
          }
        } else {
          t.children.forEach(w => {
            if(!w.children){
              if (u && !u.some(x => w.value === x)) {
                w.disabled = true;
              }
            } else {
              w.children.forEach(x => {
                if (u && !u.some(z => x.value === z)) {
                  x.disabled = true;
                }
              });
              if (w.children.filter(c => c.disabled).length === w.children.length) {
                w.disabled = true;
              }
            }
          });
          if (t.children.filter(c => c.disabled).length === t.children.length) {
            t.disabled = true;
          }
        }
      });
      // this.authorities = this.modules.filter(v => u.some(x => x === v.value));
      // this.authoritiesFormArray = this.createAuthoritiesForm(this.authorities);
      this.roleService.getAllRoles().subscribe(d => {
        this.roles = d;
        this.rolesLoading = false;
      });
      /*this.authoritiesFormArray.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(s => {
      });*/
    });
  }

  openAddRoleForm() {
    if (!this.disableAuthoritySave) {
      this.sweetAlertService.warning(this.translate.instant('ADMIN.ROLE.CHANGES_NOT_SAVED'))
        .then(u => {
          if (u.value) {
            this.openAddRoleDialog();
          }
        });
      return;
    }
    this.openAddRoleDialog();
  }

  private openAddRoleDialog() {
    const dialogRef = this.matDialog.open(RoleFormMgmComponent, {disableClose: true, width: '500px', data: {editMode: false}});
    dialogRef.afterClosed().subscribe(d => {
      if (d) {
        this.rolesLoading = true;
        // this.authoritiesFormArray = this.createAuthoritiesForm(this.authorities);
        this.roleService.getAllRoles().subscribe(roles => {
          this.roles = roles;
          this.loadRoleAuthorities(roles[0], 0, true);
          this.rolesLoading = false;
        });
      }
    });
  }

  openEditRoleForm(role: any, $event) {
    $event.cancelBubble = true;
    $event.stopPropagation();
    const dialogRef = this.matDialog.open(RoleFormMgmComponent, {disableClose: true, width: '500px', data: {editMode: true, role}});
    dialogRef.afterClosed().subscribe(d => {
      if (d) {
        this.rolesLoading = true;
        // this.authoritiesFormArray = this.createAuthoritiesForm(this.authorities);
        this.roleService.getAllRoles().subscribe(roles => {
          this.roles = roles;
          this.selectedRole = roles[this.selectedRoleIndex];
          this.rolesLoading = false;
        });
      }
    });
  }

  saveAuthorities() {
    this.saveLoading = true;
    let auth = [];
    _.flatten(this.modules.map(u => {
      if (!u.children) {
        if (u.selected) {
          auth = [...auth, u.value];
        }
      } else {
        u.children.forEach(c => {
          if (!c.children) {
            if (c.selected) {
              auth = [...auth, c.value];
            }
          } else {
            c.children.forEach(cc => {
              if (cc.selected) {
                auth = [...auth, cc.value];
              }
            });
          }
        });
      }
    }));
    this.selectedRole.authorities = auth;
     this.roleService.editRole(this.selectedRole).subscribe(d => {
      if (d.status === 200) {
        this.disableAuthoritySave = true;
        this.saveLoading = false;
        this.roles.splice(this.selectedRoleIndex, 1, d.body);
        this.translate.get('ADMIN.ROLE.ROLE_UPDATED').subscribe(s => {
          this.matSnackBar.open(s, 'Ok', {duration: 5000});
        });
      }
    });
  }

  loadRoleAuthorities(role, index, force = false) {
    if (!this.disableAuthoritySave && !force) {
      this.sweetAlertService.warning(this.translate.instant('ADMIN.ROLE.CHANGES_NOT_SAVED'))
        .then(u => {
          if (u.value) {
            if (!this.selectedRole || role.id !== this.selectedRole.id) {
              this.selectedRoleIndex = index;
              this.disableAuthoritySave = true;
              this.authoritiesLoading = true;
              this.roleService.getRoleById(role.id).subscribe(d => {
                this.selectedRole = d;
                this.modules.forEach( u => {
                  if (!u.children) {
                    if (d.authorities.some(v => u.value === v)) {
                      u.selected = true;
                    } else {
                      u.selected = false;
                    }
                  } else {
                    u.children.forEach(w => {
                      // if (d.authorities.some(x => w.value === x)) {
                      //   w.selected = true;
                      // } else {
                      //   w.selected = false;
                      // }

                      if (!w.children) {
                        if (d.authorities.some(x => w.value === x)) {
                          w.selected = true;
                        } else {
                          w.selected = false;
                        }
                      } else {
                        w.children.forEach(z => {
                          if (d.authorities.some(x => z.value === x)) {
                            z.selected = true;
                          } else {
                            z.selected = false;
                          }
                        });
                        if (w.children.filter(c => c.selected).length === w.children.length) {
                          w.selected = true;
                        } else {
                          w.selected = false;
                        }
                      }

                    });
                    if (u.children.filter(c => c.selected).length === u.children.length) {
                      u.selected = true;
                    } else {
                      u.selected = false;
                    }
                  }
                });
                this.authoritiesLoading = false;
              });
            }
          }
        });
      return;
    }
    if (!this.selectedRole || role.id !== this.selectedRole.id) {
      this.selectedRoleIndex = index;
      this.disableAuthoritySave = true;
      this.authoritiesLoading = true;
      this.roleService.getRoleById(role.id).subscribe(d => {
        this.selectedRole = d;
        this.modules.forEach(u => {
          if (!u.children) {
            if (d.authorities.some(v => u.value === v)) {
              u.selected = true;
            } else {
              u.selected = false;
            }
          } else {
            u.children.forEach(w => {

              if (!w.children) {
                if (d.authorities.some(x => w.value === x)) {
                  w.selected = true;
                } else {
                  w.selected = false;
                }
              } else {
                w.children.forEach(z => {
                  if (d.authorities.some(x => z.value === x)) {
                    z.selected = true;
                  } else {
                   z.selected = false;
                  }
               });
                if (w.children.filter(c => c.selected).length === w.children.length) {
                  w.selected = true;
                } else {
                 w.selected = false;
                }
               }
             });
            if (u.children.filter(c => c.selected).length === u.children.length) {
              u.selected = true;
            } else {
              u.selected = false;
            }
          }
        });
        this.authoritiesLoading = false;
      });
    }
  }

  private getSelectedAuthorities(authorities) {
    return authorities
      .map((el, i) => {
        if (el) {
          return this.authorities[i];
        }
      })
      .filter(e => e)
      .map(e => e.value);
  }

  private setupAuthoritiesFormArray(arr) {
    if (arr.length > 0) { // if role has authorities
      // reset authorities to all not selected
      this.authorities = this.authorities.map(e => {
        e.selected = false;
        return e;
      });
      // set selected authorities based on loaded role
      arr.forEach(e => {
        const i = this.authorities.findIndex(auth => auth.value === e);
        if (i > -1) {
          this.authorities[i].selected = true;
        }
      });
      this.authoritiesFormArray.setValue(this.authorities.map(u => u.selected || false));
    } else { // if role has no authorities
      this.authoritiesFormArray.setValue(this.authorities.map(u => false));
    }
  }

  private createAuthoritiesForm(authorities) {
    return new UntypedFormArray(authorities.map(au => new UntypedFormControl(au.selected || false)));
  }

  stopPropagation($event) {
    $event.cancelBubble = false;
    $event.stopPropagation();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.unsubscribe();
  }


  deleteRole(role: any, i: number, $event: MouseEvent) {
    $event.cancelBubble = true;
    $event.stopPropagation();
    this.translate.get('ADMIN.ROLE.DELETE_ROLE', {roleName: role.name}).subscribe(title => {
      this.translate.get('ADMIN.ROLE.DELETE_ROLE_MSG', {roleName: role.name}).subscribe(msg => {
        this.translate.get('ADMIN.ROLE.DELETE_ROLE_MSG', {roleName: role.name}).subscribe(err => {
          this.fireAlert(title, msg, err, role, i);
        });
      });
    });
  }
  private fireAlert(title: string | any, msg: string | any, errMsg: string | any, role, i) {
    this.sweetAlertService.warning(msg).then(e => {
      if (e) {
        this.roleService.deleteRole(role.id).subscribe(d => {
            this.roles.splice(i, 1);
            this.selectedRole = null;
            this.disableAuthoritySave = true;
          }
          , error => {
              this.sweetAlertService.error(errMsg);
          });
      }
    });
  }

  descendantsAllSelected(m: any) {
    if (!m.children) {
      return false;
    }
    return m.children.filter(u => u.selected).length === m.children.length;
  }

  descendantsPartiallySelected(m: any) {
    if (!m.children) {
      return false;
    }
    return m.children.some(u => u.selected) && !this.descendantsAllSelected(m);
  }

  selectedAllDescendants(m: any) {
    if (!m.children) {
      return;
    }
    if (m.selected) {
      m.children.forEach(u => {
        if (!u.disabled) {
          if (u.children) {
            u.children.forEach(c => {
              c.selected = true;
            });
          }
          u.selected = true;
        }
      });
    } else {
      m.children.forEach(u => {
        if (!u.disabled) {
          if (u.children) {
            u.children.forEach(c => {
              c.selected = false;
            });
          }
          u.selected = false;
        }
      });
    }
  }
}

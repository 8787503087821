// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  height: 100%;
  font-size: 12px;
}

.my-container {
  height: 100%;
  position: relative;
  background: linear-gradient(to bottom, #323232 0%, #3F3F3F 40%, #1C1C1C 150%), linear-gradient(to top, rgba(255, 255, 255, 0.4) 0%, rgba(0, 0, 0, 0.25) 200%);
  background-blend-mode: multiply;
}

mat-card {
  width: 330px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

mat-card-content {
  height: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.languages {
  padding: 0.5rem 1rem;
}
.languages a {
  margin-right: 10px;
}
.languages a.disabled {
  color: #828282;
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/app/forget-password/forget-password.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,YAAA;EACA,eAAA;AACF;;AACA;EACE,YAAA;EACA,kBAAA;EACA,6JAAA;EACA,+BAAA;AAEF;;AAAA;EACE,YAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;AAGF;;AADA;EACE,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;AAIF;;AAFA;EACE,oBAAA;AAKF;AAJE;EACE,kBAAA;AAMJ;AAHE;EACE,cAAA;EACA,oBAAA;AAKJ","sourcesContent":[":host {\r\n  display: block;\r\n  height: 100%;\r\n  font-size: 12px;\r\n}\r\n.my-container {\r\n  height: 100%;\r\n  position: relative;\r\n  background: linear-gradient(to bottom, #323232 0%, #3F3F3F 40%, #1C1C1C 150%), linear-gradient(to top, rgba(255, 255, 255, 0.40) 0%, rgba(0, 0, 0, 0.25) 200%);\r\n  background-blend-mode: multiply;\r\n}\r\nmat-card {\r\n  width: 330px;\r\n  position: absolute;\r\n  top: 50%;\r\n  left: 50%;\r\n  transform: translate(-50%, -50%);\r\n}\r\nmat-card-content {\r\n  height: 210px;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  flex-direction: column;\r\n}\r\n.languages {\r\n  padding: .5rem 1rem;\r\n  a {\r\n    margin-right: 10px;\r\n  }\r\n\r\n  a.disabled {\r\n    color: #828282;\r\n    pointer-events: none;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

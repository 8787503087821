import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {PromoModel} from "../models/promo-model";

@Injectable({
  providedIn: 'root'
})
export class PromoModelService {
  private API = environment.api + '/promo-models';

  constructor(private http: HttpClient) { }

  update(request) {
    return this.http.post<number>(this.API, request)
  }
  getPromoModelsLight() {
    return this.http.get<{totalRecords: number, data: PromoModel[]}>(this.API)
  }
  getPromoModelById(id) {
    return this.http.get<PromoModel>(this.API + '/' + id)
  }
  deleteById(id) {
    return this.http.delete<void>(this.API + '/' + id)
  }
}

import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

import moment from 'moment';
import {Client} from '../models/client';
import * as Inputmask from 'inputmask';
import {DaterangepickerDirective} from 'ngx-daterangepicker-material';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-compare-filter-date',
  templateUrl: './compare-filter-date.component.html',
  styleUrls: ['./compare-filter-date.component.scss']
})
export class CompareFilterDateComponent implements OnInit {
  @ViewChild(DaterangepickerDirective) pickerDirective: DaterangepickerDirective;
  @ViewChild('dateFromElm') dateFromElm: ElementRef;
  @Output() selectDate = new EventEmitter<{ startDate: Date, endDate: Date }>();
  @Output() selectRange = new EventEmitter<{ label: string, dates: any[] }>();
  @Input() disabled = false;
  selected: { startDate: Date, endDate: Date };
  alwaysShowCalendars: boolean;
  ranges: any = {};
  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];
  range: string;

  // isInvalidDate = (m: moment.Moment) => {
  //   return this.invalidDates.some(d => d.isSame(m, 'day'));
  // };

  constructor(private translateService: TranslateService) {
    console.log(moment.version);
    this.alwaysShowCalendars = true;
  }

  ngOnInit(): void {
    this.ranges[this.translateService.instant('COMP_FILTER.TODAY')] = [moment(), moment()];
    this.ranges[this.translateService.instant('COMP_FILTER.YESTERDAY')] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
    this.ranges[this.translateService.instant('COMP_FILTER.LAST_7_DAYS')] = [moment().subtract(6, 'days'), moment()];
    this.ranges[this.translateService.instant('COMP_FILTER.LAST_30_DAYS')] = [moment().subtract(29, 'days'), moment()];
    this.ranges[this.translateService.instant('COMP_FILTER.THIS_MONTH')] = [moment().startOf('month'), moment().endOf('month')];
    this.ranges[this.translateService.instant('COMP_FILTER.LAST_MONTH')] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];
    this.ranges[this.translateService.instant('COMP_FILTER.LAST_3_MONTHS')] = [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];
    this.ranges[this.translateService.instant('COMP_FILTER.LAST_6_MONTHS')] = [moment().subtract(6, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];
    this.ranges[this.translateService.instant('COMP_FILTER.THIS_YEAR')] = [moment().startOf('year'), moment().endOf('year')];
    this.ranges[this.translateService.instant('COMP_FILTER.LAST_YEAR')] = [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')];
    this.setDateMask();
    // this.pickerDirective.value([moment().subtract(6, 'days'), moment()]);

  }

  setDateMask() {
    Inputmask('datetime', {
      inputFormat: 'dd/mm/yyyy - dd/mm/yyyy',
      placeholder: 'gg/mm/aaaa - gg/mm/aaaa'
    }).mask(this.dateFromElm.nativeElement);
  }

  dateChange() {
    console.log(this.selected);
    this.selectDate.emit(this.selected);
  }

  updateDateElm(value: string) {
    console.log(value);
    const s1 = value.substring(0, 10);
    console.log(s1);
    const date = new Date(s1);
    const endDate = new Date(value.substring(11));
    console.log(date);
    console.log(endDate);
    // if (endDate !== 'Invalid Date') {
    //   console.log('-*-*-*-**-*')
    // }
  }

  openDatepicker() {
    this.pickerDirective.open();
  }

  clearDatepicker() {
    this.pickerDirective.clear();
  }

  rangeClicked(event) {
    console.log(event);
    this.range = event.label;
    console.log(this.range);
    this.selectRange.emit(event);
  }

  translateRange(previous: boolean) {
    if (!this.selected || !this.selected.startDate || !this.selected.endDate) {
      return;
    }
    const d1 = new Date(this.selected.endDate);
    d1.setHours(23, 59, 0);
    const d2 = new Date(this.selected.startDate);
    d2.setHours(23, 59, 0);
    const dif = d1.getTime() - d2.getTime();
    const days = dif / (1000 * 60 * 60 * 24);
    let startDate;
    let endDate;
    if (previous) {
      startDate = moment(this.selected.startDate).subtract(days + 1, 'days');
      endDate = moment(this.selected.startDate).subtract(1, 'days');
    } else {
      startDate = moment(this.selected.endDate).add(1, 'days');
      endDate = moment(this.selected.endDate).add(days + 1, 'days');
    }
    // @ts-ignore
    this.selected = {startDate, endDate};
    this.selectDate.emit(this.selected);

  }
}

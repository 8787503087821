import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {MatLegacyButtonModule} from '@angular/material/legacy-button';
import { MatDividerModule } from '@angular/material/divider';
import {   MatPaginatorModule } from '@angular/material/paginator';
import {   MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {   MatTableModule } from '@angular/material/table';
import {TranslateModule} from '@ngx-translate/core';
import {NgbPaginationModule, NgbTooltipModule, NgbTypeaheadModule} from '@ng-bootstrap/ng-bootstrap';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../../../../shared/shared.module';
import {BrandComponent} from './brand.component';
import {BrandFormModalComponent} from './brand-form-modal.component';
import {MatIconModule} from "@angular/material/icon";


const routes: Routes = [
  {path: '', component: BrandComponent}
];
@NgModule({
    declarations: [BrandComponent, BrandFormModalComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatLegacyButtonModule,
        TranslateModule,
        MatTableModule,
        MatPaginatorModule,
        NgbPaginationModule,
        ReactiveFormsModule,
        SharedModule.forChild(),
        MatDividerModule,
        NgbTypeaheadModule,
        MatProgressSpinnerModule,
        NgbTooltipModule,
        MatIconModule
    ]
})
export class BrandModule {
}

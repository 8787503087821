import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {  MatPaginator} from '@angular/material/paginator';
import {BreadcrumbService} from 'src/app/core/services/breadcrumb.service';
import {ClientFormService} from './services/client-form.service';
import {  MatSnackBar} from '@angular/material/snack-bar';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {BackdropService} from '../../../../core/services/backdrop.service';
import {CustomSnackBarComponent} from '../../../../shared/custom-snack-bar/custom-snack-bar.component';
import {ClientMgmService} from '../../../../shared/services/client-mgm.service';
import {Client} from '../../../../shared/models/client';
import {UntypedFormControl} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {SweetAlertService} from '../../../../shared/services/sweet-alert.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-client-management',
  templateUrl: './client-management.component.html',
  styleUrls: ['./client-management.component.scss']
})
export class ClientManagementComponent implements OnInit {
  columns = [
    'DATA_TABLE.ID',
    'DATA_TABLE.FULL_NAME',
    'DATA_TABLE.GENDER',
    'DATA_TABLE.EMAIL',
    'DATA_TABLE.MOBILE'
  ];
  rows: Client[] = [];
  loading = true;
  firstCall = true;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('clientFormContainer', {read: ViewContainerRef ,static: false}) clientFormContainer: ViewContainerRef;
  searchFormControl = new UntypedFormControl(null);
  public page = 1;
  public totalRecords: number;
  public pageSize = 10;
  orderType = 'DESC';
  textSearch = null;
  disabledClientFilterFormControl = new UntypedFormControl(false);
  confirmedClientFilterFormControl = new UntypedFormControl(false);
  notificationModeFilterFormControl = new UntypedFormControl(null);
  notMode: {sms: number, whatsApp: number};
  haveWhatsAppConfig = false;
  // whatsAppNotificationClientFilterFormControl = new FormControl(false);

  constructor(private http: HttpClient, private backdropService: BackdropService,
              private breadcrumbService: BreadcrumbService,
              private clientService: ClientMgmService,
              private clientFormService: ClientFormService,
              private closeDialog: MatDialog,
              private snackBar: MatSnackBar,
              public dialog: MatDialog,
              private sweetAlertService: SweetAlertService,
              private translate: TranslateService) {
  }


  ngOnInit() {
    this.sendBreadCrumb();
    this.getLazyClient({page: this.page, pageSize: this.pageSize});
    this.searchFormControl.valueChanges.pipe(debounceTime(500)).subscribe(s => {
      this.textSearch = s;
      this.page = 1;
      this.getLazyClient({page: 1, pageSize: this.pageSize, textSearch: s});
    });
    this.disabledClientFilterFormControl.valueChanges.pipe(debounceTime(800)).subscribe(s => {
      this.page = 1;
      this.getLazyClient({page: 1, pageSize: this.pageSize, textSearch: this.searchFormControl.value});
    });
    this.confirmedClientFilterFormControl.valueChanges.pipe(debounceTime(800)).subscribe(s => {
      this.page = 1;
      this.getLazyClient({page: 1, pageSize: this.pageSize, textSearch: this.searchFormControl.value});
    });
    this.notificationModeFilterFormControl.valueChanges.pipe(debounceTime(800)).subscribe(s => {
      console.log(s)
      this.page = 1;
      this.getLazyClient({page: 1, pageSize: this.pageSize, textSearch: this.searchFormControl.value});
    });
  }

  onSelect($event: any) {

  }

  onActivate($event: any) {

  }

  // delete client
  deleteClient($event, element: Client, index) {
    $event.cancelBubble = true;
    $event.stopPropagation();
    this.sweetAlertService.warning(this.translate.instant('DIALOG.YOU_WANT_TO_DELETE') + ' ' +
      element.firstName + ' ' + element.lastName)
      .then(res => {
        if (res.value) {
          this.clientService.deleteClient(element.id).subscribe(d => {
            if (d.status === 200) {
              this.getLazyClient({page: this.page, pageSize: this.pageSize, textSearch: this.textSearch}, {
                text: `${element.firstName} ${element.lastName}`,
                actionIcon: 'check',
                actionMsg: this.translate.instant('DIALOG.UPDATE_SUCCESS')
              });
            }
          }, err => {
            if (err.status === 500) {
              this.showSnackBar({
                text: `${element.firstName} ${element.lastName}`,
                actionIcon: 'failed',
                actionMsg: this.translate.instant('DIALOG.CANNOT_DELETE')
              });
            }
          });
        }
      }, err => {
      });

  }

  // create new client
  addNewClient() {
    const formRef = this.clientFormService.openForm(this.clientFormContainer,{
      editMode: false
    });
     // formRef.instance.editMode = false;
    formRef.instance.onClose.subscribe( (d: any) => {
      if (d) {
        this.getLazyClient({page: this.page, pageSize: this.pageSize, textSearch: this.textSearch}, {
           text: `${d.firstName} ${d.lastName}`,
          actionIcon: 'save',
          actionMsg: this.translate.instant('DIALOG.ADD_SUCCESS')
        });
      }
    });
  }


  // edit client
  editClient(data, i) {
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';
    const formRef = this.clientFormService.openForm(this.clientFormContainer,
      {
        client: data,
        editMode: true
      });
    // formRef.instance.client = data;
    // formRef.instance.editMode = true;
    //
    formRef.instance.onClose.subscribe(d => {
      if (d) {
        this.getLazyClient({page: this.page, pageSize: this.pageSize, textSearch: this.textSearch}, {
          // @ts-ignore
          text: `${d.firstName} ${d.lastName}`,
          actionIcon: 'save',
          actionMsg: this.translate.instant('DIALOG.UPDATE_SUCCESS')
        });
      }
      // this.rows.data = [...this.rows.data, d];
    });
  }


  changeClientState($event: MouseEvent, element: any, i: any) {
    $event.cancelBubble = true;
    $event.stopPropagation();
    let msg = '';
    if (element.visible) {
      msg = this.translate.instant('DIALOG.YOU_WANT_TO_HIDE');
    } else {
      msg = this.translate.instant('DIALOG.YOU_WANT_TO_SHOW');
    }
    this.sweetAlertService.warning(msg + ' ' +
      element.firstName + ' ' + element.lastName)
      .then(res => {
        if (res.value) {
          this.clientService.changeClientVisibleState(element.id).subscribe(d => {
            if (d.status === 200) {
              element.visible = !element.visible;
              this.getLazyClient({page: this.page, pageSize: this.pageSize, textSearch: this.textSearch}, {
                text: `${element.firstName} ${element.lastName}`,
                actionIcon: 'check',
                actionMsg: this.translate.instant('DIALOG.UPDATE_SUCCESS')
              });
            }
          });
        }
      }, err => {
      });
  }


  sendBreadCrumb(): void {
    // send message to subscribers via observable subject
    this.breadcrumbService.sendBreadcrumb(['CONTACT', 'CLIENT']);
  }

  showSnackBar(data: any) {
    this.snackBar.openFromComponent(CustomSnackBarComponent, {
      data,
      duration: 5000,
      panelClass: 'white-snackbar'
    });
  }

  clearBreadCrumb(): void {
    this.breadcrumbService.clearBreadcrumb();
  }

  getLazyClient(request, snackBarConf?) {
    this.loading = true;
    request.visible = !this.disabledClientFilterFormControl.value;
    request.confirmed = !this.confirmedClientFilterFormControl.value;
    request.smsNotification = this.notificationModeFilterFormControl.value == 'SMS';
    request.whatsAppNotification = this.notificationModeFilterFormControl.value == 'WHATS_APP';
    return this.clientService.getLazyClientList(request).subscribe(data => {
      this.rows = data.data;
      this.loading = false;
      this.firstCall = false;
      this.totalRecords = data.totalRecords;
      if (snackBarConf) {
        this.showSnackBar(snackBarConf);
      }
    });
  }

  pageChange(page: number) {
    this.page = page;
    this.getLazyClient({page, pageSize: this.pageSize, textSearch: this.textSearch});
  }

  sortRows(c: string) {
    const sort = c.substr(11);
    if (this.orderType === 'ASC') {
      this.orderType = 'DESC';
    } else {
      this.orderType = 'ASC';
    }
    switch (sort) {
      case 'ID':
        this.getLazyClient({
          page: this.page, pageSize: this.pageSize, textSearch: this.textSearch, sort: {
            attribute: 'id',
            direction: this.orderType
          }
        });
        break;
      case 'FULL_NAME':
        this.getLazyClient({
          page: this.page, pageSize: this.pageSize, textSearch: this.textSearch, sort: {
            attribute: 'firstName',
            direction: this.orderType
          }
        });
        break;
      case 'GENDER':
        this.getLazyClient({
          page: this.page, pageSize: this.pageSize, textSearch: this.textSearch, sort: {
            attribute: 'gender',
            direction: this.orderType
          }
        });
        break;
      case 'EMAIL':
        this.getLazyClient({
          page: this.page, pageSize: this.pageSize, textSearch: this.textSearch, sort: {
            attribute: 'email',
            direction: this.orderType
          }
        });
        break;
      case 'MOBILE':
        this.getLazyClient({
          page: this.page, pageSize: this.pageSize, textSearch: this.textSearch, sort: {
            attribute: 'mobile',
            direction: this.orderType
          }
        });
        break;
    }
  }

  call($event, client: Client) {
    $event.cancelBubble = true;
    $event.stopPropagation();
    window.open(
      'https://web.whatsapp.com/send?phone=' + client.mobilePrefix + client.mobile,
      '_blank' // <- This is what makes it open in a new window.
    );
  }


}



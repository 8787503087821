import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TreatmentProductReportsComponent} from './treatment-product-reports.component';
import {ReportPerMonthComponent} from './report-per-month/report-per-month.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatLegacyButtonModule} from '@angular/material/legacy-button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule, Routes} from '@angular/router';
import {SharedModule} from '../../../../shared/shared.module';
import {ReactiveFormsModule} from '@angular/forms';
import {NgbDropdownModule, NgbModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {CurrencyMaskModule} from 'ng2-currency-mask';
import {NgChartsModule} from 'ng2-charts';
import {CompareFilterDateModule} from '../../../../shared/compare-filter-date/compare-filter-date.module';
import {GenericFilterModule} from '../../../../shared/generic-filter/generic-filter.module';

const routes: Routes = [
  {path: '', component: TreatmentProductReportsComponent}
];

@NgModule({
  declarations: [TreatmentProductReportsComponent, ReportPerMonthComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatLegacyButtonModule,
    MatIconModule,
    RouterModule.forChild(routes),
    SharedModule.forChild(),
    ReactiveFormsModule,
    NgbPaginationModule,
    MatDatepickerModule,
    NgbDropdownModule,
    CurrencyMaskModule,
    NgChartsModule,
    NgbModule,
    CompareFilterDateModule,
    GenericFilterModule,
  ]
})
export class TreatmentProductReportsModule {
}

import {Group} from './group';
import {Profession} from './profession';
import {PreferredContact} from './preferred-contact';
import {HowDidYouMeetUs} from './how-did-you-meet-us';
import {Gender} from './gender';
import {Company} from './company';
import {RegisterSource} from '../enum/register-source';

export class Client {
  id?: number;
  uuid?: string;
  firstName: string;
  lastName: string;
  dateOfBirth?: string;
  fiscalCode?: string;
  gender: Gender;
  mobile: string;
  mobilePrefix: string;
  work?: string;
  workPrefix?: string;
  home?: string;
  homePrefix?: string;
  email?: string;


  // begin -> address (to be checked after)
  address?: string;
  street?: string;
  zipCode?: {
    id: number;
    province: number;
    city: number;
    country: number;
  };
  zipCodeDescription: string;
  // end -> address


  active?: boolean;
  civilState?: boolean;
  married?: boolean;
  hasSons?: boolean;
  numberOfSons?: number;
  noMarketing?: boolean;
  privacy?: boolean;
  noAppointmentReminder?: boolean;
  note?: string;

  groupId?: number;
  group?: Group;

  company: Company;
  companyId: number;

  /*from configuration table*/
  professionId?: number;
  profession?: Profession;
  preferredContactId?: number;
  preferredContact?: PreferredContact;
  // paymentTypeId?: number;
  // paymentType?: PaymentType;
  howDidYouMeetUsId?: number;
  howDidYouMeetUs?: HowDidYouMeetUs;

  visible: boolean;
  createdAt: Date;
  updatedAt: Date;

  seq?: number;

  image?: string;
  confirmed: boolean;
  registerSource: RegisterSource;
  preferredOperator: string;
  professionIDescription: string;
  howDidMeetUsDescription: string;
  companyDescription: string;
  lottery: string;

// for vanity-client
  groups?: string[];
  blocked: boolean;

  mailConfirmed?: boolean;
  isVanityClient?: boolean;

  notificationMode: NotificationMode;
}

export enum NotificationMode {
  SMS = 'SMS',
  WHATS_APP = 'WHATS_APP',
  SMS_AND_WHATS_APP = 'SMS_AND_WHATS_APP'
}

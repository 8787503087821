import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {ReportStatics} from '../../../../../shared/models/report-statics';
import {TranslateService} from '@ngx-translate/core';
import {ThemeConfig} from '../../../../../config';

declare let Chart: any;

@Component({
  selector: 'app-report-per-month',
  templateUrl: './report-per-month.component.html',
  styleUrls: ['./report-per-month.component.scss']
})
export class ReportPerMonthComponent implements OnInit {

  colors: any;
  public lineChart;
  months = [
    this.translate.instant('PLANNING_FORM.JANUARY'),
    this.translate.instant('PLANNING_FORM.FEBRUARY'),
    this.translate.instant('PLANNING_FORM.MARCH'),
    this.translate.instant('PLANNING_FORM.APRIL'),
    this.translate.instant('PLANNING_FORM.MAY'),
    this.translate.instant('PLANNING_FORM.JUNE'),
    this.translate.instant('PLANNING_FORM.JULY'),
    this.translate.instant('PLANNING_FORM.AUGUST'),
    this.translate.instant('PLANNING_FORM.SEPTEMBER'),
    this.translate.instant('PLANNING_FORM.OCTOBER'),
    this.translate.instant('PLANNING_FORM.NOVEMBER'),
    this.translate.instant('PLANNING_FORM.DECEMBER')
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public data: { data: ReportStatics[], type: string },
              private translate: TranslateService,
              public dialogRef: MatDialogRef<ReportPerMonthComponent>) {
  }

  ngOnInit() {
    let data = [];
    this.data.data.forEach(p => {
      const d = {data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: p.description};
      p.monthlyReports.forEach(r => {
        d.data[(new Date(r.creatAt)).getUTCMonth()] += this.data.type === 'quantity' ? r.salesQuantity : r.salesValue;
      });
      data = [...data, d];
    });
    this.lineChart = {
      data,
      labels: this.months,
      legend: true,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            ticks: {
              beginAtZero: true,
            }
          }
        },
      },
    };

    const colors = ThemeConfig.colors;
    const color = Chart.helpers.color;
    this.lineChart = {
      type: 'line',
      data,
      labels: this.months,
      legend: true,
      colors: [
        {
          borderColor: colors.primary,
          backgroundColor: color(colors.primary).alpha(0.5).rgbString(),
          fill: false,
          borderWidth: 4,
          pointHitRadius: 30,
          pointBackgroundColor: '#fff',
          pointBorderColor: colors.primary,
          pointHoverBorderColor: '#fff',
          pointHoverBackgroundColor: colors.primary,
          pointRadius: 5,
          pointBorderWidth: 2,
          pointHoverRadius: 7,
        },
      ],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
            }
          }]
        },
      },
    };
  }
}

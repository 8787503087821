import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {MatLegacyButtonModule} from '@angular/material/legacy-button';
import { MatDividerModule } from '@angular/material/divider';
import {   MatFormFieldModule } from '@angular/material/form-field';
import {   MatPaginatorModule } from '@angular/material/paginator';
import {   MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {   MatTableModule } from '@angular/material/table';
import {  MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';

import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbPaginationModule, NgbTooltipModule, NgbTypeaheadModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../../../shared/shared.module';
import {ColorPickerModule} from 'ngx-color-picker';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {YearPlanningModule} from '../../../../shared/year-planning/year-planning.module';
import {WeekPlanningTemplateComponent} from './week-planning-template.component';
import {WeekPlanningFormComponent} from '../../../../shared/week-template-form/week-planning-form.component';
import {WeekPlanningTemplateFormModule} from '../../../../shared/week-template-form/week-planning-template-form.module';


const routes: Routes = [
  {path: '', component: WeekPlanningTemplateComponent}
];

@NgModule({
    declarations: [WeekPlanningTemplateComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatTabsModule,
        TranslateModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatLegacyButtonModule,
        NgbPaginationModule,
        NgbTypeaheadModule,
        MatTableModule,
        MatPaginatorModule,
        SharedModule.forChild(),
        MatDividerModule,
        MatProgressSpinnerModule,
        ColorPickerModule,
        NgxMaterialTimepickerModule,
        FormsModule,
        YearPlanningModule,
        WeekPlanningTemplateFormModule,
        NgbTooltipModule
    ]
})
export class WeekPlanningTemplateModule {
}


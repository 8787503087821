import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Company} from '../models/company';
import {LazyRequest} from '../dto/lazy-request';
import {SearchResponse} from '../dto/search-response';
import {HttpClient} from '@angular/common/http';


@Injectable()
export class CompanyMgmService {
  COMPANY_API = environment.api + '/companies';

  constructor(private http: HttpClient) {
  }

  addNewCompany(company: Company) {
  return this.http.post<Company>(`${this.COMPANY_API}`, company);
  }

  editCompany(company: Company) {
    return this.http.put<Company>(`${this.COMPANY_API}`, company);
  }

  getAllCompanies() {
    return this.http.get<Company[]>(`${this.COMPANY_API}`);
  }

  deleteCompany(companyId) {
    return this.http.delete(`${this.COMPANY_API}/${companyId}`, {observe: 'response'});
  }

  changeCompanyActiveState(companyId) {
    return this.http.put(`${this.COMPANY_API}/active-state/${companyId}`, {}, {observe: 'response'});
  }

  getLazyCompanyList(request: CompanyLazyRequest) {
    return this.http.post<SearchResponse<Company>>(`${this.COMPANY_API}/filter`, request);
  }

  changeCompanyVisibleState(companyId) {
    return this.http.put(`${this.COMPANY_API}/visible-state/${companyId}`, {}, {observe: 'response'});
  }

}


class CompanyLazyRequest extends LazyRequest {
  textSearch: string;
  visible?: boolean;
}

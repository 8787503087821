// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-container {
  min-height: 100vh;
  position: relative;
  overflow: auto;
  background: linear-gradient(to bottom, #323232 0%, #3F3F3F 40%, #1C1C1C 150%), linear-gradient(to top, rgba(255, 255, 255, 0.4) 0%, rgba(0, 0, 0, 0.25) 200%);
  background-blend-mode: multiply;
}

mat-card {
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

mat-card-content {
  max-height: 600px;
  overflow-y: auto;
}

button {
  font-size: 0.9rem !important;
}

.languages a {
  margin-right: 10px;
}
.languages a.disabled {
  color: #828282;
  pointer-events: none;
}

@media screen and (max-width: 600px) {
  mat-card-content {
    max-height: 500px;
    overflow-y: auto;
  }
  mat-vertical-stepper .mat-vertical-content-container {
    margin-left: 0 !important;
  }
  button {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/init-account/init-account.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,kBAAA;EACA,cAAA;EAGA,6JAAA;EACA,+BAAA;AADF;;AAIA;EACE,UAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;AADF;;AAGA;EACE,iBAAA;EACA,gBAAA;AAAF;;AAEA;EACE,4BAAA;AACF;;AAEE;EACE,kBAAA;AACJ;AAEE;EACE,cAAA;EACA,oBAAA;AAAJ;;AAIA;EACE;IACE,iBAAA;IACA,gBAAA;EADF;EAGA;IACE,yBAAA;EADF;EAGA;IACE,WAAA;EADF;AACF","sourcesContent":[".my-container {\r\n  min-height: 100vh;\r\n  position: relative;\r\n  overflow: auto;\r\n  //background-image: linear-gradient(to right, #f78ca0 0%, #f9748f 19%, #fd868c 60%, #fe9a8b 100%);\r\n  //background: #EFF4FF;\r\n  background: linear-gradient(to bottom, #323232 0%, #3F3F3F 40%, #1C1C1C 150%), linear-gradient(to top, rgba(255, 255, 255, 0.40) 0%, rgba(0, 0, 0, 0.25) 200%);\r\n  background-blend-mode: multiply;\r\n}\r\n\r\nmat-card {\r\n  width: 80%;\r\n  position: absolute;\r\n  top: 50%;\r\n  left: 50%;\r\n  transform: translate(-50%, -50%);\r\n}\r\nmat-card-content {\r\n  max-height: 600px;\r\n  overflow-y: auto;\r\n}\r\nbutton {\r\n  font-size: .9rem !important;\r\n}\r\n.languages {\r\n  a {\r\n    margin-right: 10px;\r\n  }\r\n\r\n  a.disabled {\r\n    color: #828282;\r\n    pointer-events: none;\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 600px){\r\n  mat-card-content {\r\n    max-height: 500px;\r\n    overflow-y: auto;\r\n  }\r\n  mat-vertical-stepper .mat-vertical-content-container {\r\n    margin-left: 0 !important;\r\n  }\r\n  button {\r\n    width: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

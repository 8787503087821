import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';


import {LayoutComponent} from './content/layout/layout.component';
import {LayoutModule} from './content/layout/layout.module';
import {AdminLayoutComponent} from './administrative-content/layout/admin-layout.component';
import {AdminLayoutModule} from './administrative-content/layout/admin-layout.module';
import {AuthGuard} from './shared/guard/auth-guard';
import {FidelityComponent} from './content/pages/configurations/fidelity/fidelity.component';
import {PaymentHistoryComponent} from './content/pages/payment/payment-history/payment-history.component';
import {CategoryProductComponent} from './content/pages/configurations/category-product/category-product.component';
import {LineaComponent} from './content/pages/configurations/linea/linea.component';
import {
  CategoryTreatmentComponent
} from './content/pages/configurations/category-treatment/category-treatment.component';
import {ClientManagementComponent} from './content/pages/contacts/client-management/client-management.component';
import {Error401Component} from './content/pages/error-401/error401.component';
import {StockManagementComponent} from './content/pages/stock-and-movement/stock-management/stock-management.component';
import {QuoteComponent} from './content/pages/payment/quote/quote.component';
import {VerifyKeysGuard} from './shared/guard/verify-keys.guard';
import {
  WhatsappConfigurationComponent
} from "./content/pages/Whatsapp/whatsapp-configuration/whatsapp-configuration.component";
import {PrivacyComponent} from "./privacy/privacy.component";
import {MarketingComponent} from "./marketing/marketing.component";
import {ConditionsComponent} from "./conditions/conditions.component";

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      // dashboard
      {
        canActivate: [AuthGuard],
        path: '',
        data: {
          module: 'DASHBOARD'
        },
        loadChildren: () => import('./content/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      // {
      //   canActivate: [AuthGuard],
      //   path: 'planning',
      //   data: {
      //     module: 'PLANNING'
      //   },
      //   loadChildren: () => import('./content/pages/planning/planning.module').then(m => m.PlanningModule)
      // },
      // contact management
      {
        canActivate: [AuthGuard],
        path: 'client-mgm',
        data: {
          module: 'CLIENT'
        },
        component: ClientManagementComponent
        // loadChildren: './content/pages/contacts/client-management/client-management.module#ClientManagementModule'
      },
      // {
      //   canActivate: [AuthGuard],
      //   path: 'company-mgm',
      //   data: {
      //     module: 'COMPANY'
      //   },
      //   loadChildren: () => import('./content/pages/contacts/company-management/company-management.module').then(m => m.CompanyManagementModule)
      // },
      {
        canActivate: [AuthGuard],
        path: 'provider-mgm',
        data: {
          module: 'PROVIDER'
        },
        loadChildren: () => import('./content/pages/contacts/provider-management/provider-management.module').then(m => m.ProviderManagementModule)
      },
      /*{
        path: 'operator-mgm',
        loadChildren: './content/pages/operator-management/operator-management.module#OperatorManagementModule'
      },*/
      {
        canActivate: [AuthGuard],
        path: 'product-mgm',
        data: {
          module: 'ARTICLE'
        },
        loadChildren: () => import('./content/pages/productsAndServices/product-management/product-management.module').then(m => m.ProductManagementModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'subscription-mgm',
        data: {
          module: 'SUBSCRIPTION'
        },
        loadChildren: () => import('./content/pages/productsAndServices/subscription-management/subscription-management.module').then(m => m.SubscriptionManagementModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'treatment-mgm',
        data: {
          module: 'TREATMENT'
        },
        loadChildren: () => import('./content/pages/productsAndServices/treatment-management/treatment-management.module').then(m => m.TreatmentManagementModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'vat-code-conf',
        data: {
          module: 'SETUP_SELL_POINT'
        },
        loadChildren: () => import('./content/pages/configurations/codice-iva/codice-iva.module').then(m => m.CodiceIVAModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'pref-contact-conf',
        data: {
          module: 'SETUP_CLIENTS'
        },
        loadChildren: () => import('./content/pages/configurations/preferred-contact/preferred-contact.module').then(m => m.PreferredContactModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'profession-conf',
        data: {
          module: 'SETUP_CLIENTS'
        },
        loadChildren: () => import('./content/pages/configurations/profession/profession.module').then(m => m.ProfessionModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'payment-type-conf',
        data: {
          module: 'SETUP_SELL_POINT'
        },
        loadChildren: () => import('./content/pages/configurations/payment-type/payment-type.module').then(m => m.PaymentTypeModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'how-did-you-meet-us',
        data: {
          module: 'SETUP_CLIENTS'
        },
        loadChildren: () => import('./content/pages/configurations/how-did-you-meet-us/how-did-you-meet-us.module').then(m => m.HowDidYouMeetUsModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'brand-conf',
        data: {
          module: 'PRODUCTS_AND_SERVICES'
        },
        loadChildren: () => import('./content/pages/configurations/brand/brand.module').then(m => m.BrandModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'linea-conf',
        data: {
          module: 'PRODUCTS_AND_SERVICES'
        },
        // loadChildren: './content/pages/configurations/linea/linea.module#LineaModule'
        component: LineaComponent
      },
      {
        canActivate: [AuthGuard],
        path: 'category-product-conf',
        data: {
          module: 'PRODUCTS_AND_SERVICES'
        },
        // loadChildren: './content/pages/configurations/category-product/category-product.module#CategoryProductModule'
        component: CategoryProductComponent
      },
      {
        canActivate: [AuthGuard],
        path: 'category-treatment-conf',
        data: {
          module: 'PRODUCTS_AND_SERVICES'
        },
        // loadChildren: './content/pages/configurations/category-treatment/category-treatment.module#CategoryTreatmentModule'
        component: CategoryTreatmentComponent
      },
      {
        canActivate: [AuthGuard],
        path: 'client',
        data: {
          module: 'SETUP_SELL_POINT'
        },
        loadChildren: () => import('./content/pages/configurations/client/client.module').then(m => m.ClientModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'cabin',
        data: {
          module: 'SETUP_SELL_POINT'
        },
        loadChildren: () => import('./content/pages/configurations/cabin/cabin.module').then(m => m.CabinModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'operators',
        data: {
          module: 'SETUP_SELL_POINT'
        },
        loadChildren: () => import('./content/pages/configurations/operators-planning/operators-planning.module').then(m => m.OperatorsPlanningModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'week-planning',
        data: {
          module: 'SETUP_SELL_POINT'
        },
        loadChildren: () => import('./content/pages/configurations/week-planning-template/week-planning-template.module').then(m => m.WeekPlanningTemplateModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'fidelity',
        data: {
          module: 'FIDELITY'
        },
        component: FidelityComponent
      },
      {
        canActivate: [AuthGuard],
        path: 'payment-history',
        data: {
          module: 'DOCUMENT_MANAGEMENT'
        },
        component: PaymentHistoryComponent
      },
      // {
      //   canActivate: [AuthGuard],
      //   path: 'payment',
      //   data: {
      //     module: 'PAYMENT'
      //   },
      //   loadChildren: () => import('./content/pages/payment/payment.module').then(m => m.PaymentModule)
      // },

      {
        canActivate: [AuthGuard],
        path: 'sms-config',
        data: {
          module: 'SETUP_SELL_POINT'
        },
        loadChildren: () => import('./content/pages/sms/sms-configuration/sms-configuration.module').then(m => m.SmsConfigurationModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'whatsapp-config',
        data: {
          module: 'WHATS_APP'
        },
        component: WhatsappConfigurationComponent,
      },
      {
        canActivate: [AuthGuard],
        path: 'mobile-app-config',
        data: {
          module: 'SETUP_SELL_POINT'
        },
        loadChildren: () => import('./content/pages/configurations/app-configurations/app-configurations.module').then(m => m.AppConfigurationsModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'sms-compaigns',
        data: {
          module: 'PROMO_SMS'
        },
        loadChildren: () => import('./content/pages/sms/sms-compaign/sms-compaign.module').then(m => m.SmsCompaignModule)
      },

      {
        canActivate: [AuthGuard],
        path: 'sms-history',
        data: {
          module: 'PROMO_SMS'
        },
        loadChildren: () => import('./content/pages/sms/sms-history/sms-history.module').then(m => m.SmsHistoryModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'rt-conf',
        data: {
          module: 'PRINTERS'
        },
        loadChildren: () => import('./content/pages/configurations/rt/rt.module').then(m => m.RtModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'rt-conf/close',
        data: {
          module: 'PRINTERS'
        },
        loadChildren: () => import('./content/pages/configurations/rt/rt.module').then(m => m.RtModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'courtesy',
        data: {
          module: 'PRINTERS'
        },
        loadChildren: () => import('./content/pages/configurations/courtesy-rt/courtesy-rt.module').then(m => m.CourtesyRtModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'courtesy/close',
        data: {
          module: 'PRINTERS'
        },
        loadChildren: () => import('./content/pages/configurations/courtesy-rt/courtesy-rt.module').then(m => m.CourtesyRtModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'satispay-conf',
        data: {
          module: 'SATISPAY'
        },
        loadChildren: () => import('./content/pages/configurations/satispay/satispay.module').then(m => m.SatispayModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'carico',
        data: {
          module: 'CARICO',
        },
        loadChildren: () => import('./content/pages/stock-and-movement/movement-management/movement-management.module').then(m => m.MovementManagementModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'scarico',
        data: {
          module: 'SCARICO'
        },
        loadChildren: () => import('./content/pages/stock-and-movement/movement-management/movement-management.module').then(m => m.MovementManagementModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'transfer',
        data: {
          module: 'TRANSFER'
        },
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./content/pages/stock-and-movement/transfer-movement-management/transfer-movement-management.module').then(m => m.TransferMovementManagementModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'inventory',
        data: {
          module: 'INVENTORY',
        },
        loadChildren: () => import('./content/pages/stock-and-movement/inventory-management/inventory-management.module').then(m => m.InventoryManagementModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'stock',
        data: {
          module: 'STOCK',
        },
        component: StockManagementComponent
      },
      {
        canActivate: [AuthGuard],
        path: 'product_report',
        data: {
          module: 'PRODUCT_REPORTS'
        },
        loadChildren: () => import('./content/pages/reports/treatment-product-reports/treatment-product-reports.module').then(m => m.TreatmentProductReportsModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'treatment_report',
        data: {
          module: 'TREATMENT_REPORTS'
        },
        loadChildren: () => import('./content/pages/reports/treatment-product-reports/treatment-product-reports.module').then(m => m.TreatmentProductReportsModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'fiscal_report',
        data: {
          module: 'CASH_DESK'
        },
        loadChildren: () => import('./content/pages/reports/fiscal-reports/fiscal-reports.module').then(m => m.FiscalReportsModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'operator_report',
        data: {
          module: 'OPERATOR_REPORTS'
        },
        loadChildren: () => import('./content/pages/reports/operator-reports/operator-reports.module').then(m => m.OperatorReportsModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'quote',
        data: {
          module: 'QUOTE'
        },
        component: QuoteComponent
      },
      {
        path: 'first-note-cause',
        canActivate: [AuthGuard],
        data: {
          module: 'FIRST_NOTE'
        },
        loadChildren: () => import('./content/pages/firstNote/first-note-cause/first-note-cause.module').then(m => m.FirstNoteCauseModule)
      },
      {
        path: 'first-note',
        canActivate: [AuthGuard],
        data: {
          module: 'FIRST_NOTE'
        },
        loadChildren: () => import('./content/pages/firstNote/first-note/first-note.module').then(m => m.FirstNoteModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'promotion',
        data: {
          module: 'PROMOTION'
        },
        loadChildren: () => import('./content/pages/promotion-management/promotion-management.module').then(m => m.PromotionManagementModule)
      },
      {
        path: '401',
        canActivate: [AuthGuard],
        component: Error401Component
      },
      {
        canActivate: [AuthGuard],
        path: 'promotion-template-editor',
        data: {
          module: 'PROMOTION'
        },
        loadChildren: () => import('./content/pages/promotion-template-editor/promotion-template-editor.module').then(m => m.PromotionTemplateEditorModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'social-posts',
        data: {
          module: 'PROMOTION'
        },
        loadChildren: () => import('./content/pages/social-posts/social-posts.module').then(m => m.SocialPostsModule)
      },
      {
        // canActivate: [AuthGuard],
        path: 'update-version',
        data: {
          module: 'PROMOTION'
        },
        loadChildren: () => import('./content/pages/update-version/update-version.module').then(m => m.UpdateVersionModule)
      },
    ]
  },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./administrative-content/pages/admin-module/admin.module').then(m => m.AdminModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'owner',
        loadChildren: () => import('./administrative-content/pages/owner/owner.module').then(m => m.OwnerModule),
        canActivate: [AuthGuard],
      }
    ]
  },
  {
    path: 'login',
    loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'init-account',
    loadChildren: () => import('./init-account/init-account.module').then(m => m.InitAccountModule),
    canActivate: [AuthGuard, VerifyKeysGuard]
  },
  {
    path: 'forget-password',
    loadChildren: () => import('./forget-password/forget-password.module').then(m => m.ForgetPasswordModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'change-password',
    loadChildren: () => import('./change-password/change-password.module').then(m => m.ChangePasswordModule),
    canActivate: [AuthGuard, VerifyKeysGuard]
  },
  {
    path: 'privacy',
    component: PrivacyComponent
  },
  {
    path: 'marketing',
    component: MarketingComponent
  },
  {
    path: 'conditions',
    component: ConditionsComponent
  },
  {
    path: '**',
    redirectTo: '',
  }
];


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {useHash: true}),
    LayoutModule,
    AdminLayoutModule
  ],
  declarations: [],
  exports: [],
})
export class AppRoutingModule {
}

import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {SearchResponse} from '../dto/search-response';
import {LazyRequest} from '../dto/lazy-request';
import {Product} from '../models/product';

@Injectable()
export class ProductMgmService {

  PRODUCT_API = environment.api + '/products';

  constructor(private http: HttpClient) {
  }

  getProductsByCategoryId(categoryId) {
    return this.http.get(`${this.PRODUCT_API}/get-all-products-by-categoryId/${categoryId}`);
  }

  getProduct(productId) {
    return this.http.get<Product>(`${this.PRODUCT_API}/${productId}`);
  }

  addNewProduct(product: Product) {
    return this.http.post<Product>(`${this.PRODUCT_API}`, product);
  }

  editProduct(product: Product) {
    return this.http.put<Product>(`${this.PRODUCT_API}`, product);
  }

  deleteProduct(productId) {
    return this.http.delete(`${this.PRODUCT_API}/${productId}`, {observe: 'response'});
  }

  getLazyProductList(request: ProductLazyRequest) {
    return this.http.post<SearchResponse<Product>>(`${this.PRODUCT_API}/filter`, request);
  }

  delete(id: number) {
    return this.http.delete(`${this.PRODUCT_API}/${id}`, {observe: 'response'});
  }

  getTicket() {
    return this.http.get(`${this.PRODUCT_API}/get-products-ticket`, {observe: 'response', responseType: 'blob'});
  }

  printTicket(request) {
    return this.http.post(`${this.PRODUCT_API}/print-products-ticket`, request, {observe: 'response', responseType: 'blob'});
  }

  printFacePlates(request) {
    return this.http.post(`${this.PRODUCT_API}/print-products-face-plates`, request, {observe: 'response', responseType: 'blob'});
  }


  findByBarCode(ean) {
    return this.http.get<Product>(`${this.PRODUCT_API}/find-by-barcode/${ean}`);
  }

  countAlertProduct() {
    return this.http.get<number>(`${this.PRODUCT_API}/count-alert-product`);
  }

  enableDisableProduct(clientId) {
    return this.http.put(`${this.PRODUCT_API}/enable-disable-sales/${clientId}`, {}, {observe: 'response'});
  }

  findProductIdByProdCodeAndCenterId(prodCode: String) {
    return this.http.post<Product>(`${this.PRODUCT_API}/find-product-id-by-prod-code-and-center-id`, prodCode);
  }

  searchByCode(code) {
    return this.http.get<Product[]>(`${this.PRODUCT_API}/search-by-code/${code}`);
  }

  searchSpAndByCode(code, sp) {
    return this.http.get<Product[]>(`${this.PRODUCT_API}/search-by-sp-code/${sp}/${code}`);
  }


  searchProduct(request: SearchForStockRequest) {
    return this.http.post<Product[]>(`${this.PRODUCT_API}/search-products`, request);
  }

  findByBarCodeAndSp(ean, spId) {
    return this.http.get<Product>(`${this.PRODUCT_API}/find-by-sp-barcode/${spId}/${ean}`);
  }

  findByBarCodeSpAndDate(request: SearchForStockRequest) {
    return this.http.post<Product>(`${this.PRODUCT_API}/find-by-sp-barcode-date`, request);
  }

  getProductBySp(productId, spId) {
    return this.http.get<Product>(`${this.PRODUCT_API}/by-sp/${spId}${productId}`);
  }


  getProductBySpAndDate(request: SearchForStockRequest) {
    return this.http.post<Product>(`${this.PRODUCT_API}/by-sp-date`, request);
  }

}


class ProductLazyRequest extends LazyRequest {
  checkActiveDetailSale?: boolean;
  checkToSellProduct?: boolean;
  alertStock?: boolean;
  activeDetailSale?: boolean;
  notActiveProduct?: boolean;
  providerId?: number;
  brandId?: number;
}


class SearchForStockRequest {
  sellPointId?: number;
  date?;
  code?: string;
  id?: number;
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.7;
}

.spinner i {
  font-size: 2em; /* Taille du spinner, ajustez selon vos besoins */
}`, "",{"version":3,"sources":["webpack://./src/app/content/pages/contacts/client-management/client-fidelity/client-fidelity.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,YAAA;AACF;;AAEA;EACE,cAAA,EAAA,iDAAA;AACF","sourcesContent":[".spinner {\r\n  position: absolute;\r\n  top: 90%;\r\n  left: 50%;\r\n  transform: translate(-50%, -50%);\r\n  opacity: 0.7;\r\n}\r\n\r\n.spinner i {\r\n  font-size: 2em; /* Taille du spinner, ajustez selon vos besoins */\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

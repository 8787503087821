import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ProductMgmService} from '../../../../../shared/services/product-mgm.service';
import {SequenceService} from '../../../../../shared/services/sequence.service';
import {ConfigurationsService} from '../../../../../shared/services/configurations.service';
import {TreatmentMgmService} from '../../../../../shared/services/treatment-mgm.service';
import {IvaCode} from '../../../../../shared/models/ivaCode';
import {ProviderMgmService} from '../../../../../shared/services/provider-mgm.service';
import {Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {Product} from '../../../../../shared/models/product';
import {CabinService} from '../../../../../shared/services/cabin.service';
import {SequenceType} from '../../../../../shared/enum/sequence-type.enum';
import {Treatment} from '../../../../../shared/models/treatment';
import {SweetAlertService} from '../../../../../shared/services/sweet-alert.service';
import {TranslateService} from '@ngx-translate/core';
import {TreatmentProduct} from '../../../../../shared/models/treatment-product';
import {MeasureUnityType} from '../../../../../shared/enum/measure-unity-type.enum';
import {CategoryFormModalComponent} from '../../../configurations/category-treatment/category-form-modal.component';
import {SubCategoryFormModalComponent} from '../../../configurations/category-treatment/sub-category-form-modal.component';
import {LineaFormModalComponent} from '../../../configurations/linea/linea-form-modal.component';
import {EanType} from "../../../../../shared/enum/EanType.enum";
import {ProdCodeMgmService} from "../../../../../shared/services/prod-code-mgm.service";
import {BarCodeMgmService} from "../../../../../shared/services/bar-code-mgm.service";
const MAX_NUMBER = 2147483646;

@Component({
  selector: 'app-treatment-management-form',
  templateUrl: './treatment-management-form.component.html',
  styleUrls: ['./treatment-management-form.component.scss']
})
export class TreatmentManagementFormComponent implements OnInit, OnDestroy {
  onClose = new Subject();
  appRef;
  dialogComponentRef;
  treatmentForm: UntypedFormGroup;
  relatedProductsForm: UntypedFormGroup;
  editMode;
  editClicked = false;
  treatment: Treatment;
  submitted = false;
  relatedProductsSubmitted = false;

  IvaCodeList: IvaCode[] = [];
  IvaCodePage = 1;

  providersList: any[] = [];
  providersPage = 1;

  lineaList: any[] = [];
  lineaPage = 1;

  categoriesList: any[] = [];
  categoriesPage = 1;

  subCategoriesList: any[] = [];
  subCategoriesPage = 1;

  cabinesList: any[] = [];
  cabinesPage = 1;

  productsList: Product[] = [];
  productsPage = 1;

  unsubscribe$ = new Subject();
  categoryFormControl = new UntypedFormControl();
  productFormControl = new UntypedFormControl();
  quantityFormControl = new UntypedFormControl();
  operatorTimeFormControl = new UntypedFormControl();
  averageOperatorCostFormControl = new UntypedFormControl();
  masterPriceFormControl = new UntypedFormControl();
  pdvPriceFormControl = new UntypedFormControl();
  beautyCenterCostFormControl = new UntypedFormControl();
  unitFormControl = new UntypedFormControl();
  barCodeFormControl = new UntypedFormControl();
  prodCodeFormControl = new UntypedFormControl();
  validBarcode = false;
  validProdcode = false;
  uniqueBarcode = false;
  uniqueProdcode = false;
  operatorCost = 0;


  categoryIdLoading = false;
  currentSeq = '';
  public totalRecords: number;
  public pageSize = 10;

  product: Product;
  selectedIndex = 0;

  relatedProducts: TreatmentProduct[] = [];
  dialogRef: any;
  preventValueChange = false;
  editRelatedProducts = false;

  disableCabine = new UntypedFormControl();
  disableOperator = new UntypedFormControl();

  columns = [
    '',
    'PRODUCT_FORM.BAR_CODE',
    'PRODUCT_FORM.DESCRIPTION',
    'PRODUCT_FORM.PRICE',
    ''
  ];

  selectProductsColumns = [
    'TREATMENT_FORM.DESCRIPTION',
    'TREATMENT_FORM.CATEGORY',
    'TREATMENT_FORM.SUBCATEGORY',
    'TREATMENT_FORM.PRICE_QUANTITY',
    'TREATMENT_FORM.MEASURE_UNIT',
    'TREATMENT_FORM.QUANTITY',
    'TREATMENT_FORM.PRICE',
    ''
  ];


  rows: any[] = [];
  loading = true;
  disableSave = false;

  constructor(private http: HttpClient,
              private dialog: MatDialog,
              private sequence: SequenceService,
              private configurationsService: ConfigurationsService,
              private treatmentService: TreatmentMgmService,
              private providerService: ProviderMgmService,
              private cabineService: CabinService,
              private matDialog: MatDialog,
              private productService: ProductMgmService,
              private sweetAlertService: SweetAlertService,
              private translate: TranslateService,
              private prodCodeService: ProdCodeMgmService,
              private barCodeService: BarCodeMgmService
  ) {
  }


  ngOnInit() {
    this.treatmentForm = new UntypedFormGroup({
      id: new UntypedFormControl(),
      seq: new UntypedFormControl(),
      createdAt: new UntypedFormControl(new Date()),
      description: new UntypedFormControl(null, Validators.required),
      prodCode: new UntypedFormControl(null, Validators.required), //ALSO KNOWN AS PLU
      categoryId: new UntypedFormControl(null, Validators.required),
      subCategoryId: new UntypedFormControl(null, Validators.required),
      lineaId: new UntypedFormControl(),
      ivaCodeId: new UntypedFormControl(null, Validators.required),
      cabineId: new UntypedFormControl(null, Validators.required),
      cabinTime: new UntypedFormControl(null, Validators.required),
      disabledCabine: new UntypedFormControl(),
      operatorTime: new UntypedFormControl(null, Validators.required),
      pauseTime: new UntypedFormControl(),
      masterPrice: new UntypedFormControl(null, Validators.required),
      pdvPrice: new UntypedFormControl(),
      disabledOperator: new UntypedFormControl(false),
      note: new UntypedFormControl(),
      beautyCenterCost: new UntypedFormControl(),
      averageOperatorCost: new UntypedFormControl(),
      productsCost: new UntypedFormControl(null),
      treatmentCost: new UntypedFormControl(null),
      profitMargin: new UntypedFormControl(null),
      profitMarginPerCent: new UntypedFormControl(null)
    });
    this.relatedProductsForm = new UntypedFormGroup({
      id: new UntypedFormControl(),
      idProduct: new UntypedFormControl(),
      quantityPerTreatment: new UntypedFormControl(null, Validators.min(1)),
      measureUnit: new UntypedFormControl(),
      totalPrice: new UntypedFormControl(null)
    });
    this.masterPriceFormControl.setValidators(Validators.required);
    this.operatorTimeFormControl.setValidators(Validators.required);
    /* treatments */
    this.treatmentForm.get('createdAt').disable();
    this.operatorTimeFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if (!c) {
        c = 0;
      }
      this.treatmentForm.get('operatorTime').setValue(c);
      if (this.treatmentForm.get('averageOperatorCost').value !== null) {
        this.operatorCost = c * (this.treatmentForm.get('averageOperatorCost').value / 60);
        this.treatmentForm.get('treatmentCost').setValue(this.operatorCost + this.treatmentForm.get('productsCost').value);
        this.calculateBenefit();
      }
    });
    this.masterPriceFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if (!c) {
        c = 0;
      }
      this.treatmentForm.get('masterPrice').setValue(c);
      this.calculateBenefit();
    });
    this.pdvPriceFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if (!c) {
        c = 0;
      }
      this.treatmentForm.get('pdvPrice').setValue(c);
      this.calculateBenefit();
    });
    this.beautyCenterCostFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if (!c) {
        c = 0;
      }
      this.treatmentForm.get('beautyCenterCost').setValue(c);
      this.calculateBenefit();
    });
    this.averageOperatorCostFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if (!c) {
        c = 0;
      }
      this.treatmentForm.get('averageOperatorCost').setValue(c);
      if (this.treatmentForm.get('operatorTime').value !== null) {
        this.operatorCost = this.treatmentForm.get('operatorTime').value * (c / 60);
        this.treatmentForm.get('treatmentCost').setValue(this.operatorCost + this.treatmentForm.get('productsCost').value);
        this.calculateBenefit();
      }
    });
    this.categoryFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      this.treatmentForm.get('categoryId').setValue(c);
      if (c && !this.categoryFormControl.disabled) {
        this.treatmentForm.get('subCategoryId').enable();
        this.getSubCategories(c);
      } else if (!c) {
        this.treatmentForm.get('subCategoryId').setValue(null);
        this.treatmentForm.get('subCategoryId').disable();
      }
    });
    if (!this.editMode) {
      this.sequence.getCurrentSequence(SequenceType.TREATMENT).subscribe(res => {
        this.treatmentForm.get('seq').setValue(res + 1);
        this.currentSeq = (this.treatmentForm.get('seq').value + '').padStart(4, '0');
      });
    }
    this.currentSeq = (this.treatmentForm.get('seq').value + '').padStart(4, '0');
    if (this.editMode) {
      this.preventValueChange = true;
      this.treatmentForm.patchValue(this.treatment);
      this.disableCabine.patchValue(this.treatment.disabledCabine);
      this.disableOperator.patchValue(this.treatment.disabledOperator);
      if (this.treatment.category !== null ){
        this.categoryFormControl.patchValue(this.treatment.category.id);
        this.getSubCategories(this.treatment.category.id);
      }
      this.currentSeq = (this.treatmentForm.get('seq').value + '').padStart(4, '0');
      if (this.treatment.subCategory != null) {
        this.treatmentForm.get('subCategoryId').setValue(this.treatment.subCategory.id);
      }
      if (this.treatment.linea != null) {
        this.treatmentForm.get('lineaId').setValue(this.treatment.linea.id);
      }
      if (this.treatment.ivaCode != null) {
        this.treatmentForm.get('ivaCodeId').setValue(this.treatment.ivaCode.id);
      }
      if (!this.treatment.disabledCabine && this.treatment.cabine != null) {
        this.treatmentForm.get('cabineId').setValue(this.treatment.cabine.id);
      }
      if (this.treatment.operatorTime != null) {
        this.operatorTimeFormControl.patchValue(this.treatment.operatorTime);
      }
      if (this.treatment.averageOperatorCost != null) {
        this.averageOperatorCostFormControl.patchValue(this.treatment.averageOperatorCost);
      }
      if (this.treatment.masterPrice != null) {
        this.masterPriceFormControl.patchValue(this.treatment.masterPrice);
      }
      if (this.treatment.pdvPrice != null) {
        this.pdvPriceFormControl.patchValue(this.treatment.pdvPrice);
      }
      if (this.treatment.beautyCenterCost != null) {
        this.beautyCenterCostFormControl.patchValue(this.treatment.beautyCenterCost);
      }
      this.relatedProducts = this.treatment.treatmentProducts.map(e => {
        e.idProduct = e.product.id;
        return e;
      });
      this.recalculateTotalProductsCost(this.relatedProducts);
      this.operatorTimeFormControl.disable();
      this.treatmentForm.disable();
      this.categoryFormControl.disable();
      this.pdvPriceFormControl.disable();
      this.masterPriceFormControl.disable();
      this.beautyCenterCostFormControl.disable();
      this.averageOperatorCostFormControl.disable();
      this.disableOperator.disable();
      this.disableCabine.disable();
    }
    this.prodCodeFormControl.setValue(this.treatmentForm.get('prodCode').value);
    this.validProdcode = true;
    this.uniqueProdcode = true;
    this.getConfiguration();

    /* end treatments */

    this.disableCabine.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if (c) {
        this.treatmentForm.get('cabinTime').disable();
        this.treatmentForm.get('cabineId').disable();
        this.treatmentForm.get('cabinTime').setValue(null);
        this.treatmentForm.get('cabineId').setValue(null);
        this.treatmentForm.get('disabledCabine').setValue(c);
      } else {
        this.treatmentForm.get('cabinTime').enable();
        this.treatmentForm.get('cabineId').enable();
        if (this.treatment) {
          this.treatmentForm.get('cabinTime').setValue(this.treatment.cabinTime);
          if (this.treatment.cabine) {
            this.treatmentForm.get('cabineId').setValue(this.treatment.cabine.id);
          }
        }
        this.treatmentForm.get('disabledCabine').setValue(c);
      }
    });

    this.disableOperator.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if (c) {
        this.operatorTimeFormControl.patchValue(null);
        this.operatorTimeFormControl.disable();
        this.treatmentForm.get('disabledOperator').setValue(c);
      } else {
        this.operatorTimeFormControl.enable();
        if (this.treatment) {
          this.operatorTimeFormControl.patchValue(this.treatment.operatorTime);
        }
        this.treatmentForm.get('disabledOperator').setValue(c);
      }
    });

    /* begin related products*/

    this.productFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if (c) {
        this.relatedProductsForm.get('totalPrice').setValue(null);
        this.relatedProductsForm.get('idProduct').setValue(c);
        this.product = this.productsList.find(x => x.id === c);
        //   this.relatedProductsForm.get('measureUnit').setValue(this.product.measureUnit);
        this.unitFormControl.patchValue(MeasureUnityType.Unit);
        this.relatedProductsForm.get('quantityPerTreatment').setValue(1);
        this.relatedProductsForm.get('totalPrice').setValue(this.getTotalPrice(this.relatedProductsForm.get('measureUnit').value,
          this.relatedProductsForm.get('quantityPerTreatment').value,
          this.product.quantity, this.product.purchasePrice));
      }
    });
    this.quantityFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if (!c) {
        c = 0;
      }
      this.relatedProductsForm.get('quantityPerTreatment').setValue(c);
      this.relatedProductsForm.get('totalPrice').setValue(this.getTotalPrice(this.relatedProductsForm.get('measureUnit').value,
        this.relatedProductsForm.get('quantityPerTreatment').value,
        this.product.quantity, this.product.purchasePrice));
    });
    this.unitFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(u => {
      this.relatedProductsForm.get('measureUnit').setValue(u);
      if (this.product.measureUnit != null && u === MeasureUnityType.Unit) {
        this.quantityFormControl.patchValue(1);
      }
      if (this.product.measureUnit != null && u !== MeasureUnityType.Unit && this.product.activeDetailSale) {
        this.quantityFormControl.patchValue(this.product.quantity);
      }
      this.relatedProductsForm.get('totalPrice').setValue(this.getTotalPrice(this.relatedProductsForm.get('measureUnit').value,
        this.relatedProductsForm.get('quantityPerTreatment').value,
        this.product.quantity, this.product.purchasePrice));
    });
    this.prodCodeFormControl.valueChanges.pipe(debounceTime(500)).subscribe(c => {
      if (c.length === 0){
        this.treatmentForm.get('prodCode').setValue(c);
        this.validProdcode =  false;
        return
      }
      this.treatmentForm.get('prodCode').setValue(c);
      this.validProdcode =  true;
      console.log(this.validProdcode)
      this.prodCodeService.isUnique(this.treatmentForm.get('prodCode').value.toUpperCase()).subscribe(res => {
        console.log('univocita ' + res)
        if (res) {
          this.barCodeService.uniqueProductEAN113({
            code:this.treatmentForm.get('prodCode').value.toUpperCase(),
            type: EanType.PRODUCT
          }).subscribe(prodCodeUnivc => {
            if (prodCodeUnivc){
              this.uniqueProdcode = true;
              return;
            }else {
              this.uniqueProdcode = false;
              return;
            }
          })
          return;
        }
        this.uniqueProdcode = false;
        console.log(this.validProdcode)
        console.log(this.treatmentForm.get('prodCode').value)
      });
    });

    /* end related products */
  }

  /* treatments */

  getConfiguration() {
    this.configurationsService.lazyIvaCode({page: this.IvaCodePage, pageSize: MAX_NUMBER}).subscribe(d => {
      this.IvaCodeList = [...this.IvaCodeList, ...d.data];
      this.IvaCodePage++;
    });

    this.providerService.getLazyProviderList({page: this.providersPage, pageSize: MAX_NUMBER, visible: true}).subscribe(d => {
      this.providersList = [...this.providersList, ...d.data];
      this.providersPage++;
    });

    this.configurationsService.getLazyLineas({page: this.lineaPage, pageSize: MAX_NUMBER}).subscribe(d => {
      this.lineaList = [...this.lineaList, ...d.data];
      this.lineaPage++;
    });

    this.configurationsService.getLazyCategories({page: this.categoriesPage, pageSize: MAX_NUMBER, type: 'TREATMENT'}).subscribe(d => {
      this.categoriesList = [...this.categoriesList, ...d.data];
      this.categoriesPage++;
    });

    this.cabineService.search({page: this.cabinesPage, pageSize: MAX_NUMBER}).subscribe(d => {
      this.cabinesList = [...this.cabinesList, ...d.data];
      this.cabinesPage++;
    });
  }

  getConfigurationsByType(confType: string) {
    switch (confType) {
      case 'TAX': {
        this.configurationsService.lazyIvaCode({page: this.IvaCodePage, pageSize: MAX_NUMBER}).subscribe(d => {
          this.IvaCodeList = [...this.IvaCodeList, ...d.data];
          this.IvaCodePage++;
        });
        break;
      }
      case 'PROVIDER': {
        this.providerService.getLazyProviderList({page: this.providersPage, pageSize: MAX_NUMBER, visible: true}).subscribe(d => {
          this.providersList = [...this.providersList, ...d.data];
          this.providersPage++;
        });
        break;
      }

      case 'LINEA': {
        this.configurationsService.getLazyLineas({page: this.lineaPage, pageSize: MAX_NUMBER}).subscribe(d => {
          this.lineaList = [...this.lineaList, ...d.data];
          this.lineaPage++;
        });
        break;
      }

      case 'CATEGORY': {
        this.configurationsService.getLazyCategories({page: this.categoriesPage, pageSize: MAX_NUMBER, type: 'TREATMENT'}).subscribe(d => {
          this.categoriesList = [...this.categoriesList, ...d.data];
          this.categoriesPage++;
        });
        break;
      }

      case 'CABINE': {
        this.cabineService.search({page: this.cabinesPage, pageSize: MAX_NUMBER}).subscribe(d => {
          this.cabinesList = [...this.cabinesList, ...d.data];
          this.cabinesPage++;
        });
        break;
      }
    }
  }

  getSubCategories(c) {
    this.categoryIdLoading = true;
    this.configurationsService.getSubCategoriesByCategoryid(c).subscribe(d => {
      this.subCategoriesList = d;
      this.categoryIdLoading = false;
      if (!this.editMode || c !== this.treatment.category.id) {
        this.treatmentForm.get('subCategoryId').setValue(null);
      }
      if (this.subCategoriesList.length === 0) {
        this.treatmentForm.get('subCategoryId').disable();
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(void 0);
    this.unsubscribe$.unsubscribe();
  }
  closeFormSave() {
          this.destroyForm();
  }
  closeForm() {
    if (this.treatmentForm.dirty) {
     this.sweetAlertService.showConfirmationAlert()
      .then((confirmation) => {
        if (confirmation) {
          this.destroyForm();
        }
      });}
    else{
      this.destroyForm();
    }
  }

  destroyForm() {
    this.appRef.detachView(this.dialogComponentRef.hostView);
    this.dialogComponentRef.destroy();
  }

  activateEdit() {
    if (this.editMode) {
      this.editClicked = true;
      this.treatmentForm.enable();
      this.categoryFormControl.enable();
      this.operatorTimeFormControl.enable();
      this.averageOperatorCostFormControl.enable();
      this.pdvPriceFormControl.enable();
      this.masterPriceFormControl.enable();
      this.beautyCenterCostFormControl.enable();
      this.treatmentForm.get('createdAt').disable();
      this.disableOperator.enable();
      this.disableCabine.enable();
      if (this.treatmentForm.get('disabledCabine').value) {
        this.treatmentForm.get('cabinTime').disable();
        this.treatmentForm.get('cabineId').disable();
      }
      if (this.treatmentForm.get('disabledOperator').value) {
        this.operatorTimeFormControl.disable();
      }

      this.preventValueChange = false;
    }
  }

  save() {
    this.submitted = true;
    if (!this.uniqueProdcode){
      this.sweetAlertService.danger('Controllare il codice articolo');
      return;
    }
    if (!this.treatmentForm.valid) {
      this.sweetAlertService.notification(this.translate.instant('DIALOG.INSERT_ALL_MANDATORY_INFORMATION')).then(e => {
      });
      return;
    }
    this.treatmentForm.get('prodCode').setValue(String(this.treatmentForm.get('prodCode').value).toUpperCase())
    const prodCode = this.treatmentForm.get('prodCode').value + '';
    if (!prodCode || prodCode.length === 0){
      return;
    }
    this.disableSave = true;

    if (!this.editMode) {
      this.prodCodeService.isUnique(prodCode).subscribe(r=> {
        if (r) {
          this.treatmentService.addNewTreatment({
            ...this.treatmentForm.getRawValue(),
            treatmentProducts: this.relatedProducts
          }).subscribe(d => {
            this.onClose.next(this.treatmentForm.value);
            this.closeFormSave();
          }, error => this.disableSave = false);
        }
      });
    } else {
      if (this.treatmentForm.get('prodCode').dirty){
        this.prodCodeService.isUnique(prodCode).subscribe(r=> {
          if (r) {
            this.treatmentService.editTreatment({
              ...this.treatmentForm.getRawValue(),
              treatmentProducts: this.relatedProducts
            }).subscribe(d => {
              this.onClose.next(this.treatmentForm.value);
              this.closeFormSave();
            }, error => this.disableSave = false);
          }
        });
      } else {
        this.treatmentService.editTreatment({
          ...this.treatmentForm.getRawValue(),
          treatmentProducts: this.relatedProducts
        }).subscribe(d => {
          this.onClose.next(this.treatmentForm.value);
          this.closeFormSave();
        }, error => this.disableSave = false);
      }
    }


    /*
    this.prodCodeService.isUnique(prodCode).subscribe(r=>{
      if (r){
        if (!this.editMode) {
          this.treatmentService.addNewTreatment({
            ...this.treatmentForm.getRawValue(),
            treatmentProducts: this.relatedProducts
          }).subscribe(d => {
            this.onClose.next(this.treatmentForm.value);
            this.closeForm();
          }, error => this.disableSave = false);
        } else {
          this.treatmentService.editTreatment({
            ...this.treatmentForm.getRawValue(),
            treatmentProducts: this.relatedProducts
          }).subscribe(d => {
            this.onClose.next(this.treatmentForm.value);
            this.closeForm();
          }, error => this.disableSave = false);
        }

    }})
    */

  }

  recalculateTotalProductsCost(items: any) {
    this.treatmentForm.get('productsCost').setValue(items.reduce((a, p) => a + this.getTotalPrice(p.measureUnit, p.quantityPerTreatment,
      p.product.quantity, p.product.purchasePrice), 0));
    this.treatmentForm.get('treatmentCost').setValue(this.operatorCost + this.treatmentForm.get('productsCost').value);
    this.calculateBenefit();
  }

  calculateBenefit() {
    let benefit = 0;
    let total = 0;
    this.treatmentForm.get('profitMargin').setValue(null);
    this.treatmentForm.get('profitMarginPerCent').setValue(null);
    if (this.treatmentForm.get('pdvPrice').value !== null && this.treatmentForm.get('pdvPrice').value !== 0) {
      total += this.treatmentForm.get('pdvPrice').value;
    } else if (this.treatmentForm.get('masterPrice').value != null && this.treatmentForm.get('masterPrice').value !== 0) {
      total += this.treatmentForm.get('masterPrice').value;
    } else {
      total = 0;

    }
    if (total !== 0) {
      benefit = total;
      if (this.treatmentForm.get('productsCost').value) {
        benefit -= this.treatmentForm.get('productsCost').value;
      }
      if (this.operatorCost) {
        benefit -= this.operatorCost;
      }
      if (this.treatmentForm.get('beautyCenterCost').value) {
        benefit -= this.treatmentForm.get('beautyCenterCost').value;
      }
      this.treatmentForm.get('profitMargin').setValue(benefit);
      this.treatmentForm.get('profitMarginPerCent').setValue(benefit * 100 / total);
    }
  }

  /* end treatments */

  getLazyProducts() {
    this.productService.getLazyProductList({page: this.productsPage, pageSize: this.pageSize, activeDetailSale: true}).subscribe(d => {
      this.productsList = [...this.productsList, ...d.data];
      this.productsPage++;
    });
  }

  open(content) {
    this.productsList = [];
    this.productsPage = 1;
    this.getLazyProducts();
    this.relatedProductsSubmitted = false;
    this.dialogRef = this.matDialog.open(content, {
      autoFocus: false,
      maxHeight: '90vh'
    });
  }

  getTotalPrice(measureUnit, quantityPerTreatment, quantity, purchasePrice) {
    if (measureUnit !== null) {
      if (measureUnit === 'Unit'
        && quantityPerTreatment !== null
        && purchasePrice !== null) {
        return quantityPerTreatment * purchasePrice;
      }
      if (measureUnit !== 'Unit'
        && quantityPerTreatment !== null
        && quantity !== null
        && quantity !== 0
        && purchasePrice !== null) {
        return quantityPerTreatment * purchasePrice / quantity;
      }
    }
  }

  saveRelatedProduct() {
    this.relatedProductsSubmitted = true;
    if (this.relatedProductsForm.valid) {
      if (!this.editRelatedProducts && this.relatedProductsForm.get('idProduct').value) {
        this.relatedProducts.push({
          ...this.relatedProductsForm.getRawValue(),
          product: this.product
        });
        this.resetRelatedProductsForm();
        this.dialogRef.close();
      } else {
        this.relatedProducts[this.selectedIndex]
          = {...this.relatedProductsForm.getRawValue(), product: this.product};
        this.resetRelatedProductsForm();
        this.dialogRef.close();
      }
    }
  }

  cancel() {
    this.resetRelatedProductsForm();
  }

  resetRelatedProductsForm() {
    this.recalculateTotalProductsCost(this.relatedProducts);
    this.productFormControl.enable();
    this.productFormControl.patchValue(null, {
      onlySelf: true,
      emitEvent: false
    });
    this.quantityFormControl.patchValue(null, {
      onlySelf: true,
      emitEvent: false
    });
    this.unitFormControl.patchValue(null, {
      onlySelf: true,
      emitEvent: false
    });
    this.product = null;
    this.relatedProductsForm.get('totalPrice').setValue(null);
    this.relatedProductsSubmitted = false;
    this.editRelatedProducts = false;
    this.relatedProductsForm.reset();
  }

  deleteRelatedProduct(index: number) {
    this.sweetAlertService.warning(this.translate.instant('TREATMENT_FORM.YOU_WANT_TO_HIDE_RELATED_PRODUCT'))
      .then(res => {
        if (res.value) {
          this.relatedProducts.splice(index, 1);
          this.recalculateTotalProductsCost(this.relatedProducts);
        }
      });
  }


  EditRelatedProduct(product, content, index) {
    this.editRelatedProducts = true;
    this.productsList = [];
    this.productsList.push(product.product);
    this.productFormControl.patchValue(product.product.id);
    this.productFormControl.disable();
    this.relatedProductsForm.patchValue(product);
    this.unitFormControl.patchValue(product.measureUnit);
    this.quantityFormControl.patchValue(product.quantityPerTreatment);
    this.product = product.product;
    this.selectedIndex = index;
    this.relatedProductsSubmitted = false;
    this.dialogRef = this.matDialog.open(content, {
      autoFocus: false,
      maxHeight: '90vh'
    });
  }

  addCategory() {
    if (this.treatmentForm.controls.id.value && !this.editClicked) {
      return;
    }
    const dialogRef = this.matDialog.open(CategoryFormModalComponent, {
      width: '700px',
      autoFocus: true,
      disableClose: true,
      data: {editMode: false, fromProduct: true}
    });
    dialogRef.afterClosed().subscribe(d => {
      if (d) {
        this.categoriesList = [d, ...this.categoriesList];
        this.categoryFormControl.setValue(d.id, {emitEvent: false});
        this.treatmentForm.get('categoryId').setValue(d.id);
        // this.treatmentForm.get('subCategoryId').enable();
      }
    });
  }

  addSubCategory() {
    if (!this.categoryFormControl.value || (this.treatmentForm.controls.id.value && !this.editClicked)) {
      return;
    }
    const dialogRef = this.matDialog.open(SubCategoryFormModalComponent, {
      width: '700px',
      autoFocus: true,
      disableClose: true,
      data: {
        editMode: false, fromProduct: true,
        categoryId: this.treatmentForm.get('categoryId').value
      }
    });
    dialogRef.afterClosed().subscribe(d => {
      if (d) {
        this.subCategoriesList = [d, ...this.subCategoriesList];
        this.treatmentForm.get('subCategoryId').setValue(d.id);
      }
    });
  }

  addLinea() {
    if (this.treatmentForm.controls.id.value && !this.editClicked) {
      return;
    }
    const dialogRef = this.matDialog.open(LineaFormModalComponent, {
      width: '700px',
      autoFocus: true,
      disableClose: true,
      data: {
        editMode: false, fromProduct: true,
        categoryId: this.treatmentForm.get('categoryId').value
      }
    });
    dialogRef.afterClosed().subscribe(d => {
      if (d) {
        this.lineaList = [d, ...this.lineaList];
        this.treatmentForm.get('lineaId').setValue(d.id);
      }
    });
  }

  generateProdCode() {
    if (this.editMode && !this.editClicked) {
      return;
    }
    this.prodCodeService.generateProdCode().subscribe(r => {
      this.treatmentForm.get('prodCode').setValue(r);
      this.validProdcode = true;
      this.uniqueProdcode = true;
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.treatmentForm.dirty) {
      $event.returnValue = true;
    }
  }
}


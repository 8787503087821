import {IvaCode} from './ivaCode';
import {Provider} from './provider';
import {Category} from './category';
import {SubCategory} from './sub-category';
import {Barcode} from './barcode';
import {Brand} from './brand';
import {Linea} from './linea';
import {ProductUseType} from '../enum/product-use-type.enum';
import {MeasureUnityType} from '../enum/measure-unity-type.enum';

export class Product {

  id?: number;
  uuid?: string;
  seq?: string;
  createdAt?: string;
  updatedAt?: string;

  description?: string;
  commercialDescription?: string;
  note?: string;
  barCode?: string;
  prodCode?: string;

  price?: number;
  pricePDV?: number;

  useType?: ProductUseType;

  recharge?: number;
  rechargePDV?: number;
  purchasePrice?: number;

  minStock?: number;
  measureUnit?: MeasureUnityType;
  quantity?: number;
  activeDetailSale?: boolean;

  ecommerce?: boolean;

  meduimCost?: number;
  lastLoadingCost?: number;
  lastSalePrice?: number;
  stock?: number;

  disponibility?: number;

  ivaCodeId?: number;
  ivaCode?: IvaCode;

  categoryId?: number;
  category?: Category;

  subCategoryId?: number;
  subCategory?: SubCategory;

  brandId?: number;
  brand?: Brand;

  lineaId?: number;
  linea?: Linea;

  providerId?: number;
  provider?: Provider;

  categoryName?: string;
  subCategoryName?: string;

  image?: string;
  ivaValue?: string;
  stockAlert?: boolean;
  currentQuantity?: number;

  providerDescription?: string;
  lineaDescription?: string;
  brandDescription?: string;
  subCategoryDescription?: string;
  categoryDescription?: string;
  barcodeDto?: Barcode[];
}

import { Pipe, PipeTransform } from '@angular/core';
import {Treatment} from '../../../../../../shared/models/treatment';

@Pipe({
  name: 'groupBy'
})
export class GroupByPipe implements PipeTransform {

  transform(value: any[], key: string): any {
    const group = value.reduce((r, a) => {
      r[a.uuid] = [...r[a.uuid] || [], a];
      return r;
    }, {});
    return Object.keys(group).map(u => {
      if (u && u.length > 0) {
        return {
          ...group[u][0],
          quantity: group[u].length
        };
      }
      return null;
    }).filter(u => u !== null);
  }

}

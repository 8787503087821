export class ConfigCourtesyRtDto {
  id: null;
  uuid: null;
  ip: string;
  port: string;
  active: boolean;
  creationDate: null;
  modificationDate: null;
  header: any[]; //max 6 length
  footer: any[]; //max 4 length
}

/**
 * Every line of text you send to the courtesy rt have to have it's own text and font size
 * header and footer are arrays of rows and Every rows's character have width and height,
 * */

export class PrintCourtesyRtDto {
  id:null;
  text:string;
  width:number;
  height:number;
  lineNumber:number;
}


import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Treatment} from '../../../../../../shared/models/treatment';
import {Product} from '../../../../../../shared/models/product';
import {Subject} from 'rxjs';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {   MatSnackBar } from '@angular/material/snack-bar';
import {ProductMgmService} from '../../../../../../shared/services/product-mgm.service';
import {TreatmentMgmService} from '../../../../../../shared/services/treatment-mgm.service';
import {takeUntil} from 'rxjs/operators';
import {FidelityService} from '../../../../../../shared/services/fidelity.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-threshold-form',
  templateUrl: './threshold-form.component.html',
  styleUrls: ['./threshold-form.component.scss']
})
export class ThresholdFormComponent implements OnInit {
  submitted = false;
  fidelityForm: UntypedFormGroup;
  private formBuilder = new UntypedFormBuilder();
  treatmentList: Treatment[] = [];
  treatmentLoading = false;
  productsList: Product[] = [];
  productsPage = 1;
  unsubscribe$ = new Subject();
  treatmentPage = 1;
  disableSave = false;

  constructor(public dialogRef: MatDialogRef<ThresholdFormComponent>,
              private productService: ProductMgmService, private cardService: FidelityService,
              @Inject(MAT_DIALOG_DATA) public data: any, private snackBar: MatSnackBar, private translate: TranslateService,
              private treatmentService: TreatmentMgmService) {
  }


  ngOnInit() {
    this.loadProducts();
    this.LoadTreatment();
    this.fidelityForm = this.formBuilder.group({
      id: new UntypedFormControl(),
      name: new UntypedFormControl(null, Validators.required),
      point: new UntypedFormControl(null, Validators.required),
      treatmentId: new UntypedFormControl(null, Validators.required),
      productId: new UntypedFormControl(null, Validators.required),
      withSecondaryThreshold: new UntypedFormControl(false),
      secondaryPoint: new UntypedFormControl(null),
      price: new UntypedFormControl(null),
      fidelityCardId: new UntypedFormControl(null),
    });
    this.fidelityForm.get('withSecondaryThreshold').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if (c) {
        this.fidelityForm.get('secondaryPoint').setValidators(Validators.required);
        this.fidelityForm.get('price').setValidators(Validators.required);
      } else {
        this.fidelityForm.get('secondaryPoint').setValidators(null);
        this.fidelityForm.get('price').setValidators(null);
      }
      this.fidelityForm.get('secondaryPoint').updateValueAndValidity();
      this.fidelityForm.get('price').updateValueAndValidity();
    });
    this.fidelityForm.get('productId').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if (c) {
        this.fidelityForm.get('treatmentId').setValidators(null);
      } else {
        this.fidelityForm.get('treatmentId').setValidators(Validators.required);
      }
      this.fidelityForm.get('treatmentId').updateValueAndValidity({emitEvent: false});
    });

    this.fidelityForm.get('treatmentId').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if (c) {
        this.fidelityForm.get('productId').setValidators(null);
      } else {
        this.fidelityForm.get('productId').setValidators(Validators.required);
      }
      this.fidelityForm.get('productId').updateValueAndValidity({emitEvent: false});
    });
    if (this.data.threshold) {
      this.fidelityForm.patchValue(this.data.threshold);
      if (this.data.threshold.productDescription) {
        this.loadProducts(this.data.threshold.productDescription);
      }
      if (this.data.threshold.treatmentDescription) {
        this.treatmentService.getLazyTreatmentList({
          page: 1, pageSize: 1, textSearch: this.data.threshold.treatmentDescription
        }).subscribe(data => {
          this.treatmentList.push(data.data[0]);
        });
      }
    }
  }

  save() {
    this.submitted = true;
    if (!this.fidelityForm.valid) {
      return;
    }
    this.fidelityForm.get('name').disable();
    if (!this.data.card.id) {
      this.cardService.create(this.data.card).subscribe(r => {
        this.fidelityForm.get('fidelityCardId').setValue(r.id);
        this.saveThreshold();
        return;
      }, error => this.fidelityForm.get('name').enable());
    } else {
      this.fidelityForm.get('fidelityCardId').setValue(this.data.card.id);
      this.saveThreshold();
    }

  }

  saveThreshold() {
    if (this.fidelityForm.get('id').value) {
      this.cardService.updateThreshold(this.fidelityForm.getRawValue()).subscribe(d => {
        this.snackBar.open(this.translate.instant('DIALOG.ADD_SUCCESS'), 'Ok', {duration: 5000, panelClass: 'white-snackbar'});
        this.dialogRef.close(this.fidelityForm.get('fidelityCardId').value);
      }, (err) => {
        this.snackBar.open(this.translate.instant('DIALOG.INTERNAL_SERVER_ERROR'), 'Ok', {duration: 5000});
        this.fidelityForm.get('name').enable();
      }, () => {
        this.submitted = false;
        this.dialogRef.close(this.fidelityForm.get('fidelityCardId').value);
      });
      return;
    }
    this.cardService.createThreshold(this.fidelityForm.getRawValue()).subscribe(d => {
      this.snackBar.open(this.translate.instant('DIALOG.ADD_SUCCESS'), 'Ok', {duration: 5000, panelClass: 'white-snackbar'});
      this.dialogRef.close(this.fidelityForm.get('fidelityCardId').value);
    }, (err) => {
      this.fidelityForm.get('name').enable();
      this.snackBar.open(this.translate.instant('DIALOG.INTERNAL_SERVER_ERROR'), 'Ok', {duration: 5000});
    }, () => {
      this.submitted = false;
      this.dialogRef.close(this.fidelityForm.get('fidelityCardId').value);
    });
  }

  loadProducts(textSearch?) {
    this.productService.getLazyProductList({page: this.productsPage, pageSize: 10, textSearch}).subscribe(d => {
      this.productsList = [...this.productsList, ...d.data];
      this.productsPage++;
    });
  }

  LoadTreatment(textSearch?) {
    this.treatmentService.getLazyTreatmentList({page: this.treatmentPage, pageSize: 10, textSearch}).subscribe(data => {
      this.treatmentList = [...this.treatmentList, ...data.data];
      this.treatmentPage++;
    });
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {ClientManagementComponent} from './client-management.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {MatTableModule} from '@angular/material/table';
import {MatListModule} from '@angular/material/list';
import {MatPaginatorModule} from '@angular/material/paginator';
import {ClientManagementFormComponent} from './client-management-form/client-management-form.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatRippleModule} from '@angular/material/core';
import {
  NgbDropdownModule,
  NgbPaginationModule,
  NgbPopoverModule,
  NgbTooltipModule,
  NgbTypeaheadModule
} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ClientFormService} from './services/client-form.service';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {SubheaderModule} from '../../../layout/components/subheader/subheader.module';
import {SharedModule} from '../../../../shared/shared.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';

import {ClientFidelityComponent} from './client-fidelity/client-fidelity.component';
import {ClientDialogComponent} from './client-dialog/client-dialog.component';
import {FidelityComponent} from '../../configurations/fidelity/fidelity.component';
import {FidelityModule} from '../../configurations/fidelity/fidelity.module';
import {FidelityHistoryComponent, IntPipe} from './client-fidelity/fidelity-history/fidelity-history.component';
import {ClientSubscriptionComponent} from './client-subscription/client-subscription.component';
import {
  SubscriptionFormModule
} from '../../productsAndServices/subscription-management/subscription-form/subscription-form.module';
import {AnamnesiComponent} from './anamnesi/anamnesi.component';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {FilterGeneralOptionsPipe} from './anamnesi/pipes/filter-general-options.pipe';
import {GroupByPipe} from './anamnesi/pipes/group-by.pipe';
import {GetBodyZonePipe} from './anamnesi/pipes/get-body-zone.pipe';
import {SetImcCheckPipe} from './anamnesi/pipes/set-imc-check.pipe';
import {RomanNumberPipe} from './anamnesi/pipes/roman-number.pipe';
import {CurrencyMaskModule} from 'ng2-currency-mask';
import {PurchaseHistoryComponent} from './purchase-history/purchase-history.component';
import {ClientTintComponent} from './client-tint/client-tint.component';
import {AddTintComponent} from './client-tint/add-tint/add-tint.component';
import {MatLegacyDialog, MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import {WhatsAppDialogComponent} from './whats-app-dialog/whats-app-dialog.component';

const routes: Routes = [];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatButtonModule,
    MatIconModule,
    SubheaderModule,
    NgxDatatableModule,
    MatTableModule,
    MatListModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatRippleModule,
    NgbDropdownModule,
    TranslateModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    SharedModule.forChild(),
    NgbTypeaheadModule,
    NgbPaginationModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    FidelityModule,
    FormsModule,
    SubscriptionFormModule,
    MatRadioModule,
    MatCheckboxModule,
    CurrencyMaskModule,
    NgbPopoverModule,
    NgbTooltipModule,
    MatLegacyDialogModule,
  ],
  providers: [
    ClientFormService,
    MatLegacyDialog
  ],
  declarations: [
    ClientManagementComponent,
    ClientManagementFormComponent,
    ClientFidelityComponent,
    ClientDialogComponent,
    FidelityHistoryComponent,
    ClientSubscriptionComponent,
    IntPipe,
    AnamnesiComponent,
    FilterGeneralOptionsPipe,
    GroupByPipe,
    GetBodyZonePipe,
    SetImcCheckPipe,
    RomanNumberPipe,
    PurchaseHistoryComponent,
    ClientTintComponent,
    AddTintComponent,
    WhatsAppDialogComponent,
  ],
  exports: [
    ClientManagementFormComponent,
    PurchaseHistoryComponent,
    AddTintComponent,
  ]
})
export class ClientManagementModule {
}

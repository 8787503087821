// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 767.98px) {
  .page-heading .breadcrumb {
    display: -webkit-box;
  }
}
.page-heading {
  padding-bottom: 4px;
}`, "",{"version":3,"sources":["webpack://./src/app/content/layout/components/breadcrumb/breadcrumb.component.scss"],"names":[],"mappings":"AAAA;EACA;IACI,oBAAA;EACF;AACF;AAGA;EACE,mBAAA;AADF","sourcesContent":["@media (max-width: 767.98px){\r\n.page-heading .breadcrumb {\r\n    display: -webkit-box;\r\n  }\r\n}\r\n\r\n\r\n.page-heading {\r\n  padding-bottom: 4px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {   MatSnackBar } from '@angular/material/snack-bar'
import {FidelityFormComponent} from './fidelity-form/fidelity-form.component';
import {UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {BreadcrumbService} from '../../../../core/services/breadcrumb.service';
import {LazyRequest} from '../../../../shared/dto/lazy-request';
import {FidelityService} from '../../../../shared/services/fidelity.service';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {FidelityCardConfiguration} from '../../../../shared/models/fidelity-card-configuration';
import {FidelityPointFormComponent} from './fidelity-point-form/fidelity-point-form.component';
import {TranslateService} from '@ngx-translate/core';
import {SweetAlertService} from '../../../../shared/services/sweet-alert.service';
import {FidelityCardType} from '../../../../shared/enum/fidelity-card-type';
import {FidelityWithDiscountFormComponent} from './fidelity-with-discount-form/fidelity-with-discount-form.component';
import {Client} from '../../../../shared/models/client';
import {CustomSnackBarComponent} from '../../../../shared/custom-snack-bar/custom-snack-bar.component';

@Component({
  selector: 'app-fidelity',
  templateUrl: './fidelity.component.html',
  styleUrls: ['./fidelity.component.scss']
})
export class FidelityComponent implements OnInit, OnDestroy {
  @Input() isModal = false;
  @Input() client = new Client();
  @Output() associate = new EventEmitter<number>();
  columns = [
    'DATA_TABLE.FIRST_NAME',
    'DATA_TABLE.TYPE',
    'DATA_TABLE.CREATION_DATE',
    'DATA_TABLE.AVAILABLE',
  ];
  rows: FidelityCardConfiguration[] = [];
  searchFormControl = new UntypedFormControl();
  totalRecords: number;
  pageSize = 10;
  page = 1;
  unsubscribe = new Subject();
  loading = true;
  firstCall = true;
  orderType: 'ASC' | 'DESC' = 'DESC';

  constructor(private matDialog: MatDialog, private cardService: FidelityService,
              private snackBar: MatSnackBar, private translate: TranslateService, private sweetAlertService: SweetAlertService,
              private breadcrumbService: BreadcrumbService) {
  }

  ngOnInit() {
    if (!this.isModal) {
      this.sendBreadCrumb();
      this.search({page: this.page, pageSize: this.pageSize});
      this.searchFormControl.valueChanges.pipe(debounceTime(500), takeUntil(this.unsubscribe)).subscribe(text => {
        this.search({page: 1, pageSize: this.pageSize, textSearch: text});
      });
      return;
    }

    this.cardService.findAvailable(this.client.id).subscribe(data => {
      this.rows = data;
      this.loading = false;
      this.totalRecords = data.length;
      this.firstCall = false;
    });

  }

  sendBreadCrumb(): void {
    this.breadcrumbService.sendBreadcrumb(['CONFIGURATION', 'FIDELITY']);
  }

  addFidelity() {
    const dialogRef = this.matDialog.open(FidelityFormComponent, {
      width: '700px',
      autoFocus: true,
      disableClose: true,
      data: {editMode: false}
    });
    dialogRef.afterClosed().subscribe(d => {
      this.search({page: this.page, pageSize: this.pageSize});
    });
  }

  openEdit(fidelity: FidelityCardConfiguration) {
    let dialogRef;
    if (fidelity.cardType === FidelityCardType.POINT_WITH_GIFT) {
      dialogRef = this.matDialog.open(FidelityPointFormComponent, {
        width: '900px',
        disableClose: true,
        data: {editMode: true, type: fidelity.cardType, card: fidelity}
      });
    } else {
      dialogRef = this.matDialog.open(FidelityWithDiscountFormComponent, {
        width: '900px',
        disableClose: true,
        data: {editMode: true, type: fidelity.cardType, card: fidelity}
      });
    }
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(d => {
      if (d) {
        this.search({page: this.page, pageSize: this.pageSize, textSearch: this.searchFormControl.value});
      }
    });
  }

  delete(fidelity: any, $event: MouseEvent) {
    $event.stopPropagation();
    $event.cancelBubble = true;
    this.sweetAlertService.warning(this.translate.instant('DIALOG.YOU_WANT_TO_DELETE') + fidelity.name).then(e => {
      if (e.value) {
        this.cardService.delete(fidelity.id).subscribe(d => {
          if (d.status === 200) {
            this.search({page: 1, pageSize: this.pageSize});
          }
        }, err => {
          if (err.status === 500) {
            this.showSnackBar({
              text: `${fidelity.name}`,
              actionIcon: 'failed',
              actionMsg: this.translate.instant('DIALOG.CANNOT_DELETE')
            });
          }
        });
      }
    });
  }

  showSnackBar(data: any) {
    this.snackBar.openFromComponent(CustomSnackBarComponent, {
      data,
      duration: 5000,
      panelClass: 'white-snackbar'
    });
  }

  pageChange(page) {
    this.page = page;
    this.search({page: this.page, pageSize: this.pageSize, textSearch: this.searchFormControl.value});
  }

  search(request: LazyRequest) {
    this.loading = true;
    return this.cardService.find(request).subscribe(data => {
      this.rows = data.data;
      this.loading = false;
      this.totalRecords = data.totalRecords;
      this.firstCall = false;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.unsubscribe();
  }

  sortRows(c: string) {
    const sort = c.substr(11);
    if (this.orderType === 'ASC') {
      this.orderType = 'DESC';
    } else {
      this.orderType = 'ASC';
    }
    switch (sort) {
      case 'FIRST_NAME':
        this.search({
          page: this.page, pageSize: this.pageSize, textSearch: this.searchFormControl.value, sort: {
            attribute: 'name',
            direction: this.orderType
          }
        });
        break;
      case 'TYPE':
        this.search({
          page: this.page, pageSize: this.pageSize, textSearch: this.searchFormControl.value, sort: {
            attribute: 'cardType',
            direction: this.orderType
          }
        });
        break;
      case 'CREATION_DATE':
        this.search({
          page: this.page, pageSize: this.pageSize, textSearch: this.searchFormControl.value, sort: {
            attribute: 'creationDate',
            direction: this.orderType
          }
        });
        break;
      case 'AVAILABLE':
        if (this.orderType === 'ASC') {
          this.rows.sort((a, b) => String(a.available).localeCompare(String(b.available)));
          return;
        }
        this.rows.sort((a, b) => String(b.available).localeCompare(String(a.available)));
        break;
    }
  }
}

import {ProductUseType} from '../enum/product-use-type.enum';
import {MeasureUnityType} from '../enum/measure-unity-type.enum';
import {StockHistory} from './stock-history';

export class Stock {
  id: number;
  seq: string;
  prodCode: string;
  description: string;
  commercialDescription: string;
  category: string;
  subCategory: string;
  provider: string;
  measureUnit: MeasureUnityType;
  useType: ProductUseType;
  brand: string;
  linea: string;
  purchasePrice;
  mediumCost: number;
  iva: string;
  price: number;
  minStock: number;
  stock: number;
  createdAt: Date;
  active: boolean;
  withPhoto: boolean;
  histories: StockHistory[] = [];
  gift: number;
  lastCost: number;
  lastPrice: number;
  stockAlert?: boolean;
}


import {Time} from 'ngx-bootstrap/timepicker/timepicker.models';

export class WorkTime {

  // mondayDayOff = true;
  // mondayWithBreak = false;
  // mondayMorFrom = '08:00';
  // mondayMorTo = '20:00';
  // mondayAfterFrom = null;
  // mondayAfterTo = null;
  //
  // tuesDayOff = true;
  // tuesWithBreak = false;
  // tuesMorFrom = '08:00';
  // tuesMorTo = '20:00';
  // tuesAfterFrom = null;
  // tuesAfterTo = null;
  //
  // wedDayOff = true;
  // wedWithBreak = false;
  // wedMorFrom = '08:00';
  // wedMorTo = '20:00';
  // wedAfterFrom = null;
  // wedAfterTo = null;
  //
  // thurDayOff = true;
  // thurWithBreak = false;
  // thurMorFrom = '08:00';
  // thurMorTo = '20:00';
  // thurAfterFrom = null;
  // thurAfterTo = null;
  //
  // friDayOff = true;
  // friWithBreak = false;
  // friMorFrom = '08:00';
  // friMorTo = '20:00';
  // friAfterFrom = null;
  // friAfterTo = null;
  //
  // satDayOff = true;
  // satWithBreak = false;
  // satMorFrom = '08:00';
  // satMorTo = '20:00';
  // satAfterFrom = null;
  // satAfterTo = null;
  //
  // sunDayOff = true;
  // sunWithBreak = false;
  // sunMorFrom = '08:00';
  // sunMorTo = '20:00';
  // sunAfterFrom = null;
  // sunAfterTo = null;


  mondayDayOff: boolean;
  mondayWithBreak: boolean;
  mondayMorFrom: Time;
  mondayMorTo: Time;
  mondayAfterFrom: Time;
  mondayAfterTo: Time;

  tuesDayOff: boolean;
  tuesWithBreak: boolean;
  tuesMorFrom: Time;
  tuesMorTo: Time;
  tuesAfterFrom: Time;
  tuesAfterTo: Time;

  wedDayOff: boolean;
  wedWithBreak: boolean;
  wedMorFrom: Time;
  wedMorTo: Time;
  wedAfterFrom: Time;
  wedAfterTo: Time;

  thurDayOff: boolean;
  thurWithBreak: boolean;
  thurMorFrom: Time;
  thurMorTo: Time;
  thurAfterFrom: Time;
  thurAfterTo: Time;

  friDayOff: boolean;
  friWithBreak: boolean;
  friMorFrom: Time;
  friMorTo: Time;
  friAfterFrom: Time;
  friAfterTo: Time;

  satDayOff: boolean;
  satWithBreak: boolean;
  satMorFrom: Time;
  satMorTo: Time;
  satAfterFrom: Time;
  satAfterTo: Time;

  sunDayOff: boolean;
  sunWithBreak: boolean;
  sunMorFrom: Time;
  sunMorTo: Time;
  sunAfterFrom: Time;
  sunAfterTo: Time;

}

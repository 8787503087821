import {Component, OnDestroy, OnInit} from '@angular/core';
import {Client} from '../../../../../shared/models/client';
import {Subject} from 'rxjs';
import {SweetAlertService} from "../../../../../shared/services/sweet-alert.service";
import {ClientMgmService} from "../../../../../shared/services/client-mgm.service";


@Component({
  selector: 'app-client-dialog',
  templateUrl: './client-dialog.component.html',
  styleUrls: ['./client-dialog.component.scss']
})
export class ClientDialogComponent implements OnInit, OnDestroy {
  private viewMode = 'client';
  dirty:boolean = false;
  editMode;
  client: Client;
  appRef;
  dialogComponentRef;
  onClose = new Subject();
  swipeCoord: number[] = [0, 0];
  swipeTime = 0;
  public tabs = [
    {id: 1},
    {id: 2},
    {id: 3},
    {id: 4},
    {id: 5},
    {id: 6},
    {id: 7},
  ];
  selectedTab = 0;

  constructor(private sweetAlertService: SweetAlertService,  private clientService: ClientMgmService,) {
  }

  ngOnInit() {
    this.clientService.formDirtyChanged.subscribe((isDirty: boolean) => {
      if (isDirty) {
       this.dirty = isDirty;
      }
    });
  }


  closeForm() {
    this.onClose.next(this.client);
    this.destroyFormSave();
  }

  close() {
    this.destroyFormSave();
  }

  destroyFormSave() {
          this.appRef.detachView(this.dialogComponentRef.hostView);
          this.dialogComponentRef.destroy();
  }

  destroyForm() {
    if(this.dirty){
     this.sweetAlertService.showConfirmationAlert()
      .then((confirmation) => {
        if (confirmation) {
          this.appRef.detachView(this.dialogComponentRef.hostView);
          this.dialogComponentRef.destroy();
        }
      });}
    else{
      this.destroyFormSave();
    }
  }

  ngOnDestroy(): void {
    document.body.style.overflow = 'unset';
    document.body.style.position = 'unset';
  }

  changeView(mode: string) {
    this.viewMode = mode;
  }

  savedClient(event) {
    this.client = event;
    this.closeForm();
  }

  swipe(e: TouchEvent, when: string): void {
    // @ts-ignore
    if (e.composedPath().findIndex(u => ['TABLE', 'TBODY', 'THEAD', 'TD', 'TH', 'TR'].findIndex(v => v === u.tagName) > -1) > -1) {
      return;
    }
    const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
    const time = new Date().getTime();
    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      const tt0 = coord[0] - this.swipeCoord[0];
      const t1 = coord[1] - this.swipeCoord[1];
      const direction = [tt0, t1];
      const duration = time - this.swipeTime;
      if (duration <= 1000 //
        && Math.abs(direction[0]) > 30 // Long enough
        && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) { // Horizontal enough
        const swipe = direction[0] < 0 ? 'next' : 'previous';
        if (swipe === 'next') {
          const isFirst = this.selectedTab === 0;
          if (this.selectedTab <= 4) {
            this.selectedTab = isFirst ? 1 : this.selectedTab + 1;
          }
        } else if (swipe === 'previous') {
          const isLast = this.selectedTab === 4;
          if (this.selectedTab >= 1) {
            this.selectedTab--;
          }
        }
      }
    }
  }
}

import {LazyRequest} from '../sms-campaign-client.ts/lazy-request';

export class UpdateVersion {
  id: number;
  uuid: string;
  createdAt: string;
  updatedAt: string;
  active: boolean;
  title: string;
  content: string;
}

export class UpdateVersionTranslation {
  id: number;
  title: string;
  active: boolean;
  content: string;
  langEnum: string;
}

export class UpdateVersionPageRequest extends LazyRequest {
  createdAt: string;
  updatedAt: string;
}

import {Component, ElementRef, OnInit, Renderer2, ViewEncapsulation} from '@angular/core';

import {BackdropService} from 'src/app/core/services/backdrop.service';
import {ComponenRegistryService} from 'src/app/core/services/component-registry.service';
import {SellPointWebNotificationService} from "../../../../shared/services/sell-point-web-notification.service";
import {WebNotificationsResponse} from "../../../../shared/models/web-notifications/web-notifications-response";
import {Observable} from "rxjs";
import {finalize} from "rxjs/operators";
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-quick-sidebar',
  templateUrl: './quick-sidebar.component.html',
  styleUrls: ['./quick-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuickSidebarComponent implements OnInit {
  opened: boolean;
  arr = new Array(10).fill(0, 0, 10);
  OneSignal = window['OneSignal'] || [];
  subscribe = 'QUICK_SIDEBAR.SUBSCRIBE_TO_PUSH_NOTIFICATIONS';
  unsubscribe = 'QUICK_SIDEBAR.UNSUBSCRIBE_FROM_PUSH_NOTIFICATIONS';
  isOptedOut;
  isPushEnabled;
  loading = false;
  notifications$: Observable<{ totalCount: number, notifications: WebNotificationsResponse[] }>;
  notifications: WebNotificationsResponse[] = [];
  lang = 'it';
  pageSize = 12;
  page = 0;
  loadingNotifications;

  constructor(
    private _el: ElementRef,
    private renderer: Renderer2,
    private _backdropService: BackdropService,
    private _componenRegistryService: ComponenRegistryService,
    private webNotificationService: SellPointWebNotificationService,
    private translateService: TranslateService
  ) {
  }

  async ngOnInit() {
    this.lang = this.translateService.currentLang ? this.translateService.currentLang : 'it';
    this.translateService.onLangChange.subscribe((ev: LangChangeEvent) => {
      this.lang = ev.lang ? ev.lang : 'it';
    });
    // Register the component in registry
    this._componenRegistryService.register('quick-sidebar', this);
    this._backdropService.close.subscribe(() => {
      if (this.opened) {
        this._hideSidebar();
      }
    });
    this.OneSignal.push(() => {
      this.updatePushParameters();
      this.OneSignal.on('subscriptionChange', (isSubscribed) => {
        this.updatePushParameters();
        this.loading = false;
        console.log('The user\'s subscription state is now:', isSubscribed);
        this.OneSignal.getUserId().then((userId) => {
          if (isSubscribed) {
            this.webNotificationService.push(userId).subscribe();
          }
        });
      });
    });
    this.OneSignal.push(["getNotificationPermission", (permission) => {
      if(permission) {
        this.OneSignal.setSubscription(true);
      }
    }]);
    this.isPushEnabled = await this.OneSignal.isPushNotificationsEnabled();
    this.isOptedOut = await this.OneSignal.isOptedOut();
    this.lang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe((ev: LangChangeEvent) => {
      this.lang = ev.lang;
    });
  }

  toggleOpen() {
    if (this.opened) {
      this.close();
    } else {
      this.open();
    }
  }

  open() {
    document.body.style.overflow = 'hidden';
    this._backdropService.show();
    this.opened = true;
    this.renderer.addClass(this._el.nativeElement, 'shined');
    document.getElementById('quick-sidebar').classList.add('shined');
    this.page = 0;
    this.notifications = [];
    this.loadNotifications();
  }

  loadNotifications() {
    this.loadingNotifications = true;
    this.webNotificationService
      .get({page: this.page, pageSize: this.pageSize})
      .pipe(finalize(() => this.loadingNotifications = false))
      .subscribe(d => {
        this.notifications = [...this.notifications, ...d.notifications];
      });
  }

  close() {
    this._backdropService.hide();
    this._hideSidebar();
  }

  _hideSidebar() {
    document.body.style.overflow = 'unset';
    this.opened = false;
    this.renderer.removeClass(this._el.nativeElement, 'shined');
    document.getElementById('quick-sidebar').classList.remove('shined');
  }

  subscribeToPush() {
    this.loading = true;
    this.getSubscriptionState().then((state) => {
      if (state.isPushEnabled) {
        /* Subscribed, opt them out */
        this.OneSignal.setSubscription(false);
      } else {
        if (state.isOptedOut) {
          /* Opted out, opt them back in */
          this.OneSignal.setSubscription(true);
        } else {
          /* Unsubscribed, subscribe them */
          this.OneSignal.registerForPushNotifications();
        }
      }
    });
  }


  private getSubscriptionState() {
    return Promise.all([
      this.OneSignal.isPushNotificationsEnabled(),
      this.OneSignal.isOptedOut()
    ]).then((result) => {
      this.isPushEnabled = result[0];
      this.isOptedOut = result[1];
      return {
        isPushEnabled: this.isPushEnabled,
        isOptedOut: this.isOptedOut
      };
    });
  }

  private async updatePushParameters() {
    this.isPushEnabled = (await this.getSubscriptionState()).isPushEnabled;
    this.isOptedOut = (await this.getSubscriptionState()).isOptedOut;
  }

  onScrollDown() {
    if (!this.loadingNotifications) {
      this.page++;
      this.loadNotifications();
    }
  }

  changeNotificationStatus(no: WebNotificationsResponse, i: number) {
    this.webNotificationService.changeNotificationStatus(no.id).subscribe(() => {
      this.notifications[i].vu = true;
    });
  }
}

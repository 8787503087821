import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {   MatPaginator } from '@angular/material/paginator';
import {   MatSnackBar } from '@angular/material/snack-bar';
import {Abonnement} from '../../../../shared/models/Abonnement';
import {debounceTime} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {BreadcrumbService} from '../../../../core/services/breadcrumb.service';
import {CustomSnackBarComponent} from '../../../../shared/custom-snack-bar/custom-snack-bar.component';
import {SubscriptionFormService} from './services/subscription-form.service';
import {SubscriptionService} from '../../../../shared/services/subscription.service';
import {SweetAlertService} from '../../../../shared/services/sweet-alert.service';
import {ActionState} from '../../../../shared/enum/action-state.enum';
import {BehaviorSubject, Subject} from 'rxjs';
import {SubscriptionType} from '../../../../shared/enum/subscription-type.enum';

@Component({
  selector: 'app-subscription-management',
  templateUrl: './subscription-management.component.html',
  styleUrls: ['./subscription-management.component.scss']
})
export class SubscriptionManagementComponent implements OnInit {

  columns = [
    'SUBSCRIPTION_FORM.NAME',
    'SUBSCRIPTION_FORM.STARTS_AT',
    'SUBSCRIPTION_FORM.ENDS_AT',
    'DATA_TABLE.SALES',
    'SUBSCRIPTION_FORM.PRICE'
  ];
  public page = 1;
  public totalRecords: number;
  public pageSize = 10;
  loading = true;
  firstCall = true;
  orderType = 'DESC';
  rows: Abonnement[] = [];
  textSearch = null;
  searchFormControl = new UntypedFormControl(null);
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('subscriptionFormContainer', {read: ViewContainerRef ,static: false}) subscriptionFormContainer: ViewContainerRef;


  constructor(private translate: TranslateService,
              public snackBar: MatSnackBar,
              private breadcrumbService: BreadcrumbService,
              private subscriptionService: SubscriptionService,
              private subscriptionFormService: SubscriptionFormService,
              private sweetAlertService: SweetAlertService) {
  }

  ngOnInit() {
    this.sendBreadCrumb();
    this.getLazySubscription({page: this.page, pageSize: this.pageSize, type: SubscriptionType.GENERIC});
    this.searchFormControl.valueChanges.pipe(debounceTime(500)).subscribe(s => {
      this.textSearch = s;
      this.getLazySubscription({page: 1, pageSize: this.pageSize, textSearch: s, type: SubscriptionType.GENERIC});
    });
  }

  sendBreadCrumb(): void {
    this.breadcrumbService.sendBreadcrumb(['PRODUCT_AND_SERVICES', 'SUBSCRIPTIONS']);
  }

  getLazySubscription(request, snackBarConf?) {
    this.loading = true;
    return this.subscriptionService.getLazySubscriptionList(request).subscribe(data => {
      this.rows = data.data;
      this.loading = false;
      this.firstCall = false;
      this.totalRecords = data.totalRecords;
      if (snackBarConf) {
        this.showSnackBar(snackBarConf);
      }
    });
  }

  showSnackBar(data: any) {
    this.snackBar.openFromComponent(CustomSnackBarComponent, {
      data,
      duration: 5000,
      panelClass: 'white-snackbar'
    });
  }

  addNewSubscription() {
    const formRef = this.subscriptionFormService.openForm(this.subscriptionFormContainer, {
      actionState: new BehaviorSubject<ActionState>(ActionState.ADD)
    });
    // formRef.instance.actionState = new BehaviorSubject<ActionState>(ActionState.ADD);
    formRef.instance.onClose.subscribe(d => {
      if (d) {
        this.getLazySubscription({page: this.page, pageSize: this.pageSize, textSearch: this.textSearch, type: SubscriptionType.GENERIC}, {
          text: `${d} `,
          actionIcon: 'save',
          actionMsg: this.translate.instant('DIALOG.ADD_SUCCESS')
        });
      }
    });
  }

  pageChange(page: number) {
    this.page = page;
    this.getLazySubscription({page, pageSize: this.pageSize, textSearch: this.textSearch, type: SubscriptionType.GENERIC});
  }

  editSubscription(data, i) {
    const formRef = this.subscriptionFormService.openForm(this.subscriptionFormContainer,{
      subscription : data,
      actionState : new BehaviorSubject<ActionState>(ActionState.DISABLE_EDIT)
    });
    // formRef.instance.subscription = data;
    // formRef.instance.actionState = new BehaviorSubject<ActionState>(ActionState.DISABLE_EDIT);
    formRef.instance.onClose.subscribe( (d: any)  => {
      if (d) {
        this.getLazySubscription({page: this.page, pageSize: this.pageSize, textSearch: this.textSearch, type: SubscriptionType.GENERIC}, {
          text: `${d} `,
          actionIcon: 'save',
          actionMsg: this.translate.instant('DIALOG.UPDATE_SUCCESS')
        });
      }
    });
  }

  deleteSubscription($event, subscription: Abonnement) {
    $event.cancelBubble = true;
    $event.stopPropagation();
    this.sweetAlertService.warning(this.translate.instant('DIALOG.YOU_WANT_TO_DELETE') + ' ' + subscription.description)
      .then(res => {
        if (res.value) {
          this.subscriptionService.delete(subscription.id).subscribe(d => {
            // tslint:disable-next-line: max-line-length
            this.getLazySubscription({page: this.page, pageSize: this.pageSize, textSearch: this.textSearch, type: SubscriptionType.GENERIC}, {
              text: `${subscription.description}`,
              actionIcon: 'check',
              actionMsg: this.translate.instant('DIALOG.DELETE_SUCCESS')
            });
          }, err => {
            if (err.status === 500) {
              this.showSnackBar({
                text: `${subscription.description}`,
                actionIcon: 'failed',
                actionMsg: this.translate.instant('DIALOG.CANNOT_DELETE')
              });
            }
          });
        }
      });
  }

  sortRows(c: string) {
    const sort = c.substr(18);
    console.log(sort);
    if (this.orderType === 'ASC') {
      this.orderType = 'DESC';
    } else {
      this.orderType = 'ASC';
    }
    switch (sort) {
      case 'NAME':
        this.getLazySubscription({page: this.page, pageSize: this.pageSize, textSearch: this.textSearch, type: SubscriptionType.GENERIC, sort: {
          attribute: 'description',
          direction: this.orderType
        }});
        break;
      case 'STARTS_AT':
        this.getLazySubscription({page: this.page, pageSize: this.pageSize, textSearch: this.textSearch, type: SubscriptionType.GENERIC, sort: {
          attribute: 'saleDurationStartsAt',
          direction: this.orderType
        }});
        break;
      case 'ENDS_AT':
        this.getLazySubscription({page: this.page, pageSize: this.pageSize, textSearch: this.textSearch, type: SubscriptionType.GENERIC, sort: {
          attribute: 'saleDurationEndsAt',
          direction: this.orderType
        }});
        break;
      case 'PRICE':
        this.getLazySubscription({page: this.page, pageSize: this.pageSize, textSearch: this.textSearch, type: SubscriptionType.GENERIC, sort: {
          attribute: 'price',
          direction: this.orderType
        }});
        break;
    }
  }

}

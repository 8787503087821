import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Anamnesi} from '../models/anamnesi/anamnesi.model';

@Injectable({
  providedIn: 'root'
})
export class AnamnesiService {

  private API = environment.api + '/anamnesi';

  constructor(private http: HttpClient) {
  }

  loadAnamnesiForm() {
    return this.http.get<Anamnesi>(`${this.API}/load-form`);
  }

  getClientConfig(id, anamnesiId) {
    return this.http.get<Anamnesi>(`${this.API}/${anamnesiId}/client/${id}`);
  }


  saveAnamnesi(request) {
    return this.http.post(this.API, request);
  }

  getAnamnesiList(clientId) {
    return this.http.get<any>(`${this.API}/client/${clientId}`);
  }

  getPreviousAnamnesi(clientId, anamnesiId?) {
    return this.http.get<any>(`${this.API}/previous${anamnesiId === undefined ? '' : '/' + anamnesiId}/client/${clientId}`);
  }

  delete(id: number) {
    return this.http.delete<void>(`${this.API}/${id}`);
  }

  generateAnamnesiQuote(request) {
    return this.http.post(`${this.API}/quote`, request, {responseType: 'arraybuffer'});
  }

  sendQuoteToClientEmail(request) {
    return this.http.post(`${this.API}/quote-mail`, request);
  }
}

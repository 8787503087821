import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Client} from '../../../../shared/models/client';
import {ReportStatics} from '../../../../shared/models/report-statics';
import {ReportsMgmService} from '../../../../shared/services/reports-mgm.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ClientMgmService} from '../../../../shared/services/client-mgm.service';
import {Router} from '@angular/router';
import {BreadcrumbService} from '../../../../core/services/breadcrumb.service';
import {ReportPerMonthComponent} from './report-per-month/report-per-month.component';
import {Subject} from 'rxjs';
import {GenericFilterComponent} from '../../../../shared/generic-filter/generic-filter.component';

@Component({
  selector: 'app-treatment-product-reports',
  templateUrl: './treatment-product-reports.component.html',
  styleUrls: ['./treatment-product-reports.component.scss']
})
export class TreatmentProductReportsComponent implements OnInit {
  @ViewChild(GenericFilterComponent ) child: GenericFilterComponent ;

  types: any;
  page = 1;
  clients: Client[] = [];
  clientSearch$ = new Subject<string>();
  columns = [
    'STATISTIC_FORM.PRODUCT',
    'STATISTIC_FORM.CATEGORY',
    'STATISTIC_FORM.QTT_SALES',
    'STATISTIC_FORM.ALL_SALES',
    'STATISTIC_FORM.QTT_RESO',
    'STATISTIC_FORM.ALL_RESO',
    'STATISTIC_FORM.ALL_PROFIT',
  ];
  loading = true;
  firstCall = true;
  rows: ReportStatics[] = [];
  compareRows: ReportStatics[] = [];
  public totalRecords: number;
  public pageSize = 2147483647;

  type = 'PRODUCT';
  timeLaps = [];
  currentOrder = 'PRODUCT';
  orderType = 'ASC';
  disableCsv = false;
  disableExcel = false;
  compareRanges = false;
  request;
  constructor(private reportService: ReportsMgmService, private matDialog: MatDialog,
              private clientService: ClientMgmService,
              private router: Router,
              private breadcrumbService: BreadcrumbService ) {
  }

  ngOnInit() {
    this.breadcrumbService.sendBreadcrumb(['PRODUCT_REPORT']);
    if (this.router.url !== '/product_report') {
      this.type = 'TREATMENT';
      this.columns = [
        'STATISTIC_FORM.TREATMENT',
        'STATISTIC_FORM.CATEGORY',
        'STATISTIC_FORM.QTT_SALES',
        'STATISTIC_FORM.ALL_SALES',
        'STATISTIC_FORM.QTT_RESO',
        'STATISTIC_FORM.ALL_RESO',
        'STATISTIC_FORM.ALL_PROFIT',
      ];
      this.breadcrumbService.sendBreadcrumb(['TREATMENT_REPORT']);
    }
  }

  search(request: any) {
    this.request = request;
    this.compareRanges = false;
    this.loading = true;
    if (this.type === 'PRODUCT') {
      this.reportService.getProductReport(request).subscribe(data => {
        this.rows = data.data.sort((a, b) => b.salesQuantity - a.salesQuantity);
        console.log(this.rows);
        this.getRowComparePercent();
        this.loading = false;
        this.firstCall = false;
        this.totalRecords = data.totalRecords;
        this.compareRanges = request.compareRanges;
      });
      return;
    }
    this.reportService.getTreatmentReport(request).subscribe(data => {
      this.rows = data.data.sort((a, b) => b.salesQuantity - a.salesQuantity);
      this.loading = false;
      this.getRowComparePercent();
      this.firstCall = false;
      this.compareRanges = request.compareRanges;
      this.totalRecords = data.totalRecords;

    });
   }

  getRowComparePercent() {
    this.rows.forEach(r => {
      r.comparePerSlValue = this.getPercent(r.salesValue, r.compareSlValue);
      r.comparePerSlQuantity = this.getPercent(r.salesQuantity, r.compareSlQuantity);
      r.comparePerResoValue = this.getPercent(r.resoValue, r.compareResoValue);
      r.comparePerResoQuantity = this.getPercent(r.resoQuantity, r.compareResoQuantity);
      r.comparePerProfitValue = this.getPercent(r.profitValue, r.compareProfitValue);
    });
  }

  pageChange(page: number) {
    this.page = page;
    this.search(this.request);
  }

  openQuantitySales(type) {
    const dialogRef = this.matDialog.open(ReportPerMonthComponent, {
      width: '1000px',
      autoFocus: true,
      disableClose: true,
      data: {data: this.rows, type}
    });
  }

   sortRows(c: string) {
    const sort = c.substr(15);
    switch (sort) {
      case 'PRODUCT':
        this.oderRowsWithDescription(sort);
        break;
      case 'TREATMENT':
        this.oderRowsWithDescription(sort);
        break;
      case 'CATEGORY':
        if (this.currentOrder === sort) {
          if (this.orderType === 'ASC') {
            this.orderType = 'DES';
            this.rows.sort((a, b) => b.categoryDescription.localeCompare(a.categoryDescription));
            break;
          }
        }
        this.currentOrder = sort;
        this.orderType = 'ASC';
        this.rows.sort((a, b) => a.categoryDescription.localeCompare(b.categoryDescription));
        break;
      case 'QTT_SALES':
        if (this.currentOrder === sort) {
          if (this.orderType === 'ASC') {
            this.orderType = 'DES';
            this.rows.sort((a, b) => b.salesQuantity - a.salesQuantity);
            break;
          }
        }
        this.currentOrder = sort;
        this.orderType = 'ASC';
        this.rows.sort((a, b) => a.salesQuantity - b.salesQuantity);
        break;
      case 'ALL_SALES':
        if (this.currentOrder === sort) {
          if (this.orderType === 'ASC') {
            this.orderType = 'DES';
            this.rows.sort((a, b) => b.salesValue - a.salesValue);
            break;
          }
        }
        this.currentOrder = sort;
        this.orderType = 'ASC';
        this.rows.sort((a, b) => a.salesValue - b.salesValue);
        break;
      case 'ALL_PROFIT':
        if (this.currentOrder === sort) {
          if (this.orderType === 'ASC') {
            this.orderType = 'DES';
            this.rows.sort((a, b) => b.profitValue - a.profitValue);
            break;
          }
        }
        this.currentOrder = sort;
        this.orderType = 'ASC';
        this.rows.sort((a, b) => a.profitValue - b.profitValue);
        break;
    }
  }

   applyFilter() {
    let dateTo = null;
    if (this.request.dateTo) {
      dateTo = new Date(this.request.dateTo);
      dateTo.setHours(dateTo.getHours() + 23);
    }
    return {
      page: this.page,
      pageSize: 1000,
      dateFrom: this.request.dateFrom,
      dateTo,
      timeLaps: this.request.timeLaps,
      categoryId: this.request.categoryId,
      clientId: this.request.clientId,
      textSearch: this.request.textSearch
    };
  }

  downloadCsv() {
    this.disableCsv = true;
    if (this.type === 'PRODUCT') {
      this.reportService.downloadProductCsv(this.applyFilter()).subscribe(r => {
        this.download(r);
        this.disableCsv = false;
      }, error => this.disableCsv = false);
      return;
    }
    this.reportService.downloadTreatmentCsv(this.applyFilter()).subscribe(r => {
      this.download(r);
      this.disableCsv = false;
    }, error => this.disableCsv = false);
  }

  downloadExcel() {
    this.disableExcel = true;
    if (this.type === 'PRODUCT') {
      this.reportService.downloadProductExcel(this.applyFilter()).subscribe(r => {
        this.download(r);
        this.disableExcel = false;
      }, error => this.disableExcel = false);
      return;
    }
    this.reportService.downloadTreatmentExcel(this.applyFilter()).subscribe(r => {
      this.download(r);
      this.disableExcel = false;
    }, error => this.disableExcel = false);
  }

  download(data) {
    const contentDisposition = data.headers.get('content-disposition');
    const fileName = contentDisposition.replace('attachment; filename=', '');
    const blob = data.body;
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.download = fileName;
    anchor.href = url;
    anchor.click();
  }

  private oderRowsWithDescription(sort) {
    if (this.currentOrder === sort) {
      if (this.orderType === 'ASC') {
        this.orderType = 'DES';
        this.rows.sort((a, b) => b.description.localeCompare(a.description));
        return;
      }
    }
    this.currentOrder = sort;
    this.orderType = 'ASC';
    this.rows.sort((a, b) => a.description.localeCompare(b.description));
    return;
  }

  getTotalQuantity() {
    return this.rows.reduce((acc, el) => acc + el.salesQuantity, 0);
  }

  getTotalCompareQuantity() {
    return this.rows.reduce((acc, el) => acc + el.compareSlQuantity, 0);
  }

  getTotalQuantityReso() {
    return this.rows.reduce((acc, el) => acc + el.resoQuantity, 0);
  }

  getCompareTotalQuantityReso() {
    return this.rows.reduce((acc, el) => acc + el.compareResoQuantity, 0);
  }

  getTotalSales() {
    return this.rows.reduce((acc, el) => acc + el.salesValue, 0);
  }

  getCompareTotalSales() {
    return this.rows.reduce((acc, el) => acc + el.compareSlValue, 0);
  }

  getTotalReso() {
    return this.rows.reduce((acc, el) => acc + el.resoValue, 0);
  }

  getCompareTotalReso() {
    return this.rows.reduce((acc, el) => acc + el.compareResoValue, 0);
  }

  getTotalProfit() {
    return this.rows.reduce((acc, el) => acc + el.profitValue, 0);
  }

  getCompareTotalProfit() {
    return this.rows.reduce((acc, el) => acc + el.compareProfitValue, 0);
  }


  getPercent(value: number, compareValue: number) {
    if (value == 0) {
      return compareValue == 0 ? 0 : 100;
    }
    if (value == compareValue) {
      return 100;
    }
    const per = (compareValue - value) / value * 100;
    return per;
    // return '( ' + per + (per < 100 ? ' <' : ' >') + ' )';
  }

}

import {Component, Input, OnInit} from '@angular/core';
import {Client} from '../../../../../shared/models/client';
import {SubscriptionService} from '../../../../../shared/services/subscription.service';
import {Abonnement} from '../../../../../shared/models/Abonnement';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
// tslint:disable-next-line:max-line-length
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
// tslint:disable-next-line:max-line-length
import {BehaviorSubject, Subject} from 'rxjs';
import {ActionState} from '../../../../../shared/enum/action-state.enum';
import {SubscriptionType} from '../../../../../shared/enum/subscription-type.enum';
import {SweetAlertService} from '../../../../../shared/services/sweet-alert.service';
import {SubscriptionInstance} from '../../../../../shared/models/subscriptionInstance';
import {SubscriptionProductEntry} from '../../../../../shared/models/subscriptionProductEntry';
import {SubscriptionTreatmentEntry} from '../../../../../shared/models/subscriptionTreatmentEntry';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-client-subscription',
  templateUrl: './client-subscription.component.html',
  styleUrls: ['./client-subscription.component.scss']
})
export class ClientSubscriptionComponent implements OnInit {

  @Input() client: Client;

  subscriptionInstancePage = 1;
  subscriptionInstancePageSize = 5;
  subscriptionInstances: SubscriptionInstance[] = [];
  public totaInstances: number;

  specificSubscriptionPage = 1;
  specificSubscriptionPageSize = 5;
  specificSubscriptions: Abonnement[] = [];
  public totalSpecificRecords: number;

  actionState: BehaviorSubject<ActionState>;
  subscription: Abonnement;
  appRef;
  dialogComponentRef;
  unsubscribe$ = new Subject();
  specificLoading = false;
  instanceLoading = false;

  entries: SubscriptionProductEntry[] | SubscriptionTreatmentEntry[];

  columns1 = [
    'SUBSCRIPTION_FORM.CREATION_DATE',
    'SUBSCRIPTION_FORM.NAME',
    'DATA_TABLE.VALID',
    'SUBSCRIPTION_FORM.PRODUCTS',
    'SUBSCRIPTION_FORM.TREATMENTS',
  ];

  columns2 = [
    'SUBSCRIPTION_FORM.NAME',
    'SUBSCRIPTION_FORM.STARTS_AT',
    'SUBSCRIPTION_FORM.ENDS_AT',
    'DATA_TABLE.SALES',
    'SUBSCRIPTION_FORM.PRICE',
    ''
  ];

  columns3 = [
    'SUBSCRIPTION_FORM.DESCRIPTION',
    'SUBSCRIPTION_FORM.QT',
    'SUBSCRIPTION_FORM.OMMAGIO',
    'SUBSCRIPTION_FORM.QT_REST',
    'SUBSCRIPTION_FORM.OMMAGIO_REST'
  ];

  columnstest = [
    'DATA_TABLE.DESCRIPTION',
    'DATA_TABLE.DATE',
    'DATA_TABLE.QUANTITY',
    'DATA_TABLE.CONSUMED',
    'DATA_TABLE.REMAINING_QUANTITY',
  ];

  constructor(private matDialog: MatDialog,
              private subscriptionService: SubscriptionService,
              private modalService: NgbModal, private translate: TranslateService,
              private sweetAlertService: SweetAlertService) {
  }

  ngOnInit() {
    this.getLazySpecificSubscription();
    this.getSubscriptionInstances();
  }

  getSubscriptionInstances() {
    this.instanceLoading = true;
    this.subscriptionService.getSubscriptionInstances({
      page: this.subscriptionInstancePage,
      pageSize: this.subscriptionInstancePageSize,
      clientId: this.client.id,
    }).subscribe(d => {
      this.subscriptionInstances = d.data;
      this.totaInstances = d.totalRecords;
      this.instanceLoading = false;
    });
  }

  getLazySpecificSubscription() {
    this.specificLoading = true;
    this.subscriptionService.getLazySubscriptionList({
      page: this.specificSubscriptionPage,
      pageSize: this.specificSubscriptionPageSize,
      type: SubscriptionType.SPECIFIC,
      clientId: this.client.id
    }).subscribe(d => {
      this.specificSubscriptions = d.data;
      this.totalSpecificRecords = d.totalRecords;
      this.specificLoading = false;
    });
  }

  genericPageChange(page: number) {
    this.subscriptionInstancePage = page;
    this.getSubscriptionInstances();
  }

  specificPageChange(page: number) {
    this.specificSubscriptionPage = page;
    this.getLazySpecificSubscription();
  }

  open(content) {
    this.actionState = new BehaviorSubject<ActionState>(ActionState.ADD);
    this.modalService.open(content, {
      size: 'lg',
      backdrop: 'static'
    }).result.then(() => {
      this.subscription = undefined;
    }, () => {
      this.subscription = undefined;
    });
  }

  save() {
    this.actionState.next(ActionState.SAVE);
  }

  activateEdit() {
    this.actionState.next(ActionState.ENABLE_EDIT);
  }

  updateSubscriptions($event) {
    this.modalService.dismissAll();
    this.specificSubscriptionPage = 1;
    this.specificSubscriptions = [];
    this.getLazySpecificSubscription();
  }

  editSubscription(data, i, content) {
    this.subscription = data;
    this.actionState = new BehaviorSubject<ActionState>(ActionState.DISABLE_EDIT);
    this.modalService.open(content, {
      size: 'lg',
      backdrop: 'static'
    }).result.then(() => {
      this.subscription = undefined;
    }, () => {
      this.subscription = undefined;
    });
  }

  deleteSubscription($event, subscription: Abonnement) {
    $event.cancelBubble = true;
    $event.stopPropagation();
    this.sweetAlertService.warning(this.translate.instant('DIALOG.DELETE_SUBSCRIPTION'))
      .then(res => {
        if (res.value) {
          this.subscriptionService.delete(subscription.id).subscribe(d => {
            this.specificSubscriptionPage = 1;
            this.specificSubscriptions = [];
            this.getLazySpecificSubscription();
          }, err => {
            if (err.status === 500) {
              this.sweetAlertService.danger(this.translate.instant('DIALOG.CANNOT_DELETE'));
            }
          });
        }
      });
  }


  openList(content, list: any[]) {
    this.entries = [...list].sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
    this.modalService.open(content, {
      size: 'lg',
      backdrop: 'static'
      }).result.then(() => {
      this.entries = [];
    }, () => {
      this.entries = [];
    });
  }


}

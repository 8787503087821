import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {SweetAlertService} from '../../../../../shared/services/sweet-alert.service';
import {TranslateService} from '@ngx-translate/core';
import {  MatSnackBar} from '@angular/material/snack-bar';
import {ProductMgmService} from '../../../../../shared/services/product-mgm.service';
import {BreadcrumbService} from '../../../../../core/services/breadcrumb.service';
import {catchError, debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import {ProductStock} from '../../../../../shared/models/product-stock';
import {MovementMgmService} from '../../../../../shared/services/movement-mgm.service';
import {Inventory} from '../../../../../shared/models/Inventory';
import * as FileSaver from 'file-saver';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {Product} from '../../../../../shared/models/product';
import {BarCodeMgmService} from '../../../../../shared/services/bar-code-mgm.service';
import {Provider} from '../../../../../shared/models/provider';
import {Brand} from '../../../../../shared/models/brand';
import {ProviderMgmService} from '../../../../../shared/services/provider-mgm.service';
import {ConfigurationsService} from '../../../../../shared/services/configurations.service';
import {SessionStorageService} from 'ngx-webstorage';
import {SellPointService} from '../../../../../shared/services/sell-point.service';
import moment from 'moment';

const MAX_NUMBER = 2147483646;

@Component({
  selector: 'app-inventory-management-form',
  templateUrl: './inventory-management-form.component.html',
  styleUrls: ['./inventory-management-form.component.scss']
})
export class InventoryManagementFormComponent implements OnInit {
  data;
  inventory: Inventory;
  appRef;
  dialogComponentRef;
  onClose = new Subject();
  editMode;
  editClicked = false;
  submitted = false;
  rows: ProductStock[] = [];
  unsubscribe$ = new Subject();
  productSearchList: Product[] = [];
  newCode = '';
  dialogRef: any;
  editProductMode = false;
  editProductClicked = false;
  @ViewChild('prodTable') private prodTable: ElementRef;
  disableToZero = false;
  columns = [
    '',
    'PRODUCT_FORM.PROD_CODE',
    'PRODUCT_FORM.BAR_CODE',
    'PRODUCT_FORM.DESCRIPTION',
    // 'PRODUCT_FORM.MIN_STOCK',
    'PRODUCT_FORM.MEASURE_UNIT',
    'PRODUCT_FORM.STOCK',
    'PRODUCT_FORM.NEW_STOCK',
    // 'PRODUCT_FORM.ACTIVE',
    // 'PRODUCT_FORM.CATEGORY',
    // 'PRODUCT_FORM.CATEGORY_REQUIRED',
  ];
  public page = 1;
  public totalRecords: number;
  public pageSize = 10;
  loading = true;
  firstCall = true;

  productsStock: ProductStock[] = [];
  searchFormControl = new UntypedFormControl(null);
  note = '';
  disableSave = false;

  disableDownload = false;
  loadingInventoryPdf = false;
  updated = false;
  loadAllPdts;
  inventoryForm: UntypedFormGroup;
  searchedProduct: Product = new Product();
  productQtt = 1;
  productList: Product[];
  productCode = null;
  productToReplace: any;
  searchFormControl1: UntypedFormGroup;
  searchFormControl2: UntypedFormGroup;
  saveEventsSubject: Subject<void> = new Subject<void>();
  editEventsSubject: Subject<void> = new Subject<void>();
  product: Product;
  editSelectedProd = false;
  productColumns = [];
  providers: Provider[] = [];
  brands: Brand[] = [];
  providersPage = 1;
  brandPage = 1;
  providerSearchList: Provider[] = [];
  sellPoints = [];
  sellPointId;
  isScroll = false;
  showBody = false;
  date;

  constructor(private translate: TranslateService,
              public snackBar: MatSnackBar, private barcodeService: BarCodeMgmService,
              private productService: ProductMgmService, private configurationsService: ConfigurationsService,
              private breadcrumbService: BreadcrumbService, private providerService: ProviderMgmService,
              private sweetAlertService: SweetAlertService, private matDialog: MatDialog,
              private movementService: MovementMgmService, private formBuilder: UntypedFormBuilder,
              private sessionStorage: SessionStorageService, private sellPointService: SellPointService
  ) {
  }

  ngOnInit() {
    this.getSellPointsFromAccount();
    this.resetSearchForm2();
    this.productsStock = [];
    if (this.editMode && this.loadAllPdts) {
      this.productsStock = this.inventory.products;
      this.note = this.inventory.note;
      this.sellPointId = this.inventory.sellPointId;
      this.showBody = true;
    }


    // if (this.loadAllPdts) {
    //   this.getLazyProduct({page: this.page, pageSize: this.pageSize});
    //   this.searchFormControl.valueChanges.pipe(debounceTime(500)).subscribe(s => {
    //     this.getLazyProduct({page: 1, pageSize: this.pageSize, textSearch: s});
    //   });
    // }

    this.productColumns = ['MOVEMENT_FORM.CODE', 'PRODUCT_FORM.DESCRIPTION', 'PRODUCT_FORM.BRAND',
      'PRODUCT_FORM.PROVIDER'];

    this.inventoryForm = new UntypedFormGroup({
      totalQuantity: new UntypedFormControl(null),
      searchedProduct: new UntypedFormControl(null),
      productQtt: new UntypedFormControl(null),
      productPurchasePrice: new UntypedFormControl(null),
      productPrice: new UntypedFormControl(null),
      products: this.formBuilder.array([])
    });
    this.getProviders();
    this.getBrands();


    if (this.editMode && !this.loadAllPdts) {
      this.sellPointId = this.inventory.sellPointId;
      this.note = this.inventory.note;
      this.inventoryForm.patchValue(this.inventory, {emitEvent: false});
      // this.inventoryForm.get('sellPoint').setValue(this.inventory.sellPointId);
      this.inventory.products.forEach(p => {
        const control = this.formBuilder.group(p);
        this.productsForm.insert(this.inventory.products.indexOf(p), control);
      });
      this.productsStock = [...this.inventory.products];
      this.inventoryForm.disable();
      if (!this.inventory.draft) {
        this.columns = [
          '',
          'PRODUCT_FORM.PROD_CODE',
          'PRODUCT_FORM.BAR_CODE',
          'PRODUCT_FORM.DESCRIPTION',
          // 'PRODUCT_FORM.MIN_STOCK',
          'PRODUCT_FORM.MEASURE_UNIT',
          'PRODUCT_FORM.OLD_STOCK',
          'PRODUCT_FORM.NEW_STOCK',
          // 'PRODUCT_FORM.ACTIVE',
          // 'PRODUCT_FORM.CATEGORY',
          // 'PRODUCT_FORM.CATEGORY_REQUIRED',
        ];
      }
      this.showBody = true;
    }

  }

  getSellPointsFromAccount() {
    this.sellPointService.getSellPointsByAccountByCentre().subscribe(res => {
      this.sellPoints = res.sellPoints;
      if (this.sellPointId) {
        this.sellPointSelected();
      }
    });
  }

  getLazyProduct(request) {
    if (this.loadAllPdts) {
      this.loading = true;
      this.movementService.getLazyProductStockList(request).subscribe(data => {
        this.rows = data.data;
        if (this.isScroll) {
          this.pageSize = this.pageSize + 10;
        }
        this.loading = false;
        this.firstCall = false;
        this.totalRecords = data.totalRecords;
        if (this.productsStock.length) {
          this.rows.forEach(p => {
            const index = this.productsStock.findIndex(u => u.productId === p.productId);
            if (index !== -1) {
              p.newStock = this.productsStock[index].newStock;
              p.oldStock = this.productsStock[index].oldStock;
              if (this.editMode) {
                p.movementType = this.productsStock[index].movementType;
              }
            }
          });
        }
      });
    } else {
      const rq = {
        page: 1,
        pageSize: 100,
        textSearch: this.searchFormControl2.get('textSearch').value,
        sellPointId: this.sellPointId,
      };
      this.productService.getLazyProductList(rq).subscribe(r => {
        r.data.forEach(r => {
          this.addSpecificProductToList(r, true);
          console.log(this.productsForm.controls.length);
        });
      });
    }
  }


  pageChange(page) {
    this.page = page;
    this.getLazyProduct({page, pageSize: this.pageSize});
  }

  activateEdit() {
    if (this.editMode) {
      this.editClicked = true;
      // this.movementForm.get('date').setValue(new Date());
      // this.movementForm.get('clientProviderId').enable();
      this.inventoryForm.enable();
      // this.enableAddProduct();
      // if (this.movement.draft) {
      //   this.movementForm.get('clientProviderId').enable();
      // }
      if (!this.loadAllPdts) {
        this.prodTable.nativeElement.scrollTop = this.prodTable.nativeElement.scrollHeight;
        this.focusInInput('searchedProduct');
      }
    }

  }

  save(draft: boolean) {
    if (!this.sellPointId) {
      this.sweetAlertService.notification(this.translate.instant('DIALOG.INSERT_ALL_MANDATORY_INFORMATION')).then(e => {
      });
      return;
    }
    if (!this.loadAllPdts) {
      this.productsStock = [];
      this.productsForm.controls.forEach(c => {
        if (c.value.newStock != null) {
          this.productsStock.push(c.value);
        }
      });
    }

    if (!this.productsStock.length) {
      this.sweetAlertService.warning(this.translate.instant('DIALOG.YOU_MUST_ADD_PRODUCT'))
        .then(res => {
        });
      return;
    }
    const request = {
      id: this.inventory ? this.inventory.id : null,
      draft,
      products: this.productsStock,
      note: this.note,
      sellPointId: this.sellPointId,
      date: this.date ?
        moment(this.date).format('YYYY-MM-DDTHH:mm:ss') : null
    };
    this.disableSave = true;
    if (!request.id) {
      this.movementService.createInventory(request).subscribe(r => {
        this.onClose.next(true);
        this.closeForm();
      }, error => this.disableSave = false);
    } else {
      this.movementService.updateInventory(request).subscribe(r => {
        this.onClose.next(true);
        this.closeForm();
      }, error => this.disableSave = false);
    }
  }


  closeForm() {
    if (this.updated && !this.dialogComponentRef.instance.disableSave) {
      this.sweetAlertService.warning(
        this.translate.instant('PLANNING.CONFIRM_CANCELLATION')).then(e => {
        if (e.value) {
          this.destroyForm();
        }
      });
    } else {
      this.destroyForm();
    }
  }

  destroyForm() {
    this.updated = false;

     this.sweetAlertService.showConfirmationAlert()
      .then((confirmation) => {
        if (confirmation) {
        this.appRef.detachView(this.dialogComponentRef.hostView);
        this.dialogComponentRef.destroy();
        }
      });

  }

  destroyFormSave() {
          this.appRef.detachView(this.dialogComponentRef.hostView);
          this.dialogComponentRef.destroy();
  }

  changeStock(product: ProductStock) {
    const index = this.productsStock.findIndex(u => u.productId === product.productId);
    if (index !== -1) {
      this.productsStock[index].newStock = product.newStock;
      this.updated = true;
      if (product.newStock === null) {
        this.productsStock.splice(index, 1);
      }
      return;
    }
    if (product.newStock === product.stock || product.newStock === null) {
      return;
    }
    this.productsStock = [...this.productsStock, product];
  }


  getInventoryExcel(type) {
    this.disableDownload = true;
    this.movementService.getInventoryExcel(this.inventory.id, type).subscribe(r => {
      const blob: Blob = new Blob([r], {type: 'text/csv'});
      const fileName = type === 'excel' ? 'Inventario.xlsx' : 'Inventario.csv';
      const objectUrl = URL.createObjectURL(blob);
      const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
      a.href = objectUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(objectUrl);
      this.disableDownload = false;
    }, error => this.disableDownload = false);
  }


  inventoryPdf() {
    this.loadingInventoryPdf = true;
    this.movementService.getInventoryPdf(this.inventory.id).subscribe(d => {
      const blob = new Blob([d], {type: 'application/pdf'});
      FileSaver.saveAs(blob, `Inventario.pdf`);
      this.loadingInventoryPdf = false;
    }, error => {
      this.sweetAlertService.error('Errore durante la generazione del preventivo');
      this.loadingInventoryPdf = false;
    });
  }

  get productsForm() {
    return this.inventoryForm.get('products') as UntypedFormArray;
  }


  searchProduct(addProductContent) {
    this.newCode = this.inventoryForm.get('searchedProduct').value;
    this.searchedProduct = this.inventoryForm.get('searchedProduct').value;
    if (!this.searchedProduct.id) {
      if (this.newCode && this.newCode.trim().length) {
        console.log(this.newCode);
        this.scanEAN(this.newCode, addProductContent);
      }
      return;
    }
    const index = this.productsForm.value.findIndex(u => u.productId === this.searchedProduct.id);
    if (index !== -1) {
      this.sweetAlertService.warning(this.translate.instant('PRODUCT_FORM.PROD_ALREADY_EXIST')).then(u => {
        if (!u.value) {
          this.resetSearch();
          return;
        }
        this.focusInInput('productQtt');
      });
    }
    this.focusInInput('productQtt');
  }


  openSearchProduct(content, product) {
    this.productToReplace = product;
    this.resetSearchForm();
    const dialogRef = this.matDialog.open(content, {
      width: '1200px',
      autoFocus: true,
      disableClose: true,
      height: '85vh'
    });
  }


  resetSearchForm() {
    this.searchFormControl1 = new UntypedFormGroup({
      textSearch: new UntypedFormControl(null),
      providerId: new UntypedFormControl(null),
      brandId: new UntypedFormControl(null),
      date: new UntypedFormControl(this.date)
    });
    this.filter();
  }

  filter() {
    this.page = 1;
    this.filterProducts();
  }

  filterProducts() {
    const request = {
      page: this.page,
      pageSize: 10,
      providerId: this.searchFormControl1.get('providerId').value,
      brandId: this.searchFormControl1.get('brandId').value,
      textSearch: this.searchFormControl1.get('textSearch').value,
      sellPointId: this.sellPointId,
      date: this.searchFormControl1.get('date').value,
    };
    this.loading = true;
    this.productService.getLazyProductList(request).subscribe(r => {
      this.productSearchList = r.data;
      this.loading = false;
    });
  }

  resetSearchForm2() {
    this.searchFormControl2 = new UntypedFormGroup({
      textSearch: new UntypedFormControl(null),
    });
    if (this.loadAllPdts) {
      this.filterLazyProd();
    }
  }

  filterLazyProd() {
    this.page = 1;
    this.getLazyProductsForFilter();
  }

  getLazyProductsForFilter() {
    this.getLazyProduct({
      page: this.page,
      pageSize: this.pageSize,
      textSearch: this.searchFormControl2.get('textSearch').value,
      sellPointId: this.sellPointId
    });
  }

  focusInInput(inputName) {
    const inp = document.getElementsByName(inputName);
    if (inp && inp.length) {
      inp[0].focus();
    }
  }


  editRow(product, fromTab?) {
    if (this.productsStock.findIndex(u => u.id === product.id) > -1) {
      this.productsStock[this.productsStock.findIndex(u => u.id === product.id)].newStock = product.value.newStock;
      return;
    }
    if (!fromTab) {
      this.prodTable.nativeElement.scrollTop = this.prodTable.nativeElement.scrollHeight;
      this.focusInInput('searchedProduct');
    }
  }


  @HostListener('document:keydown.escape')
  onEscapeBtnClick() {
    if (this.searchedProduct && this.searchedProduct.id) {
      this.resetSearch();
    }
  }

  @HostListener('document:keydown.tab')
  onTabBtnClick($event) {
    // @ts-ignore
    if (event.srcElement.name) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  checkSelectedProduct() {
    if (!this.searchedProduct.id) {
      this.focusInInput('searchedProduct');
    }
  }


  confirmProductRow() {
    if (!this.searchedProduct || !this.searchedProduct.id) {
      this.focusInInput('searchedProduct');
      return;
    }
    this.addProductToList();
  }

  addSpecificProductToList(product: Product, withQtt?) {
    const productForm = {
      id: null,
      productId: product.id,
      seq: product.seq,
      barCode: product.barCode ? product.barCode : '',
      prodCode: product.prodCode ? product.prodCode : '',
      description: product.description,
      iva: product.ivaValue,
      newStock: withQtt ? null : this.productQtt,
      purchaseCost: product.purchasePrice,
      costPrice: product.purchasePrice,
      price: product.price,
      measureUnit: product.measureUnit,
      stock: product.stock,
      oldStock: product.stock,
      minStock: product.minStock,
      active: product.activeDetailSale,
      category: product.category ? product.category.name : '',
      subCategory: product.subCategory ? product.subCategory.name : ''
    };
    if (withQtt) {
      this.resetSearch();
    }
    this.productsForm.insert(this.productsForm.length, this.formBuilder.group(productForm));
    this.prodTable.nativeElement.scrollTop = this.prodTable.nativeElement.scrollHeight;
  }

  addProductToList() {
    this.addSpecificProductToList({...this.searchedProduct});
    this.resetSearch();
    this.prodTable.nativeElement.scrollTop = this.prodTable.nativeElement.scrollHeight;
  }


  searchProd(term) {
    if (term === '' || term.length < 2) {
      this.productList = [];
      return of([]);
    }
    return this.productService.searchProduct({
      code: term, sellPointId: this.sellPointId, date: this.date ?
        moment(this.date).format('YYYY-MM-DDTHH:mm:ss') : null
    }).pipe(
      map((response: Product[]) => {
        this.productList = response;
        return response;
      })
    );
  }

  search = (text$: Observable<Product>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(term =>
        this.searchProd(term).pipe(
          catchError(() => {
            return of([]);
          }))
      )
    );
  formatter = (result: Product) => (result.prodCode ? result.prodCode : (result.barCode ? result.barCode : result.description));

  scanEAN(ean, content?) {
    console.log(ean);
    if (ean === null || (ean.toString()).trim() === '') {
      return;
    }
    this.productService.findByBarCodeSpAndDate({
      code: ean, sellPointId: this.sellPointId, date: this.date ?
        moment(this.date).format('YYYY-MM-DDTHH:mm:ss') : null
    }).subscribe(r => {
      if (r) {
        this.searchedProduct = r;
        return;
      } else {
        this.barcodeService.findProductIdByBarCodeAndCenterId(ean).subscribe(id => {
          if (id) {
            this.productService.getProductBySpAndDate({
              id, sellPointId: this.sellPointId, date: this.date ? moment(this.date).format('YYYY-MM-DDTHH:mm:ss') : null
            }).subscribe(p => {
              if (p) {
                this.searchedProduct = p;
                this.focusInInput('productQtt');
                return;
              }
              if (content) {
                this.openCreateNewProdAlert(content);
              }
            });
            return;
          }
          if (content) {
            this.openCreateNewProdAlert(content);
          }
        }, error => {
          this.sweetAlertService.danger(this.translate.instant('DIALOG.INTERNAL_SERVER_ERROR'));
        });
      }

    });
  }

  openCreateNewProdAlert(addProductContent) {
    this.sweetAlertService.warning(this.translate.instant('PRODUCT_FORM.PRODUCT_NOT_FOUND_ADD_IT')).then(u => {
      if (u.value) {
        this.addNewProduct(addProductContent, this.newCode.trim());
        return;
      } else {
        this.productCode = '';
        this.inventoryForm.get('searchedProduct').setValue('');
        this.focusInInput('productQtt');
      }
    });
  }

  addNewProduct(content, withCode?) {
    this.editProductMode = false;
    this.newCode = withCode;
    this.dialogRef = this.matDialog.open(content, {
      width: '1300px',
      autoFocus: true,
      disableClose: true,
      height: '90vh'
    });
  }

  resetSearch() {
    this.inventoryForm.get('searchedProduct').setValue('');
    this.searchedProduct = new Product();
    this.productQtt = 1;
    this.productCode = '';
    this.focusInInput('searchedProduct');
  }

  saveNewProduct() {
    this.saveEventsSubject.next(void 0);
  }

  activateProductEdit() {
    if (this.editProductMode) {
      this.editProductClicked = true;
      this.editEventsSubject.next(void 0);
    }
  }

  savedEvent(product: Product, addProductContent?) {
    this.disableSave = false;
    if (this.editProductMode) {
      if (this.editSelectedProd) {
        this.updateSelectedProducts(product);
      } else {
        this.filterProducts();
      }
      this.dialogRef.close();
    } else {
      this.productCode = product.prodCode;
      this.inventoryForm.get('searchedProduct').setValue(product);
      if (product) {
        this.matDialog.closeAll();
        this.searchProduct(addProductContent);
        setTimeout(() => {
          this.searchedProduct = product;
          this.focusInInput('productQtt');
        }, 650);
      }

    }

  }

  updateSelectedProducts(product: Product) {
    this.productsForm.controls.forEach(c => {
      if (c.get('productId').value === product.id) {
        c.get('prodCode').setValue(product.prodCode ? product.prodCode : '');
        c.get('barCode').setValue(product.barCode ? product.barCode : '');
        c.get('description').setValue(product.description);
        c.get('iva').setValue(product.ivaValue);
      }
    });
  }

  editProduct(product, content) {
    this.product = product;
    this.editProductMode = true;
    this.dialogRef = this.matDialog.open(content, {
      width: '1300px',
      autoFocus: true,
      disableClose: true,
      height: '90vh'
    });
    this.editEventsSubject.next(void 0);
    this.editProductClicked = false;

  }

  editSelectedProduct(product, content) {
    this.editSelectedProd = false;
    this.editProductMode = true;
    this.editProductClicked = false;
    this.productService.getProduct(product.value.productId).subscribe(r => {
      if (r) {
        this.editSelectedProd = true;
        this.editProduct(r, content);
      }
    });
  }

  selectProduct(product: Product, addProductContent) {
    if (this.productToReplace) {
      const control = this.productsForm.controls.filter(u => u.value.id === this.productToReplace.value.id).shift();
      if (control) {
        control.get('productId').setValue(product.id);
        control.get('prodCode').setValue(product.prodCode ? product.prodCode : '');
        control.get('barCode').setValue(product.barCode ? product.barCode : '');
        control.get('description').setValue(product.description);
        control.get('active').setValue(product.activeDetailSale);
        control.get('measureUnit').setValue(product.measureUnit);
        control.get('oldStock').setValue(product.stock);
        this.editRow(control);
        this.matDialog.closeAll();
        return;
      }
    }

    this.matDialog.closeAll();
    this.productCode = product.prodCode;
    this.inventoryForm.get('searchedProduct').setValue(product);
    this.searchProduct(addProductContent);
    setTimeout(() => {
      this.searchedProduct = product;
      this.focusInInput('productQtt');
    }, 650);
  }

  getProviders() {
    this.providerService.getLazyProviderList({page: this.providersPage, pageSize: MAX_NUMBER, visible: true}).subscribe(d => {
      this.providers = [...this.providers, ...d.data];
      this.providersPage++;
    });
  }


  getBrands() {
    this.configurationsService.getLazyBrands({page: this.brandPage, pageSize: MAX_NUMBER}).subscribe(d => {
      this.brands = [...this.brands, ...d.data];
      this.brandPage++;
    });
  }


  onScroll($event) {
    if (this.loadAllPdts && this.editMode && this.totalRecords > this.rows.length) {
      this.isScroll = true;
      this.getLazyProduct({page: this.page, pageSize: (this.pageSize + 10)});
    }
  }

  stockToZero() {
    this.sweetAlertService.warning(this.translate.instant('MOVEMENT_FORM.CONFIRM_STOCK_ZERO'))
      .then(e => {
        if (e.value) {
          this.disableToZero = true;
          this.movementService.stockToZero().subscribe(r => {
            this.sweetAlertService.success(this.translate.instant('DIALOG.UPDATE_SUCCESS'));
            this.disableToZero = false;
            this.closeForm();
          });
        }
      });
  }

  quantityToZero() {
    if (this.loadAllPdts) {
      this.rows.forEach(p => {
        if (p.active) {
          p.newStock = 0;
          this.changeStock(p);
        }
      });
    } else {
      this.productsForm.controls.forEach(p => {
        p.get('newStock').setValue(0);
        this.editRow(p, true);
      });
    }
  }

  sellPointSelected() {
    if (this.loadAllPdts) {
      this.getLazyProduct({page: this.page, pageSize: this.pageSize, sellPointId: this.sellPointId, date: this.date});
      this.showBody = true;
      return;
    }
    this.showBody = true;
  }


  delete($event: MouseEvent, product) {
    this.productsForm.value.splice(this.productsForm.value.indexOf(product), 1);
    this.productsForm.controls.splice(this.productsForm.controls.indexOf(product), 1);
    this.productsForm.controls.forEach(p => {
      p.get('seq').setValue(this.productsForm.controls.indexOf(p) + 1);
    });
  }
}

import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {AppRoutingModule} from './app-routing.module';

import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeEn from '@angular/common/locales/en';

import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface} from 'ngx-om-perfect-scrollbar';

import {SplashScreenService} from './core/services/splash-screen.service';

import {AppComponent} from './app.component';
import {BackdropComponent} from './core/components/backdrop/backdrop.component';

import {ThemeConfigService} from './core/services/theme-config.service';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {AdminLayoutModule} from './administrative-content/layout/admin-layout.module';
import {NXT_DATE_TIME_FORMATS, DateTimeModule, DateTimeIntl} from 'nxt-pick-datetime';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {AuthInterceptor} from './shared/interceptor/auth.interceptor';
import {AuthExpiredInterceptor} from './shared/interceptor/auth-expired.interceptor';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {FidelityModule} from './content/pages/configurations/fidelity/fidelity.module';
import {PaymentHistoryComponent} from './content/pages/payment/payment-history/payment-history.component';
import {NgbDropdownModule, NgbPaginationModule, NgbPopoverModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from './shared/shared.module';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CategoryProductModule} from './content/pages/configurations/category-product/category-product.module';
import {LineaModule} from './content/pages/configurations/linea/linea.module';
import {CategoryTreatmentModule} from './content/pages/configurations/category-treatment/category-treatment.module';
import {ClientManagementModule} from './content/pages/contacts/client-management/client-management.module';
import {Error401Component} from './content/pages/error-401/error401.component';
import {SmsHistoryModule} from './content/pages/sms/sms-history/sms-history.module';
import {SmsCompaignModule} from './content/pages/sms/sms-compaign/sms-compaign.module';
import {SmsConfigurationModule} from './content/pages/sms/sms-configuration/sms-configuration.module';
import {StockManagementModule} from './content/pages/stock-and-movement/stock-management/stock-management.module';
import {DashboardModule} from './content/pages/dashboard/dashboard.module';
import {registerLocaleData} from '@angular/common';
import {QuoteComponent} from './content/pages/payment/quote/quote.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {
  WhatsappConfigurationComponent
} from './content/pages/Whatsapp/whatsapp-configuration/whatsapp-configuration.component';
import {PickerModule} from '@ctrl/ngx-emoji-mart';
import {PreferredContactModule} from "./content/pages/configurations/preferred-contact/preferred-contact.module";
import {ProfessionModule} from "./content/pages/configurations/profession/profession.module";
// import {CompanyManagementModule} from "./content/pages/contacts/company-management/company-management.module";
import {HowDidYouMeetUsModule} from "./content/pages/configurations/how-did-you-meet-us/how-did-you-meet-us.module";
import {FontColorPipe} from "font-color-pipe";
import {PrivacyComponent} from './privacy/privacy.component';
import {MarketingComponent} from "./marketing/marketing.component";
import {ConditionsComponent} from "./conditions/conditions.component";
import {MatIconModule} from "@angular/material/icon";
import {
  ProductManagementModule
} from './content/pages/productsAndServices/product-management/product-management.module';
// @ts-ignore
import {LoadingBarModule} from '@ngx-loading-bar/core';
import {NgChartsModule} from 'ng2-charts';
import {TooltipModule} from "@swimlane/ngx-charts";
import {DefaultIntl} from './content/pages/sms/sms-compaign/default-intl';
import {NativeDateTimeModule} from "nxt-pick-datetime/native-adapter";
import {MatLegacyDialog, MatLegacyDialogModule} from "@angular/material/legacy-dialog";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  // suppressScrollX: true
};
// create our cost var with the information about the format that we want
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};
export const MY_NATIVE_FORMATS = {
  fullPickerInput: {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'},
  datePickerInput: {year: 'numeric', month: 'numeric', day: 'numeric'},
  timePickerInput: {hour: '2-digit', minute: '2-digit', hourCycle: 'h24'},
  monthYearLabel: {year: 'numeric', month: 'short'},
  dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
  monthYearA11yLabel: {year: 'numeric', month: 'long'},
};


@NgModule({
  declarations: [
    AppComponent,
    BackdropComponent,
    PaymentHistoryComponent,
    Error401Component,
    QuoteComponent,
    WhatsappConfigurationComponent,
    PrivacyComponent,
    MarketingComponent,
    ConditionsComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    HttpClientModule,
    MatLegacyDialogModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    TranslateModule.forRoot(),
    NgxWebstorageModule.forRoot({prefix: 'vanity', separator: '-'}),
    NativeDateTimeModule,
    AdminLayoutModule,
    FidelityModule,
    NgbPaginationModule,
    SharedModule,
    MatButtonModule,
    CategoryProductModule,
    LineaModule,
    CategoryTreatmentModule,
    ClientManagementModule,
    MatProgressSpinnerModule,
    SmsHistoryModule,
    SmsCompaignModule,
    SmsConfigurationModule,
    StockManagementModule,
    ProductManagementModule,
    DashboardModule,
    MatSnackBarModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    NgbTooltipModule,
    DateTimeModule,
    PickerModule,
    NgbPopoverModule,
    NgbDropdownModule,
    PreferredContactModule,
    ProfessionModule,
    HowDidYouMeetUsModule,
    MatTooltipModule,
    TooltipModule,
    MatIconModule,
    LoadingBarModule,
    NgChartsModule,
  ],
  providers: [
    ThemeConfigService,
    SplashScreenService,
    FontColorPipe,
    {provide: NXT_DATE_TIME_FORMATS, useValue: MY_NATIVE_FORMATS},
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'it-IT'
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    }, {
      provide: MAT_DATE_FORMATS,
      useValue: MY_FORMATS
    },
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: {useUTC: true}
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthExpiredInterceptor,
      multi: true
    },
    {provide: DateTimeIntl, useClass: DefaultIntl},
    MatLegacyDialog
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    registerLocaleData(localeFr, 'fr');
    registerLocaleData(localeIt, 'it');
    registerLocaleData(localeEn, 'en');
  }
}



// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.time-input {
  width: 4rem;
  text-align: center;
  border: aliceblue;
}

.checkbox {
  display: inline-block;
}

.month-content {
  /*content: '';*/
  /*display: block;*/
  /*width: 5px;*/
  /*background: #1a2027;*/
  background: aliceblue;
  /*margin: 20px !important;*/
}

.calendar .fc-content .fc-view-month table tbody tr td.fc-widget-content {
  border: #fff solid 2px;
  background: #f5f5f5;
  margin: 3px 3px;
  padding: 10px;
}

.dropdown-toggle::after {
  display: none;
}

.table thead th, .table thead td {
  min-width: 0 !important;
  white-space: nowrap;
  padding-right: 3px;
}

@media (max-width: 767.98px) {
  .table thead th, .table thead td {
    padding-right: 3px;
  }
  .mat-dialog-content {
    padding: 0 15px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/year-planning/year-planning.component.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,kBAAA;EACA,iBAAA;AAAF;;AAGA;EACE,qBAAA;AAAF;;AAGA;EACE,eAAA;EACA,kBAAA;EACA,cAAA;EACA,uBAAA;EACA,qBAAA;EACA,2BAAA;AAAF;;AAEA;EACE,sBAAA;EACA,mBAAA;EACA,eAAA;EACA,aAAA;AACF;;AAEA;EACE,aAAA;AACF;;AACA;EACE,uBAAA;EACA,mBAAA;EACA,kBAAA;AAEF;;AACA;EACE;IACE,kBAAA;EAEF;EACA;IACE,0BAAA;EACF;AACF","sourcesContent":["\r\n.time-input {\r\n  width: 4rem;\r\n  text-align: center;\r\n  border: aliceblue;\r\n}\r\n\r\n.checkbox {\r\n  display: inline-block\r\n}\r\n\r\n.month-content {\r\n  /*content: '';*/\r\n  /*display: block;*/\r\n  /*width: 5px;*/\r\n  /*background: #1a2027;*/\r\n  background: aliceblue;\r\n  /*margin: 20px !important;*/\r\n}\r\n.calendar .fc-content .fc-view-month table tbody tr td.fc-widget-content {\r\n  border: #fff solid 2px;\r\n  background: #f5f5f5;\r\n  margin: 3px 3px;\r\n  padding: 10px;\r\n}\r\n\r\n.dropdown-toggle::after {\r\n  display:none;\r\n}\r\n.table thead th, .table thead td {\r\n  min-width: 0 !important;\r\n  white-space: nowrap;\r\n  padding-right: 3px;\r\n}\r\n\r\n@media (max-width: 767.98px){\r\n  .table thead th, .table thead td {\r\n    padding-right: 3px;\r\n  }\r\n\r\n  .mat-dialog-content {\r\n    padding: 0 15px !important;\r\n  }\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

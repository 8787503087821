import {RtDriverService} from './../../../../shared/services/RtDriver/rt-driver.service';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {RtService} from '../../../../shared/services/rt.service';
import {ConfigRtDto, DepartmentRtDto, HeaderRtDto, OperatorRtDto, PaymentRtDto, VatRtDto} from '../../../../shared/dto/config-rt-dto';
import {  MatDialog,   MatDialogRef} from '@angular/material/dialog';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {SweetAlertService} from '../../../../shared/services/sweet-alert.service';
import {  MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {IvaRtRepartoConfigComponent} from './iva-rt-reparto-config/iva-rt-reparto-config.component';
import {PaymentRtPagamentoConfigComponent} from './payment-rt-pagamento-config/payment-rt-pagamento-config.component';
import {finalize, takeUntil} from 'rxjs/operators';
import {forkJoin, Subject} from 'rxjs';
import {BreadcrumbService} from '../../../../core/services/breadcrumb.service';
import {PaymentService} from '../../../../shared/services/payment.service';
import {environment} from '../../../../../environments/environment';
import {SellPointService} from '../../../../shared/services/sell-point.service';
import {Router} from '@angular/router';
import {AdvanceType} from '../../../../shared/enum/advance-type';
import {RtXmlVersion} from '../../../../shared/enum/rt-xml-version';
import moment from 'moment';
import {RtModel} from '../../../../shared/enum/rt-model';
import {RtDriverMapperService} from 'src/app/shared/services/RtDriver/rt-driver-mapper.service';

@Component({
  selector: 'app-rt',
  templateUrl: './rt.component.html',
  styleUrls: ['./rt.component.scss']
})
export class RtComponent implements OnInit, OnDestroy {
  @ViewChild('content', { static: false }) rtContent: any;
  ip = '';
  rtModel: RtModel[] = [
    RtModel.RCH, RtModel.EPSON, RtModel.RCH_NATIVE
  ];
  selectedRTModel: RtModel;
  disable = false;
  departmentColumn = [
    'DATA_TABLE.SEQUENCE',
    'DATA_TABLE.DESCRIPTION',
    'DATA_TABLE.PRICE',
    'DATA_TABLE.HALO',
    'DATA_TABLE.LALO',
    'DATA_TABLE.VAT',
  ];
  vatColumn = ['DATA_TABLE.SEQUENCE',
    'DATA_TABLE.VAT'];
  paymentColumn = ['DATA_TABLE.SEQUENCE',
    'DATA_TABLE.DESCRIPTION',
    'DATA_TABLE.CASH',
    'DATA_TABLE.CREDIT',
    'DATA_TABLE.CHANGE',
    'DATA_TABLE.DRAWER'];
  operatorColumn = ['DATA_TABLE.SEQUENCE',
    'DATA_TABLE.NAME',
    ''];
  headerColumn = ['DATA_TABLE.SEQUENCE',
    'DATA_TABLE.DESCRIPTION',
    ''];
  configColumn = [
    'DATA_TABLE.IP',
    'DATA_TABLE.CREATION_DATE',
    'DATA_TABLE.MODEL',
    'DATA_TABLE.STATUS',
    'PAYMENT.RT_REFERENCE',
    'DATA_TABLE.FIRMWARE',
    'DATA_TABLE.DEMO_RT',
    '',
    '',
  ];
  rtConfigList: ConfigRtDto[] = [];
  rtConfig: ConfigRtDto;
  selectedDepartment: DepartmentRtDto;
  departmentForm: UntypedFormGroup;
  editModal: MatDialogRef<unknown, any>;
  rtModal: MatDialogRef<unknown, any>;
  operationsModal: MatDialogRef<unknown, any>;
  vatForm: UntypedFormGroup;
  selectedVat: VatRtDto;
  operatorForm: UntypedFormGroup;
  selectedOperator: OperatorRtDto;
  headerForm: UntypedFormGroup;
  selectedHeader: HeaderRtDto;
  paymentForm: UntypedFormGroup;
  selectedPayment: PaymentRtDto;
  loading = true;
  reset = null;
  receipt = null;
  smallLoading = false;
  payWithoutPrint = false;
  hasDark: boolean;
  rtWindow: Window;
  usePrintingPopup = new UntypedFormControl(false);

  private unsubscribe = new Subject();
  advanceTypes = [];
  selectedRt: ConfigRtDto;
  xmlVersions = [];
  startLotteryDate: UntypedFormControl;
  actualXmlVersion: string;
  private demoRt: boolean;
  private registerNumber: string;

  constructor(private rtService: RtService, private rtDriverService: RtDriverService, private rtDriverMapper: RtDriverMapperService,
              private modalService: MatDialog, private translate: TranslateService,
              private matDialog: MatDialog, private sellPointService: SellPointService,
              private breadcrumbService: BreadcrumbService, private paymentService: PaymentService,
              private sweetAlertService: SweetAlertService, private snackBar: MatSnackBar, private router: Router) {
  }

  ngOnInit() {
    this.rtService.getSalesWithoutPrint().subscribe((r: boolean) => {
      this.payWithoutPrint = r;
    });
    this.paymentService.hasDark().subscribe((r: boolean) => {
      this.hasDark = r;
    });
    this.breadcrumbService.sendBreadcrumb(['CONFIGURATION', 'RT']);
    this.initForms();
    this.getConfigList();
    this.togglePrintingPopupState();
    this.translateAdvanceTypes();
    this.translateXmlVersions();
  }

  get isUsingPopup() {
    return this.usePrintingPopup.value;
  }

  async getConfigList() {
    this.rtService.getAllConfigs().subscribe((r: ConfigRtDto[]) => {
      this.loading = false;
      this.rtConfigList = r;
      // this.rtConfigList.forEach(u => {
      //   u.demoRt = u.rtModel === "RCH_NATIVE" && this.rtService.isDemoRt(u.ip);
      //   if (!u.registerNumber) {
      //     this.rtService.getReferenceCommand().subscribe(mc => {
      //       this.rtService.updateRt(u.ip, mc.cmd).subscribe(resp => {
      //         this.rtService.formatMatricule({xml: resp.body, demo: u.demoRt}).subscribe(m => {
      //           u.registerNumber = m;
      //         });
      //       });
      //     })
      //   }
      // })
      const activeRT = r.filter(u => u.active)[0];
      this.sellPointService.getUsePrintingPopup().subscribe(d => {
        this.usePrintingPopup.setValue(d, {emitEvent: false});
        if (this.router.url === '/rt-conf/close') {
          if (this.selectedRTModel === RtModel.RCH_NATIVE) {
            this.rtService.getStatusCommand().subscribe(r => {
              this.rtService.updateRt(this.ip, r.cmd).subscribe(resp => {
                this.rtService.getFormattedCode(resp.body).subscribe(m => {
                  activeRT.demoPrinter = m && ((m.startsWith('i') && m[3] == '1') || (!m.startsWith('i') && m[2] == '1'));
                  console.log(activeRT);
                  this.openCassaOperation(this.rtContent, activeRT);
                }, error => {
                  this.sweetAlertService.danger(this.translate.instant('DIALOG.ERROR_IN_PRINT_CONTACT_ASSISTANCE'));
                  this.openCassaOperation(this.rtContent, activeRT);
                });
              }, error => {
                this.openCassaOperation(this.rtContent, activeRT);
              });
            }, error => this.openCassaOperation(this.rtContent, activeRT));
            return;
          } else {
            this.openCassaOperation(this.rtContent, activeRT);
          }
        }
      });
    });
  }

  initForms() {
    this.departmentForm = new UntypedFormGroup({
      description: new UntypedFormControl(),
      price: new UntypedFormControl(),
      halo: new UntypedFormControl(),
      lalo: new UntypedFormControl(),
      vat: new UntypedFormControl(),
      groupCode: new UntypedFormControl()
    });
    this.vatForm = new UntypedFormGroup({
      vat: new UntypedFormControl(),
    });

    this.operatorForm = new UntypedFormGroup({
      name: new UntypedFormControl(),
    });
    this.headerForm = new UntypedFormGroup({
      description: new UntypedFormControl(),
    });
    this.paymentForm = new UntypedFormGroup({
      description: new UntypedFormControl(),
      change: new UntypedFormControl(),
      cash: new UntypedFormControl(),
      credit: new UntypedFormControl(),
      drawer: new UntypedFormControl()
    });
  }

  getRtConf(rtConfigurationDialog) {
    /*
    if (this.rtConfigList.filter(a => a.ip.replace(' ', '') === this.ip.replace(' ', '')).shift()) {
      this.sweetAlertService.notification(this.translate.instant('RT.THE_CONFIGURATION_OF_THIS_IP_ADDRESS_IS_ALREADY_SAVED')).then(e => {
      });
      return;
    }


     */
    if (this.selectedRTModel === RtModel.RCH || this.selectedRTModel === RtModel.RCH_NATIVE) {
      this.disable = true;
      if (this.isUsingPopup) {
        const cmd = '<?xml version="1.0" encoding="UTF-8"?> <Service> <cmd>&lt;&lt;/?C</cmd> </Service>';
        this.rtWindow = window.open(
          `${environment.printingUrl}?cmd=${encodeURIComponent(cmd)}&ip=${this.ip}&lang=${this.translate.currentLang}`,
          '_blank',
          // tslint:disable-next-line:max-line-length
          `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no,width=700,height=500,left=${(screen.width - 700) / 2},top=${(screen.height - 500) / 2}`
        );
        if (!this.rtWindow) {
          alert('enable popup to continue');
          return;
        }
        window.addEventListener('message', data => {
          const res = data.data;
          this.rtWindow.close();
          if (res.success) {
            this.rtService.mapConfigurations(res.xml).subscribe((r2: ConfigRtDto) => {
              this.rtConfig = r2;
              this.rtConfig.ip = this.ip;
              this.rtConfig.departmentRtList.map((u) => {
                u.lalo = u.lalo / 100;
                u.halo = u.halo / 100;
                u.price = u.price / 100;
                return u;
              });
              this.rtConfig.vatList.map((u) => {
                u.vat = u.vat / 100;
                return u;
              });
              this.rtModal = this.modalService.open(rtConfigurationDialog, {
                maxWidth: '98%',
              });
            });
            this.disable = false;
          } else {
            this.disable = false;
            this.sweetAlertService.warning(this.translate.instant('DIALOG.FAILED_TO_CONNECT_TO_PRINT')).then(e => {
            });
          }
        }, {once: true});
      } else {
        this.rtService.getRtConfig(this.ip).subscribe((r: any) => {
          this.rtService.mapConfigurations(r.body).subscribe((r2: ConfigRtDto) => {
            this.rtConfig = r2;
            this.rtConfig.ip = this.ip;
            if (this.rtConfig.departmentRtList) {
              this.rtConfig.departmentRtList.map((u) => {
                u.lalo = u.lalo / 100;
                u.halo = u.halo / 100;
                u.price = u.price / 100;
                return u;
              });
            }
            if (this.rtConfig.vatList) {
              this.rtConfig.vatList.map((u) => {
                u.vat = u.vat / 100;
                return u;
              });
            }
            this.rtModal = this.modalService.open(rtConfigurationDialog, {
              height: 'inherit',
              maxWidth: '98%',
            });
          });
          this.disable = false;
        }, error => {
          this.disable = false;
          this.sweetAlertService.warning(this.translate.instant('DIALOG.FAILED_TO_CONNECT_TO_PRINT')).then(e => {
          });
        });
      }
    }

    if (this.selectedRTModel === RtModel.EPSON) {
      this.disable = true;
      // rtDriverService
      this.rtDriverService.getRtConfig(this.ip, this.selectedRTModel).subscribe((r: any) => {
        this.rtConfig = this.rtDriverMapper.mapConfigurations(r);
        this.rtConfig.ip = this.ip;
        this.rtModal = this.modalService.open(rtConfigurationDialog, {
          height: 'inherit',
          maxWidth: '98%',
        });
        this.disable = false;
      }, error => {
        this.disable = false;
        this.sweetAlertService.danger(this.translate.instant('DIALOG.FAILED_TO_CONNECT_TO_PRINT')).then(e => {
        });
      });
    }

  }

  editDepartmentModal(content, department) {
    this.selectedDepartment = department;
    const f = this.departmentForm.controls;
    f.description.setValue(department.description);
    f.price.setValue(department.price);
    f.halo.setValue(department.halo);
    f.lalo.setValue(department.lalo);
    f.vat.setValue(department.vatRtSequence);
    f.groupCode.setValue(department.groupCode);
    this.editModal = this.modalService.open(content);
  }

  applicateDept() {
    const f = this.departmentForm.controls;
    this.selectedDepartment.description = f.description.value;
    this.selectedDepartment.price = f.price.value;
    this.selectedDepartment.halo = f.halo.value;
    this.selectedDepartment.lalo = f.lalo.value;
    this.selectedDepartment.vatRtSequence = f.vat.value;
    this.selectedDepartment.groupCode = f.groupCode.value;
    this.editModal.close();
    if (this.isUsingPopup) {
      this.rtService.saveDepartment(this.selectedDepartment).subscribe((r: any) => {
        this.rtWindow = window.open(
          `${environment.printingUrl}?cmd=${encodeURIComponent(r.cmd)}&ip=${this.rtConfig.ip}&lang=${this.translate.currentLang}`,
          '_blank',
          // tslint:disable-next-line:max-line-length
          `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no,width=700,height=500,left=${(screen.width - 700) / 2},top=${(screen.height - 500) / 2}`
        );
        if (!this.rtWindow) {
          alert('enable popup to continue');
          return;
        }
        window.addEventListener('message', data => {
          const res = data.data;
          this.rtWindow.close();
          if (res.success) {
            this.snackBar.open(this.translate.instant('DIALOG.UPDATE_SUCCESS'), 'Ok', {duration: 5000});
          }
        }, {once: true});
      });
    } else {
      this.rtService.saveDepartment(this.selectedDepartment).subscribe((r: any) => {
        this.rtService.updateRt(this.rtConfig.ip, r).subscribe((r2: any) => {
          this.snackBar.open(this.translate.instant('DIALOG.UPDATE_SUCCESS'), 'Ok', {duration: 5000});
        });
      });
    }
  }

  checkBeforeSave() {
    if (this.selectedRTModel === RtModel.RCH_NATIVE) {
      this.rtService.getStatusCommand().subscribe(r => {
        this.rtService.updateRt(this.ip, r.cmd).subscribe(resp => {
          this.rtService.getFormattedCode(resp.body).subscribe(m => {
            console.log(m);
            // iABD000C
            this.demoRt = m && ((m.startsWith('i') && m[3] == '1') || (!m.startsWith('i') && m[2] == '1'));
            console.log(this.demoRt);
            this.rtConfig.demoPrinter = this.demoRt;
            this.rtService.getReferenceCommand().subscribe(ref => {
              this.rtService.updateRt(this.ip, ref.cmd).subscribe(resp => {
                this.rtService.formatMatricule({xml: resp.body, nativeRt: true}).subscribe(m => {
                  if (m) {
                    this.registerNumber = m;
                    this.rtConfig.registerNumber = m;
                  }
                  this.rtService.getFirmwareCommand().subscribe(fir => {
                    this.rtService.updateRt(this.ip, fir.cmd).subscribe(resp => {
                      this.rtService.getFormattedCode(resp.body).subscribe(m => {
                        console.log(m);
                        if (m) {
                          this.rtConfig.firmware = m;
                        }
                        console.log(this.registerNumber);
                        this.saveRtConfig();
                      });
                    });
                  });
                });
              });
            });


          }, error => {
            this.sweetAlertService.danger(this.translate.instant('DIALOG.ERROR_IN_PRINT_CONTACT_ASSISTANCE'));
          });
        });
      });
      return;
    }
    this.saveRtConfig();
  }

  saveRtConfig() {
    // console.log(JSON.stringify(this.rtConfig.vatList));
    this.rtConfig.ip = this.ip;
    this.rtConfig.rtModel = this.selectedRTModel;
    // this.rtConfig.creationDate = new Date();

    // this.rtConfig.xmlVersion = (new Date() >= new Date(2021, 3, 1)) ? RtXmlVersion.XML7 : RtXmlVersion.XML6;
    this.rtConfig.xmlVersion = RtXmlVersion.XML7;

    if (this.selectedRTModel === RtModel.RCH || this.selectedRTModel === RtModel.RCH_NATIVE) {
      if (this.isUsingPopup) {
        this.rtService.getFiscalCodeCommand().subscribe((r: any) => {
          this.rtWindow = window.open(
            `${environment.printingUrl}?cmd=${encodeURIComponent(r.cmd)}&ip=${this.rtConfig.ip}&lang=${this.translate.currentLang}`,
            '_blank',
            // tslint:disable-next-line:max-line-length
            `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no,width=700,height=500,left=${(screen.width - 700) / 2},top=${(screen.height - 500) / 2}`
          );
          if (!this.rtWindow) {
            alert('enable popup to continue');
            return;
          }
          window.addEventListener('message', data => {
            const res = data.data;
            this.rtWindow.close();
            if (res.success) {
              this.rtService.getFormattedCode(res.xml).subscribe((r3: any) => {
                this.rtConfig.fiscalCode = r3;
                this.rtService.saveRtConfig(this.rtConfig).subscribe(() => {
                  this.snackBar.open(this.translate.instant('DIALOG.ADD_SUCCESS'), 'Ok', {duration: 5000});
                  this.rtModal.close();
                  this.getConfigList();
                });
              }, error => this.sweetAlertService.danger(this.translate.instant('DIALOG.ERROR_IN_PRINT_CONTACT_ASSISTANCE')));
            }
          }, {once: true});
        });
      } else {
        this.rtService.getFiscalCodeCommand().subscribe((r: any) => {
          this.rtService.updateRt(this.rtConfig.ip, r.cmd).subscribe(r2 => {
            this.rtService.getFormattedCode(r2.body).subscribe((r3: any) => {
              this.rtConfig.fiscalCode = r3;
              this.rtService.saveRtConfig(this.rtConfig).subscribe(() => {
                this.snackBar.open(this.translate.instant('DIALOG.ADD_SUCCESS'), 'Ok', {duration: 5000});
                this.rtModal.close();
                this.getConfigList();
              });
            }, error => this.sweetAlertService.danger(this.translate.instant('DIALOG.ERROR_IN_PRINT_CONTACT_ASSISTANCE')));
          });
        });
      }
    } else {
      this.rtDriverService.getFiscalCode(this.ip, this.selectedRTModel).subscribe((obj: any) => {
        this.rtConfig.fiscalCode = obj.matricola;

        this.rtService.saveRtConfig(this.rtConfig).subscribe(() => {
          this.snackBar.open(this.translate.instant('DIALOG.ADD_SUCCESS'), 'Ok', {duration: 5000});
          this.rtModal.close();
          this.getConfigList();
        });
      }, error => {
        this.disable = false;
        this.sweetAlertService.danger(this.translate.instant('DIALOG.FAILED_TO_CONNECT_TO_PRINT')).then(e => {
        });
      });
    }
  }

  openEditRtConfig(content, rtConfig) {
    this.rtConfig = rtConfig;

    this.selectedRTModel = this.rtConfig.rtModel as RtModel;
    if (this.selectedRTModel === null) {
      this.selectedRTModel = RtModel.RCH;
    }

    this.rtConfig.departmentRtList.sort(function(a, b) {
      if (Number(a.sequence) < Number(b.sequence)) {
        return -1;
      }
      return 1;
    });
    this.rtConfig.vatList.sort(function(a, b) {
      if (Number(a.sequence) < Number(b.sequence)) {
        return -1;
      }
      return 1;
    });
    this.rtConfig.paymentRtList.sort(function(a, b) {
      if (Number(a.sequence) < Number(b.sequence)) {
        return -1;
      }
      return 1;
    });
    this.rtConfig.operatorRtList.sort(function(a, b) {
      if (Number(a.sequence) < Number(b.sequence)) {
        return -1;
      }
      return 1;
    });
    // console.log(this.rtConfig.departmentRtList);
    this.rtModal = this.modalService.open(content, {
      maxWidth: '98%',
    });
    //this.synchronizeRtConfig();
  }

  deleteRtConfig(id) {
    this.sweetAlertService.warning(this.translate.instant('DIALOG.YOU_WANT_TO_DELETE')).then(e => {
      if (e.value) {
        this.rtService.deleteConfig(id).subscribe((r: any) => {
          if (r.status === '500') {
            this.sweetAlertService.danger(this.translate.instant('RT.DELETE_ERROR'));
          } else {
            this.snackBar.open(this.translate.instant('DIALOG.DELETE_SUCCESS'), 'Ok', {duration: 5000});
            this.getConfigList();
          }
        }, error => {
          this.sweetAlertService.notification(this.translate.instant('RT.DELETE_ERROR'));
        });
      }
    });
  }

  applicateVat() {
    const f = this.vatForm.controls;
    this.selectedVat.vat = f.vat.value;
    this.editModal.close();
    if (this.isUsingPopup) {
      this.rtService.saveVat(this.selectedVat).subscribe((r: any) => {
        this.rtWindow = window.open(
          `${environment.printingUrl}?cmd=${encodeURIComponent(r.cmd)}&ip=${this.rtConfig.ip}&lang=${this.translate.currentLang}`,
          '_blank',
          // tslint:disable-next-line:max-line-length
          `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no,width=700,height=500,left=${(screen.width - 700) / 2},top=${(screen.height - 500) / 2}`
        );
        if (!this.rtWindow) {
          alert('enable popup to continue');
          return;
        }
        window.addEventListener('message', data => {
          const res = data.data;
          this.rtWindow.close();
          if (res.success) {
            this.snackBar.open(this.translate.instant('DIALOG.UPDATE_SUCCESS'), 'Ok', {duration: 5000});
          }
        }, {once: true});
      });
    } else {
      this.rtService.saveVat(this.selectedVat).subscribe((r: any) => {
        this.rtService.updateRt(this.rtConfig.ip, r).subscribe((r2: any) => {
          this.snackBar.open(this.translate.instant('DIALOG.UPDATE_SUCCESS'), 'Ok', {duration: 5000});
        });
      });
    }

  }

  editVatModal(content, o: VatRtDto) {
    this.selectedVat = o;
    const f = this.vatForm.controls;
    f.vat.setValue(o.vat);
    this.editModal = this.modalService.open(content);
  }

  applicateOperator() {
    const f = this.operatorForm.controls;
    this.selectedOperator.name = f.name.value;
    this.editModal.close();
    if (this.isUsingPopup) {
      this.rtService.saveOperator(this.selectedOperator).subscribe((r: any) => {
        this.rtWindow = window.open(
          `${environment.printingUrl}?cmd=${encodeURIComponent(r.cmd)}&ip=${this.rtConfig.ip}&lang=${this.translate.currentLang}`,
          '_blank',
          // tslint:disable-next-line:max-line-length
          `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no,width=700,height=500,left=${(screen.width - 700) / 2},top=${(screen.height - 500) / 2}`
        );
        if (!this.rtWindow) {
          alert('enable popup to continue');
          return;
        }
        window.addEventListener('message', data => {
          this.rtWindow.close();
          const res = data.data;
          if (res.success) {
            this.snackBar.open(this.translate.instant('DIALOG.UPDATE_SUCCESS'), 'Ok', {duration: 5000});
          }
        }, {once: true});

      });
    } else {
      this.rtService.saveOperator(this.selectedOperator).subscribe((r: any) => {
        /*this.rtService.updateRt(this.rtConfig.ip, r).subscribe((r2: any) => {
          this.snackBar.open(this.translate.instant('DIALOG.UPDATE_SUCCESS'), 'Ok', {duration: 5000});
        });*/
      });
    }
  }

  editOperatorModal(content, o) {
    this.selectedOperator = o;
    const f = this.operatorForm.controls;
    f.name.setValue(o.name);
    this.editModal = this.modalService.open(content);
  }

  applicateHeader() {
    const f = this.headerForm.controls;
    this.selectedHeader.description = f.description.value;
    this.editModal.close();
    if (this.isUsingPopup) {
      this.rtService.saveHeader(this.selectedHeader).subscribe((r: any) => {
        this.rtWindow = window.open(
          `${environment.printingUrl}?cmd=${encodeURIComponent(r.cmd)}&ip=${this.rtConfig.ip}&lang=${this.translate.currentLang}`,
          '_blank',
          // tslint:disable-next-line:max-line-length
          `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no,width=700,height=500,left=${(screen.width - 700) / 2},top=${(screen.height - 500) / 2}`
        );
        if (!this.rtWindow) {
          alert('enable popup to continue');
          return;
        }
        window.addEventListener('message', data => {
          const res = data.data;
          this.rtWindow.close();
          if (res.success) {
            this.snackBar.open(this.translate.instant('DIALOG.UPDATE_SUCCESS'), 'Ok', {duration: 5000});
            if (this.rtConfig.id !== undefined && this.rtConfig.id !== null) {
              this.rtService.syncHeader(this.rtConfig.header, this.rtConfig.id).subscribe((r3: any) => {
              });
            }
          }
        }, {once: true});
      });
    } else {
      this.rtService.saveHeader(this.selectedHeader).subscribe((r: any) => {
        this.rtService.updateRt(this.rtConfig.ip, r).subscribe((r2: any) => {
          this.snackBar.open(this.translate.instant('DIALOG.UPDATE_SUCCESS'), 'Ok', {duration: 5000});
          if (this.rtConfig.id !== undefined && this.rtConfig.id !== null) {
            this.rtService.syncHeader(this.rtConfig.header, this.rtConfig.id).subscribe((r3: any) => {
            });
          }
        });
      });
    }
  }

  editHeaderModal(content, o) {
    this.selectedHeader = o;
    const f = this.headerForm.controls;
    f.description.setValue(o.description);
    this.editModal = this.modalService.open(content);
  }

  applicatePayment() {
    const f = this.paymentForm.controls;
    this.selectedPayment.description = f.description.value;
    this.selectedPayment.cash = f.cash.value;
    this.selectedPayment.credit = f.credit.value;
    this.selectedPayment.change = f.change.value;
    this.selectedPayment.drawer = f.drawer.value;
    this.editModal.close();
    if (this.isUsingPopup) {
      this.rtService.savePayment(this.selectedPayment).subscribe((r: any) => {
        this.rtWindow = window.open(
          `${environment.printingUrl}?cmd=${encodeURIComponent(r.cmd)}&ip=${this.rtConfig.ip}&lang=${this.translate.currentLang}`,
          '_blank',
          // tslint:disable-next-line:max-line-length
          `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no,width=700,height=500,left=${(screen.width - 700) / 2},top=${(screen.height - 500) / 2}`
        );
        if (!this.rtWindow) {
          alert('enable popup to continue');
          return;
        }
        window.addEventListener('message', data => {
          const res = data.data;
          this.rtWindow.close();
          if (res.success) {
            this.snackBar.open(this.translate.instant('DIALOG.UPDATE_SUCCESS'), 'Ok', {duration: 5000});
          }
        }, {once: true});
      });
    } else {
      this.rtService.savePayment(this.selectedPayment).subscribe((r: any) => {
        this.rtService.updateRt(this.rtConfig.ip, r).subscribe((r2: any) => {
          this.snackBar.open(this.translate.instant('DIALOG.UPDATE_SUCCESS'), 'Ok', {duration: 5000});
        });
      });
    }
  }

  editPaymentModal(content, o) {
    this.selectedPayment = o;
    const f = this.paymentForm.controls;
    f.description.setValue(o.description);
    f.cash.setValue(o.cash);
    f.credit.setValue(o.credit);
    f.change.setValue(o.change);
    f.drawer.setValue(o.drawer);
    this.editModal = this.modalService.open(content);
  }

  buildVat(sequence) {
    const o = this.rtConfig.vatList.filter(u => u.sequence === sequence).shift();
    return (o !== undefined ? o.vat : 0) + '%';
  }

  synchronize(ip, id, model) {
    this.sweetAlertService.warning(this.translate.instant('DIALOG.YOU_WANT_TO_SYNCHRONIZE_RT')).then(e => {
      if (e.value) {
        if (model as RtModel === RtModel.RCH || model as RtModel === RtModel.RCH_NATIVE) {
          if (this.isUsingPopup) {
            const cmd = '<?xml version="1.0" encoding="UTF-8"?> <Service> <cmd>&lt;&lt;/?C</cmd> </Service>';
            this.rtWindow = window.open(
              `${environment.printingUrl}?cmd=${encodeURIComponent(cmd)}&ip=${ip}&lang=${this.translate.currentLang}`,
              '_blank',
              // tslint:disable-next-line:max-line-length
              `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no,width=700,height=500,left=${(screen.width - 700) / 2},top=${(screen.height - 500) / 2}`
            );
            if (!this.rtWindow) {
              alert('enable popup to continue');
              return;
            }
            window.addEventListener('message', data => {
              const res = data.data;
              if (res.success) {
                this.rtWindow.close();
                this.rtService.synchronizeConfig(res.xml, id).subscribe(async (r2) => {
                  await this.getConfigList();
                  this.snackBar.open(this.translate.instant('DIALOG.UPDATE_SUCCESS'), 'Ok', {duration: 5000});
                });
              }
            });

          } else {
            this.rtService.getRtConfig(ip).subscribe((r: any) => {
              this.rtService.synchronizeConfig(r.body, id).subscribe(async (r2) => {
                await this.getConfigList();
                this.snackBar.open(this.translate.instant('DIALOG.UPDATE_SUCCESS'), 'Ok', {duration: 5000});
              });
            });
          }
        }
        if (model as RtModel === RtModel.EPSON) {
          this.rtDriverService.getRtConfig(ip, model as RtModel).subscribe((r: any) => {
            console.log(r);
            const newConfig: ConfigRtDto = this.rtDriverMapper.mapConfigurations(r);
            newConfig.rtModel = RtModel.EPSON;
            this.rtDriverService.synchronizeConfig(newConfig, id).subscribe(async (r2) => {
              await this.getConfigList();
              this.snackBar.open(this.translate.instant('DIALOG.UPDATE_SUCCESS'), 'Ok', {duration: 5000});
            }, error => {
              this.disable = false;
              this.sweetAlertService.danger(this.translate.instant('DIALOG.FAILED_TO_CONNECT_TO_PRINT')).then(e => {
              });
            });
          }, error => {
            this.disable = false;
            this.sweetAlertService.danger(this.translate.instant('DIALOG.FAILED_TO_CONNECT_TO_PRINT')).then(e => {
            });
          });
        }
      }
    });
  }

  saveXmlV7Config(ip, id) {
    if (this.isUsingPopup) {
      const cmd = '<?xml version="1.0" encoding="UTF-8"?> <Service> <cmd>&lt;&lt;/?C</cmd> </Service>';
      this.rtWindow = window.open(
        `${environment.printingUrl}?cmd=${encodeURIComponent(cmd)}&ip=${ip}&lang=${this.translate.currentLang}`,
        '_blank',
        // tslint:disable-next-line:max-line-length
        `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no,width=700,height=500,left=${(screen.width - 700) / 2},top=${(screen.height - 500) / 2}`
      );
      if (!this.rtWindow) {
        alert('enable popup to continue');
        return;
      }
      window.addEventListener('message', data => {
        const res = data.data;
        if (res.success) {
          this.rtWindow.close();
          this.rtService.synchronizeConfig(res.xml, id).subscribe(async (r2) => {
            await this.getConfigList();
            this.snackBar.open(this.translate.instant('DIALOG.UPDATE_SUCCESS'), 'Ok', {duration: 5000});
          });
        }
      });

    } else {
      this.rtService.getRtConfig(ip).subscribe((r: any) => {
        this.rtService.synchronizeConfig(r.body, id).subscribe(async (r2) => {
          await this.getConfigList();
          this.snackBar.open(this.translate.instant('DIALOG.UPDATE_SUCCESS'), 'Ok', {duration: 5000});
        });
      });
    }
  }

  synchronizeRtConfig() {
    if (this.rtConfig.rtModel === RtModel.RCH) {
      if (this.isUsingPopup) {
        const cmd = '<?xml version="1.0" encoding="UTF-8"?> <Service> <cmd>&lt;&lt;/?C</cmd> </Service>';
        this.rtWindow = window.open(
          `${environment.printingUrl}?cmd=${encodeURIComponent(cmd)}&ip=${this.rtConfig.ip}&lang=${this.translate.currentLang}`,
          '_blank',
          // tslint:disable-next-line:max-line-length
          `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no,width=700,height=500,left=${(screen.width - 700) / 2},top=${(screen.height - 500) / 2}`
        );
        if (!this.rtWindow) {
          alert('enable popup to continue');
          return;
        }
        window.addEventListener('message', data => {
          const res = data.data;
          this.rtWindow.close();
          if (res.success) {
            this.rtService.mapConfigurations(res.xml).subscribe((r2: ConfigRtDto) => {
              r2.departmentRtList.map((u) => {
                u.lalo = u.lalo / 100;
                u.halo = u.halo / 100;
                u.price = u.price / 100;
                return u;
              }).map(u => {
                const conf = this.rtConfig.departmentRtList.filter(x => x.sequence === u.sequence).shift();
                if (u.description !== conf.description) {
                  conf.descriptionUpdated = u.description;
                  conf.updated = true;
                }
                if (u.price !== conf.price) {
                  conf.priceUpdated = u.price;
                  conf.updated = true;
                }
                if (u.halo !== conf.halo) {
                  conf.haloUpdated = u.halo;
                  conf.updated = true;
                }
                if (u.lalo !== conf.lalo) {
                  conf.laloUpdated = u.lalo;
                  conf.updated = true;
                }
                if (u.single !== conf.single) {
                  conf.singleUpdated = u.single;
                  conf.updated = true;
                }
                if (u.vatRtSequence !== conf.vatRtSequence) {
                  conf.vatRtSequenceUpdated = u.vatRtSequence;
                  conf.updated = true;
                }
                if (u.groupCode !== conf.groupCode) {
                  conf.groupCodeUpdated = u.groupCode;
                  conf.updated = true;
                }
              });
              r2.vatList.map((u) => {
                u.vat = u.vat / 100;
                return u;
              }).map(u => {
                const conf = this.rtConfig.vatList.filter(x => x.sequence === u.sequence).shift();
                if (u.vat !== conf.vat) {
                  conf.vatUpdated = u.vat;
                  conf.updated = true;
                }
              });
              r2.operatorRtList.map((u) => {
                const conf = this.rtConfig.operatorRtList.filter(x => x.sequence === u.sequence).shift();
                if (u.name !== conf.name) {
                  conf.nameUpdated = u.name;
                  conf.updated = true;
                }
              });
              r2.header.map((u) => {
                const conf = this.rtConfig.header.filter(x => x.sequence === u.sequence).shift();
                if (u.description !== conf.description) {
                  conf.descriptionUpdated = u.description;
                  conf.updated = true;
                }
              });
              r2.paymentRtList.map((u) => {
                const conf = this.rtConfig.paymentRtList.filter(x => x.sequence === u.sequence).shift();
                if (u.description !== conf.description) {
                  conf.descriptionUpdated = u.description;
                  conf.updated = true;
                }
                if (u.change !== conf.change) {
                  conf.changeUpdated = u.change;
                  conf.updated = true;
                }
                if (u.cash !== conf.cash) {
                  conf.cashUpdated = u.cash;
                  conf.updated = true;
                }
                if (u.credit !== conf.credit) {
                  conf.creditUpdated = u.credit;
                  conf.updated = true;
                }
                if (u.drawer !== conf.drawer) {
                  conf.drawerUpdated = u.drawer;
                  conf.updated = true;
                }
                if (u.mandatoryAmount !== conf.mandatoryAmount) {
                  conf.mandatoryAmountUpdated = u.mandatoryAmount;
                  conf.updated = true;
                }
              });
            });
          } else {

          }
        }, {once: true});
      } else {
        this.rtService.getRtConfig(this.rtConfig.ip).subscribe((r: any) => {
          this.rtService.mapConfigurations(r.body).subscribe((r2: ConfigRtDto) => {
            r2.departmentRtList.map((u) => {
              u.lalo = u.lalo / 100;
              u.halo = u.halo / 100;
              u.price = u.price / 100;
              return u;
            }).map(u => {
              const conf = this.rtConfig.departmentRtList.filter(x => x.sequence === u.sequence).shift();
              if (u.description !== conf.description) {
                conf.descriptionUpdated = u.description;
                conf.updated = true;
              }
              if (u.price !== conf.price) {
                conf.priceUpdated = u.price;
                conf.updated = true;
              }
              if (u.halo !== conf.halo) {
                conf.haloUpdated = u.halo;
                conf.updated = true;
              }
              if (u.lalo !== conf.lalo) {
                conf.laloUpdated = u.lalo;
                conf.updated = true;
              }
              if (u.single !== conf.single) {
                conf.singleUpdated = u.single;
                conf.updated = true;
              }
              if (u.vatRtSequence !== conf.vatRtSequence) {
                conf.vatRtSequenceUpdated = u.vatRtSequence;
                conf.updated = true;
              }
              if (u.groupCode !== conf.groupCode) {
                conf.groupCodeUpdated = u.groupCode;
                conf.updated = true;
              }
            });
            r2.vatList.map((u) => {
              u.vat = u.vat / 100;
              return u;
            }).map(u => {
              const conf = this.rtConfig.vatList.filter(x => x.sequence === u.sequence).shift();
              if (u.vat !== conf.vat) {
                conf.vatUpdated = u.vat;
                conf.updated = true;
              }
            });
            r2.operatorRtList.map((u) => {
              const conf = this.rtConfig.operatorRtList.filter(x => x.sequence === u.sequence).shift();
              if (u.name !== conf.name) {
                conf.nameUpdated = u.name;
                conf.updated = true;
              }
            });
            r2.header.map((u) => {
              const conf = this.rtConfig.header.filter(x => x.sequence === u.sequence).shift();
              if (u.description !== conf.description) {
                conf.descriptionUpdated = u.description;
                conf.updated = true;
              }
            });
            r2.paymentRtList.map((u) => {
              const conf = this.rtConfig.paymentRtList.filter(x => x.sequence === u.sequence).shift();
              if (u.description !== conf.description) {
                conf.descriptionUpdated = u.description;
                conf.updated = true;
              }
              if (u.change !== conf.change) {
                conf.changeUpdated = u.change;
                conf.updated = true;
              }
              if (u.cash !== conf.cash) {
                conf.cashUpdated = u.cash;
                conf.updated = true;
              }
              if (u.credit !== conf.credit) {
                conf.creditUpdated = u.credit;
                conf.updated = true;
              }
              if (u.drawer !== conf.drawer) {
                conf.drawerUpdated = u.drawer;
                conf.updated = true;
              }
              if (u.mandatoryAmount !== conf.mandatoryAmount) {
                conf.mandatoryAmountUpdated = u.mandatoryAmount;
                conf.updated = true;
              }
            });
          });
        });

      }
    } else {
      /*
      this.rtDriverService.getRtConfig(this.rtConfig.ip, this.rtConfig.rtModel as RtModel).subscribe((r: any) => {
          this.rtConfig = this.rtDriverService.mapConfigurations(r);
        });
        */

    }
  }

  checkDepartmentUpdated() {
    return this.rtConfig && this.rtConfig.departmentRtList ? this.rtConfig.departmentRtList.some(item => item.updated === true) : false;
  }

  synchronizeDeptObject(o: any) {
    o.description = o.descriptionUpdated !== undefined ? o.descriptionUpdated : o.description;
    o.price = o.priceUpdated !== undefined ? o.priceUpdated : o.price;
    o.halo = o.haloUpdated !== undefined ? o.haloUpdated : o.halo;
    o.lalo = o.laloUpdated !== undefined ? o.laloUpdated : o.lalo;
    o.single = o.singleUpdated !== undefined ? o.singleUpdated : o.single;
    o.vatRtSequence = o.vatRtSequenceUpdated !== undefined ? o.vatRtSequenceUpdated : o.vatRtSequence;
    o.groupCode = o.groupCodeUpdated !== undefined ? o.groupCodeUpdated : o.groupCode;
    o.updated = false;
    this.rtService.syncDept(o).subscribe((r: any) => {
      o = r;
      const conf = this.rtConfig.departmentRtList.filter(u => u.sequence === o.sequence).shift();
      conf.descriptionUpdated = null;
      conf.priceUpdated = null;
      conf.haloUpdated = null;
      conf.laloUpdated = null;
      conf.singleUpdated = null;
      conf.vatRtSequenceUpdated = null;
      conf.groupCodeUpdated = null;
      conf.updated = false;
    });
  }

  synchronizeAllDept() {
    this.rtConfig.departmentRtList.forEach(o => {
      if (o.updated === true) {
        o.description = o.descriptionUpdated !== undefined ? o.descriptionUpdated : o.description;
        o.price = o.priceUpdated !== undefined ? o.priceUpdated : o.price;
        o.halo = o.haloUpdated !== undefined ? o.haloUpdated : o.halo;
        o.lalo = o.laloUpdated !== undefined ? o.laloUpdated : o.lalo;
        o.single = o.singleUpdated !== undefined ? o.singleUpdated : o.single;
        o.vatRtSequence = o.vatRtSequenceUpdated !== undefined ? o.vatRtSequenceUpdated : o.vatRtSequence;
        o.groupCode = o.groupCodeUpdated !== undefined ? o.groupCodeUpdated : o.groupCode;
        o.updated = false;
        this.rtService.syncDept(o).subscribe((r: any) => {
          o = r;
          this.rtConfig.departmentRtList.forEach(u => {
            u.descriptionUpdated = null;
            u.priceUpdated = null;
            u.haloUpdated = null;
            u.laloUpdated = null;
            u.singleUpdated = null;
            u.vatRtSequenceUpdated = null;
            u.groupCodeUpdated = null;
            u.updated = false;
          });
        });
      }
    });
  }

  synchronizeVatObject(o) {
    o.vat = o.vatUpdated !== undefined ? o.vatUpdated : o.vat;
    o.updated = false;
    this.rtService.syncVat(o).subscribe((r: any) => {
      o = r;
      const conf = this.rtConfig.vatList.filter(u => u.sequence === o.sequence).shift();
      conf.vatUpdated = null;
      conf.updated = null;
    });
  }

  synchronizeAllVat() {
    this.rtConfig.vatList.forEach(o => {
      if (o.updated === true) {
        o.vat = o.vatUpdated !== undefined ? o.vatUpdated : o.vat;
        o.updated = false;
        this.rtService.syncVat(o).subscribe((r: any) => {
          o = r;
          this.rtConfig.vatList.forEach(u => {
            u.vatUpdated = null;
            u.updated = false;
          });
        });
      }
    });
  }

  checkVatUpdated() {
    return this.rtConfig && this.rtConfig.vatList ? this.rtConfig.vatList.some(item => item.updated === true) : false;
  }


  synchronizeOperatorObject(o) {
    o.name = o.nameUpdated !== undefined ? o.nameUpdated : o.name;
    o.updated = false;
    this.rtService.syncOperator(o).subscribe((r: any) => {
      o = r;
      const conf = this.rtConfig.operatorRtList.filter(u => u.sequence === o.sequence).shift();
      conf.nameUpdated = null;
      conf.updated = false;
    });
  }

  synchronizeAllOperators() {
    this.rtConfig.operatorRtList.forEach(o => {
      if (o.updated === true) {
        o.name = o.nameUpdated !== undefined ? o.nameUpdated : o.name;
        o.updated = false;
        this.rtService.syncOperator(o).subscribe((r: any) => {
          o = r;
          this.rtConfig.operatorRtList.forEach(u => {
            u.nameUpdated = null;
            u.updated = false;
          });
        });
      }
    });
  }

  checkOperatorUpdated() {
    return this.rtConfig.operatorRtList ? this.rtConfig.operatorRtList.some(item => item.updated === true) : false;
  }

  checkHeaderUpdated() {
    return this.rtConfig.header && this.rtConfig.header.some(item => item.updated === true);
  }

  synchronizeHeaderObject(o: any) {
    o.description = o.descriptionUpdated !== undefined ? o.descriptionUpdated : o.description;
    o.updated = false;
    this.rtService.syncHeader(this.rtConfig.header, this.rtConfig.id).subscribe((r: any) => {
      this.rtConfig.header = r;
    });
  }

  synchronizeAllHeaders() {
    this.rtConfig.header.forEach(o => {
      if (o.updated === true) {
        o.description = o.descriptionUpdated !== undefined ? o.descriptionUpdated : o.description;
        o.updated = false;
      }
    });
    this.rtService.syncHeader(this.rtConfig.header, this.rtConfig.id).subscribe((r: any) => {
      this.rtConfig.header = r;
    });
  }

  checkPaymentUpdated() {
    return this.rtConfig && this.rtConfig.paymentRtList ? this.rtConfig.paymentRtList.some(item => item.updated === true) : false;
  }

  synchronizePaymentObject(o: any) {
    o.description = o.descriptionUpdated !== undefined ? o.descriptionUpdated : o.description;
    o.change = o.changeUpdated !== undefined ? o.changeUpdated : o.change;
    o.cash = o.cashUpdated !== undefined ? o.cashUpdated : o.cash;
    o.credit = o.creditUpdated !== undefined ? o.creditUpdated : o.credit;
    o.drawer = o.drawerUpdated !== undefined ? o.drawerUpdated : o.drawer;
    o.mandatoryAmount = o.mandatoryAmountUpdated !== undefined ? o.mandatoryAmountUpdated : o.mandatoryAmount;
    o.updated = false;
    this.rtService.syncPayment(o).subscribe((r: any) => {
      o = r;
      const conf = this.rtConfig.paymentRtList.filter(u => u.sequence === o.sequence).shift();
      conf.descriptionUpdated = null;
      conf.changeUpdated = null;
      conf.cashUpdated = null;
      conf.creditUpdated = null;
      conf.drawerUpdated = null;
      conf.mandatoryAmountUpdated = null;
      conf.updated = false;
    });
  }

  synchronizeAllPayment() {
    this.rtConfig.paymentRtList.forEach(o => {
      if (o.updated === true) {
        o.description = o.descriptionUpdated !== undefined ? o.descriptionUpdated : o.description;
        o.change = o.changeUpdated !== undefined ? o.changeUpdated : o.change;
        o.cash = o.cashUpdated !== undefined ? o.cashUpdated : o.cash;
        o.credit = o.creditUpdated !== undefined ? o.creditUpdated : o.credit;
        o.drawer = o.drawerUpdated !== undefined ? o.drawerUpdated : o.drawer;
        o.mandatoryAmount = o.mandatoryAmountUpdated !== undefined ? o.mandatoryAmountUpdated : o.mandatoryAmount;
        o.updated = false;
        this.rtService.syncPayment(o).subscribe((r: any) => {
          o = r;
          this.rtConfig.paymentRtList.forEach(u => {
            u.descriptionUpdated = null;
            u.changeUpdated = null;
            u.cashUpdated = null;
            u.creditUpdated = null;
            u.drawerUpdated = null;
            u.mandatoryAmountUpdated = null;
            u.updated = false;
          });
        });
      }
    });
  }

  disableRtConfig(o, id) {
    this.rtService.editStatus(id).subscribe(r => {
      if (r === true) {
        this.getConfigList();
      }
    });
  }

  private buildFiscalCode(txt) {
    const segments: string[] = txt.split(' ');
    const document0: string = segments[0];
    const document1: string = segments[segments.length - 1];
    const substring: string = document1.substring(document1.length - 6);
    const desiredString: string = document1.substring(0, 2) + 'M' + document0 + substring;
    return desiredString;
  }

  openIvaDepartmentConfig(o: any) {
    const dialogRef = this.matDialog.open(IvaRtRepartoConfigComponent,
      {
        disableClose: true,
        autoFocus: true,
        width: '700px',
        data: o
      });
    dialogRef.afterClosed().subscribe(d => {
      if (d) {

      }
    });
  }

  printTest() {
    if (this.selectedRTModel === RtModel.RCH || this.selectedRTModel === RtModel.RCH_NATIVE) {
      if (this.isUsingPopup) {
        this.rtService.buildRtTestCmd().subscribe((r: any) => {
          this.rtWindow = window.open(
            `${environment.printingUrl}?cmd=${encodeURIComponent(r.cmd)}&ip=${this.rtConfig.ip}&lang=${this.translate.currentLang}`,
            '_blank',
            // tslint:disable-next-line:max-line-length
            `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no,width=700,height=500,left=${(screen.width - 700) / 2},top=${(screen.height - 500) / 2}`
          );
          if (!this.rtWindow) {
            alert('enable popup to continue');
            return;
          }
          window.addEventListener('message', data => {
            const res = data.data;
            this.rtWindow.close();
            if (res.success) {
              this.sweetAlertService.success(this.translate.instant('DIALOG.SUCCESS_PRINT_TEST'));
            }
          }, {once: true});
        });
      } else {
        this.rtService.buildRtTestCmd().subscribe((r: any) => {
          this.rtService.updateRt(this.rtConfig.ip, r.cmd).subscribe(() => {
            this.sweetAlertService.success(this.translate.instant('DIALOG.SUCCESS_PRINT_TEST'));
          });
        });
      }
    } else {
      console.log(this.selectedRTModel);
      this.rtDriverService.printTest(this.rtConfig.ip, this.selectedRTModel).subscribe(() => {
        this.sweetAlertService.success(this.translate.instant('DIALOG.SUCCESS_PRINT_TEST'));
      }, error => {
        this.disable = false;
        this.sweetAlertService.danger(this.translate.instant('DIALOG.FAILED_TO_CONNECT_TO_PRINT')).then(e => {
        });
      });
    }

  }

  openPaymentPagamentoConfig(o: ConfigRtDto) {
    const dialogRef = this.matDialog.open(PaymentRtPagamentoConfigComponent,
      {
        disableClose: true,
        autoFocus: true,
        width: '700px',
        data: o
      });
    dialogRef.afterClosed().subscribe(d => {
    });
  }

  openCassaOperation(content, activeRT) {
    this.rtConfig = activeRT;
    this.receipt = '';
    this.reset = '';
    this.smallLoading = true;
    let resetNumber;
    let receiptNumber;

    // const activeRT: ConfigRtDto = this.rtConfigList.find(rt => {
    //   return rt.active;
    // });
    console.log('**************************');
    console.log(activeRT);
    if (activeRT.rtModel === null || activeRT.rtModel === 'RCH' || activeRT.rtModel === 'RCH_NATIVE') {
      if (this.isUsingPopup) {
        forkJoin(this.rtService.getResetNumberCommand(activeRT.demoPrinter), this.rtService.getReceiptCommand()).subscribe(cmd => {
          this.rtWindow = window.open(
            `${environment.printingUrl}?cmd=${encodeURIComponent(cmd[0].cmd)}&ip=${this.rtConfig.ip}&lang=${this.translate.currentLang}`,
            '_blank',
            // tslint:disable-next-line:max-line-length
            `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no,width=700,height=500,left=${(screen.width - 700) / 2},top=${(screen.height - 500) / 2}`
          );
          if (!this.rtWindow) {
            alert('enable popup to continue');
            return;
          }
          window.addEventListener('message', data => {
            const res0 = data.data;
            if (res0.success) {
              resetNumber = res0.xml;
              this.rtWindow.postMessage({cmd: cmd[1].cmd, ip: this.rtConfig.ip}, '*');
              window.addEventListener('message', data1 => {
                const res1 = data1.data;
                this.rtWindow.close();
                if (res1.success) {
                  receiptNumber = res1.xml;
                  forkJoin(this.rtService.getFormattedCode(resetNumber), this.rtService.getFormattedCode(receiptNumber)).pipe(finalize(() => {
                  })).subscribe((res: any) => {
                    this.reset = activeRT.demoPrinter ? ('0000' + Number(res[1])).slice(-4) : ('0000' + (Number(res[1]) + 1)).slice(-4);
                    this.receipt = ('0000' + res[0]).slice(-4);
                    this.smallLoading = false;
                  }, error => this.sweetAlertService.danger(this.translate.instant('DIALOG.ERROR_IN_PRINT_CONTACT_ASSISTANCE')));
                }
              }, {once: true});
            }
          }, {once: true});
        });
      } else {
        forkJoin(this.rtService.getResetNumberCommand(activeRT.demoPrinter), this.rtService.getReceiptCommand()).subscribe(cmd => {
          this.rtService.updateRt(this.rtConfig.ip, cmd[0]).subscribe(response => {
            this.rtService.updateRt(this.rtConfig.ip, cmd[1]).subscribe(n => {
              forkJoin(this.rtService.getFormattedCode(n.body), this.rtService.getFormattedCode(response.body)).pipe(finalize(() => {
              })).subscribe((res: any) => {
                this.receipt = ('0000' + res[0]).slice(-4);
                this.reset = activeRT.demoPrinter ? ('0000' + Number(res[1])).slice(-4) : ('0000' + (Number(res[1]) + 1)).slice(-4);
                this.smallLoading = false;
              }, error => this.sweetAlertService.danger(this.translate.instant('DIALOG.ERROR_IN_PRINT_CONTACT_ASSISTANCE')));
            });
          });
        });
      }
    } else {
      this.rtDriverService.getStatus(activeRT.ip, activeRT.rtModel).subscribe(status => {
        // I CAN PRINT
        if (status.FullStatusCode === '00110' || status.FullStatusCode === '20110') {
          console.log(status);
          this.rtDriverService.getResetNumber(activeRT.ip, activeRT.rtModel).subscribe(resetNumber => {
            this.reset = resetNumber.resetNumber;
            this.rtDriverService.getInvoiceNumber(activeRT.ip, activeRT.rtModel).subscribe(invoiceNumber => {
              this.receipt = invoiceNumber.invoiceNumber;
              this.smallLoading = false;
            });

          });
        }
      });
    }


    this.operationsModal = this.modalService.open(content, {
      width: '700px'
    });
  }

  closeCash() {
    if (this.hasDark) {
      this.sweetAlertService.warning(this.translate.instant('DIALOG.YOU_WANT_TO_CLEAR_TEMPORARY_DATA')).then(e => {
        if (e.value) {
          this.paymentService.deleteDark().subscribe(() => {
            this.hasDark = false;
            this.snackBar.open(this.translate.instant('DIALOG.CLOSING_SUCCESS'), 'Ok', {duration: 5000});
          });
        }
        this.closeCase();
      });
      return;
    }
    this.closeCase();
  }

  closeCase() {
    this.sweetAlertService.warning(this.translate.instant('DIALOG.YOU_WANT_TO_CLOSE_CASH')).then(e => {
      if (e.value) {
        this.loading = true;

        const activeRT: ConfigRtDto = this.rtConfigList.find(rt => {
          return rt.active;
        });

        if (activeRT.rtModel === null || activeRT.rtModel === 'RCH' || activeRT.rtModel === 'RCH_NATIVE') {
          if (this.isUsingPopup) {
            this.rtService.closeCash().subscribe((r: any) => {
              this.rtWindow = window.open(
                `${environment.printingUrl}?cmd=${encodeURIComponent(r.cmd)}&ip=${this.rtConfig.ip}&lang=${this.translate.currentLang}`,
                '_blank',
                // tslint:disable-next-line:max-line-length
                `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no,width=700,height=500,left=${(screen.width - 700) / 2},top=${(screen.height - 500) / 2}`
              );
              if (!this.rtWindow) {
                alert('enable popup to continue');
                return;
              }
              window.addEventListener('message', data1 => {
                const res1 = data1.data;
                this.loading = false;
                this.rtWindow.close();
                if (res1.success) {
                  this.sweetAlertService.success(this.translate.instant('DIALOG.CLOSING_SUCCESS'));
                }
              }, {once: true});
            });
          } else {
            this.rtService.closeCash().subscribe((r: any) => {
              this.rtService.updateRt(this.rtConfig.ip, r.cmd).pipe(finalize(() => {
                this.loading = false;
              })).subscribe(() => {
                this.sweetAlertService.success(this.translate.instant('DIALOG.CLOSING_SUCCESS'));
              });
            });

          }
        } else {
          this.rtDriverService.getStatus(activeRT.ip, activeRT.rtModel).subscribe(status => {
            if (status.FullStatusCode === '00110' || status.FullStatusCode === '20110') {
              this.rtDriverService.ReportZ(activeRT.ip, activeRT.rtModel).subscribe(success => {
                if (success) {
                  this.loading = false;
                  this.sweetAlertService.success(this.translate.instant('DIALOG.CLOSING_SUCCESS'));
                } else {
                  this.loading = false;
                  this.sweetAlertService.danger(this.translate.instant('DIALOG.CLOSING_FAILURE'));
                }
              }, err => {
                this.loading = false;
                this.sweetAlertService.danger(this.translate.instant('DIALOG.CLOSING_FAILURE'));
              });
            } else {
              this.loading = false;
              this.sweetAlertService.danger(this.translate.instant('DIALOG.CLOSING_FAILURE'));
            }
          }, err => {
            this.loading = false;
            this.sweetAlertService.danger(this.translate.instant('DIALOG.CLOSING_FAILURE'));
          });
        }
      }
    });
  }

  readingCash() {
    this.sweetAlertService.warning(this.translate.instant('DIALOG.YOU_WANT_TO_READ_CASH')).then(e => {
      if (e.value) {
        this.loading = true;
        const activeRT: ConfigRtDto = this.rtConfigList.find(rt => {
          return rt.active;
        });

        if (activeRT.rtModel === null || activeRT.rtModel === 'RCH' || activeRT.rtModel === 'RCH_NATIVE') {
          if (this.isUsingPopup) {
            this.rtService.readingCash().subscribe((r: any) => {
              this.rtWindow = window.open(
                `${environment.printingUrl}?cmd=${encodeURIComponent(r.cmd)}&ip=${this.rtConfig.ip}&lang=${this.translate.currentLang}`,
                '_blank',
                // tslint:disable-next-line:max-line-length
                `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no,width=700,height=500,left=${(screen.width - 700) / 2},top=${(screen.height - 500) / 2}`
              );
              if (!this.rtWindow) {
                alert('enable popup to continue');
                return;
              }
              window.addEventListener('message', data1 => {
                const res1 = data1.data;
                this.rtWindow.close();
                this.loading = false;
                if (res1.success) {
                  this.sweetAlertService.success(this.translate.instant('DIALOG.READING_SUCCESS'));
                }
              }, {once: true});
            });
          } else {
            this.rtService.readingCash().subscribe((r: any) => {
              this.rtService.updateRt(this.rtConfig.ip, r.cmd).pipe(finalize(() => {
                this.loading = false;
              })).subscribe(() => {
                this.sweetAlertService.success(this.translate.instant('DIALOG.READING_SUCCESS'));
              });
            });
          }
        } else {
          this.rtDriverService.getStatus(activeRT.ip, activeRT.rtModel).subscribe(status => {
            if (status.FullStatusCode === '00110' || status.FullStatusCode === '20110') {
              this.rtDriverService.ReportX(activeRT.ip, activeRT.rtModel).subscribe(success => {
                if (success) {
                  this.loading = false;
                  this.sweetAlertService.success(this.translate.instant('DIALOG.READING_SUCCESS'));
                } else {
                  this.loading = false;
                  this.sweetAlertService.danger(this.translate.instant('DIALOG.READING_FAILURE'));
                }
              }, err => {
                this.loading = false;
                this.sweetAlertService.danger(this.translate.instant('DIALOG.READING_FAILURE'));
              });
            } else {
              this.loading = false;
              this.sweetAlertService.danger(this.translate.instant('DIALOG.READING_FAILURE'));
            }
          }, err => {
            this.loading = false;
            this.sweetAlertService.danger(this.translate.instant('DIALOG.READING_FAILURE'));
          });
        }
      }
    });
  }

  activateSalesWithoutPrint() {
    const msg = this.payWithoutPrint ? 'DIALOG.YOU_WANT_TO_DISABLE_SALES_WITHOUT_PRINT' : 'DIALOG.YOU_WANT_TO_ENABLE_SALES_WITHOUT_PRINT';
    this.sweetAlertService.warning(this.translate.instant(msg)).then(e => {
      if (e.value) {
        this.rtService.activateSalesWithoutPrint().subscribe((r: any) => {
          this.payWithoutPrint = !this.payWithoutPrint;
          this.snackBar.open(this.translate.instant('DIALOG.UPDATE_SUCCESS'), 'Ok', {duration: 5000});
        }, error => {
          this.sweetAlertService.danger(this.translate.instant('DIALOG.CANNOT_UPDATE'));
        });
      }
    });
  }

  private togglePrintingPopupState() {
    this.usePrintingPopup.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(d => {
      if (d) {
        this.sweetAlertService
          .warning(this.translate.instant('RT.ARE_YOU_SURE_POPUP'))
          .then(u => {
            if (!u.value) {
              this.usePrintingPopup.setValue(false, {emitEvent: false});
              return;
            }
            this.sellPointService.updateUsePrintingPopupForCurrentSellPoint(d).subscribe(() => {
              this.snackBar.open(this.translate.instant('RT.YOU_ARE_USING_POPUP'), '', {duration: 2000});
            });
          });
      } else {
        this.sweetAlertService.warning(this.translate.instant('RT.IF_YOU_DONT_WANT_TO_USE'))
          .then(u => {
            if (!u.value) {
              this.usePrintingPopup.setValue(true, {emitEvent: false});
              return;
            }
            this.sellPointService.updateUsePrintingPopupForCurrentSellPoint(d).subscribe(() => {
              this.snackBar.open(this.translate.instant('RT.YOU_ARE_USING_STANDARD'), 'Ok');
            });
          });
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }

  translateAdvanceTypes() {
    this.advanceTypes = [{description: this.translate.instant('RT.' + AdvanceType.DISABLED), id: AdvanceType.DISABLED},
      {description: this.translate.instant('RT.' + AdvanceType.USING_ONE_DOCUMENT), id: AdvanceType.USING_ONE_DOCUMENT},
      {description: this.translate.instant('RT.' + AdvanceType.USING_TWO_DOCUMENT), id: AdvanceType.USING_TWO_DOCUMENT}];
  }

  translateXmlVersions() {
    this.xmlVersions = [{description: this.translate.instant('RT.' + RtXmlVersion.XML6), id: RtXmlVersion.XML6},
      {description: this.translate.instant('RT.' + RtXmlVersion.XML7), id: RtXmlVersion.XML7}];
  }

  openRtConfig(configRt, o: ConfigRtDto) {
    this.selectedRt = o;
    this.actualXmlVersion = this.selectedRt.xmlVersion;
    this.startLotteryDate = new UntypedFormControl(moment(this.selectedRt.startLotteryDate));
    this.startLotteryDate.valueChanges.subscribe(val => {
      const d: Date = new Date(moment(val).format('YYYY-MM-DD'));
      this.selectedRt.startLotteryDate = d;
      o.startLotteryDate = d;
    });
    if (this.selectedRt.rtModel === RtModel.RCH_NATIVE) {
      this.rtService.getStatusCommand().subscribe(r => {
        this.rtService.updateRt(this.selectedRt.ip, r.cmd).subscribe(resp => {
          this.rtService.getFormattedCode(resp.body).subscribe(m => {
            console.log(m);
            // iABD000C
            this.selectedRt.demoPrinter = m && ((m.startsWith('i') && m[3] == '1') || (!m.startsWith('i') && m[2] == '1'));
          }, error => this.sweetAlertService.danger(this.translate.instant('DIALOG.ERROR_IN_PRINT_CONTACT_ASSISTANCE')));
        });
      });
    }
    this.operationsModal = this.modalService.open(configRt, {
      width: '1000px'
    });
    this.operationsModal.afterClosed().subscribe(c => {
        if (c) {
          this.rtService.updateRtConfig(this.selectedRt).subscribe(r => {
            o.xmlVersion = this.selectedRt.xmlVersion;

            if (o.xmlVersion === RtXmlVersion.XML7) {
              // IF XML VERSION IS 7 AND PREVIUS WAS XML v6, i need to configure payment methods.
              if (this.isUsingPopup) {
                this.rtService.getXML7Config().subscribe(r => {
                  this.rtWindow = window.open(
                    `${environment.printingUrl}?cmd=${encodeURIComponent(r)}&ip=${this.selectedRt.ip}&lang=${this.translate.currentLang}`,
                    '_blank',
                    // tslint:disable-next-line:max-line-length
                    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no,width=700,height=500,left=${(screen.width - 700) / 2},top=${(screen.height - 500) / 2}`
                  );
                  if (!this.rtWindow) {
                    alert('enable popup to continue');
                    return;
                  }
                  window.addEventListener('message', data => {
                    const res = data.data;
                    this.rtWindow.close();
                    if (res.success) {
                      this.sweetAlertService.success(this.translate.instant('DIALOG.UPDATE_SUCCESS')).then(e => {
                        this.synchronize(o.ip, o.id, o.rtModel);
                      });
                    }
                  }, {once: true});
                });
              } else {
                this.rtService.getXML7Config().subscribe(r => {
                  this.rtService.updateRt(this.selectedRt.ip, r).subscribe(() => {
                    this.synchronize(o.ip, o.id, o.rtModel);
                  });
                });
              }
            } else {
              this.sweetAlertService.success(this.translate.instant('DIALOG.UPDATE_SUCCESS'));
            }
            // o = this.selectedRt
          });
        }
      }, error =>
        this.sweetAlertService.danger(this.translate.instant('DIALOG.CANNOT_UPDATE'))
    );
  }

  changeXmlVersion() {
    if (RtXmlVersion.XML7 !== this.selectedRt.xmlVersion) {
      this.selectedRt.advanceType = null;
    }
  }

  verifyFirmware(config: ConfigRtDto) {
    this.rtService.getFirmwareCommand().subscribe(fir => {
      this.rtService.updateRt(config.ip, fir.cmd).subscribe(resp => {
        this.rtService.getFormattedCode(resp.body).subscribe(m => {
          console.log(m);
          if (m) {
            config.firmware = m;
            this.rtService.updateFirmwareAndRegister(config).subscribe(() => {
              this.getConfigList();
            });
          }
        }, error => this.sweetAlertService.danger(this.translate.instant('DIALOG.ERROR_IN_PRINT_CONTACT_ASSISTANCE')));
      }, error => this.sweetAlertService.danger(this.translate.instant('DIALOG.ERROR_IN_PRINT_CONTACT_ASSISTANCE')));
    });
  }


  verifyReference(config: ConfigRtDto) {
    this.rtService.getReferenceCommand().subscribe(ref => {
      this.rtService.updateRt(config.ip, ref.cmd).subscribe(resp => {
        this.rtService.formatMatricule({xml: resp.body, nativeRt: true}).subscribe(m => {
          console.log(m);
          if (m) {
            config.registerNumber = m;
            this.rtService.updateFirmwareAndRegister(config).subscribe(() => {
              this.getConfigList();
            });
          }
        });
      }, error => this.sweetAlertService.danger(this.translate.instant('DIALOG.ERROR_IN_PRINT_CONTACT_ASSISTANCE')));
    });
  }
}

import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {SmsHistoryComponent} from './sms-history.component';
import {Routes, RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {SubheaderModule} from 'src/app/content/layout/components/subheader/subheader.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatLegacyDialog as MatDialog, MatLegacyDialogModule} from '@angular/material/legacy-dialog';
import {  MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbPaginationModule, NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';

const routes: Routes = [
  {path: '', component: SmsHistoryComponent}
];


@NgModule({
  declarations: [
    SmsHistoryComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    SubheaderModule,
    NgxDatatableModule,
    MatTableModule,
    FormsModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    NgbPopoverModule,
    MatIconModule,
    MatButtonModule,
    MatLegacyDialogModule,
    MatTableModule,
    MatTabsModule
  ], providers: [
    DatePipe
  ]
})
export class SmsHistoryModule {
}

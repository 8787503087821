import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreferredContactComponent } from './preferred-contact.component';
import {RouterModule, Routes} from '@angular/router';
import {MatLegacyButtonModule} from '@angular/material/legacy-button';
import {TranslateModule} from '@ngx-translate/core';
import {  MatTableModule} from '@angular/material/table';
import {  MatPaginatorModule} from '@angular/material/paginator';
import {NgbPaginationModule, NgbTooltipModule, NgbTypeaheadModule} from '@ng-bootstrap/ng-bootstrap';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../../../../shared/shared.module';
import {PreferredContactFormModalComponent} from './preferred-contact-form-modal.component';
import {MatDividerModule} from '@angular/material/divider';
import {  MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatIconModule} from "@angular/material/icon";

const routes: Routes = [
  {path: '', component: PreferredContactComponent}
];
@NgModule({
    declarations: [PreferredContactComponent, PreferredContactFormModalComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatLegacyButtonModule,
        TranslateModule,
        MatTableModule,
        MatPaginatorModule,
        NgbPaginationModule,
        ReactiveFormsModule,
        SharedModule.forChild(),
        MatDividerModule,
        NgbTypeaheadModule,
        MatProgressSpinnerModule,
        NgbTooltipModule,
        MatIconModule
    ]
})
export class PreferredContactModule { }

import {AfterViewInit, Component, OnInit, TemplateRef, ViewChild, OnDestroy} from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router
} from '@angular/router';

import {TranslateService} from '@ngx-translate/core';
import {TranslationLoaderService} from './core/services/translation-loader.service';

import {SplashScreenService} from './core/services/splash-screen.service';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {BackdropService} from 'src/app/core/services/backdrop.service';

import {locale as enLang} from './config/i18n/en';
import {locale as itLang} from './config/i18n/it';
import {locale as frLang} from './config/i18n/fr';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {DateAdapter} from '@angular/material/core';
import {environment} from "../environments/environment";
import {forkJoin, fromEvent, Observable, Subscription} from "rxjs";
import {  MatSnackBar,   MatSnackBarHorizontalPosition,   MatSnackBarVerticalPosition} from "@angular/material/snack-bar";
import {finalize} from "rxjs/operators";
import {OwnerService} from "./administrative-content/pages/owner/services/owner.service";
import {FontColorPipe} from "font-color-pipe";

declare let MetisMenu: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;

  subscriptions: Subscription[] = [];

  connectionStatusMessage: string;
  connectionStatus: string;


  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  @ViewChild('contentUpdate', { static: false }) contentUpdate: TemplateRef<any>;
  seconds: number = 5;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private loader: LoadingBarService,
    private _splashScreenService: SplashScreenService,
    private _translateService: TranslateService,
    private _translationLoaderService: TranslationLoaderService,
    private _backdropService: BackdropService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private matDialogClient: MatDialog, private ownerService: OwnerService,
    private colorPipe: FontColorPipe,
  ) {
    // Set translations
    this._translateService.addLangs(['en', 'it', 'fr']);
    this._translationLoaderService.loadTranslations(enLang, itLang, frLang);
    this._translateService.setDefaultLang('it');
    this._translationLoaderService.setInitLang();
  }

  ngOnInit() {
    this.initNotifications();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // set page progress bar loading to start on NavigationStart event router
        this.loader.start();
      }
      if (event instanceof RouteConfigLoadStart) {
        this.loader.increment(35);
      }
      if (event instanceof RouteConfigLoadEnd) {
        this.loader.increment(75);
      }
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        this._backdropService.hide();
        // set page progress bar loading to end on NavigationEnd event router
        this.loader.complete();
      }
    });

    /**
     * Get the online/offline status from browser window
     */
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Back to online';
      this.connectionStatus = 'online';
      console.log('Online...');
      this.snackBar.open(
        this.translate.instant('DIALOG.Back_to_online'),
        'Ok',
        {
          duration: 5000,
          panelClass: 'green-snackbar',
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        }
      );
    }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Connection lost ! You are not connected to internet';
      this.connectionStatus = 'offline';
      console.log('Offline...');
      this.snackBar.open(
        this.translate.instant('DIALOG.Connection_lost'),
        'Ok',
        {
          duration: 5000,
          panelClass: 'red-snackbar',
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        }
      );
    }));
    this.initColors();

  }

  ngOnDestroy(): void {
    /**
     * Unsubscribe all subscriptions to avoid memory leak
     */
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
  private initNotifications() {
    const OneSignal = window['OneSignal'] || [];
    OneSignal.push(() => {
      OneSignal.init({
        appId: environment.oneSignalAppId,
        safari_web_id: 'web.onesignal.auto.47e61a84-7486-476e-83ad-85400aafc6ca',
        allowLocalhostAsSecureOrigin: true,
        welcomeNotification: {
          'title': 'GAB Software',
          'message': 'Thanks for subscribing!',
        },
        notificationClickHandlerMatch: 'origin',
        notificationClickHandlerAction: 'focus',
        autoResubscribe: true
      });
      OneSignal.getUserId().then((userId) => {
        console.log('[LAYOUT] User ID is', userId);
      });
    });
  }

  ngAfterViewInit() {
    this._splashScreenService.init();
    // if (localStorage.getItem('version') == null) {
    //   localStorage.setItem('version', environment.version);
    // } else {
    if (null != localStorage.getItem('version') && environment.version != localStorage.getItem('version')) {
      this.matDialogClient.open(this.contentUpdate);
      setInterval(() => {
        this.seconds -= 1;
      }, 1000);
      setTimeout(() => {
        localStorage.setItem('version', environment.version);
        var newUrl =
          location.href.split('?')[0] +
          '?' +
          new Date().getTime();
        location.href = newUrl;
        location.reload();
      }, 5000);
    }
    // }
  }


  initColors() {
    return new Promise<void>((resolve, reject) => {
       this.ownerService.getCurrentOwnerColors().pipe(
        finalize(() => resolve())
      ).subscribe(val => {
         console.log('value of theme colors = ', val);
        if (val) {
          document.documentElement.style.setProperty('--primary-confirm', val.successIconsColor ? val.successIconsColor : '#272b2f');
          document.documentElement.style.setProperty('--primary-cancel', val.cancelIconsColor ? val.cancelIconsColor : '#f80202');
          document.documentElement.style.setProperty('--header-color', val.headerColor ? val.headerColor : '#212529');
          document.documentElement.style.setProperty('--secondary-color', val.secondaryColor ? val.secondaryColor : '#f638a9');
          document.documentElement.style.setProperty('--checkbox-color', val.checkBoxColor ? val.checkBoxColor : '#2b333d');
          document.documentElement.style.setProperty('--radio-color', val.radioButtonColor ? val.radioButtonColor : '#2b333d');
          document.documentElement.style.setProperty('--sidebar-color', val.sideBarColor ? val.sideBarColor : '#212529');
          document.documentElement.style.setProperty('--success-icons-color', val.successIconsColor ? val.successIconsColor : '#237db3');
          document.documentElement.style.setProperty('--cancel-icons-color', val.cancelIconsColor ? val.cancelIconsColor : '#f80202');
          document.documentElement.style.setProperty('--primary-confirm-color', val.primaryColor ? this.colorPipe.transform(val.primaryColor) : '#ffffff');
          document.documentElement.style.setProperty('--primary-cancel-color', val.primaryColor ? this.colorPipe.transform(val.primaryColor) : '#ffffff');
          document.documentElement.style.setProperty('--header-font-color', val.headerColor ? this.colorPipe.transform(val.headerColor) : '#ffffff');
          document.documentElement.style.setProperty('--secondary-font-color', val.secondaryColor ? this.colorPipe.transform(val.secondaryColor) : '#ffffff');
          document.documentElement.style.setProperty('--bg-cancel-icon-color', val.cancelIconsColor ? this.colorPipe.transform(val.cancelIconsColor) : '#f80202');
          document.documentElement.style.setProperty('--bg-success-icon-color', val.successIconsColor ? this.colorPipe.transform(val.successIconsColor) : '#ffffff');
        }
      });
    });
  }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-group .active {
  background-color: var(--secondary-color) !important;
  border: none;
}
.list-group .active button {
  color: white !important;
}
.list-group .mat-icon {
  font-size: 18px !important;
}
.list-group button {
  color: #2B333D !important;
}
.list-group .list-group-item {
  cursor: pointer;
}

.col-title {
  font-size: 15px;
  font-weight: 600;
}

.active:focus {
  outline: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/administrative-content/pages/admin-module/components/center-mgm/center-mgm.component.scss"],"names":[],"mappings":"AACE;EAEE,mDAAA;EACA,YAAA;AADJ;AAEI;EACE,uBAAA;AAAN;AAGE;EACE,0BAAA;AADJ;AAGE;EACE,yBAAA;AADJ;AAGE;EACE,eAAA;AADJ;;AAKA;EACE,eAAA;EACA,gBAAA;AAFF;;AAIA;EACE,UAAA;AADF","sourcesContent":[".list-group {\r\n  .active {\r\n    //background-color: #2B333D;\r\n    background-color: var(--secondary-color)!important;\r\n    border: none;\r\n    button {\r\n      color: white !important;\r\n    }\r\n  }\r\n  .mat-icon {\r\n    font-size: 18px !important;\r\n  }\r\n  button {\r\n    color: #2B333D !important;\r\n  }\r\n  .list-group-item {\r\n    cursor: pointer;\r\n  }\r\n}\r\n\r\n.col-title {\r\n  font-size: 15px;\r\n  font-weight: 600;\r\n}\r\n.active:focus {\r\n  outline: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import {EventEmitter, Injectable} from '@angular/core';
import {Client} from '../models/client';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {LazyRequest} from '../dto/lazy-request';
import {SearchResponse} from '../dto/search-response';

@Injectable()
export class ClientMgmService {

  CLIENT_API = environment.api + '/clients';
  formDirtyChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(private http: HttpClient) {
  }

  emitFormDirtyChanged(isDirty: boolean) {
    this.formDirtyChanged.emit(isDirty);
  }

  /* Add new client */
  addNewClient(client: Client) {
    return this.http.post<Client>(`${this.CLIENT_API}`, client);
  }

  /* Edit an existing client */
  editClient(client: Client) {
    return this.http.put<Client>(`${this.CLIENT_API}`, client);
  }

  /* Get client list */
  getAllClients() {
    return this.http.get<Client[]>(`${this.CLIENT_API}`);
  }

  /*get current group visible clients*/
  getAllVisibleClientsByGroup() {
    return this.http.get<Client[]>(`${this.CLIENT_API}/visible`);
  }

  /* Delete client */
  deleteClient(clientId) {
    return this.http.delete(`${this.CLIENT_API}/${clientId}`, {observe: 'response'});
  }

  /* Change client visible state */
  changeClientVisibleState(clientId) {
    return this.http.put(`${this.CLIENT_API}/visible-state/${clientId}`, {}, {observe: 'response'});
  }

  getLazyClientList(request: ClientLazyRequest) {
    return this.http.post<SearchResponse<Client>>(`${this.CLIENT_API}/filter`, request);
  }

  getClientById(clientId: number) {
    return this.http.get<Client>(`${this.CLIENT_API}/${clientId}`);
  }

  getClients() {
    return this.http.get<Client[]>(`${this.CLIENT_API}/visible-active-clients`);
  }


  getLatestBirthdays() {
    return this.http.get<Client[]>(`${this.CLIENT_API}/latest-birthdays`);
  }

  getClientInfo(id: number) {
    return this.http.get<any>(`${this.CLIENT_API}/info/${id}`);
  }

  countReceivers() {
    return this.http.get<number[]>(`${this.CLIENT_API}/count-promo-receivers`);
  }

  filterClientsForPromo(request: ClientLazyRequest) {
    return this.http.post<SearchResponse<Client>>(`${this.CLIENT_API}/filter-for-promo`, request);
  }

  getClientsByIds(request) {
    return this.http.post<Client[]>(`${this.CLIENT_API}/get-clients-by-ids`, request);
  }

  sendResetPassword(id) {
    return this.http.get(`${this.CLIENT_API}/send-reset-password/${id}`);
  }

  confirmClient(id) {
    return this.http.get(`${this.CLIENT_API}/confirm-client/${id}`);
  }

  changeGroup(request) {
    return this.http.post<Client[]>(`${environment.api}/auth-client/assign-group-and-login`, request);
  }
  getClientByMailOrPhone(request) {
    return this.http.post<Client[]>(`${this.CLIENT_API}/get-clients-by-email-or-phone`, request);
  }

  getCountByNotificationMode() {
    return this.http.get<{sms: number, whatsApp: number}>(`${this.CLIENT_API}/count-by-notification-mode`);
  }
}

class ClientLazyRequest extends LazyRequest {
  visible?: boolean;
  confirmed?: boolean;
  noMarketingCheck?: boolean;
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-checkbox {
  font-size: 0.8rem;
}

.modules-children {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}`, "",{"version":3,"sources":["webpack://./src/app/administrative-content/pages/owner/components/license-configuration/license-config-form/license-config-form.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AACA;EACE,gBAAA;EACA,aAAA;EACA,qCAAA;AAEF","sourcesContent":["mat-checkbox {\r\n  font-size: .8rem;\r\n}\r\n.modules-children {\r\n  list-style: none;\r\n  display: grid;\r\n  grid-template-columns: repeat(3, 1fr);\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

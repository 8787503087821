import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {SubscriptionFormComponent} from './subscription-form.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatLegacyButtonModule} from '@angular/material/legacy-button';
import {MatLegacyCheckboxModule} from '@angular/material/legacy-checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import {SharedModule} from '../../../../../shared/shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbDropdownModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import { CurrencyMaskModule } from "ng2-currency-mask";


const routes: Routes = [];

@NgModule({
    declarations: [SubscriptionFormComponent],
    imports: [
        CommonModule,
        TranslateModule,
        MatLegacyButtonModule,
        MatIconModule,
        RouterModule.forChild(routes),
        SharedModule.forChild(),
        ReactiveFormsModule,
        NgbPaginationModule,
        MatDatepickerModule,
        NgbDropdownModule,
        CurrencyMaskModule,
        MatLegacyCheckboxModule,
        FormsModule
    ],
    exports: [
        SubscriptionFormComponent
    ]
})
export class SubscriptionFormModule {
}

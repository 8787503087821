// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-btn {
  color: white;
  background-color: #36404c;
  border-radius: 5px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
  transition: 0.1s ease-in-out all;
  cursor: pointer;
}
.add-btn .add-btn-icon {
  font-size: 3rem;
  margin-bottom: 0.5rem;
}
.add-btn h4 {
  font-size: 16px;
}

.add-btn:hover {
  background-color: #2B333D;
}

.center-card {
  border-radius: 5px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
  background-color: #ffffff;
  transition: 0.1s ease-in-out all;
  position: relative;
}
.center-card .center-title {
  font-size: 1.1rem;
  font-weight: 600;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.center-card .center-subtitle {
  font-size: 0.9rem;
  color: #999999;
}

.center-card-content {
  padding: 0.5rem 1.2rem;
  margin-top: -2rem;
}

.dropdown-toggle::after {
  display: none;
}

.col-title {
  font-size: 15px;
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/app/administrative-content/pages/admin-module/components/center-mgm/sell-points/sell-points.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,mFAAA;EACA,gCAAA;EACA,eAAA;AACF;AAAE;EACE,eAAA;EACA,qBAAA;AAEJ;AAAE;EACE,eAAA;AAEJ;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,kBAAA;EACA,mFAAA;EAEA,yBAAA;EACA,gCAAA;EACA,kBAAA;AAAF;AACE;EACE,iBAAA;EACA,gBAAA;EACA,UAAA;EACA,gBAAA;EACA,uBAAA;AACJ;AACE;EACE,iBAAA;EACA,cAAA;AACJ;;AAEA;EACE,sBAAA;EACA,iBAAA;AACF;;AACA;EACE,aAAA;AAEF;;AAAA;EACE,eAAA;EACA,gBAAA;AAGF","sourcesContent":[".add-btn {\r\n  color: white;\r\n  background-color: lighten(#2B333D, 5%);\r\n  border-radius: 5px;\r\n  box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);\r\n  transition: .1s ease-in-out all;\r\n  cursor: pointer;\r\n  .add-btn-icon {\r\n    font-size: 3rem;\r\n    margin-bottom: .5rem;\r\n  }\r\n  h4 {\r\n    font-size: 16px;\r\n  }\r\n}\r\n\r\n.add-btn:hover {\r\n  background-color: #2B333D\r\n}\r\n\r\n.center-card {\r\n  border-radius: 5px;\r\n  box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);\r\n  // border: .5pt solid #a4a4a4;\r\n  background-color: #ffffff;\r\n  transition: .1s ease-in-out all;\r\n  position: relative;\r\n  .center-title {\r\n    font-size: 1.1rem;\r\n    font-weight: 600;\r\n    width: 90%;\r\n    overflow: hidden;\r\n    text-overflow: ellipsis;\r\n  }\r\n  .center-subtitle {\r\n    font-size: .9rem;\r\n    color: lighten(black, 60%)\r\n  }\r\n}\r\n.center-card-content {\r\n  padding: .5rem 1.2rem;\r\n  margin-top: -2rem;\r\n}\r\n.dropdown-toggle::after {\r\n  display:none;\r\n}\r\n.col-title {\r\n  font-size: 15px;\r\n  font-weight: 600;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

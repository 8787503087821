import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {GroupConfigurationService} from '../../../../../shared/services/group-configuration.service';
import {  MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ClientRangeConfig} from '../../../../../shared/models/client-range-config';
import {SweetAlertService} from '../../../../../shared/services/sweet-alert.service';
import {ClientMgmService} from '../../../../../shared/services/client-mgm.service';
import {PromotionMgmService} from '../../../../../shared/services/promotion-mgm.service';
import {Product} from '../../../../../shared/models/product';
import {Client} from '../../../../../shared/models/client';
import {Treatment} from '../../../../../shared/models/treatment';
import {LocalStorageService} from 'ngx-webstorage';
import {SocialConfigurationService} from '../../../../../shared/services/social-configuration.service';

declare var FB;

@Component({
  selector: 'app-configuration-mgm',
  templateUrl: './configuration-mgm.component.html',
  styleUrls: ['./configuration-mgm.component.scss']
})
export class ConfigurationMgmComponent implements OnInit {
  @ViewChild('file', { static: false }) inputFile: ElementRef;
  configForm: UntypedFormGroup;
  rangeConfigForm: UntypedFormGroup;
  smsConfigForm: UntypedFormGroup;
  dialogRef: any;
  columns = ['ADMIN.GROUP.LEVEL', 'ADMIN.GROUP.NAME', 'ADMIN.GROUP.FROM', 'ADMIN.GROUP.TO'];
  generalConfig = true;
  saveLoading = false;
  loadedConf = false;
  submitted = false;
  rangeConfig = false;
  utilityConfig = false;
  promoConfig = false;
  password;
  disableDownload = false;
  DEFAULT_LANG = 'IT';
  disableSave = false;
  file: FormData = new FormData();
  fileToUpload: File = null;
  uploaded;
  productList: Product[] = [];
  treatmentList: Treatment[] = [];
  loading = true;
  productColumns = [
    '',
    'PLU',
    'PRODUCT_FORM.DESCRIPTION',
    'PRODUCT_FORM.COMMERCIAL_DESCRIPTION',
    'PRODUCT_FORM.BAR_CODE',
    'PRODUCT_FORM.PURCHASE_COST',
    'PRODUCT_FORM.PRICE',
    'PRODUCT_FORM.MIN_STOCK',
    'PRODUCT_FORM.MEASURE_UNIT',
    'PRODUCT_FORM.QUANTITY',
    'PRODUCT_FORM.ACTIVATE_DETAILS_SALE',
    'DATA_TABLE.VAT',
    'PRODUCT_FORM.USE_TYPE',
    'PRODUCT_FORM.CATEGORY',
    'CONFIGURATION.SUBCATEGORIES',
    'DATA_TABLE.BRAND',
    'PRODUCT_FORM.LINEA',
    'PRODUCT_FORM.PROVIDER',
    'PRODUCT_FORM.NOTE',
  ];
  clientColumns = [
    '',
    'CLIENT_FORM.FIRST_NAME',
    'CLIENT_FORM.LAST_NAME',
    'CLIENT_FORM.GENDER',
    'CLIENT_FORM.EMAIL',
    'CLIENT_FORM.DATE_OF_BIRTH',
    'CLIENT_FORM.FISCAL_CODE',
    'CLIENT_FORM.MOBILE',
    'CLIENT_FORM.WORK_PHONE',
    'CLIENT_FORM.HOME_PHONE',
    'CLIENT_FORM.ADDRESS',
    'CLIENT_FORM.STREET',
    'CLIENT_FORM.ZIP',
    'CLIENT_FORM.ACTIVE',
    'CLIENT_FORM.MARRIED',
    'CLIENT_FORM.SONS',
    'CLIENT_FORM.NO_MARKETING',
    'CLIENT_FORM.PRIVACY',
    'CLIENT_FORM.NO_APPOINTMENT_REMINDER',
    'CLIENT_FORM.NOTE',
    'CLIENT_FORM.COMPANY',
    'DATA_TABLE.HOW_DID_YOU_MEET_US',
    'CLIENT_FORM.VISIBLE',
  ];

  treatmentColumns = [
    '',
    'PLU',
    'TREATMENT_FORM.DESCRIPTION',
    'TREATMENT_FORM.CATEGORY',
    'TREATMENT_FORM.SUBCATEGORY',
    'TREATMENT_FORM.LINEA',
    'TREATMENT_FORM.TAX',
    'TREATMENT_FORM.CABINE',
    'TREATMENT_FORM.TEMPO_CABINE',
    'TREATMENT_FORM.TEMPO_OPERATORE',
    'TREATMENT_FORM.PAUSE_TIME',
    'DATA_TABLE.PRICE',
    'TREATMENT_FORM.NOTE_PRODUCT',
  ];
  uploadType = '';
  clientList: Client[] = [];
  socialConfigForm: UntypedFormGroup;
  saveSocialLoading = false;
  loadingProducts = false;

  constructor(private fb: UntypedFormBuilder,
              private matDialog: MatDialog,
              private groupConfigurationService: GroupConfigurationService,
              private promotionMgmService: PromotionMgmService,
              private matSnackbar: MatSnackBar,
              private translate: TranslateService,
              private sweetAlertService: SweetAlertService,
              private translateService: TranslateService,
              private clientService: ClientMgmService,
              private facebookConfiguration: SocialConfigurationService
  ) {
  }

  ngOnInit() {
    this.configForm = this.fb.group({
      id: null,
      toleranceDays: [null, Validators.compose([Validators.required, Validators.pattern(/^[0-9]+$/)])],
      quoteDuration: new UntypedFormControl('')
    });
    this.smsConfigForm = this.fb.group({
      authKey: new UntypedFormControl('', Validators.required),
      authSecret: new UntypedFormControl('', Validators.required),
      sender: new UntypedFormControl('', Validators.compose([Validators.required, Validators.maxLength(11)])),
    });
    this.groupConfigurationService.getCurrentGroupConfig().subscribe(d => {
      if (d) {
        this.configForm.patchValue(d);
      }
    });
    this.rangeConfigForm = this.fb.group({
      id: new UntypedFormControl(null),
      rangeDuration: new UntypedFormControl('TRIMESTER', Validators.compose([Validators.required])),
      firstLevelFrom: new UntypedFormControl(null, Validators.required),
      // firstLevelTo: new FormControl(null, Validators.required),
      secondLevelFrom: new UntypedFormControl(null, Validators.required),
      secondLevelTo: new UntypedFormControl(null, Validators.required),
      thirdLevelFrom: new UntypedFormControl(null, Validators.required),
      thirdLevelTo: new UntypedFormControl(null, Validators.required),
      forthLevelFrom: new UntypedFormControl(null, Validators.required),
      forthLevelTo: new UntypedFormControl(null, Validators.required),
    });

    this.socialConfigForm = this.fb.group({
      id: null,
      appId: [null, Validators.required],
      appSecret: [null, Validators.required],
      pageId: [null, Validators.required],
      instagramUserId: null,
    });
  }

  save() {
    this.saveLoading = true;
    this.groupConfigurationService.update(this.configForm.value).subscribe(() => {
      this.saveLoading = false;
      this.matSnackbar.open(this.translateService.instant('ADMIN.GROUP.UPDATED_SUCCESS'), 'Ok', {duration: 5000});
      this.groupConfigurationService.getCurrentGroupConfig().subscribe(d => {
        if (d) {
          this.configForm.patchValue(d);
        }
      });
    }, error => this.saveLoading = false);
  }

  openRageConfig(content) {
    this.groupConfigurationService.getRange().subscribe(d => {
      if (d) {
        this.rangeConfigForm.patchValue(d);
      }
      this.dialogRef = this.matDialog.open(content, {minWidth: 1200});
    });

  }

  saveRange() {
    this.submitted = true;
    if (!this.checkRangeConfig(this.rangeConfigForm.getRawValue())) {
      this.sweetAlertService.danger(this.translate.instant('DIALOG.RANGE_FORM_INVALID'));
      return;
    }
    this.saveLoading = true;
    this.groupConfigurationService.updateRange(this.rangeConfigForm.value).subscribe(() => {
      this.saveLoading = false;
      this.submitted = false;
      this.matSnackbar.open(this.translateService.instant('ADMIN.GROUP.UPDATED_SUCCESS'), 'Ok', {duration: 5000});
    }, error => this.saveLoading = false);
  }

  saveSmsConfig() {
    if (!this.smsConfigForm.valid) {
      this.sweetAlertService.notification(this.translate.instant('DIALOG.INSERT_ALL_MANDATORY_INFORMATION'));
      return;
    }
    this.saveLoading = true;
    this.promotionMgmService.saveSmsConfig(this.smsConfigForm.value).subscribe(() => {
      this.saveLoading = false;
      this.matSnackbar.open(this.translateService.instant('ADMIN.GROUP.UPDATED_SUCCESS'), 'Ok', {duration: 5000});
    }, error => this.saveLoading = false);

  }

  checkRangeConfig(range: ClientRangeConfig) {
    return range.forthLevelFrom < range.forthLevelTo && range.forthLevelTo <= range.thirdLevelFrom &&
      range.thirdLevelFrom <= range.thirdLevelTo && range.thirdLevelTo < range.secondLevelFrom &&
      range.secondLevelFrom < range.secondLevelTo && range.secondLevelTo < range.firstLevelFrom;
  }

  changeToRangeConfig() {
    this.generalConfig = false;
    this.utilityConfig = false;
    this.rangeConfig = true;
    this.promoConfig = false;
    this.groupConfigurationService.getRange().subscribe(d => {
      this.loadedConf = true;
      if (d) {
        this.rangeConfigForm.patchValue(d);
      }
    });
  }

  changeToStandardConfig() {
    this.generalConfig = true;
    this.utilityConfig = false;
    this.rangeConfig = false;
    this.promoConfig = false;
    this.groupConfigurationService.getCurrentGroupConfig().subscribe(d => {
      if (d) {
        this.configForm.patchValue(d);
      }
    });
  }

  changeToUtilityConfig() {
    this.generalConfig = false;
    this.utilityConfig = true;
    this.rangeConfig = false;
    this.promoConfig = false;

  }

  changeToPromoConfig() {
    this.promoConfig = true;
    this.generalConfig = false;
    this.utilityConfig = false;
    this.rangeConfig = false;
    // this.promotionMgmService.getSmsConfig().subscribe((r) => {
    //   if (r) {
    //     this.smsConfigForm.patchValue(r);
    //   }
    // });
    // this.promotionMgmService.getSocialConfig().subscribe(val => {
    //   if (val) {
    //     this.socialConfigForm.patchValue(val);
    //   }
    // });
  }

  exportOperator() {
    this.groupConfigurationService.exportCSV().subscribe(resp => {
      const contentDisposition = resp.headers.get('content-disposition');
      const fileName = contentDisposition.replace('attachment; filename=', '');
      const blob = resp.body;
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.download = fileName;
      anchor.href = url;
      anchor.click();
    });

  }

  resetPoint(content) {
    this.sweetAlertService.warning(this.translate.instant('DIALOG.YOU_WANT_TO_RESET'))
      .then(res => {
        if (res.value) {
          this.dialogRef = this.matDialog.open(content, {minWidth: 400});
          this.dialogRef.afterClosed().subscribe(d => {
            if (d) {
              this.groupConfigurationService.resetPoint(d).subscribe(resp => {
                this.sweetAlertService.success(this.translate.instant('DIALOG.DELETE_SUCCESS'));
              }, error => {
                if (error.error && error.error.message === 'INVALID_CREDENTIALS') {
                  this.sweetAlertService.danger(this.translate.instant('DIALOG.INVALID_PASSWORD'));
                  return;
                }
                this.sweetAlertService.danger(this.translate.instant('DIALOG.INTERNAL_SERVER_ERROR'));
              });
            }
          });
          this.password = '';
        }
      });
  }

  hardResetPointCard(content) {
    this.sweetAlertService.warning(this.translate.instant('DIALOG.YOU_WANT_TO_RESET'))
      .then(res => {
        if (res.value) {
          this.dialogRef = this.matDialog.open(content, {minWidth: 400});
          this.dialogRef.afterClosed().subscribe(d => {
            if (d) {
              this.groupConfigurationService.hardResetPointCard(d).subscribe(resp => {
                this.sweetAlertService.success(this.translate.instant('DIALOG.DELETE_SUCCESS'));
              }, error => {
                if (error.error && error.error.message === 'INVALID_CREDENTIALS') {
                  this.sweetAlertService.danger(this.translate.instant('DIALOG.INVALID_PASSWORD'));
                  return;
                }
                this.sweetAlertService.danger(this.translate.instant('DIALOG.INTERNAL_SERVER_ERROR'));
              });
            }
          });
          this.password = '';
        }
      });
  }

  hardReset(content) {
    this.groupConfigurationService.hardReset().subscribe(resp => {
      this.sweetAlertService.success(this.translate.instant('DIALOG.DELETE_SUCCESS'));
    }, error => {
      if (error.error && error.error.message === 'INVALID_CREDENTIALS') {
        this.sweetAlertService.danger(this.translate.instant('DIALOG.INVALID_PASSWORD'));
        return;
      }
      this.sweetAlertService.danger(this.translate.instant('DIALOG.INTERNAL_SERVER_ERROR'));
    });

  }

  importClient() {

  }

  downloadModel(type) {
    this.disableDownload = true;
    const lg = localStorage.getItem('language') || this.translate.currentLang || this.DEFAULT_LANG;
    this.groupConfigurationService.downloadExcelModel(type).subscribe(r => {
      const blob: Blob = new Blob([r], {type: 'text/csv'});
      const fileName = type + '-model.xlsx';
      const objectUrl = URL.createObjectURL(blob);
      const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
      a.href = objectUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(objectUrl);
      this.disableDownload = false;
    }, error => this.disableDownload = false);
  }

  handleFileInput(files: FileList, content, contentClient, contentTreatment) {
    this.fileToUpload = files.item(0);
    const data = new FormData();
    data.append('file', this.fileToUpload);
    this.disableSave = true;
    this.loadingProducts = true;
    this.groupConfigurationService.uploadFromExcel(this.uploadType, data).subscribe(r => {
      this.disableSave = false;
      this.loadingProducts = false;
      this.productList = r.products;
      this.clientList = r.clients;
      this.treatmentList = r.treatments;
      if (this.uploadType === 'product') {
        if (!r.products.length) {
          this.sweetAlertService.danger(this.translate.instant('PRODUCT_FORM.NO_PRODUCT_FOUND'));
        }
        if (r.products.length) {
          this.matDialog.open(content, {width: '1800px', maxHeight: '92vh'});
        }
        return;
      } else if (this.uploadType === 'client') {
        if (!r.clients.length) {
          this.sweetAlertService.danger(this.translate.instant('PRODUCT_FORM.NO_CLIENT_FOUND'));
        }
        if (r.clients.length) {
          this.matDialog.open(contentClient, {width: '1800px', maxHeight: '92vh'});
        }
      } else {
        if (!r.treatments.length) {
          this.sweetAlertService.danger(this.translate.instant('TREATMENT_FORM.NO_TREATMENT_FOUND'));
        }
        if (r.treatments.length) {
          this.matDialog.open(contentTreatment, {width: '1800px', maxHeight: '92vh'});
        }
      }
    }, error => {
      this.sweetAlertService.danger(this.translate.instant('DIALOG.CANNOT_IMPORT_PRODUCTS_CHECK_YOUR_FILE'));
      this.disableSave = false;
      this.loadingProducts = false;
    });
  }

  saveProducts() {
    this.loadingProducts = true;
    this.groupConfigurationService.creatProducts(this.productList).subscribe(r => {
      this.sweetAlertService.success(this.translate.instant('DIALOG.SUCCESS_CREAT_PRODUCTS'));
      this.loadingProducts = false;
    }, error => this.loadingProducts = false);
  }

  saveClients() {
    this.groupConfigurationService.creatClients(this.clientList).subscribe(r =>
      this.sweetAlertService.success(this.translate.instant('DIALOG.SUCCESS_CREAT_CLIENTS')));
  }

  deleteProduct(i: number) {
    this.productList.splice(i, 1);
  }

  deleteTr(i: number) {
    this.treatmentList.splice(i, 1);
  }

  deleteClient(i: number) {
    this.clientList.splice(i, 1);
  }

  openFileRead() {
    this.sweetAlertService.notification(this.translate.instant('DIALOG.ATTENTION_REQUIRED_EXCEL_FIELDS')).then(e => {
      this.uploadType = 'product';
      this.inputFile.nativeElement.click();
    });
  }

  openFileClientRead() {
    this.sweetAlertService.notification(this.translate.instant('DIALOG.ATTENTION_REQUIRED_CLIENT_EXCEL_FIELDS')).then(e => {
      this.uploadType = 'client';
      this.inputFile.nativeElement.click();
    });
  }

  openFileTreatment() {
    this.sweetAlertService.notification(this.translate.instant('DIALOG.ATTENTION_REQUIRED_TREATMENTS_EXCEL_FIELDS')).then(e => {
      this.uploadType = 'treatment';
      this.inputFile.nativeElement.click();
    });
  }

  saveTreatments() {
    this.groupConfigurationService.creatTreatments(this.treatmentList).subscribe(r =>
      this.sweetAlertService.success(this.translate.instant('DIALOG.SUCCESS_CREAT_TREATMENTS')));
  }

  saveFacebookConfig() {
    if (this.socialConfigForm.invalid) {
      this.sweetAlertService.notification(this.translate.instant('DIALOG.INSERT_ALL_MANDATORY_INFORMATION'));
      return;
    }
    this.saveSocialLoading = true;
    this.promotionMgmService.saveFacebookConfig(this.socialConfigForm.value).subscribe(res => {
      this.saveSocialLoading = false;
      this.socialConfigForm.get('id').setValue(res.id);
      this.matSnackbar.open(this.translateService.instant('ADMIN.GROUP.UPDATED_SUCCESS'), 'Ok', {duration: 5000});
    }, error => this.saveSocialLoading = false);
  }

  isSdkLoaded() {
    return FB !== undefined;
  }

  getFacebookToken() {
    const conf = this.socialConfigForm.value;
    this.facebookConfiguration.loadFacebookToken(
      conf.appId,
      conf.appSecret,
      conf.pageId,
      this.socialConfigForm.get('instagramUserId') as UntypedFormControl
    );
  }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .ck-editor__editable_inline {
  min-height: 500px !important;
}

.active-updateVersion {
  border: transparent !important;
  box-shadow: none;
}`, "",{"version":3,"sources":["webpack://./src/app/administrative-content/pages/owner/components/update-version/update-version-details/update-version-details.component.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;AACF;;AAEA;EACE,8BAAA;EACA,gBAAA;AACF","sourcesContent":[":host ::ng-deep .ck-editor__editable_inline {\r\n  min-height: 500px !important;\r\n}\r\n\r\n.active-updateVersion{\r\n  border: transparent !important;\r\n  box-shadow: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

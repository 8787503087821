import {DurationType} from '../enum/duration-type.enum';
import {SubscriptionProducts} from './subscriptionProducts';
import {SubscriptionTreatments} from './subscriptionTreatments';
import {SubscriptionType} from '../enum/subscription-type.enum';
import {Client} from './client';

export class Abonnement {

  id?: number;
  seq?: number;
  product?: boolean;
  treatment?: boolean;
  description?: string;
  alwaysActif?: boolean;
  saleDurationStartsAt?: Date;
  saleDurationEndsAt?: Date;
  durationType?: DurationType;
  durationStartsAt?: Date;
  durationEndsAt?: Date;
  numberOfDays?: number;
  products?: SubscriptionProducts[];
  treatments?: SubscriptionTreatments[];
  totalCosts?: number;
  price?: number;
  type?: SubscriptionType;
  clientId?: number;
  client?: Client;
  available?: boolean;
}

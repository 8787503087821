import {NgModule} from '@angular/core';
import {CommonModule, DecimalPipe} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from './dashboard.component';
import {NgChartsModule} from 'ng2-charts';
import {TranslateModule} from '@ngx-translate/core';
import {NgxChartsModule} from "@swimlane/ngx-charts";

const routes: Routes = [{path: '', component: DashboardComponent}];

@NgModule({
  declarations: [DashboardComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgChartsModule,
    NgxChartsModule,
    TranslateModule]
  ,
  providers: [DecimalPipe]
})
export class DashboardModule {
}

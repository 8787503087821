// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*:host {
    ngb-tabset .nav-pills {
        height: 80px;
        display: flex;
        align-items: center;
    }
}*/
.no-notif {
  padding: 1em;
  text-align: center;
  color: rgba(0, 0, 0, 0.4117647059);
  font-weight: 500;
}

.vscroll {
  height: calc(100vh - 35px);
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/content/layout/components/quick-sidebar/quick-sidebar.component.scss"],"names":[],"mappings":"AAKA;;;;;;EAAA;AAOA;EACE,YAAA;EACA,kBAAA;EACA,kCAAA;EACA,gBAAA;AAJF;;AAMA;EACE,0BAAA;EACA,cAAA;AAHF","sourcesContent":["\r\n@import 'src/@theme/scss/components/functions';\r\n@import 'src/@theme/scss/components/mixins';\r\n@import 'src/@theme/scss/components/variables';\r\n\r\n/*:host {\r\n    ngb-tabset .nav-pills {\r\n        height: 80px;\r\n        display: flex;\r\n        align-items: center;\r\n    }\r\n}*/\r\n.no-notif {\r\n  padding: 1em;\r\n  text-align: center;\r\n  color: #00000069;\r\n  font-weight: 500;\r\n}\r\n.vscroll {\r\n  height: calc(100vh - 35px);\r\n  overflow: auto;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

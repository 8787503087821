import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Promotion} from '../../../shared/models/promotion';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BreadcrumbService} from '../../../core/services/breadcrumb.service';
import {TranslateService} from '@ngx-translate/core';
import {PromotionFormService} from './services/promotion-form.service';
import {PromotionMgmService} from '../../../shared/services/promotion-mgm.service';
import {debounceTime} from 'rxjs/operators';
import {CustomSnackBarComponent} from '../../../shared/custom-snack-bar/custom-snack-bar.component';
import {Client} from '../../../shared/models/client';
import {ClientMgmService} from '../../../shared/services/client-mgm.service';
import {SweetAlertService} from '../../../shared/services/sweet-alert.service';
import {DatePipe} from '@angular/common';
import {PromoClient} from '../../../shared/models/promo-client';
import {PromoType} from '../../../shared/enum/promo-type';

@Component({
  selector: 'app-promotion-management',
  templateUrl: './promotion-management.component.html',
  styleUrls: ['./promotion-management.component.scss']
})
export class PromotionManagementComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('promoFormContainer', {read: ViewContainerRef}) promoFormContainer: ViewContainerRef;
  columns = [
    'PROMO_FORM.ID',
    'PROMO_FORM.CREATION_DATE',
    'PROMO_FORM.OBJECT',
    'PROMO_FORM.TYPE',
    'PROMO_FORM.SENT_DATE_HOUR',
    'PROMO_FORM.RECEIVER',
    'PROMO_FORM.STATUS',
  ];
  dialog_columns = [
    'SMS.RECEIVER',
    'PROMO_FORM.MAIL',
    'SMS.PHONE',
  ];
  sms_columns = [
    'SMS.STATUS',
    'SMS.SENT_AT',
  ];
  mail_columns = [
    'SMS.MAIL_SENT_AT',
    'SMS.MAIL_STATUS',
  ];
  receivers: PromoClient[] = [];
  receivs: PromoClient[] = [];

  public page = 1;
  public totalRecords: number;
  public pageSize = 10;
  loading = true;
  firstCall = true;
  textSearch = null;
  rows: Promotion[] = [];
  orderType = 'DESC';
  searchFormControl = new FormControl(null);
  dialogRef: any;
  clientRows: Client[];
  dialogSearchFormControl = new FormControl(null);
  type: PromoType;

  constructor(private translate: TranslateService,
              public datepipe: DatePipe, public snackBar: MatSnackBar,
              private promotionFormService: PromotionFormService,
              private promoService: PromotionMgmService,
              private matDialog: MatDialog,
              private clientService: ClientMgmService,
              private sweetAlertService: SweetAlertService,
              private breadcrumbService: BreadcrumbService) {
  }

  ngOnInit() {
    this.sendBreadCrumb();
    this.filterPromos();
    this.searchFormControl.valueChanges.pipe(debounceTime(500)).subscribe(s => this.filterPromos());
    this.dialogSearchFormControl.valueChanges.pipe(debounceTime(500)).subscribe(s => {
      this.receivs = this.receivers;
      this.receivs = this.receivs.filter(x => ((x.firstName.toLowerCase() + ' ' +
          x.lastName.toLowerCase()).includes(s.toLowerCase()))
        || (x.mobilePrefix + x.mobile).includes(s)
        || (this.translate.instant('SMS.' + (this.receivs[0].smsStatus))).toLowerCase().includes(s.toLowerCase())
        || (x.smsSendAt && (this.datepipe.transform(x.smsSendAt, 'dd/MM/yyyy HH:mm')).includes(s))
        || (x.mailSendAt && (this.datepipe.transform(x.mailSendAt, 'dd/MM/yyyy HH:mm')).includes(s))
      );
    });
  }

  sendBreadCrumb(): void {
    this.breadcrumbService.sendBreadcrumb(['PROMOTION']);
  }

  editPromo(promo: Promotion, i: number) {
    const formRef = this.promotionFormService.openForm(this.promoFormContainer,
      {
        promo,
        editMode: true,
      });
    formRef.instance.editMode = true;
    formRef.instance.onClose.subscribe((d:any) => {
      if (d) {
        this.filterPromos();
        this.showSnackBar({
          text: `${d.firstName} ${d.lastName}`,
          actionIcon: 'save',
          actionMsg: this.translate.instant('DIALOG.UPDATE_SUCCESS')
        });
      }
    });
    formRef.instance.promo = promo ? promo : null;

  }

  showSnackBar(data: any) {
    this.snackBar.openFromComponent(CustomSnackBarComponent, {
      data,
      duration: 5000,
      panelClass: 'white-snackbar'
    });
  }

  sortRows(c: string) {

  }

  pageChange(page) {
    this.page = page;
    this.filterPromos();
  }

  addNewPromo() {
    const formRef = this.promotionFormService.openForm(this.promoFormContainer);
    formRef.instance.editMode = false;
    formRef.instance.onClose.subscribe((d:any) => {
      if (d) {
        this.filterPromos();
        this.showSnackBar({
          text: `${d.firstName} ${d.lastName}`,
          actionIcon: 'save',
          actionMsg: this.translate.instant('DIALOG.UPDATE_SUCCESS')
        });
      }
    });

  }

  filterPromos() {
    this.promoService.filter({
      page: this.page,
      pageSize: this.pageSize,
      textSearch: this.searchFormControl.value
    }).subscribe(r => {
      this.rows = r.data;
      this.totalRecords = r.totalRecords;
    });

  }

  openReceiversContent($event, content, promo: Promotion) {
    $event.cancelBubble = true;
    $event.stopPropagation();
    this.type = promo.type;
    this.receivers = promo.promoClients;
    this.receivs = promo.promoClients;
    // this.clientRows = promo.promoClients.reverse();
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    this.dialogRef = this.matDialog.open(content, {
      autoFocus: false,
      width: '95%',
      maxHeight: '90vh'
    });
  }

  annulReactivePromo($event, promo: Promotion) {
    $event.cancelBubble = true;
    $event.stopPropagation();
    let msg = '';
    if (promo.status === 'SCHEDULED') {
      msg = this.translate.instant('DIALOG.YOU_WANT_TO_ANNUL');
    } else if (promo.status === 'ANNULLED') {
      msg = this.translate.instant('DIALOG.YOU_WANT_TO_REACTIVE');
    } else {
      return;
    }
    this.sweetAlertService.warning(msg + promo.object, '', '',
      this.translate.instant('DIALOG.ATTENTION')).then(e => {
      if (e.value) {
        this.promoService.annulReactivePromo(promo.id).subscribe(r => {
          // @ts-ignore
          promo.status = promo.status === 'SCHEDULED' ? 'ANNULLED' : 'SCHEDULED';
        });
      }
    });
  }
}

import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'romanNumber'
})
export class RomanNumberPipe implements PipeTransform {

  constructor(private sanitized: DomSanitizer) {
  }

  transform(value: number): any {
    return this.sanitized.bypassSecurityTrustHtml(`&#${value + 8544};`);
  }

}

import {Component, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {PaymentService} from '../../../../shared/services/payment.service';
import {BreadcrumbService} from '../../../../core/services/breadcrumb.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {   MatSnackBar } from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {SweetAlertService} from '../../../../shared/services/sweet-alert.service';
import {TranslateService} from '@ngx-translate/core';
import {RtService} from '../../../../shared/services/rt.service';
import {CustomSnackBarComponent} from '../../../../shared/custom-snack-bar/custom-snack-bar.component';
import {QuoteService} from '../../../../shared/services/quote.service';
import {Quote} from '../../../../shared/models/quote';
import * as FileSaver from 'file-saver';
import {environment} from '../../../../../environments/environment';
import {SellPointService} from '../../../../shared/services/sell-point.service';
import {ErrorCodeInterceptor} from '../../../../shared/dto/config-rt-dto';

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss']
})
export class QuoteComponent implements OnInit {
  payments: Quote[] = [];
  load = false;
  searchFormControl = new UntypedFormControl(null);
  pageSize = 10;
  page = 1;
  public totalRecords: number;
  selectedQuote: Quote;
  changingReceipt = false;

  reset: number = null;
  receipt: number = null;


  columns = [
    'DATA_TABLE.CREATION_DATE',
    'DATA_TABLE.NUMBER',
    'DATA_TABLE.CLIENT',
    'DATA_TABLE.DISCOUNT',
    'DATA_TABLE.TOTAL',
    '',
    ''
  ];
  private printingWindow: Window;
  private isUsingPopup: boolean;

  constructor(private paymentService: PaymentService,
              private breadcrumbService: BreadcrumbService,
              private matDialog: MatDialog,
              private snackBar: MatSnackBar,
              private quoteService: QuoteService,
              private route: ActivatedRoute,
              private router: Router,
              private sweetAlertService: SweetAlertService,
              private translate: TranslateService,
              private rtService: RtService,
              private sellPointService: SellPointService) {
  }

  ngOnInit() {
    this.breadcrumbService.sendBreadcrumb(['QUOTE_HISTORY']);
    // this.getLazyPayments({page: this.page, pageSize: this.pageSize});
    // this.searchFormControl.valueChanges.pipe(debounceTime(500)).subscribe(s => {
    //   this.getLazyPayments({page: 1, pageSize: this.pageSize, textSearch: s});
    // });
    this.sellPointService.getUsePrintingPopup().subscribe(d => {
      this.isUsingPopup = d;
      this.loadQuotes();
    });
  }

  getLazyPayments(request, snackBarConf?) {
    this.load = true;
    return this.quoteService.search(request).subscribe(data => {
      this.payments = data.data;
      this.load = false;
      this.totalRecords = data.totalRecords;
      if (snackBarConf) {
        this.showSnackBar(snackBarConf);
      }
    });
  }

  loadQuotes() {
    this.load = true;
    this.quoteService.getQuoteList(this.page).subscribe(data => {
      this.payments = data.data;
      this.load = false;
      this.totalRecords = data.totalRecords;
    });
  }

  showSnackBar(data: any) {
    this.snackBar.openFromComponent(CustomSnackBarComponent, {
      data,
      duration: 5000,
      panelClass: 'white-snackbar'
    });
  }

  pageChange(page: number) {
    this.page = page;
    this.loadQuotes();
  }

  printQuote(id) {
    this.quoteService.getReport(id).subscribe(d => {
      const blob = new Blob([d], {type: 'application/pdf'});
      FileSaver.saveAs(blob, `${this.selectedQuote.accountDescription}pdf`);
    }, error => {
      this.sweetAlertService.error('Errore durante la generazione del preventivo');
    });
  }

  getTicket(id) {
    if (this.isUsingPopup) {
      this.quoteService.getTicket(id).subscribe(d => {
        this.printingWindow = window.open(
          `${environment.printingUrl}?lang=${this.translate.currentLang}`,
          '_blank',
          // tslint:disable-next-line:max-line-length
          `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no,width=700,height=500,left=${(screen.width - 700) / 2},top=${(screen.height - 500) / 2}`
        );
        window.addEventListener('message', x => {
          if (x.data !== 'ready') {
            return;
          }
          this.printingWindow.postMessage({cmd: d.cmd, ip: d.ip}, '*');
          window.addEventListener('message', e => {
            this.printingWindow.close();
            const r = e.data;
            if (r.success) {
              this.rtService.interceptErrorCode(r.xml).subscribe(rr => {
                if (rr.errorCode !== 0) {
                  this.interceptPrinterError(rr);
                  return;
                }
                  this.sweetAlertService.success(this.translate.instant('DIALOG.SUCCESS_EMIT_QUOTE'));
              });
            } else {
              this.sweetAlertService.danger(this.translate.instant('DIALOG.FAILED_EMIT_QUOTE'));
            }
          }, {once: true});
        }, {once: true});
      }, error => {
        this.sweetAlertService.danger(this.translate.instant('DIALOG.FAILED_EMIT_QUOTE'));
      });
    } else {
      this.quoteService.getTicket(id).subscribe(d => {
        this.rtService.updateRt(d.ip, d.cmd).subscribe(res => {
          this.rtService.interceptErrorCode(res.body).subscribe(rr => {
            if (rr.errorCode !== 0) {
              this.interceptPrinterError(rr);
              return;
            }
              this.sweetAlertService.success(this.translate.instant('DIALOG.SUCCESS_EMIT_QUOTE'));
          });
        }, error => {
          this.sweetAlertService.danger(this.translate.instant('DIALOG.FAILED_EMIT_QUOTE'));
        });
      }, error => {
        this.sweetAlertService.danger(this.translate.instant('DIALOG.FAILED_EMIT_QUOTE'));
      });
    }
  }

  showDetails(p, content) {
    this.quoteService.getQuoteEntries(p.id, p.type).subscribe(r => {
      this.selectedQuote = p;
      this.selectedQuote.quoteEntry = r;
      const dialogRef = this.matDialog.open(content, {width: '800px', maxHeight: '92vh'});
      dialogRef.afterClosed().subscribe(result => {
        // this.getLazyPayments({page: this.page, pageSize: this.pageSize});
      });
    });
  }

  interceptPrinterError(rr: ErrorCodeInterceptor) {
    if (rr.errorCode !== 0) {
      let msg = this.translate.instant('DIALOG.FAILED_EMIT_TICKET');
      if (null != rr.errorTitle) {
        msg = rr.errorTitle + '\n';
      }
      msg += rr.errorDescription ? rr.errorDescription : '';
      this.sweetAlertService.danger(msg);
    }
  }
}

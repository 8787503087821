import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {SequenceType} from '../enum/sequence-type.enum';
import {Observable, Subject, Subscription} from 'rxjs';
import {PaymentType} from '../models/payment-type';
import {ZipCode} from '../models/zip-code';
import {HttpClient} from '@angular/common/http';
import {ProviderMgmService} from '../services/provider-mgm.service';
import {ConfigurationsService} from '../services/configurations.service';
import {ZipCodeService} from '../services/zip-code.service';
import {SweetAlertService} from '../services/sweet-alert.service';
import {TranslateService} from '@ngx-translate/core';
import {SequenceService} from '../services/sequence.service';
import {Product} from '../models/product';
import {Provider} from '../models/provider';

// tslint:disable-next-line:max-line-length
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@Component({
  selector: 'app-provider-create',
  templateUrl: './provider-create.component.html',
  styleUrls: ['./provider-create.component.scss']
})
export class ProviderCreateComponent implements OnInit {
  @Input() editMode: boolean;
  @Input() provider: Provider;
  @Input() editEvents: Observable<void>;
  @Input() saveEvents: Observable<void>;
  @Output() saved = new EventEmitter<Provider>();
  @Output() failed = new EventEmitter<Provider>();
  editEventsSubscription: Subscription;
  saveEventsSubscription: Subscription;

  onClose = new Subject();

  phonePrefix = '+216';
  mobilePhonePrefix = '+216';
  faxPrefix = '+216';
  providerForm: UntypedFormGroup;
  editClicked = false;
  submitted = false;
  prefixList: any[] = [];
  payments: PaymentType[] = [];
  paymentTypePage = 1;

  unsubscribe1$ = new Subject();
  unsubscribe2$ = new Subject();

  zipCodeSearch1 = new Subject<string>();
  zipCodeSearch2 = new Subject<string>();

  registeredOfficeCountryFormControl = new UntypedFormControl();
  registeredOfficeCityFormControl = new UntypedFormControl();
  registeredOfficeProvinceFormControl = new UntypedFormControl();

  operatingOfficeCountryFormControl = new UntypedFormControl();
  operatingOfficeCityFormControl = new UntypedFormControl();
  operatingOfficeProvinceFormControl = new UntypedFormControl();


  zipCodeList: ZipCode[] = [];
  currentSeq = '';
  disableSave = false;
  @Output() updated = new EventEmitter<boolean>();
  constructor(private http: HttpClient,
              private providerService: ProviderMgmService,
              private configurationService: ConfigurationsService,
              private zipCodeService: ZipCodeService,
              private sweetAlertService: SweetAlertService,
              private translate: TranslateService,
              private sequence: SequenceService) { }

  ngOnInit() {
    this.editEventsSubscription = this.editEvents.subscribe(() => this.activateEdit());
    this.saveEventsSubscription = this.saveEvents.subscribe(() => this.save());
    this.providerForm = new UntypedFormGroup({
      id: new UntypedFormControl(),
      seq: new UntypedFormControl(null),
      active: new UntypedFormControl(true),
      visible: new UntypedFormControl(true),
      businessName: new UntypedFormControl(null, Validators.required),
      vatNumber: new UntypedFormControl(null, Validators.required),
      fiscalCode: new UntypedFormControl(null, Validators.required),
      codeSDI: new UntypedFormControl(null, Validators.required),
      // rea: new FormControl(),
      emailPEC: new UntypedFormControl(null, Validators.pattern(EMAIL_REGEX)),
      // address 1
      registeredOfficeStreet: new UntypedFormControl(null, Validators.required),
      registeredOfficeAddress: new UntypedFormControl(null, Validators.required),
      registeredOfficeZipCodeId: new UntypedFormControl(null),
      registeredOfficeCountry: new UntypedFormControl(null),
      registeredOfficeCity: new UntypedFormControl(null),
      registeredOfficeProvince: new UntypedFormControl(null),
      //

      // address 2
      operatingOfficeStreet: new UntypedFormControl(null),
      operatingOfficeAddress: new UntypedFormControl(null),
      operatingOfficeZipCodeId: new UntypedFormControl(null),
      operatingOfficeCountry: new UntypedFormControl(null),
      operatingOfficeCity: new UntypedFormControl(null),
      operatingOfficeProvince: new UntypedFormControl(null),
      //
      phone: new UntypedFormControl(null, [Validators.pattern('^[0-9]{8}[0-9]*')]),
      phonePrefix: new UntypedFormControl('+39'),
      mobile: new UntypedFormControl(null, [Validators.pattern('^[0-9]{8}[0-9]*')]),
      mobilePrefix: new UntypedFormControl('+39'),
      // fax: new FormControl(null, [Validators.pattern('^[0-9]{8}[0-9]*')]),
      // faxPrefix: new FormControl('+39'),
      email: new UntypedFormControl(null, Validators.pattern(EMAIL_REGEX)),
      referent: new UntypedFormControl(),
      website: new UntypedFormControl(),
      bank: new UntypedFormControl(),
      bankBranch: new UntypedFormControl(),
      iban: new UntypedFormControl(),
      // cin: new FormControl(),
      // bicSwift: new FormControl(),
      // abi: new FormControl(),
      // cab: new FormControl(),
      paymentId: new UntypedFormControl(),
      note: new UntypedFormControl(),
      createdAt: new UntypedFormControl(),
      updatedAt: new UntypedFormControl(),
    });

    this.providerForm.get('registeredOfficeZipCodeId').disable();
    this.registeredOfficeProvinceFormControl.disable();
    this.registeredOfficeCityFormControl.disable();

    this.providerForm.get('operatingOfficeZipCodeId').disable();
    this.operatingOfficeProvinceFormControl.disable();
    this.operatingOfficeCityFormControl.disable();

    this.providerForm.valueChanges.pipe(takeUntil(this.unsubscribe1$)).subscribe(c => {
      if ( this.providerForm.dirty) {
        this.updated.emit(true);
      }
    });
    this.registeredOfficeCountryFormControl.valueChanges.pipe(takeUntil(this.unsubscribe1$)).subscribe(c => {
      if (this.registeredOfficeCountryFormControl.dirty) {
        this.updated.emit(true);
      }
      if (c && !this.registeredOfficeCountryFormControl.disabled) {
        this.providerForm.get('registeredOfficeZipCodeId').enable();
        this.zipCodeService.getAllZipCodesByCountry(c).subscribe(d => {
          this.zipCodeList = d;
        });
      } else if (!c) {
        this.registeredOfficeProvinceFormControl.setValue(null);
        this.registeredOfficeCityFormControl.setValue(null);
        this.providerForm.get('registeredOfficeZipCodeId').setValue(null);
        this.providerForm.get('registeredOfficeZipCodeId').disable();
      }
    });
    this.providerForm.get('registeredOfficeZipCodeId').valueChanges.pipe(takeUntil(this.unsubscribe1$)).subscribe(d => {
      this.registeredOfficeProvinceFormControl.setValue(null);
      this.registeredOfficeCityFormControl.setValue(null);
      if (d && this.zipCodeList.length > 0) {
        const zc = this.zipCodeList.find(e => e.id === d);
        this.setProvinceAndCity1(zc);
      }
    });


    this.operatingOfficeCountryFormControl.valueChanges.pipe(takeUntil(this.unsubscribe2$)).subscribe(c => {
      if (this.operatingOfficeCountryFormControl.dirty) {
        this.updated.emit(true);
      }
      if (!c) {
        this.operatingOfficeProvinceFormControl.setValue(null);
        this.operatingOfficeCityFormControl.setValue(null);
        this.providerForm.get('operatingOfficeZipCodeId').setValue(null);
        this.providerForm.get('operatingOfficeZipCodeId').disable();
        return;
      }
      if (!this.operatingOfficeCountryFormControl.disabled) {
        this.providerForm.get('operatingOfficeZipCodeId').enable();
        this.zipCodeService.getAllZipCodesByCountry(c).subscribe(d => {
          this.zipCodeList = d;
        });
      }
    });
    this.providerForm.get('operatingOfficeZipCodeId').valueChanges.pipe(takeUntil(this.unsubscribe2$)).subscribe(d => {
      this.operatingOfficeProvinceFormControl.setValue(null);
      this.operatingOfficeCityFormControl.setValue(null);
      if (d && this.zipCodeList.length > 0) {
        this.setProvinceAndCity2(this.zipCodeList.find(e => e.id === d));
      }
    });

    this.http.get<any[]>('/assets/TEL_PREFIX.json').subscribe(d => this.prefixList = d.map(e => {
      e.label = `${e.country}: ${e.prefix}`;
      return e;
    }));

    if (this.editMode) {
      this.providerForm.disable();
      this.registeredOfficeCountryFormControl.disable();
      this.operatingOfficeCountryFormControl.disable();
      this.providerForm.patchValue(this.provider);
      this.providerForm.get('phonePrefix').setValue(this.provider.phonePrefix || '+39');
      // this.providerForm.get('faxPrefix').setValue(this.provider.faxPrefix || '+39');
      this.providerForm.get('mobilePrefix').setValue(this.provider.mobilePrefix || '+39');
      this.providerForm.get('paymentId').setValue(this.provider.payment ? this.provider.payment.id : null);
      if (this.provider.registeredOfficeZipCode) {
        this.registeredOfficeCountryFormControl
          .setValue(this.provider.registeredOfficeZipCode ? this.provider.registeredOfficeZipCode.country : null);
        this.zipCodeService.getAllZipCodesByCountry(this.provider.registeredOfficeZipCode.country).subscribe(d => {
          this.zipCodeList = d;
          this.providerForm.get('registeredOfficeZipCodeId').enable();
          this.providerForm.get('registeredOfficeZipCodeId').setValue(this.provider.registeredOfficeZipCode.id);
          this.providerForm.get('registeredOfficeZipCodeId').disable();
          this.setProvinceAndCity1(d.find(e => e.id === this.provider.registeredOfficeZipCode.id));
        });
      }

      if (this.provider.operatingOfficeZipCode) {
        this.operatingOfficeCountryFormControl
          .setValue(this.provider.operatingOfficeZipCode ? this.provider.operatingOfficeZipCode.country : null);
        this.zipCodeService.getAllZipCodesByCountry(this.provider.operatingOfficeZipCode.country).subscribe(d => {
          this.zipCodeList = d;
          this.providerForm.get('operatingOfficeZipCodeId').enable();
          this.providerForm.get('operatingOfficeZipCodeId').setValue(this.provider.operatingOfficeZipCode.id);
          this.providerForm.get('operatingOfficeZipCodeId').disable();
          this.setProvinceAndCity1(d.find(e => e.id === this.provider.operatingOfficeZipCode.id));
        });
      }
      this.currentSeq = (this.providerForm.get('seq').value + '').padStart(4, '0');
    }


    this.getPaymentConfigurationsByType();

    if (!this.editMode) {
      this.sequence.getCurrentSequence(SequenceType.PROVIDER).subscribe(res => {
        this.currentSeq = String(res + 1).padStart(4, '0');
      });
    }

  }


  activateEdit() {
    if (this.editMode) {
      this.editClicked = true;
      this.providerForm.enable();
      this.registeredOfficeCountryFormControl.enable();
      this.operatingOfficeCountryFormControl.enable();
    }
  }

  setProvinceAndCity1(zc) {
    this.registeredOfficeProvinceFormControl.enable();
    this.registeredOfficeCityFormControl.enable();
    this.registeredOfficeProvinceFormControl.setValue(zc.province);
    this.registeredOfficeCityFormControl.setValue(zc.city);
    this.registeredOfficeProvinceFormControl.disable();
    this.registeredOfficeCityFormControl.disable();
  }

  setProvinceAndCity2(zc) {
    this.operatingOfficeProvinceFormControl.enable();
    this.operatingOfficeCityFormControl.enable();
    this.operatingOfficeProvinceFormControl.setValue(zc.province);
    this.operatingOfficeCityFormControl.setValue(zc.city);
    this.operatingOfficeProvinceFormControl.disable();
    this.operatingOfficeCityFormControl.disable();
  }


  changePhonePrefix(s: string) {
    this.phonePrefix = s;
  }

  changeMobilePhonePrefix(s: string) {
    this.mobilePhonePrefix = s;
  }

  changeFaxPrefix(s: string) {
    this.faxPrefix = s;
  }

  alert($event: MouseEvent) {
    $event.preventDefault();
  }


  save() {
    if (!this.providerForm.valid) {
      this.sweetAlertService.notification(this.translate.instant('DIALOG.INSERT_ALL_MANDATORY_INFORMATION')).then(e => {
      });
       this.failed.emit(null);
      return;
    }
    if (!this.editMode) {
      this.providerService.addNewProvider(this.providerForm.getRawValue()).subscribe(d => {
        this.saved.emit(d);
      }, error =>  this.failed.emit(null));
    } else {
      this.providerService.editProvider(this.providerForm.getRawValue()).subscribe(d => {
        this.saved.emit(d);
      }, error =>  this.failed.emit(null));
    }
  }

  getPaymentConfigurationsByType() {
    this.configurationService.getLazyPaymentType({page: this.paymentTypePage, pageSize: 10}).subscribe(d => {
      this.payments = [...this.payments, ...d.data];
      this.paymentTypePage++;
    });
  }

  capSearch(term: string, item: ZipCode) {
    return item.city.toLowerCase().startsWith(term.toLowerCase()) || item.cap.toLowerCase().startsWith(term.toLowerCase());
  }
}

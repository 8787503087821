import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {InitAccountComponent} from './init-account.component';
import {  MatSnackBarModule} from '@angular/material/snack-bar';
import {MatStepperModule} from '@angular/material/stepper';
import {TranslateModule} from '@ngx-translate/core';
import {ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {MatLegacyButtonModule} from '@angular/material/legacy-button';
import {  MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';

const routes: Routes = [
  {path: ':mailKey', component: InitAccountComponent}
];
@NgModule({
  declarations: [InitAccountComponent],
  imports: [
    CommonModule,
    MatSnackBarModule,
    RouterModule.forChild(routes),
    MatStepperModule,
    TranslateModule,
    ReactiveFormsModule,
    NgSelectModule,
    MatLegacyButtonModule,
    MatCardModule,
    MatIconModule,
    NgxMaterialTimepickerModule
  ]
})
export class InitAccountModule { }

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getBodyZone'
})
export class GetBodyZonePipe implements PipeTransform {

  transform(value: any, bodyZones): any {
    return bodyZones.find(u => u.zone === value) ? bodyZones.find(u => u.zone === value).value : '';
  }

}

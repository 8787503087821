import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {SearchResponse} from '../dto/search-response';
import {LazyRequest} from '../dto/lazy-request';
import {Treatment} from '../models/treatment';

@Injectable({
  providedIn: 'root'
})
export class TreatmentMgmService {

  TREATMENT_API = environment.api + '/treatments';

  constructor(private http: HttpClient) {
  }

  addNewTreatment(treatment: Treatment) {
    return this.http.post<Treatment>(`${this.TREATMENT_API}`, treatment);
  }
  getTreatmentsByCategoryId(categoryId){
    return this.http.get(`${this.TREATMENT_API}/get-all-treatments-by-categoryId/${categoryId}`);
  }

  editTreatment(treatment: Treatment) {
    console.log(treatment)
    return this.http.put<Treatment>(`${this.TREATMENT_API}`, treatment);
  }

  getTreatment(treatmentId) {
    return this.http.get<Treatment>(`${this.TREATMENT_API}/${treatmentId}`);
  }


  getLazyTreatmentList(request: TreatmentLazyRequest) {
    return this.http.post<SearchResponse<Treatment>>(`${this.TREATMENT_API}/filter`, request);
  }

  getTreatmentsByIds(ids: number[]) {
    return this.http.post<Treatment[]>(`${this.TREATMENT_API}/find-by-ids`, ids);
  }

  delete(id: number) {
    return this.http.delete(`${this.TREATMENT_API}/${id}`, {observe: 'response'});
  }
  findTreatmentIdByProdCodeAndCenterId(prodCode:String){
    return this.http.post<Treatment>(`${this.TREATMENT_API}/find-treatment-id-by-prod-code-and-center-id`, prodCode);
  }
}


class TreatmentLazyRequest extends LazyRequest {
  textSearch?: string;
  priceNotNul?: boolean;
}

import {TypePlanning} from '../enum/type-planning';
import {WorkTime} from './workTime';

export class YearPlanning {
  id: number;
  uuid: string;
  year: number;
  planningPosition: number;
  planningColor: string;
  type: TypePlanning;
  operatorId: number;
  cabinId: number;
  weeksPlanning: WeekPlanning[];
}

export class WeekPlanning {
  id: number;
  uuid: string;
  month: number;
  order: number;
  yearOrder: number;
  year: number;
  workTimeDto: WorkTime;
  weekPlanningTemplateId: number;
  weekPlanningName: string;
  yearPlanningId: number;
}

export class Year {
  name: number;
  months: Month[];
}

export class Month {
  name: string;
  weeks: Week[] = [];
}

export class Week {
  id: number;
  uuid: string;
  order: number;
  days: Day[];
  month: number;
  year: number;
  weekPlanningTemplateId: number;
  workTimeDto: WorkTime;
}

export class Day {
  number: number | null;
  name: string;
}

export class WeekPlanningTemplate {

  id: number;
  uuid: string;
  name: string;
  description: string;
  seq?: number;

  workTimeDto: WorkTime;
  //
  // mondayDayOff: boolean;
  // mondayWithBreak: boolean;
  // mondayMorFrom: Time;
  // mondayMorTo: Time;
  // mondayAfterFrom: Time;
  // mondayAfterTo: Time;
  //
  // tuesDayOff: boolean;
  // tuesWithBreak: boolean;
  // tuesMorFrom: Time;
  // tuesMorTo: Time;
  // tuesAfterFrom: Time;
  // tuesAfterTo: Time;
  //
  // wedDayOff: boolean;
  // wedWithBreak: boolean;
  // wedMorFrom: Time;
  // wedMorTo: Time;
  // wedAfterFrom: Time;
  // wedAfterTo: Time;
  //
  // thurDayOff: boolean;
  // thurWithBreak: boolean;
  // thurMorFrom: Time;
  // thurMorTo: Time;
  // thurAfterFrom: Time;
  // thurAfterTo: Time;
  //
  // friDayOff: boolean;
  // friWithBreak: boolean;
  // friMorFrom: Time;
  // friMorTo: Time;
  // friAfterFrom: Time;
  // friAfterTo: Time;
  //
  // satDayOff: boolean;
  // satWithBreak: boolean;
  // satMorFrom: Time;
  // satMorTo: Time;
  // satAfterFrom: Time;
  // satAfterTo: Time;
  //
  // sunDayOff: boolean;
  // sunWithBreak: boolean;
  // sunMorFrom: Time;
  // sunMorTo: Time;
  // sunAfterFrom: Time;
  // sunAfterTo: Time;
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {BreadcrumbService} from '../../../../core/services/breadcrumb.service';
import {PreferredContact} from '../../../../shared/models/preferred-contact';
import {UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {ConfigurationsService} from '../../../../shared/services/configurations.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {SweetAlertService} from '../../../../shared/services/sweet-alert.service';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {LazyRequest} from '../../../../shared/dto/lazy-request';
import {HowDidYouMeetUs} from '../../../../shared/models/how-did-you-meet-us';
import {HowDidYouMeetUsFormModalComponent} from './how-did-you-meet-us-form-modal.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-how-did-you-meet-us',
  templateUrl: './how-did-you-meet-us.component.html',
  styleUrls: ['./how-did-you-meet-us.component.scss']
})
export class HowDidYouMeetUsComponent implements OnInit, OnDestroy {
  columns = [
    'DATA_TABLE.ID',
    'DATA_TABLE.HOW_DID_YOU_MEET_US'
  ];
  rows: PreferredContact[] = [];
  searchFormControl = new UntypedFormControl();
  totalRecords: number;
  pageSize = 10;
  page = 1;
  unsubscribe = new Subject();
  loading = false;
  firstCall = false;
  orderType: 'ASC' | 'DESC' = 'DESC';


  constructor(private configurationsService: ConfigurationsService,
              private matDialog: MatDialog,
              private sweetAlertService: SweetAlertService,
              private breadcrumbService: BreadcrumbService,
              private  translate: TranslateService) { }

  ngOnInit() {
    this.sendBreadCrumb();
    this.getLazyHowDidYouMeetUs({page: this.page, pageSize: this.pageSize});
    this.searchFormControl.valueChanges.pipe(debounceTime(500), takeUntil(this.unsubscribe)).subscribe(text => {
      this.getLazyHowDidYouMeetUs({page: 1, pageSize: this.pageSize, textSearch: text});
    });
  }

  sendBreadCrumb(): void {
    this.breadcrumbService.sendBreadcrumb(['CONFIGURATION', 'HOW_DID_YOU_MEET_US']);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.unsubscribe();
  }

  openFormDialog() {
    const dialogRef = this.matDialog.open(HowDidYouMeetUsFormModalComponent, {
      width: '400px',
      autoFocus: true,
      disableClose: true,
      data: {editMode: false}
    });
    dialogRef.afterClosed().subscribe(d => {
      if (d) {
        this.page = 1;
        this.getLazyHowDidYouMeetUs({page: 1, pageSize: this.pageSize});
      }
    });
  }

  deleteHowDidYouMeetUs(el: any, i: number) {
    this.sweetAlertService.warning(this.translate.instant('DIALOG.YOU_WANT_TO_DELETE') + el.description).then(e => {
      if (e.value) {
        this.configurationsService.deleteHowDidYouMeetUs(el.id).subscribe(d => {
          if (d.status === 200) {
            this.getLazyHowDidYouMeetUs({page: this.page, pageSize: this.pageSize});
          }
        });
      }
    });
  }

  getLazyHowDidYouMeetUs(request: LazyRequest) {
    this.loading = true;
    return this.configurationsService.getLazyHowDidYouMeetUs(request).subscribe(data => {
      this.rows = data.data;
      this.totalRecords = data.totalRecords;
      this.loading = false;
      this.firstCall = false;
    });
  }

  openEditHowDidYouMeetUs(howDidYouMeetUs: HowDidYouMeetUs, i: number) {
    const dialogRef = this.matDialog.open(HowDidYouMeetUsFormModalComponent, {
      width: '400px',
      disableClose: true,
      data: {editMode: true, suggestion: howDidYouMeetUs}
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(d => {
      if (d) {
        this.getLazyHowDidYouMeetUs({page: this.page, pageSize: this.pageSize, textSearch: this.searchFormControl.value});
      }
    });
  }

  pageChange(page: number) {
    this.page = page;
    this.getLazyHowDidYouMeetUs({page: this.page, pageSize: this.pageSize, textSearch: this.searchFormControl.value});
  }

  sortRows(c: string) {
    const sort = c.substr(11);
    if (this.orderType === 'ASC') {
      this.orderType = 'DESC';
    } else {
      this.orderType = 'ASC';
    }
    switch (sort) {
      case 'ID':
        this.getLazyHowDidYouMeetUs({
          page: this.page, pageSize: this.pageSize, textSearch: this.searchFormControl.value, sort: {
            attribute: 'id',
            direction: this.orderType
          }
        });
        break;
      case 'HOW_DID_YOU_MEET_US':
        this.getLazyHowDidYouMeetUs({
          page: this.page, pageSize: this.pageSize, textSearch: this.searchFormControl.value, sort: {
            attribute: 'description',
            direction: this.orderType
          }
        });
        break;
    }
  }
}

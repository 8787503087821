import {RtService} from './../rt.service';
import {ProductMgmService} from './../product-mgm.service';
import {TreatmentMgmService} from './../treatment-mgm.service';
import {SweetAlertService} from './../sweet-alert.service';
import {RtDriverService} from 'src/app/shared/services/RtDriver/rt-driver.service';
import {Injectable} from '@angular/core';
import {forkJoin, Observable, Subscriber} from 'rxjs';
import {ConfigRtDto} from '../../dto/config-rt-dto';
import {FidelityCard} from '../../models/fidelity-card';
import {PaymentEntry} from '../../models/payment-entry';
import {PaymentElementType} from '../../enum/payment-element-type';
import {MappedCard, MappedQuote, MappedSubscription} from '../../models/mapped-card';
import {Payment} from '../../models/payment';
import {Client} from '../../models/client';
import {PaymentMode} from '../../enum/payment-mode';
import {
  MappedAcconto,
  MappedPayment,
  MappedPaymentEntry,
  MappedPaymentMethod,
  MappedPaymentMethodType,
  PaymentEntryType
} from '../../models/mapped-payment';
import {PaymentMethodsType} from '../../enum/payment-methods-type';
import {Abonnement} from '../../models/Abonnement';
import {TranslateService} from '@ngx-translate/core';
import {DiscountMode} from '../../enum/discount-mode';
import {PaymentResponse} from '../../models/payment-response';
import {SubscriptionService} from '../subscription.service';

@Injectable({
  providedIn: 'root'
})
export class RtDriverMapperService {

  constructor(private rtDriverService: RtDriverService, private sweetAlertService: SweetAlertService, private translate: TranslateService,
      private rtService: RtService,
     private treatmentService: TreatmentMgmService, private productService: ProductMgmService, private subscriptionService: SubscriptionService) { }

  mapConfigurations(conf): ConfigRtDto {
    const configRtDto: ConfigRtDto = new ConfigRtDto();

    configRtDto.active = true;

    configRtDto.departmentRtList = [];
    let sequenceNumber = 1;
    conf.departmentConfig.map(dep => {
      const obj = {
        description: dep.Description,
        groupCode: 0,
        halo: 0,
        id: null,
        lalo: 0.01,
        price: Number(dep.UnitPrice),
        sequence: sequenceNumber,
        single: null,
        updated: null,
        vatRtSequence: dep.VATGroup
      };
      sequenceNumber++;
      configRtDto.departmentRtList.push(obj);
    });

    configRtDto.vatList = [];
    conf.vatConfig.map(vat => {
      const obj = {
        id: null,
        sequence: vat.VatNumber,
        updated: null,
        uuid: null,
        vat: vat.VatRate
      };
      configRtDto.vatList.push(obj);
    });


    configRtDto.paymentRtList = [];
    sequenceNumber = 1;
    conf.paymentConfig.map(payment => {
      const obj = {
        cash: (payment.paymentMethodType === 0) ? true : false,
        change: false,
        credit: false,
        creditType: sequenceNumber,
        description: payment.description,
        drawer: false,
        id: null,
        inputTotalAmount: false,
        mandatoryAmount: null,
        payDiscount: false,
        sequence: 1,
        ticket: false,
        updated: null,
        uuid: null
      };
      sequenceNumber++;
      configRtDto.paymentRtList.push(obj);
    });

    configRtDto.operatorRtList = [];
    configRtDto.header = [];

    configRtDto.enableLottery = true;
    configRtDto.startLotteryDate = new Date(2021, 1, 1);

    return configRtDto;
  }

  public checkStatusAndPrintDriverNotFiscal(r: PaymentResponse, courtesyEntry: PaymentEntry, fidelityCard: FidelityCard, valueUsed: number, clientSelected: Client): Observable<boolean> {
    // 
    return Observable.create((observer: Subscriber<boolean>) => {
      this.rtDriverService.getStatus(r.ip, r.rtModel).subscribe(status => {

        // I CAN PRINT
        if (status.FullStatusCode === '00110' || status.FullStatusCode === '20110') {

          if (courtesyEntry.type === PaymentElementType.CARD || courtesyEntry.paymentMethod.subscription === 'RELOAD_CARD' || courtesyEntry.paymentMethod.subscription === 'GIFT_CARD') {
            let mappedFidelity: MappedCard;
            if (valueUsed !== -1) {
              mappedFidelity = this.mapFidelity(clientSelected.id, fidelityCard, valueUsed)
            } else {
              mappedFidelity = this.mapFidelity(clientSelected.id, fidelityCard);
            }

            this.rtDriverService.fidelity(r.ip, r.rtModel, mappedFidelity).subscribe((success) => {
              observer.next(success);
              observer.complete();
            },  error => {
              this.sweetAlertService.danger(this.translate.instant('DIALOG.FAILED_TO_CONNECT_TO_PRINT')).then(e => {});
            });
          }


          if (courtesyEntry.type === PaymentElementType.SUBSCRIPTION) {
            this.mapSubscription(courtesyEntry).subscribe((mappedSubscription) => {
              this.rtDriverService.subscription(r.ip, r.rtModel, mappedSubscription).subscribe((success) => {
                observer.next(success);
                observer.complete();
              },   error => {
                this.sweetAlertService.danger(this.translate.instant('DIALOG.FAILED_TO_CONNECT_TO_PRINT')).then(e => {});
              });
            })
          }
        }
      }, error => {
        this.sweetAlertService.danger(this.translate.instant('DIALOG.FAILED_TO_CONNECT_TO_PRINT')).then(e => {});
      });
    });

  }
  public mapPayment(payment: Payment, rtModel: string, secondPay?: boolean): Observable<MappedPayment> {

    let buonoMultiuso: number = 0;  // LA SOMMA CORRISPONDENTE ALLA MODALITA' DI PAGAMENTO COME BUONO MULTIUSO
    let buonoMonouso: number = 0; // LA SOMMA CORRISPONDENTE ALLA MODALITà DI PAGAMENTO NON RISCOSSO BENI

    const observablesSubscriptions: number[] = []; // SE MONOUSO, DEVO ESTRARRE IL REPARTO DEI BENI AL SUO INTERNO
    const observablesSubscriptionsPaymentMode: Observable<Abonnement>[] = [];  // SE PAGO CON ABBONAMENTO ED E' UN PRODOTTO, DEVO ESTRARRE L'ABBONAMENTO PER CAPIRE SE MONOUSO O MULTIUSO

    const tempSubscriptionPaymentMode: number[] = [];

    return Observable.create((observer: Subscriber<MappedPayment>) => {

      const mappedPayment: MappedPayment = new MappedPayment();

      mappedPayment.date = new Date();
      mappedPayment.amount =  (payment.advance) ? payment.total - payment.advance : payment.amount;
      mappedPayment.total = payment.total;
      mappedPayment.lotteryCode = (payment.lottery !== '') ? payment.lottery : null;

      const ivaId: number[] = this.createIvaCodeArray(payment);

      this.rtService.getIvaCode(ivaId).subscribe((departments) => {
        let i = 0;
        payment.paymentsEntry.map(entry => {
          const mappedPaymentEntry: MappedPaymentEntry = new MappedPaymentEntry();

          switch(entry.type) {
            case PaymentElementType.PRODUCT: {
              mappedPaymentEntry.type = PaymentEntryType.PRODUCT;
              break;
            }
            case PaymentElementType.TREATMENT: {
              mappedPaymentEntry.type = PaymentEntryType.SERVICE;
              break;
            }
            case PaymentElementType.SUBSCRIPTION: {
              mappedPaymentEntry.type = PaymentEntryType.SUBSCRIPTION;
              break;
            }
          }

          mappedPaymentEntry.description = entry.advance && !secondPay ? "Acconto " + entry.description : entry.description;
          mappedPaymentEntry.unitPricing = entry.unitPricing;
          mappedPaymentEntry.quantity = entry.quantity;
          mappedPaymentEntry.total = entry.unitPricing * entry.quantity;
          if (entry.discount > 0) {
            mappedPaymentEntry.discount = entry.discountMode === DiscountMode.AMOUNT ? entry.discount : ((entry.unitPricing * entry.quantity) * (entry.discount / 100));
          }

          //PRIMO ACCONTO
          if (entry.advance) {
            if (secondPay) {

              const mappedAcconto: MappedAcconto = new MappedAcconto();
              mappedAcconto.first = false;
              mappedAcconto.second = true;
              mappedAcconto.accontoAmount = entry.advanceAmount;
              mappedPaymentEntry.acconto = mappedAcconto;

              // AGGIUNGO I METODI DI PAGAMENTO ASSOCIATI
              const mappedPaymentMethod: MappedPaymentMethod = new MappedPaymentMethod();
              mappedPaymentMethod.type = (entry.type === 'TREATMENT') ? MappedPaymentMethodType.NON_RISCOSSO_SERVIZI : MappedPaymentMethodType.NON_RISCOSSO_BENI;
              mappedPaymentMethod.amount = entry.advanceAmount;
              mappedPayment.paymentMethods.push(mappedPaymentMethod);

            } else {

              const mappedAcconto: MappedAcconto = new MappedAcconto();
              mappedAcconto.first = true;
              mappedAcconto.second = false;
              mappedAcconto.accontoAmount = entry.advanceAmount;
              mappedPaymentEntry.acconto = mappedAcconto;

            }
          }

          // METODI DI PAGAMENTO

          if (!entry.advance) {

            // METODO DI PAGAMENTO --> FIDELITY

            if (entry && entry.paymentMethod && entry.paymentMethod.subscription) {
              if (entry.paymentMethod.subscription === PaymentMode.RELOAD_CARD || entry.paymentMethod.subscription === PaymentMode.GIFT_CARD) {
                buonoMultiuso += mappedPaymentEntry.total - (mappedPaymentEntry.discount ? mappedPaymentEntry.discount : 0) - entry.total;
                //buonoMultiuso += (entry.unitPricing * entry.quantity) - entry.totalDiscount - entry.total;
              }

              // METODO DI PAGAMENTO --> GIFT
              if (entry.paymentMethod.subscription === PaymentMode.GIFT) {
                mappedPaymentEntry.omaggio = true;
              }

              // METODO DI PAGAMENTO --> ABBONAMENTO
              if (entry.paymentMethod.subscription === PaymentMode.SUBSCRIPTION) {

                if (entry.type === PaymentElementType.PRODUCT) {
                  // ESTRAGGO IL TIPO DI ABBONAMENTO
                  observablesSubscriptionsPaymentMode.push(this.subscriptionService.getByInstance(entry.paymentMethod.data));
                  tempSubscriptionPaymentMode.push((entry.unitPricing * entry.quantity) - entry.totalDiscount - entry.total);
                } else {
                  buonoMultiuso += (entry.unitPricing * entry.quantity) - entry.totalDiscount - entry.total;
                }
              }
            }

          }

          // ATTRIBUISCO DEPARTMENT RT
          if (this.mapDepartmentRT(entry, mappedPaymentEntry, departments[i], rtModel, observablesSubscriptions)) ++i;

          mappedPayment.paymentEntry.push(mappedPaymentEntry);
        });


        if (observablesSubscriptionsPaymentMode.length > 0) {
          forkJoin(observablesSubscriptionsPaymentMode).subscribe(subscriptions => {
            let i = 0;
            subscriptions.map(sub => {
              if (this.CheckMonouso(sub)) {
                buonoMonouso += tempSubscriptionPaymentMode[i];
              } else {
                buonoMultiuso += tempSubscriptionPaymentMode[i];
              }
              ++i;
            })

            this.mapPaymentMethods(payment, mappedPayment, buonoMultiuso, buonoMonouso);

            if (observablesSubscriptions.length > 0) {

              this.rtService.getIvaCode(observablesSubscriptions).subscribe((r) => {
                let i = 0;
                mappedPayment.paymentEntry.map(entry => {
                  if (entry.type === PaymentEntryType.SUBSCRIPTION && entry.sameIVA) {
                    entry.RTDepartment = r[i];
                    ++i;
                  }
                })

                observer.next(mappedPayment);
                observer.complete();
              })
            } else {
              observer.next(mappedPayment);
              observer.complete();
            }
          });
        } else {

          this.mapPaymentMethods(payment, mappedPayment, buonoMultiuso, buonoMonouso);

          if (observablesSubscriptions.length > 0) {

            this.rtService.getIvaCode(observablesSubscriptions).subscribe((r) => {
              let i = 0;
              mappedPayment.paymentEntry.map(entry => {
                if (entry.type === PaymentEntryType.SUBSCRIPTION && entry.sameIVA) {
                  entry.RTDepartment = r[i];
                  ++i;
                }
              })

              observer.next(mappedPayment);
              observer.complete();
            })

          } else {
            observer.next(mappedPayment);
            observer.complete();
          }
        }
      });
    });
  }

  public mapDepartmentRT(entry: PaymentEntry, mappedPaymentEntry: MappedPaymentEntry, department: number, rtModel: string, observablesSubscriptions: number[]): boolean {

    if (entry.type !== PaymentElementType.CARD && entry.type !== PaymentElementType.SUBSCRIPTION) {
      mappedPaymentEntry.RTDepartment = department;
      return true;
    } else {

      if (entry.type === PaymentElementType.CARD) {
        if (rtModel === 'EPSON') {
          mappedPaymentEntry.RTDepartment = 11;
        }
      }

      if (entry.type === PaymentElementType.SUBSCRIPTION) {
        // SE TUTTI HANNO LA STESSA IVA, MONOUSO
        // SE IVA DIVERSA, MULTIUSO
        let sameIVA = this.CheckMonouso(entry.subscription);

        if (!sameIVA) {
          mappedPaymentEntry.sameIVA = false;
          if (rtModel === 'EPSON') {
            mappedPaymentEntry.RTDepartment = 11;
          }
        }
        if (sameIVA) {
          mappedPaymentEntry.sameIVA = true;
          if (rtModel === 'EPSON') {
            observablesSubscriptions.push(entry.subscription.products[0].product.ivaCodeId);
          }
        }
      }
      return false;
    }
  }
  public mapPaymentMethods(payment: Payment, mappedPayment: MappedPayment, buonoMultiuso: number, buonoMonouso: number) {
    payment.paymentMethods.map((method) => {
      const mappedPaymentMethod: MappedPaymentMethod = new MappedPaymentMethod();
      switch (method.type) {
        case PaymentMethodsType.CASH: {
          mappedPaymentMethod.type = MappedPaymentMethodType.CASH;
          break;
        }
        case PaymentMethodsType.CREDIT_CARD: {
          mappedPaymentMethod.type = MappedPaymentMethodType.CREDIT_CARD;
          break;
        }
        case PaymentMethodsType.CHEQUE: {
          mappedPaymentMethod.type = MappedPaymentMethodType.CHEQUE;
          break;
        }
      }

      mappedPaymentMethod.amount = (payment.discountToPay > 0) ? mappedPayment.amount - payment.discountToPay : mappedPayment.amount;

      mappedPayment.paymentMethods.push(mappedPaymentMethod);
    });

    if (payment.discountToPay > 0) {
      const mappedPaymentMethod: MappedPaymentMethod = new MappedPaymentMethod();
      mappedPaymentMethod.type = MappedPaymentMethodType.DISCOUNT_ON_PAYMENT;
      mappedPaymentMethod.amount = payment.discountToPay;

      mappedPayment.paymentMethods.push(mappedPaymentMethod);
    }

    if (buonoMultiuso > 0) {
      const mappedPaymentMethod: MappedPaymentMethod = new MappedPaymentMethod();
      mappedPaymentMethod.type = MappedPaymentMethodType.BUONO_MULTIUSO;
      mappedPaymentMethod.amount = buonoMultiuso;

      mappedPayment.paymentMethods.push(mappedPaymentMethod);
    }

    if (buonoMonouso > 0) {
      const mappedPaymentMethod: MappedPaymentMethod = new MappedPaymentMethod();
      mappedPaymentMethod.type = MappedPaymentMethodType.NON_RISCOSSO_BENI;
      mappedPaymentMethod.amount = buonoMonouso;

      mappedPayment.paymentMethods.push(mappedPaymentMethod);
    }
  }

  public mapFidelity(idClient: number, fidelityCard: FidelityCard, valueUsed?: number ): MappedCard {
      const mappedCard: MappedCard = new MappedCard();
      mappedCard.amount = fidelityCard.currentAmount;
      mappedCard.number = fidelityCard.number;
      mappedCard.name = fidelityCard.name;
      mappedCard.cardType = fidelityCard.cardType;

      if (valueUsed) {
        mappedCard.usage = valueUsed;
      }

      if (fidelityCard.discount !== null) {
        mappedCard.discount = String(fidelityCard.discount)  + "%";
      }

      const date = new Date(); //Data di emissione
      let expirationDate = null;
      if (fidelityCard.duoDate) {
        expirationDate = new Date(fidelityCard.duoDate);
      }

      mappedCard.emissionDate = (date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`) + '/' + (date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`) + '/' + String(date.getFullYear());
      if (expirationDate) {
        mappedCard.expirationDate = (expirationDate.getDate() < 10 ? `0${expirationDate.getDate()}` : `${expirationDate.getDate()}`) + '/' + (expirationDate.getMonth() + 1 < 10 ? `0${expirationDate.getMonth() + 1}` : `${expirationDate.getMonth() + 1}`) + '/' + String(expirationDate.getFullYear());
      }

      return mappedCard;
  }

  public mapSubscription(entry: PaymentEntry): Observable<MappedSubscription> {
    return Observable.create((observer: Subscriber<MappedSubscription>) => {
      const mappedSubscription: MappedSubscription = new MappedSubscription();
      mappedSubscription.amount = entry.unitPricing;
      mappedSubscription.name = entry.description;

      const today = new Date();
      mappedSubscription.emissionDate = String(today.getDate()) + "/" + String(today.getMonth() + 1) + "/" + String(today.getFullYear());

      mappedSubscription.entries = [];
      if (entry.subscription.product) {
        entry.subscription.products.map(product => {
          mappedSubscription.entries.push({
            description: product.product.description,
            quantity: product.quantity
          })
        })
      }

      if (entry.subscription.treatment) {
        entry.subscription.treatments.map(treatment => {
          mappedSubscription.entries.push({
            description: treatment.treatment.description,
            quantity: treatment.quantity
          })
        })
      }

      observer.next(mappedSubscription);
      observer.complete();

    });
  }

  private createIvaCodeArray(payment: Payment): number[] {

    const ivaId: number[] = []

    payment.paymentsEntry.map((entry) => {
      if (entry.type !== PaymentElementType.CARD && entry.type !== PaymentElementType.SUBSCRIPTION) {

        if (entry.ivaId) {
          ivaId.push(entry.ivaId);
        } else {
          if (entry.type === 'TREATMENT') {
            ivaId.push(entry.treatment.ivaCode.id);
          }
          if (entry.type === "PRODUCT") {
            ivaId.push(entry.product.ivaCodeId);
          }
        }
      }
    });

    return ivaId;
  }

  public getIvaCodeId(payment: Payment): Observable<boolean> {
    // SE ESISTONO DEI GIFT, DEVO ATTRIBUIRE IL CODICE IVA

    const subscription: Observable<any>[] = [];
    const gift: PaymentEntry[] = [];

    return Observable.create((observer: Subscriber<boolean>) => {
      (payment as Payment).paymentsEntry.map(entry => {

        if (entry && entry.paymentMethod && entry.paymentMethod.subscription) {

          if (entry.paymentMethod.subscription === PaymentMode.GIFT) {
            gift.push(entry);
            if (entry.type === PaymentElementType.PRODUCT) {
              subscription.push(this.productService.getProduct((entry as any).elementId));
            }
            if (entry.type === PaymentElementType.TREATMENT) {
              subscription.push(this.treatmentService.getTreatment((entry as any).elementId));
            }
          }
        }

      });


      if (subscription.length === 0) {
        observer.next(true);
        observer.complete();
        return;
      }

      forkJoin(subscription).subscribe(elements => {
        let i = 0;
        gift.map(entry => {
          entry.ivaId = elements[i].ivaCode.id;

          if (entry.type === PaymentElementType.PRODUCT) {
            entry.unitPricing = elements[i].price;
          }
          if (entry.type === PaymentElementType.TREATMENT) {
            entry.unitPricing = elements[i].masterPrice;
          }

          ++i;
        })
        observer.next(true);
        observer.complete();
      })
    });
  }

  private CheckMonouso(subscription) {
    if (subscription.treatment) {
      return false;
    }

    if (subscription.product) {

      for (let i = 0; i < subscription.products.length; ++i) {
        for (let j = i + 1; j < subscription.products.length; ++j) {
          const value1 = subscription.products[i].ivaValue;
          const value2 = subscription.products[j].ivaValue;

          if (value1 !== value2) {
            return false;
          }
        }
      }
      return true;
    }
    return false;
}

public mapQuote(payment: Payment, client?: Client): MappedQuote {
    const mappedQuote: MappedQuote = new MappedQuote();

    if (client) {
        mappedQuote.client = `${client.firstName} ${client.lastName}`;
        mappedQuote.dataNascita = `${client.dateOfBirth}`;
        mappedQuote.codiceFiscale = `${client.fiscalCode}`;
        mappedQuote.telefono = `${client.mobile}`;
    }

    mappedQuote.paymentEntry = [];

    payment.paymentsEntry.map(entry => {
        mappedQuote.paymentEntry.push({description: entry.description, total: entry.total, iva: (entry as any).ivaCode})
    });

    return mappedQuote;
}
}

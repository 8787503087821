import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
@Injectable()
export class ProdCodeMgmService {
  PRODUCT_API = environment.api + '/plu';
  constructor(
    private http: HttpClient
  ) { }
  isUnique(request: string){
    return this.http.post<boolean>(`${this.PRODUCT_API}/is-unique`, request);
  }

  generateProdCode() {
    return this.http.get<string>(`${this.PRODUCT_API}/generate-prod-code`);
  }
}

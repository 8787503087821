// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form {
  width: 40%;
}

@media screen and (max-width: 426px) {
  form {
    width: 100%;
  }
}
.coolQRCode {
  filter: drop-shadow(3px 3px 3px #999);
  text-align: center !important;
}
.coolQRCode img {
  width: 250px;
}`, "",{"version":3,"sources":["webpack://./src/app/content/pages/configurations/app-configurations/app-configurations.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;AACF;;AAEA;EACE;IACE,WAAA;EACF;AACF;AACA;EACE,qCAAA;EAIA,6BAAA;AAFF;AADE;EACE,YAAA;AAGJ","sourcesContent":["form {\r\n  width: 40%;\r\n}\r\n\r\n@media screen and (max-width: 426px){\r\n  form {\r\n    width: 100%;\r\n  }\r\n}\r\n.coolQRCode {\r\n  filter: drop-shadow(3px 3px 3px #999);\r\n  img {\r\n    width: 250px;\r\n  }\r\n  text-align: center !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import {Component, OnInit, ViewChild} from '@angular/core';
import {SatispayService} from '../../../../shared/services/satispay.service';
import {UntypedFormControl, Validators} from '@angular/forms';
import {finalize} from 'rxjs/operators';
import {SweetAlertService} from '../../../../shared/services/sweet-alert.service';
import {BreadcrumbService} from '../../../../core/services/breadcrumb.service';
import {  MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-satispay',
  templateUrl: './satispay.component.html',
  styleUrls: ['./satispay.component.scss']
})
export class SatispayComponent implements OnInit {
  @ViewChild('confirmResetDialog', { static: false }) confirmResetDialog;
  tokenFormControl = new UntypedFormControl(null, Validators.required);
  rsaPrivateKey = null; // pkcs8
  rsaPublicKey = null; // pkcs8
  keyId = null;
  token = null;
  loading = false;
  tokenVerifyFormControl: UntypedFormControl;

  constructor(
    private satispayService: SatispayService,
    private sweetAlertService: SweetAlertService,
    private breadcrumbService: BreadcrumbService,
    private matSnackbar: MatSnackBar,
    private translateService: TranslateService,
    private matDialog: MatDialog) {
  }

  ngOnInit() {
    this.breadcrumbService.sendBreadcrumb(['CONFIGURATION', 'SATISPAY_CONFIG']);
    this.satispayService.getCurrentConfig().subscribe(v => {
      if (!v || !v.keyId) {
        return;
      }
      this.rsaPrivateKey = v.rsaPrivateKey;
      this.rsaPublicKey = v.rsaPublicKey;
      this.keyId = v.keyId;
      this.token = v.token;
      this.tokenFormControl.setValue(this.token);
      this.tokenVerifyFormControl = new UntypedFormControl(null, [Validators.required, Validators.pattern(this.token)]);
    });
  }

  addConfig() {
    if (this.tokenFormControl.invalid) {
      return;
    }
    this.loading = true;
    this.satispayService.addConfig(this.tokenFormControl.value).pipe(finalize(() => this.loading = false)).subscribe(resp => {
      if (resp.success) {
        this.satispayService.getCurrentConfig().subscribe(v => {
          this.rsaPrivateKey = v.rsaPrivateKey;
          this.rsaPublicKey = v.rsaPublicKey;
          this.keyId = v.keyId;
          this.token = v.token;
          this.tokenVerifyFormControl = new UntypedFormControl(null, [Validators.required, Validators.pattern(this.token)]);
        });
      } else {
        this.matSnackbar.open(this.translateService.instant('SATISPAY.' + resp.message), null, {duration: 5000});
      }
    });
  }

  testAuthentication() {
    this.satispayService.testAuthentication().subscribe(resp => {
      if (resp && resp.authentication_key.role === 'ONLINE_SHOP') {
        this.sweetAlertService.success('You have been authenticated successfully');
      } else {
        this.sweetAlertService.danger('Authentication failed');
      }
    });
  }

  resetConfig(token) {
    this.matDialog.open(this.confirmResetDialog, {data: token, width: '500px'});
  }

  confirmDelete() {
    this.satispayService.resetConfig().subscribe(() => {
      this.matDialog.closeAll();
      this.rsaPrivateKey = null;
      this.rsaPublicKey = null;
      this.keyId = null;
      this.token = null;
      this.tokenFormControl.setValue(null);
    });
  }
}

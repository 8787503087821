import {Component, OnInit} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {FirstNoteCauseFormDialogComponent} from './first-note-cause-form-dialog/first-note-cause-form-dialog.component';
import {FirstNoteCauseService} from '../../../../shared/services/first-note-cause.service';
import {FirstNoteCause} from '../../../../shared/models/first-note';
import {SweetAlertService} from '../../../../shared/services/sweet-alert.service';
import {TranslateService} from '@ngx-translate/core';
import {  MatSnackBar} from '@angular/material/snack-bar';
import {BreadcrumbService} from '../../../../core/services/breadcrumb.service';

@Component({
  selector: 'app-first-note-cause',
  templateUrl: './first-note-cause.component.html',
  styleUrls: ['./first-note-cause.component.scss']
})
export class FirstNoteCauseComponent implements OnInit {
  causes: FirstNoteCause[];

  constructor(
    private matDialog: MatDialog,
    private firstNoteCauseService: FirstNoteCauseService,
    private sweetAlertService: SweetAlertService,
    private translateService: TranslateService,
    private matSnackBar: MatSnackBar,
    private breadcrumbService: BreadcrumbService) {
  }

  ngOnInit() {
    this.firstNoteCauseService.getAll().subscribe(r => this.causes = r);
    this.breadcrumbService.sendBreadcrumb(['FIRST_NOTE_CAUSE']);
  }

  openCauseForm(editMode, cause?) {
    this.matDialog.open(FirstNoteCauseFormDialogComponent, {width: '400px', disableClose: true, data: {editMode, cause}})
      .afterClosed().subscribe(v => {
      if (v) {
        this.firstNoteCauseService.getAll().subscribe(r => this.causes = r);
      }
    });
  }

  delete(id, index) {
    this.sweetAlertService.warning(this.translateService.instant('FIRST_NOTE.DELETE_CAUSE_MSG'))
      .then(u => {
        if (u.value) {
          this.firstNoteCauseService.delete(id).subscribe(() => {
            this.causes.splice(index, 1);
          }, () => {
            this.matSnackBar.open(this.translateService.instant('DIALOG.CANNOT_DELETE'), 'Ok', {duration: 3000});
          });
        }
      });
  }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  position: relative;
}

h4 {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%);
  font-weight: bold;
  font-size: 4rem;
  color: rgba(167, 167, 167, 0.29);
}`, "",{"version":3,"sources":["webpack://./src/app/administrative-content/layout/admin-layout.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,kBAAA;AACF;;AACA;EACE,kBAAA;EACA,YAAA;EACA,SAAA;EACA,0BAAA;EACA,iBAAA;EACA,eAAA;EACA,gCAAA;AAEF","sourcesContent":[":host {\r\n  display: block;\r\n  position: relative;\r\n}\r\nh4 {\r\n  position: absolute;\r\n  bottom: 10px;\r\n  left: 50%;\r\n  transform: translate(-50%);\r\n  font-weight: bold;\r\n  font-size: 4rem;\r\n  color: rgba(167, 167, 167, 0.29);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

export const IMC = [
  {
    value: 'Meno di 16',
    category: 'Molto sottopeso',
    backgroundColor: '#ff887c',
    color: '#df0b06'
  },
  {
    value: 'Da 16 a 18.5',
    category: 'Sottopeso',
    backgroundColor: '#f8ff71',
    color: '#958b1f'
  },
  {
    value: 'Da 18.5 a 25',
    category: 'Normale',
    backgroundColor: '#7eff83',
    color: '#2a8d29'
  },
  {
    value: 'Da 25 a 30',
    category: 'Sovrappeso',
    backgroundColor: '#f8ff71',
    color: '#958b1f'
  },
  {
    value: 'Da 30 a 35',
    category: 'Obesità Grado Ⅰ',
    backgroundColor: '#ff887c',
    color: '#df0b06'
  },
  {
    value: 'Da 35 a 40',
    category: 'Obesità Grado Ⅱ',
    backgroundColor: '#da7b7b',
    color: '#b70b06'
  },
  {
    value: 'Più di 40',
    category: 'Obesità Grado Ⅲ',
    backgroundColor: '#a65656',
    color: '#770b06'
  }
];

export class Tint {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  color: string;
  note: string;
  accountId: number;
  accountDescription: string;
  clientId: number;
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.x {
  overflow-x: scroll;
  overflow-y: hidden;
  width: 440px;
}`, "",{"version":3,"sources":["webpack://./src/app/content/pages/reports/treatment-product-reports/report-per-month/report-per-month.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACE,kBAAA;EACA,YAAA;AACJ","sourcesContent":[".x{\r\n  overflow-x: scroll;\r\n    overflow-y: hidden;\r\n    width: 440px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

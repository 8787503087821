import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CategoryFormModalComponent} from './category-form-modal.component';
import {RouterModule, Routes} from '@angular/router';
import {MatLegacyButtonModule} from '@angular/material/legacy-button';
import {MatDividerModule} from '@angular/material/divider';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTableModule} from '@angular/material/table';
import {TranslateModule} from '@ngx-translate/core';
import {NgbModule, NgbPaginationModule, NgbTypeaheadModule} from '@ng-bootstrap/ng-bootstrap';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../../../../shared/shared.module';
import {CategoryTreatmentComponent} from './category-treatment.component';
import {SubCategoryFormModalComponent} from './sub-category-form-modal.component';
import {ColorPickerModule} from "ngx-color-picker";
import {MatIconModule} from "@angular/material/icon";
import {MatLegacyDialogModule} from "@angular/material/legacy-dialog";

const routes: Routes = [];

@NgModule({
  declarations: [CategoryTreatmentComponent, CategoryFormModalComponent, SubCategoryFormModalComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatLegacyButtonModule,
    TranslateModule,
    MatTableModule,
    MatPaginatorModule,
    NgbPaginationModule,
    MatLegacyDialogModule,
    ReactiveFormsModule,
    SharedModule.forChild(),
    MatDividerModule,
    NgbTypeaheadModule,
    MatProgressSpinnerModule,
    NgbModule,
    ColorPickerModule,
    MatIconModule
  ]
})
export class CategoryTreatmentModule {
}

import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {LazyRequest} from '../dto/lazy-request';
import {SearchResponse} from '../dto/search-response';
import {ReportStatics} from '../models/report-statics';
import {OperatorReport} from '../models/operator-report';

@Injectable({
  providedIn: 'root'
})
export class ReportsMgmService {
  REPORT_API = environment.api + '/reports';

  constructor(private http: HttpClient) {
  }

  getProductReport(request?) {
    return this.http.post<SearchResponse<ReportStatics>>(`${this.REPORT_API}/get-product-reports`, request);
  }

  getTreatmentReport(request?) {
    return this.http.post<SearchResponse<ReportStatics>>(`${this.REPORT_API}/get-treatment-reports`, request);
  }

  getOperatorReport(request) {
    return this.http.post<SearchResponse<OperatorReport>>(`${this.REPORT_API}/get-operator-reports`, request);
  }

  downloadProductExcel(request?) {
    return this.http.post(`${this.REPORT_API}/download-excel-product`, request, {responseType: 'blob', observe: 'response'});
  }

  downloadProductCsv(request?) {
    return this.http.post(`${this.REPORT_API}/download-csv-product`, request, {responseType: 'blob', observe: 'response'});
  }

  downloadTreatmentExcel(request?) {
    return this.http.post(`${this.REPORT_API}/download-excel-treatment`, request, {responseType: 'blob', observe: 'response'});
  }

  downloadOperatorCsv(request?) {
    return this.http.post(`${this.REPORT_API}/download-csv-operator`, request, {responseType: 'blob', observe: 'response'});
  }

  downloadOperatorExcel(request?) {
    return this.http.post(`${this.REPORT_API}/download-excel-operator`, request, {responseType: 'blob', observe: 'response'});
  }

  downloadTreatmentCsv(request?) {
    return this.http.post(`${this.REPORT_API}/download-csv-treatment`, request, {responseType: 'blob', observe: 'response'});
  }

}

class ReportsRequest extends LazyRequest {
  dateTo: Date;
  dateFrom: Date;
  clientId: number;
  categoryId: number;
  timeLaps: string;
  type: string;

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SignupComponent} from './signup.component';
import {RouterModule, Routes} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {NgbCarouselModule, NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {ReactiveFormsModule} from '@angular/forms';
import {MatLegacyButtonModule} from '@angular/material/legacy-button';
import {NgSelectModule} from '@ng-select/ng-select';
import {HttpClientModule} from '@angular/common/http';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {EmailSentDialogComponent} from './email-sent-dialog.component';
import {MatLegacyDialogModule as MatLegacyDialogModule} from '@angular/material/legacy-dialog';
import {MatSnackBarModule} from '@angular/material/snack-bar';

const routes: Routes = [
  {path: '', component: SignupComponent}
]

@NgModule({
  declarations: [SignupComponent, EmailSentDialogComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    NgbCarouselModule,
    ReactiveFormsModule,
    MatLegacyButtonModule,
    NgSelectModule,
    NgbPopoverModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatLegacyDialogModule,
    MatSnackBarModule
  ]
})
export class SignupModule {
}

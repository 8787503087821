import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {Subject} from "rxjs";
import {SmsCompaign} from "../../../../shared/models/sms-compaign";
import {PlanningBookingElement, PlanningBookingElementClient} from "../../../../shared/models/booking.model";
import {SmsService} from "../../../../shared/services/sms-service.service";
import {BreadcrumbService} from "../../../../core/services/breadcrumb.service";
import {SweetAlertService} from "../../../../shared/services/sweet-alert.service";
import {TranslateService} from "@ngx-translate/core";
import {ClientMgmService} from "../../../../shared/services/client-mgm.service";
import {BookingService} from "../../../../shared/services/booking.service";
import {  MatSnackBar} from "@angular/material/snack-bar";
import {  MatDialog,   MatDialogRef} from "@angular/material/dialog";
import {takeUntil} from "rxjs/internal/operators/takeUntil";
import moment from "moment/moment";
import {WhatsappServiceService} from "../../../../shared/services/whatsapp-service.service";
import {WhatsAppDialogComponent} from "../../contacts/client-management/whats-app-dialog/whats-app-dialog.component";
import {WhatsappMessage} from "../../../../shared/models/WhatsappMessage";
import {WhatsappConfiguration} from "../../../../shared/models/WhatsappConfiguration";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-whatsapp-configuration',
  templateUrl: './whatsapp-configuration.component.html',
  styleUrls: ['./whatsapp-configuration.component.scss']
})
export class WhatsappConfigurationComponent implements OnInit {
  @ViewChild('iconDisplay') displayElement: ElementRef;
  @ViewChild('emoji') emoji: ElementRef;
  messageSize = 1024;
  loadClients = false;
  dialogRef: MatDialogRef<any, any>;
  configurationForm: UntypedFormGroup;
  prefixList: any[] = [];
  activeAppointmentReminderFormControl = new UntypedFormControl();
  activeBirthdayReminderFormControl = new UntypedFormControl();
  genericSmsSenderToAppointmentsFormControl = new UntypedFormControl();
  unsubscribe$: any = new Subject();
  disableSave = false;
  compaignForm: UntypedFormGroup;
  clients: PlanningBookingElementClient[] = [];
  bookings: PlanningBookingElement[] = [];


  constructor(private fb: UntypedFormBuilder, private whatsappService: WhatsappServiceService,private smsService: SmsService
              , private breadcrumbService: BreadcrumbService,
              private sweetAlertService: SweetAlertService, private translateService: TranslateService,

              private clientService: ClientMgmService, private matSnackBar: MatSnackBar,
              private dialog: MatDialog, private bookingService: BookingService,
              private http: HttpClient) { }

  toggled: boolean = false;
  toggledBirthday: boolean = false;
  toggledCampaign: boolean = false;
  showAdditionalTextArea = false;
 showAdditionalTextAreaBirthday: boolean = false;
 showAdditionalTextAreaCampaign: boolean = false;
  showTestInput: boolean = false;
  showTestInputBirthday: boolean = false;
  showTestInputCampaign:boolean = false;

  ngOnInit() {
    this.sendBreadCrumb();
    const fromDate = new Date();
    const toDate = new Date();
    const sendCampaignAt = new Date();
    toDate.setHours(sendCampaignAt.getHours() + 1);
    const sendDate = new Date();
    toDate.setHours(sendDate.getHours() + 1);
    this.compaignForm = this.fb.group({
      id: new UntypedFormControl(),
      whatsAppTemplateCampaign: new UntypedFormControl('', Validators.required),
      sendCampaignAt: new UntypedFormControl(sendCampaignAt, Validators.required),
      clientIds: [],
      fromDate: new UntypedFormControl(fromDate, Validators.required),
      toDate: new UntypedFormControl(toDate, Validators.required),
      statusWhatsApp: new UntypedFormControl(),
      sendWhatsApp: new UntypedFormControl(true),
      messageTemplateCampaign: new UntypedFormControl(''),
      templateNameCampaign :new UntypedFormControl(null, Validators.required),
      languageCodeCampaign:new UntypedFormControl({ value: 'it', disabled: true }),
      phoneNumberCampaign:new UntypedFormControl('',Validators.pattern('^[0-9]{8}[0-9]*')),
      mobilePrefix: new UntypedFormControl('+39', [Validators.required]),
    });
    this.configurationForm = this.fb.group({
      baseurl: new UntypedFormControl({ value: 'https://graph.facebook.com/v18.0' , disabled:true}),
      phoneNumberId: new UntypedFormControl('', Validators.required),
      token: new UntypedFormControl('',Validators.required ),
      templateAppointment: new UntypedFormControl('', Validators.required),
      sendAppointmentBefore: new UntypedFormControl('', Validators.required),
      activeAppointmentReminder: new UntypedFormControl(true),
      genericSmsSenderToAppointments: new UntypedFormControl(true),
      templateBirthday: new UntypedFormControl('', Validators.required),
      sendDate: new UntypedFormControl(sendDate, Validators.required),
      activeBirthdayReminder: new UntypedFormControl(true),
      messageTemplate :new UntypedFormControl(''),
      templateName :new UntypedFormControl('', Validators.required),
      languageCode:new UntypedFormControl({ value: 'it', disabled: true }),
      phoneNumber:new UntypedFormControl('',Validators.pattern('^[0-9]{8}[0-9]*')),
      mobilePrefixAppointment: new UntypedFormControl('+39', [Validators.required]),
      templateNameBirthday:new UntypedFormControl('', Validators.required),
      languageCodeBirthday:new UntypedFormControl({ value: 'it', disabled: true }),
      messageTemplateBirthday :new UntypedFormControl(''),
      phoneNumberBirthday:new UntypedFormControl('',Validators.pattern('^[0-9]{8}[0-9]*')),
      mobilePrefixBirthday: new UntypedFormControl('+39', [Validators.required]),

    });

    this.activeAppointmentReminderFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      this.configurationForm.get('activeAppointmentReminder').setValue(c);
      if (!c) {
        this.configurationForm.get('templateAppointment').disable();
        this.configurationForm.get('sendAppointmentBefore').disable();
        this.configurationForm.get('templateName').disable();
        //this.configurationForm.get('languageCode').disable();
      } else {
        this.configurationForm.get('templateAppointment').enable();
        this.configurationForm.get('sendAppointmentBefore').enable();
        this.configurationForm.get('templateName').enable();
        //this.configurationForm.get('languageCode').enable();
      }
    });

    this.activeBirthdayReminderFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      this.configurationForm.get('activeBirthdayReminder').setValue(c);
      if (!c) {
        this.configurationForm.get('templateBirthday').disable();
        this.configurationForm.get('templateNameBirthday').disable();
        //this.configurationForm.get('languageCodeBirthday').disable();
      } else {
        this.configurationForm.get('templateBirthday').enable();
        this.configurationForm.get('templateNameBirthday').enable();
        //this.configurationForm.get('languageCodeBirthday').enable();
      }
    });

    this.whatsappService.getConfiguration().subscribe(r => {
      if (null == r) {
        this.activeBirthdayReminderFormControl.setValue(false);
        this.activeAppointmentReminderFormControl.setValue(false);
        this.genericSmsSenderToAppointmentsFormControl.setValue(false);
        return;
      }
      this.configurationForm.patchValue(r);
      this.activeAppointmentReminderFormControl.setValue(r.activeAppointmentReminder);
      this.activeBirthdayReminderFormControl.setValue(r.activeBirthdayReminder);
      this.genericSmsSenderToAppointmentsFormControl.setValue(r.genericMessage);
      this.saveOtherFormValues();

    });
    this.http.get<any[]>('/assets/TEL_PREFIX.json').subscribe(d => this.prefixList = d.map(e => {
      e.label = `${e.country}: ${e.prefix}`;
      return e;
    }));

  }
  sendBreadCrumb(): void {
    this.breadcrumbService.sendBreadcrumb(['WhatsApp', 'CONFIGURATION']);
  }

  addDate() {
    this.configurationForm.get('templateAppointment').setValue(this.configurationForm.get('templateAppointment').value + ' {dd.MM.yyyy} ');
  }

  addTime() {
    this.configurationForm.get('templateAppointment').setValue(this.configurationForm.get('templateAppointment').value + ' {HH:mm} ');
  }


  addFirstName() {
    this.configurationForm.get('templateBirthday').setValue(this.configurationForm.get('templateBirthday').value + ' {First Name} ');
  }

  addLastName() {
    this.configurationForm.get('templateBirthday').setValue(this.configurationForm.get('templateBirthday').value + ' {Last Name} ');
  }

  save() {
    if (!this.configurationForm.valid) {
      this.sweetAlertService.notification(this.translateService.instant('DIALOG.INSERT_ALL_MANDATORY_INFORMATION')).then(e => {
      });
      return;
    }
    this.disableSave = true;
    this.whatsappService.addNewConfiguration(this.configurationForm.getRawValue()).subscribe(r => {
      this.sweetAlertService.success(this.translateService.instant('WHATS_APP.CONFIGURATION_SUCCESS')).then(e => {
        this.disableSave = false;
      });

    }, error => this.disableSave = false);
  }

  activateAppointmentReminder() {
    this.activeAppointmentReminderFormControl.setValue(!this.configurationForm.get('activeAppointmentReminder').value);
  }
  activateGenericMessage() {
    this.genericSmsSenderToAppointmentsFormControl.setValue(!this.configurationForm.get('genericSmsSenderToAppointments').value);
  }
  activateBirthdayReminder() {
    this.activeBirthdayReminderFormControl.setValue(!this.configurationForm.get('activeBirthdayReminder').value);
  }
  sendNow() {
    this.sweetAlertService.warning(this.translateService.instant('WHATS_APP.SEND_QUESTION'))
      .then(res => {
        if (res.value) {
          this.compaignForm.get('sendCampaignAt').clearValidators();
          this.compaignForm.get('sendCampaignAt').updateValueAndValidity();
          if (!this.compaignForm.valid) {
            this.sweetAlertService.notification(this.translateService.instant('DIALOG.INSERT_ALL_MANDATORY_INFORMATION')).then(e => {
            });
            return;
          }
          this.disableSave = true;
          this.loadClients = true;
          this.clients = [];
          const request = {
            startDate: moment(this.compaignForm.get('fromDate').value).format('YYYY-MM-DD'),
            endDate: moment(this.compaignForm.get('toDate').value).format('YYYY-MM-DD')
          };
          this.bookingService.getBookingsBetweenDates(request).subscribe(r => {
            this.clients = r.map(booking => booking.client);
            this.loadClients = false;
            this.compaignForm.get('clientIds').setValue(this.clients.map(({id}) => id));
            this.smsService.addNewCompaign(this.compaignForm.getRawValue()).subscribe(r => {
              this.matSnackBar.open(this.translateService.instant('WHATS_APP.SUCCESS'), 'Ok', {
                duration: 5000,
                panelClass: 'white-snackbar',
              });
              this.disableSave = false;
            }, e => {
              this.disableSave = false;
              this.matSnackBar.open(this.translateService.instant('WHATS_APP.FAILED'), 'Ok', {
                duration: 5000,
                panelClass: 'white-snackbar',
              });
            });
          });
        }
      });
  }

  addEmoji(evt) {
    const content = [
      this.configurationForm.get('templateAppointment').value,
      evt.emoji.native].join('');
    this.configurationForm.get('templateAppointment').setValue(content);
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const clickedElement = event.target as HTMLElement;
    if (clickedElement && (clickedElement.tagName === 'TEXTAREA' || clickedElement.tagName === 'DIV')) {
      this.toggled = false;
      this.toggledBirthday = false;
      this.toggledCampaign = false;
    }
  }

  addEmojiBirthday(evt) {
    const content = [
      this.configurationForm.get('templateBirthday').value,
      evt.emoji.native].join('');
    this.configurationForm.get('templateBirthday').setValue(content);
  }
  addEmojiCampaign(evt) {
    const content = [
      this.compaignForm.get('whatsAppTemplateCampaign').value,
      evt.emoji.native].join('');
    this.compaignForm.get('whatsAppTemplateCampaign').setValue(content);
  }

  modifyTemplate() {
    this.showAdditionalTextArea = true;
    let originalTemplate = this.configurationForm.get('templateAppointment').value;
    this.whatsappService.modifyTemplate(originalTemplate).subscribe(
      (response) => {
        console.log('Modification du modèle réussie :', response);
        this.configurationForm.get('messageTemplate').setValue(response)

      },
      (error) => {
        console.error('Erreur lors de la modification du modèle :', error);
      }
    );
  }

  modifyTemplateBirthday() {
    this.showAdditionalTextAreaBirthday = true;
    let originalTemplate = this.configurationForm.get('templateBirthday').value;
    this.whatsappService.modifyTemplateBirthday(originalTemplate).subscribe(
      (response) => {
        console.log('Modification du modèle réussie :', response);
        this.configurationForm.get('messageTemplateBirthday').setValue(response)
      },
      (error) => {
        console.error('Erreur lors de la modification du modèle :', error);
      }
    );
  }

  modifyTemplateCampaign() {
    this.showAdditionalTextAreaCampaign = true;
    let originalTemplate = this.compaignForm.get('whatsAppTemplateCampaign').value;
    this.whatsappService.modifyTemplateBirthday(originalTemplate).subscribe(
      (response) => {
        console.log('Modification du modèle réussie :', response);
        this.compaignForm.get('messageTemplateCampaign').setValue(response)
      },
      (error) => {
        console.error('Erreur lors de la modification du modèle :', error);
      }
    );
  }

  sendMessage() {
    const mobilePrefix = this.configurationForm.get('mobilePrefixAppointment').value;
    const phoneNumber = this.configurationForm.get('phoneNumber').value;
    const recipient = `${mobilePrefix}${phoneNumber}`;
    const templateName = this.configurationForm.get('templateName').value;
    const languageCode = this.configurationForm.get('languageCode').value;
    const extractedParameters = this.extractParametersFromTemplate(this.configurationForm.get('templateAppointment').value);
    const baseurl =this.configurationForm.get('baseurl').value;
    const phoneNumberId =this.configurationForm.get('phoneNumberId').value;
    const token =this.configurationForm.get('token').value;

    const whatsappConfiguration = {
      baseurl: baseurl,
      phoneNumberId: phoneNumberId,
      token: token,
    };

    const whatsappMessage: WhatsappMessage = {
      recipient: recipient,
      templateName: templateName,
      languageCode:languageCode,
      textParameters:extractedParameters,
      // @ts-ignore
      configuration: whatsappConfiguration,

    };

    this.whatsappService.sendWhatsAppMessage(whatsappMessage).subscribe(
      (response) => {
        console.log("+++",response);

        if(response.errorCode == null){
          this.sweetAlertService.success(this.translateService.instant('SMS.MESSAGE_TEST')).then(e => {});

      }else{
          this.sweetAlertService.danger(this.translateService.instant('SMS.MESSAGE_TEST_FAILED')+ ' : ' +'{ Codice di errore : '+response.errorCode +' '+', Messaggio di errore : '+ response.errorMsg+'}').then(e => {});

    }},
      (error) => {

      }
    );
  }

  extractParametersFromTemplate(template: string): string[] {
    const parameters: string[] = [];

    if (template.includes('{dd.MM.yyyy}')) {
      parameters.push('{dd.MM.yyyy}');
    }

    if (template.includes('{HH:mm}')) {
      parameters.push('{HH:mm}');
    }

    if (template.includes('{First Name}')) {
      parameters.push('{First Name}');
    }
    if (template.includes('{Last Name}')) {
      parameters.push('{Last Name}');
    }

    return parameters;
  }

  sendMessageBirthday(){
    const mobilePrefix = this.configurationForm.get('mobilePrefixBirthday').value;
    const phoneNumber = this.configurationForm.get('phoneNumberBirthday').value;
    const recipient = `${mobilePrefix}${phoneNumber}`;
    const templateName = this.configurationForm.get('templateNameBirthday').value;
    const languageCode = this.configurationForm.get('languageCodeBirthday').value;
    const extractedParameters = this.extractParametersFromTemplate(this.configurationForm.get('templateBirthday').value);
    const baseurl =this.configurationForm.get('baseurl').value;
    const phoneNumberId =this.configurationForm.get('phoneNumberId').value;
    const token =this.configurationForm.get('token').value;

    const whatsappConfiguration = {
      baseurl: baseurl,
      phoneNumberId: phoneNumberId,
      token: token,
    };

    const whatsappMessage: WhatsappMessage = {
      recipient: recipient,
      templateName: templateName,
      languageCode:languageCode,
      textParameters:extractedParameters,
      // @ts-ignore
      configuration: whatsappConfiguration,

    };

    this.whatsappService.sendWhatsAppMessage(whatsappMessage).subscribe(
      (response) => {
        if(response.errorCode == null){
          this.sweetAlertService.success(this.translateService.instant('SMS.MESSAGE_TEST')).then(e => {});

    }else{
      this.sweetAlertService.danger(this.translateService.instant('SMS.MESSAGE_TEST_FAILED')+ ' : ' +'{ Codice di errore : '+response.errorCode +' '+', Messaggio di errore : '+ response.errorMsg+'}').then(e => {});

    }},
      (error) => {

      }
    );
  }

  saveOtherFormValues() {
    const otherFormValues = this.configurationForm.value;
    console.log("***",otherFormValues);
    this.whatsappService.setOtherFormValues(otherFormValues);

  }

  sendMessageCampaign(){
    const mobilePrefix = this.compaignForm.get('mobilePrefix').value;
    const phoneNumber = this.compaignForm.get('phoneNumberCampaign').value;
    const recipient = `${mobilePrefix}${phoneNumber}`;
    const templateName = this.compaignForm.get('templateNameCampaign').value;
    const languageCode = this.compaignForm.get('languageCodeCampaign').value;
    const extractedParameters = this.extractParametersFromTemplate(this.compaignForm.get('whatsAppTemplateCampaign').value);
    const baseurl =this.configurationForm.get('baseurl').value;
    const phoneNumberId =this.configurationForm.get('phoneNumberId').value;
    const token =this.configurationForm.get('token').value;

    const whatsappConfiguration = {
      baseurl: baseurl,
      phoneNumberId: phoneNumberId,
      token: token,
    };

    const whatsappMessage: WhatsappMessage = {
      recipient: recipient,
      templateName: templateName,
      languageCode:languageCode,
      textParameters:extractedParameters,
      // @ts-ignore
      configuration: whatsappConfiguration,

    };

    this.whatsappService.sendWhatsAppMessage(whatsappMessage).subscribe(
      (response) => {
        if (response.errorCode == null) {
          this.sweetAlertService.success(this.translateService.instant('SMS.MESSAGE_TEST')).then(e => {});
      }else{
          this.sweetAlertService.danger(this.translateService.instant('SMS.MESSAGE_TEST_FAILED')+ ' : ' +'{ Codice di errore : '+response.errorCode +' '+', Messaggio di errore : '+ response.errorMsg+'}').then(e => {});

        }
        },
      (error) => {

      }
    );
  }




}

import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {  MAT_DIALOG_DATA,   MatDialogRef} from "@angular/material/dialog";
import {ClientMgmService} from "../../../../../shared/services/client-mgm.service";

import {WhatsappMessage} from "../../../../../shared/models/WhatsappMessage";
import {Client} from "../../../../../shared/models/client";
import {WhatsappServiceService} from "../../../../../shared/services/whatsapp-service.service";

@Component({
  selector: 'app-whats-app-dialog',
  templateUrl: './whats-app-dialog.component.html',
  styleUrls: ['./whats-app-dialog.component.scss']
})
export class WhatsAppDialogComponent implements OnInit {
  whatsAppForm: UntypedFormGroup;
  whatsappMessage: WhatsappMessage = new WhatsappMessage();
  client: Client;

  constructor(private dialogRef: MatDialogRef<WhatsAppDialogComponent>,
              private fb: UntypedFormBuilder,
              private  WhatsappService: WhatsappServiceService,
              @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.whatsAppForm = this.fb.group({
      phoneNumber: [''],
      message: ['', Validators.required],
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  /*sendMessage() {
    const message = this.whatsAppForm.get('message').value;
    const whatsappMessage: WhatsappMessage = {
      recipient: this.client.mobilePrefix + this.client.mobile,
      message: message,
      configuration: null
    };

    this.WhatsappService.sendWhatsAppMessage(whatsappMessage).subscribe(
      (response) => {
      },
      (error) => {

      }
    );
    this.dialogRef.close();

  }*/

}

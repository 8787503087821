import {RangeLevel} from '../enum/range-level';

export class ClientRanges {
  rangeDuration: string;
  currentRangeAmount: number;
  currentRange: RangeLevel;
  rangePeriods: RangePeriod[];
}

export class RangePeriod {
  period: number;
  year: number;
  amount: number;
  range: RangeLevel;
}

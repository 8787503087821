// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subheader {
  display: flex;
  justify-content: space-between;
}

.table-container table .table-row:hover {
  background: #f4f4f4;
  color: white !important;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
}

td {
  vertical-align: middle;
  cursor: pointer;
}

tr.mat-footer-row, tr.mat-row {
  height: 40px;
}

@media screen and (max-width: 970px) {
  .subheader {
    flex-direction: column;
  }
  .subheader .action-btns {
    display: flex;
    justify-content: flex-end;
  }
  .table-container {
    margin-top: 20px;
    max-width: 100vw;
    overflow-x: scroll;
  }
  .table-container .mat-table {
    overflow: auto;
    width: 1123px;
  }
}
.dark-sell {
  background-color: beige;
}

@media (max-width: 767.98px) {
  table th:first-child {
    min-width: 180px;
  }
  button {
    width: 100%;
  }
  button:first-child {
    margin-right: 0px !important;
    margin-bottom: 10px;
  }
}
.dark-sell {
  background-color: beige;
}`, "",{"version":3,"sources":["webpack://./src/app/content/pages/payment/quote/quote.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;AACF;;AAEA;EACE,mBAAA;EACA,uBAAA;EACA,yBAAA;UAAA,iBAAA;EACA,eAAA;AACF;;AAEA;EACE,sBAAA;EACA,eAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE;IACE,sBAAA;EACF;EACE;IACE,aAAA;IACA,yBAAA;EACJ;EAEA;IACE,gBAAA;IACA,gBAAA;IACA,kBAAA;EAAF;EAEE;IACE,cAAA;IACA,aAAA;EAAJ;AACF;AAIA;EACE,uBAAA;AAFF;;AAKA;EACE;IACE,gBAAA;EAFF;EAKA;IACE,WAAA;EAHF;EAMA;IACE,4BAAA;IACA,mBAAA;EAJF;AACF;AAOA;EACE,uBAAA;AALF","sourcesContent":[".subheader {\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n\r\n.table-container table .table-row:hover {\r\n  background: #f4f4f4;\r\n  color: white !important;\r\n  user-select: none;\r\n  cursor: pointer;\r\n}\r\n\r\ntd {\r\n  vertical-align: middle;\r\n  cursor: pointer;\r\n}\r\n\r\ntr.mat-footer-row, tr.mat-row {\r\n  height: 40px;\r\n}\r\n\r\n@media screen and (max-width: 970px) {\r\n  .subheader {\r\n    flex-direction: column;\r\n\r\n    .action-btns {\r\n      display: flex;\r\n      justify-content: flex-end;\r\n    }\r\n  }\r\n  .table-container {\r\n    margin-top: 20px;\r\n    max-width: 100vw;\r\n    overflow-x: scroll;\r\n\r\n    .mat-table {\r\n      overflow: auto;\r\n      width: 1123px;\r\n    }\r\n  }\r\n}\r\n\r\n.dark-sell {\r\n  background-color: beige;\r\n}\r\n\r\n@media (max-width: 767.98px) {\r\n  table th:first-child {\r\n    min-width: 180px;\r\n  }\r\n\r\n  button {\r\n    width: 100%;\r\n  }\r\n\r\n  button:first-child {\r\n    margin-right: 0px !important;\r\n    margin-bottom: 10px;\r\n  }\r\n}\r\n\r\n.dark-sell {\r\n  background-color: beige;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Center} from '../models/center';

@Injectable()
export class CenterService {
  readonly API = environment.api + '/centers';
  constructor(private http: HttpClient) { }

  addNewCenter(center) {
    return this.http.post<Center>(`${this.API}`, center);
  }

  getAllCenters() {
    return this.http.get<Center[]>(`${this.API}`);
  }

  editCenter(center) {
    return this.http.put<Center>(`${this.API}`, center);
  }

  getCenterById(centerId) {
    return this.http.get<Center>(`${this.API}/${centerId}`);
  }

  deleteCenter(id: any) {
    return this.http.delete<void>(`${this.API}/${id}`);
  }
}

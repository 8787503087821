import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {  MAT_DIALOG_DATA,   MatDialog,   MatDialogRef} from '@angular/material/dialog';
import {CompanyBusinessService} from '../../../../../shared/services/company-business.service';
import {SweetAlertService} from "../../../../../shared/services/sweet-alert.service";

@Component({
  selector: 'types-of-activities-form-component',
  template: `
      <div matDialogTitle>
          {{'ADMIN.TYPES_ACTIVITIES.ADD_NEW' | translate}}
      </div>
      <div matDialogContent>
          <form [formGroup]="activityTypeForm" (ngSubmit)="save()" id="activityForm">
              <div class="form-group">
                  <label>{{'DATA_TABLE.DESCRIPTION' | translate}}</label>
                  <input type="text" placeholder="{{'DATA_TABLE.DESCRIPTION' | translate}}" class="form-control" formControlName="description">
              </div>
          </form>

      </div>
      <div matDialogActions align="end">
          <button mat-flat-button class="cancel-button" (click)="confirmAndCloseDialog()">
              <mat-icon>clear</mat-icon>
              {{'BUTTONS.CANCEL' | translate}}</button>
        <span class="button-space" ></span>
          <button mat-flat-button class="confirm-button" form="activityForm">
              <mat-icon>save</mat-icon>
              {{'BUTTONS.SAVE' | translate}}</button>
      </div>
  `
})

export class TypesOfActivitiesFormComponent implements OnInit {
  activityTypeForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private companyBusinessService: CompanyBusinessService,
    private matDialogRef: MatDialogRef<TypesOfActivitiesFormComponent>,
    private sweetAlertService: SweetAlertService,
    @Inject(MAT_DIALOG_DATA) private data: {editMode: boolean, activity}
  ) {
  }

  ngOnInit() {
    this.activityTypeForm = this.fb.group({
      id: null,
      description: [null, Validators.required]
    });
    if (this.data.editMode) {
      this.activityTypeForm.patchValue(this.data.activity);
    }
  }

  save() {
    this.companyBusinessService.update(this.activityTypeForm.value).subscribe(() => {
      this.matDialogRef.close(true);
    });
  }

  confirmAndCloseDialog(): void {
    if (this.activityTypeForm.dirty) {
     this.sweetAlertService.showConfirmationAlert()
      .then((confirmation) => {
        if (confirmation) {
          this.matDialogRef.close();

        }
      });}
    else{
      this.matDialogRef.close();
    }
  }
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.activityTypeForm.dirty) {
      $event.returnValue = true;
    }
  }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-group-text {
  padding: 0.55rem 2rem !important;
}

.table-input {
  margin-left: 30%;
  width: 40%;
}

.active-config {
  background-color: var(--header-color) !important;
  border: none;
}
.active-config p {
  color: #b3b3b3 !important;
}
.active-config .ft-more-vertical, .active-config .role-title {
  color: white;
}

.utility-rows {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
}

.card-utility {
  cursor: pointer;
}

.ft-check-square {
  color: #06B5B6;
}

.ft-x-square {
  color: #f44336;
}

.facebook-btn {
  color: #4267B2;
  background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/app/administrative-content/pages/admin-module/components/configuration-mgm/configuration-mgm.component.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;AACF;;AAEA;EACE,gBAAA;EACA,UAAA;AACF;;AAEA;EAEE,gDAAA;EAMA,YAAA;AALF;AACE;EACE,yBAAA;AACJ;AAIE;EACE,YAAA;AAFJ;;AAMA;EACE,aAAA;EACA,cAAA;EACA,2DAAA;AAHF;;AAMA;EACE,eAAA;AAHF;;AAMA;EACE,cAAA;AAHF;;AAMA;EACE,cAAA;AAHF;;AAKA;EACE,cAAA;EACA,uBAAA;AAFF","sourcesContent":[".input-group-text {\r\n  padding: 0.55rem 2rem !important;\r\n}\r\n\r\n.table-input {\r\n  margin-left: 30%;\r\n  width: 40%;\r\n}\r\n\r\n.active-config {\r\n // background-color: #2B333D !important;\r\n  background-color: var(--header-color) !important;\r\n\r\n  p {\r\n    color: lighten(black, 70%) !important;\r\n  }\r\n\r\n  border: none;\r\n\r\n  .ft-more-vertical, .role-title {\r\n    color: white;\r\n  }\r\n}\r\n\r\n.utility-rows {\r\n  display: grid;\r\n  grid-gap: 10px;\r\n  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));\r\n}\r\n\r\n.card-utility {\r\n  cursor: pointer;\r\n}\r\n\r\n.ft-check-square {\r\n  color: #06B5B6;\r\n}\r\n\r\n.ft-x-square {\r\n  color: #f44336;\r\n}\r\n.facebook-btn {\r\n  color: #4267B2;\r\n  background-color: white;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import {FidelityCardMovementType} from '../enum/fidelity-card-movement-type';

export class FidelityCardHistoryDto {
  amount: number;
  movementType: FidelityCardMovementType;
  fidelityCardId: number;
  productId: number;
  treatmentId: number;
  subscriptionId: number;
}

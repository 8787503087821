// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  margin-top: 30px;
}

.color-picker-square {
  width: 50px;
  height: 25px;
  border-radius: 5px;
  margin-right: 10px;
}

.color-input-container {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/administrative-content/pages/owner/components/mail-config/mail-config.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AACA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;AAEF;;AAAA;EACE,mBAAA;EAEA,aAAA;EACA,mBAAA;AAEF","sourcesContent":[".container {\r\n  margin-top: 30px;\r\n}\r\n.color-picker-square {\r\n  width: 50px;\r\n  height: 25px;\r\n  border-radius: 5px;\r\n  margin-right: 10px;\r\n}\r\n.color-input-container {\r\n  margin-bottom: 10px;\r\n\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {YearPlanningComponent} from './year-planning.component';
import {MatLegacyButtonModule} from '@angular/material/legacy-button';
import {MatDividerModule} from '@angular/material/divider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTableModule} from '@angular/material/table';
import {  MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';

import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbDropdownModule, NgbPaginationModule, NgbTypeaheadModule} from '@ng-bootstrap/ng-bootstrap';
import {ColorPickerModule} from 'ngx-color-picker';
import {SharedModule} from '../shared.module';
import {WeekPlanningTemplateFormModule} from '../week-template-form/week-planning-template-form.module';
import {WeekPlanningApplicationModeComponent} from './week-planning-application-mode.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';

@NgModule({
  declarations: [YearPlanningComponent, WeekPlanningApplicationModeComponent],
  imports: [
    CommonModule,
    MatTabsModule,
    TranslateModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatLegacyButtonModule,
    NgbPaginationModule,
    NgbTypeaheadModule,
    MatTableModule,
    MatPaginatorModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    ColorPickerModule,
    NgxMaterialTimepickerModule,
    FormsModule,
    SharedModule.forChild(),
    NgbDropdownModule,
    MatIconModule,
    WeekPlanningTemplateFormModule
  ],
  exports: [YearPlanningComponent]
})
export class YearPlanningModule {
}

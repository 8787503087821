import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {NavigationModule} from 'src/app/core/navigation/navigation.module';
import {SidebarComponent} from './sidebar.component';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyButtonModule} from '@angular/material/legacy-button';
import {PerfectScrollbarModule} from "ngx-om-perfect-scrollbar";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NavigationModule,
    MatIconModule,
    MatLegacyButtonModule,
    PerfectScrollbarModule,
  ],
  declarations: [SidebarComponent],
  exports: [SidebarComponent]
})
export class SidebarModule {
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul {
  list-style: none;
}

.tree-view {
  padding: 0;
  font-size: 12px;
}

.children {
  height: 0;
  padding-left: 70px;
  overflow: hidden;
  transition: 0.2s ease-in-out all;
}

.expand {
  height: 50px;
}`, "",{"version":3,"sources":["webpack://./src/app/administrative-content/pages/owner/components/group-mgm/treeview/tree-view.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,UAAA;EACA,eAAA;AACF;;AACA;EACE,SAAA;EACA,kBAAA;EACA,gBAAA;EACA,gCAAA;AAEF;;AAAA;EACE,YAAA;AAGF","sourcesContent":["ul {\r\n  list-style: none;\r\n}\r\n\r\n.tree-view{\r\n  padding: 0;\r\n  font-size: 12px;\r\n}\r\n.children {\r\n  height: 0;\r\n  padding-left: 70px;\r\n  overflow: hidden;\r\n  transition: .2s ease-in-out all;\r\n}\r\n.expand {\r\n  height: 50px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FiscalReportsComponent } from './fiscal-reports.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatLegacyButtonModule} from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import {   TooltipComponent } from '@angular/material/tooltip';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';


const routes: Routes = [
  {path: '', component: FiscalReportsComponent}
];


@NgModule({
  declarations: [FiscalReportsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatDatepickerModule,
    ReactiveFormsModule,
    TranslateModule,
    MatLegacyButtonModule,
    MatIconModule,

  ]
})
export class FiscalReportsModule { }

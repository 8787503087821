export const locale = {
  lang: 'en',
  data: {
    UNAUTHORIZED_MSG: 'You are unauthorized to access this page',
    HEADER: {
      OPERATOR_INFORMATIONS: 'Operator Informations',
      MANAGE_OPERATORS: 'Operator management',
      MANAGE_ROLES: 'Role management',
      CONFIGURATIONS: 'Configurations',
      MEGA_MENU: 'sd',
      HOME: 'Home',
      PROFILE: 'Profile',
      TYPES_OF_ACTIVITIES: 'Type of activities',
      LICENSE_CONFIG: 'License configurations',
      CALENDAR: 'Calendar',
      VANITY_CLIENT: 'GAB clients',
      BREADCRUMB: {
        DAYS_LEFT: 'days left until the license expires',
        CONTACT: 'Contact',
        COMPANY: 'Company',
        CLIENT: 'Client',
        PROVIDER: 'Provider',
        OPERATORS: 'Operator',
        CONFIGURATION: 'Configuration',
        IVA_CODE: 'Tax',
        HOW_DID_YOU_MEET_US: 'How did you find out about us',
        PAYMENT_TYPE: 'Payment type',
        PREF_CONTACT: 'Preferred contact',
        PROFESSION: 'Profession',
        PRODUCT_AND_SERVICES: 'Products and services',
        PRODUCT: 'Articles',
        PRODUCTS: 'Products',
        BRANDS: 'Brands',
        CATEGORIES_PRODUCT: 'Product categories',
        CATEGORIES_TREATMENT: 'Treatment categories',
        CABIN: 'Cabin',
        LINEAS: 'Lines',
        FIDELITY: 'Fidelity',
        TREATMENTS: 'Treatments',
        SUBSCRIPTIONS: 'Subscriptions',
        CHECK_DATA: 'Check data',
        PAYMENT: 'Payment',
        PAYMENT_HISTORY: 'Receipts',
        PLANNING: 'Planning',
        SMS: 'SMS',
        WhatsApp: 'WhatsApp',
        COMPAIGNS: 'Campaigns',
        MOVEMENT: 'Warehouse',
        MOVEMENT_CARICO: 'Warehouse Load',
        MOVEMENT_SCARICO: 'Warehouse Discharge',
        INVENTORY: 'Inventory',
        MOVEMENT_AND_STOCK: 'Movement and Storage',
        STOCK: 'Storage',
        RT: 'Cash desk',
        HISTORY: 'History',
        DASHBOARD: 'Dashboard',
        PRODUCT_REPORT: 'Product reports',
        TREATMENT_REPORT: 'Service reports',
        OPERATOR_REPORT: 'Operator reports',
        QUOTE: 'Quote',
        QUOTE_HISTORY: 'Quotes History',
        FISCAL_REPORT: 'Financial Report',
        FIRST_NOTE: 'First note',
        FIRST_NOTE_CAUSE: 'First note cause',
        SATISPAY_CONFIG: 'Satispay configuration',
        PROMOTION: 'Promotion',
        MOBILE_APP_CONFIG: 'APP configuration',
        TEMPLATE_PROMO: 'Template promo',
        SOCIAL_POSTS: 'Social publications',
      }
    },
    SUBHEADER: {
      SEARCH: 'Search',
    },
    ALERT_DIALOG: {
      ARE_YOU_SURE: 'Are you sure?',
      MSG: 'You are going back to the home page'
    },
    BUTTONS: {
      SAVE: 'Save',
      EDIT: 'Edit',
      CANCEL: 'Cancel',
      CLOSE: 'Close',
      DELETE: 'Delete',
      ADD: 'Add',
      EDIT_PASSWORD: 'Change Password',
      PAYMENT: 'Payment',
      MODIFY: 'Modify',
      RETURN_TO_PAYMENT: 'Return to Payment',
      GO_TO_PLANNING: 'Go to Planning',
      COPY: 'Copy',
      CUT: 'Cut',
      CHOOSE: 'Choose',
      PRINT: 'Print',
      PRINT_NO_FISCAL: 'Non-fiscal reprint',
      RETRY: 'Retry print',
      GO_HOME: 'Go Homepage',
      ADD_SUPER_ADMIN: 'Add super admin',
      SCHEDULE: 'Schedule',
      SAVE_WITHOUT_PRINT: 'Save without print',
      SAVE_WITH_PRINT: 'Save with print',
      SAVE_DRAFT: 'Save draft',
      HISTORY: 'History',
      PRINT_TICKET: 'Etiquette',
      PRINT_PLANNING: 'Print planning',
      DOWNLOAD: 'Download',
      ADD_TINT: 'Tint',
      CLEAR: 'Clear',
      FACE_PLATES: 'Shelf labels',
      NEXT: 'Next',
      PREVIOUS: 'Previous',
      ADD_OPERATOR: 'Add operator',
      ADD_CABIN: 'Add cabin',
      SEND: 'Send',
      GO_TO: 'Go to',
      EXPIRED: 'Expired',
      VALID: 'Valid',
      ENABLED: 'Enabled',
      DISABLED: 'Disabled',
      SEARCH: 'Search',
      RESET_FILTER: 'Reset Filter',
      ACTIVE: 'Active',
      DEACTIVATED: 'Deactivated',
      CREAT: 'Create',
      SEARCH_TICKET: 'Search Ticket',
      ANNUL_TICKET: 'Cancel ticket',
      RESTORE: 'Restore',
      APPLY: 'Apply',
      CONTINUE: 'Continue',
      IMPORT_FROM_FISCAL_RT:'Import from fiscal rtr',
      NO: 'No',
      YES: 'Yes',
      DELETE_INVENTORY: 'You must delete inventory'
    },
    LOADING: 'Loading',
    DATA_TABLE: {
      ID: 'ID',
      FIRST_NAME: 'First name',
      LAST_NAME: 'Last name',
      FULL_NAME: 'Full name',
      DESCRIPTION: 'Description',
      Gender: 'Gender',
      EMAIL: 'E-mail',
      MOBILE: 'Mobile',
      NO_MARKETING: 'No marketing',
      COMPANY: 'Company',
      TAX_DESCRIPTION: 'TVA Description',
      TAX_RATE: 'Tax rate',
      CLIENTE: 'Client',
      NO_CONFIGURATION_FOUND: 'No configuration found',
      HOW_DID_YOU_MEET_US: 'How did you find out about us',
      BUSINESS_NAME: 'Business name',
      VAT_NUMBER: 'VAT number',
      CREATION_DATE: 'Creation date',
      ROLE: 'Role',
      BRAND_DESCRIPTION: 'Brand description',
      CABIN_DESCRIPTION: 'Cabin Description',
      PLANNING_POSITION: 'Position in the planning',
      PLANNING_COLOR: 'Color in the planning',
      CATEGORY_DESCRIPTION: 'Category description',
      CATEGORY_NAME: 'Category name',
      DAY: 'Day',
      DAY_OFF: 'Day off',
      FROM: 'From',
      TO: 'To',
      PAUSE: 'Pause',
      YEAR: 'Year',
      SELL_POINTS: 'Sell points',
      PLANING_NAME: 'Planning Name',
      NAME: 'Name',
      SUBCATEGORY_NAME: 'Subcategory name',
      SUBCATEGORY_DESCRIPTION: 'Subcategory description',
      THRESHOLD: 'Threshold',
      OTHER_THRESHOLD: 'Other threshold',
      TREATMENT: 'Treatment',
      PRODUCT: 'Product',
      VALUE: 'Value',
      DISCOUNT: 'Discount',
      TYPE: 'Type',
      SEQUENCE: 'Sequence',
      PRICE: 'Price',
      HALO: 'Halo',
      LALO: 'Lalo',
      VAT: 'Vat',
      GROUP_CODE: 'Group Code',
      CASH: 'Sum In Cash',
      CREDIT: 'Credit',
      CHANGE: 'Rest enabled',
      DRAWER: 'Drawer opening',
      IP: 'IP',
      MODIFICATION_DATE: 'Modification Date',
      MODEL: 'Model',
      STATUS: 'Status',
      AMOUNT: 'Amount',
      TOTAL: 'Total',
      ERROR: 'Error',
      OPERATOR: 'Operator',
      AMOUNT_PAID: 'Amount paid',
      DATE_FORM: 'dd/mm/yyyy',
      PLANING: 'Planning',
      USERNAME: 'Username',
      SOLD: 'Balance',
      NUMBER: 'Number',
      DATE: 'Date',
      REST: 'Rest',
      POINTS: 'Points',
      QUANTITY: 'Quantity',
      CONSUMED: 'Consumed',
      REMAINING_QUANTITY: 'Remaining quantity',
      GENDER: 'Gender',
      USE_TYPE: 'Type of use',
      PURCHASE_COST: 'Purchase cost',
      AVERAGE_COST: 'Average cost',
      UNDER_ESCORT: 'Under escort',
      PHOTO: 'Photos',
      COMMERCIAL_DESCRIPTION: 'Commercial description',
      BAR_CODE: 'Barcode',
      CATEGORY: 'Category',
      SUBCATEGORIES: 'Subcategory',
      PROVIDER: 'Provider',
      ML_GR: 'ml / gr',
      BRAND: 'Brand',
      LINEA: 'Line',
      STOCK: 'Stock',
      ACTIVE: 'Active',
      CODE: 'Code',
      IVA: 'VAT',
      TOTAL_PURCHASE_COST: 'Total Purchase Cost',
      CAUSAL: 'Causal',
      GIFT: 'Gift',
      SUBCATEGORIES_LIST: 'Subcategories',
      DOCUMENT: 'Document',
      PURCHASE_DATE: 'Purchase date',
      COLOR: 'Color',
      TINT: 'Tint',
      CLIENT: 'Client',
      DATE_AND_HOUR: 'Date and time',
      MAX_CABINS: 'Max cabins',
      MAX_OPERATORS: 'Max operators',
      MODULES: 'Modules',
      CAUSE: 'Cause',
      F_AMOUNT: 'Amount',
      AVAILABLE: 'Available',
      VALID: 'Valid',
      SALES: 'Sales',
      PLANING_POSITION: 'Planning Position',
      RESO_QUANTITY: 'Return quantity',
      RESO_AMOUNT: 'Total return',
      TOTAL_IN_CASE: 'Total in caisse',
      TOTAL_RENDER: 'total render',
      RESO_TYPE: 'Return type',
      ANNULMENT: 'Annulment',
      RESO: 'Return',
      SUBSCRIPTION: 'Subscription',
      CARD: 'Card',
      REGISTER_SOURCE: 'Source',
      CONFIRMED: 'Confirmed',
      NOT_CONFIRMED: 'Not Confirmed',
      AMOUNTPAYED: 'Amount paid',
      SALES_PRICE: 'Price sale',
      DEMO_RT: 'Demo printer',
      FIRMWARE: 'Firmware',
    },
    SIDENAV: {
      LINEA: 'Line',
      CONTACTS: 'Contacts',
      REGISTER: 'Register',
      COMPANY: 'Company',
      CLIENT: 'Client',
      PROVIDER: 'Provider',
      CONFIGURATIONS: 'Configurations',
      PREF_CONTACT: 'Preferred contact',
      PROD_SERVICE: 'Products and services',
      PROFESSION: 'Profession',
      PAYMENT_TYPE: 'Payment type',
      COUNTRY: 'Country',
      HOW_DID_YOU_MEET_US: 'How did you find out about us',
      OPERATORS: 'Operator',
      VAT_CODE: 'VAT code',
      CLIENTE: 'Client',
      PRODUCT: 'Article',
      BRAND: 'Brand',
      CABIN: 'Cabin',
      WEEK_PLANNING: 'Working week',
      CATEGORY_PRODUCT: 'Category type product',
      CATEGORY_TREATMENT: 'Category type traitment',
      OPERATORS_PLANNING: 'Operators Planning',
      ADMINISTRATION: 'Administration',
      FIDELITY: 'Fidelity',
      TREATMENT: 'Treatment',
      PAYMENT: 'Payment',
      SUBSCRIPTION: 'Subscription',
      PAYMENT_HISTORY: 'Receipts',
      WANT_TO_DELETE_WHOLE_RECEIPT: 'Do you want to delete the entire receipt?',
      RT: 'RT',
      SMS_BASIC_CONFIG: 'SMS Basic configuration',
      WHATSAPP_BASIC_CONFIG: 'WhatsApp Basic Configuration',
      SMS_COMPAIGNS: 'Campaigns',
      SMS_INSTANCE: 'Historical',
      SMS_WhatsApp_COMPAIGNS: 'Campaigns',
      SMS_WhatsApp_INSTANCE: 'Historical',
      MOVEMENT: 'Warehouse',
      CARICO: 'Load',
      SCARICO: 'Discharge',
      INVENTORY: 'Inventory',
      MOVEMENT_AND_STOCK: 'Movement and Storage',
      STOCK: 'Storage',
      PRODUCT_REPORT: 'Product reports',
      TREATMENT_REPORT: 'Service reports',
      OPERATOR_REPORT: 'Operator reports',
      QUOTE: 'Quote',
      SALES: 'Sales',
      FISCAL_REPORT: 'Financial report',
      FIRST_NOTE: 'First note',
      FIRST_NOTE_CAUSE_SETTING: 'First note cause settings',
      PROMOTION: 'Promotion',
      DOCUMENT_MANAGEMENT: 'Document Management',
      STATISTICS: 'Statistics',
      SETUP_SELL_POINT: 'Center Setup',
      SETUP_CLIENTS: 'Client Setup',
      CASH_DESK: 'Cash desk',
      PRINTERS: 'Printers',
      CASH_REGISTER_CLOSURE: 'Cash Register Closure',
      MOBILE_APP_CONFIG: 'APP configuration',
      ENTER_PROMOTION: 'Enter promotion',
      SOCIAL_POSTS: 'Social publications'
    },
    CLIENT_FORM: {
      ADD_CLIENT: 'Add client',
      CLIENT_DETAILS: 'Client details',
      CLIENT_TYPE: 'Client Type',
      SAVE: 'Save',
      EDIT: 'Edit',
      STATUS_OF_INACTIVITY: 'Status',
      DEPT: 'Dept',
      LAST_PASSAGE: 'Last passage',
      FIRST_PASSAGE: 'First Step',
      ACTIVE: 'Active',
      INACTIVE: 'Inactive',
      DEACTIVATED: 'Deactivated',
      VISIBLE: 'Visible',
      HIDDEN: 'Hidden',
      STATE: 'State',
      MALE: 'Male',
      FEMALE: 'Female',
      OTHER: 'Other',
      GENDER: 'Gender',
      FIRST_NAME: 'First name',
      LAST_NAME: 'Last name',
      DATE_OF_BIRTH: 'Date of birth',
      FISCAL_CODE: 'Fiscal code',
      SDI_CODE: 'SDI code',
      CITY: 'City',
      STREET: 'Street',
      COUNTRY: 'Country',
      ZIP: 'ZIP code',
      ADDRESS: 'Address',
      PROVINCE: 'Province',
      MOBILE: 'Mobile',
      WORK_PHONE: 'Work phone',
      HOME_PHONE: 'Home phone',
      EMAIL: 'E-mail',
      PREF_CONTACT: 'Preferred contact',
      PROFESSION: 'Profession',
      COMPANY: 'Company',
      PREF_PAYMENT: 'Preferred payment',
      MEET_INSTITUTE: 'How did you meet the institute?',
      CIVIL_STATE: 'Civil state',
      MARRIED: 'Married',
      SONS: 'Sons',
      NO_MARKETING: 'No marketing',
      PRIVACY: 'Privacy',
      NO_APPOINTMENT_REMINDER: 'No appointment reminder',

      NOTE: 'Note',
      NO_CLIENT_FOUND: 'No client found',
      GENDER_REQUIRED: 'Gender is required',
      FIRST_NAME_REQUIRED: 'First name is required',
      LAST_NAME_REQUIRED: 'Last name is required',
      MOBILE_REQUIRED: 'Mobile phone is required',
      DATE_FORMAT: 'dd/mm/yyyy',
      PROGRESS_CODE: 'Progress code',
      SUBSCRIPTION: 'Subscription',
      CHOOSE_CLIENT: 'Choose client',
      SELECT_CLIENT: 'Select a customer',
      ANAMNESI: 'Medical history',
      FIDELITY: 'Fidelity',
      PURCHASE_HISTORY: 'Purchase history',
      TOTAL_PRODUCT_PURCHASE: 'Tot. prod. purchase',
      TOTAL_TREATMENT_PURCHASE: 'Tot. tr. purchase',
      TOTAL_PURCHASE: 'Total purchase',
      TOTAL_DISCOUNT: 'Total discount',
      TINT: 'Tint',
      ADD_TINT: 'Add tint',
      EDIT_TINT: 'Change tint',
      MOBILE_INVALID: 'The phone number is wrong',
      WORK_PHONE_REQUIRED: 'Mobile phone is required',
      HOME_PHONE_REQUIRED: 'Mobile phone is required',
      TOTAL_TREATMENT_RESO: 'Total return of services',
      TOTAL_RESO: 'Total return',
      TOTAL_PRODUCT_RESO: 'Total product return',
      SHOW_DISABLED_CLIENT: 'Deactivated',
      SHOW_NOT_CONFIRMED_CLIENT: 'Unconfirmed',
      PREFERRED_OPERATOR: 'Preferred operator',
      LOTTERY: 'Lottery',
    },
    COMPANY_FORM: {
      ACTIVE: 'Active',
      DEACTIVATED: 'Deactivated',
      VISIBLE: 'Visible',
      HIDDEN: 'Hidden',
      SAVE: 'Save',
      EDIT: 'Edit',
      ADD_PROVIDER: 'Add company',
      COMPANY_DETAILS: 'Company details',
      BUSINESS_NAME: 'Business name',
      VAT_NUMBER: 'VAT number',
      FISCAL_CODE: 'Fiscal code',
      CODE_SDI: 'SDI Code',
      REA: 'R.E.A',
      REGISTERED_OFFICE: 'Registered office',
      ADDRESS: 'Address',
      STREET_NUMBER: 'Street number',
      NATION: 'Nation',
      POSTAL_CODE: 'Postal code',
      LOCATION: 'Location',
      PROVINCE: 'Province',
      OPERATING_OFFICE: 'Operating office',
      PHONE: 'Phone',
      MOBILE_PHONE: 'Mobile phone',
      FAX: 'Fax',
      EMAIL: 'E-mail',
      EMAIL_PEC: 'Email Pec',
      REFERENT: 'Referent',
      WEB_SITE: 'Web site',
      BANK: 'Bank',
      BANK_BRANCH: 'Bank branch',
      IBAN: 'IBAN',
      CIN: 'CIN',
      BIC_SWIFT: 'Bic/Swift',
      ABI: 'Abi',
      CAB: 'Cab',
      VAT: 'VAT',
      PAYMENT: 'Payment',
      NOTE: 'Note',
      NO_COMPANY_FOUND: 'No company found',
      BUSINESS_NAME_REQUIRED: 'Business name is required',
      VAT_REQUIRED: 'VAT number is required',
      FISCAL_CODE_REQUIRED: 'Fiscal code is required',
      SDI_REQUIRED: 'Code SDI is required',
      ADDRESS_REQUIRED: 'Address is required',
      STREET_NUMBER_REQUIRED: 'Street number is required',
      COUNTRY_REQUITED: 'Country is required',
      CAP_REQUIRED: 'CAP is required',
      LOCATION_REQUIRED: 'Location is required',
      PROVINCE_REQUIRED: 'Province is required',
      PROGRESS_CODE: 'Progress code',
      CITY: 'City',
      STREET: 'Street',
      COUNTRY: 'Country',
    },
    PROVIDER_FORM: {
      ACTIVE: 'Active',
      DEACTIVATED: 'Deactivated',
      VISIBLE: 'Visible',
      HIDDEN: 'Hidden',
      SAVE: 'Save',
      EDIT: 'Edit',
      ADD_PROVIDER: 'Add provider',
      PROVIDER_DETAILS: 'Provider details',
      BUSINESS_NAME: 'Business name',
      VAT_NUMBER: 'VAT number',
      FISCAL_CODE: 'Fiscal code',
      CODE_SDI: 'SDI Code',
      REA: 'R.E.A',
      REGISTERED_OFFICE: 'Registered office',
      ADDRESS: 'Address',
      STREET_NUMBER: 'Street number',
      NATION: 'Nation',
      POSTAL_CODE: 'Postal code',
      LOCATION: 'Location',
      PROVINCE: 'Province',
      OPERATING_OFFICE: 'Operating office',
      PHONE: 'Phone',
      MOBILE_PHONE: 'Mobile phone',
      FAX: 'Fax',
      EMAIL: 'E-mail',
      EMAIL_PEC: 'Email Pec',
      REFERENT: 'Referent',
      WEB_SITE: 'Web site',
      BANK: 'Bank',
      BANK_BRANCH: 'Bank branch',
      IBAN: 'IBAN',
      CIN: 'CIN',
      BIC_SWIFT: 'Bic/Swift',
      ABI: 'Abi',
      CAB: 'Cab',
      VAT: 'VAT',
      PAYMENT: 'Payment',
      NOTE: 'Note',
      NO_PROVIDER_FOUND: 'No provider found',
      BUSINESS_NAME_REQUIRED: 'Business name is required',
      VAT_REQUIRED: 'VAT number is required',
      FISCAL_CODE_REQUIRED: 'Fiscal code is required',
      SDI_REQUIRED: 'Code SDI is required',
      ADDRESS_REQUIRED: 'Address is required',
      STREET_NUMBER_REQUIRED: 'Street number is required',
      LOCATION_REQUIRED: 'Location is required',
      PROVINCE_REQUIRED: 'Province is required',
      PROGRESS_CODE: 'Progress code',
      STREET: 'Street',
      COUNTRY: 'Country',
    },
    OPERATOR_FORM: {
      ACTIVE: 'Active',
      DEACTIVATED: 'Deactivated',
      SAVE: 'Save',
      EDIT: 'Edit',
      ADD_OPERATOR: 'Add operator',
      OPERATOR_DETAILS: 'Operator details',
      VAT_NUMBER: 'VAT number',
      FISCAL_CODE: 'Fiscal code',
      STATE: 'State',
      ROLE: 'Role',
      FIRST_NAME: 'First name',
      LAST_NAME: 'Last name',
      BIRTH_DATE: 'Date of birth',
      USERNAME: 'Username',
      OLD_PASSWORD: 'Old Password',
      NEW_PASSWORD: 'New Password',
      PASSWORD: 'Password',
      CONFIRM_PASSWORD: 'Confirm Password',
      BAR_CODE: 'Bar Code',
      MOBILE: 'Mobile',
      CITY: 'City',
      STREET: 'Street',
      COUNTRY: 'Country',
      ZIP: 'ZIP code',
      ADDRESS: 'Address',
      PROVINCE: 'Province',
      STREET_NUMBER: 'Street number',
      NATION: 'Nation',
      POSTAL_CODE: 'Postal code',
      LOCATION: 'Location',
      PLANING_NAME: 'Planning Name',
      PLANING_POSITION: 'Planning Position',
      PLANING_COLOR: 'Planning Color',
      IS_REQUIRED: 'Required field',
      MUST_MATCH: 'Passwords must match',
      PHONE: 'Phone',
      MOBILE_PHONE: 'Mobile phone',
      EMAIL: 'E-mail',
      PROGRESS_CODE: 'Progress code',
      VISIBLE: 'Visible',
      HIDDEN: 'Hidden',
      SELL_POINT: 'Sell point',
      PLANNING: 'Planning',
      NO_OPERATOR_FOUND: 'No operator found',
      ADD_NEW_OPERATOR: 'Add new operator',
      EDIT_OPERATOR: 'Edit operator',
      IS_INVALID_FISCAL: 'Invalid Fiscal Code',
      IS_INVALID_MAIL: 'Invalid Email',
    },
    CONFIGURATION: {
      NEW_CONTACT: 'Add new preferred contact',
      EDIT_CONTACT: 'Edit preferred contact',
      NEW_CONTACT_DESCRIPTION: 'Preferred contact description',
      NEW_PAYMENT_TYPE: 'Add new payment type',
      EDIT_PAYMENT: 'Edit payment type',
      NEW_PAYMENT_TYPE_DESCRIPTION: 'Payment type description',
      NEW_PROFESSION: 'Add new Profession',
      EDIT_PROFESSION: 'Edit profession',
      NEW_PROFESSION_DESCRIPTION: 'Profession description',
      NEW_IVA_CODE: 'Add new tax',
      EDIT_IVA_CODE: 'Edit tax',
      TVA_DESCRIPTION: 'TVA description',
      TVA_RATE: 'TVA rate',
      HOW_DID_YOU_MEET_US_: 'How did you meet us',
      CLOSE: 'Close',
      SAVE: 'Save',
      ADD_NEW_ELEMENT: 'Add new element',
      ATTACH_COLOR_TO_ALL_PRODUCTS: 'Associate the color with all products',
      EMPTY_DESCRIPTION: 'Description can\'t be empty',
      EMPTY_RATE: 'TVA rate value can\'t be empty',
      NEW_BRAND: 'New brand',
      EDIT_BRAND: 'Edit brand',
      BRAND_DESCRIPTION: 'Band description',
      NEW_CABIN: 'Add new cabin',
      EDIT_CABIN: 'Edit cabin',
      CABIN_DESCRIPTION: 'Cabin Description',
      PLANNING_POSITION: 'Position in the planning',
      PLANNING_COLOR: 'Color in the planning',
      PLANNING_YEAR: 'Year',
      NEW_PLANNING_YEAR: 'Add new planning',
      EDIT_PLANNING_YEAR: 'Edit planning',
      DESCRIPTION: 'Description',
      NEW_LINEA: 'New line',
      EDIT_LINEA: 'Edit line',
      LINEA_DESCRIPTION: 'Line description',
      CATEGORY_NAME: 'Category name',
      EDIT_CATEGORY: 'Edit category',
      NEW_CATEGORY: 'New category',
      CATEGORY_DESCRIPTION: 'Category description',
      EMPTY_NAME: 'Enter the category name',
      EMPTY_TYPE: 'Enter the category type',
      SUB_CATEGORY_NAME: 'Subcategory name',
      SUB_CATEGORY_DESCRIPTION: 'Subcategory description',
      SUB_CATEGORY_TYPE: 'Subcategory description',
      EDIT: 'Edit',
      DELETE: 'Delete',
      SUBCATEGORIES: 'Subcategories',
      ADD_SUBCATEGORIES: 'add new subcategory',
      NEW_SUBCATEGORY: 'New subcategory',
      EDIT_SUBCATEGORY: 'Edit subcategory',
      NO: 'No',
      APPLY_CATEGORY_COLOR_TO_ALL_PRODUCTS:'Do you want to apply the color to all the products in the category?'
    },
    DIALOG: {
      Back_to_online:'Now you are connected to the Internet',
      Connection_lost :'Internet connection not available. Please check',
      NOTE_TO_BE_SOON:'Will be soon available',
      YOU_WANT_TO_SHOW: 'Are you sure you want to show ',
      YOU_WANT_TO_HIDE: 'Are you sure you want to hide ',
      YOU_WANT_TO_HIDE_BOOKING: 'Attention if you proceed the appointment disappears definitively from the planning but any sms will ' +
        'still be sent to the customer. If you want to permanently delete it, proceed with the delete function',
      YOU_WANT_TO_DELETE: 'Are you sure you want to delete ',
      YOU_WANT_TO_DELETE_LINE: 'Are you sure you want to delete this line',
      UPDATE_SUCCESS: 'Successfully updated',
      CANNOT_UPDATE_SUBSCRIPTION: 'Cannot change a previously sold subscription ',
      CANNOT_UPDATE: 'Cannot updated',
      ADD_SUCCESS: 'Successfully added',
      DELETE_SUCCESS: 'Successfully deleted',
      YOU_WANT_DECAL_CABIN: 'The indicated position is already occupied, in the planning, by another cabin, proceed with the insertion?\n' +
        'ATTENTION, the other cabins will be scaled by one position !',
      THIS_PLANNING_YEAR_ALREADY_EXIST: 'This schedule already exists',
      THIS_POSITION_IS_ALREADY_TOKEN: 'This position has already been assigned',
      YOU_MUST_FILL_ALL_THE_PLANNING: 'Enter all the schedules',
      YOU_WANT_TO_SYNCHRONIZE_RT: 'You want to synchronize RT? You will need to reconfigure VAT and Departments',
      NO_AVAILABLE_THRESHOLDS: 'No gift available',
      NO_AVAILABLE_CARD: 'No card associated',
      SAVE_SUCCESS: 'Save successfully',
      SUCCESS_EMIT_TICKET: 'Successfully print ticket',
      SUCCESS_PRINT_TEST: 'Success Print Test',
      NO_IVA_FOR_TREATMENT: 'No VAT associated with the service',
      NO_IVA_FOR_PRODUCT: 'No VAT associated with the product',
      NO_PAYMENT_RT_CONFIG: 'No payment configuration',
      NO_PAYMENT_RT_CONFIG_FOR: 'No payment configuration ',
      NO_TREATMENT_RT_IVA_CONFIG: 'No VAT RT configuration',
      NO_PRODUCT_IVA_RT_CONFIG: 'No VAT RT configuration',
      NO_RT_CONFIG: 'No RT configuration',
      YOU_WANT_TO_CLOSE_CASH: 'You Want to Close Cash',
      YOU_WANT_TO_READ_CASH: 'You Want to Read Cash',
      CLOSING_SUCCESS: 'Closing Success',
      CLOSING_FAILURE: 'Closing Failure',
      READING_SUCCESS: 'Reading Success',
      READING_FAILURE: 'Reading Failure',
      CANNOT_DELETE: 'Warning: it is not possible to delete the selected record because it is already used. ',
      FAILED_EMIT_TICKET: 'Failed to print ticket',
      CLOSE_CASH_REGISTER: 'Attention, close the cash register',
      PAYMENT_FAILED: 'Print failed',
      PAYMENT_FAILED_PRINT_STATUS: 'Attention, check the printer status',
      RECEIPT_NOT_SENT: 'receipt not sent',
      DELETE_SUBSCRIPTION: 'Do you want to delete the subscription',
      YOU_MUST_ADD_PRODUCT: 'Add product',
      INSERT_ALL_MANDATORY_INFORMATION: 'Please insert all required data',
      FAILED_TO_CONNECT_TO_PRINT: 'Unable to connect to the printer',
      INTERNAL_SERVER_ERROR: 'Internal server error! Try again later',
      NO_INFORMATION_FOUND: 'No information found',
      ATTENTION_WEEK_PLANNING_CHANGED: 'Attention, you are about to change the week planning. To make the changes effective on the '
        + 'calendars of the operators and booths you have to reassign the week planning',
      YOU_WANT_TO_ENABLE_SALES_WITHOUT_PRINT: 'Do you want to activate the sale without a tax meter?',
      YOU_WANT_TO_DISABLE_SALES_WITHOUT_PRINT: 'Do you want to disable sales mode without tax meter ?',
      PRICE_CANNOT_BE_ZERO: 'Attention, it is not possible to enter a price lower than or equal to zero',
      YOU_WANT_TO_CLEAR_TEMPORARY_DATA: 'Do you want to clear all temporary data?',
      YOU_WANT_TO_REVOKE: 'Return to operator mode',
      SUCCESS_EMIT_QUOTE: 'Successfully print quote',
      FAILED_EMIT_QUOTE: 'The quote cannot be printed',
      NO_EAN_FOR_THIS_OPERATOR: 'The operator does not have an ean code, please make sure you have generated it',
      NON_EXISTENT_PRODUCT: 'Non-existent article',
      NON_CONFIGURED_PRODUCT: 'Not configured article',

      INVALID_PASSWORD: 'Invalid password',
      NOT_ENOUGH_CREDIT: 'Attention, the credit on the selected fidelity card is not sufficient for the full amount, therefore the ' +
        'available amount will be deducted and not the total to be paid',
      RANGE_FORM_INVALID: 'Could not save because one or more values entered are not consistent',
      LICENSE_CONFIG: 'License configuration',
      SELECT_ARTICLE_TO_GENERATE_DRAFT_MOVEMENT: 'Select the ticks of the articles for which you want to generate a draft movement',
      SUCCESS_CREAT_DRAFT_MOVEMENT: 'Success creat draft movement',
      YOU_WANT_TO_RESET: 'Attention, do you want to proceed with the definitive deletion of data?',
      ARE_YOU_SURE_TO_ANNUL_TICKET: 'Do you want to cancel the payment?',
      CANNOT_FIND_PAYMENT: 'Payment not found',
      ARE_YOU_SURE_TO_RETURN_ENTRIES: 'Do you want to return the payment?',
      YOU_WANT_TO_ENABLE: 'Are you sure you want to activate',
      YOU_WANT_TO_DISABLE: 'Are you sure you want to disable',
      YOU_MUST_SELECT_CLIENTS: 'you must select receivers',
      YOU_MUST_SELECT_METHOD_PROMO: 'you must select at least one promotion sending method (SMS, EMAIL, Facebook, Instagram)',
      INFO_TEMPLATE_PROMO: "This field is used in the case of sending an email (text and image of the template are taken into consideration) and in the case of a publication on Facebook and Instagram (only the image is taken into consideration)",
      INFO_SUBJECT: "This field is used when sending an email and represents the subject of the email",
      INFO_MESSAGE: "This field is used when sending an SMS. It represents the text of the message. In the case of Facebook and Instagram it represents the textual part of the post.",      YOU_WANT_TO_REACTIVE: 'Are you sure you want to reactivate',
      INFO_DATE_HOUR: "for all types of publication it is possible to configure the sending date and time",
      INFO_DESTINATION: "This field allows you to specify one or more recipients of an email or text message",
      INFO_SMS: "To create an SMS promotion go to Marketing, select the SMS & WhatsApp Promo item and click on SMS & WhatsApp Campaign",
      YOU_WANT_TO_ANNUL: 'Are you sure you want to cancel',
      YOU_MUST_SELECT_ELEMENTS: 'Must select item',
      ELEMENT_MUST_HAVE_THE_SAME_IVA: 'Attention you can only make a deposit with products or services with the same VAT code',
      SELECT_ALL_ELEMENT_WITH_IVA: 'Select all item with VAT ',
      SUCCESS_SEND_RESET_PASSWORD: 'Password reset email sent successfully',
      FAILED_SEND_RESET_PASSWORD: 'Failed to send email rest password',
      INVALID_MAIL: 'The email is not valid',
      CONFIRM_CLIENT: 'Are you sure you want to confirm the customer',
      SUCCESS_CONFIRM: 'Customer confirmation successful',
      FAILED_CONFIRM: 'Unable to Confirm Customer',
      ATTENTION_REQUIRED_EXCEL_FIELDS: 'Attention, make sure you have created the secondary tables like categories, brands, VAT, etc.',
      CANNOT_IMPORT_PRODUCTS_CHECK_YOUR_FILE: 'Error loading products! Please check your file',
      SUCCESS_CREAT_PRODUCTS: 'Add products successfully',
      SUCCESS_CREAT_CLIENTS: 'Add customers successfully',
      INVALID_LOTTERY: 'Invalid lottery code',
      CORRECT_LOTTERY: 'Lottery code correctly entered',
      NO_PRODUCT_INSERTED: 'No article inserted in the document',
      ATTENTION_REQUIRED_TREATMENTS_EXCEL_FIELDS: 'Be careful, make sure you have created the secondary tables' +
        'such as categories, line, VAT, etc.',
      SUCCESS_CREAT_TREATMENTS: 'Add service to us successfully',
      ATTENTION: 'Attention',
      PLANNING_COMPLETE: 'Do you want to close the appointment?',
      INVALID_LOTTERY_EMPTY: 'Lottery code can\'t be empty',
      INVALID_LOTTERY_NOT_EIGHT_CHARS: 'Lottery code must be 8 characters',
      INVALID_LOTTERY_INVALID_CHARS: 'Lottery code does not allow special characters',
      YOU_WANT_TO_DISPLAY_TUTTI_PDT: 'Do you want to load the current stock of all products?',
      INVALID_FILE_TYPE: 'Invalid file type',
      MAILING_ERROR: 'Email sending server not working. Contact the Administrator',
      ATTENTION_RT_DEMO_MODE: 'Attention! The RT printer is in the Demo mode',
      ERROR_IN_PRINT_CONTACT_ASSISTANCE: 'Warning, the printer could not be contacted correctly, please restart it. If the problem persists, contact Support',
      UPDATE_VERSION_MSG: 'An update has been made, the page will be reloaded in 5 seconds',
      UPDATE_VERSION: 'New version',
      CLIENT_WITH_SAME_EMAIL_PHONE: 'Clients with same email / mobile phone'
    },
    ADMIN: {
      CENTER: {
        LIST: 'List of centers',
        ADD: 'Add',
        ADD_NEW: 'Add new center',
        SAVE: 'Save',
        EDIT: 'Edit',
        CLOSE: 'Close',
        DELETE: 'Delete',
        INFO: 'Info',
        COMPANY_NAME: 'Company name',
        VAT_NUMBER: 'VAT number',
        FISCAL_CODE: 'Fiscal code',
        CODE_SDI: 'SDI Code',
        REA: 'R.E.A',
        ADDRESS: 'Address',
        STREET_NUMBER: 'Street',
        COUNTRY: 'Country',
        ZIP_CODE: 'ZIP code',
        CITY: 'City',
        PROVINCE: 'Province',
        CONTACT: 'Contact',
        PHONE: 'Phone',
        MOBILE_PHONE: 'Mobile phone',
        FAX: 'Fax',
        EMAIL: 'E-mail',
        REFERENT: 'Referent',
        WEB_SITE: 'Web site',
        BANK: 'Bank',
        BANK_BRANCH: 'Bank branch',
        IBAN: 'IBAN',
        CIN: 'CIN',
        BIC_SWIFT: 'Bic/Swift',
        ABI: 'Abi',
        CAB: 'Cab',
        NOTE: 'Note',
        NO_CENTER_FOUND: 'No center found',
        GO_TO: 'Go to',
        COMPANY_NAME_REQUIRED: 'Company name is required',
        VAT_REQUIRED: 'VAT number is required',
        VAT_INVALID: 'VAT number is invalid',
        FISCAL_CODE_REQUIRED: 'Fiscal code is required',
        SDI_REQUIRED: 'Code SDI is required',
        ADDRESS_REQUIRED: 'Address is required',
        STREET_NUMBER_REQUIRED: 'Street number is required',
        COUNTRY_REQUIRED: 'Country is required',
        CAP_REQUIRED: 'ZIP code is required',
        LOCATION_REQUIRED: 'Location is required',
        PROVINCE_REQUIRED: 'Province is required',
        PROGRESS_CODE: 'Progress code',
        TYPE_OF_ACTIVITY: 'Type of activity',
        VAT_NUMBER_USED: 'Attention, the VAT number is already in use.'
      },
      SELL_POINT: {
        LIST: 'List of point of sales',
        ADD: 'Add',
        NO_SELL_POINT: 'No sell point found, click [add] to add new  point of sale to ',
        OPENING_HOUR: 'Opening hours',
        FINAL_HOUR: 'Final Hours',
        REFERENT: 'Referent',
        MOBILE: 'Mobile',
        ADD_NEW: 'Add new point of sale to',
        EDIT: 'Edit',
        INFO: 'Info',
        NAME: 'Name',
        PLANNING_TYPE: 'Planning type',
        ADDRESS: 'Address',
        COUNTRY: 'Country',
        STREET: 'Street',
        ZIP_CODE: 'ZIP code',
        PROVINCE: 'Province',
        CITY: 'City',
        PHONE: 'Phone',
        EMAIL: 'E-mail',
        DESCRIPTION: 'Description',
        NOTE: 'Note',
        SAVE: 'Save',
        CLOSE: 'Close',
        NO_CABIN_FOUND: 'No Cabin Fond',
        CANNOT_DELETE: 'Sell point can\'t be deleted',
        ACTIVE_ON_MOBILE: 'Active on mobile',
        SIEGE_ALREADY_EXIST: 'Attention, there is a <b> {{name}} </b> point of sale designated as Headquarters, would you like to proceed?',
      },
      ROLE: {
        ROLES: 'Roles',
        AUTHORITIES: 'Authorities',
        NAME: 'Name',
        DESCRIPTION: 'Description',
        ADD_ROLE: 'Add new role',
        EDIT_ROLE: 'Edit role',
        DELETE_ROLE: 'Delete role: {{roleName}}',
        DELETE_ROLE_MSG: 'You are about to delete {{roleName}}. Are you sure?',
        DELETE_ERROR: 'The role ({{role.name}}) is linked to one or many users!',
        NAME_REQUIRED: 'Role name is required',
        DESC_REQUIRED: 'Role description is required',
        ROLE_UPDATED: 'Role updated successfully',
        NO_ROLE_FOUND: 'No role found, click add to add new role',
        SELECT_ROLE: 'Select a role to edit it\'s authorities',
        CHANGES_NOT_SAVED: 'Warning, you have not saved your changes, do you want to exit?',
      },
      GROUP: {
        ATTENTION: 'Attention',
        DELETE_TITLE: 'Delete {{name}}',
        GROUP: 'group',
        GROUPS: 'Groups',
        YOU_ARE_ABOUT_TO_DELETE: 'You are about to delete <b>{{name}}</b>. Are you sure?',
        YOU_ARE_ABOUT_TO_RESTORE: 'You are about to restore <b>{{name}}</b>. Are you sure?',
        CANNOT_DELETE_GROUP: 'Group can\'t be deleted because it is not empty',
        CANNOT_DELETE_SUPER_ADMIN: 'Super admin can\'t be deleted directly',
        ADD_GROUP: 'New client',
        EDIT_GROUP: 'Edit client',
        GROUP_INFO: 'Group info',
        SUPER_ADMIN_INFO: 'Super admin info',
        USERNAME_EXISTS_ERROR: 'Username is taken',
        ADMINISTRATOR_PANEL: 'Company Administration Panel',
        DATE_VALIDITY_TITLE: 'Setup group license validity',
        LICENSE_DURATION: 'Licence duration',
        DAYS: 'days',
        CUSTOM: 'custom',
        NUMBER_OF_DAYS: 'Number of days',
        MONTH: 'month',
        TRIMESTER: 'trimester',
        MONTHS: 'months',
        YEAR: 'year',
        STARTS_AT: 'Starts at:',
        START_DATE: 'Start date',
        DAYS_REMAINS: 'days remains',
        EXPIRES_AT: 'expires at',
        EXPIRATION_DATE: 'expiration date',
        NO_GROUP_FOUND: 'No group found.',
        SEARCH: 'Search',
        VALIDITY: 'Validity',
        ALL: 'All',
        VALID: 'Valid',
        EXPIRED: 'Expired',
        STATE: 'State',
        ACTIVE: 'Active',
        INACTIVE: 'Inactive',
        UPDATED_SUCCESS: 'Updated successfully',
        QUOTE_DURATION: 'quote duration',
        VERIFICATION: 'Verification',
        VERIFIED: 'Verified',
        NOT_VERIFIED: 'Not verified',
        LICENSE: 'License',
        FROM: 'From',
        TO: 'To',
        RANGE_DURATION: 'Evaluation interval',
        SEMESTER: 'Semester',
        RANGE_CONFIG: 'Customer classification',
        FIRST_LEVEL: 'L1',
        SECOND_LEVEL: 'L2',
        THIRD_LEVEL: 'L3',
        FORTH_LEVEL: 'L4',
        VALUE_MUST_BE_GRATER_THAN: 'The value entered must be higher than',
        INVALID_VALUE: 'Invalid value',
        GENERAL: 'Generic',
        RANGE: 'Rating range',
        GENERAL_CONFIG: 'General configuration',
        NAME: 'Name',
        LEVEL: 'Level',
        EXPORT_AS_CSV: 'Export as csv',
        UTILITY: 'Utility',
        UTILITY_CONFIG: 'Utility configuration',
        EXPORT_CLIENT: 'Clients',
        RESET_FIDELITY: 'Reset fidelity points',
        HARD_RESET_FIDELITY: 'Reset fidelity point and historical deletion',
        TRASH: 'Trash',
        SMS_CONFIG: 'Configuration Sms',
        PROMO_CONFIG: 'Promo configuration',
        PROMO: 'Marketing promotions',
        DOWNLOAD_PRODUCT_EXCEL_MODEL: 'Download product excel model',
        UPLOAD_PRODUCTS: 'Upload products',
        UPLOADED_PRODUCTS: 'Uploaded products',
        DOWNLOAD_CLIENT_EXCEL_MODEL: 'Download client excel model',
        UPLOAD_CLIENTS: 'Upload clients',
        UPLOADED_CLIENTS: 'Uploaded clients',
        PLANNING_NAME_EXISTS: 'Operator name already used, proceed anyway?',
        UPLOAD_TREATMENTS: 'Upload services',
        UPLOADED_TREATMENTS: 'Services loaded',
        DESCRIPTION: 'Description',
        GROUP_NAME: 'Group name',
        MODEL: 'Model',
      },
      TYPES_ACTIVITIES: {
        ADD_NEW: 'Add new activity',
        NO_ACTIVITY_FOUND: 'No activity found',
        DELETE_MSG: 'Your are about to delete'
      },
      LICENSE_CONFIG: {
        DELETE_MSG: 'You are about to delete {{name}}!',
        ERROR_DELETE: 'License already in use!'
      }
    },
    PRODUCT_FORM: {
      PROD_CODE: 'Item number',
      CODE: 'Code',
      ID: 'ID',
      NO_PRODUCT_FOUND: 'No products found',
      ADD_PRODUCT: 'Add a product',
      PRODUCT_DETAILS: 'Product details',
      SAVE: 'Save',
      EDIT: 'Edit',
      PROGRESS_CODE: 'Code',
      CREATION_DATE: 'Creation date',
      BAR_CODE: 'Bar code',
      DESCRIPTION: 'Description',
      DESCRIPTION_REQUIRED: 'Required description',
      COMMERCIAL_DESCRIPTION: 'Commercial description',
      CATEGORY: 'Category',
      CATEGORY_REQUIRED: 'Required category',
      USE_TYPE_REQUIRED: 'Required use type',
      BARCODE_REQUIRED: 'Required bar code',
      SUBCATEGORY: 'Subcategory',
      BRAND: 'Brand',
      LINEA: 'Line',
      PROVIDER: 'Provider',
      USE_TYPE: 'Use type',
      MEASURE_UNIT: 'Unit of measure',
      TAX: 'Tax',
      MIN_STOCK: 'Stock min.',
      PURCHASE_COST: 'Purchase cost',
      RECHARGE: 'Recharge',
      PRICE: 'Price',
      PRICE_PDV: 'Price PDV',
      NOTE: 'Product notes',
      CANCEL: 'Cancel',
      MEDUIM_COST: 'Average cost',
      LAST_LOADING_COST: 'Last loading cost',
      LAST_SALE_PRICE: 'Last sale price',
      STOCK: 'Stock',
      UNIT: 'Unit',
      INTERNAL_USE: 'Internal use',
      IN_SALE: 'In sale',
      INTERNAL_USE_OR_IN_SALE: 'Internal use or in sale',
      BARCODE_INCORRECT: 'Bar code must be valid',
      ACTIVATE_DETAILS_SALE: 'Activate product',
      WEIGHT_REQUIRED: 'Weight required',
      UNIT_REQUIRED: 'Unit required',
      ML: 'Ml',
      GR: 'Gr',
      QUANTITY: 'Weight',
      DESCRIPTION_PLU: 'Description PLU',
      NEW_STOCK: 'New Stock',
      OLD_STOCK: 'Last Stock',
      ACTIVE: 'Active',
      LAST_PRICE: 'Last sale price',
      LAST_COST: 'Last loading cost',
      MEDIUM_COST: 'Average cost',
      PRINT_TICKET: 'Print Labels',
      PRINT_QUANTITY: 'Quantity to be printed',
      VALID_BARCODE: 'Valid bar code',
      INVALID_BARCODE: 'Invalid barcode',
      UNIQUE_BARCODE: 'Unique barcode',
      NON_UNIQUE_BARCODE: 'Non unique bar code',
      PLU_REQUIRED: 'Code is mandatory',
      VALID_PLU: 'Valid code',
      INVALID_PLU: 'Invalid code',
      EXISTING_PLU:'Code already associated to another product',
      UNIQUE_PLU: 'Unique code',
      NON_UNIQUE_PLU: 'Non unique code',
      BARCODE_EMPTY: 'Barcodes empty',
      ONE_PER_PAGE: 'Continuous Form 40 x 27 Thermal Zebra',
      CONTINUOUS_63_37: 'Continuous Form 63 x 37 Termico Zebra',
      CONTINUOUS_40_30: 'Continuous Form 40 * 30 Termico Zebra',
      A4_62_32: 'Form A4 Label 62 * 32',
      LIST: 'Form A4 Label Buffetti 47.5 x 35',
      ETIQUETTE_TYPE: 'Etiquette type',
      PRINT_FACE_PLATES: 'Print Shelf labels',
      FACE_PLATES: 'Shelf labels',
      STOCK_ALERT: 'Stock alert Products',
      SHOW_STOCK_ALERT_LIST: 'Show items in stock alert',
      MIN_STOCK_INFO: 'If the field is left empty, it will not be taken into account by the software for the control of the under stock',
      INCLUDE_ACTIVATE_DETAILS_SALE: 'View disabled products',
      PRODUCT_DISABLED: 'Pr. disabled',
      ERROR_MAX_DISCOUNT: 'The discount cannot be greater than the amount of the product'
    },
    TREATMENT_FORM: {
      ID: 'ID',
      TREATMENT_DETAILS: 'Treatment details',
      ADD_TREATMENT: 'Add treatment',
      CANCEL: 'Cancel',
      EDIT: 'Edit',
      SAVE: 'Save',
      CREATION_DATE: 'Creation date',
      PROGRESS_CODE: 'Code',
      DESCRIPTION: 'Description',
      DESCRIPTION_REQUIRED: 'Mandatory description',
      CATEGORY: 'Category',
      CATEGORY_REQUIRED: 'Mandatory category',
      SUBCATEGORY: 'Sub-category',
      LINEA: 'Line',
      TAX: 'Tax',
      CABINE: 'Cabin',
      TEMPO_CABINE: 'Cabin time',
      TEMPO_OPERATORE: 'Operator time',
      PAUSE_TIME: 'Break time',
      PREZZO_MASTER: 'Price',
      PREZZO_PDV: 'Price per PDV',
      ENABLE_PRICE: 'Activate the price',
      NOTE: 'Note',
      RELATED_PRODUCTS: 'Relative Products',
      ADD_PRODUCT: 'Add a product',
      NO_PRODUCT_FOUND: 'No product found',
      PRODUCT_REQUIRED: 'Product required',
      QUANTITY_REQUIRED: 'You must choose a quantity other than zero',
      PRODUCT_COST_IMPACT: 'Impact of product costs',
      COST_BEAUTY_CENTER: 'Cost of the center',
      PROFIT_MARGIN: 'Profit margin',
      AVERAGE_OPERATOR_HOURLY_COST: 'Average hourly operator cost',
      TREATMENT_COST: 'Cost of treatments',
      SUBCATEGORY_REQUIRED: 'Mandatory subcategory',
      DISABLE_CABINE: 'No cabin',
      DISABLE_OPERATOR: 'No operator',
      ARTICLE: 'Product',
      ADD_RELATED_PRODUCTS: 'Add a relative product',
      EDIT_RELATED_PRODUCTS: 'Edit a relative product',
      MEASURE_UNIT: 'Unit of measure',
      PRICE_QUANTITY: 'Cost of the unit',
      QUANTITY: 'Quantity of the unit',
      PRICE: 'Total',
      QUANTITY_UNIT: 'Number of units',
      NO_TREATMENT_FOUND: 'No treatment found',
      UNIT: 'Unit',
      YOU_WANT_TO_HIDE_RELATED_PRODUCT: 'Are you sure you want to delete this product',
      NO_UNIT_SELECTED: 'No measurement unit selected',
      DESCRIPTION_PLU: 'Description PLU',
      NOTE_PRODUCT: 'Service processing protocol'
    },
    PLANNING_FORM: {
      NO_PLANNING_YET: 'No planning yet',
      PLANNING_LIST: 'Planning list',
      EDIT_PLANNING: 'Edit planning',
      NEW_PLANNING: 'Add new planning',
      ADD: 'Add',
      MONDAY: 'Monday',
      TUESDAY: 'Tuesday',
      WEDNESDAY: 'Wednesday',
      THURSDAY: 'Thursday',
      FRIDAY: 'Friday',
      SATURDAY: 'Saturday',
      SUNDAY: 'Sunday',
      ABBREVIATION_MONDAY: 'M',
      ABBREVIATION_TUESDAY: 'T',
      ABBREVIATION_WEDNESDAY: 'W',
      ABBREVIATION_THURSDAY: 'T',
      ABBREVIATION_FRIDAY: 'F',
      ABBREVIATION_SATURDAY: 'S',
      ABBREVIATION_SUNDAY: 'S',
      JANUARY: 'January',
      FEBRUARY: 'February',
      MARCH: 'March',
      APRIL: 'April',
      MAY: 'May',
      JUNE: 'June',
      JULY: 'July',
      AUGUST: 'August',
      SEPTEMBER: 'September',
      OCTOBER: 'October',
      NOVEMBER: 'November',
      DECEMBER: 'December',
      JUST_THIS_WEEK: 'Just this week',
      THIS_MONTH: 'This month',
      FIRST_QUARTER: 'First quarter',
      SECOND_QUARTER: 'Second quarter',
      THIRD_QUARTER: 'Third quarter',
      FORTH_QUARTER: 'Forth quarter',
      ALL_THE_YEAR: 'All the year',
      APPLICATION_MODE: 'Want to assign this template to:',
      ATTENTION_NON_AVAILABLE_OPERATOR: 'Attention you are about to enter an appointment in a non-availability time slot for the operator',
      ATTENTION_NON_AVAILABLE_CABIN: 'Attention you are about to enter an appointment in a non-availability time slot for the cabin',
      ATTENTION_NON_BEFORE_OPEN: 'Attention you are about to enter an appointment before the opening of the sell point',
      ATTENTION_NON_AFTER_CLOSE: 'Attention you are about to enter an appointment after the closing of the sell point',
      ATTENTION_DAY_OFF_OPERATOR: 'Attention you are about to enter and appointment during day off of the operator',
      ATTENTION_DAY_OFF_CABIN: 'Attention you are about to enter and appointment during day off of the cabin',
      OPERATOR_OCCUPIED: 'Operator <b> {{label}} </b> already occupied',
      CABIN_OCCUPIED: 'Cabin <b> {{label}} </b> already occupied',
      WARNING: 'Warning',
      WANT_TO_CONTINUE: 'Would you like to continue?',
    },
    FIDELITY_FORM: {
      QUANTITY: 'Quantity',
      PRICE: 'Price',
      NAME: 'Name',
      CREATION_DATE: 'Creation date',
      DISCOUNT: 'Discount',
      TOTAL_DISCOUNT: 'Total discount',
      CARD: 'Card',
      POINT_WITH_GIFT: 'Points with gift',
      ADD_RECHARGE: 'Add top-ups',
      RECHARGES: 'Recharges',
      CREATE_RECHARGE: 'Create recharge',
      VALUE: 'Value',
      PREPAID_WITH_DISCOUNT: 'Prepaid with discounts',
      EURO_SPENT: 'Every euro spent',
      LOAD_POINT: 'Loading points',
      THRESHOLDS: 'Thresholds',
      ADD_THRESHOLD: 'Add threshold',
      TREATMENT: 'Treatment',
      PRODUCT: 'Product',
      CREATE_THRESHOLD: 'Create threshold',
      THRESHOLD: 'Threshold',
      POINT: 'Points',
      APPLY_ALTER_THRESHOLD: 'Apply another threshold',
      NO_RECHARGE_FOUND: 'No refills found',
      SELECT_FIDELITY_TYPE: 'Select the type of loyalty',
      GIFT_CARD: 'Free Card',

      SOLD: 'Balance',
      NUMBER: 'Number',
      TYPE: 'Type',
      HISTORY: 'History',
      NO_HISTORY_FOUND: 'No move found',
      ASSOCIATION_DATE: 'Issue date',

      ASSOCIATION: 'ASSOCIATION',
      GAIN: 'Earnings',
      CONSUMPTION: 'Consumption',
      MANUAL_ADD: 'Manual addition',
      MANUAL_SUBTRACT: 'Subtract manual',
      PRODUCT_SELL: 'Product',
      TREATMENT_SELL: 'Treatment',
      ABONMENT_SELL: 'subscription',
      RELOAD: 'Reload',
      SELECT_CARD: 'Select card',
      IGNORE_PRINCIPAL_DISCOUNT: 'Attention the main discount entered will be ignored, do you want to continue?',
      RELOAD_VALUE: 'Reload',
      DUE_DATE: 'Due date',
      POINT_CART_NAME: 'Card description',
      PREPAID_CART_NAME: 'Card description',
      THRESHOLD_CART_NAME: 'Threshold Description',
    },
    PAYMENT: {
      ANONYMOUS: 'Anonymous client',
      TOTAL: 'Total',
      FEE_PAID: 'Fee paid',
      FEE_NOT_PAID: 'Fee not paid',
      CHECKOUT: 'Checkout',
      DESCRIPTION: 'Description',
      OPERATOR: 'Operator',
      CABIN: 'Cabin',
      SUBSCRIPTIONS: 'Subscriptions',
      QUANTITY: 'Quantity',
      PRICE: 'Unit pricing',
      DISCOUNT: 'Discount',
      FINAL_PRICE: 'Final price',
      ASSOCIATED_COMPANY: 'Associated company',
      GENERAL_CARD: 'General card',
      CARD: 'Card',
      AMOUNT: 'Amount',
      AMOUNT_TO_PAY: 'Rest to pay',
      CHARGE: 'Charge',
      BALANCE: 'Balance',
      POINTS: 'Points',
      POINTS_ACCRUED: 'Points accrued',
      HOMAGE: 'Homage',
      PREVENTIVE: 'Preventive',
      ALTERNATIVE_SELLING: 'fidelity/subscription',
      SHOPPING_CART_EMPTY: 'Shopping cart empty',
      START_ADDING_ITEMS: 'Start by adding an item from the list',
      GIFTS: 'Available gifts',
      PRODUCT: 'Product',
      TREATMENT: 'Treatment',
      POINT: 'Points',
      ASSOCIATE_CARD: 'Associate card',
      CREDIT_CARD: 'Bank card',
      CASH: 'Cash',
      CHEQUE: 'Check',
      BANK_CARD: 'Bank card',
      PAYMENT: 'Payment',
      REST: 'Rest',
      PRICE_GIFT: 'Price',
      POINT_CARD: 'Point card',
      GIFT_CARD: 'Gift card',
      RECHARGE_CARD: 'Reload card',
      PAYMENT_MODE_ERROR: 'The amount entered is greater than the total.',
      DO_YOU_WANT_TO_USE_YOUR_FREE_OBJECTS: 'Do you want to use your gifts?',
      NO_PAYMENT_FOUND: 'No payment found',
      NO_QUOTE_FOUND: 'No quote found',
      STATUS: 'Status',
      DONE: 'Done',
      FAILED: 'Failed',
      PENDING: 'Pending',
      STATUS_HISTORY: 'Payment history status',
      OPERATOR_DETAILS: 'Operator details',
      USERNAME: 'Username',
      NAME: 'Name',
      MOBILE: 'Mobile',
      EMAIL: 'Email',
      ROLE: 'Role',
      PAYMENT_DETAILS: 'Payment details',
      SEARCH: 'search',
      WAIT_FOR_RECEIPT: 'Waiting for Doc. N.',
      RESET: 'Reset',
      RECEIPT: 'Receipt',
      TICKET: 'Ticket',
      TRANSFER: 'Bank transfer',
      PAY: 'Pay',
      RECEIPT_NOT_SENT: 'Discount. not issued ',
      PRINT_FAILED: 'Print discount. failed ',
      EAN_NON_VALID: 'Code EAN non valid',
      FAILED_RESET: 'Discount. issued without reset ',
      FAILED_TICKET: 'Discount. issued without progressive ',
      PAY_WITHOUT_PRINT: 'Pay without ticket print',
      SIMULATION: 'Closed account',
      ANONYMOUS_OPERATOR: 'Anonymous',
      PAYMENT_MODE: 'Payment method',
      QUOTE: 'Quote',
      SCAN: 'Scan',
      EAN_SCAN: 'Scan Ean',
      EAN_NUMBER: 'Ean Number',
      GIFT: 'Gift',
      RELOAD_CARD: 'Reload card',
      SUBSCRIPTION: 'Subscription',
      FISCAL: 'Fiscal',
      SATISPAY: 'Satispay',
      NO_SATISPAY_CONFIG: 'Satispay account not configured',
      WAITING_FOR_PAYMENT: 'Waiting for payment',
      ERROR_PRINT_QRCODE: 'Error when printing QR code',
      ERROR_CODE_SCANNED: 'Scan the printed QR code with satispay app to pay',
      ANNUL_TICKET: 'Cancel ticket',
      SEARCH_PAYMENT: 'Search payment',
      SHOW_RESO_LIST: 'View Returns and Cancellations',
      PAYMENT_ALREADY_ANNULLED: 'Receipt already canceled',
      RT_REFERENCE: 'RT serial number',
      PAYMENT_REFERENCE: 'Payment reference',
      PAYMENT_ALREADY_HAS_RESO: 'Receipt has already returned',
      RETURNED_QUANTITY: 'Return Qty',
      AVAILABLE_QUANTITY: 'Remaining Qty',
      QUANTITY_TO_RENDER: 'Qty to return',
      QUANTITY_TOTAL: 'Total Qty',
      GROUPON: 'Groupon',
      RESO: 'Return',
      TYPE: 'Type',
      ADVANCE: 'Advance',
      ADVANCE_COMPLETED: 'Advance completed',
      PAYMENTS: 'Payments',
      CREATE_ADVANCE: 'Create advance',
      CONTINUE_PAYMENT: 'Continue payment',
      TOTAL_PAYMENT: 'Total payment',
      CLIENT_NAME: 'Client name',
      SEARCH_ADVANCE: 'Search advances',
      PAYMENT_ADVANCE_COMPLETED: 'Advance completed',
      PAYMENT_ADVANCE: 'Advance payment',
      ACTION: 'Action',
      FAILED_ADVANCE_RESET: 'Advance issued without reset ',
      FAILED_ADVANCE_TICKET: 'Advance issued without progressive ',
      PRINT_ADVANCE_FAILED: 'Print advance failed ',
      ADD_DISCOUNT: 'Add discount',
      CARD_NAME: 'Card name',
      GIFTS_SOLD: 'Sold',
    },
    SUBSCRIPTION_FORM: {
      NAME: 'Name',
      TYPE: 'Type',
      STARTS_AT: 'Starts at',
      ENDS_AT: 'Ends at',
      PRICE: 'Price',
      SUBSCRIPTION_DETAILS: 'Abonnement details',
      ADD_SUBSCRIPTION: 'Add subscription',
      EDIT: 'Edit',
      SAVE: 'Save',
      NO_SUBSCRIPTION_FOUND: 'No subscription found',
      DESCRIPTION: 'Description',
      FROM: 'From ',
      TO: 'To ',
      SALE_PERIOD: 'Sale period',
      SUBSCRIPTION_PERIOD: 'Abonnement period',
      DURATION: 'Duration',
      ALWAYS_ACTIF: 'Always actif',
      PRODUCTS: 'Products',
      TREATMENTS: 'Treatments',
      THREE_MONTHS: '3 months',
      SIX_MONTHS: '6 months',
      NINE_MONTHS: '9 months',
      TWELVE_MONTHS: '12 months',
      NUMBER_OF_DAYS: 'Number of days',
      OTHER: 'Other',
      PRODUCT: 'Product',
      TREATMENT: 'Treatment',
      TOTAL: 'Impact of total costs',
      TOTAL_PRICE: 'Total price',
      CATEGORY: 'Category',
      WEIGHT: 'Weight',
      PURCHASE_COST: 'Purchase cost',
      SALE_COST: 'Sale price',
      QUANTITY: 'Quantity',
      DISCOUNT: 'Discount',
      FINAL_PRICE: 'Final price',
      FREE: 'Free',
      MINUTES: 'Minutes',
      COST: 'Cost impact',
      UNIT: 'Unity',
      NO_PRODUCT_FOUND: 'No product found',
      NO_TREATMENT_FOUND: 'No treatment found',
      CREATION_DATE: 'Creation date',
      ENTRIES: 'Details',
      MY_SUBSCRIPTIONS: 'My subscriptions',
      HISTORY: 'History',
      DESC: 'Descr',
      CHOOSE_PRODUCT_OR_TREATMENT: 'Choose at least a product or a treatment'
    },
    PLANNING: {
      TODAY: 'Today',
      TOMORROW: 'Tomorrow',
      YESTERDAY: 'Yesterday',
      HOUR: 'Hour',
      CLIENT: 'client',
      ADD_TREATMENT: 'Add treatment',
      TREATMENT: 'Treatment',
      CABIN: 'Cabin',
      OPERATOR: 'Operator',
      DURATION: 'Duration',
      ALERT_TITLE: 'Delete Booking',
      NEW: 'New',
      NOTE: 'Note',
      NO_NOTE: 'No note',
      CONTACT: 'Contact',
      FIRST_PASSAGE: 'First passage',
      LAST_PASSAGE: 'Last passage',
      ALERT_TEXT: 'You are about to delete booking forever, are you sure?',
      FIRST_VISIT: 'First visit',
      LAST_VISIT: 'Last visit',
      HISTORY: 'History',
      PAID: 'PAID',
      DURATION_MSG: 'Minimum duration of cabin and operator is 10 minutes.',
      LOADING: 'Loading',
      CONFIRM_CANCELLATION: 'Do you confirm the cancellation?',
      SELECT_CABIN_OR_OPERATOR: 'Select at least one cabin or operator for a treatment',
      COMPANY: 'Company',
      Disable: 'Disable',
      NEW_WORKING_HOURS: 'New working hours',
      START_DATE: 'Start date',
      END_DATE: 'End date',
      SELECT_MIN_OPERATOR_OR_CABIN: 'Select at least one operator or one cabin',
      WARNING_INVALID_ACTION: 'Warning, invalid action!',
      DEACTIVATED: 'Deactivated',
      START_HOUR_FROM: 'Hour from',
      START_HOUR_TO: 'Hour to',
      NO_BOOKING_FOUND: 'No booking found',
      DRAG_THE_APPOINTMENT: 'Drag the appointment',
      APPOINTMENT_COPIED: 'Appointment copied successfully',
      CLIENT_SEARCH: 'Search buy first and last name',
      BOOKING_LOG: 'Log',
      CREATED_AT: 'Created at',
      UPDATED_AT: 'Updated at',
      CANCELED_AT: 'Canceled at',
      CREATED_BY: 'Created by',
      APP_APPOINTMENTS: 'App appointments',
      HOURS_NOT_AVAILABLE: 'Hours not available',
      WEEK: 'Week',
      DAY: 'Day',
      PREVIEW_NOT_AVAILABLE: 'Preview not available, download the pdf to view it',
      DISABLE_DAY_OPERATOR: 'There are appointments assigned to this operator. We advise you to check and reassign them if necessary. Would you like to proceed anyway?',
      DISABLE_DAY_CABIN: 'There are appointments assigned to this cabin. We advise you to check and reassign them if necessary. Would you like to proceed anyway?',
      APPOINTMENT_OPERATOR_CHECK_WARNING: 'Attention, there are already appointments for the operator {{operator}}, would you like to continue?',
      APPOINTMENT_CABIN_CHECK_WARNING: 'Attention, there are already appointments for the cabin {{cabin}}, would you like to continue?',
      APPOINTMENT_MIX_CHECK_WARNING: 'Attention, there are already appointments for the operator {{operator}} and the cabin {{cabin}}, would you like to continue?',
      CUSTOMER_IN_HURRY: 'Customer is in a hurry',
    },
    RT: {
      RT_CONFIGURATION: 'RT Configuration',
      ALL_CONFIGURATION: 'All configurations',
      GET_RT_CONFIG: 'Get the RT configuration',
      THE_CONFIGURATION_OF_THIS_IP_ADDRESS_IS_ALREADY_SAVED: 'The configuration of this ip address is already saved',
      EDIT_DEPARTMENT: 'Edit Department',
      EDIT_VAT: ' Edit VAT',
      DEPARTMENT: 'Department',
      VAT: 'VAT',
      PAYMENT: 'Payment',
      OPERATOR: 'Operator',
      HEADER: 'Header',
      EDIT_OPERATOR: 'Edit Operator',
      DESCRIPTION: 'Description',
      EDIT_PAYMENT: 'Edit Payment',
      DELETE_ERROR: 'The RT Config is linked to one or many payments!',
      EDIT_HEADER: 'Edit Header',
      PRINT_TEST: 'Print Test',
      CASHIER_OPERATIONS: 'Cashier Operations',
      CASH_REGISTER_CLOSURE: 'Cash Register Closure',
      DAILY_READING: 'Daily Reading',
      DEPARTMENT_COUPLING: 'Department coupling',
      PAYMENT_COUPLING: 'Payment coupling',
      ENABLE_DISABLE_PAYMENT_WITHOUT_PRINT: 'Enable / disable sales mode without tax meter',
      CONFIGURATION: 'View Configuration',
      RETRIEVE_CONFIGURATION: 'Retrieve Configuration',
      DEPARTMENTS: 'Dpartements',
      CLOSURES: 'Closures',
      ARE_YOU_SURE_POPUP: 'Are you sure you want to use pop up printing?',
      YOU_ARE_USING_STANDARD: 'You are now using the standard printing service. Make sure you have authorized unsafe content on your ' +
        'browser or made https your printer',
      YOU_ARE_USING_POPUP: 'You are using pop up printing',
      IF_YOU_DONT_WANT_TO_USE: 'If you don\'t want to use printing popup you have to authorize the unsafe content on your browser or ' +
        'make https your printer',
      USE_PRINTING_POPUP: 'Use printing popup',
      SETTINGS: 'Settings',
      DEMO: 'Demo print',
      RCH: 'RCH',
      RCH_NATIVE: 'RCH Native',
      EPSON: 'Epson'
    },
    COURTESYRT:{
      HEADERFOOTERCONFIGURATIONTOOLTIP:'Configure header and footer',
      ONLYFORINDICATIVEPURPOSE:'The preview is for indicative purposes only, click here to perform the test print',
      DID_THE_PRINTING_TEST_TOOK_PLACE:'Did the test print took place?',
      HEADERFOOTERCONFIGURATION:'Set header and footer',
      HEADERFOOTERNOTUPDATED:'Due to server error, header and footer were not updated',
      HEADERFOOTERUPDATED:'Header and footer have been updated',
      CHECK_IP:'Please check the ip address you entered',
      RT_ADDED:'The printer was added successfully',
      YES:'YES',
      NO:'YES',
      CONTENT:'CONTENT',
      ROWS:'ROWS',
      ROWONE:'one',
      ROWTWO:'two',
      ROWTHREE:'three',
      ROWFOUR:'four',
      ROWFIVE:'five',
      ROWSIX:'six',
      ROWTEXT:'text',
      STYLE:'style',
      CENTERTEXT:'center text',
      DOUBLEHEIGHTTEXT:'double height text',
      DOUBLEWIDTHTEXT:'double width text',
    },
    LOGIN: {
      USERNAME: 'Username',
      PASSWORD: 'password',
      FORGET_PASSWORD: 'Forget password?',
      REMEMBER_ME: 'Remember me',
      LOGIN: 'Login',
      WELCOME_BACK: 'Welcome back',
      CHOOSE_SELLPOINT: 'Choose your sell point',
      LOGOUT: 'Logout',
      CONFIRM: 'Confirm',
      SELL_POINTS: 'sell points',
      LANGUAGE: 'Language',
      FRENCH: 'French',
      ITALIAN: 'Italian',
      ENGLISH: 'English',
      WRONG_USERNAME_PASSWORD: 'Wrong username or password',
      ACCOUNT_LOCKED: 'Your account is locked',
      AUTHENTICATION: 'Authentication',
      SIGN_IN_WITH_YOUR_BARCODE: 'Sign in with your barcode',
      CODE_SUCCESSFULLY_IDENTIFIED: 'Code successfully identified',
      AWAITING_READING: 'Awaiting reading',
      USE_BARCODE: 'Use barcode',
      OWNER_OR_SUPEADMIN_BARCODE_ERROR: 'Owner and super admin can\'t login using a barcode, please use your username and password',
      SWITCH_LICENSE_MSG: 'Dear Customer, the trial period is over. You can still continue to use the application in free mode. If you ' +
        'would like to upgrade please contact us.',
      YOU_DONT_HAVE_ACCOUNT: 'You don\'t have an account?',
      SIGN_UP: 'Sign up.',
      LOGIN_PAGE: 'Login Page',
    },
    SMS: {
      TEMPLATE_NAME: 'Template Name',
      LANGUAGE_CODE: 'Language Code',
      COMPAIGN_NUMBER: 'Campaign N.',
      TEMPLATE: 'Template',
      CREATION_DATE: 'Creation date',
      SCHEDULED_AT: 'Scheduled at',
      SUBSCRIBERS_NUMBER: 'Subscribers number',
      NEW_COMPAIGN: 'New Compaign',
      SELECT_ALL: 'Select all',
      AT: 'At',
      DATE: 'Date',
      FIRST_NAME: 'First name',
      LAST_NAME: 'Last name',
      SETTINGS: 'SMS settings',
      PROVIDER_CONFIGURATION: 'Smshosting Provider configuration',
      APPOINTMENT_REMINDER: 'Appointment reminder',
      GENERIC_SMS_TO_APPOINMENTS: 'Generic SMS for appointment clients',
      GENERIC_MESSAGE_FROM: 'Appointments from',
      GENERIC_MESSAGE_TO: 'to',
      BEFORE: 'Before',
      HOURS: 'Hours',
      TIME: 'Time',
      BIRTHDAY_REMINDER: 'Birthday reminder',
      CONFIGURATION_SUCCESS: 'Configuration updated successfully',
      HOSTNAME: 'Hostname',
      PORT: 'Port',
      USERNAME: 'Username',
      PASSWORD: 'Password',
      PROXY: 'Proxy',
      PROXY_PORT: 'Proxy port',
      ACTIVATE_PROXY: 'Activate proxy',
      STATUS: 'Status',
      DO_YOU_WANT_TO_DELETE_CAMPAIGN: 'Do you want to delete the campaign ?',
      CAMPAIGN_ID: 'Campaign ID',
      RECEIVER_ID: 'Receiver ID',
      RECEIVER: 'Receiver',
      SENT_AT: 'Sent at',
      CAMPAIGN_REMINDER: 'Campaign reminder',
      MOBILE: 'Mobile',
      SENT: 'Sent',
      FAILED: 'Failed',
      INSERTED: 'Sent',
      NOT_INSERTED: 'Failed',
      DELIVERED: "Delivered ",
      NOTDELIVERED: "Not delivered",
      PENDING: "Pending",
      //SENT: "SENT",
      NOSENT: "Not sent",
      SCHEDULED: 'Scheduled',
      CANCELED: 'Canceled',
      DONE: 'Done',
      PHONE: 'Phone',
      SENDER: 'Text or sender number',
      TEST: 'Key validation',
      KEY_VALIDATED: 'Validation key confirmed',
      KEY_NOT_VALIDATED: 'Validation key are not valid',
      INFO: 'Attention, to ensure the sending of all text messages to recipients, ' +
        'postpone at least 10 minutes compared to the current time of sending',
      SELECT_CLIENTS: 'Select clients',
      CREDITS: 'SMS balance',
      BUY: 'Buy SMS',
      BADNUMBERFORMAT: 'Bad number format',
      DUPLICATESMS: 'DUPLICATE SMS: SMS already entered in the last 24 hours with identical recipient, sender and text',
      BLACKLIST: 'BLACK LIST: recipient in blacklist',
      BAD_CREDIT: 'Insufficient credit',
      SEND_NOW: 'Instant send',
      ONLY_SEND: 'Send',
      SUCCESS: 'Sending successful',
      SEND_FAILED: 'Error sending, try again or contact support',
      ERROR_PROVIDER: 'Error trying to contact the SMS provider',
      BAD_TRANSACTIONID: 'Parameter transactionId not valid',
      BAD_DATE: 'Format date not valid',
      BAD_TEXT: 'Parameter text not valid',
      BAD_TEXT_PLACEHOLDER: 'Text contains non-replaceable placeholders',
      BAD_CALLBACK: 'Parameter statusCallback not valid',
      BAD_ENCODING: 'Parameter encoding not valid',
      BAD_FROM: 'Parameter from not valid',
      BAD_GROUP: 'Parameter group not valid',
      NO_CREDIT: 'Insufficient credit',
      NO_VALID_RECIPIENT: 'No valid recipients',
      GENERIC_ERROR: 'Internal server error',
      INVALID_CREDENTIALS: 'Invalid credentials',
      METHOD_NOT_ALLOWED: 'HTTP Method not allowed',
      RESEND: 'Do you want to send the message to {{nome}} {{cognome}} on {{number}} ?',
      SEND_QUESTION: 'Confirm creation of this mail campaign?',
      MAX_ELEVEN: 'Allowed characters 11, including spaces'
    },
    WHATS_APP: {
      GENERIC_MSG_TO_APPOINMENTS: 'WhatsApp generic messages for customers with appointments',
      SETTINGS: 'WhatsApp Settings',
      BASE_URL: 'Base URL',
      Sender_Phone_Number_Id: 'Sender Phone Number ID',
      Token: 'Token',
      Whatsapp_config: 'WhatsApp Web Configuration',
    },
    MOVEMENT_FORM: {
      CODE: 'Code',
      NO_MOVEMENT_FOUND: 'No movement found',
      CREATE_MOVEMENT: 'Create movement',
      CREATE_TRANS: 'Create transfer',
      DOCUMENT_NUMBER: 'Document number',
      CLIENT: 'Customer',
      PROVIDER: 'Supplier',
      PAYMENT: 'Payment',
      DOCUMENT_DATE: 'Document date',
      DOCUMENT_TYPE: 'Document type',
      TYPE: 'Type',
      NUMBER: 'N°',
      DATE: 'Movement Date',
      BAR_CODE: 'bare code',
      PLU_DESCRIPTION: 'Description Plu',
      PURCHASE_COAST: 'Purchase cost',
      IVA: 'VAT',
      QUANTITY: 'Quantity',
      CASH: 'Cash',
      CHEQUE: 'Check',
      CREDIT_CARD: 'Bank card',
      RECAP: 'Summaries',
      TOTAL_QUANTITY: 'QT. Tot. Art.',
      TOTAL_IVA: 'Total VAT',
      TOTAL_TAXABLE: 'Total Taxable',
      SUPPLIER_LOAD: 'Supplier load',
      CUSTOMER_SALES: 'Client sales',
      SELF_CONSUMPTION: 'Self-consumption',
      VARIOUS_DISCHARGE: 'Varied unloading',
      VARIOUS_LOAD: 'Varied loading',
      NONE: 'None',
      BILL: 'Invoice',
      DDT: 'DDT',
      ADD_PRODUCT: 'Add product',
      TOTAL_IVATO: 'Total VAT',
      PRODUCT: 'Product',
      DATE_FROM: 'Date from',
      DATE_TO: 'Date to',
      SAVE_DRAFT: 'Save draft',
      MOVEMENT_DETAILS: 'Movement detail',
      INVENTORY_DETAILS: 'Inventory detail',
      CREATE_INVENTORY: 'Create inventory',
      NOTE: 'Note',
      CLIENT_PROVIDER: 'Supplier / Customer',
      DRAFT: 'Draft',
      EDIT_NAME: 'Change name',
      EDIT_COST: 'Change purchase cost',
      EDIT_PRICE: 'Change sale price',
      EDIT_DETAILS: 'Change data',
      CREATION: 'Product creation',
      YES: 'Yes',
      NO: 'No',
      SELECT_COLUMNS: 'Personalize columns',
      LOAD_PRODUCTS: 'Load products',
      EDIT_PRODUCT: 'Edit product',
      UPLOAD_FILE: 'Upload File',
      DOWNLOAD_MODEL: 'Download model',
      NOT_FOUNDED: 'Product not founded',
      INVALID_ROW: 'Attention, invalid import file \n  row number',
      IMPORT_PREVIOUS_ROWS: 'Import previous rows?',
      SALES_PRICE: 'Selling price',
      RESO: 'Return',
      GIFT: 'Gift',
      RESULT_COUNT: 'Number of items displayed',
      ALL: 'All',
      TOTAL: 'Total',
      TRANSFER_DETAILS: 'Detail Transfer of goods',
      CREATE_TRANSFER: 'Create Transfer of goods',
      SELL_POINT_ORIGINE: 'Departure Sell Point',
      SELL_POINT_DEST: 'Destination Sell Point ',
      TOTAL_QUANTITY_TRANSFER: 'QT. Tot. Art.Tras',
      DELETE_LAST_MOVEMENT_ROW: 'Deleting the last row will delete the movement!',
    },
    STOCK_FORM: {
      PROGRESS_CODE: 'Code',
      CREATION_DATE: 'Creation date',
      BAR_CODE: 'Barcode',
      PRODUCT_HISTORY: 'Historical Product',
      OPERATOR: 'Operator',
      QUANTITY: 'Quantity',
      CONSUMED: 'Consumed',
      REMAINING_QUANTITY: 'Remaining quantity',
      USE_TYPE: 'Type of use',
      PURCHASE_COST: 'Purchase cost',
      AVERAGE_COST: 'Average cost',
      UNDER_ESCORT: 'Under escort',
      PHOTO: 'Photos',
      COMMERCIAL_DESCRIPTION: 'Commercial description',
      CATEGORY: 'Category',
      SUBCATEGORIES: 'Subcategory',
      PROVIDER: 'Provider',
      ML_GR: 'Ml / Gr',
      BRAND: 'Brand',
      LINEA: 'Line',
      STOCK: 'Stock',
      ACTIVE: 'Active',
      CODE: 'Code',
      IVA: 'VAT',
      TOTAL_PURCHASE_COST: 'Total Purchase Cost',
      CAUSAL: 'Causal',
      GIFT: 'Gift',
      DESCRIPTION: 'Description',
      PRICE: 'Price',
      MIN_STOCK: 'Stock min.',
      STOCK_ALERT: 'Stock alert Products',
    },
    STATISTIC_FORM: {
      TIME_LAPS: 'Time interval',
      CATEGORY: 'Category',
      CLIENT: 'Customer',
      SEARCH: 'Search',
      PRODUCT: 'Product',
      QTT_SALES: 'Quantity sold',
      QTT_RESO: 'Quantity returned - canceled',
      ALL_RESO: 'Total returned - cancellation',
      ALL_SALES: 'Total sold',
      ALL_PROFIT: 'Total profit',
      MONTH_SALES_VALUE: 'Sales value per month',
      MONTH_SALES_QTT: 'Sales quantity per month',
      TREATMENT: 'Service',
      CURRENT_WEEK: 'Week in progress',
      LAST_WEEK: 'Last week',
      CURRENT_MONTH: 'Month in progress',
      LAST_MONTH: 'Last month',
      FIRST_TRIMESTER: 'First quarter',
      SECOND_TRIMESTER: 'Second quarter',
      THIRD_TRIMESTER: 'Third quarter',
      FORTH_TRIMESTER: 'Fourth quarter',
      FIRST_SEMESTER: 'First semester',
      SECOND_SEMESTER: 'Second semester',
      CURRENT_YEAR: 'Current year',
      LAST_YEAR: 'Last year',
      NO_TREATMENT_FOUND: 'No treatment found',
      NO_PRODUCT_FOUND: 'No products found',
      CSV: 'CSV',
      EXCEL: 'EXCEL',
      USERNAME: 'Username',
      OPERATOR: 'Operator',
      SALES_TYPE: 'Sales type',
      NO_OPERATOR_FOUND: 'No operator found',
    },
    DASHBOARD: {
      TODAYS_BOOKING: 'TODAY S BOOKING',
      NEXT_BIRTHDAY: 'THE NEXT ANNIVERSARY',
      INCASSA: 'Daily collection forecast',
      FICHE_MEDIA: 'Medium chip',
      SCONTRINO_MEDIO: 'Average Receipt',
      TOP_CLIENT: 'TOP CLIENTS',
      LAST2WEEKS: 'Last 2 weeks',
      TOP_SERVICE: 'TOP SERVICES',
      GO_TO_CONFIG: 'You haven\'t set up your account yet. Click here',
      COLLECTION_TREND_IN_THE_LAST_2_WEEKS: 'Collection trend in the last 2 weeks'
    },
    DAY: {
      MONDAY: 'Monday',
      TUESDAY: 'Tuesday',
      WEDNESDAY: 'Wednesday',
      THURSDAY: 'Thursday',
      FRIDAY: 'Friday',
      SATURDAY: 'Saturday',
      SUNDAY: 'Sunday'
    },
    REGISTER: {
      SIGN_UP: 'Sign up',
      ACTIVITY_NAME: 'Activity Name',
      FIRST_NAME: 'First name',
      LAST_NAME: 'Last name',
      EMAIL: 'Email',
      PASSWORD: 'Password',
      CONFIRM_PASSWORD: 'Confirm password',
      MOBILE: 'Mobile',
      CONFIRM: 'Confirm',
      EMAIL_EXISTS: 'This email address is unavailable',
      THANKS_FOR_SIGNUP: 'Thank you for your registration!',
      // tslint:disable-next-line:max-line-length
      REGISTER_SUCCESS: 'Thanks for checking in! Registration was successful. An email was sent to your address. The activation will be ' +
        'completed only after you click on the link received in the email. If you have not received anything, please check the ' +
        'spam/unsubscribe section.',
      LENGTH_ERROR: 'Length equal or more than 6',
      ONE_UPPERCASE_ERROR: 'Contain at least one uppercase character',
      ONE_LOWERCASE_ERROR: 'Contain at least one lowercase character',
      ONE_NUMBER_ERROR: 'Contain at least one number',
      VALIDATION: 'Validation',
    },
    IMAGE: {
      ADD_PICTURE: 'Add a photo',
      OR: 'Or',
      CLICK: 'Click here',
      DRAG: 'Drag a photo here',
      ADD: 'Add',
      CANCEL: 'Cancel',
      ANOTHER: 'Choose another',
      OVERSIZE: 'Attention, the selected image exceeds the maximum size allowed 2 MB',
      NOT_IMAGE: 'Select another photo'
    },
    INIT_ACCOUNT: {
      YOUR_COMPANY: 'Your company',
      SELL_POINT: 'Sell point',
      KEY_EXPIRED: 'Your registration email key is expired, please sign up again',
      BUSINESS_NAME: 'Business name',
      VAT: 'VAT number',
      YOUR_BUSINESS: 'The name of your business',
      ADDRESS: 'Address',
      STREET: 'Street',
      COUNTRY: 'Country',
      ZIP_CODE: 'Zip code',
      PROVINCE: 'Province',
      CITY: 'City',
      DEALER_CODE: 'Dealer code',
      YOUR_PLAN: 'Your plan',
      PLANNING_START_TIME: 'Planning start time',
      END_OF_PLANNING_HOURS: 'End of planning hours',
      OPERATOR: 'Operator',
      FIRST_NAME: 'First name',
      LAST_NAME: 'Last name',
      PLANNING_NAME: 'Planning name',
      USERNAME: 'Username',
      PASSWORD: 'Password',
      CONFIRM_PASSWORD: 'Confirm password',
      CABIN_NAME: 'Cabin name',
      CABIN: 'Cabin',
      USERNAME_NOT_AVAILABLE: 'Username {{username}} already used',
      COMPANY_NAME_NOT_AVAILABLE: 'Business name {{businessName}} already used',
      VAT_NUMBER_NOT_AVAILABLE: 'VAT number {{vatNumber}} already used',
      ADD_OPERATOR_OR_CABIN: 'To proceed, you must enter at least one operator or one cabin',
      DID_NOT_ADD_CABIN: 'You have not inserted a cabin. Do you want to proceed anyway?',
      DID_NOT_ADD_OPERATOR: 'You have not inserted an operator. Do you want to proceed anyway?',
    },
    FORGET_PASSWORD: {
      EXPIRED: 'Your token is expired, try again',
      FORGOT_YOUR_PASSWORD: 'Forgot your password?',
      ENTER_USERNAME: 'Enter the username associated with your account',
      WE_WILL_EMAIL_YOU: 'We will email you a link to reset your password',
      WRITE_YOUR_USERNAME: 'Write your username here',
      YOU_JUST_GOT_EMAIL: 'You just got an email with instructions to reset your password',
      CHANGE_YOUR_PASSWORD: 'Change your password',
      USERNAME_NOT_FOUND: 'Invalid username',
    },
    FISCAL_REPORT: {
      FISCAL_REPORT: 'FINANCIAL REPORT',
      TOTAL_CASSA: 'Total in cash',
      TOTAL_BANCA: 'Total in the Bank',
      TOTAL_DOC: 'SALES ',
      WITHOUT_SCONTO: 'Total sales (without discount)',
      SCONTO: 'Discounts applied',
      TOTAL: 'Discounted total',
      ABANDFID: 'SUBSCRIPTIONS & FIDELITY',
      TOTAL_AB_VEND: 'Total subscriptions sold',
      TOTAL_GC_VEND: 'Total giftcards sold',
      TOTAL_LC_VEND: 'Total Top-ups sold',
      TOTAL_PAG: 'PAYMENTS',
      SOLD: 'Sales',
      CASH: 'Cash',
      CREDIT: 'Credit card',
      ASSEGNO: 'check',
      BONIFICO: 'Transfer',
      TOT: 'Total',
      QUOTE: 'Quote'
    },
    FIRST_NOTE: {
      FIRST_NOTE: 'First note',
      FIRST_NOTE_CAUSE: 'First note cause',
      DATE: 'Date',
      NAME: 'Name',
      INCOME: 'INCOME',
      OUTLAY: 'OUTLAY',
      DELETE_MSG: 'The deletion of a line from the first note is not reversible, are you sure you want to continue?',
      DELETE_CAUSE_MSG: 'The deletion of a line from the first note cause is not reversible, are you sure you want to continue?',
      TOTAL_INCOME: 'TOTAL INCOME',
      TOTAL_OUTLAY: 'TOTAL OUTLAY',
      BALANCE_FIRST_NOTE: 'BALANCE FIRST NOTE',
    },
    SATISPAY: {
      SATISPAY_CONFIG: 'Satispay configuration',
      INVALID_RSA_KEY: 'Invalid RSA Key',
      TOKEN_ALREADY_PAIRED: 'Token already paired',
      DEVICE_TOKEN_NOT_FOUND: 'Device token not found',
      GENERATE: 'Generate',
      TEST_AUTHENTICATION: 'Test authentication',
      RESET_CONFIGURATION: 'Reset configuration',
      ARE_YOU_SURE: 'Are you absolutely sure?',
      YOU_CANNOT_USE_SATISPAY: 'If you proceed, you will no longer be able to use <b>Satispay</b> as a payment method until you proceed ' +
        'with a new configuration.',
      PLEASE_TYPE: ` Enter the following code <b>{{data}}</b> to confirm the cancellation`
    },
    PROMO_FORM: {
      ID: 'ID',
      NO_PROMO_FOUND: 'No promotion found',
      ADD_PROMO: 'Add promotion',
      SMS: 'Sms',
      MAIL: 'E-mail',
      TYPE: 'What do you want to send?',
      MODEL: 'Promotion model',
      OBJECT: 'Object',
      MESSAGE: 'Message',
      SENT_DATE_HOUR: 'Date and time sent',
      SENT_HOUR: 'Now I send',
      RECEIVER: 'Number of recipients',
      FILTER_RECEIVERS: 'Recipient filter',
      CREATION_DATE: 'Creation date',
      PROMO_DETAILS: 'Promotion detail',
      LAST_MONTH: 'Last month',
      LAST_THREE_MONTHS: 'Last three months',
      LAST_SIX_MONTHS: 'Last six months',
      LAST_NINE_MONTHS: 'Last nine months',
      LAST_YEAR: 'Last year',
      STATUS: 'Status',
      EDIT_TEMPLATE: 'Edit template',
    },
    MOBILE_APP_CONFIG: {
      TOLERANCE_HOURS: 'Booking cancel toleration (in hours)',
      UPDATED_SUCCESSFULLY: 'Mobile app configuration updated successfully',
      NOTIFICATION_REMINDER_HOURS: 'Notification reminder (in hours)',
      QR_CODE: 'QR Code',
      DISABLE_RDV: 'Deactivate making an appointment'
    },
    QUICK_SIDEBAR: {
      SUBSCRIBE_TO_PUSH_NOTIFICATIONS: 'Subscribe to push notifications',
      UNSUBSCRIBE_FROM_PUSH_NOTIFICATIONS: 'Unsubscribe from push notifications',
      NO_NOTIFICATION: 'No notification present',
    },
    PROMO_EDITOR: {
      NAME: 'Name',
      DESCRIPTION: 'Description',
      TEMPLATE_DETAILS: 'Template details',
      SELECT_TEMPLATE: 'Select template',
      NO_TEMPLATE_FOUND: 'No template found',
      DELETE_MSG: 'You are going to delete this template! Are you sure?',
      TEMPLATE_USED: 'This template cannot be deleted, because it is used',
      TEMPLATE_SAVED: 'The template has been saved successfully',
    },
    PROMO_SOCIAL: {
      STATUS: 'Status',
      FAILED: 'Failed',
      PENDING: 'Pending',
      SENT: 'Sent',
      CREATED_AT: 'Created at',
      NO_POST_FOUND: 'No post found',
    },
    COMP_FILTER: {
      TODAY: 'Today',
      YESTERDAY: 'Yesterday',
      LAST_7_DAYS: 'Last 7 days',
      LAST_30_DAYS: 'Last 30 days',
      THIS_MONTH: 'This month',
      LAST_MONTH: 'Last month',
      LAST_3_MONTHS: 'Last 3 months',
      LAST_6_MONTHS: 'Last 6 months',
      CURRENT_YEAR: 'Current year',
      LAST_YEAR: 'Last year',
    },
  }
};

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {NavigationComponent} from './navigation.component';
import {SharedModule} from '../../shared/shared.module';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyButtonModule} from '@angular/material/legacy-button';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    SharedModule,
    MatIconModule,
    MatLegacyButtonModule
  ],
    declarations: [NavigationComponent],
    exports: [NavigationComponent]
})
export class NavigationModule { }

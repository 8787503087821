import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {   MAT_DIALOG_DATA } from '@angular/material/dialog';
import {   MatDialog,   MatDialogRef } from '@angular/material/dialog';
import {   MatSnackBar } from '@angular/material/snack-bar';
import {RechargeFormComponent} from './recharge-form/recharge-form.component';
import {FidelityService} from '../../../../../shared/services/fidelity.service';
import {ProductMgmService} from '../../../../../shared/services/product-mgm.service';
import {TranslateService} from '@ngx-translate/core';
import {SweetAlertService} from '../../../../../shared/services/sweet-alert.service';
import {ReloadCard} from '../../../../../shared/models/reload-card';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {FidelityCardType} from 'src/app/shared/enum/fidelity-card-type';
import {ThresholdFormComponent} from '../fidelity-point-form/threshold-form/threshold-form.component';

@Component({
  selector: 'app-fidelity-recharge-form',
  templateUrl: './fidelity-with-discount-form.component.html',
  styleUrls: ['./fidelity-with-discount-form.component.scss']
})
export class FidelityWithDiscountFormComponent implements OnInit {
  submitted = false;
  fidelityWithDiscountForm: UntypedFormGroup;
  private formBuilder = new UntypedFormBuilder();
  rechargesColumns = ['DATA_TABLE.NAME', 'DATA_TABLE.VALUE', 'DATA_TABLE.DISCOUNT'];
  rechargesList: ReloadCard[] = [];
  unsubscribe$ = new Subject();
  orderType: 'ASC' | 'DESC' = 'DESC';

  constructor(public dialogRef: MatDialogRef<FidelityWithDiscountFormComponent>, private cardService: FidelityService,
              private productService: ProductMgmService, private matDialog: MatDialog,
              private snackBar: MatSnackBar, private translate: TranslateService,
              @Inject(MAT_DIALOG_DATA) public data: any, private sweetAlertService: SweetAlertService) {
  }

  ngOnInit() {
    this.fidelityWithDiscountForm = this.formBuilder.group({
      id: new UntypedFormControl(),
      name: new UntypedFormControl(null, Validators.required),
      creationDate: new UntypedFormControl(new Date().toLocaleDateString()),
      discount: new UntypedFormControl(null, Validators.required),
      cardType: new UntypedFormControl(this.data.type),
      withReload: new UntypedFormControl(false),
      dueDate: new UntypedFormControl(),
    });

    if (this.data.editMode) {
      this.fidelityWithDiscountForm.patchValue(this.data.card);
      this.fidelityWithDiscountForm.get('creationDate').setValue(new Date(this.data.card.createdAt).toLocaleDateString());
      if (this.data.type === FidelityCardType.PREPAID_WITH_DISCOUNT) {
        this.getReloads();
      }
    }
  }

  getReloads() {
    this.cardService.getReloads(this.fidelityWithDiscountForm.get('id').value).subscribe(r => {
      this.rechargesList = r;
      if (r.length) {
        this.fidelityWithDiscountForm.get('discount').setValidators(null);
        this.fidelityWithDiscountForm.get('discount').disable();
      } else {
        this.fidelityWithDiscountForm.get('discount').setValidators(Validators.required);
        this.fidelityWithDiscountForm.get('discount').enable();
      }
      this.fidelityWithDiscountForm.get('discount').updateValueAndValidity();
    });
  }


  deleteRecharge($event: MouseEvent, reload) {
    $event.stopPropagation();
    $event.cancelBubble = true;
    this.sweetAlertService.warning(this.translate.instant('DIALOG.YOU_WANT_TO_DELETE') + reload.name).then(e => {
      if (e.value) {
        this.cardService.deleteReload(reload.id).subscribe(d => {
          if (d.status === 200) {
            this.getReloads();
          }
        });
      }
    });
  }
  editRecharge(reload: any) {
    const dialogRef = this.matDialog.open(RechargeFormComponent, {
      width: '900px',
      autoFocus: true,
      disableClose: true,
      data: {card: this.fidelityWithDiscountForm.getRawValue(), reload}
    });
    dialogRef.afterClosed().subscribe(d => {
      if (d) {
        this.fidelityWithDiscountForm.get('id').setValue(d);
        this.getReloads();
      }
    });
  }

  addNewRecharge() {
    if (!this.fidelityWithDiscountForm.get('discount').value) {
      this.openAddRecharge();
      return;
    }
    this.sweetAlertService.warning(this.translate.instant('FIDELITY_FORM.IGNORE_PRINCIPAL_DISCOUNT')).then(e => {
      if (e.value) {
        this.fidelityWithDiscountForm.get('discount').setValue(null);
        this.fidelityWithDiscountForm.get('discount').setValidators(null);
        this.fidelityWithDiscountForm.get('discount').updateValueAndValidity();
        this.openAddRecharge();
      }
    });
  }

  openAddRecharge() {
    this.fidelityWithDiscountForm.get('withReload').setValue(true);
    const dialogRef = this.matDialog.open(RechargeFormComponent, {
      width: '700px',
      autoFocus: true,
      disableClose: true,
      data: {card: this.fidelityWithDiscountForm.getRawValue()}
    });
    dialogRef.afterClosed().subscribe(d => {
      if (d) {
        this.fidelityWithDiscountForm.get('id').setValue(d);
        this.getReloads();
      }
    });
  }

  save() {
    this.submitted = true;
    if (!this.fidelityWithDiscountForm.valid) {
      return;
    }
    if (this.fidelityWithDiscountForm.get('discount').value === null) {
      this.fidelityWithDiscountForm.get('withReload').setValue(true);
    }
    let dateTo = null;
    if (this.fidelityWithDiscountForm.get('dueDate').value) {
      dateTo = new Date(this.fidelityWithDiscountForm.get('dueDate').value);
      dateTo.setHours(dateTo.getHours() + 20);
      this.fidelityWithDiscountForm.get('dueDate').setValue(dateTo);
    }
    if (this.fidelityWithDiscountForm.get('id').value) {
      this.cardService.update(this.fidelityWithDiscountForm.getRawValue()).subscribe(d => {
        this.snackBar.open(this.translate.instant('DIALOG.UPDATE_SUCCESS'), 'Ok', {duration: 5000, panelClass: 'white-snackbar'});
      }, (err) => {
        this.fidelityWithDiscountForm.get('name').enable();
        this.fidelityWithDiscountForm.get('discount').enable();
        this.snackBar.open(this.translate.instant('DIALOG.INTERNAL_SERVER_ERROR'), 'Ok', {duration: 5000});
      }, () => {
        this.dialogRef.close(true);
      });
    } else {
      this.cardService.create(this.fidelityWithDiscountForm.getRawValue()).subscribe(d => {
        this.snackBar.open(this.translate.instant('DIALOG.ADD_SUCCESS'), 'Ok', {duration: 5000, panelClass: 'white-snackbar'});
      }, (err) => {
        this.fidelityWithDiscountForm.get('name').enable();
        this.fidelityWithDiscountForm.get('discount').enable();
        this.snackBar.open(this.translate.instant('DIALOG.INTERNAL_SERVER_ERROR'), 'Ok', {duration: 5000});
      }, () => {
        this.dialogRef.close(true);
      });
    }
  }

  sortRows(c: string) {
    const sort = c.substr(11);
    if (this.orderType === 'ASC') {
      this.orderType = 'DESC';
    } else {
      this.orderType = 'ASC';
    }
    switch (sort) {
      case 'NAME':
        if (this.orderType === 'ASC') {
          this.rechargesList.sort((a, b) => a.name.localeCompare(b.name));
          return;
        }
        this.rechargesList.sort((a, b) => b.name.localeCompare(a.name));
        break;
      case 'VALUE':
        if (this.orderType === 'ASC') {
          this.rechargesList.sort((a, b) => a.value - b.value);
          return;
        }
        this.rechargesList.sort((a, b) => b.value - a.value);
        break;
      case 'DISCOUNT':
        if (this.orderType === 'ASC') {
          this.rechargesList.sort((a, b) => a.discount - b.discount);
          return;
        }
        this.rechargesList.sort((a, b) => b.discount - a.discount);
        break;
    }

  }

  confirmAndClose(): void {
    if (this.fidelityWithDiscountForm.dirty){
     this.sweetAlertService.showConfirmationAlert()
      .then((confirmation) => {
        if (confirmation) {
          this.dialogRef.close(null);
        }
      });}
  else {
  this.dialogRef.close(null);
}
  }
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.fidelityWithDiscountForm.dirty) {
      $event.returnValue = true;
    }
  }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subheader {
  display: flex;
  justify-content: space-between;
}

.mat-column-action {
  text-align: right;
}

.modal-header {
  padding: 0rem !important;
}

.card.card-fullheight {
  height: unset !important;
}

.category-color-preview {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #b42100;
}

.apply_to_all {
  height: 100%;
  width: 2rem;
  padding: 0;
}

/* Ripple effect */
.ripple-category-edit {
  border: none;
  border-radius: 0.3rem;
  background-position: center;
  transition: background 0s;
  -webkit-transition: background 0s;
  -moz-transition: background 0s;
  -o-transition: background 0s;
}

.ripple-category-edit:hover {
  border: none;
  cursor: pointer;
  box-shadow: none;
  outline: none;
}

.ripple-category-edit:active {
  background-color: white !important;
  background-size: 100%;
  transition: background 0.5s;
  -webkit-transition: background 0.5s;
  -moz-transition: background 0.5s;
  -o-transition: background 0.5s;
}`, "",{"version":3,"sources":["webpack://./src/app/content/pages/configurations/category-treatment/category-treatment.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;AACF;;AACA;EACE,iBAAA;AAEF;;AAEA;EACE,wBAAA;AACF;;AACA;EACE,wBAAA;AAEF;;AACA;EACE,YAAA;EACA,WAAA;EACA,kBAAA;EACA,mBAAA;AAEF;;AAAA;EACE,YAAA;EACA,WAAA;EACA,UAAA;AAGF;;AADA,kBAAA;AAEA;EACE,YAAA;EACA,qBAAA;EACA,2BAAA;EAEA,yBAAA;EACA,iCAAA;EACA,8BAAA;EACA,4BAAA;AAEF;;AACA;EACE,YAAA;EACA,eAAA;EACA,gBAAA;EACA,aAAA;AAEF;;AAAA;EACE,kCAAA;EACA,qBAAA;EACA,2BAAA;EACA,mCAAA;EACA,gCAAA;EACA,8BAAA;AAGF","sourcesContent":[".subheader {\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n.mat-column-action {\r\n  text-align: right;\r\n}\r\n\r\n\r\n.modal-header {\r\n  padding: 0rem !important;\r\n}\r\n.card.card-fullheight {\r\n  height: unset !important;\r\n}\r\n\r\n.category-color-preview{\r\n  height: 20px;\r\n  width: 20px;\r\n  border-radius: 50%;\r\n  background: #b42100;\r\n}\r\n.apply_to_all{\r\n  height: 100%;\r\n  width: 2rem;\r\n  padding: 0;\r\n}\r\n/* Ripple effect */\r\n\r\n.ripple-category-edit {\r\n  border: none;\r\n  border-radius: 0.3rem;\r\n  background-position: center;\r\n\r\n  transition: background 0s;\r\n  -webkit-transition: background 0s;\r\n  -moz-transition: background 0s;\r\n  -o-transition: background 0s;\r\n}\r\n\r\n.ripple-category-edit:hover {\r\n  border: none;\r\n  cursor: pointer;\r\n  box-shadow: none;\r\n  outline: none;\r\n}\r\n.ripple-category-edit:active {\r\n  background-color: white !important;\r\n  background-size: 100%;\r\n  transition: background 0.5s;\r\n  -webkit-transition: background 0.5s;\r\n  -moz-transition: background 0.5s;\r\n  -o-transition: background 0.5s;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-header {
  background-color: var(--header-color) !important;
}
.admin-header .navbar-brand {
  color: var(--header-font-color) !important;
}
.admin-header .navbar-brand:hover {
  color: var(--secondary-color) !important;
}

.nav-item .nav-link {
  color: var(--header-font-color) !important;
}

.nav-divider {
  height: 25px;
  width: 1px;
  background: white;
}

.group-name {
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 2px;
  -webkit-text-fill-color: var(--header-font-color) !important;
  -webkit-text-stroke-color: var(--header-font-color) !important;
  -webkit-text-stroke-width: 1px;
}

.navbar-light .navbar-toggler {
  color: white;
  border-color: white;
}

@media screen and (max-width: 990px) {
  .navbar-brand {
    font-size: 0.9rem;
  }
  .group-name {
    font-size: 0.8rem;
    font-weight: 400;
  }
  .nav-divider {
    display: none !important;
  }
  .navbar-nav {
    align-items: flex-start !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/administrative-content/layout/components/navbar/navbar.component.scss"],"names":[],"mappings":"AAAA;EACE,gDAAA;AACF;AAAE;EAEE,0CAAA;AACJ;AACE;EAEE,wCAAA;AAAJ;;AAGA;EAEE,0CAAA;AADF;;AAGA;EACE,YAAA;EACA,UAAA;EACA,iBAAA;AAAF;;AAGA;EACE,iBAAA;EACA,gBAAA;EACA,mBAAA;EACA,4DAAA;EACA,8DAAA;EACA,8BAAA;AAAF;;AAEA;EACE,YAAA;EACA,mBAAA;AACF;;AACA;EACE;IACE,iBAAA;EAEF;EAAA;IACE,iBAAA;IACA,gBAAA;EAEF;EAAA;IACE,wBAAA;EAEF;EAAA;IACE,kCAAA;EAEF;AACF","sourcesContent":[".admin-header {\r\n  background-color: var(--header-color) !important;\r\n  .navbar-brand {\r\n    //color: white;\r\n    color:var(--header-font-color) !important ;\r\n  }\r\n  .navbar-brand:hover {\r\n    //color: darken(white, 15%);\r\n    color:var(--secondary-color) !important ;\r\n  }\r\n}\r\n.nav-item .nav-link {\r\n  //color: white;\r\n  color:var(--header-font-color) !important ;\r\n}\r\n.nav-divider {\r\n  height: 25px;\r\n  width: 1px;\r\n  background: white;\r\n}\r\n\r\n.group-name {\r\n  font-size: 1.5rem;\r\n  font-weight: 500;\r\n  letter-spacing: 2px;\r\n  -webkit-text-fill-color: var(--header-font-color) !important;\r\n  -webkit-text-stroke-color: var(--header-font-color) !important;\r\n  -webkit-text-stroke-width: 1px;\r\n}\r\n.navbar-light .navbar-toggler {\r\n  color: white;\r\n  border-color: white;\r\n}\r\n@media screen and (max-width: 990px) {\r\n  .navbar-brand  {\r\n    font-size: .9rem;\r\n  }\r\n  .group-name {\r\n    font-size: .8rem;\r\n    font-weight: 400;\r\n  }\r\n  .nav-divider {\r\n    display: none !important;\r\n  }\r\n  .navbar-nav {\r\n    align-items: flex-start !important;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

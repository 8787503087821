import {Component, Inject, OnInit} from '@angular/core';
import {IvaCode} from '../../../../../shared/models/ivaCode';
import {LazyRequest} from '../../../../../shared/dto/lazy-request';
import {ConfigurationsService} from '../../../../../shared/services/configurations.service';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {DepartmentRtDto} from '../../../../../shared/dto/config-rt-dto';
import {RtService} from '../../../../../shared/services/rt.service';

@Component({
  selector: 'app-iva-rt-reparto-config',
  templateUrl: './iva-rt-reparto-config.component.html',
  styleUrls: ['./iva-rt-reparto-config.component.scss']
})
export class IvaRtRepartoConfigComponent implements OnInit {
  rows: IvaCode[] = [];
  totalRecords: number;
  pageSize = 10;
  page = 1;
  loading = true;
  firstCall = true;

  constructor(private configurationsService: ConfigurationsService, private rtService: RtService, @Inject(MAT_DIALOG_DATA) public data,
              public dialogRef: MatDialogRef<IvaRtRepartoConfigComponent>) {
  }

  ngOnInit() {
    this.configurationsService.getAllIvaCode(this.data.id).subscribe(data => {
      this.rows = data;
      console.log(this.rows);
    });
  }

  save() {
    console.log(this.rows);
    let conf = [];
    this.rows.forEach(u => {
      if (u.rtDepartmentConfigId) {
        conf = [...conf, {ivaCodeId: u.id, departmentRtId: u.rtDepartmentConfigId}];
      }
    });
    if (!conf.length) {
      this.dialogRef.close(null);
      return;
    }
    this.rtService.saveIvaRepartoConfig(conf).subscribe(r => {
      this.dialogRef.close(null);
    });
  }
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GroupConfiguration} from '../models/group-configuration';
import {ClientRangeConfig} from '../models/client-range-config';
import {ClientRanges} from '../models/client-ranges';
import {Product} from '../models/product';
import {Client} from '../models/client';
import {Treatment} from '../models/treatment';

@Injectable({
  providedIn: 'root'
})
export class GroupConfigurationService {
  GROUP_CONFIG_API = '/api/group-configuration';

  constructor(private http: HttpClient) {
  }

  update(request) {
    return this.http.post<void>(this.GROUP_CONFIG_API, request);
  }

  getCurrentGroupConfig() {
    return this.http.get<GroupConfiguration>(this.GROUP_CONFIG_API);
  }

  updateRange(request) {
    return this.http.post<void>(`${this.GROUP_CONFIG_API}/range`, request);
  }

  getRange() {
    return this.http.get<ClientRangeConfig>(`${this.GROUP_CONFIG_API}/range`);
  }

  range(clientId) {
    return this.http.get<ClientRanges>(`${this.GROUP_CONFIG_API}/get-client-ranges/${clientId}`);
  }

  exportCSV() {
    return this.http.get(`${this.GROUP_CONFIG_API}/export-csv`, {responseType: 'blob', observe: 'response'});
  }

  resetPoint(password) {
    return this.http.post(`${this.GROUP_CONFIG_API}/reset-point-card`, password);
  }

  hardResetPointCard(password) {
    return this.http.post(`${this.GROUP_CONFIG_API}/hard-reset-point-card`, password);
  }

  hardReset() {
    return this.http.get(`${this.GROUP_CONFIG_API}/hard-reset`);
  }

  downloadExcelModel(type) {
    return this.http.get(`${this.GROUP_CONFIG_API}/download-${type}-excel-model`, {responseType: 'arraybuffer'});
  }

  uploadFromExcel(type, data) {
    return this.http.post<{
      treatments: Treatment[];
      products: Product[], clients: Client[]
    }>(`${this.GROUP_CONFIG_API}/upload-${type}-from-excel`, data);
  }

  creatProducts(products) {
    return this.http.post(`${this.GROUP_CONFIG_API}/creat-products`, products);
  }

  creatClients(products) {
    return this.http.post(`${this.GROUP_CONFIG_API}/creat-clients`, products);
  }

  creatTreatments(treatments) {
    return this.http.post(`${this.GROUP_CONFIG_API}/creat-treatments`, treatments);
  }
}

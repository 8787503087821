// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subheader {
  display: flex;
  justify-content: space-between;
}

.card.card-fullheight {
  height: unset !important;
}

@media screen and (max-width: 970px) {
  .subheader {
    flex-direction: column;
    margin-bottom: 10px;
  }
  .subheader .action-btns {
    display: flex;
    justify-content: flex-end;
  }
  .table-container {
    margin-top: 20px;
    max-width: 100vw;
    overflow-x: scroll;
  }
  .table-container .mat-table {
    overflow: auto;
    width: 1123px;
  }
  .action-btns {
    text-align: right;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/content/pages/stock-and-movement/stock-management/stock-management.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;AACF;;AAEA;EACE,wBAAA;AACF;;AAGA;EACE;IACE,sBAAA;IACA,mBAAA;EAAF;EAEE;IACE,aAAA;IACA,yBAAA;EAAJ;EAGA;IACE,gBAAA;IACA,gBAAA;IACA,kBAAA;EADF;EAGE;IACE,cAAA;IACA,aAAA;EADJ;EAIA;IACE,iBAAA;EAFF;AACF","sourcesContent":[".subheader {\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n\r\n.card.card-fullheight {\r\n  height: unset !important;\r\n}\r\n\r\n\r\n@media screen and (max-width: 970px) {\r\n  .subheader {\r\n    flex-direction: column;\r\n    margin-bottom: 10px;\r\n\r\n    .action-btns {\r\n      display: flex;\r\n      justify-content: flex-end;\r\n    }\r\n  }\r\n  .table-container {\r\n    margin-top: 20px;\r\n    max-width: 100vw;\r\n    overflow-x: scroll;\r\n\r\n    .mat-table {\r\n      overflow: auto;\r\n      width: 1123px;\r\n    }\r\n  }\r\n  .action-btns {\r\n    text-align: right;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CenterMgmComponent} from './components/center-mgm/center-mgm.component';
import {RouterModule, Routes} from '@angular/router';
import {MatLegacyButtonModule} from '@angular/material/legacy-button';
import {MatMenuModule} from '@angular/material/menu';
import {MatDividerModule} from '@angular/material/divider';
import {OperatorMgmComponent} from './components/operator-mgm/operator-mgm.component';
import {MatLegacyDialog as MatDialog, MatLegacyDialogModule} from '@angular/material/legacy-dialog';
import {TranslateModule} from '@ngx-translate/core';
import {CenterMgmFormComponent} from './components/center-mgm/center-mgm-form/center-mgm-form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {SellPointsComponent} from './components/center-mgm/sell-points/sell-points.component';
import {MatIconModule} from '@angular/material/icon';
import {SharedModule} from '../../../shared/shared.module';
import {SellPointFormComponent} from './components/center-mgm/sell-points/sell-point-form/sell-point-form.component';
// @ts-ignore
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {DateTimeModule} from 'nxt-pick-datetime';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {
  OperatorMgmFormDialogComponent
} from './components/operator-mgm/operator-mgm-form-dialog/operator-mgm-form-dialog.component';
import {ColorPickerModule} from 'ngx-color-picker';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {
  FocusInvalidInputDirective
} from './components/operator-mgm/operator-mgm-form-dialog/focus-invalid-input.directive';
import {RoleMgmComponent} from './components/role-mgm/role-mgm.component';
import {RoleFormMgmComponent} from './components/role-mgm/role-form-mgm/role-form-mgm.component';
import {MatLegacyCheckboxModule} from '@angular/material/legacy-checkbox';
import {ConfigurationMgmComponent} from './components/configuration-mgm/configuration-mgm.component';
import {CurrencyMaskModule} from "ng2-currency-mask";
import {MatTooltipModule} from '@angular/material/tooltip';
import {NgbDropdownModule, NgbPaginationModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';

const routes: Routes = [
  {
    path: '',
    component: CenterMgmComponent,
    children: [
      {
        path: 'center/:centerId',
        component: SellPointsComponent
      }
    ]
  },
  {
    path: 'operators',
    component: OperatorMgmComponent
  },
  {
    path: 'roles',
    component: RoleMgmComponent
  },
  {
    path: 'configurations',
    component: ConfigurationMgmComponent
  }
];

@NgModule({
  declarations: [
    CenterMgmComponent,
    OperatorMgmComponent,
    CenterMgmFormComponent,
    SellPointsComponent,
    SellPointFormComponent,
    OperatorMgmFormDialogComponent,
    FocusInvalidInputDirective,
    RoleMgmComponent,
    RoleFormMgmComponent,
    ConfigurationMgmComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule.forChild(),
    ReactiveFormsModule,
    MatLegacyButtonModule,
    MatMenuModule,
    MatDividerModule,
    NgbDropdownModule,
    MatLegacyDialogModule,
    NgbPaginationModule,
    TranslateModule,
    NgSelectModule,
    MatIconModule,
    NgxMaterialTimepickerModule,
    DateTimeModule,
    MatProgressSpinnerModule,
    ColorPickerModule,
    MatDatepickerModule,
    MatDatepickerModule,
    MatLegacyCheckboxModule,
    FormsModule,
    CurrencyMaskModule,
    MatTooltipModule,
    NgbTooltipModule,
  ]
})
export class AdminModule {
}

import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {RoleService} from '../../../../../../shared/services/role.service';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {SweetAlertService} from '../../../../../../shared/services/sweet-alert.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-role-form-mgm',
  templateUrl: './role-form-mgm.component.html',
  styleUrls: ['./role-form-mgm.component.scss']
})
export class RoleFormMgmComponent implements OnInit {
  roleForm: UntypedFormGroup;
  submitted = false;
  disableSaveBtn = false;

  constructor(
    private roleService: RoleService,
    private matDialogRef: MatDialogRef<RoleFormMgmComponent>,
    private sweetAlertService: SweetAlertService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    this.roleForm = new UntypedFormGroup({
      id: new UntypedFormControl(),
      name: new UntypedFormControl(null, Validators.required),
      description: new UntypedFormControl(null),
      authorities: new UntypedFormControl()
    });
    if (this.data.editMode) {
      this.roleForm.patchValue(this.data.role);
    }
  }

  saveRole() {
    this.submitted = true;
    if (!this.roleForm.valid) {
      this.sweetAlertService.notification(this.translate.instant('DIALOG.INSERT_ALL_MANDATORY_INFORMATION')).then(e => {
      });
      return;
    }
    if (!this.data.editMode) {
      this.disableSaveBtn = true;
      this.roleService.addRole(this.roleForm.value).subscribe(d => {
        if (d.status === 200) {
          this.matDialogRef.close(true);
        } else {
          this.disableSaveBtn = false;
        }
      });
    } else {
      this.disableSaveBtn = true;
      this.roleService.editRole(this.roleForm.value).subscribe(d => {
        if (d.status === 200) {
          this.matDialogRef.close(true);
        } else {
          this.disableSaveBtn = false;
        }
      });
    }
  }

  confirmAndClose(): void {
    if (this.roleForm.dirty) {
       this.sweetAlertService.showConfirmationAlert()
        .then((confirmation) => {
          if (confirmation) {
            this.matDialogRef.close(null);
          }
        });}
    else{
      this.matDialogRef.close(null);
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.roleForm.dirty) {
      $event.returnValue = true;
    }
  }
}

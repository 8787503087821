import {Component, HostListener, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {SearchResponse} from '../../../../../../shared/dto/search-response';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {SweetAlertService} from '../../../../../../shared/services/sweet-alert.service';
import {TranslateService} from '@ngx-translate/core';
import {BreadcrumbService} from '../../../../../../core/services/breadcrumb.service';
import {Router} from '@angular/router';
import {LocalStorageService} from 'ngx-webstorage';
import {  MatSnackBar} from '@angular/material/snack-bar';
import {debounceTime} from 'rxjs/operators';
import {UpdateVersion, UpdateVersionPageRequest, UpdateVersionTranslation} from '../../../../../../shared/models/update-version';
import {LocalTranslate} from '../../../../../../shared/pipes/local-translate.pipes';
import {UpdateVersionService} from '../../../../../../shared/services/update-version.service';

@Component({
  selector: 'app-update-version-list',
  templateUrl: './update-version-list.component.html',
  styleUrls: ['./update-version-list.component.scss']
})
export class UpdateVersionListComponent implements OnInit {

  columns = [
    'DATA_TABLE.ID',
    'DATA_TABLE.TITLE',
    'DATA_TABLE.CREATED',
    'DATA_TABLE.UPDATED',
    'DATA_TABLE.ACTIVE'
  ];

  filterForm: UntypedFormGroup;
  showMoreFilters = false;

  request: UpdateVersionPageRequest = new UpdateVersionPageRequest();
  pageSizesValues = [5, 10, 15, 25, 50, 75, 100, 150];
  updateVersionResponse: SearchResponse<UpdateVersion>;

  addForm: UntypedFormGroup;
  modal: any;

  constructor(
    private matDialog: MatDialog,
    private sweetAlertService: SweetAlertService,
    private translateService: TranslateService,
    private breadcrumbService: BreadcrumbService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private translate: TranslateService,
    private matSnackBar: MatSnackBar,
    private localTranslate: LocalTranslate,
    private updateVersionService: UpdateVersionService
  ) {
    this.sendBreadCrumb();
  }

  ngOnInit() {
    this.initRequest();
    this.getUpdateVersions();
    this.initFilterForm();
  }

  sendBreadCrumb(): void {
    this.breadcrumbService.sendBreadcrumb(['SPECIAL_PAGE']);
  }

  initFilterForm() {
    this.filterForm = new UntypedFormGroup({
      textSearch: new UntypedFormControl(null),
      createdAt: new UntypedFormControl(null),
      updatedAt: new UntypedFormControl(null),
    });

    this.filterForm.get('textSearch').valueChanges.pipe(debounceTime(500)).subscribe(
      (text) => {
        this.request.page = 1;
        this.request.textSearch = text;
        this.getUpdateVersions();
      });

    this.filterForm.get('updatedAt').valueChanges.subscribe(
      (d) => {
        if (d) {
          this.request.updatedAt = d._i.year + '-' + this.getTwo(d._i.month + 1) + '-' + this.getTwo(d._i.date);
        } else {
          this.request.updatedAt = '';
        }
        this.request.page = 1;
        this.getUpdateVersions();
      });

    this.filterForm.get('createdAt').valueChanges.subscribe(
      (d) => {
        if (d) {
          this.request.createdAt = d._i.year + '-' + this.getTwo(d._i.month + 1) + '-' + this.getTwo(d._i.date);
        } else {
          this.request.createdAt = '';
        }
        this.request.page = 1;
        this.getUpdateVersions();
      });

  }

  initAddForm() {
    this.addForm = new UntypedFormGroup({
      title: new UntypedFormControl(null, [Validators.required])
    });
  }

  getTwo(nbr): string {
    return (nbr < 10) ? '0' + nbr : '' + nbr;
  }

  initRequest() {
    this.request = new UpdateVersionPageRequest();
    this.request.page = 1;
    this.request.pageSize = 10;
    this.request.textSearch = '';
  }

  getUpdateVersions() {
    this.updateVersionService.getLazyUpdateVersions(this.request).subscribe(
      (response) => {
        console.log('update versions response = ', response);
        this.updateVersionResponse = response;
      }
    );
  }

  changePageSize() {
    this.localStorageService.store('pageSize', this.request.pageSize);
    this.pageChange(1);
  }

  pageChange($event) {
    this.request.page = $event;
    this.getUpdateVersions();
  }

  resetFilter() {
    this.initRequest();
    this.initFilterForm();
    this.getUpdateVersions();
  }


  public onAdd(modal?) {
    return this.addUpdateVersion(modal);
  }

  addUpdateVersion(modal) {
    this.initAddForm();
    this.modal = this.matDialog.open(modal, {
      width: '400px',
      autoFocus: false,
      disableClose: true,
    });
    this.modal.afterClosed().subscribe((d) => {
      if (d === true) {
        const newUpdateVersion: UpdateVersion = new UpdateVersion();
        newUpdateVersion.title = this.addForm.get('title').value;
        // newUpdateVersion.transInfo.push(t);
        console.log('new upd v request = ', newUpdateVersion);
        this.updateVersionService.addUpdateVersion(newUpdateVersion).subscribe((response) => {
          this.getUpdateVersions();
          this.matSnackBar.open(this.translateService.instant('DIALOG.ADD_SUCCESS'), 'Ok', {duration: 5000});
        }, (error) => {
          console.log('add error = ', error);
          this.matSnackBar.open(this.translateService.instant('DIALOG.CANNOT_ADD'), 'Ok', {duration: 5000});
        });
      }
    });
  }

  editUpdateVersion(updateVersion: any) {
    console.log('updateVersion table click edit = ', updateVersion)
    this.router.navigate(['/admin/owner/update-version/details/', updateVersion.id]).then();
  }

  deleteUpdateVersion(updateVersion: UpdateVersion) {
    this.sweetAlertService
      .warning(this.translateService.instant('DIALOG.YOU_WANT_TO_DELETE')) //+ this.localTranslate.transform(updateVersion, 'title') + ' ?')
      .then(e => {
        if (e.value) {
          this.updateVersionService.deleteUpdateVersion(updateVersion.id).subscribe(
            (response) => {
              if (response.status === 200) {
                this.getUpdateVersions();
                this.matSnackBar.open(this.translateService.instant('DIALOG.DELETE_SUCCESS' ), 'Ok', {duration: 5000});
              }
            },
            (error) => {
              console.log('error = ', error);
              // this.matSnackBar.open(this.translateService.instant('DIALOG.CANNOT_DELETE')
              //   + ' ' + this.localTranslate.transform(updateVersion, 'title'), 'Ok', {duration: 5000});
            }
          );
        }
      });
  }

  changeUpdateVersionState(element: any) {
    let msg = element.isActive ? this.translate.instant('DIALOG.YOU_WANT_TO_HIDE')
      : this.translate.instant('DIALOG.YOU_WANT_TO_SHOW');
    this.sweetAlertService.warning(msg + ' ' +
      element.title)
      .then(res => {
        if (res.value) {
          this.updateVersionService.changeUpdateVersionState(element.id).subscribe(d => {
            element.isActive = !element.isActive;
            this.getUpdateVersions();
          });
        }
      });
  }
  confirmAndClose(): void {
    if (this.addForm.dirty) {
     this.sweetAlertService.showConfirmationAlert()
      .then((confirmation) => {
        if (confirmation) {
          this.modal.close(false);
        }
      });}
    else{
      this.modal.close(false);
    }
  }
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.addForm.dirty) {
      $event.returnValue = true;
    }
  }
}

import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'email-sent-dialog-component',
  template: `
      <div matDialogTitle>
          {{'REGISTER.THANKS_FOR_SIGNUP' | translate}} ✔
      </div>
      <div matDialogContent>
          <p>{{'REGISTER.REGISTER_SUCCESS' | translate}}</p>
      </div>
      <div matDialogActions align="center">
          <button mat-flat-button color="primary" [matDialogClose]="">Ok</button>
      </div>
  `
})

export class EmailSentDialogComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }
}

export class MappedPayment {
  public date: Date;
  public amount: number;
  public total: number;
  public paymentEntry: MappedPaymentEntry[];
  public paymentMethods: MappedPaymentMethod[];
  public lotteryCode?: string;
  public discountToPay?: number;
  public buonoMultiuso?: number; // UTILIZZO
  public reso?: {reset: string, number: string, date: string};
  public annullo?: {reset: string, number: string, date: string};

  public constructor() {
    this.paymentEntry = [];
    this.paymentMethods = [];
  }

}

export class MappedPaymentEntry {
  public type: PaymentEntryType;
  public description: string;
  public unitPricing: number;
  public RTDepartment: number;
  public discount?: number;
  public acconto?: MappedAcconto;
  public omaggio?: boolean;   // OMAGGIO
  public quantity: number;
  public total: number;
  public sameIVA?: boolean;
}

export class MappedPaymentMethod {
  type: MappedPaymentMethodType;
  amount: number;
  constructor() {}
}


export class MappedDiscount {
  public discount: number;
  public discountType: DiscountType;
  public adjustmentType: AdjustmentType;
  public constructor(discount: number, discountType: DiscountType, adjustmentType: AdjustmentType) {
    this.discount = discount;
    this.discountType = discountType;
    this.adjustmentType = adjustmentType;
  }
}

export class MappedAcconto {
  first: boolean;
  second: boolean
  accontoAmount: number;
}

/**************ENUM***************/
export enum PaymentEntryType {
  SERVICE = 'SERVICE',
  PRODUCT = 'PRODUCT',
  SUBSCRIPTION = 'SUBSCRIPTION'
}

enum DiscountType {
  PERCENT, VALUE
}

enum AdjustmentType {
  DISCOUNT, SURCHANGE
}

export enum MappedPaymentMethodType {
  CASH = 0, // CONTANTI
  CHEQUE = 1, // ASSEGNO
  CREDIT_CARD = 2, // CARTA ELETTRONICA
  TICKET = 3, // TICKET
  TICKET_WITH_NUMBER = 4,
  NO_PAID = 5, // NON RISCOSSO
  DISCOUNT_ON_PAYMENT = 6, // SCONTO A PAGARE
  BUONO_MULTIUSO = 7, // BUONO MULTIUSO
  NON_RISCOSSO_BENI = 8, // BUONO MONOUSO
  NON_RISCOSSO_SERVIZI = 9 //NON RISCOSSO SERVIZI
}






import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericFilterComponent } from './generic-filter.component';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {CompareFilterDateModule} from '../compare-filter-date/compare-filter-date.module';
import {CompareFilterDateComponent} from '../compare-filter-date/compare-filter-date.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgbTooltipModule, NgbTypeaheadModule} from "@ng-bootstrap/ng-bootstrap";
import {MatLegacyButtonModule} from "@angular/material/legacy-button";

@NgModule({
    declarations: [GenericFilterComponent],
    exports: [
        GenericFilterComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        CompareFilterDateModule,
        NgSelectModule,
        NgbTypeaheadModule,
        NgbTooltipModule,
        MatLegacyButtonModule,
    ]
})
export class GenericFilterModule { }

import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {ProductFormService} from './services/product-form.service';
import {TranslateService} from '@ngx-translate/core';
import {Product} from '../../../../shared/models/product';
import {ProductMgmService} from '../../../../shared/services/product-mgm.service';
import {CustomSnackBarComponent} from '../../../../shared/custom-snack-bar/custom-snack-bar.component';
import {   MatPaginator } from '@angular/material/paginator';
import {   MatSnackBar } from '@angular/material/snack-bar';
import {BreadcrumbService} from '../../../../core/services/breadcrumb.service';
import {debounceTime} from 'rxjs/operators';
import {SweetAlertService} from '../../../../shared/services/sweet-alert.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-product-management',
  templateUrl: './product-management.component.html',
  styleUrls: ['./product-management.component.scss']
})
export class ProductManagementComponent implements OnInit {

  columns = [
    'PRODUCT_FORM.CODE',
    'PRODUCT_FORM.DESCRIPTION',
    // 'PRODUCT_FORM.MIN_STOCK',
    // 'PRODUCT_FORM.STOCK_ALERT',
    'PRODUCT_FORM.PRICE',
  ];
  public page = 1;
  public totalRecords: number;
  public pageSize = 10;
  loading = true;
  firstCall = true;
  textSearch = null;
  rows: Product[] = [];
  orderType = 'DESC';
  searchFormControl = new UntypedFormControl(null);
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('productFormContainer', {read: ViewContainerRef,static: false}) productFormContainer: ViewContainerRef;
  activeDetailSaleFilterFormControl = new UntypedFormControl(false);

  constructor(private translate: TranslateService,
              public snackBar: MatSnackBar,
              private productService: ProductMgmService,
              private productFormService: ProductFormService,
              private breadcrumbService: BreadcrumbService,
              private sweetAlertService: SweetAlertService
  ) {
  }

  ngOnInit() {
    this.sendBreadCrumb();
    this.getLazyProduct({page: this.page, pageSize: this.pageSize});
    this.searchFormControl.valueChanges.pipe(debounceTime(500)).subscribe(s => {
      this.page = 1;
      this.textSearch = s;
      this.getLazyProduct({page: 1, pageSize: this.pageSize, textSearch: s});
    });
    this.activeDetailSaleFilterFormControl.valueChanges.pipe(debounceTime(800)).subscribe(s => {
      this.page = 1;
      this.getLazyProduct({page: 1, pageSize: this.pageSize, textSearch: this.searchFormControl.value});
    });
  }

  sendBreadCrumb(): void {
    this.breadcrumbService.sendBreadcrumb(['PRODUCT_AND_SERVICES', 'PRODUCTS']);
  }

  addNewProduct() {
    const formRef = this.productFormService.openForm(this.productFormContainer, {
      editMode : false
    });
    formRef.instance.onClose.subscribe( (d: any)  => {
      if (d) {
        this.getLazyProduct({page: this.page, pageSize: this.pageSize, textSearch: this.textSearch}, {
          text: `${d.description} `,
          actionIcon: 'save',
          actionMsg: this.translate.instant('DIALOG.ADD_SUCCESS')
        });
      }
    });
  }

  printTickets() {
    this.productService.getTicket().subscribe(data => {
      const downloadUrl = window.URL.createObjectURL(data.body);
      window.open(downloadUrl);
    });
  }

  getLazyProduct(request, snackBarConf?) {
    this.loading = true;
    request.activeDetailSale = !this.activeDetailSaleFilterFormControl.value;
    request.notActiveProduct = this.activeDetailSaleFilterFormControl.value;
    return this.productService.getLazyProductList(request).subscribe(data => {
      this.rows = data.data;
      this.loading = false;
      this.firstCall = false;
      this.totalRecords = data.totalRecords;
      if (snackBarConf) {
        this.showSnackBar(snackBarConf);
      }
    });
  }

  showSnackBar(data: any) {
    this.snackBar.openFromComponent(CustomSnackBarComponent, {
      data,
      duration: 5000,
      panelClass: 'white-snackbar'
    });
  }

  editProduct(data, i) {
    const formRef = this.productFormService.openForm(this.productFormContainer,
      {
        product: data,
        editMode: true
      });
    formRef.instance.onClose.subscribe((d: any)  => {
      if (d) {
        this.getLazyProduct({page: this.page, pageSize: this.pageSize, textSearch: this.textSearch}, {
          text: `${d.description} `,
          actionIcon: 'save',
          actionMsg: this.translate.instant('DIALOG.UPDATE_SUCCESS')
        });
      }
    });
  }

  pageChange(page: number) {
    this.page = page;
    this.getLazyProduct({page, pageSize: this.pageSize, textSearch: this.textSearch});
  }


  delete($event, el: Product): void {
    $event.cancelBubble = true;
    $event.stopPropagation();
    this.sweetAlertService.warning(this.translate.instant('DIALOG.YOU_WANT_TO_DELETE') + ' ' + el.description)
      .then(res => {
        if (res.value) {
          this.productService.delete(el.id).subscribe(r => {
            this.showSnackBar({
              text: `${el.description}`,
              actionIcon: 'delete',
              actionMsg: this.translate.instant('DIALOG.DELETE_SUCCESS')
            });
            this.getLazyProduct({page: this.page, pageSize: this.pageSize, textSearch: this.textSearch});
          }, err => {
            if (err.status === 500) {
              this.showSnackBar({
                text: `${el.description}`,
                actionIcon: 'failed',
                actionMsg: this.translate.instant('DIALOG.CANNOT_DELETE')
              });
            }
          });
        }
      });
  }


  sortRows(c: string) {
    const sort = c.substr(13);
    if (this.orderType === 'ASC') {
      this.orderType = 'DESC';
    } else {
      this.orderType = 'ASC';
    }
    switch (sort) {
      case 'ID':
        this.getLazyProduct({
          page: this.page, pageSize: this.pageSize, textSearch: this.textSearch, sort: {
            attribute: 'id',
            direction: this.orderType
          }
        });
        break;
      case 'BAR_CODE':
        this.getLazyProduct({
          page: this.page, pageSize: this.pageSize, textSearch: this.textSearch, sort: {
            attribute: 'barCode',
            direction: this.orderType
          }
        });
        break;
      case 'DESCRIPTION':
        this.getLazyProduct({
          page: this.page, pageSize: this.pageSize, textSearch: this.textSearch, sort: {
            attribute: 'description',
            direction: this.orderType
          }
        });
        break;
      case 'PRICE':
        this.getLazyProduct({
          page: this.page, pageSize: this.pageSize, textSearch: this.textSearch, sort: {
            attribute: 'price',
            direction: this.orderType
          }
        });
        break;
      case 'CODE':
        this.getLazyProduct({
          page: this.page, pageSize: this.pageSize, textSearch: this.textSearch, sort: {
            attribute: 'prodCode',
            direction: this.orderType
          }
        });
        break;
    }
  }

  enableDisableProduct($event: MouseEvent, product: Product) {
    $event.cancelBubble = true;
    $event.stopPropagation();
    let msg = '';
    if (product.activeDetailSale) {
      msg = this.translate.instant('DIALOG.YOU_WANT_TO_DISABLE');
    } else {
      msg = this.translate.instant('DIALOG.YOU_WANT_TO_ENABLE');
    }
    this.sweetAlertService.warning(msg + ' ' + product.description)
      .then(res => {
        if (res.value) {
          this.productService.enableDisableProduct(product.id).subscribe(d => {
            if (d.status === 200) {
              product.activeDetailSale = !product.activeDetailSale;
              this.getLazyProduct({page: this.page, pageSize: this.pageSize, textSearch: this.textSearch}, {
                text: `${product.description}`,
                actionIcon: 'check',
                actionMsg: this.translate.instant('DIALOG.UPDATE_SUCCESS')
              });
            }
          });
        }
      }, err => {
      });
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {ChangePasswordComponent} from './change-password.component';
import {MatCardModule} from '@angular/material/card';
import {MatLegacyButtonModule} from '@angular/material/legacy-button';
import {TranslateModule} from '@ngx-translate/core';
import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';

const routes: Routes = [
  {path: ':token', component: ChangePasswordComponent}
];

@NgModule({
  declarations: [
    ChangePasswordComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatCardModule,
    MatLegacyButtonModule,
    TranslateModule,
    NgbPopoverModule,
    ReactiveFormsModule,
    MatIconModule
  ]
})
export class ChangePasswordModule {
}

import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {WeekPlanning, YearPlanning} from '../models/week-planning-template';
import {SearchResponse} from '../dto/search-response';
import {CheckEditPlanningResponse} from '../models/check-edit-planning-response';

@Injectable()
export class YearPlanningService {
  YEAR_PLANNING_API = environment.api + '/year-plannings';

  constructor(private http: HttpClient) {
  }


  addNewPlanning(planning) {
    return this.http.post<YearPlanning>(`${this.YEAR_PLANNING_API}`, planning);
  }


  editYearPlanning(request) {
    return this.http.put<YearPlanning>(`${this.YEAR_PLANNING_API}`, request);
  }

  lazyYearPlanning(request) {
    return this.http.post<SearchResponse<YearPlanning>>(`${this.YEAR_PLANNING_API}/filter`, request);
  }

  getAllYearPlanning() {
    return this.http.get<YearPlanning[]>(`${this.YEAR_PLANNING_API}/find-all`);
  }

  deleteYearPlanning(id: number) {
    return this.http.delete(`${this.YEAR_PLANNING_API}/${id}`, {observe: 'response'});
  }

  getPlanningByCabinId(cabinId) {
    return this.http.get<YearPlanning[]>(`${this.YEAR_PLANNING_API}/find-by-cabin/` + cabinId);
  }

  getPlanningByOperatorAndSellPoint(operatorId) {
    return this.http.get<YearPlanning[]>(`${this.YEAR_PLANNING_API}/find-by-operator-and-sellPoint/${operatorId}`);
  }


  getPlanningById(planningId: number) {
    return this.http.get<YearPlanning>(`${this.YEAR_PLANNING_API}/find-by-id/` + planningId);
  }


  getWeeksByPlanning(planningId: number) {
    return this.http.get<WeekPlanning[]>(`${this.YEAR_PLANNING_API}/get-weeks-by-planning/` + planningId);
  }

  saveWeekPlanning(weekPlanning) {
    return this.http.post<WeekPlanning>(`${this.YEAR_PLANNING_API}/save-week-planning`, weekPlanning);
  }

  checkCabinPositionAndYear(request) {
    return this.http.post<CheckEditPlanningResponse>(`${this.YEAR_PLANNING_API}/check-position-and-year`, request);
  }

  getLastCabinPosition(year) {
    return this.http.get<number>(`${this.YEAR_PLANNING_API}/get-last-cabin-position/${year}`);
  }

  getLastOperatorPosition(year) {
    return this.http.get<number>(`${this.YEAR_PLANNING_API}/get-last-operator-position/${year}`);
  }

  associateTemplates() {
    return this.http.get<number>(`${this.YEAR_PLANNING_API}/associate-templates`);
  }
}

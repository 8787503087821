// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .purchase-tab .mat-tab-labels {
  background-color: unset !important;
  justify-content: flex-start;
}
::ng-deep .purchase-tab .mat-tab-labels .mat-tab-label {
  height: 40px !important;
}

.purchase-card {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 66px);
}

::ng-deep .ng-dropdown-panel {
  width: auto !important;
  min-width: 250px;
}

.show-container {
  animation: showForm 0.3s ease-in;
}

@media screen and (max-width: 700px) {
  .form-container {
    width: 100%;
  }
  ::ng-deep .ng-dropdown-panel {
    width: 100% !important;
  }
}
label {
  font-size: 11px;
}

.section-title {
  font-weight: bold;
  font-size: 13px;
}

@media screen and (min-width: 1000px) {
  .marketing-privacy {
    transform: translateY(175%);
  }
}
@keyframes showForm {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
.spinner-container {
  position: relative;
}

.spinner {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.7;
}

.spinner i {
  font-size: 2em; /* Taille du spinner, ajustez selon vos besoins */
}`, "",{"version":3,"sources":["webpack://./src/app/content/pages/contacts/client-management/purchase-history/purchase-history.component.scss"],"names":[],"mappings":"AAAA;EACE,kCAAA;EACA,2BAAA;AACF;AACE;EACE,uBAAA;AACJ;;AAGA;EACE,gBAAA;EACA,kBAAA;EACA,0BAAA;AAAF;;AAGA;EACE,sBAAA;EACA,gBAAA;AAAF;;AAGA;EACE,gCAAA;AAAF;;AAIA;EACE;IACE,WAAA;EADF;EAGA;IACE,sBAAA;EADF;AACF;AAKA;EACE,eAAA;AAHF;;AAMA;EACE,iBAAA;EACA,eAAA;AAHF;;AAMA;EACE;IACE,2BAAA;EAHF;AACF;AAMA;EACE;IACE,2BAAA;EAJF;EAMA;IACE,wBAAA;EAJF;AACF;AAMA;EACE,kBAAA;AAJF;;AAMA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,YAAA;AAHF;;AAMA;EACE,cAAA,EAAA,iDAAA;AAHF","sourcesContent":["::ng-deep .purchase-tab .mat-tab-labels {\r\n  background-color: unset !important;\r\n  justify-content: flex-start;\r\n\r\n  .mat-tab-label {\r\n    height: 40px !important;\r\n  }\r\n}\r\n\r\n.purchase-card {\r\n  overflow-y: auto;\r\n  overflow-x: hidden;\r\n  height: calc(100vh - 66px);\r\n}\r\n\r\n::ng-deep .ng-dropdown-panel {\r\n  width: auto !important;\r\n  min-width: 250px;\r\n}\r\n\r\n.show-container {\r\n  animation: showForm .3s ease-in;\r\n}\r\n\r\n\r\n@media screen and (max-width: 700px) {\r\n  .form-container {\r\n    width: 100%;\r\n  }\r\n  ::ng-deep .ng-dropdown-panel {\r\n    width: 100% !important;\r\n  }\r\n}\r\n\r\n\r\nlabel {\r\n  font-size: 11px;\r\n}\r\n\r\n.section-title {\r\n  font-weight: bold;\r\n  font-size: 13px;\r\n}\r\n\r\n@media screen and (min-width: 1000px) {\r\n  .marketing-privacy {\r\n    transform: translateY(175%);\r\n  }\r\n}\r\n\r\n@keyframes showForm {\r\n  0% {\r\n    transform: translateX(100%);\r\n  }\r\n  100% {\r\n    transform: translateX(0);\r\n  }\r\n}\r\n.spinner-container {\r\n  position: relative;\r\n}\r\n.spinner {\r\n  position: absolute;\r\n  top: 85%;\r\n  left: 50%;\r\n  transform: translate(-50%, -50%);\r\n  opacity: 0.7;\r\n}\r\n\r\n.spinner i {\r\n  font-size: 2em; /* Taille du spinner, ajustez selon vos besoins */\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SmsConfigurationComponent} from './sms-configuration.component';
import {Routes, RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {TranslateModule} from '@ngx-translate/core';
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {DateTimeModule} from "nxt-pick-datetime";
import {MatIconModule} from "@angular/material/icon";

const routes: Routes = [
  {path: '', component: SmsConfigurationComponent}
];

@NgModule({
  declarations: [SmsConfigurationComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgSelectModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    NgbTooltipModule,
    DateTimeModule,
    MatIconModule
  ]
})
export class SmsConfigurationModule {
}

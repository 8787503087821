// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul.versions {
  list-style: none;
  padding-left: 0 !important;
}
ul.versions li {
  cursor: pointer;
  padding: 10px 5px;
  font-size: 14px;
  color: var(--primary-confirm);
}
ul.versions li:hover {
  background: #F7F9FC;
  font-weight: 700;
  font-size: 16px;
}
ul.versions li.selected {
  font-weight: 500;
  font-size: 16px;
}

::ng-deep figure img {
  width: -webkit-fill-available !important;
}

.versions-smallScreens {
  cursor: pointer;
  padding: 10px 5px;
  font-size: 20px;
  font-weight: 700;
  color: #0e2abd;
}`, "",{"version":3,"sources":["webpack://./src/app/content/pages/update-version/update-version-list/update-version-list.component.scss"],"names":[],"mappings":"AACA;EACE,gBAAA;EACA,0BAAA;AAAF;AAEE;EACE,eAAA;EACA,iBAAA;EACA,eAAA;EACA,6BAAA;AAAJ;AAEI;EACE,mBAAA;EACA,gBAAA;EACA,eAAA;AAAN;AAGI;EACE,gBAAA;EACA,eAAA;AADN;;AAKA;EACE,wCAAA;AAFF;;AAKA;EACE,eAAA;EACA,iBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AAFF","sourcesContent":["\r\nul.versions {\r\n  list-style: none;\r\n  padding-left: 0 !important;\r\n\r\n  li {\r\n    cursor: pointer;\r\n    padding: 10px 5px;\r\n    font-size: 14px;\r\n    color: var(--primary-confirm);\r\n\r\n    &:hover {\r\n      background: #F7F9FC;\r\n      font-weight: 700;\r\n      font-size: 16px;\r\n    }\r\n\r\n    &.selected {\r\n      font-weight: 500;\r\n      font-size: 16px;\r\n    }\r\n  }\r\n}\r\n::ng-deep figure img {\r\n  width: -webkit-fill-available !important;\r\n}\r\n\r\n.versions-smallScreens {\r\n  cursor: pointer;\r\n  padding: 10px 5px;\r\n  font-size: 20px;\r\n  font-weight: 700;\r\n  color: #0e2abd;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

export enum PaymentStatus {
  WAITING = 'WAITING',
  DONE = 'DONE',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  WAIT_FOR_RECEIPT = 'WAIT_FOR_RECEIPT',
  RECEIPT_NOT_SENT = 'RECEIPT_NOT_SENT',
  PRINT_FAILED = 'PRINT_FAILED',
  FAILED_RESET = 'FAILED_RESET',
  FAILED_TICKET = 'FAILED_TICKET',
  SIMULATION = 'SIMULATION',
  ADVANCE = 'ADVANCE',
  ADVANCE_COMPLETED = 'ADVANCE_COMPLETED',
  FAILED_ADVANCE_RESET = 'FAILED_ADVANCE_RESET',
  FAILED_ADVANCE_TICKET = 'FAILED_ADVANCE_TICKET',
  PRINT_ADVANCE_FAILED = 'PRINT_ADVANCE_FAILED'
}

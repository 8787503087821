import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {AuthRequest} from '../models/auth-request';
import {AuthResponse} from '../models/auth-response';
import {ChangePasswordRequest} from '../models/change-password-request';
import {UserService} from './user.service';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private $localStorage: LocalStorageService,
    private $sessionStorage: SessionStorageService,
    private router: Router
  ) {
  }

  getToken(): string {
    return this.$localStorage.retrieve('authenticationToken') || this.$sessionStorage.retrieve('authenticationToken') || '';
  }

  login(credentials: AuthRequest): Observable<void | AuthResponse> {
    return this.http
      .post<AuthResponse>(environment.api + '/auth', credentials)
      .pipe(
        map(response => this.authenticateSuccess(response, credentials.rememberMe))
      );
  }

  loginWithBarcode(barcode: string, rememberMe) {
    return this.http
      .post<AuthResponse>(environment.api + '/auth/barcode', barcode)
      .pipe(map(response => this.authenticateSuccess(response, rememberMe)));
  }

  chooseSellPoint(sellPointId: number, rememberMe: boolean, headers?: HttpHeaders) {
    return this.http.post<AuthResponse>(environment.api + '/auth/sell-point', sellPointId, {headers})
      .pipe(map(response => this.authenticateSuccess(response, rememberMe)));
  }

  private authenticateSuccess(response: AuthResponse, rememberMe: boolean): void | AuthResponse {
    if (response.status === 'DONE') {
      const jwt = response.token;
      if (rememberMe) {
        this.$localStorage.store('authenticationToken', jwt);
      } else {
        this.$sessionStorage.store('authenticationToken', jwt);
      }
    }
    return response;
  }

  changePassword(request: ChangePasswordRequest) {
    return this.http.post<boolean>(environment.api + '/auth/change-password', request)
      .pipe(map(response => response));
  }

  logout(): Observable<void> {
    return new Observable((observer) => {
      this.$localStorage.clear('authenticationToken');
      this.$sessionStorage.clear('authenticationToken');
      this.$sessionStorage.clear('user');
      this.$localStorage.clear('user');
      this.userService.clearAccountCache();
      const OneSignal = window['OneSignal'] || [];
      OneSignal.setSubscription(false);
      this.router.navigate(['/login']);
      observer.complete();
    });
  }

  grant(password): Observable<void | AuthResponse> {
    return this.http
      .post<AuthResponse>(environment.api + '/auth/grant', password)
      .pipe(map(response => this.authenticateSuccess(response, null)));
  }

  revoke(): Observable<void | AuthResponse> {
    return this.http
      .get<AuthResponse>(environment.api + '/auth/revoke')
      .pipe(map(response => this.authenticateSuccess(response, null)));
  }

  loginWithEmailKey(mailKey: string) {
    return this.http
      .get<AuthResponse>(environment.api + '/auth/use-mail-key/' + mailKey)
      .pipe(map(response => this.authenticateSuccess(response, false)));
  }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.separator-text {
  font-weight: 500;
  margin-bottom: 1em;
  padding: 0.5rem 0;
  font-size: 1rem;
  color: #747274;
  border-bottom: 1px solid #d0d0d0;
}

::ng-deep .dropdown {
  z-index: 1200 !important;
}

::ng-deep.prefix-ng-select .ng-dropdown-panel {
  width: 250px;
}`, "",{"version":3,"sources":["webpack://./src/app/administrative-content/pages/owner/components/group-mgm/group-form-modal/group-form-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,cAAA;EACA,gCAAA;AACF;;AACA;EACE,wBAAA;AAEF;;AAAA;EACE,YAAA;AAGF","sourcesContent":[".separator-text {\r\n  font-weight: 500;\r\n  margin-bottom: 1em;\r\n  padding: .5rem 0;\r\n  font-size: 1rem;\r\n  color: #747274;\r\n  border-bottom: 1px solid #d0d0d0;\r\n}\r\n::ng-deep .dropdown {\r\n  z-index: 1200 !important;\r\n}\r\n::ng-deep.prefix-ng-select .ng-dropdown-panel {\r\n  width: 250px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

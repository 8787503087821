// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .operator-ng-select.ng-dropdown-panel {
  z-index: 1500;
}`, "",{"version":3,"sources":["webpack://./src/app/content/pages/contacts/client-management/client-tint/add-tint/add-tint.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF","sourcesContent":["::ng-deep .operator-ng-select.ng-dropdown-panel {\r\n  z-index: 1500;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

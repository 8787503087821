import {Component, OnInit} from '@angular/core';
import {  MatDialog,   MatDialogRef} from '@angular/material/dialog';
import {PromotionMgmService} from '../../../../shared/services/promotion-mgm.service';
import {PromotionHistory} from '../../../../shared/models/promotion-history';
import {UntypedFormControl} from '@angular/forms';
import {SmsCampaignClient} from '../../../../shared/models/sms-campaign-client';
import {debounceTime} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-promo-sms-history',
  templateUrl: './promo-sms-history.component.html',
  styleUrls: ['./promo-sms-history.component.scss']
})
export class PromoSmsHistoryComponent implements OnInit {
  loadPromotions = true;

  columns2 = [
    'PROMO_FORM.OBJECT',
    'SMS.SENT_AT',
    'SMS.RECEIVER'];
  columns3 = [
    'SMS.STATUS'
  ];
  promotions: PromotionHistory[];
  dialogRef: MatDialogRef<any, any>;
  totalPromoRecords: number;
  pageSize = 10;
  page = 1;
  dialogSearchFormControl = new UntypedFormControl(null);
  dialog_columns = [
    'SMS.RECEIVER',
    'SMS.PHONE',
    'SMS.SENT_AT',
    'SMS.STATUS',
    'SMS.MAIL',
  ];
  receivers: SmsCampaignClient[] = [];
  receivs: SmsCampaignClient[] = [];


  constructor(private translate: TranslateService, private dialog: MatDialog,
              public datepipe: DatePipe, private promoService: PromotionMgmService) {
  }

  ngOnInit() {
    this.getHistory();
    this.dialogSearchFormControl.valueChanges.pipe(debounceTime(500)).subscribe(s => {
      this.receivs = this.receivers;
      this.receivs = this.receivs.filter(x => ((x.client.firstName.toLowerCase() + ' ' +
        x.client.lastName.toLowerCase()).includes(s.toLowerCase()))
        || (x.client.mobilePrefix + x.client.mobile).includes(s)
        || (this.translate.instant('SMS.' + (this.receivs[0].smsStatus))).toLowerCase().includes(s.toLowerCase())
        || (x.sendAt && (this.datepipe.transform(x.sendAt, 'dd/MM/yyyy HH:mm')).includes(s))
      );
    });
  }

  getHistory() {
    this.promoService.filterHistory({page: this.page, pageSize: this.pageSize}).subscribe(r => {
      this.loadPromotions = false;
      this.promotions = r.data;
      this.totalPromoRecords = r.totalRecords;
    });
  }

  getReceiversNumber(comp) {
    return comp.smsCampaignClients.filter(e => e.resent === false).length;
  }

  view(content, comp) {
    this.receivers = comp;
    this.receivs = comp;
    this.dialogRef = this.dialog.open(content, {
      width: '1200px',
      maxHeight: '90vh'
    });
    this.dialogRef.afterClosed().subscribe(d => {
      this.receivers = null;
    });
  }


  campaignPageChange(page: number) {
    this.page = page;
    this.getHistory();
  }
}

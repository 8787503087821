import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {SearchResponse} from '../dto/search-response';
import {ThresholdCard} from '../models/threshold-card';
import {ReloadCard} from '../models/reload-card';
import {FidelityCardConfiguration} from '../models/fidelity-card-configuration';
import {FidelityCard} from '../models/fidelity-card';
import {FidelityCardHistory} from '../models/fidelity-card-history';
import {FidelityCardHistoryDto} from '../dto/fidelity-card-history-dto';
import {FidelityRecharge} from '../models/fidelity-recharge';
import {PaymentFromReloadRequestDto} from '../dto/payment-from-reload-request-dto';
import {LazyRequest} from '../dto/lazy-request';

@Injectable()
export class FidelityService {
  FIDELITY_API = environment.api + '/fidelity-cards';

  constructor(private http: HttpClient) {
  }


  create(card) {
    return this.http.post<FidelityCardConfiguration>(`${this.FIDELITY_API}`, card);
  }

  update(card) {
    return this.http.put<FidelityCardConfiguration>(`${this.FIDELITY_API}`, card);
  }

  find(request: LazyRequest) {
    return this.http.post<SearchResponse<FidelityCardConfiguration>>(`${this.FIDELITY_API}/search`, request);
  }


  findAvailable(clientId) {
    return this.http.get<FidelityCardConfiguration[]>(`${this.FIDELITY_API}/get-available-client-cards/${clientId}`);
  }

  findActive(clientId) {
    return this.http.get<FidelityCard[]>(`${this.FIDELITY_API}/get-client-active-cards/${clientId}`);
  }

  delete(id) {
    return this.http.delete(`${this.FIDELITY_API}/${id}`, {observe: 'response'});
  }


  createReload(reload) {
    return this.http.post<any>(`${this.FIDELITY_API}/create-reload`, reload);
  }

  updateReload(reload) {
    return this.http.post<any>(`${this.FIDELITY_API}/update-reload`, reload);
  }

  getReloads(cardId) {
    return this.http.get<ReloadCard[]>(`${this.FIDELITY_API}/get-reload-list/${cardId}`);
  }

  getRechargeableCards(cardId) {
    return this.http.get<ReloadCard[]>(`${this.FIDELITY_API}/get-client-rechargeable-cards/${cardId}`);
  }

  deleteReload(id: any) {
    return this.http.delete(`${this.FIDELITY_API}/delete-reload/${id}`, {observe: 'response'});
  }


  createThreshold(threshold) {
    return this.http.post<any>(`${this.FIDELITY_API}/create-threshold`, threshold);
  }

  updateThreshold(threshold) {
    return this.http.post<any>(`${this.FIDELITY_API}/update-threshold`, threshold);
  }

  getThresholds(cardId) {
    return this.http.get<ThresholdCard[]>(`${this.FIDELITY_API}/get-thresholds-list/${cardId}`);
  }

  deleteThreshold(id: any) {
    return this.http.delete(`${this.FIDELITY_API}/delete-threshold/${id}`, {observe: 'response'});
  }

  associateToClient(fidelityCard) {
    return this.http.post<FidelityCard>(`${this.FIDELITY_API}/associate-to-client`, fidelityCard);
  }

  enableDisable(id) {
    return this.http.put(`${this.FIDELITY_API}/enable-disable-association/${id}`, {}, {observe: 'response'});
  }

  getClientCards(clientId) {
    return this.http.get<FidelityCard[]>(`${this.FIDELITY_API}/get-client-cards/${clientId}`);
  }

  getHistory(id) {
    return this.http.get<FidelityCardHistory[]>(`${this.FIDELITY_API}/get-history/${id}`);
  }

  effectChange(change: FidelityCardHistoryDto) {
    return this.http.post<FidelityCardHistory>(`${this.FIDELITY_API}/effect-movement`, change);
  }

  deleteAssociation(id) {
    return this.http.delete(`${this.FIDELITY_API}/delete-association/${id}`, {observe: 'response'});
  }

  getGifCards() {
    return this.http.get<FidelityCardConfiguration>(`${this.FIDELITY_API}/get-gift-cards`);
  }

  createFidelityRecharge(reload) {
    return this.http.post<any>(`${this.FIDELITY_API}/create-fidelity-recharge`, reload);
  }

  getFidelityRecharges(cardId) {
    return this.http.get<FidelityRecharge[]>(`${this.FIDELITY_API}/get-fidelity-recharges/${cardId}`);
  }

  payedWithCard(reloadCardToPay: PaymentFromReloadRequestDto[]) {
    return this.http.post<any>(`${this.FIDELITY_API}/pay-with-card`, reloadCardToPay);
  }

  getAvailableThresholds(cardId, clientId) {
    return this.http.get<ThresholdCard[]>(`${this.FIDELITY_API}/get-available-client-cards/${cardId}/${clientId}`);
  }

  countAvailableThresholds(cardId, clientId) {
    return this.http.get<number>(`${this.FIDELITY_API}/count-available-client-cards/${cardId}/${clientId}`);
  }

  totalCardSold(request: any) {
    return this.http.post<number>(`${this.FIDELITY_API}/total-card-sold`, request);
  }

  sumCardSold(request: any) {
    return this.http.post<number>(`${this.FIDELITY_API}/sum-card-sold`, request);
  }

  getFidelity(clientId: number, cardId: number) {
    return this.http.get<FidelityCard>(`${this.FIDELITY_API}/${cardId}/${clientId}`);
  }

}

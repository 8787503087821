import {Component, OnInit} from '@angular/core';
import {  MatDialog,   MatDialogRef} from '@angular/material/dialog';
import {FidelityWithDiscountFormComponent} from '../fidelity-with-discount-form/fidelity-with-discount-form.component';
import {FidelityPointFormComponent} from '../fidelity-point-form/fidelity-point-form.component';
import {FidelityCardType} from '../../../../../shared/enum/fidelity-card-type';
import {SweetAlertService} from "../../../../../shared/services/sweet-alert.service";

@Component({
  selector: 'app-fidelity-form',
  templateUrl: './fidelity-form.component.html',
  styleUrls: ['./fidelity-form.component.scss']
})
export class FidelityFormComponent implements OnInit {
  cardList: any[] = [
    {name: 'point', class: 'gradient-orange'},
    {name: 'discount', class: 'gradient-green'},
    {name: 'gift', class: 'gradient-red'}];

  constructor(public dialogRef: MatDialogRef<FidelityFormComponent>,
              private matDialog: MatDialog, private sweetAlertService: SweetAlertService,) {
  }

  ngOnInit() {
  }

  openCard(card: any) {
    if (card === 'point') {
      const dialog = this.matDialog.open(FidelityPointFormComponent, {
        width: '900px',
        autoFocus: true,
        disableClose: true,
        data: {editMode: false}
      });
      dialog.afterClosed().subscribe(d => {
        if (d) {
        }
      });
      return;
    }
    if (card === 'discount') {
      const dialogRef = this.matDialog.open(FidelityWithDiscountFormComponent, {
        width: '900px',
        autoFocus: true,
        disableClose: true,
        data: {editMode: false}
      });
      dialogRef.afterClosed().subscribe(d => {
        if (d) {
        }
      });
      return;
    }
  }

  openPointCard() {
    const dialog = this.matDialog.open(FidelityPointFormComponent, {
      width: '900px',
      autoFocus: true,
      disableClose: true,
      data: {editMode: false}
    });
    dialog.afterClosed().subscribe(d => {
      if (d) {
              this.dialogRef.close(true);
      }

    });
  }

  openDiscountCard() {
    const dialogRef = this.matDialog.open(FidelityWithDiscountFormComponent, {
      width: '900px',
      autoFocus: true,
      disableClose: true,
      data: {editMode: false, type: FidelityCardType.PREPAID_WITH_DISCOUNT}
    });
    dialogRef.afterClosed().subscribe(d => {
      if (d) {
        this.dialogRef.close(true);
      }
    });
  }

  openGiftCard() {
    const dialogRef = this.matDialog.open(FidelityWithDiscountFormComponent, {
      width: '900px',
      autoFocus: true,
      disableClose: true,
      data: {editMode: false, type: FidelityCardType.GIFT_CARD}
    });
    dialogRef.afterClosed().subscribe(d => {
      if (d) {
        this.dialogRef.close(true);
      }
    });
  }
}

import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {SequenceType} from '../enum/sequence-type.enum';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {EanType} from '../enum/EanType.enum';
import {Product} from '../models/product';
import {IvaCode} from '../models/ivaCode';
import {Barcode} from '../models/barcode';
import {Observable, Subject, Subscription} from 'rxjs';
import {Stock} from '../models/stock';
import {HttpClient} from '@angular/common/http';
import {ProductMgmService} from '../services/product-mgm.service';
import {SequenceService} from '../services/sequence.service';
import {ConfigurationsService} from '../services/configurations.service';
import {ProviderMgmService} from '../services/provider-mgm.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {BarCodeMgmService} from '../services/bar-code-mgm.service';
import {ProdCodeMgmService} from '../services/prod-code-mgm.service';
import {MovementMgmService} from '../services/movement-mgm.service';
import {SweetAlertService} from '../services/sweet-alert.service';
import {TranslateService} from '@ngx-translate/core';
import createPrefixedInputHandler from '../../content/pages/payment/create-prefixed-input-handler-config';
import {ProductUseType} from '../enum/product-use-type.enum';
import {CropImageComponent} from '../crop-image/crop-image.component';
import {BarCodeRequest} from '../models/barCodeRequest';
import {CategoryFormModalComponent} from '../../content/pages/configurations/category-product/category-form-modal.component';
import {SubCategoryFormModalComponent} from '../../content/pages/configurations/category-product/sub-category-form-modal.component';
import {LineaFormModalComponent} from '../../content/pages/configurations/linea/linea-form-modal.component';
import {ProductBarcodeType} from '../enum/product-barcode-type';
import {Client} from '../models/client';

const MAX_NUMBER = 2147483646;

@Component({
  selector: 'app-product-create',
  templateUrl: './product-create.component.html',
  styleUrls: ['./product-create.component.scss']
})
export class ProductCreateComponent implements OnInit, OnDestroy {
  @Input() editMode: boolean;
  @Input() product: Product;
  @Input() code: String;
  @Input() editEvents: Observable<void>;
  @Input() saveEvents: Observable<void>;
  @Output() saved = new EventEmitter<Product>();
  editEventsSubscription: Subscription;
  saveEventsSubscription: Subscription;
  appRef;
  dialogComponentRef;
  editClicked = false;
  productForm: UntypedFormGroup;
   submitted ;

  productPicture: string;
  currentSeq = '';

  IvaCodeList: IvaCode[] = [];
  IvaCodePage = 1;

  providersList: any[] = [];
  providersPage = 1;

  brandList: any[] = [];
  brandPage = 1;

  lineaList: any[] = [];
  lineaPage = 1;

  categoriesList: any[] = [];
  categoriesPage = 1;

  subCategoriesList: any[] = [];
  barcodeList: Barcode[] = [];
  subCategoriesPage = 1;

  categoryFormControl = new UntypedFormControl();
  rechargeFormControl = new UntypedFormControl();
  priceFormControl = new UntypedFormControl('', Validators.required);
  rechargePDVFormControl = new UntypedFormControl();
  pricePDVFormControl = new UntypedFormControl();
  purchasePriceFormControl = new UntypedFormControl('', Validators.required);
  activeDetailSaleFormControl = new UntypedFormControl(true);
  unsubscribe$ = new Subject();
  categoryIdLoading = false;
  barcodeLoading = false;
  preventValueChange = false;

  useTypes = [];
  units = [
    {name: this.translate.instant('Ml'), value: 'Ml'},
    {name: this.translate.instant('Gr'), value: 'Gr'},
  ];
  productDetails: Stock = new Stock();
  dialogRef: any;
  productDetailsForm: UntypedFormGroup;
  @Output() disableSave = new EventEmitter<boolean>();
  ticketNumber = 1;
  barCodeFormControl = new UntypedFormControl();
  prodCodeFormControl = new UntypedFormControl();
  validBarcode = false;
  validProdcode = false;
  uniqueBarcode = false;
  uniqueProdcode = false;
  printTicketSent = false;
  types = [];
  selectedType;
  printMode;
  private listener: any;
  private barcodeToAddList: Barcode[] = [];
  @Output() updated = new EventEmitter<boolean>() ;
  constructor(private http: HttpClient,
              private productService: ProductMgmService,
              private sequence: SequenceService,
              private configurationsService: ConfigurationsService,
              private providerService: ProviderMgmService,
              private matDialogClient: MatDialog,
              private barCodeService: BarCodeMgmService,
              private prodCodeService: ProdCodeMgmService,
              private movementService: MovementMgmService,
              private sweetAlertService: SweetAlertService,
              private translate: TranslateService) {
    this.initialiseListener();
  }

  ngOnInit() {
    this.editEventsSubscription = this.editEvents.subscribe(() => this.activateEdit());
    this.saveEventsSubscription = this.saveEvents.subscribe(() => this.save());
    this.translateUseTypes();
    this.productDetailsForm = new UntypedFormGroup({
      stock: new UntypedFormControl(),
      mediumCost: new UntypedFormControl(),
      lastCost: new UntypedFormControl(),
      lastPrice: new UntypedFormControl(),
    });
    this.productDetailsForm.disable();
    this.productForm = new UntypedFormGroup({
      id: new UntypedFormControl(),
      seq: new UntypedFormControl(),
      createdAt: new UntypedFormControl(new Date()),
      barCode: new UntypedFormControl(null),
      prodCode: new UntypedFormControl(null, Validators.required), // ALSO KNOWN AS PLU
      description: new UntypedFormControl(null, Validators.required),
      commercialDescription: new UntypedFormControl(),
      categoryId: new UntypedFormControl(null, Validators.required),
      subCategoryId: new UntypedFormControl(null),
      brandId: new UntypedFormControl(),
      lineaId: new UntypedFormControl(),
      providerId: new UntypedFormControl(),
      measureUnit: new UntypedFormControl(),
      quantity: new UntypedFormControl(),
      activeDetailSale: new UntypedFormControl(false),
      ivaCodeId: new UntypedFormControl(null, Validators.required),
      useType: new UntypedFormControl(null, Validators.required),
      minStock: new UntypedFormControl(null, Validators.min(0)),
      purchasePrice: new UntypedFormControl(0, Validators.min(0)),
      recharge: new UntypedFormControl(0),
      price: new UntypedFormControl(0, Validators.required),
      rechargePDV: new UntypedFormControl(null),
      pricePDV: new UntypedFormControl(null),
      note: new UntypedFormControl(),
      meduimCost: new UntypedFormControl(),
      lastLoadingCost: new UntypedFormControl(),
      lastSalePrice: new UntypedFormControl(),
      stock: new UntypedFormControl(),
      image: new UntypedFormControl(null)
    });
    /* Object.keys(this.productForm.controls).forEach(e => {
       if (this.productForm.get(e).getError('required')) {
         const el = document.querySelector('[formControlName="' + e + '"]');
         el.classList.add('required-field');
       }
     });*/
    if (this.editMode) {
      this.loadBarcodes();

      this.movementService.getStockHistoryByProduct(this.product.id).subscribe(r => {
        this.productDetails = r;
        this.productDetails.minStock = this.product.minStock;
        this.productDetailsForm.patchValue(this.productDetails);
      });
    }
    if (!this.editMode) {
      this.sequence.getCurrentSequence(SequenceType.PRODUCT).subscribe(res => {
        this.productForm.get('seq').setValue(res + 1);
        this.currentSeq = (this.productForm.get('seq').value + '').padStart(4, '0');
      });
    }

    this.productForm.get('seq').disable();
    this.productForm.get('subCategoryId').disable();
    this.productForm.get('createdAt').disable();
    this.productForm.get('meduimCost').disable();
    this.productForm.get('lastLoadingCost').disable();
    this.productForm.get('lastSalePrice').disable();
    this.productForm.get('stock').disable();
    this.rechargePDVFormControl.disable();
    this.getConfiguration();
    this.activeDetailSaleFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if ( this.activeDetailSaleFormControl.dirty) {
        this.updated.emit(true);
      }
      this.productForm.get('activeDetailSale').setValue(c);
      // this.changeValidators();
      if ( this.activeDetailSaleFormControl.dirty) {
        this.updated.emit(true);
      }
    });

    this.categoryFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
       if ( this.categoryFormControl.dirty) {
        this.updated.emit(true);
      }
       this.productForm.get('categoryId').setValue(c);

       if (c && !this.categoryFormControl.disabled) {
        this.productForm.get('subCategoryId').enable();
        this.getSubCategories(c);
      } else if (!c) {
        this.productForm.get('subCategoryId').setValue(null);
        this.productForm.get('subCategoryId').disable();
      }
    });


    this.purchasePriceFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if ( this.purchasePriceFormControl.dirty) {
        this.updated.emit(true);
      }
      this.productForm.get('purchasePrice').setValue(c);
      if (this.productForm.get('price').value !== 0) {
        this.rechargeFormControl.setValue(((this.productForm.get('price').value - this.productForm.get('purchasePrice').value) * 100)
          / this.productForm.get('purchasePrice').value, {
          onlySelf: true,
          emitEvent: false,
        });
      }
    });

    this.rechargeFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if ( this.rechargeFormControl.dirty) {
        this.updated.emit(true);
      }
      this.productForm.get('recharge').setValue(c);
      if (c === 0) {
        this.priceFormControl.setValue(this.productForm.get('purchasePrice').value, {
          onlySelf: true,
          emitEvent: false,
        });
        this.productForm.get('price').setValue(this.priceFormControl.value);
        return;
      }

      if (this.productForm.get('purchasePrice').value !== 0 && this.productForm.get('price').value === 0) {
        this.priceFormControl.setValue(this.productForm.get('purchasePrice').value + (this.productForm.get('purchasePrice').value *
          this.productForm.get('recharge').value / 100), {
          onlySelf: true,
          emitEvent: false,
        });
        this.productForm.get('price').setValue(this.priceFormControl.value);
        return;
      }

      if (this.productForm.get('price').value !== 0 && this.productForm.get('purchasePrice').value === 0) {
        this.purchasePriceFormControl.setValue(this.productForm.get('price').value /
          (1 + (this.productForm.get('recharge').value / 100)), {
          onlySelf: true,
          emitEvent: false,
        });
        this.productForm.get('purchasePrice').setValue(this.purchasePriceFormControl.value);
        return;
      }


      if (this.productForm.get('price').value !== 0 && this.productForm.get('purchasePrice').value !== 0) {
        this.priceFormControl.setValue(this.productForm.get('purchasePrice').value + (this.productForm.get('purchasePrice').value *
          this.productForm.get('recharge').value / 100), {
          onlySelf: true,
          emitEvent: false,
        });
        this.productForm.get('price').setValue(this.priceFormControl.value);
        return;
      }

    });

    this.priceFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if ( this.priceFormControl.dirty) {
        this.updated.emit(true);
      }
      this.productForm.get('price').setValue(c);
      if (this.productForm.get('purchasePrice').value !== 0) {
        this.rechargeFormControl.setValue(((this.productForm.get('price').value - this.productForm.get('purchasePrice').value) * 100)
          / this.productForm.get('purchasePrice').value, {
          onlySelf: true,
          emitEvent: false,
        });
      }

    });

    if (this.editMode) {
      console.log(this.product);
      this.preventValueChange = true;
      this.productForm.patchValue(this.product);
      this.productPicture = this.product.image;
      if (this.product.category !== null) {
        this.categoryFormControl.patchValue(this.product.category.id);
        this.getSubCategories(this.product.category.id);
      }
      this.barCodeFormControl.setValue('');
      this.prodCodeFormControl.setValue(this.productForm.get('prodCode').value);
      this.validProdcode = true;
      this.uniqueBarcode = false;
      this.uniqueProdcode = true;
      this.currentSeq = (this.productForm.get('seq').value + '').padStart(4, '0');
      this.productForm.get('purchasePrice').setValue(this.product.purchasePrice);
      this.productForm.get('recharge').setValue(this.product.recharge);
      this.productForm.get('price').setValue(this.product.price);
      this.productForm.get('rechargePDV').setValue(this.product.rechargePDV);
      this.productForm.get('pricePDV').setValue(this.product.pricePDV);
      this.purchasePriceFormControl.patchValue(this.product.purchasePrice);
      this.rechargeFormControl.patchValue(this.product.recharge);
      this.priceFormControl.patchValue(this.product.price);
      this.rechargePDVFormControl.patchValue(this.product.rechargePDV);
      this.pricePDVFormControl.patchValue(this.product.pricePDV);
      this.activeDetailSaleFormControl.patchValue(this.product.activeDetailSale, {emitEvent: false});
      this.activeDetailSaleFormControl.disable({emitEvent: false});
      if (this.product.subCategory != null) {
        this.productForm.get('subCategoryId').setValue(this.product.subCategory.id);
      }
      if (this.product.brand != null) {
        this.productForm.get('brandId').setValue(this.product.brand.id);
      }
      if (this.product.linea != null) {
        this.productForm.get('lineaId').setValue(this.product.linea.id);
      }
      if (this.product.provider != null) {
        this.productForm.get('providerId').setValue(this.product.provider.id);
      }
      if (this.product.ivaCode != null) {
        this.productForm.get('ivaCodeId').setValue(this.product.ivaCode.id);
      }

      this.productForm.disable();
      this.purchasePriceFormControl.disable();
      this.rechargeFormControl.disable();
      this.priceFormControl.disable();
      this.rechargePDVFormControl.disable();
      this.pricePDVFormControl.disable();
      this.categoryFormControl.disable();
    }
    this.productForm.get('useType').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      this.changeValidators();
    });
    this.productForm.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if ( this.productForm.dirty) {
        this.updated.emit(true);
      }});
    this.barCodeFormControl.valueChanges.pipe(debounceTime(500)).subscribe(c => {
      if ( this.barCodeFormControl.dirty) {
        this.updated.emit(true);
      }
      if (c.length === 0) {
        this.productForm.get('barCode').setValue(c);
        return;
      }
      this.productForm.get('barCode').setValue(c);
      this.barCodeService.uniqueProductEAN113({
        id: this.productForm.get('id').value,
        code: this.productForm.get('barCode').value.toUpperCase(),
        type: EanType.PRODUCT
      }).subscribe(res => {
        if (!res) {
          this.uniqueBarcode = false;
          return;
        }
        this.prodCodeService.isUnique(this.productForm.get('barCode').value.toUpperCase()).subscribe(prodcodeUnique => {
          if (prodcodeUnique) {
            this.uniqueBarcode = true;
            return;
          }
          this.uniqueBarcode = false;
          return;
        });
      });
    });

    this.prodCodeFormControl.valueChanges.pipe(debounceTime(500)).subscribe(c => {
      if ( this.prodCodeFormControl.dirty) {
        this.updated.emit(true);
      }
      if (c.length === 0) {
        this.productForm.get('prodCode').setValue(c);
        this.validProdcode = false;
        return;
      }
      this.productForm.get('prodCode').setValue(c);
      this.validProdcode = true;
      this.prodCodeService.isUnique(this.productForm.get('prodCode').value.toUpperCase()).subscribe(res => {
        if (res) {
          this.barCodeService.uniqueProductEAN113({
            code: this.productForm.get('prodCode').value.toUpperCase(),
            type: EanType.PRODUCT
          }).subscribe(prodCodeUnivc => {
            if (prodCodeUnivc) {
              this.uniqueProdcode = true;
              return;
            } else {
              this.uniqueProdcode = false;
              return;
            }
          });
          return;
        }
        this.uniqueProdcode = false;
      });
    });

    if (!this.editMode) {
      if (this.code) {
        this.productForm.get('prodCode').setValue(this.code.toUpperCase());
        this.prodCodeFormControl.setValue(this.productForm.get('prodCode').value);
      }
    }
  }

  activateEdit() {
    console.log('***************************');
    if (this.editMode) {
      this.editClicked = true;
      this.productForm.enable();
      this.purchasePriceFormControl.enable();
      this.rechargeFormControl.enable();
      this.priceFormControl.enable();
      this.rechargePDVFormControl.enable();
      this.pricePDVFormControl.enable();
      this.categoryFormControl.enable();
      this.productForm.get('createdAt').disable();
      this.productForm.get('meduimCost').disable();
      this.productForm.get('lastLoadingCost').disable();
      this.productForm.get('lastSalePrice').disable();
      this.productForm.get('stock').disable();
      //  this.productForm.get('barCode').disable();
      this.activeDetailSaleFormControl.enable({emitEvent: false});
      this.preventValueChange = false;
    }
  }

  initialiseListener() {
    const handler = createPrefixedInputHandler()((c) => {
      if (c.length === 8 && c.toUpperCase().match('[A-Z0-9]*')) {
        this.productForm.get('barCode').setValue(c.toUpperCase());
      }
    });
    this.listener = (event: Event): void => handler(event as KeyboardEvent);
  }

  closeScanner() {
    document.getElementById('product-barcode').removeEventListener('keydown', null, null);

  }


  changeValidators() {
    // this.activeDetailSaleFormControl.setValue(false);
    if (ProductUseType.IN_SALE === this.productForm.get('useType').value) {
      // this.activeDetailSaleFormControl.setValue(true);
      this.productForm.get('measureUnit').clearValidators();
      this.productForm.get('quantity').clearValidators();
    } else {
      this.productForm.get('measureUnit').setValidators(Validators.required);
      this.productForm.get('quantity').setValidators(Validators.required);
      // if (ProductUseType.INTERNAL_USE_OR_IN_SALE === this.productForm.get('useType').value) {
      //   this.activeDetailSaleFormControl.setValue(true);
      // }
    }
    this.productForm.get('measureUnit').updateValueAndValidity();
    this.productForm.get('quantity').updateValueAndValidity();
  }

  // closeForm() {
  //   this.destroyForm();
  // }

  // destroyForm() {
  //   this.appRef.detachView(this.dialogComponentRef.hostView);
  //   this.dialogComponentRef.destroy();
  // }

  getSubCategories(c) {
    this.categoryIdLoading = true;
    this.configurationsService.getSubCategoriesByCategoryid(c).subscribe(d => {
      this.subCategoriesList = d;
      this.categoryIdLoading = false;
      if (!this.editMode || c !== this.product.category.id) {
        this.productForm.get('subCategoryId').setValue(null);
      }
      if (this.subCategoriesList.length === 0) {
        this.productForm.get('subCategoryId').disable();
      }
    });
  }

  //Get all barcodes relative to that product
  getBarcodes(c) {
    this.categoryIdLoading = true;
    this.barCodeService.findAllByProductIdAndCenterId(c.id).subscribe(d => {
      this.barcodeList = d;
      this.barcodeLoading = false;
      if (!this.editMode) {
        this.productForm.get('subCategoryId').setValue(null);
      }
      if (this.subCategoriesList.length === 0) {
        this.productForm.get('barcode').disable();
      }
    });
  }

  openChangeImageModal() {
    if (!this.editMode || this.editClicked) {
      const dialogRef = this.matDialogClient.open(CropImageComponent, {width: '60%'});
      dialogRef.afterClosed().subscribe(d => {
        if (d) {
          this.productPicture = d;
        }
      });
    }
  }

  generateProdCode() {
    if (this.editMode && !this.editClicked) {
      return;
    }
    this.prodCodeService.generateProdCode().subscribe(r => {
      this.productForm.get('prodCode').setValue(r);
      this.validProdcode = true;
      this.uniqueProdcode = true;
    });
  }

  generateBarCode() {
    if (this.editMode && !this.editClicked) {
      return;
    }
    /*
    let uuid = UUID().replace(/-/g, '');
    uuid = uuid.slice(2, 11);
    uuid = uuid.toUpperCase() + this.currentSeq;
    this.productForm.get('barCode').setValue(uuid);*/

    const barCodeRequest: BarCodeRequest = {
      id: this.productForm.get('id').value,
      type: EanType.PRODUCT
    };

    this.barCodeService.generateEAN13(barCodeRequest).subscribe(r => {
      this.productForm.get('barCode').setValue(r);
      this.barCodeService.uniqueProductEAN113({
        id: this.productForm.get('id').value,
        code: this.productForm.get('barCode').value,
        type: EanType.PRODUCT
      }).subscribe(res => {
        this.validBarcode = true;
        this.uniqueBarcode = true;

      });
    });
  }


  getConfiguration() {

    this.configurationsService.lazyIvaCode({page: this.IvaCodePage, pageSize: MAX_NUMBER}).subscribe(d => {
      this.IvaCodeList = [...this.IvaCodeList, ...d.data];
      this.IvaCodePage++;
    });

    this.providerService.getLazyProviderList({page: this.providersPage, pageSize: MAX_NUMBER, visible: true}).subscribe(d => {
      this.providersList = [...this.providersList, ...d.data];
      this.providersPage++;
    });

    this.configurationsService.getLazyBrands({page: this.brandPage, pageSize: MAX_NUMBER}).subscribe(d => {
      this.brandList = [...this.brandList, ...d.data];
      this.brandPage++;
    });

    this.configurationsService.getLazyLineas({page: this.lineaPage, pageSize: MAX_NUMBER}).subscribe(d => {
      this.lineaList = [...this.lineaList, ...d.data];
      this.lineaPage++;
    });

    this.configurationsService.getLazyCategories({page: this.categoriesPage, pageSize: MAX_NUMBER, type: 'PRODUCT'}).subscribe(d => {
      this.categoriesList = [...this.categoriesList, ...d.data];
      this.categoriesPage++;
    });


  }

  getConfigurationsByType(confType: string) {
    switch (confType) {
      case 'TAX': {
        this.configurationsService.lazyIvaCode({page: this.IvaCodePage, pageSize: MAX_NUMBER}).subscribe(d => {
          this.IvaCodeList = [...this.IvaCodeList, ...d.data];
          this.IvaCodePage++;
        });
        break;
      }
      case 'PROVIDER': {
        this.providerService.getLazyProviderList({page: this.providersPage, pageSize: MAX_NUMBER, visible: true}).subscribe(d => {
          this.providersList = [...this.providersList, ...d.data];
          this.providersPage++;
        });
        break;
      }

      case 'BRAND': {
        this.configurationsService.getLazyBrands({page: this.brandPage, pageSize: MAX_NUMBER}).subscribe(d => {
          this.brandList = [...this.brandList, ...d.data];
          this.brandPage++;
        });
        break;
      }

      case 'LINEA': {
        this.configurationsService.getLazyLineas({page: this.lineaPage, pageSize: MAX_NUMBER}).subscribe(d => {
          this.lineaList = [...this.lineaList, ...d.data];
          this.lineaPage++;
        });
        break;
      }

      case 'CATEGORY': {
        this.configurationsService.getLazyCategories({page: this.categoriesPage, pageSize: MAX_NUMBER, type: 'PRODUCT'}).subscribe(d => {
          this.categoriesList = [...this.categoriesList, ...d.data];
          this.categoriesPage++;
        });
        break;
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(void 0);
    this.unsubscribe$.unsubscribe();
    this.editEventsSubscription.unsubscribe();
    this.saveEventsSubscription.unsubscribe();
  }


  addCategory() {
    if (this.productForm.controls.id.value && !this.editClicked) {
      return;
    }
    const dialogRef = this.matDialogClient.open(CategoryFormModalComponent, {
      width: '700px',
      autoFocus: true,
      disableClose: true,
      data: {editMode: false, fromProduct: true}
    });
    dialogRef.afterClosed().subscribe(d => {
      if (d) {
        this.categoriesList = [d, ...this.categoriesList];
        this.categoryFormControl.setValue(d.id, {emitEvent: false});
        this.productForm.get('categoryId').setValue(d.id);
        // this.productForm.get('subCategoryId').enable();
      }
    });
  }

  loadBarcodes() {
    this.barCodeService.findAllByProductIdAndCenterId(this.product.id).subscribe(d => {
      this.barcodeList = d;
    });
  }

  addSubCategory() {
    if (!this.categoryFormControl.value || (this.productForm.controls.id.value && !this.editClicked)) {
      return;
    }
    const dialogRef = this.matDialogClient.open(SubCategoryFormModalComponent, {
      width: '700px',
      autoFocus: true,
      disableClose: true,
      data: {
        editMode: false, fromProduct: true,
        categoryId: this.productForm.get('categoryId').value
      }
    });
    dialogRef.afterClosed().subscribe(d => {
      if (d) {
        this.subCategoriesList = [d, ...this.subCategoriesList];
        this.productForm.get('subCategoryId').setValue(d.id);
      }
    });
  }

  addLinea() {
    if (this.productForm.controls.id.value && !this.editClicked) {
      return;
    }
    const dialogRef = this.matDialogClient.open(LineaFormModalComponent, {
      width: '700px',
      autoFocus: true,
      disableClose: true,
      data: {
        editMode: false, fromProduct: true,
        categoryId: this.productForm.get('categoryId').value
      }
    });
    dialogRef.afterClosed().subscribe(d => {
      if (d) {
        this.lineaList = [d, ...this.lineaList];
        this.productForm.get('lineaId').setValue(d.id);
      }
    });
  }


  translateUseTypes() {
    this.useTypes = [
      {name: this.translate.instant('PRODUCT_FORM.INTERNAL_USE'), value: 'INTERNAL_USE'},
      {name: this.translate.instant('PRODUCT_FORM.IN_SALE'), value: 'IN_SALE'},
      {name: this.translate.instant('PRODUCT_FORM.INTERNAL_USE_OR_IN_SALE'), value: 'INTERNAL_USE_OR_IN_SALE'}
    ];
  }

  addNewBarcode(template) {
    if (this.productForm.controls.id.value && !this.editClicked) {
      return;
    }

    this.productForm.get('barCode').setValue('');
    this.barCodeFormControl.setValue('');

    this.validBarcode = false;
    this.uniqueBarcode = false;

    this.dialogRef = this.matDialogClient.open(template, {
      width: 'fit-content',
      autoFocus: true,
      disableClose: true,
    });
    document.getElementById('product-barcode').addEventListener('keydown', this.listener, {passive: true});

    this.dialogRef.afterClosed().subscribe(d => {
      if (d) {
        let barcode: Barcode = new Barcode();
        barcode.barcode = String(this.productForm.get('barCode').value);
        barcode.product = this.product;
        this.barcodeToAddList.push(barcode);
        this.barcodeList = [barcode, ...this.barcodeList];
        this.productForm.get('barCode').setValue('');
        this.barCodeFormControl.setValue('');

        this.validBarcode = false;
        this.uniqueBarcode = false;
        return;
      } else {
        this.productForm.get('barCode').setValue('');
        this.barCodeFormControl.setValue('');

        this.validBarcode = false;
        this.uniqueBarcode = false;
        return;
      }
    });
  }

  deleteBarcode(id: number) {
    if (this.productForm.controls.id.value && !this.editClicked) {
      return;
    }
    this.sweetAlertService.warning(this.translate.instant('DIALOG.YOU_WANT_TO_DELETE'), 'Si', 'No').then(res => {
      if (res.value) {
        this.barCodeService.deleteBarcode(id).subscribe(isDeleted => {
          this.sweetAlertService.success('Il barcode è stato correttamente eliminato');
          this.loadBarcodes();
          return;
        });
        return;
      }


    });

  }

  save() {
    this.submitted = true;
    if (!this.uniqueProdcode) {
      this.sweetAlertService.danger('Controllare il codice articolo');
      return;
    }
    this.productForm.get('activeDetailSale').setValue(this.activeDetailSaleFormControl.value);
    if (!this.productForm.valid || this.priceFormControl.invalid || this.purchasePriceFormControl.invalid) {
      this.sweetAlertService.notification(this.translate.instant('DIALOG.INSERT_ALL_MANDATORY_INFORMATION')).then(e => {
      });
      return;
    }
    this.productForm.get('image').setValue(this.productPicture);
    this.productForm.get('prodCode').setValue(String(this.productForm.get('prodCode').value).toUpperCase());
    const prodCode = this.productForm.get('prodCode').value + '';
    if (!prodCode || prodCode.length === 0) {
      return;
    }
    this.disableSave.emit(true);
    this.prodCodeService.isUnique(prodCode).subscribe(r => {
      if (r) {
        if (!this.editMode) {
          this.productService.addNewProduct(this.productForm.getRawValue()).subscribe(d => {
            this.barcodeToAddList.map(barcode => {
              this.productService.getProduct(d.id).subscribe(p => {
                barcode.product = p;
                this.barCodeService.addNewProductBarcode(barcode).subscribe(d => {
                });
              });
              /**/
            });
            this.productForm.get('id').setValue(d.id);
            this.saved.emit(d);
          }, error => this.disableSave.emit(false));
        } else {
          this.productService.editProduct(this.productForm.getRawValue()).subscribe(d => {
            this.barcodeToAddList.map(barcode => {
              this.barCodeService.addNewProductBarcode(barcode).subscribe(d => {
              });
            });
            this.productForm.get('id').setValue(d.id);
            this.saved.emit(d);
          }, error => this.disableSave.emit(false));
        }
      } else {
        if (!this.editMode) {
          this.productService.addNewProduct(this.productForm.getRawValue()).subscribe(d => {
            this.productForm.get('id').setValue(d.id);
            this.barcodeToAddList.map(barcode => {
              this.productService.getProduct(d.id).subscribe(p => {
                barcode.product = p;
                this.barCodeService.addNewProductBarcode(barcode).subscribe(d => {
                });
              });
              /**/
            });
            this.saved.emit(d);
          }, error => this.disableSave.emit(false));
        } else {
          this.productService.editProduct(this.productForm.getRawValue()).subscribe(d => {
            if (d === null) {
              this.sweetAlertService.danger(this.translate.instant('PRODUCT_FORM.EXISTING_PLU'));
              return;
            }
            this.productForm.get('id').setValue(d.id);
            this.barcodeToAddList.map(barcode => {
              this.barCodeService.addNewProductBarcode(barcode).subscribe(d => {
              });
            });
            this.saved.emit(d);
          }, error => this.disableSave.emit(false));
        }

      }

    });

    /*
    const barcode = this.productForm.get('barCode').value + '';
    this.disableSave = true;

    if (barcode.length !== 13 && barcode.length !== 12 && barcode.length !== 8) {
      this.productForm.get('barCode').markAsTouched();
      this.disableSave = false;
      return;
    }
    if (barcode.length === 13) {
      this.barCodeService.validateEAN13({
        code: this.productForm.get('barCode').value
      }).subscribe(res => {
        if (!res) {
          this.productForm.get('barCode').markAsTouched();
          this.disableSave = false;
          return;
        }
        this.checkUniqueAndSave();
      });
    } else {
      this.barCodeService.validateEAN({
        code: this.productForm.get('barCode').value
      }).subscribe(res => {
        if (!res) {
          this.productForm.get('barCode').markAsTouched();
          this.disableSave = false;
          return;
        }
        this.checkUniqueAndSave();
      });

    }
  }

  checkUniqueAndSave() {
    const prodCode = this.productForm.get('prodCode').value + '';
    if (!prodCode || prodCode.length === 0){
      return;
    }
    this.barCodeService.uniqueProductEAN113({
      id: this.productForm.get('id').value,
      code: this.productForm.get('barCode').value,
      type: EanType.PRODUCT
    }).subscribe(r1 => {
      if (!r1) {
        this.productForm.get('barCode').markAsTouched();
        this.disableSave = false;
        return;
      }

    });
  */
  }

}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-title {
  padding: 12px 0;
  font-size: 20px;
  font-weight: 600;
  color: #989898;
}`, "",{"version":3,"sources":["webpack://./src/app/content/pages/configurations/fidelity/fidelity-with-discount-form/fidelity-with-discount-form.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AACF","sourcesContent":[".card-title {\r\n  padding: 12px 0;\r\n  font-size: 20px;\r\n  font-weight: 600;\r\n  color: #989898;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

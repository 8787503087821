import {Component, ElementRef, HostListener, Inject, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {   MAT_DIALOG_DATA } from '@angular/material/dialog';
import {   MatDialog,   MatDialogRef } from '@angular/material/dialog';
import {   MatSnackBar } from '@angular/material/snack-bar';
import {BreadcrumbService} from '../../../../../core/services/breadcrumb.service';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {CabinService} from '../../../../../shared/services/cabin.service';
import {WorkTime} from '../../../../../shared/models/workTime';
import {CheckEditPlanningResponse, CheckPlanningError} from '../../../../../shared/models/check-edit-planning-response';
import {YearPlanningService} from '../../../../../shared/services/year-planning.service';
import {YearPlanningComponent} from '../../../../../shared/year-planning/year-planning.component';
import {SweetAlertService} from '../../../../../shared/services/sweet-alert.service';
import {TypePlanning} from '../../../../../shared/enum/type-planning';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {WeekPlanningService} from '../../../../../shared/services/week-planning.service';

@Component({
  selector: 'app-cabin-form-modal',
  templateUrl: './cabin-form-modal.component.html',
  styles: [`
      .time-input {
          width: 4rem;
          text-align: center;
          border: aliceblue;
      }

      .checkbox {
          display: inline-block
      }

      ::ng-deep .position--ng-select.ng-dropdown-panel {
          z-index: 1500;
      }

      @media (max-width: 767.98px) {
          button {
              padding: 0 8px;
          }

          /*.button-mob{
      width: 100%;
      margin-bottom: 6px;
    }*/
      }



  `]

})
export class CabinFormModalComponent implements OnInit {
  cabinForm: UntypedFormGroup;
  submitted = false;
  addedElement = false;
  addMultipleCheckbox = new UntypedFormControl(false);
  @ViewChild('descInput', { static: false }) descInput: ElementRef;
  @ViewChild('valueInput', { static: false }) valueInput: ElementRef;
  planningColor = '#642222';
  workTime: WorkTime = new WorkTime();
  unsubscribe$ = new Subject();
  rows: any;
  loading = false;
  positions = [];
  currentYear = new Date().getFullYear();
  generatingError: { week: number; month: number }[] = [];

  constructor(public dialogRef: MatDialogRef<CabinFormModalComponent>,
              private cabinService: CabinService,
              private yearPlanningService: YearPlanningService,
              private snackBar: MatSnackBar,
              private translate: TranslateService,
              private sweetAlertService: SweetAlertService,
              private matDialog: MatDialog,
              private breadcrumbService: BreadcrumbService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.cabinForm = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      cabinId: new UntypedFormControl(null),
      name: new UntypedFormControl(null, [Validators.required]),
      planningColor: new UntypedFormControl(this.planningColor, [Validators.required]),
      planningPosition: new UntypedFormControl(null, [Validators.required, Validators.min(1)]),
      year: new UntypedFormControl(this.currentYear,
        [Validators.required, Validators.min(this.data.planning ? this.data.planning.year : this.currentYear)]),
      type: new UntypedFormControl(TypePlanning.CABIN)
    });
    if (this.data.editMode) {
      this.cabinForm.get('name').setValue(this.data.cabin.name);
      this.cabinForm.get('cabinId').setValue(this.data.cabin.id);
      if (this.data.planning) {
        this.cabinForm.patchValue(this.data.planning);
        this.planningColor = this.data.planning.planningColor;
      }
    }
    if (this.data.generate) {
      this.generatingError = this.data.generatingError;
      this.cabinForm.get('name').setValue(this.data.cabin.name);
      this.cabinForm.get('cabinId').setValue(this.data.cabin.id);
      this.cabinForm.get('year').setValue(this.data.year.name);
      this.cabinForm.get('planningColor').setValue(this.data.color);
      this.cabinForm.get('planningPosition').setValue(this.data.pos);
      this.planningColor = this.data.color;
      // if (this.generatingError && this.generatingError.length) {
      //     this.sweetAlertService.notification(this.translate.instant('DIALOG.ATTENTION_INVALID_PLANNING'))
      // }
    }
    this.yearPlanningService.getLastCabinPosition(this.cabinForm.get('year').value).subscribe(r => {
      for (let i = 1; i <= r; i++) {
        this.positions = [...this.positions, i];
      }
      if (this.data.planning) {
        this.positions.splice(this.positions.length - 1, 1);
      } else {
        if (this.data.generate && this.positions.indexOf(this.data.pos) > -1) {
          this.cabinForm.get('planningPosition').setValue(this.data.pos);
        } else {
          this.cabinForm.get('planningPosition').setValue(r);
        }
      }
    });
    this.cabinForm.get('year').valueChanges.pipe(debounceTime(500), takeUntil(this.unsubscribe$)).subscribe(c => {
      if ((this.data.editMode === 0 || this.data.editMode === 2) && c) {
        this.positions = [];
        if (c < this.currentYear) {
          this.cabinForm.get('planningPosition').setValue(null);
          return;
        }
        this.yearPlanningService.getLastCabinPosition(c).subscribe(r => {
          for (let i = 1; i <= r; i++) {
            this.positions = [...this.positions, i];
          }
          this.cabinForm.get('planningPosition').setValue(r);
        });
      }
    });
  }

  openForPlanningDialog() {
    this.yearPlanningService.checkCabinPositionAndYear({
      id: this.cabinForm.get('cabinId').value,
      planningId: this.cabinForm.get('id').value,
      planningType: TypePlanning.CABIN,
      year: this.cabinForm.get('year').value,
      position: this.cabinForm.get('planningPosition').value,
    }).subscribe((r: CheckEditPlanningResponse) => {
      if (r.result === CheckPlanningError.YEAR) {
        this.sweetAlertService.notification(this.translate.instant('DIALOG.THIS_PLANNING_YEAR_ALREADY_EXIST')).then(e => {
        });
        return;
      } else {
        this.openYearPlanning();
      }
    }, err => {
    });
  }

  openYearPlanning() {
    this.cabinForm.controls.planningColor.setValue(this.planningColor);
    const dialogRef = this.matDialog.open(YearPlanningComponent, {
      width: '90%',
      autoFocus: true,
      maxHeight: '88vh',
      disableClose: true,
      data: {editMode: this.data.editMode, form: this.cabinForm.value, cabin: this.data.cabin, planning: this.data.planning}
    });
    dialogRef.afterClosed().subscribe(d => {
      if (d) {
        if (!this.addMultipleCheckbox.value) {
          this.dialogRef.close(true);
        } else {
          this.ngOnInit();
        }
      }
    });
  }

  updateCabin() {
    this.cabinForm.get('id').setValue(this.data.cabin.id);
    this.cabinService.edit(this.cabinForm.getRawValue()).subscribe(d => {
    }, (err) => {
      this.cabinForm.get('name').enable();
      this.snackBar.open(this.translate.instant('DIALOG.INTERNAL_SERVER_ERROR'), 'Ok', {duration: 5000});
    }, () => {
      this.dialogRef.close(true);
      this.snackBar.open(this.translate.instant('DIALOG.UPDATE_SUCCESS') + ' ' + this.data.cabin.name, 'Ok', {duration: 5000});
    });
  }

  genYearPlanning() {
    this.cabinForm.controls.planningColor.setValue(this.planningColor);
    const dialogRef = this.matDialog.open(YearPlanningComponent, {
      width: '90%',
      maxHeight: '88vh',
      autoFocus: true,
      disableClose: true,
      data: {
        form: this.cabinForm.value,
        type: TypePlanning.CABIN,
        planning: this.data.planning,
        year: this.data.year,
        cabin: this.data.cabin
      }
    });
    dialogRef.afterClosed().subscribe(d => {
      if (d) {
        this.dialogRef.close(true);

      }
    });
  }
  confirmAndClose(): void {
    if (this.cabinForm.dirty) {
     this.sweetAlertService.showConfirmationAlert()
      .then((confirmation) => {
        if (confirmation) {
          this.dialogRef.close(this.addedElement);
        }
      });}
    else{
      this.dialogRef.close(this.addedElement);
    }
  }
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.cabinForm.dirty) {
      $event.returnValue = true;
    }
  }
}

import {Component, OnInit} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {GroupFormModalComponent} from './group-form-modal/group-form-modal.component';
import {GroupWithSuperAdmins} from '../../../../../shared/models/group';
import {OwnerService} from '../../services/owner.service';
import {FeatureNode, TREE_DATA} from '../../../../../shared/const/authorities';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SuperAdminMgmFormComponent} from './super-admin-mgm-form/super-admin-mgm-form.component';
import * as Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';
import {HttpErrorResponse} from '@angular/common/http';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {Router} from '@angular/router';
import {UserService} from '../../../../../shared/services/user.service';
import {DateMgmFormModalComponent} from './date-mgm-form-modal/date-mgm-form-modal.component';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {LicenseConfigurationService} from '../../../../../shared/services/license-configuration.service';
import {CustomSnackBarComponent} from '../../../../../shared/custom-snack-bar/custom-snack-bar.component';
import {SweetAlertService} from '../../../../../shared/services/sweet-alert.service';


@Component({
  selector: 'app-group-mgm',
  templateUrl: './group-mgm.component.html',
  styleUrls: ['./group-mgm.component.scss']
})
export class GroupMgmComponent implements OnInit {
  groups: GroupWithSuperAdmins[] = [];
  features: FeatureNode[];
  showSaveFeatureButton = false;
  openedPanel;
  tableColumns = [
    '',
    'DATA_TABLE.USERNAME',
    'DATA_TABLE.FIRST_NAME',
    'DATA_TABLE.LAST_NAME',
    'DATA_TABLE.MOBILE',
    ''
  ];
  filterForm = new UntypedFormGroup({
    search: new UntypedFormControl(null),
    active: new UntypedFormControl(null),
    expired: new UntypedFormControl(null),
    confirmed: new UntypedFormControl(null),
    licenseConfigId: new UntypedFormControl(null),
    from: new UntypedFormControl(null),
    to: new UntypedFormControl(null),
    deleted: new UntypedFormControl(null),
  });
  loading = false;
  licenseConfigs: any[];

  constructor(
    private matDialog: MatDialog,
    private ownerService: OwnerService,
    private matSnackBar: MatSnackBar,
    private translateService: TranslateService,
    private $localStorage: LocalStorageService,
    private $sessionStorage: SessionStorageService,
    private router: Router,
    private userService: UserService,
    private sweetAlertService: SweetAlertService,
    private licenseService: LicenseConfigurationService) {
  }

  ngOnInit() {
    this.loadGroups();
    this.features = TREE_DATA;
    this.filterForm.valueChanges.subscribe(() => {
      this.loadGroups();
    });
    this.licenseService.getLazyLicensesConfigurations({}).subscribe(d => {
      this.licenseConfigs = d;
    });
  }

  openAddGroupForm() {
    this.matDialog.open(GroupFormModalComponent, {
      closeOnNavigation: true,
      disableClose: true,
      width: '500px',
      data: {editMode: false}
    }).afterClosed().subscribe(d => {
      if (d) {
        this.filterForm.reset({search: null, active: null, expired: null}, {emitEvent: false});
        this.loadGroups(true);
      }
    });

  }


  deleteGroup(g: GroupWithSuperAdmins, $event: MouseEvent) {
    $event.cancelBubble = true;
    $event.stopPropagation();
    this.sweetAlertService.warning(
      this.translateService.instant(g.deleted ? 'ADMIN.GROUP.YOU_ARE_ABOUT_TO_RESTORE' : 'ADMIN.GROUP.YOU_ARE_ABOUT_TO_DELETE', {name: g.name}),
      this.translateService.instant('BUTTONS.CONFIRM'),
      this.translateService.instant('BUTTONS.CANCEL'),
      this.translateService.instant('ADMIN.GROUP.ATTENTION')
    ).then(e => {
      if (e) {
        this.ownerService.deleteGroup(g.id).subscribe(d => {
          this.loadGroups();
        }, (err: HttpErrorResponse) => {
          if (err.error.message.includes('ConstraintViolationException')) {
            this.matSnackBar.open(this.translateService.instant('ADMIN.GROUP.CANNOT_DELETE_GROUP'), 'Ok', {
              duration: 5000,
              panelClass: 'white-snackbar',
            });
          }
        });
      }
    });
  }

  openEditGroupForm(g: GroupWithSuperAdmins, $event: MouseEvent) {
    $event.cancelBubble = true;
    $event.stopPropagation();
    this.matDialog.open(GroupFormModalComponent, {
      width: '500px',
      disableClose: false,
      data: {editMode: true, group: g}
    }).afterClosed().subscribe(d => {
      if (d) {
        this.loadGroups();
      }
    });
  }

  loadGroups(openEditDuration?: boolean) {
    this.loading = true;
    this.ownerService.getAllGroups(this.filterForm.value).subscribe(res => {
      this.groups = res;
      this.loading = false;
      if (openEditDuration) {
        this.openDateMgmForm(res[0]);
      }
    });
  }

  generateQrCode() {
    this.ownerService.generateQrCode().subscribe(res => {
      this.loadGroups();
      this.sweetAlertService.success(this.translateService.instant('DIALOG.UPDATE_SUCCESS'));
    });
  }

  private setCheckedFeatures(selectedFeatures: string[]) {
    if (selectedFeatures.length === 0) {
      this.resetFeatures();
      return;
    }
    selectedFeatures.forEach(d => {
      this.features.forEach(e => {
        const child0 = e.children[0];
        const child1 = e.children[1];
        if (child0.value === d) {
          e.children[0].checked = true;
        }
        if (child1.value === d) {
          e.children[1].checked = true;
        }
        e.checked = e.children[0].checked && e.children[1].checked;
        e.indeterminate = (e.children[0].checked || e.children[1].checked) && !(e.children[0].checked && e.children[1].checked);
      });
    });
  }

  private getCheckedFeatures() {
    return this.features
      .filter(e => e.children.findIndex(u => u.checked) > -1)
      .map(e => e.children)
      .reduce((a, b) => a.concat(b), [])
      .filter(e => e.checked)
      .map(e => e.value);
  }

  private resetFeatures() {
    this.showSaveFeatureButton = false;
    this.features.forEach(e => {
      e.checked = false;
      e.indeterminate = false;
      e.expand = false;
      e.children.forEach(d => d.checked = false);
    });
  }

  setLastOpenedPanel(i: number) {
    this.openedPanel = i;
  }

  openSuperAdminMgmFormDialog(gpe, acc?) {
    const dialogRef = this.matDialog.open(SuperAdminMgmFormComponent, {
      closeOnNavigation: true,
      disableClose: true,
      width: '700px',
      data: {
        group: gpe,
        account: acc,
      }
    });
    dialogRef.afterClosed().subscribe(d => {
      if (d) {
        this.loadGroups();
      }
    });
  }

  deleteSuperAdmin(a) {
    this.sweetAlertService.warning(
      this.translateService.instant('ADMIN.GROUP.YOU_ARE_ABOUT_TO_DELETE', {name: `${a.firstName} ${a.lastName}`}),
      this.translateService.instant('BUTTONS.CONFIRM'),
      this.translateService.instant('BUTTONS.CANCEL'),
      this.translateService.instant('ADMIN.GROUP.ATTENTION')
    ).then(e => {
      if (e.value) {
        this.ownerService.deleteSuperAdmin(a.id).subscribe(d => {
          this.loadGroups();
        }, (err: HttpErrorResponse) => {
          if (err.error.message.includes('ConstraintViolationException')) {
            this.matSnackBar.open(this.translateService.instant('ADMIN.GROUP.CANNOT_DELETE_SUPER_ADMIN'), 'Ok', {
              duration: 5000,
              panelClass: 'white-snackbar',
            });
          }
        });
      }
    });
  }

  toggleActivation(g: GroupWithSuperAdmins, $event: MouseEvent) {
    $event.cancelBubble = true;
    $event.stopPropagation();
    this.ownerService.toggleGroupActivation(g.id).subscribe(d => {
      this.loadGroups();
    });
  }

  navigateToGroup(g: GroupWithSuperAdmins, $event: MouseEvent) {
    $event.stopPropagation();
    $event.cancelBubble = true;
    const tokenStorageKey = 'authenticationToken';
    this.ownerService.chooseGroup(g.id).subscribe(resp => {
      if (this.$localStorage.retrieve(tokenStorageKey)) {
        this.$localStorage.store(tokenStorageKey, resp.token);
      } else {
        this.$sessionStorage.store(tokenStorageKey, resp.token);
      }
      this.userService.fetch().subscribe(u => {
        if (this.$localStorage.retrieve(tokenStorageKey)) {
          this.$localStorage.store('user', u);
        } else {
          this.$sessionStorage.store('user', u);
        }
      });
      this.router.navigate(['/admin']);
    });
  }

  openDateMgmForm(g: GroupWithSuperAdmins, $event?: MouseEvent) {
    if ($event) {
      $event.stopPropagation();
      $event.cancelBubble = true;
    }
    this.matDialog.open(DateMgmFormModalComponent, {
      disableClose: true,
      data: {group: g}
    }).afterClosed().subscribe(u => {
      if (u) {
        this.loadGroups();
      }
    });
  }

  exportCSV() {
    this.ownerService.exportGroupsCSV(this.filterForm.value).subscribe(resp => {
      const contentDisposition = resp.headers.get('content-disposition');
      const fileName = contentDisposition.replace('attachment; filename=', '');
      const blob = resp.body;
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.download = fileName;
      anchor.href = url;
      anchor.click();
    });
  }

  deleteGroupDefinitely(g: GroupWithSuperAdmins, $event: MouseEvent) {
    $event.cancelBubble = true;
    $event.stopPropagation();
    this.sweetAlertService.warning(
      this.translateService.instant(
        'ADMIN.GROUP.YOU_ARE_ABOUT_TO_DELETE',
        {name: g.name}
      ),
      this.translateService.instant('BUTTONS.CONFIRM'),
      this.translateService.instant('BUTTONS.CANCEL'),
      this.translateService.instant('ADMIN.GROUP.ATTENTION')
    ).then(e => {
      if (e.value) {
        this.ownerService.deleteGroupDefinitely(g.id).subscribe(d => {
          this.loadGroups();
        }, (err: HttpErrorResponse) => {
          if (err.error.message.includes('ConstraintViolationException')) {
            this.matSnackBar.open(this.translateService.instant('ADMIN.GROUP.CANNOT_DELETE_GROUP'), 'Ok', {
              duration: 5000,
              panelClass: 'white-snackbar',
            });
          }
        });
      }
    });
  }
}


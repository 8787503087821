import {MappedQuote, MappedSubscription} from './../../models/mapped-card';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {RtModel} from '../../enum/rt-model';
import {ConfigRtDto} from '../../dto/config-rt-dto';
import {environment} from "../../../../environments/environment";
import {MappedPayment,} from "../../models/mapped-payment";
import {PrinterStatus} from "../../models/printer-status";
import {FiscalReceiptInfo} from "../../models/fiscal-receipt-info";
import {MappedCard} from "../../models/mapped-card";

@Injectable({
  providedIn: 'root'
})
export class RtDriverService {

  CONFIGURATION_API_DRIVER = '127.0.0.1:3000';
  CONFIGURATION_API = environment.api + '/rt';

  constructor(private http: HttpClient) {
  }

  getRtConfig(ip, model: RtModel) {
    const params: HttpParams = new HttpParams()
      .set('ip', ip);

    let path: string;
    if (model === RtModel.EPSON) {
      path = '/epson/config';
    }

    return this.http.get('http://' + this.CONFIGURATION_API_DRIVER + path, {params});
  }

  getFiscalCode(ip, model: RtModel) {
    const params: HttpParams = new HttpParams()
      .set('ip', ip);

    let path: string;
    if (model === RtModel.EPSON) {
      path = '/epson/config/matricola';
    }

    return this.http.get('http://' + this.CONFIGURATION_API_DRIVER + path, {params});
  }

  printTest(ip, model: RtModel) {
    const params: HttpParams = new HttpParams()
      .set('ip', ip);

    let path: string;
    if (model === RtModel.EPSON) {
      path = '/epson/config/test';
    }

    return this.http.get('http://' + this.CONFIGURATION_API_DRIVER + path, {params});
  }

  synchronizeConfig(configRtDto: ConfigRtDto, id) {
    return this.http.put(`${this.CONFIGURATION_API}/sync/` + id, configRtDto);
  }

  getStatus(ip: string, model: string) {
    const params: HttpParams = new HttpParams()
      .set('ip', ip)
      .set('model', model);

    let path: string;
    if (model === RtModel.EPSON) {
      path = '/status';
    }

    return this.http.get<PrinterStatus>('http://' + this.CONFIGURATION_API_DRIVER + path, {params});
  }

  pay(ip: string, model: string, mappedPayment: MappedPayment) {
    const params: HttpParams = new HttpParams()
      .set('ip', ip)
      .set('model', model);

    const headers: HttpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json');

    return this.http.post<FiscalReceiptInfo>('http://' + this.CONFIGURATION_API_DRIVER + '/pay', mappedPayment, {headers, params});
  }

  undo(ip: string, model: string, mappedPayment: MappedPayment) {
    const params: HttpParams = new HttpParams()
      .set('ip', ip)
      .set('model', model);

    const headers: HttpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json');

    return this.http.post<boolean>('http://' + this.CONFIGURATION_API_DRIVER + '/pay/undo', mappedPayment, {headers, params});
  }

  fidelity(ip: string, model: string, mappedCard: MappedCard) {
    const params: HttpParams = new HttpParams()
      .set('ip', ip)
      .set('model', model);

    const headers: HttpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json');

    return this.http.post<boolean>('http://' + this.CONFIGURATION_API_DRIVER + '/fidelity', mappedCard, {headers, params});
  }

  subscription(ip: string, model: string, mappedSubscription: MappedSubscription) {
    const params: HttpParams = new HttpParams()
      .set('ip', ip)
      .set('model', model);

    const headers: HttpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json');

    return this.http.post<boolean>('http://' + this.CONFIGURATION_API_DRIVER + '/subscription', mappedSubscription, {headers, params});
  }

  quote(ip: string, model: string, mappedQuote: MappedQuote) {
    const params: HttpParams = new HttpParams()
      .set('ip', ip)
      .set('model', model);

    const headers: HttpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json');

    return this.http.post<boolean>('http://' + this.CONFIGURATION_API_DRIVER + '/quote', mappedQuote, {headers, params});
  }

  appointment(ip: string, model: string, mappedAppointment: any) {
    const params: HttpParams = new HttpParams()
      .set('ip', ip)
      .set('model', model);

    const headers: HttpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json');

    return this.http.post<boolean>('http://' + this.CONFIGURATION_API_DRIVER + '/appointment', mappedAppointment, {headers, params});
  }

  ReportZ(ip: string, model: string) {
    const params: HttpParams = new HttpParams()
    .set('ip', ip)
    .set('model', model);

    const headers: HttpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json');

    return this.http.get<boolean>('http://' + this.CONFIGURATION_API_DRIVER + '/report/z', {headers, params});
  }

  ReportX(ip: string, model: string) {
    const params: HttpParams = new HttpParams()
    .set('ip', ip)
    .set('model', model);

    const headers: HttpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json');

    return this.http.get<boolean>('http://' + this.CONFIGURATION_API_DRIVER + '/report/x', {headers, params});
  }

  getResetNumber(ip: string, model: string) {
    const params: HttpParams = new HttpParams()
    .set('ip', ip)
    .set('model', model);

    const headers: HttpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json');

    return this.http.get<{resetNumber: string}>('http://' + this.CONFIGURATION_API_DRIVER + '/reset', {headers, params})
  }

  getInvoiceNumber(ip: string, model: string) {
    const params: HttpParams = new HttpParams()
    .set('ip', ip)
    .set('model', model);

    const headers: HttpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json');

    return this.http.get<{invoiceNumber: string}>('http://' + this.CONFIGURATION_API_DRIVER + '/number', {headers, params})
  }

}




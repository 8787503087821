import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FirstNoteComponent} from './first-note.component';
import {RouterModule, Routes} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {FirstNoteFormComponent} from './first-note-form/first-note-form.component';
import {MatLegacyDialogModule} from '@angular/material/legacy-dialog';
import {NgSelectModule} from '@ng-select/ng-select';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {ReactiveFormsModule} from '@angular/forms';
import {CurrencyMaskModule} from "ng2-currency-mask";

import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';

const routes: Routes = [
  {path: '', component: FirstNoteComponent}
];

@NgModule({
  declarations: [FirstNoteComponent, FirstNoteFormComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatButtonModule,
    MatLegacyDialogModule,
    NgSelectModule,
    MatIconModule,
    TranslateModule,
    ReactiveFormsModule,
    CurrencyMaskModule,
    NgbTooltipModule,
  ]
})
export class FirstNoteModule {
}

import {Component, OnInit, TemplateRef} from '@angular/core';
import {PromotionMgmService} from "../../../shared/services/promotion-mgm.service";
import {FacebookPost} from "../../../shared/models/facebook-post";
import {  MatDialog} from "@angular/material/dialog";
import {BreadcrumbService} from "../../../core/services/breadcrumb.service";
import {SweetAlertService} from '../../../shared/services/sweet-alert.service';
import {TranslateService} from '@ngx-translate/core';
import {CustomSnackBarComponent} from '../../../shared/custom-snack-bar/custom-snack-bar.component';
import {  MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-social-posts',
  templateUrl: './social-posts.component.html',
  styleUrls: ['./social-posts.component.scss']
})
export class SocialPostsComponent implements OnInit {
  page = 1;
  pageSize = 30;
  totalSize;
  facebookPosts: FacebookPost[];
  filter = [
    {icon: 'fab fa-facebook', value: 'Facebook'},
    {icon: 'fab fa-instagram', value: 'Instagram'},
  ];
  constructor(
    private promotionMgmService: PromotionMgmService,
    private sweetAlertService: SweetAlertService,
    private translate: TranslateService,
    private matDialog: MatDialog,
    private snackBar: MatSnackBar,
    private breadcrumbService: BreadcrumbService
  ) { }

  ngOnInit() {
    this.breadcrumbService.sendBreadcrumb(['SOCIAL_POSTS']);
    this.loadFacebookPosts();
  }

  loadFacebookPosts() {
    this.promotionMgmService
      .getFacebookPosts({page: this.page - 1, pageSize: this.pageSize})
      .subscribe(res => {
        this.facebookPosts = res.data;
        this.totalSize = res.totalRecords;
      });
  }

  pageChange(page: number) {
    this.page = page;
    this.loadFacebookPosts();
  }

  openPost(postDetails: TemplateRef<any>, post) {
    console.log(post);
    this.matDialog.open(postDetails, {
      width: '500px',
      data: post
    });
  }

  deleteSocialPost($event, id: any): void {
    $event.cancelBubble = true;
    $event.stopPropagation();

    this.sweetAlertService.warning(this.translate.instant('DIALOG.YOU_WANT_TO_DELETE') +
      " " + this.translate.instant('SIDENAV.SOCIAL_POSTS')
      +  " "
      + id)
      .then(res => {
        if (res.value) {
          this.promotionMgmService.delete(id).subscribe(d => {
            if (d.status === 200) {
              this.loadFacebookPosts();
              this.sweetAlertService.success(this.translate.instant('DIALOG.DELETE_SUCCESS'))
            }
          }, err => {
            if (err.status === 500) {
              this.showSnackBar({
                text: `${this.translate.instant('SIDENAV.SOCIAL_POSTS')} ${id} - `,
                actionIcon: 'failed',
                actionMsg: this.translate.instant('DIALOG.CANNOT_DELETE')
              });
            }
          });
        }
      }, err => {

      });
  }

  showSnackBar(data: any) {
    this.snackBar.openFromComponent(CustomSnackBarComponent, {
      data,
      duration: 5000,
      panelClass: 'white-snackbar'
    });
  }
}

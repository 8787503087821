import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OperatorReportsComponent} from './operator-reports.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatLegacyButtonModule} from '@angular/material/legacy-button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import {RouterModule, Routes} from '@angular/router';
import {SharedModule} from '../../../../shared/shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbDropdownModule, NgbPaginationModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {CurrencyMaskModule} from 'ng2-currency-mask';
import {NgChartsModule} from 'ng2-charts';
import {CompareFilterDateModule} from '../../../../shared/compare-filter-date/compare-filter-date.module';
import {GenericFilterModule} from '../../../../shared/generic-filter/generic-filter.module';

const routes: Routes = [
  {path: '', component: OperatorReportsComponent}
];

@NgModule({
  declarations: [OperatorReportsComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatLegacyButtonModule,
    FormsModule,
    MatIconModule,
    RouterModule.forChild(routes),
    SharedModule.forChild(),
    ReactiveFormsModule,
    NgbPaginationModule,
    MatDatepickerModule,
    NgbDropdownModule,
    CurrencyMaskModule,
    NgChartsModule,
    NgbTooltipModule,
    CompareFilterDateModule,
    GenericFilterModule,
  ]
})
export class OperatorReportsModule {
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {ClientComponent} from './client.component';
import {MatLegacyButtonModule} from '@angular/material/legacy-button';
import {   MatFormFieldModule } from '@angular/material/form-field';
import {  MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';

import {TranslateModule} from '@ngx-translate/core';
import {ReactiveFormsModule} from '@angular/forms';


const routes: Routes = [
  {path: '', component: ClientComponent}
];
@NgModule({
  declarations: [ClientComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatTabsModule,
    TranslateModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatLegacyButtonModule,
  ],


})
export class ClientModule { }

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProviderCreateComponent} from './provider-create.component';
import {MatLegacyButtonModule} from '@angular/material/legacy-button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatLegacyDialog as MatDialog, MatLegacyDialogModule} from '@angular/material/legacy-dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRippleModule} from '@angular/material/core';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTableModule} from '@angular/material/table';
import {SubheaderModule} from '../../content/layout/components/subheader/subheader.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {NgbDropdownModule, NgbPaginationModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared.module';
import {NgSelectModule} from '@ng-select/ng-select';

@NgModule({

  declarations: [ProviderCreateComponent],
  exports: [
    ProviderCreateComponent
  ],
  imports: [
    CommonModule,
    MatLegacyButtonModule,
    MatIconModule,
    SubheaderModule,
    NgxDatatableModule,
    MatTableModule,
    MatListModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatRippleModule,
    NgbDropdownModule,
    TranslateModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatLegacyDialogModule,
    SharedModule.forChild(),
    NgbPaginationModule,
    MatProgressSpinnerModule,
    NgSelectModule,
    NgbTooltipModule
  ]
})
export class ProviderCreateModule {
}

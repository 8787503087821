import {Component, Inject, OnInit, Pipe, PipeTransform} from '@angular/core';
import {  MAT_DIALOG_DATA,   MatDialog} from '@angular/material/dialog';
import {FidelityCard} from '../../../../../../shared/models/fidelity-card';
import {FidelityCardHistory} from '../../../../../../shared/models/fidelity-card-history';
import {FidelityService} from '../../../../../../shared/services/fidelity.service';
import {FidelityCardHistoryDto} from '../../../../../../shared/dto/fidelity-card-history-dto';
import {FidelityCardMovementType} from '../../../../../../shared/enum/fidelity-card-movement-type';
import {FidelityCardType} from '../../../../../../shared/enum/fidelity-card-type';
import {MAT_LEGACY_DIALOG_DATA, MatLegacyDialog} from "@angular/material/legacy-dialog";

@Component({
  selector: 'app-fidelity-history',
  templateUrl: './fidelity-history.component.html',
  styleUrls: ['./fidelity-history.component.scss']
})
export class FidelityHistoryComponent implements OnInit {
  fidelity: FidelityCard;
  creationDate;
  historyColumns = [
    'DATA_TABLE.DATE',
    'DATA_TABLE.AMOUNT_PAID',
    'DATA_TABLE.TYPE',
    'DATA_TABLE.REST',
  ];
  historyReloadColumns = [
    'DATA_TABLE.DATE',
    'DATA_TABLE.NAME',
    'DATA_TABLE.AMOUNT_PAID',
    'DATA_TABLE.TYPE',
    'DATA_TABLE.REST',
  ];
  historyPointColumns = ['DATA_TABLE.DATE', 'DATA_TABLE.POINTS', 'DATA_TABLE.TYPE', 'DATA_TABLE.REST'];
  historiesList: FidelityCardHistory[] = [];
  amount: number;

  constructor(public matDialog: MatLegacyDialog, @Inject(MAT_LEGACY_DIALOG_DATA) public data: any, private cardService: FidelityService) {
  }

  ngOnInit() {
    this.fidelity = this.data.fidelity;
    // if (this.fidelity.cardType === FidelityCardType.POINT_WITH_GIFT) {
    //   this.historyReloadColumns = ['DATA_TABLE.DATE', 'DATA_TABLE.POINTS', 'DATA_TABLE.TYPE', 'DATA_TABLE.REST'];
    // }
    this.historyColumns = this.fidelity.cardType === FidelityCardType.POINT_WITH_GIFT ? this.historyPointColumns :
      this.historyReloadColumns;
    this.creationDate = new Date(this.fidelity.creationDate);
    this.getHistory();
  }

  getHistory() {
    this.cardService.getHistory(this.fidelity.id).subscribe(r => {
      this.historiesList = r;
    });

  }

  saveHistory() {
    if (this.amount === 0) {
      return;
    }
    const change: FidelityCardHistoryDto = new FidelityCardHistoryDto();
    change.amount = this.amount;
    change.movementType = this.amount > 0 ? FidelityCardMovementType.MANUAL_ADD : FidelityCardMovementType.MANUAL_SUBTRACT;
    change.fidelityCardId = this.fidelity.id;
    this.cardService.effectChange(change).subscribe(r => {
      this.fidelity.currentAmount += this.amount;
      this.amount = 0;
      this.getHistory();
    });
  }

  resetPoints(isOpen: boolean) {
    if (!isOpen) {
      this.amount = 0;
    }
  }
}

@Pipe({
  name: 'intPipe'
})
export class IntPipe implements PipeTransform {
  transform(val: number): number {
    return Math.trunc(val);
  }
}

import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {   MatSnackBar } from '@angular/material/snack-bar';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {MovementFormService} from './service/movement-form.service';
import {Movement} from '../../../../shared/models/movement';
import {MovementType} from '../../../../shared/enum/movement-type';
import {BreadcrumbService} from '../../../../core/services/breadcrumb.service';
import {MovementMgmService} from '../../../../shared/services/movement-mgm.service';
import {SweetAlertService} from '../../../../shared/services/sweet-alert.service';
import {CustomSnackBarComponent} from '../../../../shared/custom-snack-bar/custom-snack-bar.component';
import {Router} from '@angular/router';
import {ProviderMgmService} from '../../../../shared/services/provider-mgm.service';
import {TypeDocument} from '../../../../shared/enum/type-document';
import {ChooseSellPointResponse, SellPointService} from '../../../../shared/services/sell-point.service';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {SellPoint} from '../../../../shared/models/sell-point';

@Component({
  selector: 'app-movement-management',
  templateUrl: './movement-management.component.html',
  styleUrls: ['./movement-management.component.scss']
})
export class MovementManagementComponent implements OnInit {

  columns = [];
  loading = true;
  firstCall = true;
  rows: Movement[] = [];
  searchFormControl: UntypedFormGroup;
  @ViewChild('movementFormContainer', {read: ViewContainerRef , static: false}) movementFormContainer: ViewContainerRef;
  public page = 1;
  public totalRecords: number;
  public pageSize = 10;
  types = [];
  type = 'CARICO';
  client = 'MOVEMENT_FORM.PROVIDER';
  providers = [];
  clientProviderPage = 1;
  documentTypes = [];
  affectedSellPoints = [];
  sellPoints = [];
  searchClicked = false;
  user: any;
  startDelete = false;

  constructor(
    public snackBar: MatSnackBar,
    private http: HttpClient,
    private breadcrumbService: BreadcrumbService,
    public dialog: MatDialog,
    private movementMgmService: MovementMgmService,
    private sweetAlertService: SweetAlertService,
    private translate: TranslateService,
    private movementFormService: MovementFormService,
    private router: Router,
    private providerService: ProviderMgmService,
    private sellPointService: SellPointService,
    private localStorage: LocalStorageService,
    private sessionStorage: SessionStorageService
  ) {
    this.user = this.localStorage.retrieve('user') || this.sessionStorage.retrieve('user');
  }


  ngOnInit() {
    this.sendBreadCrumb();
    this.resetSearchForm();
    this.getSellPointsFromAccount();
    // this.search();
    this.getProviders();
  }

  showSnackBar(data: any) {
    this.snackBar.openFromComponent(CustomSnackBarComponent, {
      data,
      duration: 5000,
      panelClass: 'white-snackbar'
    });
  }

  delete($event, el: Movement): void {
    $event.cancelBubble = true;
    $event.stopPropagation();
    this.sweetAlertService.warning(this.translate.instant('DIALOG.YOU_WANT_TO_DELETE_SELECTED_MVM'))
      .then(res => {
        if (res.value) {
          this.startDelete = true;
          this.movementMgmService.delete(el.id).subscribe(r => {
            this.startDelete = false;
            this.showSnackBar({
              type: this.translate.instant('HEADER.BREADCRUMB.MOVEMENT'),
              text: `0000${el.number}`.slice(-4),
              actionIcon: 'delete',
              actionMsg: this.translate.instant('DIALOG.DELETE_SUCCESS')
            });
            this.search();
          }, err => {
            this.startDelete = false;
            if (err.status === 500) {
              this.showSnackBar({
                type: this.translate.instant('HEADER.BREADCRUMB.MOVEMENT'),
                text: `0000${el.number}`.slice(-4),
                actionIcon: 'failed',
                actionMsg: this.translate.instant('DIALOG.CANNOT_DELETE')
              });
            }
          });
        }
      });
  }


  sendBreadCrumb(): void {

    if (this.router.url !== '/carico') {
      this.type = 'SCARICO';
      this.client = 'MOVEMENT_FORM.CLIENT';
      this.breadcrumbService.sendBreadcrumb(['MOVEMENT', 'MOVEMENT_SCARICO']);
    } else {
      this.breadcrumbService.sendBreadcrumb(['MOVEMENT', 'MOVEMENT_CARICO']);
    }
    this.columns = [
      'OPERATOR_FORM.SELL_POINT',
      'MOVEMENT_FORM.DATE',
      'MOVEMENT_FORM.DOCUMENT_DATE',
      // 'MOVEMENT_FORM.NUMBER',
      'MOVEMENT_FORM.TYPE',
      this.client,
      'MOVEMENT_FORM.DOCUMENT_TYPE',
      'MOVEMENT_FORM.DOCUMENT_NUMBER',
      'MOVEMENT_FORM.TOTAL_QUANTITY',
      'MOVEMENT_FORM.TOTAL_IVATO',
      'MOVEMENT_FORM.TOTAL_TAXABLE',
      'MOVEMENT_FORM.DRAFT'];
    this.translateTypes();
  }

  edit(data, i) {
    const formRef = this.movementFormService.openForm(this.movementFormContainer);
    formRef.instance.movement = data;
    formRef.instance.editMode = true;
    formRef.instance.type = this.type;
    formRef.instance.onClose.subscribe(d => {
      this.search();
      if (d) {
        this.showSnackBar({
          type: this.translate.instant('HEADER.BREADCRUMB.MOVEMENT'),
          text: `0000${data.number}`.slice(-4),
          actionIcon: 'save',
          actionMsg: this.translate.instant('DIALOG.UPDATE_SUCCESS')
        });
      }
    });
  }

  addNew() {
    this.search();
    const formRef = this.movementFormService.openForm(this.movementFormContainer);
    formRef.instance.editMode = false;
    formRef.instance.type = this.type;
    formRef.instance.onClose.subscribe(d => {
      this.search();
      if (d) {
        this.showSnackBar({
          type: this.translate.instant('HEADER.BREADCRUMB.MOVEMENT'),
          actionIcon: 'save',
          actionMsg: this.translate.instant('DIALOG.ADD_SUCCESS')
        });
      }
    });
  }


  search() {
    let dateTo = null;
    if (this.searchFormControl.get('dateTo').value) {
      dateTo = new Date(this.searchFormControl.get('dateTo').value);
      dateTo.setHours(dateTo.getHours() + 25);
    }
    let dateFrom = null;
    if (this.searchFormControl.get('dateFrom').value) {
      dateFrom = new Date(this.searchFormControl.get('dateFrom').value);
      dateFrom.setHours(dateFrom.getHours() + 4);
    }
    const request = {
      page: this.page,
      pageSize: this.pageSize,
      number: this.searchFormControl.get('number').value,
      dateFrom,
      dateTo,
      type: this.searchFormControl.get('type').value,
      typesMovement: this.searchFormControl.get('type').value === null ? this.types.map(e => e.id) : null,
      provider: this.searchFormControl.get('provider').value,
      documentType: this.searchFormControl.get('documentType').value,
      sellPoint: this.searchFormControl.get('sellPoint').value,
      inventory: false,
      allSpIds: this.affectedSellPoints.map(u => u.sellPointId),
    };
    this.loading = true;
    return this.movementMgmService.search(request).subscribe(data => {
      this.rows = data.data;
      this.loading = false;
      this.firstCall = false;
      this.totalRecords = data.totalRecords;
    });
  }

  pageChange(page: number) {
    this.page = page;
    this.search();
  }

  resetSearchForm() {
    this.searchFormControl = new UntypedFormGroup({
      dateFrom: new UntypedFormControl(null),
      dateTo: new UntypedFormControl(null),
      number: new UntypedFormControl(null),
      type: new UntypedFormControl(null),
      documentType: new UntypedFormControl(null),
      provider: new UntypedFormControl(null),
      sellPoint: new UntypedFormControl(this.user.sellPointId)
    });
    this.filter();
  }

  filter() {
    this.page = 1;
    this.search();
  }

  translateTypes() {
    this.type === 'CARICO' ? this.translateTypesCarico() : this.translateTypesScarico();
  }

  translateTypesCarico() {
    this.types = [
      {description: this.translate.instant('MOVEMENT_FORM.' + MovementType.SUPPLIER_LOAD), id: MovementType.SUPPLIER_LOAD},
      {description: this.translate.instant('MOVEMENT_FORM.' + MovementType.VARIOUS_LOAD), id: MovementType.VARIOUS_LOAD},
      {description: this.translate.instant('MOVEMENT_FORM.' + MovementType.RESO), id: MovementType.RESO},
      {description: this.translate.instant('MOVEMENT_FORM.' + MovementType.INVENTORY), id: MovementType.INVENTORY}
    ];
  }

  translateTypesScarico() {
    this.types = [
      {description: this.translate.instant('MOVEMENT_FORM.' + MovementType.CUSTOMER_SALES), id: MovementType.CUSTOMER_SALES},
      {description: this.translate.instant('MOVEMENT_FORM.' + MovementType.SELF_CONSUMPTION), id: MovementType.SELF_CONSUMPTION},
      {description: this.translate.instant('MOVEMENT_FORM.' + MovementType.VARIOUS_DISCHARGE), id: MovementType.VARIOUS_DISCHARGE},
    ];
  }

  translateDocumentTypes() {
    this.documentTypes = [{description: this.translate.instant('MOVEMENT_FORM.' + TypeDocument.NONE), id: TypeDocument.NONE},
      {description: this.translate.instant('MOVEMENT_FORM.' + TypeDocument.BILL), id: TypeDocument.BILL},
      {description: this.translate.instant('MOVEMENT_FORM.' + TypeDocument.DDT), id: TypeDocument.DDT}];
  }

  getProviders() {
    this.providerService.getLazyProviderList({page: this.clientProviderPage, pageSize: 10}).subscribe(data => {
      this.providers = [...this.providers, ...data.data];
      this.clientProviderPage++;
    });
  }

  getSellPointsFromAccount() {
    this.sellPointService.getSellPointsByAccountByCentre().subscribe(res => {
      this.sellPoints = res.sellPoints;
      this.affectedSellPoints = res.sellPoints;
      const allSp = {sellPointId: -1, sellPointLabel: this.translate.instant('MOVEMENT_FORM.ALL'), siege: false};
      this.sellPoints = [...this.sellPoints, allSp];
    });
  }

  hello() {
    this.searchClicked = false;
  }


  confirmMovement($event, movement) {
    $event.cancelBubble = true;
    $event.stopPropagation();
    this.movementMgmService.confirmMovement(movement).subscribe(r => {
      this.snackBar.open(this.translate.instant('DIALOG.UPDATE_SUCCESS'), 'Ok', {duration: 5000});
      this.search();
    });
  }
}

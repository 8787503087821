import {Component, OnInit} from '@angular/core';
import {BreadcrumbService} from 'src/app/core/services/breadcrumb.service';
import {BookingService} from 'src/app/shared/services/booking.service';
import {ClientMgmService} from 'src/app/shared/services/client-mgm.service';
import {Client} from 'src/app/shared/models/client';
import {PaymentService} from 'src/app/shared/services/payment.service';
import {Treatment} from 'src/app/shared/models/treatment';
import {TopTreatment} from 'src/app/shared/dto/top-treatment';
import {TopClient} from 'src/app/shared/dto/top-client';
import {PaymentStatsDto} from 'src/app/shared/dto/payment-stats-dto';
import {TranslateService} from '@ngx-translate/core';
import {DecimalPipe} from '@angular/common';
import {UserResponse} from 'src/app/shared/models/user-response';
import {SmsService} from 'src/app/shared/services/sms-service.service';
import {ProductMgmService} from '../../../shared/services/product-mgm.service';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';
import {style} from "@angular/animations";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public lineChart1;
  public lineChart2;
  bookingsOfToday = 0;
  approxTotal = 0;
  birthdays: Client[];
  currentDate = new Date();
  ficheMedia: number;
  scontrinoMedio: number;
  topClients: TopClient[] = [];
  topTreatments: TopTreatment[] = [];
  previousWeekStats: PaymentStatsDto[] = [];
  currentWeekStats: PaymentStatsDto[] = [];

  previousWeekDays: string[] = [];
  previousWeekData: number[] = [];

  currentWeekDays: string[] = [];
  currentWeekData: number[] = [];

  credits: UserResponse;
  clientNotificationMode: { sms: number, whatsApp: number };
  alertProduct = 0;


  DAYS = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
  previousWeekDataFormatted: string[];
  currentWeekDataFormatted: string[];

  constructor(private breadcrumbService: BreadcrumbService,
              private bookingService: BookingService,
              private clientService: ClientMgmService,
              private paymentService: PaymentService,
              private translate: TranslateService,
              private _decimalPipe: DecimalPipe,
              private productService: ProductMgmService,
              private router: Router,
              private smsService: SmsService) {
  }

  ngOnInit() {
    // let d: Date = this.startOfWeek(new Date());

    this.sendBreadCrumb();
    // this.smsService.getCredits().subscribe(r => {
    //   this.credits = r;
    // }, err => {
    //   this.credits = null;
    // });
    this.productService.countAlertProduct().subscribe(c => this.alertProduct = c);
    // this.paymentService.topProductsAndTreatments().subscribe(r => {
    //   this.topTreatments = r.sort((a, b) => (a.total > b.total ? -1 : 1));
    // });

    // this.bookingService.getBookingsOfToday().subscribe(r => {
    //   this.bookingsOfToday = r;
    // });

    // this.bookingService.getApproxTotalBookings().subscribe(r => {
    //   this.approxTotal = r;
    // });

    this.clientService.getLatestBirthdays().subscribe(r => {
      this.birthdays = r;
    });

    // this.paymentService.getFicheMedia().subscribe(r => {
    //   this.ficheMedia = r;
    // });

    // this.paymentService.getScontrinoMedio().subscribe(r => {
    //   this.scontrinoMedio = r;
    // });
    //
    // this.paymentService.getTopClients().subscribe(r => {
    //   this.topClients = r.sort((a, b) => (a.sum > b.sum ? -1 : 1));
    // });


    // this.paymentService.paymentStats().subscribe(r => {
    //   this.previousWeekStats = r.sort((a, b) => (a.creatAt < b.creatAt ? -1 : 1)).slice(0, 7);
    //   this.previousWeekDays = this.previousWeekStats.map(u => this.translate.instant('DAY.' + this.DAYS[new Date(u.creatAt).getDay()]));
    //   this.previousWeekData = this.previousWeekStats.map(u => u.sum);
    //   console.log(this.previousWeekData);
    //
    //
    //   this.currentWeekStats = r.sort((a, b) => (a.creatAt < b.creatAt ? -1 : 1)).slice(7);
    //   this.currentWeekDays = this.currentWeekStats.map(u => this.translate.instant('DAY.' + this.DAYS[new Date(u.creatAt).getDay()]));
    //   this.currentWeekData = this.currentWeekStats.map(u => u.sum);
    //
    //
    //   this.lineChart1 = {
    //     type: 'line',
    //     datasets: [{
    //       data: this.previousWeekData,
    //       label: 'Precedente',
    //       borderColor: '#2949EF',
    //       backgroundColor: '#2949efa6',
    //       tension: 0.1,
    //       fill: false,
    //       borderWidth: 3,
    //       pointHitRadius: 30,
    //       pointBackgroundColor: '#fff',
    //       pointBorderColor: '#2949EF',
    //       pointHoverBorderColor: '#fff',
    //       pointHoverBackgroundColor: '#2949EF',
    //       pointRadius: 5,
    //       pointBorderWidth: 2,
    //       pointHoverRadius: 7,
    //     },
    //       {
    //         data: this.currentWeekData, label: 'In corso',
    //         borderColor: '#FE4D2E',
    //         backgroundColor: '#fd7e14',
    //         fill: false,
    //         borderWidth: 5,
    //         pointHitRadius: 30,
    //         pointBackgroundColor: '#fff',
    //         pointBorderColor: '#FE4D2E',
    //         pointHoverBorderColor: '#fff',
    //         pointHoverBackgroundColor: '#FE4D2E',
    //         pointRadius: 5,
    //         pointBorderWidth: 5,
    //         pointHoverRadius: 7,
    //       },
    //     ],
    //     labels: this.previousWeekDays,
    //     legend: true,
    //     options: {
    //       responsive: true,
    //       maintainAspectRatio: false,
    //       drawOnChartArea: true,
    //       scales: {
    //         y: {
    //           max: r.reduce((prev, current) => (prev.sum > current.sum) ? prev : current).sum,
    //           ticks: {
    //             beginAtZero: true,
    //             suggestedMax: r.reduce((prev, current) => (prev.sum > current.sum) ? prev : current).sum,
    //             callback: function (value, index, values) {
    //               return new Intl.NumberFormat('it-IT', {style: 'currency', currency: 'EUR'}).format(value);
    //             }
    //           }
    //         }
    //       },
    //       tooltips: {
    //         callbacks: {
    //           label: function (tooltipItem, data) {
    //             const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
    //             const value = tooltipItem.yLabel || '';
    //             return datasetLabel + ': ' + new Intl.NumberFormat('it-IT', {
    //               style: 'currency',
    //               currency: 'EUR'
    //             }).format(value);
    //           }
    //         }
    //       },
    //
    //     },
    //   };
    // });

  }


  sendBreadCrumb(): void {
    this.breadcrumbService.sendBreadcrumb(['DASHBOARD']);
  }

  startOfWeek(date) {
    const diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
  }


  showTodayBooking() {
    localStorage.setItem('todayBooking', 'true');
    // this.router.navigate(['/planning']);
  }
}

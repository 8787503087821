// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .last-passage.ng-dropdown-panel {
  z-index: 2100;
}`, "",{"version":3,"sources":["webpack://./src/app/content/pages/promotion-management/filter-clients/filter-clients.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF","sourcesContent":["::ng-deep .last-passage.ng-dropdown-panel {\r\n  z-index: 2100;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

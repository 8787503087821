import {AfterViewInit, Component, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import { Router,NavigationEnd  } from '@angular/router';

declare let MetisMenu: any;

@Component({
  selector: 'layout.content-wrapper',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit, AfterViewInit {

  constructor() {
    console.log(window.location.href);

  }

  ngOnInit() {
  }



  ngAfterViewInit() {
    new MetisMenu("#sidenav");
  }

}

import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MatLegacyDialog, MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {   MatSnackBar } from '@angular/material/snack-bar';
import {Client} from '../../../../../shared/models/client';
import {UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {FidelityService} from '../../../../../shared/services/fidelity.service';
import {TranslateService} from '@ngx-translate/core';
import {SweetAlertService} from '../../../../../shared/services/sweet-alert.service';
import {FidelityCard} from '../../../../../shared/models/fidelity-card';
import {FidelityComponent} from '../../../configurations/fidelity/fidelity.component';
import {FidelityCardRequestDto} from '../../../../../shared/dto/fidelity-card-request-dto';
import {FidelityHistoryComponent} from './fidelity-history/fidelity-history.component';
import {CustomSnackBarComponent} from '../../../../../shared/custom-snack-bar/custom-snack-bar.component';

@Component({
  selector: 'app-client-fidelity',
  templateUrl: './client-fidelity.component.html',
  styleUrls: ['./client-fidelity.component.scss']
})
export class ClientFidelityComponent implements OnInit {
  @Input() client: Client = new Client();
  @ViewChild('fidelityConfigList', { static: false }) fidelityConfigList: TemplateRef<FidelityComponent>;
  columns = [
    'DATA_TABLE.NUMBER',
    'DATA_TABLE.FIRST_NAME',
    'DATA_TABLE.TYPE',
    'DATA_TABLE.CREATION_DATE',
    'DATA_TABLE.SOLD',
    'DATA_TABLE.AVAILABLE',
  ];
  rows: FidelityCard[] = [];
  searchFormControl = new UntypedFormControl();
  totalRecords: number;
  pageSize = 10;
  page = 1;
  unsubscribe = new Subject();
  loading = true;
  firstCall = true;
  orderType: 'ASC' | 'DESC' = 'DESC';

  constructor(private matDialog: MatLegacyDialog, private cardService: FidelityService,
              private snackBar: MatSnackBar, private translate: TranslateService, private sweetAlertService: SweetAlertService) {
  }

  ngOnInit() {

    this.cardService.getClientCards(this.client.id).subscribe(r => {
      this.loading=false;
      if (r) {
        this.rows = r;
      }
    });
  }

  addFidelity() {
    const dialogRef = this.matDialog.open(this.fidelityConfigList, {
      width: '1000px',
      autoFocus: true,
      disableClose: true,
      // data: {editMode: false}
    });
    dialogRef.afterClosed().subscribe(d => {
      if (d) {
        // this.search({page: this.page, pageSize: this.pageSize});
      }
    });
  }

  openEdit(fidelity, $event: MouseEvent) {
    $event.stopPropagation();
    $event.cancelBubble = true;
    const dialogRef = this.matDialog.open(FidelityHistoryComponent, {
      width: '900px',
      autoFocus: true,
      disableClose: false,
      data: {fidelity}
    });
    dialogRef.afterClosed().subscribe(d => {
      if (d) {
      }
    });

  }

  delete(fidelity: FidelityCard, $event: MouseEvent) {
    $event.stopPropagation();
    $event.cancelBubble = true;
    this.sweetAlertService.warning(this.translate.instant('DIALOG.YOU_WANT_TO_DELETE') + fidelity.name).then(e => {
      if (e.value) {
        this.cardService.deleteAssociation(fidelity.id).subscribe(d => {
          if (d.status === 200) {
            this.ngOnInit();
          }
        });
      }
    }, err => {
      if (err.status === 500) {
        this.showSnackBar({
          text: `${fidelity.name}`,
          actionIcon: 'failed',
          actionMsg: this.translate.instant('DIALOG.CANNOT_DELETE')
        });
      }
    });
  }

  showSnackBar(data: any) {
    this.snackBar.openFromComponent(CustomSnackBarComponent, {
      data,
      duration: 5000,
      panelClass: 'white-snackbar'
    });
  }

  associate(event: number) {
    const associationCard = new FidelityCardRequestDto();
    associationCard.clientId = this.client.id;
    associationCard.fidelityCardConfigurationId = event;
    this.cardService.associateToClient(associationCard).subscribe((r: FidelityCard) => {
      this.rows.push(r);
    });


  }

  enableDisableAssociation($event: MouseEvent, card: FidelityCard) {
    $event.cancelBubble = true;
    $event.stopPropagation();
    let msg = '';
    if (card.active) {
      msg = this.translate.instant('DIALOG.YOU_WANT_TO_HIDE');
    } else {
      msg = this.translate.instant('DIALOG.YOU_WANT_TO_SHOW');
    }
    this.sweetAlertService.warning(msg + card.name)
      .then(res => {
        if (res.value) {
          this.cardService.enableDisable(card.id).subscribe(d => {
            card.active = !card.active;
            if (d.status === 200) {
              this.ngOnInit();
              this.showSnackBar({
                text: `${card.name}`,
                actionIcon: 'success',
                actionMsg: this.translate.instant('DIALOG.UPDATE_SUCCESS')
              });
            }
          });
        }
      }, err => {

      });
  }

  sortRows(c: string) {
    const sort = c.substr(11);
    if (this.orderType === 'ASC') {
      this.orderType = 'DESC';
    } else {
      this.orderType = 'ASC';
    }
    switch (sort) {
      case 'FIRST_NAME':
        if (this.orderType === 'ASC') {
          this.rows.sort((a, b) => a.name.localeCompare(b.name));
          return;
        }
        this.rows.sort((a, b) => b.name.localeCompare(a.name));
        break;
      case 'NUMBER':
        if (this.orderType === 'ASC') {
          this.rows.sort((a, b) => Number(a.number) - Number(b.number));
          return;
        }
        this.rows.sort((a, b) => Number(b.number) - Number(a.number));
        break;
      case 'TYPE':
        if (this.orderType === 'ASC') {
          // tslint:disable-next-line:max-line-length
          this.rows.sort((a, b) => (this.translate.instant('FIDELITY_FORM.' + a.cardType)).localeCompare((this.translate.instant('FIDELITY_FORM.' + b.cardType))));
          return;
        }
        // tslint:disable-next-line:max-line-length
        this.rows.sort((a, b) => (this.translate.instant('FIDELITY_FORM.' + b.cardType)).localeCompare((this.translate.instant('FIDELITY_FORM.' + a.cardType))));
        break;
      case 'CREATION_DATE':
        if (this.orderType === 'ASC') {
          this.rows.sort((a, b) => new Date(a.creationDate).getTime() - new Date(b.creationDate).getTime());
          return;
        }
        this.rows.sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime());
        break;
      case 'AVAILABLE':
        if (this.orderType === 'ASC') {
          this.rows.sort((a, b) => String(a.discount).localeCompare(String(b.discount)));
          return;
        }
        this.rows.sort((a, b) => String(b.discount).localeCompare(String(a.discount)));
        break;
      case 'SOLD':
        if (this.orderType === 'ASC') {
          this.rows.sort((a, b) => a.currentAmount - b.currentAmount);
          return;
        }
        this.rows.sort((a, b) => b.currentAmount - a.currentAmount);
        break;
    }

  }
}

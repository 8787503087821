import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {
  ConfigRtDto,
  DepartmentRtDto,
  ErrorCodeInterceptor,
  HeaderRtDto,
  OperatorRtDto,
  PaymentRtDto,
  VatRtDto
} from '../dto/config-rt-dto';
import {PaymentMethodsType} from '../enum/payment-methods-type';
import {PaymentResponse} from '../models/payment-response';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RtService {
  CONFIGURATION_API = environment.api + '/rt';

  constructor(private http: HttpClient) {
  }

  getRtConfig(ip) {
    const headers = new HttpHeaders({
      'content-Type': 'application/xml'
    });
    // @ts-ignore
    const options = {headers: headers, observe: 'response', responseType: 'xml'};
    const body = '<?xml version="1.0" encoding="UTF-8"?> <Service> <cmd>&lt;&lt;/?C</cmd> </Service>';
    // @ts-ignore
    return this.http.post('http://' + ip + '/service.cgi', body, options);
  }

  updateRt(ip, xml): Observable<any> {
    const headers = new HttpHeaders({
      'content-Type': 'application/xml'
    });
    // @ts-ignore
    const options = {headers: headers, observe: 'response', responseType: 'xml'};
    // @ts-ignore
    return this.http.post('http://' + ip + '/service.cgi', xml, options);
  }

  mapConfigurations(xml) {
    return this.http.post(`${this.CONFIGURATION_API}/config`, xml);
  }

  getAllConfigs() {
    return this.http.get<ConfigRtDto[]>(`${this.CONFIGURATION_API}/config`);
  }

  saveRtConfig(rtConfig: ConfigRtDto) {
    return this.http.post<ConfigRtDto>(`${this.CONFIGURATION_API}`, rtConfig);
  }

  updateRtConfig(rtConfig: ConfigRtDto) {
    return this.http.post<ConfigRtDto>(`${this.CONFIGURATION_API}/update-config`, rtConfig);
  }

  isEnabledLottery() {
    return this.http.get<boolean>(`${this.CONFIGURATION_API}/is-enabled-lottery`);
  }

  isXmlVersion7() {
    return this.http.get<boolean>(`${this.CONFIGURATION_API}/is-xml-version7`);
  }

  deleteConfig(ip) {
    return this.http.delete(`${this.CONFIGURATION_API}/` + ip, {observe: 'response'});
  }

  saveDepartment(department: DepartmentRtDto) {
    return this.http.post(`${this.CONFIGURATION_API}/config/department`, department);
  }

  saveVat(vat: VatRtDto) {
    return this.http.post(`${this.CONFIGURATION_API}/config/vat`, vat);
  }

  saveOperator(operator: OperatorRtDto) {
    return this.http.post(`${this.CONFIGURATION_API}/config/operator`, operator);
  }

  saveHeader(header: HeaderRtDto) {
    return this.http.post(`${this.CONFIGURATION_API}/config/header`, header);
  }

  savePayment(payment: PaymentRtDto) {
    return this.http.post(`${this.CONFIGURATION_API}/config/payment`, payment);
  }

  synchronizeConfig(body, id) {
    return this.http.put(`${this.CONFIGURATION_API}/sync/RCH/` + id, body);
  }

  syncDept(o: any) {
    return this.http.put(`${this.CONFIGURATION_API}/dept`, o);
  }

  syncVat(o: any) {
    return this.http.put(`${this.CONFIGURATION_API}/vat`, o);
  }

  syncOperator(o: any) {
    return this.http.put(`${this.CONFIGURATION_API}/operator`, o);
  }

  syncHeader(o: any, id) {
    return this.http.put(`${this.CONFIGURATION_API}/header/` + id, o);
  }

  syncPayment(o: any) {
    return this.http.put(`${this.CONFIGURATION_API}/payment`, o);
  }

  editStatus(id: any) {
    return this.http.get(`${this.CONFIGURATION_API}/status/` + id);
  }

  getFiscalCodeCommand() {
    return this.http.get(`${this.CONFIGURATION_API}/fiscal-code`);
  }

  getFormattedCode(xml) : Observable<string> {
    return this.http.post(`${this.CONFIGURATION_API}/formatted-code`, xml, {responseType: 'text'});
  }

  saveIvaRepartoConfig(request: { ivaCodeId: number, departmentRtId: number }[]) {
    return this.http.post(`${this.CONFIGURATION_API}/save-iva-reparto-config`, request);
  }

  buildRtTestCmd() {
    return this.http.get(`${this.CONFIGURATION_API}/test`);
  }

  getPaymentTypeConfig(rtConfigId) {
    return this.http.get<{ paymentMethodsType: PaymentMethodsType, paymentRtId: number }[]>
    (`${this.CONFIGURATION_API}/get-payment-type-config/${rtConfigId}`);
  }

  savePaymentPagamentoConfig(request: { paymentMethodsType: PaymentMethodsType, paymentRtId: number, configRtId: number }[]) {
    return this.http.post(`${this.CONFIGURATION_API}/save-payment-pagamento-config`, request, {observe: 'events'});
  }

  getResetNumberCommand(demoRt) {
    return this.http.get<any>(`${this.CONFIGURATION_API}/reset-number?demoRt=${demoRt}`);
  }

  getReceiptCommand() {
    return this.http.get<any>(`${this.CONFIGURATION_API}/receipt`);
  }

  closeCash() {
    return this.http.get(`${this.CONFIGURATION_API}/closing`);
  }

  readingCash() {
    return this.http.get(`${this.CONFIGURATION_API}/reading`);
  }

  printSpecificTicketCommand(id: number) {
    return this.http.post<any>(`${this.CONFIGURATION_API}/specific-ticket-cmd`, id);
  }

  interceptErrorCode(xml) {
    return this.http.post<ErrorCodeInterceptor>(`${this.CONFIGURATION_API}/error-interceptor`, xml);
  }

  retryPrint(paymentId: number) {
    return this.http.get<PaymentResponse>(`${this.CONFIGURATION_API}/rebuild-cmd/${paymentId}`);
  }

  retryPrintAdvance(paymentId: number) {
    return this.http.get<PaymentResponse>(`${this.CONFIGURATION_API}/rebuild-advance-cmd/${paymentId}`);
  }

  buildBookingTicket(d: number) {
    return this.http.get<{ ip: string, cmd: string }>(`${this.CONFIGURATION_API}/build-booking-ticket/${d}`);
  }

  getSalesWithoutPrint() {
    return this.http.get(`${this.CONFIGURATION_API}/pay-without-print`);
  }

  activateSalesWithoutPrint() {
    return this.http.get(`${this.CONFIGURATION_API}/enable-disable-pay-without-print`);
  }

  getManagementResetNumberCommand() {
    return this.http.get(`${this.CONFIGURATION_API}/management-reset-number`);
  }

  getReferenceCommand() {
    return this.http.get<PaymentResponse>(`${this.CONFIGURATION_API}/reference`);
  }

  formatMatricule(xml) {
    return this.http.post(`${this.CONFIGURATION_API}/format-matricule`, xml, {responseType: 'text'});
  }

  createRtCmd(cmd) {
    return this.http.post(`${this.CONFIGURATION_API}/create-rt-cmd`, cmd);
  }

  getXML7Config() {
    return this.http.get(`${this.CONFIGURATION_API}/xml7-conf`, { responseType: 'text' });
  }

  getIvaCode(ivaId: number[]) {
    return this.http.post<number[]>(`${this.CONFIGURATION_API}/iva-codes`, ivaId);
  }

  getStatusCommand() {
    return this.http.get<any>(`${this.CONFIGURATION_API}/status`);
  }

  getFirmwareCommand() {
    return this.http.get<any>(`${this.CONFIGURATION_API}/firmware`);
  }

  updateFirmwareAndRegister(rtConfig: ConfigRtDto) {
    return this.http.post<ConfigRtDto>(`${this.CONFIGURATION_API}/update-firmware-and-register-number`, rtConfig);
  }

}

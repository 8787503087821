// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*.sidebar-menu a .sidebar-item-icon {
 color:#ed105c  !important;
}*/

.footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 20px;
  color: white;
  /*background-color: #212529;*/
  background-color: var(--header-color);
}
.footer button:hover {
  color: black;
}


.options div :active, .options div :hover {
  background-color: lightgrey;
}

#sidenav {
  margin-bottom: 100px;
}
`, "",{"version":3,"sources":["webpack://./src/app/core/navigation/navigation.component.css"],"names":[],"mappings":"AAAA;;EAEE;;AAEF;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,aAAa;EACb,YAAY;EACZ,6BAA6B;EAC7B,qCAAqC;AACvC;AACA;EACE,YAAY;AACd;;;AAGA;EACE,2BAA2B;AAC7B;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":["/*.sidebar-menu a .sidebar-item-icon {\r\n color:#ed105c  !important;\r\n}*/\r\n\r\n.footer {\r\n  position: absolute;\r\n  width: 100%;\r\n  bottom: 0;\r\n  padding: 20px;\r\n  color: white;\r\n  /*background-color: #212529;*/\r\n  background-color: var(--header-color);\r\n}\r\n.footer button:hover {\r\n  color: black;\r\n}\r\n\r\n\r\n.options div :active, .options div :hover {\r\n  background-color: lightgrey;\r\n}\r\n\r\n#sidenav {\r\n  margin-bottom: 100px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CabinComponent} from './cabin.component';
import {RouterModule, Routes} from '@angular/router';
import {MatLegacyButtonModule} from '@angular/material/legacy-button';
import { MatDividerModule } from '@angular/material/divider';
import {   MatFormFieldModule } from '@angular/material/form-field';
import {   MatPaginatorModule } from '@angular/material/paginator';
import {   MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {   MatTableModule } from '@angular/material/table';
import {  MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';

import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbPaginationModule, NgbTooltipModule, NgbTypeaheadModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../../../shared/shared.module';
import {CabinFormModalComponent} from './cabin-form/cabin-form-modal.component';
import {ColorPickerModule} from 'ngx-color-picker';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {YearPlanningModule} from '../../../../shared/year-planning/year-planning.module';
import {YearPlanningListComponent} from './year-planning-list/year-planning-list.component';
import {MatIconModule} from '@angular/material/icon';


const routes: Routes = [
  {
    path: '',
    component: CabinComponent,
    children: [
      {
        path: 'cabin/:cabinId',
        component: YearPlanningListComponent
      }
    ]
  }
];

@NgModule({
    declarations: [CabinComponent, CabinFormModalComponent, YearPlanningListComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatTabsModule,
        TranslateModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatLegacyButtonModule,
        NgbPaginationModule,
        NgbTypeaheadModule,
        MatTableModule,
        MatPaginatorModule,
        SharedModule.forChild(),
        MatDividerModule,
        MatProgressSpinnerModule,
        ColorPickerModule,
        NgxMaterialTimepickerModule,
        FormsModule,
        YearPlanningModule,
        MatIconModule,
        NgbTooltipModule
    ]
})
export class CabinModule {
}

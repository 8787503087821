import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {fromEvent, Observable, Subject, Subscription} from 'rxjs';
import {catchError, take, takeUntil, tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {TranslationLoaderService} from 'src/app/core/services/translation-loader.service';
import {ComponenRegistryService} from 'src/app/core/services/component-registry.service';
import {BreadcrumbService} from 'src/app/core/services/breadcrumb.service';
import {AuthService} from '../../../../shared/services/auth-jwt.service';
import {Router} from '@angular/router';
import {UserService} from '../../../../shared/services/user.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ProfileShowFormDialogComponent} from './profile-show-form-dialog/profile-show-form-dialog.component';
import { DateAdapter } from '@angular/material/core';
import {   MatSnackBar } from '@angular/material/snack-bar';
import {SweetAlertService} from '../../../../shared/services/sweet-alert.service';
import {SellPointWebNotificationService} from "../../../../shared/services/sell-point-web-notification.service";
import {HttpErrorResponse} from "@angular/common/http";
import {DetailSellInterfaceComponent} from '../../../pages/payment/detail-sell-interface/detail-sell-interface.component';
import {DetailSellService} from '../../../pages/payment/services/detail-sell.service';
import {Form, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NgClass, NgFor, NgIf} from "@angular/common";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  imports: [NgFor, NgClass, NgIf],
  styleUrls: ['./header.component.scss'],

})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {

  sideBar: any;
  quickSidebar: any;

  breadcrumbs: any[] = [];
  subscription: Subscription;
  user: any;
  daysRemained: number;
  timeEnd;
  timeStart;
  password;
  dialogRef: any;
  detailSellForm: UntypedFormGroup;
  private unsubscribeAll: Subject<any>;
  unseenNotifications: number;
  showCadenas = false;
  isOnline: boolean = navigator.onLine;
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;

  constructor(
    private modalService: NgbModal,
    private translateService: TranslateService,
    public translationLoaderService: TranslationLoaderService,
    private componenRegistryService: ComponenRegistryService,
    private breadcrumbService: BreadcrumbService,
    private translate: TranslateService,
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private dateAdapter: DateAdapter<any>,
    private matDialog: MatDialog,
    private sweetAlertService: SweetAlertService, private snackBar: MatSnackBar,
    private notificationService: SellPointWebNotificationService,
    public detailSellService: DetailSellService,
    private formBuilder: UntypedFormBuilder,

  ) {
    this.user = userService.getUser();

    if (this.user.licenseName !== "VanityFree") {
      this.userService.getExpirationDaysFromToday().subscribe((days) => {
        this.daysRemained = days;
      })
    }

    this.unsubscribeAll = new Subject();
    this.subscription = this.breadcrumbService.getBreadcrumb().subscribe(bc => {
      if (bc) {
        this.breadcrumbs = bc;
      } else {
        // clear messages when empty message received
        this.breadcrumbs = [];
      }
    });
  }


  ngOnInit() {
    this.showCadenas = this.userService.hasAnyAuthority('GROUPP');
    this.detailSellForm = this.formBuilder.group({
      groupElements: false,
      amountAsQuantity: false
    })
    this.componenRegistryService.onRegistryChanged
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(registry => {
        this.sideBar = this.componenRegistryService.getComponent('sidenav');
        this.quickSidebar = this.componenRegistryService.getComponent('quick-sidebar');
      });
    this.notificationService.getUnseenSellPointNotification().subscribe(data => {
      this.unseenNotifications = data;
      this.pollNotificationNumber(data);
    })

    // Créez des observables pour les événements en ligne/hors ligne
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    // Abonnez-vous aux observables pour mettre à jour isOnline
    this.onlineEvent.subscribe(() => {
      this.isOnline = true;
    });

    this.offlineEvent.subscribe(() => {
      this.isOnline = false;
    });
  }

  openSearchModal(content) {
    this.modalService.open(content, {size: 'lg'});
  }

  ngAfterViewInit() {
  }

  toggleSidebar(event): void {
    event.preventDefault();
    this.sideBar.sidebarToggleHandler();
  }

  toggleQuickSidebar(event): void {
    event.preventDefault();
    this.quickSidebar.toggleOpen();
  }

  setLanguage(lang): void {
    this.translationLoaderService.setLanguage(lang);
    this.dateAdapter.setLocale(lang);
  }
  detailSellSettings(setting){


  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.unsubscribeAll.next(void 0);
    this.unsubscribeAll.complete();
  }

  logout() {
    this.authService.logout().subscribe(e => {
    });
  }

  onProfileClick() {
    this.matDialog.open(ProfileShowFormDialogComponent, {
      width: '500px',
      disableClose: true,
      data: this.user
    });
  }

  mouseDown() {
    this.timeStart = new Date();
  }

  mouseUp(content) {
    if (!this.userService.hasAnyAuthority('GROUP') && !this.userService.hasAnyAuthority('OWNER')) {
      return;
    }
    if (this.userService.hasAnyAuthority('GROUPP')) {
      return;
    }
    this.timeEnd = new Date();
    if ((this.timeEnd - this.timeStart) / 100 >= 15) {
      this.dialogRef = this.matDialog.open(content, {minWidth: 400});
      this.dialogRef.afterClosed().subscribe(d => {
        if (d) {
          this.authService.grant(d).subscribe(e => {
            window.location.reload();
            this.snackBar.open(this.translate.instant('DIALOG.UPDATE_SUCCESS'), 'Ok', {duration: 5000});
          }, error => {
            if (error.error && error.error.message === 'INVALID_CREDENTIALS') {
              this.sweetAlertService.danger(this.translate.instant('DIALOG.INVALID_PASSWORD'));
              return;
            }
            this.sweetAlertService.danger(this.translate.instant('DIALOG.INTERNAL_SERVER_ERROR'));
          });
        }
      });
      this.password = '';
    }
    this.timeEnd = null;
    this.timeStart = null;
  }

  revokeMouseUp(fromCadnsa?) {
    if (!this.userService.hasAnyAuthority('GROUPP')) {
      return;
    }
    this.timeEnd = new Date();
    if (fromCadnsa || ((this.timeEnd - this.timeStart) / 100 >= 15)) {
      this.sweetAlertService.warning(this.translate.instant('DIALOG.YOU_WANT_TO_REVOKE')).then(e => {
        if (e.value) {
          this.authService.revoke().subscribe(r => {
            window.location.reload();
            this.snackBar.open(this.translate.instant('DIALOG.UPDATE_SUCCESS'), 'Ok', {duration: 5000});
          });
        }
      });
    }
    this.timeEnd = null;
    this.timeStart = null;
  }

  private pollNotificationNumber(n) {
    this.notificationService.getUnseenSellPointNotificationsLong(n)
      .pipe(take(1)).subscribe(data => {
      if (data !== 'NO_NOTIFICATION_YET') {
        this.unseenNotifications = data as unknown as number;
      }
      this.pollNotificationNumber(this.unseenNotifications)
    }, (error: HttpErrorResponse) => {
      if (error.status === 504) {
        console.error('Reconnecting in 5s');
        setTimeout(() => {
          this.pollNotificationNumber(this.unseenNotifications)
        }, 5000);
      }
    });
  }


  showUpdateVersions(): void {
    console.log('*****************')
    this.router.navigate(['/update-version']).then();
  }

}

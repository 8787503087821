import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {MatLegacyButtonModule} from '@angular/material/legacy-button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import {   MatSnackBar } from '@angular/material/snack-bar';
import {SharedModule} from '../../../../shared/shared.module';
import {ReactiveFormsModule} from '@angular/forms';
import {NgbDropdownModule, NgbPaginationModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import { CurrencyMaskModule, CURRENCY_MASK_CONFIG } from "ng2-currency-mask";
import {TreatmentManagementComponent} from './treatment-management.component';
import {TreatmentManagementFormComponent} from './treatment-management-form/treatment-management-form.component';
import {TreatmentFormService} from './services/treatment-form.service';
import {ProdCodeMgmService} from "../../../../shared/services/prod-code-mgm.service";
import {ProductMgmService} from "../../../../shared/services/product-mgm.service";
import {SweetAlertService} from "../../../../shared/services/sweet-alert.service";
import {HttpClient} from "@angular/common/http";
import {SequenceService} from "../../../../shared/services/sequence.service";
import {ConfigurationsService} from "../../../../shared/services/configurations.service";
import {ProviderMgmService} from "../../../../shared/services/provider-mgm.service";
import {BarCodeMgmService} from "../../../../shared/services/bar-code-mgm.service";
import {MovementMgmService} from "../../../../shared/services/movement-mgm.service";
import {TreatmentMgmService} from "../../../../shared/services/treatment-mgm.service";
import {CabinService} from "../../../../shared/services/cabin.service";
import { CustomCurrencyMaskConfig } from '../product-management/product-management.module';

const routes: Routes = [
  {path: '', component: TreatmentManagementComponent}
];

@NgModule({
    declarations: [TreatmentManagementComponent, TreatmentManagementFormComponent],
    imports: [
        CommonModule,
        TranslateModule,
        MatLegacyButtonModule,
        MatIconModule,
        RouterModule.forChild(routes),
        SharedModule.forChild(),
        ReactiveFormsModule,
        NgbPaginationModule,
        MatDatepickerModule,
        NgbDropdownModule,
        CurrencyMaskModule,
        NgbTooltipModule
    ],
    providers: [
        TreatmentFormService,
        { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
        TranslateService,
        MatSnackBar,
        BarCodeMgmService,
        ProdCodeMgmService,
        MovementMgmService,
        HttpClient,
        SequenceService,
        ConfigurationsService,
        TreatmentMgmService,
        ProviderMgmService,
        CabinService,
        MatDialog,
        ProductMgmService,
        SweetAlertService,
    ]
})
export class TreatmentManagementModule { }

import {Component, OnInit} from '@angular/core';
import {UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder} from '@angular/forms';
import {SmsService} from 'src/app/shared/services/sms-service.service';
import {BreadcrumbService} from 'src/app/core/services/breadcrumb.service';
import {SweetAlertService} from 'src/app/shared/services/sweet-alert.service';
import {TranslateService} from '@ngx-translate/core';
import {takeUntil} from 'rxjs/internal/operators/takeUntil';
import {Subject} from 'rxjs';
import {SmsCampaignClient} from '../../../../shared/models/sms-campaign-client';
import {SmsCompaign} from '../../../../shared/models/sms-compaign';
import {ClientMgmService} from '../../../../shared/services/client-mgm.service';
import {Client} from '../../../../shared/models/client';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {   MatDialogRef } from '@angular/material/dialog';
import {   MatSnackBar } from '@angular/material/snack-bar';
import {BookingService} from '../../../../shared/services/booking.service';
import moment from 'moment';
import {Booking, PlanningBookingElement, PlanningBookingElementClient} from "../../../../shared/models/booking.model";

@Component({
  selector: 'app-sms-configuration',
  templateUrl: './sms-configuration.component.html',
  styleUrls: ['./sms-configuration.component.scss']
})
export class SmsConfigurationComponent implements OnInit {


  /* disableAppReminder = false;
   disableBirthReminder = false;*/
  messageSize = 153;
  loadClients = false;
  dialogRef: MatDialogRef<any, any>;
  configurationForm: UntypedFormGroup;
  enabledProxyFormControl = new UntypedFormControl();
  activeAppointmentReminderFormControl = new UntypedFormControl();
  activeBirthdayReminderFormControl = new UntypedFormControl();
  genericSmsSenderToAppointmentsFormControl = new UntypedFormControl();
  unsubscribe$: any = new Subject();
  disableSave = false;
  compaign: SmsCompaign;
  compaignForm: UntypedFormGroup;
  clients: PlanningBookingElementClient[] = [];
  bookings: PlanningBookingElement[] = [];



  constructor(private fb: UntypedFormBuilder, private smsService: SmsService, private breadcrumbService: BreadcrumbService,
              private sweetAlertService: SweetAlertService, private translateService: TranslateService,

              private clientService: ClientMgmService, private matSnackBar: MatSnackBar,
              private dialog: MatDialog, private bookingService: BookingService) {
  }

  ngOnInit() {
    this.sendBreadCrumb();
    const fromDate = new Date();
    const toDate = new Date();
    const sendCampaignAt = new Date();
    toDate.setHours(sendCampaignAt.getHours() + 1);
    const sendDate = new Date();
    toDate.setHours(sendDate.getHours() + 1);
    this.compaignForm = this.fb.group({
      id: new UntypedFormControl(),
      templateCampaign: new UntypedFormControl('', Validators.required),
      sendCampaignAt: new UntypedFormControl(sendCampaignAt, Validators.required),
      clientIds: [],
      fromDate: new UntypedFormControl(fromDate, Validators.required),
      toDate: new UntypedFormControl(toDate, Validators.required),
      status: new UntypedFormControl(),
      sendSms: new UntypedFormControl(true)
    });

    this.configurationForm = this.fb.group({
      authKey: new UntypedFormControl('', Validators.required),
      authSecret: new UntypedFormControl('', Validators.required),
      sender: new UntypedFormControl('', Validators.compose([Validators.required])),
      templateAppointment: new UntypedFormControl('', Validators.required),
      sendAppointmentBefore: new UntypedFormControl('', Validators.required),
      activeAppointmentReminder: new UntypedFormControl(true),
      genericSmsSenderToAppointments: new UntypedFormControl(true),
      templateBirthday: new UntypedFormControl('', Validators.required),
      sendDate: new UntypedFormControl(sendDate, Validators.required),
      activeBirthdayReminder: new UntypedFormControl(true),
    });

    this.activeAppointmentReminderFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      this.configurationForm.get('activeAppointmentReminder').setValue(c);
      if (!c) {
        this.configurationForm.get('templateAppointment').disable();
        this.configurationForm.get('sendAppointmentBefore').disable();
      } else {
        this.configurationForm.get('templateAppointment').enable();
        this.configurationForm.get('sendAppointmentBefore').enable();
      }
    });

    this.activeBirthdayReminderFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      this.configurationForm.get('activeBirthdayReminder').setValue(c);
      if (!c) {
        this.configurationForm.get('templateBirthday').disable();
      } else {
        this.configurationForm.get('templateBirthday').enable();
      }
    });

    this.smsService.getConfiguration().subscribe(r => {
      if (null == r) {
        this.activeBirthdayReminderFormControl.setValue(false);
        this.activeAppointmentReminderFormControl.setValue(false);
        this.genericSmsSenderToAppointmentsFormControl.setValue(false);
        return;
      }
      this.configurationForm.patchValue(r);
      this.activeAppointmentReminderFormControl.setValue(r.activeAppointmentReminder);
      this.activeBirthdayReminderFormControl.setValue(r.activeBirthdayReminder);
      this.genericSmsSenderToAppointmentsFormControl.setValue(r.genericMessage);

    });

  }

  sendBreadCrumb(): void {
    this.breadcrumbService.sendBreadcrumb(['SMS', 'CONFIGURATION']);
  }

  addDate() {
    this.configurationForm.get('templateAppointment').setValue(this.configurationForm.get('templateAppointment').value + ' {dd.MM.yyyy} ');
  }

  addTime() {
    this.configurationForm.get('templateAppointment').setValue(this.configurationForm.get('templateAppointment').value + ' {HH:mm} ');
  }
  addDateGenericAppointment() {
    // tslint:disable-next-line:max-line-length
    this.compaignForm.get('templateCampaign').setValue(this.compaignForm.get('templateCampaign').value + ' {dd.MM.yyyy} ');
  }
  addTimeGenericAppointment() {
    // tslint:disable-next-line:max-line-length
    this.compaignForm.get('templateCampaign').setValue(this.compaignForm.get('templateCampaign').value + ' {HH:mm} ');
  }

  addFirstName() {
    this.configurationForm.get('templateBirthday').setValue(this.configurationForm.get('templateBirthday').value + ' {First Name} ');
  }

  addLastName() {
    this.configurationForm.get('templateBirthday').setValue(this.configurationForm.get('templateBirthday').value + ' {Last Name} ');
  }

  save() {
    if (!this.configurationForm.valid) {
      this.sweetAlertService.notification(this.translateService.instant('DIALOG.INSERT_ALL_MANDATORY_INFORMATION')).then(e => {
      });
      return;
    }
    this.disableSave = true;
    this.smsService.addNewConfiguration(this.configurationForm.getRawValue()).subscribe(r => {
      this.sweetAlertService.success(this.translateService.instant('SMS.CONFIGURATION_SUCCESS')).then(e => {
        this.disableSave = false;
      });
    }, error => this.disableSave = false);
  }

  activateAppointmentReminder() {
    this.activeAppointmentReminderFormControl.setValue(!this.configurationForm.get('activeAppointmentReminder').value);
  }
  activateGenericMessage() {
    this.genericSmsSenderToAppointmentsFormControl.setValue(!this.configurationForm.get('genericSmsSenderToAppointments').value);
  }
  activateBirthdayReminder() {
    this.activeBirthdayReminderFormControl.setValue(!this.configurationForm.get('activeBirthdayReminder').value);
  }
  sendNow() {
    this.sweetAlertService.warning(this.translateService.instant('SMS.SEND_QUESTION'))
      .then(res => {
        if (res.value) {
            this.compaignForm.get('sendCampaignAt').clearValidators();
            this.compaignForm.get('sendCampaignAt').updateValueAndValidity();
            if (!this.compaignForm.valid) {
              this.sweetAlertService.notification(this.translateService.instant('DIALOG.INSERT_ALL_MANDATORY_INFORMATION')).then(e => {
            });
              return;
            }
            this.disableSave = true;
            this.loadClients = true;
            this.clients = [];
            const request = {
            startDate: moment(this.compaignForm.get('fromDate').value).format('YYYY-MM-DD'),
            endDate: moment(this.compaignForm.get('toDate').value).format('YYYY-MM-DD')
          };
          this.bookingService.getBookingsBetweenDates(request).subscribe(r => {
          this.clients = r.map(booking => booking.client);
          this.loadClients = false;
          this.compaignForm.get('clientIds').setValue(this.clients.map(({id}) => id));
          this.smsService.addNewCompaign(this.compaignForm.getRawValue()).subscribe(r => {
            this.matSnackBar.open(this.translateService.instant('SMS.SUCCESS'), 'Ok', {
              duration: 5000,
              panelClass: 'white-snackbar',
            });
            this.disableSave = false;
          }, e => {
            this.disableSave = false;
            this.matSnackBar.open(this.translateService.instant('SMS.FAILED'), 'Ok', {
              duration: 5000,
              panelClass: 'white-snackbar',
            });
          });
      });
        }
    });
  }


  test() {
    this.smsService.testAuth(this.configurationForm.getRawValue()).subscribe(r => {
        this.sweetAlertService.success(this.translateService.instant('SMS.KEY_VALIDATED')).then(e => {
        });
    }, e => {
      // tslint:disable-next-line:no-shadowed-variable
      this.sweetAlertService.danger(this.translateService.instant('SMS.KEY_NOT_VALIDATED')).then(e => {
      });
    });
  }

  redirect() {
    window.open('http://u86177.smstools.it/', '_blank');
  }

}

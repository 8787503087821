import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';

import {NgbDropdownModule, NgbModalModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';

import {HeaderComponent} from './header.component';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatRippleModule} from '@angular/material/core';
import {MatDividerModule} from '@angular/material/divider';
import {ProfileShowFormDialogComponent} from './profile-show-form-dialog/profile-show-form-dialog.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatLegacyDialog as MatDialog, MatLegacyDialogModule} from '@angular/material/legacy-dialog';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from "@angular/material/icon";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    NgbDropdownModule,
    NgbModalModule,
    MatDatepickerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatLegacyDialogModule,
    ReactiveFormsModule,
    MatIconModule,
    FormsModule,
    NgbTooltipModule,
    MatCheckboxModule,
  ],
  declarations: [HeaderComponent, ProfileShowFormDialogComponent],
  exports: [HeaderComponent]
})
export class HeaderModule {
}

import {Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {HttpClient} from '@angular/common/http';
import {CropImageComponent} from '../../../../../shared/crop-image/crop-image.component';
import {ClientMgmService} from '../../../../../shared/services/client-mgm.service';
import {Client, NotificationMode,} from '../../../../../shared/models/client';
import {ConfigurationsService} from '../../../../../shared/services/configurations.service';
import {Profession} from '../../../../../shared/models/profession';
import {Company} from '../../../../../shared/models/company';
import {PaymentType} from '../../../../../shared/models/payment-type';
import {HowDidYouMeetUs} from '../../../../../shared/models/how-did-you-meet-us';
import {CompanyMgmService} from '../../../../../shared/services/company-mgm.service';
import {ZipCode} from '../../../../../shared/models/zip-code';
import {ZipCodeService} from '../../../../../shared/services/zip-code.service';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {SequenceType} from '../../../../../shared/enum/sequence-type.enum';
import {SequenceService} from '../../../../../shared/services/sequence.service';
import Inputmask from 'inputmask';
import {TranslateService} from '@ngx-translate/core';
import moment from 'moment';
import {SweetAlertService} from '../../../../../shared/services/sweet-alert.service';
import {GroupConfigurationService} from '../../../../../shared/services/group-configuration.service';
import {ClientRanges} from '../../../../../shared/models/client-ranges';
import {RtService} from 'src/app/shared/services/rt.service';
import {WhatsAppDialogComponent} from '../whats-app-dialog/whats-app-dialog.component';
//import {WhatsappServiceService} from "../../../../../shared/services/whatsapp-service.service";
import {Center} from '../../../../../shared/models/center';
import {PreferredContactFormModalComponent} from '../../../configurations/preferred-contact/preferred-contact-form-modal.component';
import {ProfessionFormModalComponent} from '../../../configurations/profession/profession-form-modal.component';
import {HowDidYouMeetUsFormModalComponent} from '../../../configurations/how-did-you-meet-us/how-did-you-meet-us-form-modal.component';
import {  MatSnackBar} from '@angular/material/snack-bar';
import {WhatsappServiceService} from '../../../../../shared/services/whatsapp-service.service';
// tslint:disable-next-line:max-line-length
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@Component({
  selector: 'app-client-management-form',
  templateUrl: './client-management-form.component.html',
  styleUrls: ['./client-management-form.component.scss']
})
export class ClientManagementFormComponent implements OnInit, OnDestroy{
  @ViewChild('dateOfBirthElm', { static: false }) dateOfBirthElm: ElementRef;
  @ViewChild('companyManagementForm', { static: false }) companyManagementForm: TemplateRef<any>;
  @Output() saved = new EventEmitter<Client>();
  @Input() editMode = false;
  @Input() client: Client = new Client();
  appRef;
  center: Center;
  dialogRef: any;
  dialogComponentRef;
  dialogRefContent;
  onClose = new Subject();
  prefixList: any[] = [];
  clientForm: UntypedFormGroup;
  editClicked = false;
  submitted = false;
  submittedCompany = false;
  profilePicture: string;
  unsubscribe$ = new Subject();
  preferredContactList: any[] = [];
  prefContactPage = 1;
  professionsList: Profession[] = [];
  professionPage = 1;
  companiesList: Company[] = [];
  companyPage = 1;
  paymentTypeList: PaymentType[] = [];
  paymentTypePage = 1;
  howDidYouMeetUsList: HowDidYouMeetUs[] = [];
  howDidYouMeetUsPage = 1;
  private formBuilder = new UntypedFormBuilder();
  zipCodeList: ZipCode[] = [];
  zipCodeListCompany: ZipCode[] = [];
  zipCodeLoading = false;
  zipCodePage = 0;
  zipCodeSearch = new Subject<string>();
  zipCodeSearchStatic = '';
  countryFormControl = new UntypedFormControl();
  cityFormControl = new UntypedFormControl();
  provinceFormControl = new UntypedFormControl();
  currentSeq = '';
  disableSave = false;
  disableSaveCompany = false;
  clientInfo;
  clientRanges: ClientRanges = new ClientRanges();
  rangeModal: any;
  sendRest = false;
  confirmSend = false;
  isEnabledLottery = false;
  isWhatsAppEnabled: boolean;
  otherClients: Client[] = [];
  companyEditMode = false;
  prefContactForm = new UntypedFormControl(null, Validators.required);
  columns = [
    'DATA_TABLE.ID',
    'DATA_TABLE.FULL_NAME',
    'DATA_TABLE.EMAIL',
    'DATA_TABLE.MOBILE'
  ];
  companyForm: UntypedFormGroup;
  registeredOfficeCountryFormControl = new UntypedFormControl();
  registeredOfficeCityFormControl = new UntypedFormControl();
  registeredOfficeProvinceFormControl = new UntypedFormControl();
  zipCodeSearch1 = new Subject<string>();
  unsubscribe1$ = new Subject();
  constructor(
    private dialog: MatDialog,
    private http: HttpClient,
    private clientService: ClientMgmService,
    private configurationsService: ConfigurationsService,
    private companyService: CompanyMgmService,
    private zipCodeService: ZipCodeService,
    private translate: TranslateService, private sweetAlertService: SweetAlertService,
    private sequence: SequenceService,
    private matDialog: MatDialog, private rtService: RtService,
    private configService: GroupConfigurationService,
    private matDialogCompany: MatDialog,
    private snackBar: MatSnackBar,
    private whatsappService: WhatsappServiceService) {
  }

  ngOnInit() {
    if (this.editMode && this.client) {
      this.configService.range(this.client.id).subscribe(r => {
        this.clientRanges = r;
      });
    }
    this.checkWhatsAppExistence();
    this.rtService.isEnabledLottery().subscribe(r => this.isEnabledLottery = r);
    this.clientForm = this.formBuilder.group({
      id: new UntypedFormControl(),
      seq: new UntypedFormControl(),
      visible: new UntypedFormControl(true),
      firstName: new UntypedFormControl(null, Validators.required),
      lastName: new UntypedFormControl(null, Validators.required),
      dateOfBirth: new UntypedFormControl(null),
      fiscalCode: new UntypedFormControl(),
      lottery: new UntypedFormControl(),
      gender: new UntypedFormControl(null, Validators.required),
      mobile: new UntypedFormControl(null, [Validators.required, Validators.pattern('^[0-9]{8}[0-9]*')]),
      mobilePrefix: new UntypedFormControl('+39', [Validators.required]),
      work: new UntypedFormControl(),
      workPrefix: new UntypedFormControl('+39'),
      home: new UntypedFormControl(),
      homePrefix: new UntypedFormControl('+39'),
      email: new UntypedFormControl(null, Validators.pattern(EMAIL_REGEX)),
      preferredContactId: new UntypedFormControl(null),
      // address
      street: new UntypedFormControl(null),
      address: new UntypedFormControl(null),
      zipCodeId: new UntypedFormControl(null),
      country: new UntypedFormControl(null),
      city: new UntypedFormControl(null),
      province: new UntypedFormControl(null),
      //
      civilState: new UntypedFormControl(null),
      hasSons: new UntypedFormControl(null),
      numberOfSons: new UntypedFormControl(0, Validators.min(1)),
      professionId: new UntypedFormControl(null),
      companyId: new UntypedFormControl(null),
      // paymentTypeId: new FormControl(null),
      howDidYouMeetUsId: new UntypedFormControl(null),
      noMarketing: new UntypedFormControl(false),
      privacy: new UntypedFormControl(false),
      noAppointmentReminder: new UntypedFormControl(false),
      note: new UntypedFormControl(null),
      image: new UntypedFormControl(null),
      notificationMode: new UntypedFormControl(NotificationMode.SMS),
      smsNotify: new UntypedFormControl(false),
      mailConfirmed: new UntypedFormControl(false)
    });

    this.clientForm.valueChanges.pipe(debounceTime(300)).subscribe(() => {
      this.onFormChange();
    });
    this.clientForm.get('numberOfSons').disable();
    this.clientForm.get('zipCodeId').disable();
    this.provinceFormControl.disable();
    this.cityFormControl.disable();

    this.countryFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if (c && !this.countryFormControl.disabled) {
        this.clientForm.get('zipCodeId').enable();
        this.zipCodeLoading = true;
        this.zipCodeService.getAllZipCodesByCountry(c).subscribe(d => {
          this.zipCodeList = d;
          this.zipCodeLoading = false;
        });
      } else if (!c) {
        this.provinceFormControl.setValue(null);
        this.cityFormControl.setValue(null);
        this.clientForm.get('zipCodeId').setValue(null);
        this.clientForm.get('zipCodeId').disable();
      }
    });
    this.clientForm.get('zipCodeId').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(d => {
      this.provinceFormControl.setValue(null);
      this.cityFormControl.setValue(null);
      if (d && this.zipCodeList.length > 0) {
        const zc = this.zipCodeList.filter(e => e.id === d)[0];
        this.setProvinceAndCity(zc);
      }
    });
    this.companyService.getAllCompanies().subscribe(d => {
      this.companiesList = d;
    });
    this.getConfiguration();
    this.clientForm.get('hasSons').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(d => {
      if (d && !this.clientForm.disabled) {
        this.clientForm.get('numberOfSons').enable();
        this.clientForm.get('numberOfSons').setValue(1);
      } else if (!d) {
        this.clientForm.get('numberOfSons').disable();
        this.clientForm.get('numberOfSons').setValue(0);
      }
    });

    // get prefix list
    if (this.editMode) {

      this.profilePicture = this.client.image;
      this.clientForm.disable();
      this.countryFormControl.disable();
      this.clientForm.patchValue(this.client);
      this.whatsappService.getConfiguration().subscribe(r => {
        if (r && r.token != null){
          this.isWhatsAppEnabled = true;
          this.clientForm.get('notificationMode').enable();
        }else {
          this.isWhatsAppEnabled = false;
          this.clientForm.get('notificationMode').disable();
        }});
      this.clientForm.get('workPrefix').setValue(this.client.workPrefix || '+39');
      this.clientForm.get('homePrefix').setValue(this.client.homePrefix || '+39');
      // this.clientForm.get('preferredContactId').setValue(this.client.preferredContact ? this.client.preferredContact.id : null);
      // this.clientForm.get('howDidYouMeetUsId').setValue(this.client.howDidYouMeetUs ? this.client.howDidYouMeetUs.id : null);
      // this.clientForm.get('companyId').setValue(this.client.company ? this.client.company.id : null);
      // this.clientForm.get('professionId').setValue(this.client.profession ? this.client.profession.id : null);
      this.clientForm.get('zipCodeId').setValue(this.client.zipCode ? this.client.zipCode.id : null);
      if (this.client.zipCode) {
        this.countryFormControl.setValue(this.client.zipCode ? this.client.zipCode.country : null);
        this.zipCodeService.getAllZipCodesByCountry(this.client.zipCode.country).subscribe(d => {
          this.zipCodeList = d;
          const zc = d.filter(e => e.id === this.client.zipCode.id)[0];
          this.clientForm.get('zipCodeId').enable();
          this.clientForm.get('zipCodeId').setValue(this.client.zipCode.id);
          this.clientForm.get('zipCodeId').disable();
          this.setProvinceAndCity(zc);
        });
      }
      this.currentSeq = (this.clientForm.get('seq').value + '').padStart(4, '0');
      this.clientService.getClientInfo(this.client.id).subscribe(d => {
        this.clientInfo = d;
      });

    }

    this.http.get<any[]>('/assets/TEL_PREFIX.json').subscribe(d => this.prefixList = d.map(e => {
      e.label = `${e.country}: ${e.prefix}`;
      return e;
    }));

    if (!this.editMode) {
      this.sequence.getCurrentSequence(SequenceType.CLIENT).subscribe(res => {
        this.currentSeq = '' + (res + 1);
        this.currentSeq = this.currentSeq.padStart(4, '0');
      });

    }

    this.setDateMask();

    this.companyForm = new UntypedFormGroup({
      id: new UntypedFormControl(),
      seq: new UntypedFormControl(),
      active: new UntypedFormControl(true),
      visible: new UntypedFormControl(true),
      businessName: new UntypedFormControl(null, Validators.required),
      vatNumber: new UntypedFormControl(null, Validators.required),
      fiscalCode: new UntypedFormControl(null, Validators.required),
      codeSDI: new UntypedFormControl(null, Validators.required),
      emailPEC: new UntypedFormControl(null, Validators.pattern(EMAIL_REGEX)),
      // address 1
      registeredOfficeStreet: new UntypedFormControl(null, Validators.required),
      registeredOfficeAddress: new UntypedFormControl(null, Validators.required),
      registeredOfficeZipCodeId: new UntypedFormControl(null),
      registeredOfficeCountry: new UntypedFormControl(null),
      registeredOfficeCity: new UntypedFormControl(null),
      registeredOfficeProvince: new UntypedFormControl(null),});

    this.companyForm.get('registeredOfficeZipCodeId').disable();
    this.registeredOfficeProvinceFormControl.disable();
    this.registeredOfficeCityFormControl.disable();



    this.registeredOfficeCountryFormControl.valueChanges.pipe(takeUntil(this.unsubscribe1$)).subscribe(c => {
      if (c && !this.registeredOfficeCountryFormControl.disabled) {
        this.companyForm.get('registeredOfficeZipCodeId').enable();
        this.zipCodeService.getAllZipCodesByCountry(c).subscribe(d => {
          this.zipCodeList = d;
        });
      } else if (!c) {
        this.registeredOfficeProvinceFormControl.setValue(null);
        this.registeredOfficeCityFormControl.setValue(null);
        this.companyForm.get('registeredOfficeZipCodeId').setValue(null);
        this.companyForm.get('registeredOfficeZipCodeId').disable();
      }
    });
    this.companyForm.get('registeredOfficeZipCodeId').valueChanges.pipe(takeUntil(this.unsubscribe1$)).subscribe(d => {
      this.registeredOfficeProvinceFormControl.setValue(null);
      this.registeredOfficeCityFormControl.setValue(null);
      if (d && this.zipCodeList.length > 0) {
        const zc = this.zipCodeList.filter(e => e.id === d)[0];
        this.setProvinceAndCity1(zc);
      }
    });


  }
  setProvinceAndCity1(zc) {
    this.registeredOfficeProvinceFormControl.enable();
    this.registeredOfficeCityFormControl.enable();
    this.registeredOfficeProvinceFormControl.setValue(zc.province);
    this.registeredOfficeCityFormControl.setValue(zc.city);
    this.registeredOfficeProvinceFormControl.disable();
    this.registeredOfficeCityFormControl.disable();
  }

  alert($event: MouseEvent) {
    $event.preventDefault();
  }

  setDateMask() {
    setTimeout( () => {
      Inputmask('datetime', {
        inputFormat: 'dd/mm/yyyy',
        placeholder: 'gg/mm/aaaa'
      }).mask(this.dateOfBirthElm?.nativeElement);
    }, 500);

  }

  save(content) {
    this.submitted = true;
    if (!this.clientForm.valid) {
      this.sweetAlertService.notification(this.translate.instant('DIALOG.INSERT_ALL_MANDATORY_INFORMATION')).then(e => {
      });
      return;
    }
    this.otherClients = [];
    const request = {
      email: this.clientForm.get('email').value,
      mobile: this.clientForm.get('mobile').value,
      mobilePrefix: this.clientForm.get('mobilePrefix').value
    };
    this.clientService.getClientByMailOrPhone(request).subscribe(ps => {
      this.otherClients = ps;
      if (!ps || !ps.length) {
        this.saveClient();
        return;
      }
      if (this.editMode) {
        this.otherClients = ps.filter(u => u.id !== this.clientForm.get('id').value);
      }
      if (!this.otherClients.length) {
        this.saveClient();
        return;
      }
       this.dialogRefContent = this.dialog.open(content, {width: '50%', maxHeight: '85vh'});
      this.dialogRefContent.afterClosed().subscribe(d => {
        if (d) {
          this.saveClient();
          return;
        }
        this.disableSave = false;
      });
    });

  }

  closeContentModal() {
    this.dialogRefContent.close();
  }

  saveClient() {
    this.disableSave = true;
    if (this.clientForm.get('dateOfBirth').value) {
      this.clientForm.get('dateOfBirth').setValue(moment(this.clientForm.get('dateOfBirth').value).format('YYYY-MM-DD'));
    }
    this.clientForm.get('image').setValue(this.profilePicture);
    if (!this.editMode) {
      this.clientService.addNewClient(this.clientForm.getRawValue()).subscribe(d => {
        this.saved.emit(d);
      }, error => this.disableSave = false);
    } else {
      this.clientService.editClient(this.clientForm.getRawValue()).subscribe(d => {
        this.clientForm.get('id').setValue(d.id);
        this.saved.emit(this.clientForm.getRawValue());
      }, error => this.disableSave = false);
    }

  }

  activateEdit() {
    if (this.editMode) {
      this.editClicked = true;
      this.clientForm.enable();
      if (!this.isWhatsAppEnabled) {
        this.clientForm.get('notificationMode').disable();
      }
      if (this.client.mailConfirmed) {
        this.clientForm.get('email').disable();
      }
      this.countryFormControl.enable();
      this.client.hasSons ? this.clientForm.get('numberOfSons').enable() : this.clientForm.get('numberOfSons').disable();
    }
  }

  closeForm() {
    this.destroyForm();
  }


  destroyForm() {
     this.sweetAlertService.showConfirmationAlert()
      .then((confirmation) => {
        if (confirmation) {
          this.appRef.detachView(this.dialogComponentRef.hostView);
          this.dialogComponentRef.destroy();
        }
      });
  }

  /**
   * init configurations
   */
  getConfiguration() {
    this.configurationsService.getLazyProfessions({page: this.professionPage, pageSize: 10}).subscribe(d => {
      this.professionsList = [...this.professionsList, ...d.data];
      this.professionPage++;
    });
    this.configurationsService.getLazyPreferredContacts({page: this.prefContactPage, pageSize: 10}).subscribe(d => {
      this.preferredContactList = [...this.preferredContactList, ...d.data];
      this.prefContactPage++;
    });
    this.configurationsService.getLazyHowDidYouMeetUs({page: this.howDidYouMeetUsPage, pageSize: 10}).subscribe(d => {
      this.howDidYouMeetUsList = [...this.howDidYouMeetUsList, ...d.data];
      this.howDidYouMeetUsPage++;
    });
    // this.configurationsService.getLazyPaymentType({page: this.paymentTypePage, pageSize: 10}).subscribe(d => {
    //   this.paymentTypeList = [...this.paymentTypeList, ...d.data];
    //   this.paymentTypePage++;
    // });
  }

  /**
   * Get configurations by type infinite scroll for ng-select component
   */
  getConfigurationsByType(confType: string) {
    switch (confType) {
      case 'PROFESSION': {
        this.configurationsService.getLazyProfessions({page: this.professionPage, pageSize: 10}).subscribe(d => {
          this.professionsList = [...this.professionsList, ...d.data];
          this.professionPage++;
        });
        break;
      }
      case 'PREF_CONTACT': {
        this.configurationsService.getLazyPreferredContacts({page: this.prefContactPage, pageSize: 10}).subscribe(d => {
          this.preferredContactList = [...this.preferredContactList, ...d.data];
          this.prefContactPage++;
        });
        break;
      }
      case 'HOW_DID': {
        this.configurationsService.getLazyHowDidYouMeetUs({
          page: this.howDidYouMeetUsPage,
          pageSize: 10
        }).subscribe(d => {
          this.howDidYouMeetUsList = [...this.howDidYouMeetUsList, ...d.data];
          this.howDidYouMeetUsPage++;
        });
        break;
      }
      case 'PREF_PAYMENT': {
        this.configurationsService.getLazyPaymentType({page: this.paymentTypePage, pageSize: 10}).subscribe(d => {
          this.paymentTypeList = [...this.paymentTypeList, ...d.data];
          this.paymentTypePage++;
        });
        break;
      }

    }
  }


  /**
   * zip code setup
   */

  setProvinceAndCity(zc) {
    this.provinceFormControl.enable();
    this.cityFormControl.enable();
    this.provinceFormControl.setValue(zc.province);
    this.cityFormControl.setValue(zc.city);
    this.provinceFormControl.disable();
    this.cityFormControl.disable();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(void 0);
    this.unsubscribe$.unsubscribe();
  }

  capSearch(term: string, item: ZipCode) {
    term = term.toLowerCase();
    return item.city.toLowerCase().startsWith(term) || item.cap.toLowerCase().startsWith(term);
  }

  call($event, client: Client) {
    console.log(client);
    $event.cancelBubble = true;
    $event.stopPropagation();
    window.open(
      'https://web.whatsapp.com/send?phone=' + client.mobilePrefix + client.mobile,
      '_blank' // <- This is what makes it open in a new window.
    );
  }


  openChangeImageModal() {
    if (!this.editMode || this.editClicked) {
      const dialogRef = this.dialog.open(CropImageComponent, {width: '60%'});
      dialogRef.afterClosed().subscribe(d => {
        if (d) {
          this.profilePicture = d;
          console.log(this.profilePicture);
        }
      });
    }
  }


  showRangeHistory(rangeHistory) {
    this.rangeModal = this.matDialog.open(rangeHistory, {maxHeight: '80vh'});
  }

  sendRestPassword() {
    this.sendRest = true;
    this.clientService.sendResetPassword(this.client.id).subscribe(d => {
      this.sweetAlertService.notification(this.translate.instant('DIALOG.SUCCESS_SEND_RESET_PASSWORD'));
    }, error => {
      let msg = this.translate.instant('DIALOG.FAILED_SEND_RESET_PASSWORD');
      if (error.error.message.includes('Invalid Addresses')) {
        msg += ' : \n' + this.translate.instant('DIALOG.INVALID_MAIL');
      }
      this.sweetAlertService.danger(msg);
    });
  }

  confirmClient() {
    this.sweetAlertService.warning(
      this.translate.instant('DIALOG.CONFIRM_CLIENT') + this.client.firstName + ' ' + this.client.lastName).then(e => {
      if (e.value) {
        this.confirmSend = true;
        this.clientService.confirmClient(this.client.id).subscribe(d => {
          this.sweetAlertService.notification(this.translate.instant('DIALOG.SUCCESS_CONFIRM'));
        }, error => {
          this.sweetAlertService.danger(this.translate.instant('DIALOG.FAILED_CONFIRM'));
        });
      }
    });

  }

  checkValidLottery() {
    const c: string = this.clientForm.get('lottery').value.toUpperCase();

    if (c && (c.length !== 8 || !c.match('^[0-9]*[A-Z]*[A-Z0-9]*$'))) {
      this.sweetAlertService.danger(this.translate.instant('DIALOG.INVALID_LOTTERY'));
      this.clientForm.get('lottery').setValue('', {emitEvent: false});
      return;
    }
    if (c) {
      this.clientForm.get('lottery').setValue(c.toUpperCase(), {emitEvent: false});
    }
  }


  openWhatsAppDialog() {
    const dialogRef = this.dialog.open(WhatsAppDialogComponent, {width: '30%'});
    dialogRef.componentInstance.client = this.client;
    console.log('***', this.client);
    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  checkWhatsAppExistence() {
    this.whatsappService.getConfiguration().subscribe(r => {
      if (r && r.token != null){
      this.isWhatsAppEnabled = true;
        this.clientForm.get('notificationMode').enable();
      }else {
        this.isWhatsAppEnabled = false;
        this.clientForm.get('notificationMode').disable();
      }
      console.log("++",this.isWhatsAppEnabled)

    });

  }


  addPreferredContact() {
    if (this.clientForm.controls.id.value && !this.editClicked) {
      return;
    }
    const dialogRef = this.dialog.open(PreferredContactFormModalComponent, {
      width: '400px',
      autoFocus: true,
      disableClose: true,
      data: {editMode: false}
    });
    dialogRef.afterClosed().subscribe(d => {
      if (d) {
        this.preferredContactList = [d, ...this.preferredContactList];
        this.clientForm.get('preferredContactId').setValue(d.id);
      }
    });
  }

  addProfession() {
    const dialogRef = this.dialog.open(ProfessionFormModalComponent, {
      width: '400px',
      disableClose: true,
      data: {editMode: false}
    });
    dialogRef.afterClosed().subscribe(d => {
      if (d) {
        this.professionsList = [d, ...this.professionsList];
        this.clientForm.get('professionId').setValue(d.id);
      }
    });
  }

  addhowDidYouMeetUs() {
    const dialogRef = this.matDialog.open(HowDidYouMeetUsFormModalComponent, {
      width: '400px',
      autoFocus: true,
      disableClose: true,
      data: {editMode: false}
    });
    dialogRef.afterClosed().subscribe(d => {
      if (d) {
        this.howDidYouMeetUsList = [d, ...this.howDidYouMeetUsList];
        this.clientForm.get('howDidYouMeetUsId').setValue(d.id);

      }
    });
  }

  confirmAndClose(): void {
    if (this.companyForm.dirty) {
     this.sweetAlertService.showConfirmationAlert()
      .then((confirmation) => {
        if (confirmation) {
          this.dialogRef.close();
        }
      });}
    else{
      this.dialogRef.close();
    }
  }


  saveCompany() {
    this.submittedCompany = true;
    if (!this.companyForm.valid) {
      this.sweetAlertService.notification(this.translate.instant('DIALOG.INSERT_ALL_MANDATORY_INFORMATION')).then(e => {
      });
      return;
    }
    this.disableSaveCompany = true;
    if (!this.editMode) {
      this.companyService.addNewCompany(this.companyForm.getRawValue()).subscribe(d => {
        this.dialogRef.close(d);

      }, error => this.disableSaveCompany = false);
    }

  }
  addCompany() {
    this.dialogRef  = this.matDialogCompany.open(this.companyManagementForm, {
      width: '800px',
      maxHeight: '100%',
      autoFocus: true,
      disableClose: false,
      data: {
        editMode: false,
        fromClient: true,
      }
    });
    this.dialogRef.afterClosed().subscribe(d => {
      if (d) {
        this.companiesList = [d, ...this.companiesList];
        this.clientForm.get('companyId').setValue(d.id);
      }
    });
  }


  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.clientForm.dirty) {
      $event.returnValue = true;
    }
  }
  onFormChange() {
    this.clientService.emitFormDirtyChanged(this.clientForm.dirty);
  }
}

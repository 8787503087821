import {Injectable, NgZone} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {WebNotificationsResponse} from "../models/web-notifications/web-notifications-response";
import {Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SellPointWebNotificationService {
  private API = `${environment.api}/notification`;

  constructor(private http: HttpClient, private ngZone: NgZone) {
  }

  push(playerId) {
    return this.http.post(this.API + '/register-sellpoint', playerId);
  }

  get(request: { page, pageSize }) {
    return this.http.post<{ totalCount: number, notifications: WebNotificationsResponse[] }>(this.API + '/sellpoint', request);
  }

  changeNotificationStatus(id: number) {
    return this.http.put(this.API + '/change-status/' + id, {});
  }

  getUnseenSellPointNotification() {
    return this.http.get<number>(this.API + '/unseen-sell-point-notifications');
  }

  getUnseenSellPointNotificationsLong(n) {
    return this.http.get(this.API + '/unseen-sell-point-notifications-long/' + n, {
      responseType: "text"
    });
  }
}


import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {PromoModel} from '../models/promo-model';
import {Promotion} from '../models/promotion';
import {SearchResponse} from '../dto/search-response';
import {SmsConfiguration} from '../models/sms-configuration';
import {PromotionHistory} from '../models/promotion-history';
import {FacebookPost} from "../models/facebook-post";

@Injectable()
export class PromotionMgmService {
  PROMO_API = environment.api + '/promotions';

  constructor(private http: HttpClient) {
  }

  filter(promo) {
    return this.http.post<SearchResponse<Promotion>>(`${this.PROMO_API}/filter`, promo);
  }

  create(promo) {
    return this.http.post<Promotion>(`${this.PROMO_API}`, promo);
  }

  update(promo) {
    return this.http.put<Promotion>(`${this.PROMO_API}`, promo);
  }

  delete(id: any) {
    return this.http.delete(`${this.PROMO_API}/${id}`, {observe: 'response'});
  }

  getModels() {
    return this.http.get<PromoModel[]>(`${this.PROMO_API}/get-models`);
  }

  getSmsConfig() {
    return this.http.get<SmsConfiguration>(`${this.PROMO_API}/get-sms-config`);
  }

  saveSmsConfig(config) {
    return this.http.post<SmsConfiguration>(`${this.PROMO_API}/sms-config`, config);
  }

  annulReactivePromo(id: number) {
    return this.http.get(`${this.PROMO_API}/annul-reactive-promo/${id}`);
  }

  filterHistory(promo) {
    return this.http.post<SearchResponse<PromotionHistory>>(`${this.PROMO_API}/history-filter`, promo);
  }

  saveFacebookConfig(conf) {
    return this.http.post<any>(`${this.PROMO_API}/facebook-config`, conf);
  }

  getSocialConfig() {
    return this.http.get<any>(`${this.PROMO_API}/facebook-config`);
  }

  getFacebookPosts(request: {page, pageSize}) {
    return this.http.post<{totalRecords, data: FacebookPost[]}>(`${this.PROMO_API}/facebook-lazy`, request);
  }
}

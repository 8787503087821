import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProductCreateComponent} from './product-create.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {NgSelectModule} from '@ng-select/ng-select';
import {MatLegacyButtonModule} from '@angular/material/legacy-button';
import {MatLegacyCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatLegacyDialog as MatDialog, MatLegacyDialogModule} from '@angular/material/legacy-dialog';
import {MatIconModule} from '@angular/material/icon';
import {SharedModule} from '../shared.module';
import {CategoryProductModule} from '../../content/pages/configurations/category-product/category-product.module';
import {StockManagementModule} from '../../content/pages/stock-and-movement/stock-management/stock-management.module';
import {CurrencyMaskModule} from "ng2-currency-mask";
import {NgbDropdownModule, NgbModule, NgbPaginationModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [ProductCreateComponent],
  imports: [
    CommonModule,
    CurrencyMaskModule,
    ReactiveFormsModule,
    TranslateModule,
    NgSelectModule,
    MatLegacyButtonModule,
    MatIconModule,
    MatLegacyDialogModule,
    SharedModule.forChild(),
    ReactiveFormsModule,
    NgbPaginationModule,
    MatDatepickerModule,
    NgbDropdownModule,
    CurrencyMaskModule,
    MatLegacyCheckboxModule,
    CategoryProductModule,
    StockManagementModule,
    FormsModule,
    NgbTooltipModule,
    NgbModule,
    MatIconModule
  ], exports: [ProductCreateComponent]
})
export class ProductCreateModule {
}

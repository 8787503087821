import {ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector} from '@angular/core';
import {SubscriptionManagementFormComponent} from '../subscription-management-form/subscription-management-form.component';
import {SweetAlertService} from '../../../../../shared/services/sweet-alert.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionFormService {

  dialogComponentRef: ComponentRef<SubscriptionManagementFormComponent>;

  constructor( private componentFactoryResolver: ComponentFactoryResolver,
               private appRef: ApplicationRef,
               private injector: Injector,
               private sweetAlertService: SweetAlertService, private translate: TranslateService) { }

  openForm(container, data?: any) {
    // close form if opened
    if (this.dialogComponentRef) {
      // @ts-ignore
      if (this.dialogComponentRef.instance.update && !this.dialogComponentRef.instance.disabledSave) {
        this.sweetAlertService.warning(
          this.translate.instant('PLANNING.CONFIRM_CANCELLATION')).then(e => {
          if (e.value) {
            this.appRef.detachView(this.dialogComponentRef.hostView);
            this.dialogComponentRef.destroy();
            this.dialogComponentRef = this.createComponent(container, data);
          }
        });
      } else {
        this.appRef.detachView(this.dialogComponentRef.hostView);
        this.dialogComponentRef.destroy();
        this.dialogComponentRef = this.createComponent(container, data);
      }
    } else {
      // open form component and pass data to it
      this.dialogComponentRef = this.createComponent(container, data);
    }
    // return a ref of the opened form component
    return this.dialogComponentRef;
  }

  createComponent(container, data?: any) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(SubscriptionManagementFormComponent);
    componentFactory.create(this.injector);
    const componentRef = container.createComponent(componentFactory);
    componentRef.instance.subscription = data?.subscription;
    componentRef.instance.actionState = data?.actionState;
    componentRef.instance.appRef = this.appRef;
    componentRef.instance.dialogComponentRef = componentRef;
    return componentRef;
  }
}

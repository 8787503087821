import { Component, OnInit } from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {SweetAlertService} from '../../../../shared/services/sweet-alert.service';
import {BreadcrumbService} from '../../../../core/services/breadcrumb.service';
import {TranslateService} from '@ngx-translate/core';
import {LazyRequest} from '../../../../shared/dto/lazy-request';
import {WeekPlanningService} from '../../../../shared/services/week-planning.service';
import { WeekPlanningFormComponent } from '../../../../shared/week-template-form/week-planning-form.component';
import {WeekPlanningTemplate} from '../../../../shared/models/week-planning-template';

@Component({
  selector: 'app-week-planning-template',
  templateUrl: './week-planning-template.component.html',
  styleUrls: ['./week-planning-template.component.scss']
})
export class WeekPlanningTemplateComponent implements OnInit {

  columns = [
    'DATA_TABLE.ID',
    'DATA_TABLE.FIRST_NAME',
    'DATA_TABLE.DESCRIPTION',
  ];
  rows: WeekPlanningTemplate[] = [];
  searchFormControl = new UntypedFormControl();
  totalRecords: number;
  pageSize = 10;
  page = 1;
  unsubscribe = new Subject();
  loading = true;
  firstCall = true;
  orderType: 'ASC' | 'DESC' = 'DESC';
  constructor(private weekPlanningService: WeekPlanningService,
              private matDialog: MatDialog,
              private sweetAlertService: SweetAlertService,
              private breadcrumbService: BreadcrumbService,
              private translate: TranslateService) { }

  ngOnInit() {
    this.sendBreadCrumb();
    this.getLazyWeekPlanning({page: this.page, pageSize: this.pageSize});
    this.searchFormControl.valueChanges.pipe(debounceTime(500), takeUntil(this.unsubscribe)).subscribe(text => {
      this.getLazyWeekPlanning({page: 1, pageSize: this.pageSize, textSearch: text});
    });
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.unsubscribe();
  }

  sendBreadCrumb(): void {
    this.breadcrumbService.sendBreadcrumb(['CONFIGURATION', 'WEEK_PLANNING']);
  }

  getLazyWeekPlanning(request: LazyRequest) {
    this.loading = true;
    return this.weekPlanningService.lazyWeekPlanning(request).subscribe(data => {
      this.rows = data.data;
      this.totalRecords = data.totalRecords;
      this.loading = false;
      this.firstCall = false;
    });
  }

  openFormDialog() {
    const dialogRef = this.matDialog.open(WeekPlanningFormComponent, {
      minWidth: '200px',
      autoFocus: true,
      disableClose: true,
      data: {editMode: false}
    });
    dialogRef.afterClosed().subscribe(d => {
      if (d) {
        this.page = 1;
        this.getLazyWeekPlanning({page: 1, pageSize: this.pageSize});
      }
    });
  }

  deleteWeekPlanning(planning: WeekPlanningTemplate, i: number) {
    this.sweetAlertService.warning(this.translate.instant('DIALOG.YOU_WANT_TO_DELETE') + planning.name).then(e => {
      if (e.value) {
        this.weekPlanningService.deleteWeekPlanning(planning.id).subscribe(d => {
          if (d.status === 200) {
            this.getLazyWeekPlanning({page: this.page, pageSize: this.pageSize});
          }
        });
      }
    });
  }

  openEditWeekPlanning(plan, i: number) {
    const dialogRef = this.matDialog.open(WeekPlanningFormComponent, {
      minWidth: '200px',
      disableClose: true,
      data: {editMode: true, planning: plan}
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(d => {
      if (d) {
        this.sweetAlertService.notification(this.translate.instant('DIALOG.ATTENTION_WEEK_PLANNING_CHANGED')).then(e => {
        });
        this.getLazyWeekPlanning({page: this.page, pageSize: this.pageSize, textSearch: this.searchFormControl.value});
      }
    });
  }

  pageChange(page: number) {
    this.page = page;
    this.getLazyWeekPlanning({page: this.page, pageSize: this.pageSize, textSearch: this.searchFormControl.value});
  }

  sortRows(c: string) {
    const sort = c.substr(11);
    if (this.orderType === 'ASC') {
      this.orderType = 'DESC';
    } else {
      this.orderType = 'ASC';
    }
    switch (sort) {
      case 'ID':
        this.getLazyWeekPlanning({
          page: this.page, pageSize: this.pageSize, textSearch: this.searchFormControl.value, sort: {
            attribute: 'id',
            direction: this.orderType
          }
        });
        break;
      case 'DESCRIPTION':
        this.getLazyWeekPlanning({
          page: this.page, pageSize: this.pageSize, textSearch: this.searchFormControl.value, sort: {
            attribute: 'description',
            direction: this.orderType
          }
        });
        break;
      case 'FIRST_NAME':
        this.getLazyWeekPlanning({
          page: this.page, pageSize: this.pageSize, textSearch: this.searchFormControl.value, sort: {
            attribute: 'name',
            direction: this.orderType
          }
        });
        break;
    }
  }
}

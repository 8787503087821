import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {Client} from '../../../../../shared/models/client';
import {ClientMgmService} from '../../../../../shared/services/client-mgm.service';
import {TranslateService} from '@ngx-translate/core';
import {SweetAlertService} from '../../../../../shared/services/sweet-alert.service';
import {PaymentService} from '../../../../../shared/services/payment.service';
import {PaymentElementType} from '../../../../../shared/enum/payment-element-type';
import {ClientPurchase, PurchaseDetails} from '../../../../../shared/models/client-purchase';
import { MatLegacyTabGroup as MatTabGroup} from '@angular/material/legacy-tabs';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-purchase-history',
  templateUrl: './purchase-history.component.html',
  styleUrls: ['./purchase-history.component.scss']
})
export class PurchaseHistoryComponent implements OnInit, AfterViewInit {
  @Input() client: Client;
  @ViewChild('tabs', { static: false }) tabGroup: MatTabGroup;

  clientPurchaseForm: UntypedFormGroup;
  page = 1;
  columns = [
    'DATA_TABLE.PURCHASE_DATE',
    'DATA_TABLE.DESCRIPTION',
    'DATA_TABLE.QUANTITY',
    'DATA_TABLE.RESO_QUANTITY',
    'DATA_TABLE.DISCOUNT',
    'DATA_TABLE.AMOUNT_PAID',
    'DATA_TABLE.RESO_AMOUNT',
    'DATA_TABLE.TOTAL_IN_CASE',
  ];
  loading = true;
  rows: PurchaseDetails[] = [];
  clientPurchase: ClientPurchase = new ClientPurchase();
  public totalRecords: number;
  public pageSize = 10;
  type = PaymentElementType.PRODUCT;

  constructor(
    private clientService: ClientMgmService, private paymentService: PaymentService,
    private translate: TranslateService, private sweetAlertService: SweetAlertService) {
  }

  ngAfterViewInit(): void {
    if (this.tabGroup) {
      this.tabGroup.selectedIndex = 0;
    }
  }

  ngOnInit() {
    this.clientPurchaseForm = new UntypedFormGroup({
      firstPassage: new UntypedFormControl(),
      lastPassage: new UntypedFormControl(),
      totalProductPurchase: new UntypedFormControl(),
      totalTreatmentPurchase: new UntypedFormControl(),
      totalPurchase: new UntypedFormControl(),
      totalPurchaseDiscount: new UntypedFormControl(),
      totalProductReso: new UntypedFormControl(),
      totalTreatmentReso: new UntypedFormControl(),
      totalReso: new UntypedFormControl(),
    });
    this.search({page: this.page, pageSize: this.pageSize, clientId: this.client.id, type: this.type});
  }



  pageChange(page: number) {
    this.page = page;
    this.search({page: this.page, pageSize: this.pageSize, clientId: this.client.id, type: this.type});
  }

  search(request) {
    this.loading= true;
    this.paymentService.getClientProductPurchase(request).subscribe(r => {
      this.loading= false;
      this.rows = r.purchaseDetails.data;
      this.totalRecords = r.purchaseDetails.totalRecords;
      this.clientPurchase = r;
      //this.clientPurchaseForm.patchValue(r);
      const formattedValues = {
        firstPassage: r.firstPassage,
        lastPassage: r.lastPassage,
        totalProductPurchase: this.formatNumber(r.totalProductPurchase),
        totalTreatmentPurchase: this.formatNumber(r.totalTreatmentPurchase),
        totalPurchase: this.formatNumber(r.totalPurchase),
        totalPurchaseDiscount: this.formatNumber(r.totalPurchaseDiscount),
        totalProductReso: this.formatNumber(r.totalProductReso),
        totalTreatmentReso:this.formatNumber( r.totalTreatmentReso),
        totalReso: this.formatNumber(r.totalReso),

      };


      this.clientPurchaseForm.patchValue(formattedValues);

    });

  }

  formatNumber(value: any): any {
    if (value != null && !isNaN(value)) {

      return value.toLocaleString('it-IT', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).replace('.', ',');
    }
    return value;
  }
  changeTabs() {
    this.rows = [];
    setTimeout( () => {
      if (this.tabGroup) {
        this.type = this.tabGroup.selectedIndex === 0 ? PaymentElementType.PRODUCT : PaymentElementType.TREATMENT;
      }
    }, 300);

    this.page = 1;
    this.search({page: this.page, pageSize: this.pageSize, clientId: this.client.id, type: this.type});
  }
}

import {Component, OnInit} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {OperatorMgmComponent} from '../operator-mgm/operator-mgm.component';
import {CenterMgmFormComponent} from './center-mgm-form/center-mgm-form.component';
import {CenterService} from '../../../../../shared/services/center.service';
import {Center} from '../../../../../shared/models/center';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {Location} from '@angular/common';
import {UserService} from '../../../../../shared/services/user.service';

@Component({
  selector: 'app-center-mgm',
  templateUrl: './center-mgm.component.html',
  styleUrls: ['./center-mgm.component.scss']
})
export class CenterMgmComponent implements OnInit {
  centersList: Center[] = [];
  centerUuid;
  loading = false;
  private unsubscribe$ = new Subject();
  public isOwner: boolean;

  constructor(
    private matDialog: MatDialog,
    private centerService: CenterService,
    private router: Router,
    private location: Location,
    private userService: UserService) {
  }

  ngOnInit() {
    this.isOwner = this.userService.getUser().authorities.some(u => u === 'OWNER');
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.location.path() === '/admin') {
          this.centerService.getAllCenters().subscribe(d => {
            this.centersList = d;
            if (d.length > 0) {
              this.router.navigate(['/admin/center', d[0].id]);
            }
          });
        }
      }
    });
    this.loading = true;
    this.centerService.getAllCenters().subscribe(d => {
      this.centersList = d;
      this.loading = false;
      if (d.length > 0) {
        this.router.navigate(['/admin/center', d[0].id]);
      }
    });
  }

  openManageUsers(center) {
    this.matDialog.open(OperatorMgmComponent, {width: '1100px', data: {openedForm: 'CENTER', center}});
  }

  openAddCenterForm() {
    const dialogRef = this.matDialog.open(CenterMgmFormComponent, {
      width: '1200px',
      closeOnNavigation: true,
      disableClose: true,
      data: {editMode: false}
    });
    dialogRef.afterClosed().subscribe(d => {
      if (d) {
        this.loading = true;
        this.centerService.getAllCenters().subscribe(u => {
          this.centersList = u;
          this.loading = false;
        });
      }
    });
  }

  deleteCenter($event: MouseEvent, center) {
    $event.cancelBubble = true;
    $event.stopPropagation();
    this.centerService.deleteCenter(center.id).subscribe(d => {

    });
  }

  openEditCenter($event: MouseEvent, center) {
    $event.cancelBubble = true;
    $event.stopPropagation();
    const dialogRef = this.matDialog.open(CenterMgmFormComponent, {width: '1200px', data: {editMode: true, center}});
    dialogRef.afterClosed().subscribe(d => {
      if (d) {
        this.loading = true;
        this.centerService.getAllCenters().subscribe(u => {
          this.centersList = u;
          const url = this.router.url;
          this.router.navigate(['/admin'], {skipLocationChange: true}).then(() => {
            this.router.navigate([url]);
          });
          this.loading = false;
        });
      }
    });
  }
}

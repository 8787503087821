import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {FirstNoteCauseComponent} from './first-note-cause.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {FirstNoteCauseFormDialogComponent} from './first-note-cause-form-dialog/first-note-cause-form-dialog.component';
import {MatLegacyDialogModule} from '@angular/material/legacy-dialog';
import {ReactiveFormsModule} from '@angular/forms';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';

const routes: Routes = [
  {path: '', component: FirstNoteCauseComponent}
];

@NgModule({
  declarations: [FirstNoteCauseComponent, FirstNoteCauseFormDialogComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatButtonModule,
    MatIconModule,
    TranslateModule,
    MatLegacyDialogModule,
    ReactiveFormsModule,
    NgbTooltipModule
  ]
})
export class FirstNoteCauseModule {
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category-tabs .category-link {
  display: block;
  text-align: center;
  padding: 1.8rem 1rem;
  background-color: #fff;
  border: 1px dashed transparent;
  border-radius: 0.25rem;
  box-shadow: 0 5px 20px #d6dee4;
  color: black;
}
.category-tabs .category-link.active {
  color: #fe4d2e;
  border-color: #fe4d2e;
  background-color: #fff;
}
.category-tabs .category-link.active > i {
  color: #fe4d2e !important;
}

.list-unstyled {
  height: 300px;
  overflow-y: scroll;
}

@media screen and (max-width: 767px) {
  .category-tabs li:not(:first-child) {
    margin-top: 1rem;
  }
}
@media screen and (min-width: 992px) {
  .category-tabs li {
    margin-top: 0 !important;
  }
}
.nav-card:hover {
  cursor: pointer;
  border: 2px solid var(--secondary-color) !important;
}`, "",{"version":3,"sources":["webpack://./src/app/content/pages/dashboard/dashboard.component.scss"],"names":[],"mappings":"AACI;EACI,cAAA;EACA,kBAAA;EACA,oBAAA;EACA,sBAAA;EACA,8BAAA;EACA,sBAAA;EACA,8BAAA;EACA,YAAA;AAAR;AACQ;EACI,cAAA;EACA,qBAAA;EACA,sBAAA;AACZ;AAAY;EACI,yBAAA;AAEhB;;AAIA;EACI,aAAA;EACA,kBAAA;AADJ;;AAIA;EAEI;IACE,gBAAA;EAFJ;AACF;AAKA;EAEI;IACE,wBAAA;EAJJ;AACF;AAQA;EACE,eAAA;EACA,mDAAA;AANF","sourcesContent":[".category-tabs {\r\n    .category-link {\r\n        display: block;\r\n        text-align: center;\r\n        padding: 1.8rem 1rem;\r\n        background-color: #fff;\r\n        border: 1px dashed transparent;\r\n        border-radius: .25rem;\r\n        box-shadow: 0 5px 20px #d6dee4;\r\n        color: black;\r\n        &.active {\r\n            color: #fe4d2e;\r\n            border-color: #fe4d2e;\r\n            background-color: #fff;\r\n            > i {\r\n                color: #fe4d2e !important;\r\n            }\r\n        }\r\n    }\r\n}\r\n\r\n.list-unstyled{\r\n    height: 300px;\r\n    overflow-y: scroll;\r\n}\r\n\r\n@media screen and (max-width: 767px) {\r\n  .category-tabs {\r\n    li:not(:first-child) {\r\n      margin-top: 1rem;\r\n    }\r\n  }\r\n}\r\n@media screen and (min-width: 992px){\r\n  .category-tabs {\r\n    li{\r\n      margin-top: 0 !important;\r\n    }\r\n  }\r\n}\r\n\r\n.nav-card:hover {\r\n  cursor: pointer;\r\n  border: 2px solid var(--secondary-color) !important;\r\n  //.nav-label {\r\n  //  color:blue;\r\n  //}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {StateStorageService} from '../services/state-storage.service';
import {map} from 'rxjs/operators';
import {UserService} from '../services/user.service';
import {AuthService} from '../services/auth-jwt.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  readonly AUTHORITIES = 'module';

  constructor(private router: Router, private authService: AuthService,
              private userService: UserService,
              private stateStorageService: StateStorageService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const authorities = route.data[this.AUTHORITIES];
    return this.checkLogin(authorities, state.url);
  }

  checkLogin(authorities: string, url: string): Observable<boolean> {
    return this.userService.identity().pipe(
      map((account) => {
        /*if (!authorities || authorities.length === 0) {
          if (url === '/login') {
            this.router.navigate(['']);
          }
          return true;
        }*/
        if (
          url === '/login' ||
          url === '/sign-up' ||
          url.startsWith('/init-account') ||
          url.startsWith('/forget-password') ||
          url.startsWith('/change-password')) {
          if (account) {
            this.router.navigate(['']);
            return false;
          }
          return true;
        }
        if (account) {
          const hasAnyAuthority = this.userService.hasAnyAuthority(authorities);
          if (url === '/login' ||
            url === '/sign-up' ||
            url.startsWith('/init-account') ||
            url.startsWith('/forget-password') ||
            url.startsWith('/change-password')) {
            this.router.navigate(['']);
            return false;
          }
          if (url === '/401') {
            return true;
          }
          if (this.userService.hasAnyAuthority('SELL_POINT') && !url.includes('/admin')) {
            if (hasAnyAuthority) {
              return true;
            }
            this.router.navigate(['/401']);
            return false;
          }
          if (this.userService.hasAnyAuthority('GROUP') && url.startsWith('/admin') && !url.includes('/admin/owner')) {
            if (this.userService.hasAnyAuthority('ADMINISTRATION')) {
              return true;
            }
            this.router.navigate(['/401']);
            return false;
          }
          if (this.userService.hasAnyAuthority('OWNER') && url.startsWith('/admin/owner')) {
            return true;
          }
          this.router.navigate(['/401']);
          return false;
          /*if (account.profile === 'SUPER_ADMIN' && (url === '/admin/owner')) {
            this.router.navigate(['/admin']);
          }
          if (account.profile === 'OWNER' && url !== '/admin/owner') {
            this.router.navigate(['/admin/owner']);
          }
          if (account.profile === 'OPERATOR' && url.startsWith('/admin')) {
            this.router.navigate(['']);
          }
          // this.router.navigate(['/login']);
          return true;*/
        }
        this.authService.logout().subscribe(e => {
        });
        // this.router.navigate(['/login']);
        return false;
      }));
  }
}

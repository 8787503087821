import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  private subject = new Subject<any>();

    sendBreadcrumb(breadcrumb: string[]) {
      console.log(breadcrumb)

      this.subject.next(breadcrumb);
    }

    clearBreadcrumb() {
        this.subject.next(void 0);
    }

    getBreadcrumb(): Observable<any> {
        console.log(this.subject)
        return this.subject.asObservable();
    }
}

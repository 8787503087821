import {Component, Inject, OnInit} from '@angular/core';
import {RtService} from '../../../../../shared/services/rt.service';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {ConfigurationsService} from '../../../../../shared/services/configurations.service';
import {IvaCode} from '../../../../../shared/models/ivaCode';
import {PaymentMode} from '../../../../../shared/enum/payment-mode';
import {PaymentMethods} from '../../../../../shared/models/payment-methods';
import {PaymentMethodsType} from '../../../../../shared/enum/payment-methods-type';
import {ConfigCourtesyRtDto, PrintCourtesyRtDto} from '../../../../../shared/dto/config-courtesy-rt-dto';
import {CourtesyRtService} from '../../../../../shared/services/courtesy-rt.service';
import {TranslateService} from '@ngx-translate/core';
import {SweetAlertService} from '../../../../../shared/services/sweet-alert.service';
import {  MatSnackBar} from '@angular/material/snack-bar';
import {HttpClient} from '@angular/common/http';
import {ConfigRtDto} from '../../../../../shared/dto/config-rt-dto';
@Component({
  selector: 'app-header-footer-courtesy-rt-config',
  templateUrl: './header-footer-courtesy-rt-config.component.html',
  styleUrls: ['./header-footer-courtesy-rt-config.component.scss']
})
export class HeaderFooterCourtesyRtConfigComponent implements OnInit {
  MaxChars = 42;
  configCourtesyRtDto: ConfigCourtesyRtDto;
  receiptHeader: PrintCourtesyRtDto[];
  receiptFooter: PrintCourtesyRtDto[];
  paymentModeList = [];
  threeDottedLines: PrintCourtesyRtDto[] = [
    {
      id:null,
      text: '------------------------------------------',
      height: 1,
      width: 1,
      lineNumber:1
    },
    {
      id:null,
      text: '------------------------------------------',
      height: 1,
      width: 1,
      lineNumber:2
    },
    {
      id:null,
      text: '------------------------------------------',
      height: 1,
      width: 1,
      lineNumber:3
    }
  ];

  constructor(private courtesyRtService: CourtesyRtService, @Inject(MAT_DIALOG_DATA) public data,
              public dialogRef: MatDialogRef<HeaderFooterCourtesyRtConfigComponent>, private rtService: RtService,
              private translate: TranslateService, private snackBar: MatSnackBar,
              private sweetAlertService: SweetAlertService, private http: HttpClient) {
  }

  ngOnInit() {
    this.receiptHeader = this.data.o.header.sort((a, b) => a.lineNumber- b.lineNumber);
    this.receiptFooter = this.data.o.footer.sort((a, b) => a.lineNumber- b.lineNumber);

  }

  save() {
    this.courtesyRtService.saveHeader(this.receiptHeader).subscribe(() => {
    });
    this.courtesyRtService.saveFooter(this.receiptFooter).subscribe(() => {
      this.dialogRef.close(null);
    });
    this.snackBar.open(this.translate.instant('DIALOG.UPDATE_SUCCESS'), 'Ok', {duration: 3000});
  }

  centerTextHeader(text, i) {
    if(text !== null){
      let res = this.courtesyRtService.centerText(text, this.receiptHeader[i].width === 2 ? (this.MaxChars / 2) : this.MaxChars);
      this.receiptHeader[i].text = res;
    }else{
      console.log('text is null')
    }

  }

  centerTextFooter(text, i) {
    if(text !== null){
      let res = this.courtesyRtService.centerText(text, this.receiptFooter[i].width === 2 ? (this.MaxChars / 2) : this.MaxChars);
    this.receiptFooter[i].text = res;
  }else{
      console.log('text is null')
    }
  }

  doubleHeightTextHeader(i) {
      this.receiptHeader[i].height === 1 ? this.receiptHeader[i].height = 2 : this.receiptHeader[i].height = 1;
    }

  doubleWidthTextHeader(i) {
    this.receiptHeader[i].width === 1 ? this.receiptHeader[i].width = 2 : this.receiptHeader[i].width = 1;
  }

  doubleHeightTextFooter(i) {
    this.receiptFooter[i].height === 1 ? this.receiptFooter[i].height = 2 : this.receiptFooter[i].height = 1;
  }

  doubleWidthTextFooter(i) {
    this.receiptFooter[i].width === 1 ? this.receiptFooter[i].width = 2 : this.receiptFooter[i].width = 1;
  }

  testPrint() {
    let jsonCourtesyRtTemp = [];
    this.receiptHeader.forEach(row => {
      jsonCourtesyRtTemp.push(row);
    });
    this.threeDottedLines.forEach(row => {
      jsonCourtesyRtTemp.push(row);
    });
    this.receiptFooter.forEach(row => {
      jsonCourtesyRtTemp.push(row);
    });
    let body = {
      ip: this.data.ip,
      port: this.data.port,
      cmd: jsonCourtesyRtTemp
    }
    this.courtesyRtService.print(body).subscribe(r=>{
      //this.sweetAlertService.success(this.translate.instant('DIALOG.SUCCESS_PRINT_TEST'));
    }, error => {
      this.translate.instant('DIALOG.FAILED_EMIT_TICKET')
    });
  }

  ImportHeaderAndFooter() {
    this.rtService.getAllConfigs().subscribe((r: ConfigRtDto[]) => {
      let activeRT = r.filter(u => u.active)[0];
      for (let i = 0; i < 6; i++){
        this.receiptHeader[i].text = activeRT.header[i].description.trim();
      }
    });
  }
}

export enum TimeLaps {
  CURRENT_WEEK = 'CURRENT_WEEK',
  LAST_WEEK = 'LAST_WEEK',
  CURRENT_MONTH = 'CURRENT_MONTH',
  LAST_MONTH = 'LAST_MONTH',
  FIRST_TRIMESTER = 'FIRST_TRIMESTER',
  SECOND_TRIMESTER = 'SECOND_TRIMESTER',
  THIRD_TRIMESTER = 'THIRD_TRIMESTER',
  FORTH_TRIMESTER = 'FORTH_TRIMESTER',
  FIRST_SEMESTER = 'FIRST_SEMESTER',
  SECOND_SEMESTER = 'SECOND_SEMESTER',
  CURRENT_YEAR = 'CURRENT_YEAR',
  LAST_YEAR = 'LAST_YEAR'
}

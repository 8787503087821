// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.md-drppicker {
  font-family: "Poppins", sans-serif !important;
}

.md-drppicker .ranges ul li {
  font-family: "Poppins", sans-serif !important;
}

::ng-deep .md-drppicker {
  text-transform: capitalize !important;
  font-family: "Poppins", sans-serif !important;
}

::ng-deep .md-drppicker .btn {
  text-transform: capitalize !important;
}

.clear-class {
  min-width: 58px !important;
  display: flex !important;
  justify-content: space-around !important;
  right: 20px !important;
}

::ng-deep .md-drppicker .ranges ul li button.active {
  background-color: #2949EF !important;
}

::ng-deep .md-drppicker .btn {
  background-color: #2949EF !important;
}

::ng-deep .md-drppicker {
  min-width: 670px !important;
  left: auto !important;
  right: auto !important;
}

::ng-deep .specific-range-class .calendar {
  display: none !important;
}

.date-range-form {
  padding-right: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/compare-filter-date/compare-filter-date.component.scss"],"names":[],"mappings":"AAAA;EACE,6CAAA;AACF;;AAEA;EACE,6CAAA;AACF;;AAGA;EACE,qCAAA;EACA,6CAAA;AAAF;;AAGA;EACE,qCAAA;AAAF;;AAGA;EACE,0BAAA;EACA,wBAAA;EACA,wCAAA;EACA,sBAAA;AAAF;;AAGA;EACE,oCAAA;AAAF;;AAGA;EACE,oCAAA;AAAF;;AAGA;EACE,2BAAA;EACA,qBAAA;EACA,sBAAA;AAAF;;AAGA;EACE,wBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF","sourcesContent":[".md-drppicker {\r\n  font-family: \"Poppins\", sans-serif !important;\r\n}\r\n\r\n.md-drppicker .ranges ul li {\r\n  font-family: \"Poppins\", sans-serif !important;\r\n\r\n}\r\n\r\n::ng-deep .md-drppicker {\r\n  text-transform: capitalize !important;\r\n  font-family: \"Poppins\", sans-serif !important;\r\n}\r\n\r\n::ng-deep .md-drppicker .btn {\r\n  text-transform: capitalize !important;\r\n}\r\n\r\n.clear-class {\r\n  min-width: 58px !important;\r\n  display: flex !important;\r\n  justify-content: space-around !important;\r\n  right: 20px !important;\r\n}\r\n\r\n::ng-deep .md-drppicker .ranges ul li button.active {\r\n  background-color: #2949EF !important;\r\n}\r\n\r\n::ng-deep .md-drppicker .btn {\r\n  background-color: #2949EF !important;\r\n}\r\n\r\n::ng-deep .md-drppicker {\r\n  min-width: 670px !important;\r\n  left: auto !important;\r\n  right: auto !important;\r\n}\r\n\r\n::ng-deep .specific-range-class  .calendar{\r\n  display: none !important;\r\n}\r\n\r\n.date-range-form {\r\n  padding-right: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

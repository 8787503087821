export class Cabin {
  id: number;
  name: string;
  planningId: number;
  seq: number;
  creatAt: Date;
  visible: boolean;
  planningColor: string;
  planningPosition: number;
}

import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {  MAT_DIALOG_DATA,   MatDialog} from '@angular/material/dialog';
import {YearPlanningService} from '../../../../../shared/services/year-planning.service';
import {
  Day,
  Month,
  Week,
  WeekPlanning,
  WeekPlanningTemplate,
  Year,
  YearPlanning
} from '../../../../../shared/models/week-planning-template';
import {CabinFormModalComponent} from '../cabin-form/cabin-form-modal.component';
import {takeUntil} from 'rxjs/operators';
import {CabinService} from '../../../../../shared/services/cabin.service';
import {Cabin} from '../../../../../shared/models/cabin';
import {SweetAlertService} from '../../../../../shared/services/sweet-alert.service';
import {TranslateService} from '@ngx-translate/core';
import {WorkTime} from '../../../../../shared/models/workTime';
import {WeekPlanningService} from '../../../../../shared/services/week-planning.service';

@Component({
  selector: 'app-year-planning-list',
  templateUrl: './year-planning-list.component.html',
  styleUrls: ['./year-planning-list.component.scss']
})
export class YearPlanningListComponent implements OnInit, OnDestroy {

  unsubscribe$ = new Subject();
  planningList: YearPlanning[];
  loading = false;

  columns = [
    '',
    'DATA_TABLE.CABIN_DESCRIPTION',
    'DATA_TABLE.PLANNING_POSITION',
    'DATA_TABLE.PLANNING_COLOR',
  ];
  unsubscribe = new Subject();
  cabin: Cabin = new Cabin();
  planningToGenerate = new YearPlanning();
  months = ['PLANNING_FORM.JANUARY',
    'PLANNING_FORM.FEBRUARY',
    'PLANNING_FORM.MARCH',
    'PLANNING_FORM.APRIL',
    'PLANNING_FORM.MAY',
    'PLANNING_FORM.JUNE',
    'PLANNING_FORM.JULY',
    'PLANNING_FORM.AUGUST',
    'PLANNING_FORM.SEPTEMBER',
    'PLANNING_FORM.OCTOBER',
    'PLANNING_FORM.NOVEMBER',
    'PLANNING_FORM.DECEMBER'];
  year: Year = new Year();
  templates: WeekPlanningTemplate[];
  generatingError: { week: number; month: number }[] = [];
  tempId: number;

  constructor(
    private route: ActivatedRoute,
    private cabinService: CabinService,
    private planningService: YearPlanningService,
    private sweetAlertService: SweetAlertService,
    private matDialog: MatDialog,
    private weekPlanningService: WeekPlanningService, private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    // this.route.paramMap.subscribe(p => {
    //   this.cabinService.getCabinById(p.get('cabinId')).subscribe(c => {
    //     this.cabin = c;
    //   });
    this.cabin = this.data.cabin;
    this.planningService.getPlanningByCabinId(this.data.cabin.id).subscribe(d => {
      this.planningList = d.sort((a, b) => b.year - a.year);
    });
    this.weekPlanningService.lazyWeekPlanning({page: 1, pageSize: 1000}).subscribe(r => this.templates = r.data);
    // });
  }


  ngOnDestroy(): void {
    this.unsubscribe$.next(void 0);
    this.unsubscribe$.unsubscribe();
  }

  openAddPlanning() {
    const dialogRef = this.matDialog.open(CabinFormModalComponent, {
      width: '700px',
      maxHeight: '88vh',
      disableClose: true,
      data: {editMode: 2, cabin: this.cabin}
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(d => {
      if (d) {
        this.ngOnInit();
      }
    });
  }

  openPlanning($event: MouseEvent, plan: YearPlanning) {
    $event.cancelBubble = true;
    $event.stopPropagation();
    const dialogRef = this.matDialog.open(CabinFormModalComponent, {
      width: '700px',
      maxHeight: '88vh',
      disableClose: true,
      data: {editMode: 2, cabin: this.cabin, planning: plan}
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(d => {
      if (d) {
        this.ngOnInit();
      }
    });

  }

  deletePlanning($event: MouseEvent, planning: YearPlanning) {
    $event.cancelBubble = true;
    $event.stopPropagation();
    this.sweetAlertService.warning(this.translate.instant('DIALOG.YOU_WANT_TO_DELETE')).then(e => {
      if (e.value) {
        this.planningService.deleteYearPlanning(planning.id).subscribe(d => {
          if (d.status === 200) {
            this.ngOnInit();
          }
        });
      }
    });
  }


  generateNextPlaning($event: MouseEvent, planning: YearPlanning) {
    $event.cancelBubble = true;
    $event.stopPropagation();
    this.generatingError = [];
    this.year = new Year();
    this.planningToGenerate = Object.assign({}, planning);
    this.generateNextYearPl();
    if (this.generatingError && this.generatingError.length) {
      this.sweetAlertService.warning(this.translate.instant('DIALOG.ATTENTION_INVALID_PLANNING')).then(a => {
        if (a.value) {
          this.openPlanningForm(planning);
        } else {
          console.log('ngOnInit()');
          this.ngOnInit();
        }
      });
    } else {
      this.openPlanningForm(planning);
    }
  }
  openPlanningForm(planning: YearPlanning) {
    const dialogRef = this.matDialog.open(CabinFormModalComponent, {
      width: '700px',
      maxHeight: '88vh',
      disableClose: true,
      data: {
        editMode: false,
        cabin: this.data.cabin,
        year: this.year,
        generate: true,
        color: planning.planningColor,
        pos: planning.planningPosition,
        generatingError: this.generatingError,
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(d => {
      this.planningToGenerate = Object.assign({}, new YearPlanning());
      this.ngOnInit();
    });
  }


  generateNextYearPl() {
    let weekPl = this.planningToGenerate.weeksPlanning.sort((a, b) => {
      if (a.month === b.month) {
        return a.order - b.order;
      } else {
        return a.month - b.month;
      }
    });
    let i = 1;
    let weeks = [];
    weekPl.forEach(w => {
      let wk = new WeekPlanning();
      if ((w.month === 1 && w.order === 1) ||
        ((w.workTimeDto.mondayDayOff || w.workTimeDto.mondayMorFrom !== null) && (w.workTimeDto.sunDayOff || w.workTimeDto.sunMorFrom !== null))) {
        wk.yearOrder = i++;
        wk.month = w.month;
        wk.order = w.order;
        wk.workTimeDto = w.workTimeDto;
        wk.weekPlanningTemplateId = w.weekPlanningTemplateId;
        weeks = [...weeks, wk];
      } else if (!w.workTimeDto.sunDayOff && w.workTimeDto.sunMorFrom === null) {
        this.tempId = null;
        const nextWeek: WorkTime = this.getNextWeek(w);
        wk.yearOrder = i++;
        wk.month = w.month;
        wk.weekPlanningTemplateId = this.tempId;
        wk.order = w.order;
        wk.workTimeDto = w.workTimeDto;
        if (!w.workTimeDto.thurDayOff && w.workTimeDto.thurMorFrom === null) {
          wk.workTimeDto.thurDayOff = nextWeek.thurDayOff;
          wk.workTimeDto.thurMorFrom = nextWeek.thurMorFrom;
          wk.workTimeDto.thurMorTo = nextWeek.thurMorTo;
          wk.workTimeDto.thurWithBreak = nextWeek.thurWithBreak;
          wk.workTimeDto.thurAfterFrom = nextWeek.thurAfterFrom;
          wk.workTimeDto.thurAfterTo = nextWeek.thurAfterTo;
        }
        if (!w.workTimeDto.wedDayOff && w.workTimeDto.wedMorFrom === null) {
          wk.workTimeDto.wedDayOff = nextWeek.wedDayOff;
          wk.workTimeDto.wedMorFrom = nextWeek.wedMorFrom;
          wk.workTimeDto.wedMorTo = nextWeek.wedMorTo;
          wk.workTimeDto.wedWithBreak = nextWeek.wedWithBreak;
          wk.workTimeDto.wedAfterFrom = nextWeek.wedAfterFrom;
          wk.workTimeDto.wedAfterTo = nextWeek.wedAfterTo;
        }
        if (!w.workTimeDto.tuesDayOff && w.workTimeDto.tuesMorFrom === null) {
          wk.workTimeDto.tuesDayOff = nextWeek.tuesDayOff;
          wk.workTimeDto.tuesMorFrom = nextWeek.tuesMorFrom;
          wk.workTimeDto.tuesMorTo = nextWeek.tuesMorTo;
          wk.workTimeDto.tuesWithBreak = nextWeek.tuesWithBreak;
          wk.workTimeDto.tuesAfterFrom = nextWeek.tuesAfterFrom;
          wk.workTimeDto.tuesAfterTo = nextWeek.tuesAfterTo;
        }
        if (!w.workTimeDto.friDayOff && w.workTimeDto.friMorFrom === null) {
          wk.workTimeDto.friDayOff = nextWeek.friDayOff;
          wk.workTimeDto.friMorFrom = nextWeek.friMorFrom;
          wk.workTimeDto.friMorTo = nextWeek.friMorTo;
          wk.workTimeDto.friWithBreak = nextWeek.friWithBreak;
          wk.workTimeDto.friAfterFrom = nextWeek.friAfterFrom;
          wk.workTimeDto.friAfterTo = nextWeek.friAfterTo;
        }
        if (!w.workTimeDto.satDayOff && w.workTimeDto.satMorFrom === null) {
          wk.workTimeDto.satDayOff = nextWeek.satDayOff;
          wk.workTimeDto.satMorFrom = nextWeek.satMorFrom;
          wk.workTimeDto.satMorTo = nextWeek.satMorTo;
          wk.workTimeDto.satWithBreak = nextWeek.satWithBreak;
          wk.workTimeDto.satAfterFrom = nextWeek.satAfterFrom;
          wk.workTimeDto.satAfterTo = nextWeek.satAfterTo;
        }
        if (!w.workTimeDto.sunDayOff && w.workTimeDto.sunMorFrom === null) {
          wk.workTimeDto.sunDayOff = nextWeek.sunDayOff;
          wk.workTimeDto.sunMorFrom = nextWeek.sunMorFrom;
          wk.workTimeDto.sunMorTo = nextWeek.sunMorTo;
          wk.workTimeDto.sunWithBreak = nextWeek.sunWithBreak;
          wk.workTimeDto.sunAfterFrom = nextWeek.sunAfterFrom;
          wk.workTimeDto.sunAfterTo = nextWeek.sunAfterTo;
        }
        weeks = [...weeks, wk];
      }
    });
    this.generate(weeks);
  }

  generate(weeks) {
    let year = this.planningList[0].year;
    this.planningList.forEach(p => {
      if (p.year > year) {
        year = p.year;
      }
    });
    year++;
    this.year.name = year;
    this.year.months = [];

    this.months.forEach((m, index) => {
      const month = new Month();
      month.name = m;
      month.weeks = [];

      const monthNumber = index + 1;
      const firstOfMonth = new Date(year, monthNumber - 1, 1);
      const lastOfMonth = new Date(year, monthNumber, 0);

      let startMonthDay = Number(firstOfMonth.getDay());
      if (startMonthDay === 0) {
        startMonthDay = 7;
      }
      const used = startMonthDay + lastOfMonth.getDate();
      const weekNumber = Math.ceil(used / 7);

      let q = 0;
      for (let i = 0; i < weekNumber; i++) {
        month.weeks[i] = new Week();
        month.weeks[i].month = monthNumber;
        month.weeks[i].year = year;
        month.weeks[i].order = i + 1;
        month.weeks[i].days = [];
        for (let j = 0; j < 7; j++) {
          month.weeks[i].days[j] = new Day();
          if (i === 0) {
            month.weeks[0].days[j].number = j < startMonthDay - 1 ? null : (q += 1);
          } else if (i === weekNumber - 1) {
            month.weeks[weekNumber - 1].days[j].number = j < lastOfMonth.getDay() ? (q += 1) : null;
          } else {
            q += 1;
            month.weeks[i].days[j].number = q;
          }
        }
      }
      if (month.weeks[weekNumber - 1].days[0].number === null) {
        month.weeks.splice(weekNumber - 1, 1);
      }

      this.year.months[index] = month;
    });

    this.getWeek(weeks);
  }

  getWeek(weeks: WeekPlanning[]) {
    let i = 0;
    this.year.months.forEach(m => {
      m.weeks.forEach(w => {
        w.workTimeDto = new WorkTime();
        if (w.days[0].number != null) {
          w.workTimeDto.mondayDayOff = weeks[i].workTimeDto.mondayDayOff;
          w.workTimeDto.mondayMorFrom = weeks[i].workTimeDto.mondayMorFrom;
          w.workTimeDto.mondayMorTo = weeks[i].workTimeDto.mondayMorTo;
          w.workTimeDto.mondayWithBreak = weeks[i].workTimeDto.mondayWithBreak;
          w.workTimeDto.mondayAfterFrom = weeks[i].workTimeDto.mondayAfterFrom;
          w.workTimeDto.mondayAfterTo = weeks[i].workTimeDto.mondayAfterTo;
        }


        if (w.days[1].number != null) {
          w.workTimeDto.tuesDayOff = weeks[i].workTimeDto.tuesDayOff;
          w.workTimeDto.tuesMorFrom = weeks[i].workTimeDto.tuesMorFrom;
          w.workTimeDto.tuesMorTo = weeks[i].workTimeDto.tuesMorTo;
          w.workTimeDto.tuesWithBreak = weeks[i].workTimeDto.tuesWithBreak;
          w.workTimeDto.tuesAfterFrom = weeks[i].workTimeDto.tuesAfterFrom;
          w.workTimeDto.tuesAfterTo = weeks[i].workTimeDto.tuesAfterTo;
        }


        if (w.days[2].number != null) {
          w.workTimeDto.wedDayOff = weeks[i].workTimeDto.wedDayOff;
          w.workTimeDto.wedMorFrom = weeks[i].workTimeDto.wedMorFrom;
          w.workTimeDto.wedMorTo = weeks[i].workTimeDto.wedMorTo;
          w.workTimeDto.wedWithBreak = weeks[i].workTimeDto.wedWithBreak;
          w.workTimeDto.wedAfterFrom = weeks[i].workTimeDto.wedAfterFrom;
          w.workTimeDto.wedAfterTo = weeks[i].workTimeDto.wedAfterTo;
        }


        if (w.days[3].number != null) {
          w.workTimeDto.thurDayOff = weeks[i].workTimeDto.thurDayOff;
          w.workTimeDto.thurMorFrom = weeks[i].workTimeDto.thurMorFrom;
          w.workTimeDto.thurMorTo = weeks[i].workTimeDto.thurMorTo;
          w.workTimeDto.thurWithBreak = weeks[i].workTimeDto.thurWithBreak;
          w.workTimeDto.thurAfterFrom = weeks[i].workTimeDto.thurAfterFrom;
          w.workTimeDto.thurAfterTo = weeks[i].workTimeDto.thurAfterTo;
        }


        if (w.days[4].number != null) {
          w.workTimeDto.friDayOff = weeks[i].workTimeDto.friDayOff;
          w.workTimeDto.friMorFrom = weeks[i].workTimeDto.friMorFrom;
          w.workTimeDto.friMorTo = weeks[i].workTimeDto.friMorTo;
          w.workTimeDto.friWithBreak = weeks[i].workTimeDto.friWithBreak;
          w.workTimeDto.friAfterFrom = weeks[i].workTimeDto.friAfterFrom;
          w.workTimeDto.friAfterTo = weeks[i].workTimeDto.friAfterTo;
        }

        if (w.days[5].number != null) {
          w.workTimeDto.satDayOff = weeks[i].workTimeDto.satDayOff;
          w.workTimeDto.satMorFrom = weeks[i].workTimeDto.satMorFrom;
          w.workTimeDto.satMorTo = weeks[i].workTimeDto.satMorTo;
          w.workTimeDto.satWithBreak = weeks[i].workTimeDto.satWithBreak;
          w.workTimeDto.satAfterFrom = weeks[i].workTimeDto.satAfterFrom;
          w.workTimeDto.satAfterTo = weeks[i].workTimeDto.satAfterTo;
        }

        if (w.days[6].number != null) {
          w.workTimeDto.sunDayOff = weeks[i].workTimeDto.sunDayOff;
          w.workTimeDto.sunMorFrom = weeks[i].workTimeDto.sunMorFrom;
          w.workTimeDto.sunMorTo = weeks[i].workTimeDto.sunMorTo;
          w.workTimeDto.sunWithBreak = weeks[i].workTimeDto.sunWithBreak;
          w.workTimeDto.sunAfterFrom = weeks[i].workTimeDto.sunAfterFrom;
          w.workTimeDto.sunAfterTo = weeks[i].workTimeDto.sunAfterTo;
          i++;
        }
      });
    });
  }

  getNextWeek(week: WeekPlanning) {
    if (week.month < 12) {
      const wPl = this.planningToGenerate.weeksPlanning.filter(u => u.month === (week.month + 1) && u.order === 1).shift();
      this.tempId = wPl.weekPlanningTemplateId;
      return Object.assign({}, wPl.workTimeDto);
    }
    const temp = this.templates.filter(u => u.id === week.weekPlanningTemplateId).shift();
    if (temp) {
      this.tempId = temp.id;
      return temp.workTimeDto;
    }
    this.generatingError = [...this.generatingError, {month: week.month, week: week.order}];
    return new WorkTime();
  }

}

import {DateTimeIntl } from 'nxt-pick-datetime';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable()
export class DefaultIntl extends DateTimeIntl {
  public cancelBtnLabel = 'Annulla';
  public setBtnLabel = 'Conferma';
  private currentLang: string;

  constructor(private translate: TranslateService) {
    super();
    this.getLang();

    this.translate.onLangChange.subscribe(lang => {
      this.getLang();
    });
  }

  public getLang() {
    this.currentLang = this.translate.currentLang;

    switch (this.currentLang) {
      case 'en':
        this.cancelBtnLabel = 'Cancel';
        this.setBtnLabel = 'Set';
        break;
      case 'it':
        this.cancelBtnLabel = 'Annulla';
        this.setBtnLabel = 'Conferma';
        break;
      case 'fr':
        this.cancelBtnLabel = 'Annuler';
        this.setBtnLabel = 'Confirmer';
        break;
    }
  }
};

import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {SweetAlertService} from '../../../../../shared/services/sweet-alert.service';
import {TranslateService} from '@ngx-translate/core';
import {OwnerService} from '../../services/owner.service';
import {ThemeColors} from "../../../../../shared/models/ThemeColors";

@Component({
  selector: 'app-mail-config',
  templateUrl: './mail-config.component.html',
  styleUrls: ['./mail-config.component.scss']
})
export class MailConfigComponent implements OnInit {
  configurationForm: UntypedFormGroup;
  updateForm:UntypedFormGroup;
  disableSave = true;
  disableSaveUpdate = true;
  mainColor = '';


  themeColors: ThemeColors;
  main_Color = '#1d1d1d';
  secondaryColor: any = '#f663b2';
  successIconsColor: any = '#000000';
  cancelIconsColor: any = '#cb037b';
  buttonSuccessColor: any = '#000000';
  buttonErrorsColor: any = '#D3D3D3';
  headerColor: any = 'rgba(75,75,75,0.97)';
  headerColorValue: any = 'rgba(37,37,37,0.95)';
  subHeaderColor: any;
  subHeaderColorValue: any = '#F9F9F9';
  footerColor: any;
  footerColorValue: any = '#F9F9F9';
  subFooterColor: any;
  subFooterColorValue: any = '#F9F9F9';
  sideBarColor: any = '#1d1d1d';
  checkBoxColor: any = '#b9b9b9';
  radioButtonColor:any ='#b9b9b9';
  constructor(private fb: UntypedFormBuilder, private ownerService: OwnerService,
              private sweetAlertService: SweetAlertService, private translateService: TranslateService) {
  }

  ngOnInit() {

    // MailConfig Init
    this.configurationForm = this.fb.group({
      id: new UntypedFormControl(null),
      host: new UntypedFormControl('', Validators.required),
      username: new UntypedFormControl('', Validators.required),
      password: new UntypedFormControl('', Validators.required),
      port: new UntypedFormControl('', Validators.required),
      protocol: new UntypedFormControl('', Validators.required),
      smtpAuth: new UntypedFormControl(true),
      smtpStartTls: new UntypedFormControl(true),
    });

    this.getMailConfig();

    // SuperHeader Init
    this.updateForm= this.fb.group({
      id: new UntypedFormControl(null),
      active:new UntypedFormControl(true),
      color:new UntypedFormControl(this.mainColor),
      description: new UntypedFormControl('', Validators.required),
    });

    this.getSuperHeaderConfig();
    this.getThemeColors();
  }

  getMailConfig() {
    this.ownerService.getMailConfig().subscribe(r => {
      this.disableSave = false;
      this.configurationForm.patchValue(r);
    }, error => this.disableSave = false);
  }
  getSuperHeaderConfig() {
    this.ownerService.getSuperHeader().subscribe(r => {
      this.disableSaveUpdate = false;
      this.updateForm.patchValue(r);
      this.mainColor = r.color ? r.color : '#F24839';
      console.log('----',r);
    }, error => this.disableSaveUpdate = false);
  }

  save() {
    if (!this.configurationForm.valid) {
      this.sweetAlertService.notification(this.translateService.instant('DIALOG.INSERT_ALL_MANDATORY_INFORMATION')).then(e => {
      });
      return;
    }
    this.disableSave = true;
    this.ownerService.updateMailConfig(this.configurationForm.getRawValue()).subscribe(r => {
      this.sweetAlertService.success(this.translateService.instant('SMS.CONFIGURATION_SUCCESS')).then(e => {
        this.disableSave = false;
      });
    }, error => this.disableSave = false);
  }

  saveSuperHeader(){
    if (!this.updateForm.valid) {
      this.sweetAlertService.notification(this.translateService.instant('DIALOG.INSERT_ALL_MANDATORY_INFORMATION')).then(e => {
      });
      return;
    }
    this.disableSaveUpdate = true;
    this.updateForm.patchValue({
      color: this.mainColor
    });
    this.ownerService.updateSuperHeader(this.updateForm.getRawValue()).subscribe(r => {
      this.sweetAlertService.success(this.translateService.instant('SMS.CONFIGURATION_SUCCESS')).then(e => {
        this.disableSaveUpdate = false;
        this.getSuperHeaderConfig();
      });
    }, error => this.disableSaveUpdate = false);
  }


  saveTheme() {
    const request = {
      primaryColor: this.main_Color,
      secondaryColor: this.secondaryColor,
      headerColor: this.headerColor === 'COLOR' ? this.headerColorValue : this.headerColor,
      buttonErrorsColor: this.buttonErrorsColor,
      buttonSuccessColor: this.buttonSuccessColor,
      successIconsColor: this.successIconsColor,
      cancelIconsColor: this.cancelIconsColor,
      sideBarColor: this.sideBarColor,
      checkBoxColor: this.checkBoxColor,
      radioButtonColor: this.radioButtonColor

    };

    this.ownerService.updateOwnerColors(request).subscribe(() => {
      this.sweetAlertService.success(this.translateService.instant('MULTIMEDIA.THEME_UPDATED')).then(e => {
        this.getThemeColors();
      });

    });
  }

  getThemeColors() {
    this.ownerService.getCurrentOwnerColors().subscribe((res) => {
      this.themeColors = res;
      this.main_Color = res.primaryColor;
      this.secondaryColor = res.secondaryColor;
      if (res.buttonSuccessColor) {
        this.buttonSuccessColor = res.buttonSuccessColor;
      }
      if (res.sideBarColor) {
        this.sideBarColor = res.sideBarColor;
      }
      if (res.cancelIconsColor) {
        this.cancelIconsColor = res.cancelIconsColor;
      }
      if (res.successIconsColor) {
        this.successIconsColor = res.successIconsColor;
      }
      if (res.buttonErrorsColor) {
        this.buttonErrorsColor = res.buttonErrorsColor;
      }

      if (res.checkBoxColor) {
        this.checkBoxColor = res.checkBoxColor;
      }
      if (res.radioButtonColor) {
        this.radioButtonColor = res.radioButtonColor;
      }
      if (res.headerColor === 'DARK' || res.headerColor === 'LIGHT') {
        this.headerColor = res.headerColor;
      } else {
        this.headerColor = 'COLOR';
        this.headerColorValue = res.headerColor;
      }

      if (res.subHeaderColor === 'DARK' || res.subHeaderColor === 'LIGHT') {
        this.subHeaderColor = res.subHeaderColor;
      } else {
        this.subHeaderColor = 'COLOR';
        this.subHeaderColorValue = res.subHeaderColor;
      }

      if (res.footerColor === 'DARK' || res.footerColor === 'LIGHT') {
        this.footerColor = res.footerColor;
      } else {
        this.footerColor = 'COLOR';
        this.footerColorValue = res.footerColor;
      }

      if (res.subFooterColor === 'DARK' || res.subFooterColor === 'LIGHT') {
        this.subFooterColor = res.subFooterColor;
      } else {
        this.subFooterColor = 'COLOR';
        this.subFooterColorValue = res.subFooterColor;
      }
    });
  }


}

import {Component, Inject, OnInit} from '@angular/core';
import moment from 'moment';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {FirstNoteService} from '../../../../../shared/services/first-note.service';
import {FirstNoteCauseService} from '../../../../../shared/services/first-note-cause.service';
import {FirstNoteCause} from '../../../../../shared/models/first-note';

@Component({
  selector: 'app-first-note-form',
  templateUrl: './first-note-form.component.html',
  styleUrls: ['./first-note-form.component.scss']
})
export class FirstNoteFormComponent implements OnInit {
  today = moment().format('DD/MM/YYYY');
  firstNoteForm: UntypedFormGroup;
  firstNoteCauses: FirstNoteCause[];
  constructor(
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: {type: 'INCOME' | 'OUTLAY'},
    private firstNoteService: FirstNoteService,
    private firstNoteCauseService: FirstNoteCauseService,
    private matDialogRef: MatDialogRef<FirstNoteFormComponent>) {
  }

  ngOnInit() {
    this.firstNoteForm = this.fb.group({
      description: null,
      amount: [null, Validators.required],
      noteType: this.data.type,
      causeId: [null, Validators.required]
    });
    this.firstNoteCauseService.getAll().subscribe(r => {
      this.firstNoteCauses = r;
    });
  }

  save() {
    if (this.firstNoteForm.invalid) {
      return;
    }
    this.firstNoteService.addFirstNote(this.firstNoteForm.value).subscribe(() => {
      this.matDialogRef.close(true);
    });
  }
}

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {   MatSnackBar } from '@angular/material/snack-bar';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FidelityService} from '../../../../../../shared/services/fidelity.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-recharge-form',
  templateUrl: './recharge-form.component.html',
  styleUrls: ['./recharge-form.component.scss']
})
export class RechargeFormComponent implements OnInit {
  submitted = false;
  fidelityForm: UntypedFormGroup;
  private formBuilder = new UntypedFormBuilder();

  constructor(public dialogRef: MatDialogRef<RechargeFormComponent>, private cardService: FidelityService,
              private translate: TranslateService, private snackBar: MatSnackBar, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.fidelityForm = this.formBuilder.group({
      id: new UntypedFormControl(),
      name: new UntypedFormControl(null, Validators.required),
      value: new UntypedFormControl(null, Validators.required),
      discount: new UntypedFormControl(null, Validators.required),
      fidelityCardId: new UntypedFormControl(null),
    });
    if (this.data.reload) {
      this.fidelityForm.patchValue(this.data.reload);
    }
  }

  save() {
    this.submitted = true;
    if (!this.fidelityForm.valid) {
      return;
    }
    if (!this.data.card.id) {
      this.fidelityForm.get('name').disable();
      this.cardService.create(this.data.card).subscribe(r => {
        this.fidelityForm.get('fidelityCardId').setValue(r.id);
        this.saveReload();
        return;
      });
    } else {
      this.fidelityForm.get('fidelityCardId').setValue(this.data.card.id);
      this.saveReload();
    }

  }

  saveReload() {
    this.fidelityForm.get('name').disable();
    if (this.fidelityForm.get('id').value) {
      this.cardService.updateReload(this.fidelityForm.getRawValue()).subscribe(d => {
        this.snackBar.open(this.translate.instant('DIALOG.ADD_SUCCESS'), 'Ok', {duration: 5000, panelClass: 'white-snackbar'});
        this.dialogRef.close(this.fidelityForm.get('fidelityCardId').value);
      }, (err) => {
        this.failedSave();
      }, () => {
        this.submitted = false;
        this.fidelityForm.get('name').enable();
        this.dialogRef.close(this.fidelityForm.get('fidelityCardId').value);
      });
      return;
    }
    this.cardService.createReload(this.fidelityForm.getRawValue()).subscribe(d => {
      this.snackBar.open(this.translate.instant('DIALOG.ADD_SUCCESS'), 'Ok', {duration: 5000, panelClass: 'white-snackbar'});
      this.dialogRef.close(this.fidelityForm.get('fidelityCardId').value);
    }, (err) => {
      this.failedSave();
    }, () => {
      this.submitted = false;
      this.fidelityForm.get('name').enable();
      this.dialogRef.close(this.fidelityForm.get('fidelityCardId').value);
    });
  }

  failedSave() {
    this.submitted = false;
    this.fidelityForm.get('name').enable();
    this.fidelityForm.get('value').enable();
    this.fidelityForm.get('discount').enable();
    this.snackBar.open(this.translate.instant('DIALOG.INTERNAL_SERVER_ERROR'), 'Ok', {duration: 5000});
  }

}

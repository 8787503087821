import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  private readonly API = environment.api + '/image';

  constructor(private http: HttpClient) { }

  uploadUnlayerImage(request) {
    return this.http.post<{url: string}>(this.API + '/unlayer-upload', request,
      {reportProgress: true, observe: 'events'});
  }

}

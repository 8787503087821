import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {MobileConfiguration} from '../models/mobile-configuration';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MobileAppConfigService {
  private API = environment.api + '/mobile-app-config';
  private reload = new Subject<any>();

  constructor(private http: HttpClient) {
  }

  update(request) {
    return this.http.post<void>(this.API, request);
  }

  addImage(request) {
    return this.http.post(this.API + '/image', request, {
      reportProgress: true,
      observe: 'events'
    });
  }

  updateImage(request) {
    return this.http.put(this.API + '/image', request, {
      reportProgress: true,
      observe: 'events'
    });
  }

  deleteImage(id) {
    return this.http.delete<void>(this.API + '/image/' + id);
  }

  getConfiguration() {
    return this.http.get<MobileConfiguration>(this.API);
  }

  loadImage(id: number) {
    return this.http.get(this.API + '/image/' + id, {responseType: 'arraybuffer', observe: "events", reportProgress: true});
  }

  goReload() {
    this.reload.next(true);
  }

  printEan(request) {
    return this.http.post(`${environment.api}/products/print-products-ticket`, request, {observe: 'response', responseType: 'blob'});
  }

  getReloadObs() {
    return this.reload.asObservable();
  }
}

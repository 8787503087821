export enum FidelityCardMovementType {
  ASSOCIATION = 'ASSOCIATION',
  GAIN = 'GAIN',
  CONSUMPTION = 'CONSUMPTION',
  MANUAL_ADD = 'MANUAL_ADD',
  MANUAL_SUBTRACT = 'MANUAL_SUBTRACT',
  PRODUCT_SELL = 'PRODUCT_SELL',
  TREATMENT_SELL = 'TREATMENT_SELL',
  ABONMENT_SELL = 'ABONMENT_SELL',
  RELOAD = 'RELOAD',

}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {Group, GroupWithSuperAdmins} from '../../../../shared/models/group';
import {Operator} from '../../../../shared/models/operator';
import {SuperAdmin} from '../../../../shared/models/super-admin';
import {AuthResponse} from '../../../../shared/models/auth-response';
import {map} from 'rxjs/operators';
import {MailConfig} from '../../../../shared/models/mail-config';
import {ThemeColors} from "../../../../shared/models/ThemeColors";
import {BehaviorSubject} from "rxjs";

@Injectable(
  {providedIn: 'root'}
)
export class OwnerService {

  readonly API = environment.api;
  public colorSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  constructor(private http: HttpClient) {
  }

  getAllGroups(request) {
    return this.http.post<GroupWithSuperAdmins[]>(`${this.API}/groups/filter`, request);
  }

  getSuperAdminsByGroup(groupId) {
    return this.http.get<Operator[]>(`${this.API}/groups/${groupId}/super-admins`);
  }

  getGroupById(groupId: any) {
    return this.http.get<Group>(`${this.API}/groups/${groupId}`);
  }

  setGroupAuthorities(request: { groupId: number, features: string[] }) {
    return this.http.post<Group>(`${this.API}/groups/authorities`, request);
  }

  addGroup(request) {
    return this.http.post<Group>(`${this.API}/groups/`, request);
  }

  getAllSuperAdmins() {
    return this.http.get<SuperAdmin[]>(`${this.API}/super-admins`);
  }

  getAvailableSuperAdmin(groupId) {
    return this.http.get<SuperAdmin[]>(`${this.API}/super-admins/available/${groupId}`);
  }

  addOrUpdateSuperAdmin(request) {
    return this.http.post<any>(`${this.API}/super-admins`, request);
  }

  deleteSuperAdmin(id) {
    return this.http.delete(`${this.API}/super-admins/${id}`);
  }

  linkSuperAdminsToGroup(groupId: number, ids: number[]) {
    return this.http.post<any>(`${this.API}/super-admins/link/${groupId}`, ids);
  }

  deleteGroup(id: number) {
    return this.http.delete<any>(`${this.API}/groups/${id}`);
  }

  deleteGroupDefinitely(id: number) {
    return this.http.delete<any>(`${this.API}/groups/definitely/${id}`);
  }

  updateGroup(request) {
    return this.http.put<any>(`${this.API}/groups`, request);
  }

  toggleGroupActivation(id: number) {
    return this.http.put<any>(`${this.API}/groups/toggle/${id}`, {});
  }

  chooseGroup(id: number) {
    return this.http.post<AuthResponse>(`${this.API}/auth/group`, id);
  }

  editGroupValidationDate(request: any) {
    return this.http.put<any>(`${this.API}/groups/date`, request);
  }

  exportGroupsCSV(request: any) {
    return this.http.post(`${this.API}/groups/export-csv`, request, {responseType: 'blob', observe: 'response'});
  }

  getCurrentGroupLicenseModules() {
    return this.http.get<any>(`${this.API}/groups/current-license-config`).pipe(map(el => {
      return el.authorities.filter(u => u !== 'ADMINISTRATION');
    }));
  }

  generateQrCode() {
    return this.http.get(`${this.API}/groups/generate-qr-code`);
  }

  updateMailConfig(config) {
    return this.http.post(`${this.API}/mail-configuration`, config);
  }

  getMailConfig() {
    return this.http.get<MailConfig>(`${this.API}/mail-configuration`);
  }

  updateSuperHeader(config) {
    return this.http.post(`${this.API}/superHeader-configuration`, config);
  }
  getSuperHeader() {
    return this.http.get<any>(`${this.API}/superHeader-configuration`);
  }

  updateOwnerColors(request) {
    return this.http.post(this.API + '/theme-owner/colors', request);
  }

  getCurrentOwnerColors() {
    return this.http.get<any>(this.API + '/theme-owner/get-colors').pipe(map((r) => {
      if (r !== null){
        this.colorSubject.next(r);
      }
      return r;
    }));
  }

}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  height: 100%;
  background-color: #eff4ff;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-wrapper {
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-content {
  max-width: 540px;
  flex-basis: 540px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/content/pages/error-401/error401.component.scss"],"names":[],"mappings":"AAAA;EACC,YAAA;EACG,yBAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACJ;;AAEA;EACI,cAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,iBAAA;EACA,kBAAA;AACJ","sourcesContent":[":host {\r\n\theight: 100%;\r\n    background-color: #eff4ff;\r\n    flex: 1 0 auto;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\n.box-wrapper {\r\n    flex: 1 0 auto;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center\r\n}\r\n\r\n.error-content {\r\n    max-width: 540px;\r\n    flex-basis: 540px;\r\n    text-align: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import {Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Category} from '../models/category';
import {TranslateService} from '@ngx-translate/core';
import {ConfigurationsService} from '../services/configurations.service';
import moment from 'moment';
import {Operator} from '../models/operator';
import {Client} from '../models/client';
import {ClientMgmService} from '../services/client-mgm.service';
import {debounceTime} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {OperatorMgmService} from '../services/operator-mgm.service';
import {TimeLaps} from '../enum/time-laps';

@Component({
  selector: 'app-generic-filter',
  templateUrl: './generic-filter.component.html',
  styleUrls: ['./generic-filter.component.scss']
})
export class GenericFilterComponent implements OnInit {
  @Input() textFilter = false;
  @Input() visible = false;
  @Input() confirmed = false;
  @Input() activeDetailSale = false;
  @Input() firstRangeCompare = false;
  @Input() secondRangeCompare = false;
  @Input() category = false;
  @Input() subCategory = false;
  @Input() type;
  @Input() compareRanges = false;
  @Input() client = false;
  @Input() displayOperator = false;
  @Input() displayProduct = false;
  @Input() displayTreatment = false;
  @Input() displayTimeLaps = false;
  @ViewChild('dateFromElm') dateFromElm: ElementRef;
  @ViewChild('dateRangeElm') dateRangeElm: ElementRef;

  page = 1;
  searchFormControl: UntypedFormGroup;
  public pageSize = 10;
  firstRange: { start: Date, end: Date };
  secondRange: { start: Date, end: Date };
  withFirstRange = false;
  withSecondRange = false;

  clients: Client[] = [];

  categoriesList: Category[] = [];
  subCategoriesList: any[] = [];
  categoriesPage = 1;
  filterFormGroup: UntypedFormGroup;
  operatorList: Operator[];
  clientSearch$ = new Subject<string>();
   request;
  timeLaps = [];

  @Output() sendRequest: EventEmitter<any> = new EventEmitter();

  constructor(private translate: TranslateService, private configurationsService: ConfigurationsService,
              private fb: UntypedFormBuilder,  private clientService: ClientMgmService, private operatorService: OperatorMgmService
  ) {
  }

  ngOnInit() {
    this.resetSearchForm();
    this.getCategories();
    this.getClients();
    this.getSubCategories();
    this.clientSearch$.pipe(debounceTime(500)).subscribe(term => {
      if (!term) {
        this.getClients();
        return;
      }
      if (term.length > 1) {
        this.getClients(term);
      }
    });
    this.sendFilter();
    this.operatorService.getAllOperatorsBySpId().subscribe(d => {
      this.operatorList = d;
    });
  }





  selectDate($event: { startDate: any; endDate: any }, firstRange: boolean) {
    if ($event && $event.startDate) {
      if (firstRange) {
        this.withFirstRange = false;
        this.firstRange = {start: null, end: null};
        this.firstRange = {start: new Date(($event.startDate)), end: new Date(($event.endDate))};
        this.withFirstRange = true;
        this.setCompareRange();
        return;
      }
      this.withSecondRange = false;
      this.secondRange = {start: null, end: null};
      this.secondRange = {start: new Date(($event.startDate)), end: new Date(($event.endDate))};
      this.withSecondRange = true;
    }
  }

  getPercent(value: number, compareValue: number) {
    if (value == 0) {
      return compareValue == 0 ? 0 : 100;
    }
    if (value == compareValue) {
      return 100;
    }
    const per = (compareValue - value) / value * 100;
    return per;
    // return '( ' + per + (per < 100 ? ' <' : ' >') + ' )';
  }


  setCompareRange() {
    const d1 = new Date(this.firstRange.end);
    const d2 = new Date(this.firstRange.start);
    d1.setHours(23, 59, 0);
    d2.setHours(23, 59, 0);
    const dif = d1.getTime() - d2.getTime();
    const days = dif / (1000 * 60 * 60 * 24);
    const endDate = moment(this.firstRange.start).subtract(1, 'days');
    const startDate = moment(this.firstRange.start).subtract(days + 1, 'days');
    // @ts-ignore
    // this.firstRange = {startDate, endDate};
    this.dateRangeElm.selected = {startDate, endDate};
  }


  getCategories() {
    this.configurationsService.getLazyCategories({page: this.categoriesPage, pageSize: 10, type: this.type}).subscribe(d => {
      this.categoriesList = [...this.categoriesList, ...d.data];
      this.categoriesPage++;
    });
  }

  getSubCategories() {
    this.configurationsService.getLazySubCategories({page: this.categoriesPage, pageSize: 10, type: this.type}).subscribe(d => {
      this.subCategoriesList = [...this.subCategoriesList, ...d.data];
    });
  }

  getClients(term?) {
    this.clientService.getLazyClientList({page: 1, pageSize: 10, visible: true, textSearch: term, confirmed: true}).subscribe(data => {
      this.clients = [...data.data];
    });
  }

  resetSearchForm() {

    // @ts-ignore
    if (this.dateRangeElm && this.dateRangeElm.pickerDirective) {
      // @ts-ignore
      this.dateRangeElm.pickerDirective.clear();
    }
    // @ts-ignore
    if (this.dateFromElm && this.dateFromElm.pickerDirective) {
      // @ts-ignore
      this.dateFromElm.pickerDirective.clear();
    }
    this.filterFormGroup = new UntypedFormGroup({
      dateFrom: new UntypedFormControl(null),
      dateTo: new UntypedFormControl(null),
      timeLaps: new UntypedFormControl(null),
      categoryId: new UntypedFormControl(null),
      subCategoryId: new UntypedFormControl(null),
      clientId: new UntypedFormControl(null),
      textSearch: new UntypedFormControl(null),
      compareRanges: new UntypedFormControl(false),
      treatment: new UntypedFormControl(true),
      product: new UntypedFormControl(true),
      operator: new UntypedFormControl(null),
    });

    this.withFirstRange = false;
    this.withSecondRange = false;
    this.firstRange = {start: null, end: null};
    this.secondRange = {start: null, end: null};

    this.sendFilter();
  }



  sendFilter() {
    const request = {
      page: this.page,
      pageSize: this.pageSize,
      dateFrom: this.firstRange ? this.firstRange.start : '',
      dateTo: this.firstRange ? this.firstRange.end : '',
      rangeDateFrom: this.secondRange ? this.secondRange.start : '',
      rangeDateTo: this.secondRange ? this.secondRange.end : '',
      compareRanges: this.filterFormGroup.get('compareRanges').value,
      timeLaps: this.filterFormGroup.get('timeLaps').value,
      categoryId: this.filterFormGroup.get('categoryId').value,
      subCategoryId: this.filterFormGroup.get('subCategoryId').value,
      clientId: this.filterFormGroup.get('clientId').value,
      textSearch: this.filterFormGroup.get('textSearch').value,
      operator: this.filterFormGroup.get('operator').value,
      treatment: this.filterFormGroup.get('treatment').value,
      product: this.filterFormGroup.get('product').value
    };
    this.sendRequest.emit(request);
  }


  translateTimeLaps() {
    this.timeLaps = [
      {id: TimeLaps.CURRENT_WEEK, value: this.translate.instant('STATISTIC_FORM.' + TimeLaps.CURRENT_WEEK)},
      {id: TimeLaps.LAST_WEEK, value: this.translate.instant('STATISTIC_FORM.' + TimeLaps.LAST_WEEK)},
      {id: TimeLaps.CURRENT_MONTH, value: this.translate.instant('STATISTIC_FORM.' + TimeLaps.CURRENT_MONTH)},
      {id: TimeLaps.LAST_MONTH, value: this.translate.instant('STATISTIC_FORM.' + TimeLaps.LAST_MONTH)},
      {id: TimeLaps.CURRENT_YEAR, value: this.translate.instant('STATISTIC_FORM.' + TimeLaps.CURRENT_YEAR)},
      {id: TimeLaps.LAST_YEAR, value: this.translate.instant('STATISTIC_FORM.' + TimeLaps.LAST_YEAR)},
      {id: TimeLaps.FIRST_TRIMESTER, value: this.translate.instant('STATISTIC_FORM.' + TimeLaps.FIRST_TRIMESTER)},
      {id: TimeLaps.SECOND_TRIMESTER, value: this.translate.instant('STATISTIC_FORM.' + TimeLaps.SECOND_TRIMESTER)},
      {id: TimeLaps.THIRD_TRIMESTER, value: this.translate.instant('STATISTIC_FORM.' + TimeLaps.THIRD_TRIMESTER)},
      {id: TimeLaps.FORTH_TRIMESTER, value: this.translate.instant('STATISTIC_FORM.' + TimeLaps.FORTH_TRIMESTER)},
      {id: TimeLaps.FIRST_SEMESTER, value: this.translate.instant('STATISTIC_FORM.' + TimeLaps.FIRST_SEMESTER)},
      {id: TimeLaps.SECOND_SEMESTER, value: this.translate.instant('STATISTIC_FORM.' + TimeLaps.SECOND_SEMESTER)},
    ];
  }



}

import {Component, Inject, OnInit} from '@angular/core';
import {WorkTime} from '../models/workTime';
import {Subject} from 'rxjs';
import {Day, Month, Week, WeekPlanning, Year, YearPlanning} from '../models/week-planning-template';
import {  MatDialog,  MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {SweetAlertService} from '../services/sweet-alert.service';
import {WeekPlanningService} from '../services/week-planning.service';
import {CabinService} from '../services/cabin.service';
import {YearPlanningService} from '../services/year-planning.service';
import {Cabin} from '../models/cabin';
import {TypePlanning} from '../enum/type-planning';
import {WeekPlanningFormComponent} from '../week-template-form/week-planning-form.component';
import {takeUntil} from 'rxjs/operators';
import {WeekPlanningApplicationModeComponent} from './week-planning-application-mode.component';

@Component({
  selector: 'app-year-planning',
  templateUrl: './year-planning.component.html',
  styleUrls: ['./year-planning.component.scss']
})
export class YearPlanningComponent implements OnInit {
  verify = false;
  disableSave = false;


  constructor(
    private weekPlanningService: WeekPlanningService,
    private yearPlanningService: YearPlanningService,
    private cabinService: CabinService,
    private snackBar: MatSnackBar,
    private matDialog: MatDialog,
    private matDialogRef: MatDialogRef<YearPlanningComponent>,
    private translate: TranslateService,
    private sweetAlertService: SweetAlertService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  planningColor = '#642222';
  columns = ['DATA_TABLE.DAY', 'DATA_TABLE.DAY_OFF', 'DATA_TABLE.FROM',
    'DATA_TABLE.TO', 'DATA_TABLE.PAUSE', 'DATA_TABLE.FROM', 'DATA_TABLE.TO'];
  workTime: WorkTime = new WorkTime();
  unsubscribe$ = new Subject();
  year: Year = new Year();
  // days = ['monday', 'tues', 'wed', 'thur', 'fri', 'sat', 'sun'];

  days = ['PLANNING_FORM.ABBREVIATION_MONDAY',
    'PLANNING_FORM.ABBREVIATION_TUESDAY',
    'PLANNING_FORM.ABBREVIATION_WEDNESDAY',
    'PLANNING_FORM.ABBREVIATION_THURSDAY',
    'PLANNING_FORM.ABBREVIATION_FRIDAY',
    'PLANNING_FORM.ABBREVIATION_SATURDAY',
    'PLANNING_FORM.ABBREVIATION_SUNDAY', ''];

  planningList: any[] = [];
  yearPlanning: YearPlanning = new YearPlanning();
  weeks: WeekPlanning[] = [];
  unsubscribe = new Subject();
  months = ['PLANNING_FORM.JANUARY',
    'PLANNING_FORM.FEBRUARY',
    'PLANNING_FORM.MARCH',
    'PLANNING_FORM.APRIL',
    'PLANNING_FORM.MAY',
    'PLANNING_FORM.JUNE',
    'PLANNING_FORM.JULY',
    'PLANNING_FORM.AUGUST',
    'PLANNING_FORM.SEPTEMBER',
    'PLANNING_FORM.OCTOBER',
    'PLANNING_FORM.NOVEMBER',
    'PLANNING_FORM.DECEMBER'];

  ngOnInit() {
    this.weekPlanningService.getAllWeekPlanning().subscribe(r => {
      this.planningList = r;
    });
    if (this.data.year) {
      this.year = this.data.year;
    } else {
      const year = this.data.form.year;
      this.year.name = year;
      this.year.months = [];

      this.months.forEach((m, index) => {
        const month = new Month();
        month.name = m;
        month.weeks = [];

        const monthNumber = index + 1;
        const firstOfMonth = new Date(year, monthNumber - 1, 1);
        const lastOfMonth = new Date(year, monthNumber, 0);

        let startMonthDay = Number(firstOfMonth.getDay());
        if (startMonthDay === 0) {
          startMonthDay = 7;
        }
        const used = startMonthDay + lastOfMonth.getDate();
        const weekNumber = Math.ceil(used / 7);

        let q = 0;
        for (let i = 0; i < weekNumber; i++) {
          month.weeks[i] = new Week();
          month.weeks[i].month = monthNumber;
          month.weeks[i].year = year;
          month.weeks[i].order = i + 1;
          month.weeks[i].days = [];
          for (let j = 0; j < 7; j++) {
            month.weeks[i].days[j] = new Day();
            if (i === 0) {
              month.weeks[0].days[j].number = j < startMonthDay - 1 ? null : (q += 1);
            } else if (i === weekNumber - 1) {
              month.weeks[weekNumber - 1].days[j].number = j < lastOfMonth.getDay() ? (q += 1) : null;
            } else {
              q += 1;
              month.weeks[i].days[j].number = q;
            }
          }
        }
        if (month.weeks[weekNumber - 1].days[0].number === null) {
          month.weeks.splice(weekNumber - 1, 1);
        }

        this.year.months[index] = month;
      });
      if (this.data.planning !== undefined) {
        this.yearPlanningService.getWeeksByPlanning(this.data.planning.id).subscribe(r => {
          this.yearPlanning.weeksPlanning = r;
          r.forEach(week => {
            this.year.months[week.month - 1].weeks[week.order - 1].workTimeDto = week.workTimeDto;
            this.year.months[week.month - 1].weeks[week.order - 1].id = week.id;
            this.year.months[week.month - 1].weeks[week.order - 1].uuid = week.uuid;
          });
        });

      }

    }

  }

  getWeekFromPlanning(week: Week) {
    const planning = this.planningList.filter(p => p.id === week.weekPlanningTemplateId)[0];
    if (planning) {
      if (week.id) {
        week.weekPlanningTemplateId = planning.id;
        week.workTimeDto = this.getPlanning(planning.workTimeDto, week);
        this.yearPlanningService.saveWeekPlanning(week).subscribe(r => {
          this.snackBar.open(this.translate.instant('DIALOG.UPDATE_SUCCESS'), 'Ok', {duration: 5000});
          return;
        });
      }

      if (week.order === 1) {
        const dialogRef = this.matDialog.open(WeekPlanningApplicationModeComponent, {
          width: '600px',
          maxHeight: '88vh',
          disableClose: true,
          data: {editMode: true, destructed: true, planning: week}
        });
        dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(d => {
          if (d) {
            if (d === 'thisMonth') {
              this.year.months[week.month - 1].weeks.forEach(w => {
                w.weekPlanningTemplateId = planning.id;
                w.workTimeDto = this.getPlanning(planning.workTimeDto, w);
              });
              console.log(this.year);

            } else if (d === 'firstThree') {
              const firstThree = [0, 1, 2];
              firstThree.forEach(i => {
                this.year.months[i].weeks.forEach(w => {
                  w.weekPlanningTemplateId = planning.id;
                  w.workTimeDto = this.getPlanning(planning.workTimeDto, w);
                });
              });
            } else if (d === 'secondThree') {
              const firstThree = [3, 4, 5];
              firstThree.forEach(i => {
                this.year.months[i].weeks.forEach(w => {
                  w.weekPlanningTemplateId = planning.id;
                  w.workTimeDto = this.getPlanning(planning.workTimeDto, w);
                });
              });
            } else if (d === 'thirdThree') {
              const firstThree = [6, 7, 8];
              firstThree.forEach(i => {
                this.year.months[i].weeks.forEach(w => {
                  w.weekPlanningTemplateId = planning.id;
                  w.workTimeDto = this.getPlanning(planning.workTimeDto, w);
                });
              });
            } else if (d === 'forthThree') {
              const firstThree = [9, 10, 11];
              firstThree.forEach(i => {
                this.year.months[i].weeks.forEach(w => {
                  w.weekPlanningTemplateId = planning.id;
                  w.workTimeDto = this.getPlanning(planning.workTimeDto, w);
                });
              });
            } else if (d === 'all') {
              this.year.months.forEach(mont => {
                mont.weeks.forEach(w => {
                  w.weekPlanningTemplateId = planning.id;
                  w.workTimeDto = this.getPlanning(planning.workTimeDto, w);
                });
              });
            } else {
              week.weekPlanningTemplateId = planning.id;
              week.workTimeDto = this.getPlanning(planning.workTimeDto, week);
            }
          } else {
            week.weekPlanningTemplateId = null;
          }
        });
      } else {
        week.weekPlanningTemplateId = planning.id;
        week.workTimeDto = this.getPlanning(planning.workTimeDto, week);
      }

      return planning.name;
    }
    return null;
  }

  save() {
    this.verify = false;
    this.disableSave = true;
    this.yearPlanning.planningColor = this.data.form.planningColor;
    this.yearPlanning.planningPosition = Number(this.data.form.planningPosition);
    this.yearPlanning.year = this.data.form.year;
    this.yearPlanning.weeksPlanning = [];

    if (this.year.months.some(m => m.weeks.some(w => !w.workTimeDto))) {
      this.verify = true;
      this.disableSave = false;
      this.sweetAlertService.notification(this.translate.instant('DIALOG.YOU_MUST_FILL_ALL_THE_PLANNING')).then(e => {
      });
      return;
    }
    this.year.months.forEach(m => {
      m.weeks.forEach(w => {
        // @ts-ignore
        this.yearPlanning.weeksPlanning.push(w);
      });
    });

    if (this.data.type === TypePlanning.OPERATOR) {
      this.yearPlanning.type = TypePlanning.OPERATOR;
      this.yearPlanning.operatorId = this.data.form.operatorId;
      if (!this.data.planning || this.data.generate) {
        this.yearPlanningService.addNewPlanning(this.yearPlanning).subscribe(res => {
        }, (err) => {
          this.disableSave = false;
          if (err.message.includes('planning already exist')) {
            this.snackBar.open(this.translate.instant('DIALOG.THIS_PLANNING_YEAR_ALREADY_EXIST'), 'Ok', {duration: 5000});
          } else {
            this.snackBar.open(this.translate.instant('DIALOG.INTERNAL_SERVER_ERROR'), 'Ok', {duration: 5000});
          }
        }, () => {
          this.matDialogRef.close(true);
          this.snackBar.open(this.translate.instant('DIALOG.SAVE_SUCCESS'), 'Ok', {duration: 5000});
        });
      } else {
        this.yearPlanning.id = this.data.planning.id;
        this.yearPlanning.operatorId = this.data.planning.operatorId;
        this.yearPlanningService.editYearPlanning(this.yearPlanning).subscribe(res => {
        }, (err) => {
          this.disableSave = false;
          this.snackBar.open(this.translate.instant('DIALOG.INTERNAL_SERVER_ERROR'), 'Ok', {duration: 5000});
        }, () => {
          this.matDialogRef.close(true);
          this.snackBar.open(this.translate.instant('DIALOG.UPDATE_SUCCESS'), 'Ok', {duration: 5000});
        });
      }
    } else {
      this.yearPlanning.type = TypePlanning.CABIN;
      if (this.data.cabin === undefined) {
        this.cabinService.addNew(this.data.form).subscribe((r: Cabin) => {
          this.yearPlanning.cabinId = r.id;
          this.yearPlanningService.addNewPlanning(this.yearPlanning).subscribe(res => {
          }, (err) => {
            this.disableSave = false;
            if (err.message.includes('planning already exist')) {
              this.snackBar.open(this.translate.instant('DIALOG.THIS_PLANNING_YEAR_ALREADY_EXIST'), 'Ok', {duration: 5000});
            } else {
              this.snackBar.open(this.translate.instant('DIALOG.INTERNAL_SERVER_ERROR'), 'Ok', {duration: 5000});
            }
            this.disableSave = false;
          }, () => {
            this.matDialogRef.close(true);
            this.snackBar.open(this.translate.instant('DIALOG.SAVE_SUCCESS'), 'Ok', {duration: 5000});
          });
        });
      } else {
        if (this.data.planning !== undefined) {
          this.yearPlanning.id = this.data.planning.id;
          this.yearPlanning.cabinId = this.data.cabin.id;
          this.yearPlanningService.editYearPlanning(this.yearPlanning).subscribe(res => {
          }, (err) => {
            this.snackBar.open(this.translate.instant('DIALOG.INTERNAL_SERVER_ERROR'), 'Ok', {duration: 5000});
            this.disableSave = false;
          }, () => {
            this.matDialogRef.close(true);
            this.snackBar.open(this.translate.instant('DIALOG.UPDATE_SUCCESS'), 'Ok', {duration: 5000});
          });
        } else {
          this.yearPlanning.id = null;
          this.yearPlanning.cabinId = this.data.cabin.id;
          this.yearPlanningService.addNewPlanning(this.yearPlanning).subscribe(res => {
          }, (err) => {
            this.disableSave = false;
            if (err.message.includes('planning already exist')) {
              this.snackBar.open(this.translate.instant('DIALOG.THIS_PLANNING_YEAR_ALREADY_EXIST'), 'Ok', {duration: 5000});
            } else {
              this.snackBar.open(this.translate.instant('DIALOG.INTERNAL_SERVER_ERROR'), 'Ok', {duration: 5000});
            }
            this.disableSave = false;
          }, () => {
            this.matDialogRef.close(true);
            this.snackBar.open(this.translate.instant('DIALOG.UPDATE_SUCCESS'), 'Ok', {duration: 5000});
          });
        }
      }
    }
  }

  openEditPlanning($event: MouseEvent, week) {
    const dialogRef = this.matDialog.open(WeekPlanningFormComponent, {
      width: '1200px',
      maxHeight: '88vh',
      disableClose: true,
      data: {editMode: true, destructed: true, planning: week}
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(d => {
      if (d) {
        week.workTimeDto = d;
      }
    });
  }


  getPlanning(workTime1: WorkTime, week: Week) {
    const workTime = Object.assign({}, workTime1);
    if (week.days[0].number === null) {
      workTime.mondayDayOff = null;
      workTime.mondayWithBreak = null;
      workTime.mondayMorFrom = null;
      workTime.mondayMorTo = null;
      workTime.mondayAfterFrom = null;
      workTime.mondayAfterTo = null;
    }

    if (week.days[1].number === null) {
      workTime.tuesDayOff = null;
      workTime.tuesWithBreak = null;
      workTime.tuesMorFrom = null;
      workTime.tuesMorTo = null;
      workTime.tuesAfterFrom = null;
      workTime.tuesAfterTo = null;
    }

    if (week.days[2].number === null) {
      workTime.wedDayOff = null;
      workTime.wedWithBreak = null;
      workTime.wedMorFrom = null;
      workTime.wedMorTo = null;
      workTime.wedAfterFrom = null;
      workTime.wedAfterTo = null;
    }

    if (week.days[3].number === null) {
      workTime.thurDayOff = null;
      workTime.thurWithBreak = null;
      workTime.thurMorFrom = null;
      workTime.thurMorTo = null;
      workTime.thurAfterFrom = null;
      workTime.thurAfterTo = null;
    }

    if (week.days[4].number === null) {
      workTime.friDayOff = null;
      workTime.friWithBreak = null;
      workTime.friMorFrom = null;
      workTime.friMorTo = null;
      workTime.friAfterFrom = null;
      workTime.friAfterTo = null;
    }

    if (week.days[5].number === null) {
      workTime.satDayOff = null;
      workTime.satWithBreak = null;
      workTime.satMorFrom = null;
      workTime.satMorTo = null;
      workTime.satAfterFrom = null;
      workTime.satAfterTo = null;
    }

    if (week.days[6].number === null) {
      workTime.sunDayOff = null;
      workTime.sunWithBreak = null;
      workTime.sunMorFrom = null;
      workTime.sunMorTo = null;
      workTime.sunAfterFrom = null;
      workTime.sunAfterTo = null;
    }

    return workTime;
  }

}

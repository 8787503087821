import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {FirstNoteCauseService} from '../../../../../shared/services/first-note-cause.service';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {finalize} from 'rxjs/operators';
import {SweetAlertService} from "../../../../../shared/services/sweet-alert.service";

@Component({
  selector: 'app-first-note-cause-form-dialog',
  templateUrl: './first-note-cause-form-dialog.component.html',
  styleUrls: ['./first-note-cause-form-dialog.component.scss']
})
export class FirstNoteCauseFormDialogComponent implements OnInit {
  causeForm: UntypedFormGroup;
  loading = false;
  constructor(
    private fb: UntypedFormBuilder,
    private firstNoteCauseService: FirstNoteCauseService,
    private matDialogRef: MatDialogRef<FirstNoteCauseFormDialogComponent>,  private sweetAlertService: SweetAlertService,
    @Inject(MAT_DIALOG_DATA) private data: {editMode, cause}
  ) { }

  ngOnInit() {
    this.causeForm = this.fb.group({
      id: null,
      name: [null, Validators.required]
    });
    if (this.data.editMode) {
      this.causeForm.patchValue(this.data.cause);
    }
  }

  save() {
    if (this.causeForm.invalid) {
      return;
    }
    this.loading = true;
    this.firstNoteCauseService.add(this.causeForm.value).pipe(finalize(() => this.loading = false)).subscribe(() => {
      this.matDialogRef.close(true);
    });
  }

  confirmAndCloseDialog(): void {
    if (this.causeForm.dirty) {
     this.sweetAlertService.showConfirmationAlert()
      .then((confirmation) => {
        if (confirmation) {
          this.matDialogRef.close();

        }
      });}
    else{
      this.matDialogRef.close();
    }
  }
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.causeForm.dirty) {
      $event.returnValue = true;
    }
  }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.emoji-display {
  position: absolute;
  z-index: 1;
  right: 25px;
  top: -40px;
}

::ng-deep .emoji-mart {
  width: 320px !important;
}

::ng-deep .emoji-mart-preview {
  display: none !important;
}

.custom-width {
  max-width: 251px;
  border-right: 2px;
}`, "",{"version":3,"sources":["webpack://./src/app/content/pages/Whatsapp/whatsapp-configuration/whatsapp-configuration.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,UAAA;EACA,WAAA;EACA,UAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAIA;EACE,wBAAA;AADF;;AAIA;EACE,gBAAA;EACA,iBAAA;AADF","sourcesContent":[".emoji-display {\r\n  position: absolute;\r\n  z-index: 1;\r\n  right: 25px;\r\n  top: -40px;\r\n}\r\n\r\n::ng-deep .emoji-mart {\r\n  width: 320px !important;\r\n  //max-height: 350px !important;\r\n  //overflow-y: auto !important;\r\n}\r\n\r\n::ng-deep .emoji-mart-preview {\r\n  display: none !important;\r\n}\r\n\r\n.custom-width {\r\n  max-width: 251px;\r\n  border-right: 2px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

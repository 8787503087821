import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {ProductManagementComponent} from './product-management.component';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {SharedModule} from '../../../../shared/shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ProductManagementFormComponent} from './product-management-form/product-management-form.component';
import {NgbDropdownModule, NgbModule, NgbPaginationModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {MatLegacyButtonModule} from '@angular/material/legacy-button';
import {MatLegacyCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatLegacyDialog as MatDialog, MatLegacyDialogModule} from '@angular/material/legacy-dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ProductFormService} from './services/product-form.service';
import {CurrencyMaskConfig, CurrencyMaskModule, CURRENCY_MASK_CONFIG} from "ng2-currency-mask";
import {CategoryProductModule} from '../../configurations/category-product/category-product.module';
import {StockManagementModule} from '../../stock-and-movement/stock-management/stock-management.module';
import {ProductMgmService} from '../../../../shared/services/product-mgm.service';
import {SweetAlertService} from '../../../../shared/services/sweet-alert.service';
import {HttpClient} from '@angular/common/http';
import {SequenceService} from '../../../../shared/services/sequence.service';
import {ConfigurationsService} from '../../../../shared/services/configurations.service';
import {ProviderMgmService} from '../../../../shared/services/provider-mgm.service';
import {BarCodeMgmService} from '../../../../shared/services/bar-code-mgm.service';
import {ProdCodeMgmService} from '../../../../shared/services/prod-code-mgm.service';
import {MovementMgmService} from '../../../../shared/services/movement-mgm.service';
import {ProductCreateModule} from '../../../../shared/product-create/product-create.module';


const routes: Routes = [
  {path: '', component: ProductManagementComponent}
];

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'right',
  allowNegative: true,
  decimal: ',',
  precision: 2,
  prefix: '',
  suffix: '',
  thousands: '.'
};


@NgModule({
  declarations: [ProductManagementComponent, ProductManagementFormComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatLegacyButtonModule,
    MatIconModule,
    MatLegacyDialogModule,
    RouterModule.forChild(routes),
    SharedModule.forChild(),
    ReactiveFormsModule,
    NgbPaginationModule,
    MatDatepickerModule,
    NgbDropdownModule,
    CurrencyMaskModule,
    MatLegacyCheckboxModule,
    CategoryProductModule,
    StockManagementModule,
    FormsModule,
    NgbTooltipModule,
    NgbModule,
    ProductCreateModule,
  ],
  providers: [
    ProductFormService,
    {provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig},
    TranslateService,
    MatSnackBar,
    ProductMgmService,
    SweetAlertService,
    HttpClient,
    MatDialog,
    SequenceService,
    ConfigurationsService,
    ProviderMgmService,
    BarCodeMgmService,
    ProdCodeMgmService,
    MovementMgmService,
    {provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig}
  ],
  exports: [ProductManagementFormComponent]
})
export class ProductManagementModule {
}

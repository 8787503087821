import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SellPointService} from '../../../../../../shared/services/sell-point.service';
import {Subject} from 'rxjs';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {SellPointFormComponent} from './sell-point-form/sell-point-form.component';
import {CenterService} from '../../../../../../shared/services/center.service';
import {Center} from '../../../../../../shared/models/center';
import {SellPoint} from '../../../../../../shared/models/sell-point';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {HttpErrorResponse} from '@angular/common/http';
import {  MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../../../../../shared/services/user.service';

@Component({
  selector: 'app-point-of-sales',
  templateUrl: './sell-points.component.html',
  styleUrls: ['./sell-points.component.scss']
})
export class SellPointsComponent implements OnInit, OnDestroy {
  sellPointsList: SellPoint[] = [];
  unsubscribe$ = new Subject();
  center: Center;
  loading = false;
  currentUser;
  isOwner: boolean;
  siegeId: number;
  siegeName: string;
  constructor(
    private route: ActivatedRoute,
    private sellPointService: SellPointService,
    private centerService: CenterService,
    private matDialog: MatDialog,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private router: Router,
    private matSnackBar: MatSnackBar,
    private translateService: TranslateService,
    private userService: UserService
  ) {
  }

  ngOnInit() {
    this.isOwner = this.userService.getUser().authorities.some(u => u === 'OWNER');
    this.route.paramMap.subscribe(p => {
      this.centerService.getCenterById(p.get('centerId')).subscribe(d => {
        this.center = d;
      });
      this.loading = true;
      this.sellPointService.getSellPointsByCenter(p.get('centerId')).subscribe(u => {
        this.sellPointsList = u;
        this.loading = false;
        const siege = u.filter(u => u.siege).shift();
        this.siegeId = siege ? siege.id : null;
        this.siegeName = siege ? siege.name : null;
      });
    });
    this.currentUser = this.localStorageService.retrieve('user') || this.sessionStorageService.retrieve('user');
    console.log(this.sessionStorageService.retrieve('user'), this.localStorageService.retrieve('user'));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(void 0);
    this.unsubscribe$.unsubscribe();
  }

  openAddSellPoint() {
    const dialogRef = this.matDialog.open(SellPointFormComponent, {
      width: '1200px',
      disableClose: true,
      autoFocus: false,
      data: {editMode: false, center: this.center, siegeId: this.siegeId, siegeName: this.siegeName}
    });
    dialogRef.afterClosed().subscribe(d => {
      if (d) {
        this.loading = true;
        this.sellPointService.getSellPointsByCenter(this.route.snapshot.params.centerId).subscribe(u => {
          this.sellPointsList = u;
          this.loading = false;
          const siege = u.filter(u => u.siege).shift();
          this.siegeId = siege ? siege.id : null;
          this.siegeName = siege ? siege.name : null;
        });
      }
    });
  }

  openEditSellPoint(sellPoint) {
    const dialogRef = this.matDialog.open(SellPointFormComponent, {
      width: '1200px',
      disableClose: true,
      autoFocus: false,
      data: {editMode: true, center: this.center, sellPoint, siegeId: this.siegeId, siegeName: this.siegeName}
    });
    dialogRef.afterClosed().subscribe(d => {
      if (d) {
        this.loading = true;
        this.sellPointService.getSellPointsByCenter(this.route.snapshot.params.centerId).subscribe(u => {
          this.sellPointsList = u;
          const siege = u.filter(u => u.siege).shift();
          this.siegeId = siege ? siege.id : null;
          this.siegeName = siege ? siege.name : null;
          this.loading = false;
        });
      }
    });
  }

  chooseSellPoint(sp: SellPoint) {
    const tokenStorageKey = 'authenticationToken';
    this.sellPointService.chooseSellPoint(sp.id).subscribe(resp => {
      if (this.localStorageService.retrieve(tokenStorageKey)) {
        this.localStorageService.store(tokenStorageKey, resp.token);
      } else {
        this.sessionStorageService.store(tokenStorageKey, resp.token);
      }
      this.router.navigate(['/']);
    });
  }

  deleteSellPoint(sp: SellPoint) {
    this.sellPointService.deleteSellPoint(sp.id).subscribe(d => {
      this.sellPointService.getSellPointsByCenter(this.center.id).subscribe(u => {
        this.sellPointsList = u;
        this.loading = false;
      });
    }, (err: HttpErrorResponse) => {
      if (err.error.message.includes('ConstraintViolationException')) {
        this.matSnackBar.open(this.translateService.instant('ADMIN.SELL_POINT.CANNOT_DELETE'), 'Ok', {
          duration: 5000,
          panelClass: 'white-snackbar',
        });
      }
    });
  }
}

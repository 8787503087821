// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card.card-fullheight {
  height: unset !important;
}`, "",{"version":3,"sources":["webpack://./src/app/content/pages/reports/operator-reports/operator-reports.component.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;AACF","sourcesContent":[".card.card-fullheight {\r\n  height: unset !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea {
  resize: none;
}

.warn-div {
  position: relative;
  left: -24px;
  width: calc(100% + 48px);
  padding: 24px;
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.token-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: end;
}
.token-grid .form-group {
  margin-right: 10px;
}

@media screen and (min-width: 930px) and (max-width: 1190px) {
  .token-grid {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    align-items: start;
  }
  .token-grid .form-group {
    margin-right: 0;
    margin-bottom: 10px !important;
  }
  .token-grid .buttons {
    display: flex;
    flex-direction: row;
  }
  .token-grid .buttons button {
    margin: 0 10px 0 0 !important;
  }
}
@media screen and (max-width: 930px) {
  .token-grid {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    align-items: start;
  }
  .token-grid .form-group {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .token-grid .buttons {
    display: flex;
    flex-direction: column;
  }
  .token-grid .buttons button {
    margin: 5px 0 !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/content/pages/configurations/satispay/satispay.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;EACA,wBAAA;EACA,aAAA;EACA,iBAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,gBAAA;AACF;AACE;EACE,kBAAA;AACJ;;AAEA;EACE;IACE,0BAAA;IACA,4BAAA;IACA,kBAAA;EACF;EAAE;IACE,eAAA;IACA,8BAAA;EAEJ;EAAE;IACE,aAAA;IACA,mBAAA;EAEJ;EADI;IACE,6BAAA;EAGN;AACF;AACA;EACE;IACE,0BAAA;IACA,4BAAA;IACA,kBAAA;EACF;EAAE;IACE,eAAA;IACA,mBAAA;EAEJ;EAAE;IACE,aAAA;IACA,sBAAA;EAEJ;EADI;IACE,wBAAA;EAGN;AACF","sourcesContent":["textarea {\r\n  resize: none;\r\n}\r\n\r\n.warn-div {\r\n  position: relative;\r\n  left: -24px;\r\n  width: calc(100% + 48px);\r\n  padding: 24px;\r\n  font-size: .9rem;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.token-grid {\r\n  display: grid;\r\n  grid-template-columns: 1fr 1fr;\r\n  align-items: end;\r\n\r\n  .form-group {\r\n    margin-right: 10px;\r\n  }\r\n}\r\n@media screen and (min-width: 930px) and (max-width: 1190px) {\r\n  .token-grid {\r\n    grid-template-columns: 1fr;\r\n    grid-template-rows: auto 1fr;\r\n    align-items: start;\r\n    .form-group {\r\n      margin-right: 0;\r\n      margin-bottom: 10px !important;\r\n    }\r\n    .buttons {\r\n      display: flex;\r\n      flex-direction: row;\r\n      button {\r\n        margin: 0 10px 0 0 !important;\r\n      }\r\n    }\r\n  }\r\n}\r\n@media screen and (max-width: 930px) {\r\n  .token-grid {\r\n    grid-template-columns: 1fr;\r\n    grid-template-rows: auto 1fr;\r\n    align-items: start;\r\n    .form-group {\r\n      margin-right: 0;\r\n      margin-bottom: 10px;\r\n    }\r\n    .buttons {\r\n      display: flex;\r\n      flex-direction: column;\r\n      button {\r\n        margin: 5px 0 !important;\r\n      }\r\n    }\r\n  }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BreadcrumbService} from '../../../../core/services/breadcrumb.service';
import {MobileAppConfigService} from '../../../../shared/services/mobile-app-config.service';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {  MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ProductBarcodeType} from '../../../../shared/enum/product-barcode-type';
import {  MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {CodiceIvaFormModalComponent} from '../codice-iva/codice-iva-form-modal.component';
import {QRCodeComponent} from 'angularx-qrcode';
import {MobileConfiguration} from "../../../../shared/models/mobile-configuration";

@Component({
  selector: 'app-app-configurations',
  templateUrl: './app-configurations.component.html',
  styleUrls: ['./app-configurations.component.scss']
})
export class AppConfigurationsComponent implements OnInit, OnDestroy {
  currentConfig: MobileConfiguration;
  configForm: UntypedFormGroup;
  private unsub = new Subject();

  uuid = '';
  link: string;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private mobileConfigService: MobileAppConfigService,
    private matSnackBar: MatSnackBar,
    private translateService: TranslateService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private matDialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.sendBreadCrumb();
    this.configForm = this.fb.group({
      bookingCancelTolerance: null,
      notificationReminder: null,
      homeInfo: null,
      disabledAppointment: false,
      qrCode: null,
    });
    this.mobileConfigService.getConfiguration().subscribe(d => {
      this.uuid = d.grpUuid;
      this.currentConfig = d;
      if (d) {
        const {bookingCancelTolerance, homeInfo, disabledAppointment, notificationReminder, qrCode} = d;
        this.configForm.patchValue({bookingCancelTolerance, homeInfo, disabledAppointment, notificationReminder, qrCode});
      }
    });
    this.activatedRoute.queryParams.pipe(takeUntil(this.unsub)).subscribe(res => {
      if (res && res.saved) {
        this.mobileConfigService.getConfiguration().subscribe(d => {
          this.currentConfig = d;
          if (d) {
            const {bookingCancelTolerance, homeInfo, disabledAppointment, notificationReminder} = d;
            this.configForm.patchValue({bookingCancelTolerance, homeInfo, disabledAppointment, notificationReminder});
          }
        });
      }
    });
  }

  get toleration() {
    return this.configForm.get('bookingCancelTolerance') as UntypedFormControl;
  }

  get moreInfo() {
    return this.configForm.get('moreInfo') as UntypedFormControl;
  }

  sendBreadCrumb(): void {
    this.breadcrumbService.sendBreadcrumb(['CONFIGURATION', 'MOBILE_APP_CONFIG']);
  }


  save() {
    if (!this.toleration.value ) {
      return;
    }
    this.configForm.disable();
    if (!this.currentConfig) {
      this.mobileConfigService.update(this.configForm.getRawValue()).subscribe(() => {
        this.mobileConfigService.getConfiguration().subscribe(d => {
          this.currentConfig = d;
          this.configForm.enable();
          this.mobileConfigService.goReload();
          this.matSnackBar.open(this.translateService.instant('MOBILE_APP_CONFIG.UPDATED_SUCCESSFULLY'), 'Ok', {duration: 3000});
        });
      });
    } else {
      this.mobileConfigService.update({...this.configForm.getRawValue(), id: this.currentConfig.id}).subscribe(() => {
        this.mobileConfigService.getConfiguration().subscribe(d => {
          this.currentConfig = d;
          this.configForm.enable();
          this.mobileConfigService.goReload();
          this.matSnackBar.open(this.translateService.instant('MOBILE_APP_CONFIG.UPDATED_SUCCESSFULLY'), 'Ok', {duration: 3000});
        });
      });
    }
  }

  ngOnDestroy(): void {
    this.unsub.next(void 0);
    this.unsub.complete();
  }


  openQrContent(contentQrCode) {
    this.matDialog.open(contentQrCode, {
      width: '400px',
      autoFocus: true,
      disableClose: true,
    });
  }

  dlDataUrlBin() {
    if (this.currentConfig.bannerImages && this.currentConfig.bannerImages.length) {
      const y = document.getElementsByTagName('img')[this.currentConfig.bannerImages.length + 8];
      this.link = y.src;
    } else {
      const y = document.getElementsByTagName('img')[8];
      this.link = y.src;
    }
  }

  toggleDisabledAppointment(checked: boolean) {
    this.configForm.patchValue({disabledAppointment: checked});

    // You can perform additional logic here based on the checkbox state
    if (checked) {
      // Do something if the checkbox is checked
      console.log('Checkbox is checked');
    } else {
      // Do something if the checkbox is unchecked
      console.log('Checkbox is unchecked');
    }
  }

}

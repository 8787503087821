// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img-dropzone {
  display: flex;
  height: 400px;
  align-items: center;
  justify-content: center;
  color: #b0b0b0;
  font-size: 2rem;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/crop-image/crop-image.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;AACF","sourcesContent":[".img-dropzone {\r\n  display: flex;\r\n  height: 400px;\r\n  align-items: center;\r\n  justify-content: center;\r\n  color: #b0b0b0;\r\n  font-size: 2rem;\r\n  font-weight: bold;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

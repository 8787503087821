import { Component, OnInit } from '@angular/core';
import {DetailSellService} from '../../content/pages/payment/services/detail-sell.service';
import {last} from 'rxjs/operators';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss']
})
export class CalculatorComponent implements OnInit {
  subText = ''; // The text that should appear in the sub-display
  mainText = ''; // The text that should appear in the main display
  operand1: number; // The first operand
  operand2: number; // The second operand
  operator = ''; // The operator
  calculationString = '';
  // This is the string that denotes the operation being performed
  answered = false;
  // A flag to check whether the solution has been processed
  operatorSet = false; // You'll see how this is used soon
  percentage = false;
  constructor(
    public detailSellService: DetailSellService,

  ) { }

  ngOnInit() {

  }

  getPositionCalculator(event) {
    if (event.source._dragRef._activeTransform.x < 0 || event.source._dragRef._activeTransform.x < 0){
      this.detailSellService.calculatorPosition.x = 0;
      this.detailSellService.calculatorPosition.y = 0;
      return
    }
    this.detailSellService.calculatorPosition.x = event.source._dragRef._activeTransform.x;
    this.detailSellService.calculatorPosition.y = event.source._dragRef._activeTransform.y;

  }

  // Line 19 - Line 36
  pressKey(key: string) {
    if (key === '%'){
      this.percentage = true;
    }
    if (key === '±'){
      this.mainText = String(-(Number(this.mainText)))
      return;
    }
    if (key === '/' || key === 'x' || key === '-' || key === '+') {
    const lastKey = this.mainText[this.mainText.length - 1];
    if (lastKey === '/' || lastKey === 'x' || lastKey === '-' || lastKey === '+')  {
      this.operatorSet = true;
    } else{
      this.operatorSet = false;
    }

    if ((this.operatorSet) || (this.mainText === ''))
    {
      return;
    }
    this.operand1 = parseFloat(this.mainText);
    this.operator = key;
    this.operatorSet = true;
  }   if (this.mainText.length === 10) {
    return;
  }
  this.mainText += key;
  }
  getAnswer() {
    this.calculationString = this.mainText;
    this.operand2 = parseFloat(this.mainText.split(this.operator)[1]);
    if (this.percentage){
      this.operand2 = (this.operand1 * this.operand2)/100;
    }
    if (this.operator === '/') {
      this.subText = this.mainText;
      this.mainText = (this.operand1 / this.operand2).toString();
      this.subText = this.calculationString;
      if (this.mainText.length > 9) {
        this.mainText = this.mainText.substr(0, 9);
      }
    } else if (this.operator === 'x') {
      this.subText = this.mainText;
      this.mainText = (this.operand1 * this.operand2).toString();
      this.subText = this.calculationString;
      if (this.mainText.length > 9) {
        this.mainText = 'ERROR';
        this.subText = 'Range Exceeded';
      }
    } else if (this.operator === '-') {
      this.subText = this.mainText;
      this.mainText = (this.operand1 - this.operand2).toString();
      this.subText = this.calculationString;
    } else if (this.operator === '+') {
      this.subText = this.mainText;
      this.mainText = (this.operand1 + this.operand2).toString();
      this.subText = this.calculationString;
      if (this.mainText.length > 9) {
        this.mainText = 'ERROR';
        this.subText = 'Range Exceeded';
      }
    } else {
      this.subText = 'ERROR: Invalid Operation';
    }
    this.answered = true;
    this.subText='';
    this.percentage = false;
  }
  allClear() {
    this.subText='';
    this.mainText='';
    this.calculationString='';
    this.percentage = false;
    this.answered = false;

  }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subheader {
  display: flex;
  justify-content: space-between;
}

.table-container table .table-row:hover {
  background: #f4f4f4;
  color: white !important;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
}

td {
  vertical-align: middle;
  cursor: pointer;
}

tr.mat-footer-row, tr.mat-row {
  height: 40px;
}

@media screen and (max-width: 970px) {
  .subheader {
    flex-direction: column;
  }
  .subheader .action-btns {
    display: flex;
    justify-content: flex-end;
  }
  .table-container {
    margin-top: 20px;
    max-width: 100vw;
    overflow-x: scroll;
  }
  .table-container .mat-table {
    overflow: auto;
    width: 1123px;
  }
}
.calender {
  display: unset !important;
  width: unset !important;
}

.card.card-fullheight {
  height: unset !important;
}

.table-sub thead th:first-child {
  min-width: 140px;
}`, "",{"version":3,"sources":["webpack://./src/app/content/pages/sms/sms-history/sms-history.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;AACJ;;AACE;EACE,mBAAA;EACA,uBAAA;EACA,yBAAA;UAAA,iBAAA;EACA,eAAA;AAEJ;;AAAE;EACE,sBAAA;EACA,eAAA;AAGJ;;AADE;EACE,YAAA;AAIJ;;AAFE;EACE;IACE,sBAAA;EAKJ;EAJI;IACE,aAAA;IACA,yBAAA;EAMN;EAHE;IACE,gBAAA;IACA,gBAAA;IACA,kBAAA;EAKJ;EAJI;IACE,cAAA;IACA,aAAA;EAMN;AACF;AAFE;EACE,yBAAA;EACA,uBAAA;AAIJ;;AADE;EACG,wBAAA;AAIL;;AADA;EACE,gBAAA;AAIF","sourcesContent":[".subheader {\r\n    display: flex;\r\n    justify-content: space-between;\r\n  }\r\n  .table-container table .table-row:hover {\r\n    background: #f4f4f4;\r\n    color: white !important;\r\n    user-select: none;\r\n    cursor: pointer;\r\n  }\r\n  td {\r\n    vertical-align: middle;\r\n    cursor: pointer;\r\n  }\r\n  tr.mat-footer-row, tr.mat-row {\r\n    height: 40px;\r\n  }\r\n  @media screen and (max-width: 970px){\r\n    .subheader {\r\n      flex-direction: column;\r\n      .action-btns {\r\n        display: flex;\r\n        justify-content: flex-end;\r\n      }\r\n    }\r\n    .table-container {\r\n      margin-top: 20px;\r\n      max-width: 100vw;\r\n      overflow-x: scroll;\r\n      .mat-table{\r\n        overflow: auto;\r\n        width: 1123px;\r\n      }\r\n    }\r\n  }\r\n\r\n  .calender{\r\n    display: unset !important;\r\n    width: unset !important;\r\n  }\r\n\r\n  .card.card-fullheight {\r\n     height: unset !important;\r\n}\r\n\r\n.table-sub thead th:first-child {\r\n  min-width: 140px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {   MatDialog,   MatDialogRef } from '@angular/material/dialog';
import {   MAT_DIALOG_DATA } from '@angular/material/dialog';
import {   MatSnackBar } from '@angular/material/snack-bar';
import {Subject} from 'rxjs';
import {ThresholdFormComponent} from './threshold-form/threshold-form.component';
import {FidelityService} from '../../../../../shared/services/fidelity.service';
import {FidelityCardType} from '../../../../../shared/enum/fidelity-card-type';
import {ThresholdCard} from '../../../../../shared/models/threshold-card';
import {TranslateService} from '@ngx-translate/core';
import {SweetAlertService} from '../../../../../shared/services/sweet-alert.service';

@Component({
  selector: 'app-fidelity-threshold-form',
  templateUrl: './fidelity-point-form.component.html',
  styleUrls: ['./fidelity-point-form.component.scss']
})
export class FidelityPointFormComponent implements OnInit {
  submitted = false;
  fidelityPointForm: UntypedFormGroup;
  private formBuilder = new UntypedFormBuilder();
  unsubscribe$ = new Subject();
  thresholdsColumns = ['DATA_TABLE.NAME', 'DATA_TABLE.THRESHOLD'];
  thresholdsColumns2 = ['DATA_TABLE.TREATMENT', 'DATA_TABLE.PRODUCT'];
  thresholdsList: ThresholdCard[];

  constructor(public dialogRef: MatDialogRef<FidelityPointFormComponent>, private cardService: FidelityService,
              private matDialog: MatDialog, private snackBar: MatSnackBar, private translate: TranslateService,
              @Inject(MAT_DIALOG_DATA) public data: any, private sweetAlertService: SweetAlertService) {
  }

  ngOnInit() {

    this.fidelityPointForm = this.formBuilder.group({
      id: new UntypedFormControl(),
      name: new UntypedFormControl('', Validators.required),
      creationDate: new UntypedFormControl(new Date().toLocaleDateString()),
      euroSpent: new UntypedFormControl('', Validators.required),
      loadPoint: new UntypedFormControl('', Validators.required),
      cardType: new UntypedFormControl(FidelityCardType.POINT_WITH_GIFT),
      dueDate: new UntypedFormControl(),
    });

    if (this.data.editMode) {
      this.fidelityPointForm.patchValue(this.data.card);
      this.fidelityPointForm.get('creationDate').setValue(new Date(this.data.card.createdAt).toLocaleDateString());
      this.getThresholds();
    }
  }

  getThresholds() {
    this.cardService.getThresholds(this.fidelityPointForm.get('id').value).subscribe(r => {
      this.thresholdsList = r;
    });
  }

  addNewThreshold() {
    const dialogRef = this.matDialog.open(ThresholdFormComponent, {
      width: '900px',
      autoFocus: true,
      disableClose: true,
      data: {card: this.fidelityPointForm.getRawValue()}
    });
    dialogRef.afterClosed().subscribe(d => {
      if (d) {
        this.fidelityPointForm.get('id').setValue(d);
        this.getThresholds();
      }
    });

  }

  editThreshold(threshold: ThresholdCard) {
    const dialogRef = this.matDialog.open(ThresholdFormComponent, {
      width: '900px',
      autoFocus: true,
      disableClose: true,
      data: {card: this.fidelityPointForm.getRawValue(), threshold}
    });
    dialogRef.afterClosed().subscribe(d => {
      if (d) {
        this.fidelityPointForm.get('id').setValue(d);
        this.getThresholds();
      }
    });
  }

  deleteThreshold($event: MouseEvent, threshold: any) {
    $event.stopPropagation();
    $event.cancelBubble = true;
    this.sweetAlertService.warning(this.translate.instant('DIALOG.YOU_WANT_TO_DELETE') + threshold.name).then(e => {
      if (e.value) {
        this.cardService.deleteThreshold(threshold.id).subscribe(d => {
          if (d.status === 200) {
            this.getThresholds();
          }
        });
      }
    });
  }

  save() {
    this.submitted = true;
    if (!this.fidelityPointForm.valid) {
      return;
    }
    let dateTo = null;
    if (this.fidelityPointForm.get('dueDate').value) {
      dateTo = new Date(this.fidelityPointForm.get('dueDate').value);
      dateTo.setHours(dateTo.getHours() + 20);
      this.fidelityPointForm.get('dueDate').setValue(dateTo);
    }
    if (this.fidelityPointForm.get('id').value) {
      this.cardService.update(this.fidelityPointForm.getRawValue()).subscribe(d => {
        this.snackBar.open(this.translate.instant('DIALOG.UPDATE_SUCCESS'), 'Ok', {duration: 5000, panelClass: 'white-snackbar'});
      }, (err) => {
        this.fidelityPointForm.get('name').enable();
        this.fidelityPointForm.get('euroSpent').enable();
        this.fidelityPointForm.get('loadPoint').enable();
        this.snackBar.open(this.translate.instant('DIALOG.INTERNAL_SERVER_ERROR'), 'Ok', {duration: 5000});
      }, () => {
        this.dialogRef.close(true);
      });
    } else {
      this.cardService.create(this.fidelityPointForm.getRawValue()).subscribe(d => {
        this.snackBar.open(this.translate.instant('DIALOG.ADD_SUCCESS'), 'Ok', {duration: 5000, panelClass: 'white-snackbar'});
      }, (err) => {
        this.fidelityPointForm.get('name').enable();
        this.fidelityPointForm.get('euroSpent').enable();
        this.fidelityPointForm.get('loadPoint').enable();
        this.snackBar.open(this.translate.instant('DIALOG.INTERNAL_SERVER_ERROR'), 'Ok', {duration: 5000});
      }, () => {
        this.dialogRef.close(true);
      });
    }
  }

  sortRows(c: string) {

  }

  confirmAndClose(): void {
    if (this.fidelityPointForm.dirty) {
     this.sweetAlertService.showConfirmationAlert()
      .then((confirmation) => {
        if (confirmation) {
          this.dialogRef.close(null);
        }
      });}
    else{
      this.dialogRef.close(null);
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.fidelityPointForm.dirty) {
      $event.returnValue = true;
    }
  }
}

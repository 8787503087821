import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {RecoverPasswordService} from '../shared/services/recover-password.service';
import {finalize} from 'rxjs/operators';
import {TranslationLoaderService} from '../core/services/translation-loader.service';
import {DateAdapter} from '@angular/material/core';
import {TranslateService} from '@ngx-translate/core';
import {  MatSnackBar} from '@angular/material/snack-bar';
import {SweetAlertService} from '../shared/services/sweet-alert.service';

@Component({
  selector: 'app-recover-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  usernameForm: UntypedFormControl;
  loading = false;
  done = false;

  constructor(
    private recoverPasswordService: RecoverPasswordService,
    public translationLoaderService: TranslationLoaderService,
    private dateAdapter: DateAdapter<any>,
    private translateService: TranslateService,
    private sweetAlertService: SweetAlertService,
    private translate: TranslateService,
    private matSnackBar: MatSnackBar
  ) {
  }

  ngOnInit() {
    this.usernameForm = new UntypedFormControl(null, Validators.required);
  }

  recoverPassword() {
    if (this.usernameForm.invalid || this.loading) {
      return;
    }
    this.loading = true;
    this.recoverPasswordService.recoverPassword(this.usernameForm.value)
      .pipe(finalize(() => this.loading = false)).subscribe(d => {
      this.done = d;
      if (!d) {
        this.matSnackBar.open(this.translateService.instant('FORGET_PASSWORD.USERNAME_NOT_FOUND'), 'Ok');
      }
    }, error => {
      if (error.error.message.includes('Mail server connection failed')) {
        this.matSnackBar.open(this.translateService.instant('DIALOG.MAILING_ERROR'), 'Ok');
        // this.sweetAlertService.danger(this.translate.instant('DIALOG.MAILING_ERROR'));
      } else {
        this.matSnackBar.open(this.translateService.instant('DIALOG.INTERNAL_SERVER_ERROR'), 'Ok');
        // this.sweetAlertService.danger(this.translate.instant('DIALOG.INTERNAL_SERVER_ERROR'));
      }
    });
  }

  changeLang(lang: 'en' | 'it' | 'fr') {
    this.translationLoaderService.setLanguage(lang);
    this.dateAdapter.setLocale(lang);
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PromotionManagementFormComponent} from './promotion-management-form/promotion-management-form.component';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule, Routes} from '@angular/router';
import {SharedModule} from '../../../shared/shared.module';
import {ReactiveFormsModule} from '@angular/forms';
import {NgbDropdownModule, NgbModule, NgbPaginationModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {CurrencyMaskModule, CURRENCY_MASK_CONFIG} from 'ng2-currency-mask';
import {PromotionManagementComponent} from './promotion-management.component';
import {CustomCurrencyMaskConfig} from '../productsAndServices/product-management/product-management.module';
import {PromotionFormService} from './services/promotion-form.service';
import {DateTimeModule} from 'nxt-pick-datetime';
import {FilterClientsComponent} from './filter-clients/filter-clients.component';
import {PromoSmsHistoryComponent} from './promo-sms-history/promo-sms-history.component';
import {TemplateEditorComponent} from './template-editor/template-editor.component';
import {PickerModule} from '@ctrl/ngx-emoji-mart';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatLegacyButtonModule} from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import {MatSnackBar, MatSnackBarModule} from "@angular/material/snack-bar";

const routes: Routes = [
  {path: '', component: PromotionManagementComponent},
  {path: 'history', component: PromoSmsHistoryComponent}
];

// @ts-ignore
@NgModule({
  declarations: [
    PromotionManagementFormComponent,
    PromotionManagementComponent,
    FilterClientsComponent,
    PromoSmsHistoryComponent,
    TemplateEditorComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatLegacyButtonModule,
    MatIconModule,
    RouterModule.forChild(routes),
    SharedModule.forChild(),
    ReactiveFormsModule,
    NgbPaginationModule,
    MatDatepickerModule,
    NgbDropdownModule,
    CurrencyMaskModule,
    NgbTooltipModule,
    DateTimeModule,
    MatSnackBarModule,
    NgbModule,
    PickerModule,
  ],
  providers: [
    MatSnackBar,
    PromotionFormService,
    {provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig}

  ]
})
export class PromotionManagementModule {
}

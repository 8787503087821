import {Component, HostListener, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CustomSnackBarComponent} from '../../../../shared/custom-snack-bar/custom-snack-bar.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {   MatSnackBar } from '@angular/material/snack-bar';
import {HttpClient} from '@angular/common/http';
import {BreadcrumbService} from '../../../../core/services/breadcrumb.service';
import {SweetAlertService} from '../../../../shared/services/sweet-alert.service';
import {TranslateService} from '@ngx-translate/core';
import {MovementMgmService} from '../../../../shared/services/movement-mgm.service';
import {InventoryFormService} from './service/inventory-form.service';
import {Inventory} from '../../../../shared/models/Inventory';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {SellPointService} from '../../../../shared/services/sell-point.service';

@Component({
  selector: 'app-inventory-management',
  templateUrl: './inventory-management.component.html',
  styleUrls: ['./inventory-management.component.scss']
})
export class InventoryManagementComponent implements OnInit {

  columns = [
    '',
    'OPERATOR_FORM.SELL_POINT',
    'MOVEMENT_FORM.DATE',
    'MOVEMENT_FORM.NOTE',
    'MOVEMENT_FORM.DRAFT',
  ];
  loading = true;
  firstCall = true;
  rows: Inventory[] = [];
  searchFormControl: UntypedFormGroup;
  @ViewChild('inventoryFormContainer', {read: ViewContainerRef , static: false }) inventoryFormContainer: ViewContainerRef;
  public page = 1;
  public totalRecords: number;
  public pageSize = 10;
  lastInvId: number;
  user: any;
  sellPoints = [];
  affectedSellPoints = [];
  inventoryRequestForm: UntypedFormGroup;
  dialogRef: any;
  sellPointList = [];

  constructor(
    public snackBar: MatSnackBar,
    private http: HttpClient, private matDialog: MatDialog,
    private breadcrumbService: BreadcrumbService,
    public dialog: MatDialog,
    private movementMgmService: MovementMgmService,
    private sweetAlertService: SweetAlertService,
    private translate: TranslateService,
    private inventoryFormService: InventoryFormService,
    private localStorage: LocalStorageService,
    private sessionStorage: SessionStorageService,
    private sellPointService: SellPointService,
  ) {
    this.user = this.localStorage.retrieve('user') || this.sessionStorage.retrieve('user');
  }


  ngOnInit() {
    this.sendBreadCrumb();
    this.resetSearchForm();
    this.getSellPointsFromAccount();
    this.inventoryRequestForm = new UntypedFormGroup({
      sellPointId: new UntypedFormControl(null),
      loadAllPdts: new UntypedFormControl(false),
      date: new UntypedFormControl(new Date(), Validators.required)
    });
  }

  showSnackBar(data: any) {
    this.snackBar.openFromComponent(CustomSnackBarComponent, {
      data,
      duration: 5000,
      panelClass: 'white-snackbar'
    });
  }

  delete($event, el: Inventory): void {
    $event.cancelBubble = true;
    $event.stopPropagation();
    this.sweetAlertService.warning(this.translate.instant('DIALOG.YOU_WANT_TO_DELETE'))
      .then(res => {
        if (res.value) {
          this.movementMgmService.deleteInventory(el.id).subscribe(r => {
            this.showSnackBar({
              type: this.translate.instant('HEADER.BREADCRUMB.INVENTORY'),
              actionIcon: 'delete',
              actionMsg: this.translate.instant('DIALOG.DELETE_SUCCESS')
            });
            this.search();
          }, err => {
            if (err.status === 500) {
              this.showSnackBar({
                type: this.translate.instant('HEADER.BREADCRUMB.INVENTORY'),
                actionIcon: 'failed',
                actionMsg: this.translate.instant('DIALOG.CANNOT_DELETE')
              });
            }
          });
        }
      });
  }

  getSellPointsFromAccount() {
    this.sellPointService.getSellPointsByAccountByCentre().subscribe(res => {
      this.sellPoints = res.sellPoints;
      this.affectedSellPoints = res.sellPoints;
      this.sellPointList = res.sellPoints;
      const allSp = {sellPointId: -1, sellPointLabel: this.translate.instant('MOVEMENT_FORM.ALL'), siege: false};
      this.sellPoints = [...this.sellPoints, allSp];
    });
  }

  sendBreadCrumb(): void {
    this.breadcrumbService.sendBreadcrumb(['MOVEMENT', 'INVENTORY']);
  }

  edit(data, i) {
    // this.sweetAlertService.warning(this.translate.instant('DIALOG.YOU_WANT_TO_DISPLAY_TUTTI_PDT'),
    //   this.translate.instant('BUTTONS.YES'), this.translate.instant('BUTTONS.NO')).then(u => {
    const formRef = this.inventoryFormService.openForm(this.inventoryFormContainer, {
      editMode: true,
      // loadAllPdts: u.value,
      loadAllPdts: false,
      inventory: data
    });
    formRef.instance.onClose.subscribe(d => {
      if (d) {
        this.showSnackBar({
          type: this.translate.instant('HEADER.BREADCRUMB.INVENTORY'),
          actionIcon: 'save',
          actionMsg: this.translate.instant('DIALOG.ADD_SUCCESS')
        });
      }
      this.search();
    });
    // });
  }

  addNew() {

    this.sweetAlertService.warning(this.translate.instant('DIALOG.YOU_WANT_TO_DISPLAY_TUTTI_PDT'),
      this.translate.instant('BUTTONS.YES'), this.translate.instant('BUTTONS.NO')).then(u => {
      const formRef = this.inventoryFormService.openForm(this.inventoryFormContainer, {
        editMode: false,
        loadAllPdts: u.value
      });
      formRef.instance.onClose.subscribe(d => {
        if (d) {
          this.showSnackBar({
            type: this.translate.instant('HEADER.BREADCRUMB.INVENTORY'),
            actionIcon: 'save',
            actionMsg: this.translate.instant('DIALOG.ADD_SUCCESS')
          });
        }
        this.search();
      });
    });
  }


  search() {
    this.lastInvId = null;
    const request = {
      page: this.page,
      pageSize: this.pageSize,
      number: null,
      dateFrom: this.searchFormControl.get('dateFrom').value,
      dateTo: this.searchFormControl.get('dateTo').value,
      sellPoint: this.searchFormControl.get('sellPoint').value,
      allSpIds: this.affectedSellPoints.map(u => u.sellPointId),
    };
    this.loading = true;
    this.movementMgmService.getLastInventoryId().subscribe(r => {
      this.lastInvId = r;
    });
    return this.movementMgmService.searchInventory(request).subscribe(data => {
      this.rows = data.data;
      this.loading = false;
      this.firstCall = false;
      this.totalRecords = data.totalRecords;
    });
  }

  pageChange(page: number) {
    this.page = page;
    this.search();
  }

  resetSearchForm() {
    this.searchFormControl = new UntypedFormGroup({
      dateFrom: new UntypedFormControl(null),
      dateTo: new UntypedFormControl(null),
      sellPoint: new UntypedFormControl(this.user.sellPointId)
    });
    this.filter();
  }

  filter() {
    this.page = 1;
    this.search();
  }

  openContent(content) {
    this.dialogRef = this.matDialog.open(content, {
      closeOnNavigation: true,
      disableClose: true,
      width: '900px',
      maxHeight: '95vh',
    });
  }

  openAddInventory() {
    this.matDialog.closeAll();
    const formRef = this.inventoryFormService.openForm(this.inventoryFormContainer, {
      editMode: false,
      ...this.inventoryRequestForm.value
    });
    formRef.instance.onClose.subscribe(d => {
      if (d) {
        this.showSnackBar({
          type: this.translate.instant('HEADER.BREADCRUMB.INVENTORY'),
          actionIcon: 'save',
          actionMsg: this.translate.instant('DIALOG.ADD_SUCCESS')
        });
      }
      this.search();
    });
  }
}

import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CropImageComponent, ImageErrorSnackBarComponent} from './crop-image/crop-image.component';
import {MatLegacyDialog as MatDialog, MatLegacyDialogModule} from '@angular/material/legacy-dialog';
import {ImageCropperModule} from 'ngx-image-cropper';
import {MatIconModule} from '@angular/material/icon';
import {CustomSnackBarComponent} from './custom-snack-bar/custom-snack-bar.component';
import {MatLegacyButtonModule} from '@angular/material/legacy-button';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDividerModule} from '@angular/material/divider';
import {DialogComponent} from './dialogComponent/dialog-component';
import {DropdownSelectComponent} from './dropdown-select/dropdown-select.component';
import {TranslateModule} from '@ngx-translate/core';
import {ReactiveFormsModule} from '@angular/forms';
import {NgbDropdownModule, NgbTypeaheadModule} from '@ng-bootstrap/ng-bootstrap';
import {ClientMgmService} from './services/client-mgm.service';
import {ProviderMgmService} from './services/provider-mgm.service';
import {CompanyMgmService} from './services/company-mgm.service';
import {ConfigurationsService} from './services/configurations.service';
import {SweetAlertService} from './services/sweet-alert.service';
import {NgSelectModule} from '@ng-select/ng-select';
import {ZipCodeService} from './services/zip-code.service';
import {ProductMgmService} from './services/product-mgm.service';
import {CabinService} from './services/cabin.service';
import {CenterService} from './services/center.service';
import {SellPointService} from './services/sell-point.service';
import {RoleService} from './services/role.service';
import {AuthService} from './services/auth-jwt.service';
import {StateStorageService} from './services/state-storage.service';
import {UserService} from './services/user.service';
import {AuthGuard} from './guard/auth-guard';
import {YearPlanningService} from './services/year-planning.service';
import {BarCodeMgmService} from './services/bar-code-mgm.service';
import {FidelityService} from './services/fidelity.service';
import {BookingService} from './services/booking.service';
import {PaymentService} from './services/payment.service';
import {HasAnyAuthorityDirective} from './has-any-authority.directive';
import {TintService} from './services/tint.service';
import {QuoteService} from './services/quote.service';
import {PromotionMgmService} from './services/promotion-mgm.service';
import {FlipCreditCardComponent} from './flip-credit-card/flip-credit-card.component';
import {CalculatorComponent} from './calculator/calculator.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {LocalTranslate} from './pipes/local-translate.pipes';
import {CompareFilterDateModule} from './compare-filter-date/compare-filter-date.module';

@NgModule({
  declarations: [
    CropImageComponent,
    ImageErrorSnackBarComponent,
    CustomSnackBarComponent,
    DialogComponent,
    DropdownSelectComponent,
    HasAnyAuthorityDirective,
    FlipCreditCardComponent,
    FlipCreditCardComponent,
    CalculatorComponent,
    LocalTranslate,
  ],
  imports: [
    CommonModule,
    MatLegacyDialogModule,
    ImageCropperModule,
    MatIconModule,
    MatLegacyButtonModule,
    MatSnackBarModule,
    MatDividerModule,
    TranslateModule,
    ReactiveFormsModule,
    NgbTypeaheadModule,
    NgbDropdownModule,
    NgSelectModule,
    MatProgressSpinnerModule,
    DragDropModule,
  ],
  exports: [
    MatLegacyDialogModule,
    ImageCropperModule,
    CropImageComponent,
    CustomSnackBarComponent,
    DropdownSelectComponent,
    NgSelectModule,
    HasAnyAuthorityDirective,
    FlipCreditCardComponent,
    CalculatorComponent,
    LocalTranslate,
  ],
  providers: [
    LocalTranslate
  ]
})
export class SharedModule {

  static forChild(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        ClientMgmService,
        ProviderMgmService,
        ProductMgmService,
        CompanyMgmService,
        ConfigurationsService,
        SweetAlertService,
        ZipCodeService,
        CabinService,
        CenterService,
        SellPointService,
        YearPlanningService,
        SellPointService,
        AuthGuard,
        AuthService,
        StateStorageService,
        UserService,
        SellPointService,
        RoleService,
        BarCodeMgmService,
        FidelityService,
        PaymentService,
        BookingService,
        TintService,
        QuoteService,
        PromotionMgmService,
        CompareFilterDateModule,
      ]
    };
  }
}

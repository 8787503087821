// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `td {
  min-width: 90px !important;
}

@media screen and (max-width: 700px) {
  .form-container {
    width: 100%;
    bottom: 0 !important;
  }
}
/*
@media (max-width: 767px) {
    .table-responsive .dropdown-menu {
        position: static !important;
        max-height: 200px;
        overflow-y: scroll;
    }
}
@media (min-width: 768px) {
    .table-responsive {
        overflow: inherit;
    }
}
*/
/*.ng-dropdown-panel.total-padding {
    min-height: 135px !important;
    overflow-y: scroll !important;
}*/`, "",{"version":3,"sources":["webpack://./src/app/content/pages/productsAndServices/subscription-management/subscription-form/subscription-form.component.scss"],"names":[],"mappings":"AAAA;EACI,0BAAA;AACJ;;AACA;EACE;IACE,WAAA;IACA,oBAAA;EAEF;AACF;AAAA;;;;;;;;;;;;;CAAA;AAeA;;;EAAA","sourcesContent":["td {\r\n    min-width: 90px !important;\r\n}\r\n@media screen and (max-width: 700px) {\r\n  .form-container {\r\n    width: 100%;\r\n    bottom: 0 !important;\r\n  }\r\n}\r\n/*\r\n@media (max-width: 767px) {\r\n    .table-responsive .dropdown-menu {\r\n        position: static !important;\r\n        max-height: 200px;\r\n        overflow-y: scroll;\r\n    }\r\n}\r\n@media (min-width: 768px) {\r\n    .table-responsive {\r\n        overflow: inherit;\r\n    }\r\n}\r\n*/\r\n\r\n/*.ng-dropdown-panel.total-padding {\r\n    min-height: 135px !important;\r\n    overflow-y: scroll !important;\r\n}*/\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

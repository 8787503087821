import {Component, Inject, OnInit} from '@angular/core';
import {Profession} from '../../../../shared/models/profession';
import {ClientMgmService} from '../../../../shared/services/client-mgm.service';
import {ConfigurationsService} from '../../../../shared/services/configurations.service';
import {Company} from '../../../../shared/models/company';
import {CompanyMgmService} from '../../../../shared/services/company-mgm.service';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Client} from '../../../../shared/models/client';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {MovementType} from '../../../../shared/enum/movement-type';
import {TranslateService} from '@ngx-translate/core';
import {LastPassage} from '../../../../shared/enum/last-passage';
import {PromotionMgmService} from '../../../../shared/services/promotion-mgm.service';
import {ZipCodeService} from '../../../../shared/services/zip-code.service';

@Component({
  selector: 'app-filter-clients',
  templateUrl: './filter-clients.component.html',
  styleUrls: ['./filter-clients.component.scss']
})
export class FilterClientsComponent implements OnInit {
  professionsList: Profession[] = [];
  professionPage = 1;
  companiesList: Company[] = [];
  lastPassages = [];
  filterClientForm: UntypedFormGroup;
  rows: Client[] = [];
  public page = 1;
  public totalRecords: number;
  public pageSize = 10;
  selectedClients: number[] = [];
  dialogRef: any;
  provinces: string[] = [];

  constructor(
    private clientService: ClientMgmService,
    private configurationsService: ConfigurationsService,
    private translate: TranslateService,
    public matDialogRef: MatDialogRef<FilterClientsComponent>,
    private companyService: CompanyMgmService,
    private zipCodeService: ZipCodeService,
    @Inject(MAT_DIALOG_DATA) public data: number[],
    private promoService: PromotionMgmService,
  ) {
  }

  ngOnInit() {
    this.filterClientForm = new UntypedFormGroup({
      gender: new UntypedFormArray([]),
      professionId: new UntypedFormControl(null),
      companyId: new UntypedFormControl(null),
      province: new UntypedFormControl(null),
      dateOfBirthFrom: new UntypedFormControl(null),
      dateOfBirthTo: new UntypedFormControl(null),
      lastPassage: new UntypedFormControl(null),
      page: new UntypedFormControl(1),
    });
    this.getProfessionsList();
    this.companyService.getAllCompanies().subscribe(d => {
      this.companiesList = d;
    });
    if (this.data && this.data.length) {
      this.clientService.getClientsByIds(this.data).subscribe(r => {
        if (r) {
          this.rows = r;
          this.totalRecords = r.length;
          this.rows.forEach(c => {
            if (this.selectedClients.indexOf(c.id) === -1) {
              this.selectedClients = [...this.selectedClients, c.id];
            }
          });
        }
      });
    }
    this.zipCodeService.getProvinces().subscribe(r => this.provinces = r);
  }

  getProfessionsList() {
    this.configurationsService.getLazyProfessions({page: this.professionPage, pageSize: 10}).subscribe(d => {
      this.professionsList = [...this.professionsList, ...d.data];
      this.professionPage++;
    });
  }

  translatePassages() {
    this.lastPassages = [{description: this.translate.instant('PROMO_FORM.' + LastPassage.LAST_MONTH), id: LastPassage.LAST_MONTH},
      {description: this.translate.instant('PROMO_FORM.' + LastPassage.LAST_THREE_MONTHS), id: LastPassage.LAST_THREE_MONTHS},
      {description: this.translate.instant('PROMO_FORM.' + LastPassage.LAST_SIX_MONTHS), id: LastPassage.LAST_SIX_MONTHS},
      {description: this.translate.instant('PROMO_FORM.' + LastPassage.LAST_NINE_MONTHS), id: LastPassage.LAST_NINE_MONTHS},
      {description: this.translate.instant('PROMO_FORM.' + LastPassage.LAST_YEAR), id: LastPassage.LAST_YEAR}];
  }

  filter() {
    this.clientService.filterClientsForPromo(this.filterClientForm.getRawValue()).subscribe(r => {
      this.rows = r.data;
      this.totalRecords = r.totalRecords;
      this.selectedClients = [];
      this.rows.forEach(c => {
        if (this.selectedClients.indexOf(c.id) === -1) {
          this.selectedClients = [...this.selectedClients, c.id];
        }
      });
    });
  }

  onCheckChange(event) {
    const formArray: UntypedFormArray = this.filterClientForm.get('gender') as UntypedFormArray;
    if (event.target.checked) {
      formArray.push(new UntypedFormControl(event.target.value));
    } else {
      let i = 0;
      formArray.controls.forEach((ctrl: UntypedFormControl) => {
        if (ctrl.value === event.target.value) {
          formArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  isSelectedClient(c) {
    return this.selectedClients.indexOf(c.id) !== -1;
  }

  updateClientList(c: Client) {
    const index = this.selectedClients.indexOf(c.id);
    if (index === -1) {
      this.selectedClients = [...this.selectedClients, c.id];
      return;
    }
    this.selectedClients.splice(index, 1);
  }

  selectAll() {
    if (this.selectedClients.length === this.totalRecords) {
      this.selectedClients = [];
      return;
    }
    this.rows.forEach(c => {
      if (this.selectedClients.indexOf(c.id) === -1) {
        this.selectedClients = [...this.selectedClients, c.id];
      }
    });
  }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-outline-gray {
  border: 1.2px solid #b2b2b2;
  background: white;
  color: #b2b2b2;
}

.btn-outline-gray.active {
  border: 1.2px solid #262e89;
  background-color: #262e89;
  color: white;
}

.no-border-radius {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/administrative-content/pages/owner/components/group-mgm/date-mgm-form-modal/date-mgm-form-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,iBAAA;EACA,cAAA;AACF;;AACA;EACE,2BAAA;EACA,yBAAA;EACA,YAAA;AAEF;;AAAA;EACE,0BAAA;EACA,6BAAA;AAGF","sourcesContent":[".btn-outline-gray {\r\n  border: 1.2px solid #b2b2b2;\r\n  background: white;\r\n  color: #b2b2b2;\r\n}\r\n.btn-outline-gray.active {\r\n  border: 1.2px solid #262e89;\r\n  background-color: #262e89;\r\n  color: white;\r\n}\r\n.no-border-radius {\r\n  border-top-right-radius: 0;\r\n  border-bottom-right-radius: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SatispayComponent} from './satispay.component';
import {RouterModule, Routes} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {MatLegacyDialogModule} from '@angular/material/legacy-dialog';

const routes: Routes = [
  {path: '', component: SatispayComponent}
]

@NgModule({
  declarations: [SatispayComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    TranslateModule,
    MatLegacyDialogModule
  ]
})
export class SatispayModule {
}

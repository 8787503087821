import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {Subscription} from 'rxjs';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {UserService} from './services/user.service';

@Directive({
  selector: '[appHasAnyAuthority]'
})
export class HasAnyAuthorityDirective implements OnInit {
  private authorities: string[] = [];
  private authenticationSubscription?: Subscription;
  operatorAuthorities = ['Dashboard', 'Planning', 'Contacts', 'Product and services', 'Sms', 'Promo', 'Report', 'Quote',
    'Configuration table', 'Payment', 'Payment History',  'Carico', 'Scarico', 'inventory', 'Sales'];
  adminAuthorities = ['Dashboard', 'Planning', 'Contacts', 'Product and services', 'Sms', 'Promo', 'Report', 'Quote',
    'Configuration table', 'Payment', 'Payment History', 'Administration',  'Carico', 'Scarico', 'inventory', 'Sales'];

  constructor(private elementRef: ElementRef,
              private userService: UserService,
              private renderer: Renderer2,
              private localStorageService: LocalStorageService,
              private sessionStorageService: SessionStorageService) {
  }

  @Input() module: string;
  ngOnInit(): void {
    if (!this.authorized(this.module)) {
      this.renderer.addClass(this.elementRef.nativeElement, 'disable-menu');
    }
  }

  private authorized(module) {
    const user = this.userService.getUser();
    return user.authorities.indexOf(module) > -1;
  }

}

import {ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector} from '@angular/core';
import {TreatmentManagementFormComponent} from '../treatment-management-form/treatment-management-form.component';
import {SweetAlertService} from '../../../../../shared/services/sweet-alert.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class TreatmentFormService {

  dialogComponentRef: ComponentRef<TreatmentManagementFormComponent>;

  constructor( private componentFactoryResolver: ComponentFactoryResolver,
               private appRef: ApplicationRef,
               private injector: Injector,
               private sweetAlertService: SweetAlertService,
               private translate: TranslateService) { }

  openForm(container, data?: any) {
    // close form if opened
    if (this.dialogComponentRef) {
      // @ts-ignore
      if (( this.dialogComponentRef.instance.deleted || this.dialogComponentRef.instance.treatmentForm.dirty || this.dialogComponentRef.instance.categoryFormControl.dirty
        || this.dialogComponentRef.instance.disableCabine.dirty
        || this.dialogComponentRef.instance.operatorTimeFormControl.dirty
        || this.dialogComponentRef.instance.disableOperator.dirty || this.dialogComponentRef.instance.masterPriceFormControl.dirty
        || this.dialogComponentRef.instance.beautyCenterCostFormControl.dirty ||
        this.dialogComponentRef.instance.averageOperatorCostFormControl.dirty || this.dialogComponentRef.instance.prodCodeFormControl.dirty || this.dialogComponentRef.instance.quantityFormControl.dirty
        || this.dialogComponentRef.instance.treatmentForm.dirty || this.dialogComponentRef.instance.relatedProductsForm.dirty )
            && !this.dialogComponentRef.instance.disableSave ) {
        this.sweetAlertService.warning(
          this.translate.instant('PLANNING.CONFIRM_CANCELLATION')).then(e => {
          if (e.value) {
            this.appRef.detachView(this.dialogComponentRef.hostView);
            this.dialogComponentRef.destroy();
            this.dialogComponentRef = this.createComponent(container, data);
          }
        });
      } else {
        this.appRef.detachView(this.dialogComponentRef.hostView);
        this.dialogComponentRef.destroy();
        this.dialogComponentRef = this.createComponent(container, data);
      }
    } else {
      // open form component and pass data to it
      this.dialogComponentRef = this.createComponent(container, data);
    }
    // return a ref of the opened form component
    return this.dialogComponentRef;
  }

  createComponent(container, data?: any) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(TreatmentManagementFormComponent);
    componentFactory.create(this.injector);
    const componentRef = container.createComponent(componentFactory);
    componentRef.instance.editMode = data?.editMode;
    componentRef.instance.treatment = data?.treatment;
    componentRef.instance.appRef = this.appRef;
    componentRef.instance.dialogComponentRef = componentRef;
    return componentRef;
  }

}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .ng-select-barcode-type.ng-dropdown-panel {
  z-index: 1500;
}

table .badge {
  margin: 3px;
}`, "",{"version":3,"sources":["webpack://./src/app/administrative-content/pages/admin-module/components/operator-mgm/operator-mgm.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AACA;EACE,WAAA;AAEF","sourcesContent":["::ng-deep .ng-select-barcode-type.ng-dropdown-panel {\r\n  z-index: 1500;\r\n}\r\ntable .badge {\r\n  margin: 3px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {SocialPostsComponent} from "./social-posts.component";
import {NgSelectModule} from "@ng-select/ng-select";
import {NgbPaginationModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {  MatLegacyDialogModule} from "@angular/material/legacy-dialog";
import {  MatButtonModule} from '@angular/material/button';

const routes: Routes = [
  {path: '', component: SocialPostsComponent}
];

@NgModule({
  declarations: [
    SocialPostsComponent
  ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        NgSelectModule,
        NgbPaginationModule,
        TranslateModule,
        MatLegacyDialogModule,
        MatButtonModule
    ]
})
export class SocialPostsModule {
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table th, .table td {
  border-bottom: none;
  padding: 0.2rem !important;
}

.card.card-fullheight {
  height: max-content;
}

.card-x {
  height: calc(100% - 1.8rem) !important;
}

@media (max-width: 767.98px) {
  .font-16, .table {
    font-size: 13px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/content/pages/reports/fiscal-reports/fiscal-reports.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,0BAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,sCAAA;AACF;;AAEA;EACA;IACE,0BAAA;EACA;AACF","sourcesContent":[".table th, .table td {\r\n  border-bottom: none ;\r\n  padding: 0.2rem !important;\r\n}\r\n\r\n.card.card-fullheight {\r\n  height: max-content;\r\n}\r\n\r\n.card-x{\r\n  height: calc(100% - 1.8rem) !important;\r\n}\r\n\r\n@media (max-width: 767.98px){\r\n.font-16, .table {\r\n  font-size: 13px !important;\r\n}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

export const BLANK_MAIL = {
  counters: {u_column: 4, u_row: 4}, body: {
    rows: [
      {
        cells: [1],
        columns: [{
          contents: [],
          values: {
            backgroundColor: "",
            padding: "0px",
            border: {},
            _meta: {htmlID: "u_column_1", htmlClassNames: "u_column"}
          }
        }],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {url: "", fullWidth: true, repeat: false, center: true, cover: false},
          padding: "0px",
          hideDesktop: false,
          hideMobile: false,
          noStackMobile: false,
          _meta: {htmlID: "u_row_1", htmlClassNames: "u_row"},
          selectable: false,
          draggable: false,
          duplicatable: false,
          deletable: false
        }
      }, {
        cells: [1],
        columns: [{
          contents: [
            {
              "type": "image",
              "values": {
                "containerPadding": "10px",
                "_meta": {
                  "htmlID": "u_content_image_2",
                  "htmlClassNames": "u_content_image"
                },
                "selectable": true,
                "draggable": false,
                "duplicatable": false,
                "deletable": false,
                "src": {
                  "url": "https://via.placeholder.com/250x250?text=IMAGE",
                  "width": 250,
                  "height": 250
                },
                "textAlign": "center",
                "altText": "Image",
                "action": {
                  "name": "web",
                  "values": {
                    "href": "",
                    "target": "_blank"
                  }
                },
                "hideDesktop": false,
                "hideMobile": false
              }
            }
          ],
          values: {
            backgroundColor: "",
            padding: "0px",
            border: {},
            _meta: {htmlID: "u_column_4", htmlClassNames: "u_column"}
          }
        }],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {url: "", fullWidth: true, repeat: false, center: true, cover: false},
          padding: "0px",
          hideDesktop: false,
          hideMobile: false,
          noStackMobile: false,
          _meta: {htmlID: "u_row_4", htmlClassNames: "u_row"},
          selectable: false,
          draggable: false,
          duplicatable: false,
          deletable: false
        }
      }, {
        cells: [1],
        columns: [{
          contents: [
            {
              "type": "text",
              "values": {
                "containerPadding": "10px",
                "_meta": {"htmlID": "u_content_text_1", "htmlClassNames": "u_content_text"},
                "selectable": true,
                "draggable": false,
                "duplicatable": false,
                "deletable": false,
                "color": "#000000",
                "textAlign": "left",
                "lineHeight": "140%",
                "linkStyle": {
                  "inherit": true,
                  "linkColor": "#0000ee",
                  "linkHoverColor": "#0000ee",
                  "linkUnderline": true,
                  "linkHoverUnderline": true
                },
                "hideDesktop": false,
                "hideMobile": false,
                "text": "<p style=\"font-size: 14px; line-height: 140%;\">This is a new Text block. Change the text.</p>"
              }
            }
          ],
          values: {
            backgroundColor: "",
            padding: "0px",
            border: {},
            _meta: {htmlID: "u_column_3", htmlClassNames: "u_column"}
          }
        }],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {url: "", fullWidth: true, repeat: false, center: true, cover: false},
          padding: "0px",
          hideDesktop: false,
          hideMobile: false,
          noStackMobile: false,
          _meta: {htmlID: "u_row_3", htmlClassNames: "u_row"},
          selectable: false,
          draggable: false,
          duplicatable: false,
          deletable: false
        }
      }, {
        cells: [1],
        columns: [{
          contents: [],
          values: {
            backgroundColor: "",
            padding: "0px",
            border: {},
            _meta: {htmlID: "u_column_3", htmlClassNames: "u_column"}
          }
        }],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {url: "", fullWidth: true, repeat: false, center: true, cover: false},
          padding: "0px",
          hideDesktop: false,
          hideMobile: false,
          noStackMobile: false,
          _meta: {htmlID: "u_row_3", htmlClassNames: "u_row"},
          selectable: false,
          draggable: false,
          duplicatable: false,
          deletable: false
        }
      }, {
        cells: [1],
        columns: [{
          contents: [],
          values: {
            backgroundColor: "",
            padding: "0px",
            border: {},
            _meta: {htmlID: "u_column_3", htmlClassNames: "u_column"}
          }
        }],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {url: "", fullWidth: true, repeat: false, center: true, cover: false},
          padding: "0px",
          hideDesktop: false,
          hideMobile: false,
          noStackMobile: false,
          _meta: {htmlID: "u_row_3", htmlClassNames: "u_row"},
          selectable: false,
          draggable: false,
          duplicatable: false,
          deletable: false
        }
      }],
    values: {
      backgroundColor: "#e7e7e7",
      backgroundImage: {url: "", fullWidth: true, repeat: false, center: true, cover: false},
      contentWidth: "500px",
      contentAlign: "center",
      fontFamily: {label: "Arial", value: "arial,helvetica,sans-serif"},
      preheaderText: "",
      linkStyle: {
        body: true,
        linkColor: "#0000ee",
        linkHoverColor: "#0000ee",
        linkUnderline: true,
        linkHoverUnderline: true
      },
      _meta: {htmlID: "u_body", htmlClassNames: "u_body"}
    }
  }, schemaVersion: 5
};

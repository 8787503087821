export const NAVIGATION: any[] = [
  {
    heading: true,
    title: 'MENU'
  },
  {
    title: 'Dashboard',
    icon: 'ft-home',
    exact: true,
    url: '/',
    translate: 'SIDENAV.DASHBOARD',
    module: 'DASHBOARD'
  },
  // {
  //   title: 'Planning',
  //   icon: 'ft-calendar',
  //   exact: true,
  //   url: '/planning',
  //   translate: 'Planning',
  //   module: 'PLANNING'
  // },
  // {
  //   title: 'Payment',
  //   icon: 'ft-shopping-cart',
  //   url: '/payment',
  //   translate: 'SIDENAV.PAYMENT',
  //   module: 'PAYMENT'
  // },
  {
    title: 'Contacts',
    icon: 'ft-phone',
    url: '',
    translate: 'SIDENAV.CONTACTS',
    children: [
      {title: 'Clienti', translate: 'SIDENAV.CLIENT', url: '/client-mgm', exact: true, module: 'CLIENT'},
      // {title: 'Aziende', translate: 'SIDENAV.COMPANY', url: '/company-mgm', exact: true, module: 'COMPANY'},
      {title: 'Fornitori', translate: 'SIDENAV.PROVIDER', url: '/provider-mgm', exact: true, module: 'PROVIDER'},
      //  {title: 'Staff', translate: 'SIDENAV.OPERATORS', url: '/operator-mgm', exact: true}
    ]
  },
  {
    title: 'Back-Office',
    icon: 'ft-archive',
    url: '',
    translate: 'BackOffice',
    children: [
      {title: 'Products', translate: 'SIDENAV.PRODUCT', url: '/product-mgm', exact: true, module: 'ARTICLE'},
      // {title: 'Servizi', translate: 'SIDENAV.TREATMENT', url: '/treatment-mgm', exact: true, module: 'TREATMENT'},
      {title: 'stock', translate: 'SIDENAV.STOCK', url: '/stock', exact: true, module: 'STOCK'},
      { title: 'Movement',
        translate: 'SIDENAV.MOVEMENT',
        children: [
          {title: 'Carico', translate: 'SIDENAV.CARICO',  url: '/carico', exact: true, module: 'CARICO'},
          {title: 'Scarico', translate: 'SIDENAV.SCARICO', url: '/scarico', exact: true, module: 'SCARICO'},
          {title: 'Transfer', translate: 'SIDENAV.TRANSFER', url: '/transfer', exact: true, module: 'TRANSFER'},
          {title: 'inventory', translate: 'SIDENAV.INVENTORY', url: '/inventory', exact: true, module: 'INVENTORY'},
        ]

      },
      // {title: 'Quote', translate: 'SIDENAV.QUOTE', url: '/quote', exact: true, module: 'QUOTE'},
      // {title: 'Subscription', translate: 'SIDENAV.SUBSCRIPTION', url: '/subscription-mgm', exact: true, module: 'SUBSCRIPTION'},
      // {title: 'fidelity management', translate: 'SIDENAV.FIDELITY', url: '/fidelity', exact: true, module: 'FIDELITY'},
      {
        title: 'document management',
        translate: 'SIDENAV.DOCUMENT_MANAGEMENT',
        url: '',
        icon: 'ft-file',
        module: 'DOCUMENT_MANAGEMENT',
        children: [
          {title: 'Payment History', translate: 'SIDENAV.PAYMENT_HISTORY', url: '/payment-history', exact: true, module: 'DOCUMENT_MANAGEMENT'},
          {title: 'Invoice', translate: 'Fatture', url: '/invoice', exact: true, module: 'DOCUMENT_MANAGEMENT'},
        ]
      },
      // {
      //   title: 'CASH DESK',
      //   translate: 'SIDENAV.CASH_DESK',
      //   module: 'CASH_DESK',
      //   children: [
      //     {title: 'Fiscal', translate: 'SIDENAV.FISCAL_REPORT', url: '/fiscal_report', exact: true, module: 'CASH_DESK'},
      //     {title: 'Close RT', translate: 'SIDENAV.CASH_REGISTER_CLOSURE', url: '/rt-conf/close', exact: true, module: 'CASH_DESK'},
      //   ]
      // },
      {
        title: 'First Note',
        icon: 'fas fa-money-bill',
        translate: 'SIDENAV.FIRST_NOTE',
        module: 'FIRST_NOTE',
        children: [
          {title: 'First Note', icon: 'fas fa-money-bill', url: '/first-note', translate: 'SIDENAV.FIRST_NOTE', exact: true, module: 'FIRST_NOTE'},
          {title: 'First Note Cause', exact: true, url: '/first-note-cause', translate: 'SIDENAV.FIRST_NOTE_CAUSE_SETTING', module: 'FIRST_NOTE'}
        ]
      },
    ]
  },
  {
    title: 'Marketing',
    icon: 'fas fa-bullhorn',
    translate: 'Marketing',
    url: '',
    children: [
      {
        title: 'Crea promozione',
        translate: 'SIDENAV.PROMOTION',
        module: 'PROMOTION',
        children: [
          {
            title: 'Crea promozione',
            translate: 'SIDENAV.ENTER_PROMOTION',
            url: '/promotion',
            exact: true,
            module: 'PROMOTION',
          },
          {
            title: 'Promotion template editor',
            translate: 'Template promo',
            url: '/promotion-template-editor',
            exact: true,
            module: 'PROMOTION',
          },
          {
            title: 'Social posts',
            translate: 'SIDENAV.SOCIAL_POSTS',
            url: '/social-posts',
            exact: true,
            module: 'PROMOTION',
          }
        ]
      },
      {
        title: 'Sms',
        icon: 'ft-mail',
        translate: 'Promo SMS & WhatsApp',
        module: 'PROMO_SMS',
        children: [
          {title: 'Bulk & Messaging compaigns', translate: 'SIDENAV.SMS_WhatsApp_COMPAIGNS', url: '/sms-compaigns', exact: true, module: 'PROMO_SMS'},
          {title: 'Historic', translate: 'SIDENAV.SMS_WhatsApp_INSTANCE', url: '/sms-history', exact: true, module: 'PROMO_SMS'}
        ]
      }
    ]
  },
  {
    title: 'Statistics',
    icon: 'ft-file-text',
    translate: 'SIDENAV.STATISTICS',
    url: '',
    children: [
      {title: 'Product', translate: 'SIDENAV.PRODUCT_REPORT', url: '/product_report', exact: true, module: 'PRODUCT_REPORTS'},
      // {title: 'Treatment', translate: 'SIDENAV.TREATMENT_REPORT', url: '/treatment_report', exact: true, module: 'TREATMENT_REPORTS'},
      {title: 'Operator', translate: 'SIDENAV.OPERATOR_REPORT', url: '/operator_report', exact: true, module: 'OPERATOR_REPORTS'}
    ]
  },
  {
    title: 'Configuration table',
    icon: 'ft-settings',
    url: '',
    translate: 'SIDENAV.CONFIGURATIONS',
    children: [
      {title: 'Administration', url: '/admin', translate: 'SIDENAV.ADMINISTRATION', module: 'ADMINISTRATION'},
      {
        title: 'Setup sell point', translate: 'SIDENAV.SETUP_SELL_POINT', module: 'SETUP_SELL_POINT',
        children: [
          {title: 'Codice IVA', translate: 'SIDENAV.VAT_CODE', url: '/vat-code-conf', exact: true, module: 'SETUP_SELL_POINT'},
          // {title: 'week planning management', translate: 'SIDENAV.WEEK_PLANNING', url: '/week-planning', exact: true, module: 'SETUP_SELL_POINT'},
          // {title: 'operators plannings', translate: 'SIDENAV.OPERATORS', url: '/operators', exact: true, module: 'SETUP_SELL_POINT'},
          // {title: 'cabin management', translate: 'SIDENAV.CABIN', url: '/cabin', exact: true, module: 'SETUP_SELL_POINT'},
          {title: 'Payment type', translate: 'SIDENAV.PAYMENT_TYPE', url: '/payment-type-conf', exact: true, module: 'SETUP_SELL_POINT'},
          {title: 'Basic Configuration', translate: 'SIDENAV.SMS_BASIC_CONFIG', url: '/sms-config', exact: true, module: 'SETUP_SELL_POINT'},
          {title: 'Basic Whatsapp Configuration', translate: 'SIDENAV.WHATS_APP_BASIC_CONFIG', url: '/whatsapp-config', exact: true, module: 'WHATS_APP'},
          {title: 'Mobile app config', translate: 'SIDENAV.MOBILE_APP_CONFIG', url: '/mobile-app-config', exact: true, module: 'SETUP_SELL_POINT'},
        ]
      },
      {
        title: 'Setup clients', translate: 'SIDENAV.SETUP_CLIENTS', module: 'SETUP_CLIENTS',
        children: [
          {title: 'Contatto preferito', translate: 'SIDENAV.PREF_CONTACT', url: '/pref-contact-conf', exact: true},
          {title: 'Profession', translate: 'SIDENAV.PROFESSION', url: '/profession-conf', exact: true},
          {title: 'How did you meet us', translate: 'SIDENAV.HOW_DID_YOU_MEET_US', url: '/how-did-you-meet-us', exact: true},
        ]
      },
      {
        title: 'Product and services',
        icon: 'ft-package',
        url: '',
        translate: 'SIDENAV.PROD_SERVICE',
        module: 'PRODUCTS_AND_SERVICES',
        children: [
          {title: 'category-product', translate: 'SIDENAV.CATEGORY_PRODUCT', url: '/category-product-conf', exact: true,  module: 'PRODUCTS_AND_SERVICES'},
          {title: 'category-treatment', translate: 'SIDENAV.CATEGORY_TREATMENT', url: '/category-treatment-conf', exact: true,  module: 'PRODUCTS_AND_SERVICES'},
          {title: 'brand', translate: 'SIDENAV.BRAND', url: '/brand-conf', exact: true,  module: 'PRODUCTS_AND_SERVICES'},
          {title: 'linea', translate: 'SIDENAV.LINEA', url: '/linea-conf', exact: true,  module: 'PRODUCTS_AND_SERVICES'},
        ]
      },
      // {
      //   title: 'printers',
      //   icon: 'ft-printer',
      //   url: '',
      //   translate: 'SIDENAV.PRINTERS',
      //   module: 'PRINTERS',
      //   children: [
      //     {title: 'RT Config', translate: 'SIDENAV.RT', url: '/rt-conf', exact: true, module: 'PRINTERS'},
      //     {title: 'Courtesy', translate: 'Di cortesia', url: '/courtesy', exact: true, module: 'PRINTERS'},
      //   ]
      // },
      // {
      //   title: 'Satispay config',
      //   translate: 'Satispay',
      //   url: '/satispay-conf',
      //   icon: 'ft-printer',
      //   module: 'SATISPAY',
      //   exact: true
      // },
    ]
  }
];

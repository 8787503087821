import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {Operator} from '../../../../../../shared/models/operator';
import {TintService} from '../../../../../../shared/services/tint.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {   MatSnackBar } from '@angular/material/snack-bar';
import {SweetAlertService} from '../../../../../../shared/services/sweet-alert.service';
import {TranslateService} from '@ngx-translate/core';
import {OperatorMgmService} from '../../../../../../shared/services/operator-mgm.service';
import {Tint} from '../../../../../../shared/models/Tint';

@Component({
  selector: 'app-add-tint',
  templateUrl: './add-tint.component.html',
  styleUrls: ['./add-tint.component.scss']
})
export class AddTintComponent implements OnInit {
  @Input() clientId: number;
  @Input() editMode = false;
  @Input() tint: Tint;

  searchFormControl = new UntypedFormControl();
  private formBuilder = new UntypedFormBuilder();
  totalRecords: number;
  pageSize = 10;
  page = 1;
  unsubscribe = new Subject();
  loading = true;
  firstCall = true;
  tintForm: UntypedFormGroup;
  submitted = false;
  operators: Operator[] = [];

  constructor(private tintService: TintService,
              public matDialog: MatDialog,
              private snackBar: MatSnackBar,
              private sweetAlertService: SweetAlertService,
              private translate: TranslateService,
              private operatorService: OperatorMgmService) {
  }

  ngOnInit() {
    this.initForm();
    this.loadOperators();
    if (this.editMode) {
      this.tintForm.patchValue(this.tint);
      const date = new Date(this.tint.createdAt);
      this.tintForm.get('creationDate').setValue(date.toLocaleDateString() + ' ' + date.toLocaleTimeString());
    }
  }

  loadOperators() {
    this.operatorService.getActiveAcounts().subscribe(d => {
      this.operators = d;
    });
  }

  initForm() {
    const date = new Date();
    this.tintForm = this.formBuilder.group({
      id: new UntypedFormControl(),
      color: new UntypedFormControl(null, Validators.required),
      creationDate: new UntypedFormControl(date.toLocaleDateString() + ' ' + date.toLocaleTimeString()),
      note: new UntypedFormControl(null),
      operatorId: new UntypedFormControl(null, Validators.required),
      clientId: new UntypedFormControl(this.clientId)
    });
  }

  save() {
    if (!this.editMode) {
      this.tintService.create(this.tintForm.getRawValue()).subscribe(r => {
        this.matDialog.closeAll();
        this.snackBar.open(this.translate.instant('DIALOG.ADD_SUCCESS'), 'Ok', {duration: 5000, panelClass: 'white-snackbar'});
        this.matDialog.closeAll();
      }, error => this.sweetAlertService.danger(this.translate.instant('DIALOG.CANNOT_UPDATE')));
      return;
    }
    this.tintService.update(this.tintForm.getRawValue()).subscribe(r => {
      this.matDialog.closeAll();
      this.snackBar.open(this.translate.instant('DIALOG.UPDATE_SUCCESS'), 'Ok', {duration: 5000, panelClass: 'white-snackbar'});
      this.matDialog.closeAll();
    }, error => this.sweetAlertService.danger(this.translate.instant('DIALOG.CANNOT_UPDATE')));
  }
}

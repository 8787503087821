import {Component, HostListener, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {   MatPaginator } from '@angular/material/paginator';
import {   MatSnackBar } from '@angular/material/snack-bar';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { HttpClient } from '@angular/common/http';
import { ProviderFormService } from './services/provider-form.service';
import { CustomSnackBarComponent } from '../../../../shared/custom-snack-bar/custom-snack-bar.component';
import { ProviderMgmService } from '../../../../shared/services/provider-mgm.service';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Provider } from '../../../../shared/models/provider';
import { SweetAlertService } from '../../../../shared/services/sweet-alert.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-provider-management',
  templateUrl: './provider-management.component.html',
  styleUrls: ['./provider-management.component.scss']
})
export class ProviderManagementComponent implements OnInit {
  searchFormControl = new UntypedFormControl(null);
  rows: Provider[] = [];
  columns = [
    'DATA_TABLE.ID',
    'DATA_TABLE.BUSINESS_NAME',
    'DATA_TABLE.VAT_NUMBER',
    'DATA_TABLE.MOBILE',
    'DATA_TABLE.EMAIL'
  ];
  loading = true;
  firstCall = true;
  orderType = 'DESC'
  public page = 1;
  public totalRecords: number;
  public pageSize = 10;
  textSearch = null;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('providerFormContainer', { read: ViewContainerRef ,static: false }) providerFormContainer: ViewContainerRef;
  appRef;
  dialogComponentRef;
  constructor(
    public snackBar: MatSnackBar,
    private http: HttpClient,
    private breadcrumbService: BreadcrumbService,
    private providerFormService: ProviderFormService,
    public dialog: MatDialog,
    private providerService: ProviderMgmService,
    private sweetAlertService: SweetAlertService,
    private translate: TranslateService) {
  }


  ngOnInit() {
    this.sendBreadCrumb();
    this.getLazyProvider({ page: this.page, pageSize: this.pageSize });
    this.searchFormControl.valueChanges.pipe(debounceTime(500)).subscribe(s => {
      this.textSearch = s;
      this.page = 1;
      this.getLazyProvider({ page: 1, pageSize: this.pageSize, textSearch: s });
    });

  }

  showSnackBar(data: any) {
    this.snackBar.openFromComponent(CustomSnackBarComponent, {
      data,
      duration: 5000,
      panelClass: 'white-snackbar'
    });
  }


  enableDisable($event, el: any, i): void {
    $event.cancelBubble = true;
    $event.stopPropagation();
    let msg = '';
    if (el.active) {
      msg = this.translate.instant('DIALOG.YOU_WANT_TO_HIDE');
    } else {
      msg = this.translate.instant('DIALOG.YOU_WANT_TO_SHOW');
    }
    this.sweetAlertService.warning(msg + el.businessName)
      .then(res => {
        if (res.value) {
          this.providerService.changeProviderVisibleState(el.id).subscribe(d => {
            if (d.status === 200) {
              this.getLazyProvider({ page: this.page, pageSize: this.pageSize, textSearch: this.textSearch }, {
                text: `${el.businessName}`,
                actionIcon: 'check',
                actionMsg: this.translate.instant('DIALOG.UPDATE_SUCCESS')
              });
            }
          });
        }
      }, err => {

      });
  }

  deleteProvider($event, el: any, i): void {
    $event.cancelBubble = true;
    $event.stopPropagation();
    this.sweetAlertService.warning(this.translate.instant('DIALOG.YOU_WANT_TO_DELETE') + ' ' + el.businessName)
      .then(res => {
        if (res.value) {
          this.providerService.deleteProvider(el.id).subscribe(d => {
            if (d.status === 200) {
              this.getLazyProvider({ page: this.page, pageSize: this.pageSize, textSearch: this.textSearch }, {
                text: `${el.businessName}`,
                actionIcon: 'check',
                actionMsg: this.translate.instant('DIALOG.UPDATE_SUCCESS')
              });
            }
          }, err => {
            if (err.status === 500) {
              this.showSnackBar({
                text: `${el.firstName} ${el.lastName}`,
                actionIcon: 'failed',
                actionMsg: this.translate.instant('DIALOG.CANNOT_DELETE')
              });
            }
          });
        }
      }, err => {

      });
  }


  sendBreadCrumb(): void {
    this.breadcrumbService.sendBreadcrumb(['CONTACT', 'PROVIDER']);
  }

  editProvider(data, i) {
    const formRef = this.providerFormService.openForm(this.providerFormContainer,
      {
        provider: data,
        editMode: true,
      });

    formRef.instance.provider = data;
    formRef.instance.editMode = true;
    formRef.instance.onClose.subscribe((d: any) => {
      if (d) {
        this.getLazyProvider({ page: this.page, pageSize: this.pageSize, textSearch: this.textSearch }, {
          text: `${d.businessName} `,
          actionIcon: 'save',
          actionMsg: this.translate.instant('DIALOG.UPDATE_SUCCESS'),

        });

      }
    });
  }


  addNewProvider() {
    const formRef = this.providerFormService.openForm(this.providerFormContainer);
    formRef.instance.editMode = false;
    formRef.instance.onClose.subscribe((d: any) => {
      if (d) {
        this.getLazyProvider({ page: this.page, pageSize: this.pageSize, textSearch: this.textSearch }, {
          text: `${d.businessName} `,
          actionIcon: 'save',
          actionMsg: this.translate.instant('DIALOG.ADD_SUCCESS')
        });
      }
    });
  }


  getLazyProvider(request, snackBarConf?) {
    this.loading = true;
    return this.providerService.getLazyProviderList(request).subscribe(data => {
      this.rows = data.data;
      this.loading = false;
      this.firstCall = false;
      this.totalRecords = data.totalRecords;
      if (snackBarConf) {
        this.showSnackBar(snackBarConf);
      }
    });
  }

  pageChange(page: number) {
    this.page = page;
    this.getLazyProvider({ page, pageSize: this.pageSize, textSearch: this.textSearch });
  }


  changeProviderState($event: MouseEvent, el: any, i: any) {
    $event.cancelBubble = true;
    $event.stopPropagation();
    let msg = '';
    if (el.visible) {
      msg = this.translate.instant('DIALOG.YOU_WANT_TO_HIDE');
    } else {
      msg = this.translate.instant('DIALOG.YOU_WANT_TO_SHOW');
    }
    this.sweetAlertService.warning(msg + el.businessName)
      .then(res => {
        if (res.value) {
          this.providerService.changeProviderVisibleState(el.id).subscribe(d => {
            if (d.status === 200) {
              this.getLazyProvider({ page: this.page, pageSize: this.pageSize, textSearch: this.textSearch }, {
                text: `${el.businessName} `,
                actionIcon: 'save',
                actionMsg: this.translate.instant('DIALOG.UPDATE_SUCCESS')
              });
            }
          });
        }
      });
  }


  sortRows(c: string) {
    const sort = c.substr(11);
    if (this.orderType === 'ASC') {
      this.orderType = 'DESC';
    } else {
      this.orderType = 'ASC';
    }
    switch (sort) {
      case 'ID':
        this.getLazyProvider({
          page: this.page, pageSize: this.pageSize, textSearch: this.textSearch, sort: {
            attribute: 'id',
            direction: this.orderType
          }
        });
        break;
      case 'BUSINESS_NAME':
        this.getLazyProvider({
          page: this.page, pageSize: this.pageSize, textSearch: this.textSearch, sort: {
            attribute: 'businessName',
            direction: this.orderType
          }
        });
        break;
      case 'VAT_NUMBER':
        this.getLazyProvider({
          page: this.page, pageSize: this.pageSize, textSearch: this.textSearch, sort: {
            attribute: 'vatNumber',
            direction: this.orderType
          }
        });
        break;
      case 'MOBILE':
        this.getLazyProvider({
          page: this.page, pageSize: this.pageSize, textSearch: this.textSearch, sort: {
            attribute: 'mobile',
            direction: this.orderType
          }
        });
        break;
      case 'EMAIL':
        this.getLazyProvider({
          page: this.page, pageSize: this.pageSize, textSearch: this.textSearch, sort: {
            attribute: 'email',
            direction: this.orderType
          }
        });
        break;
    }
  }


}




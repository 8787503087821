import {ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector} from '@angular/core';
import {PromotionManagementFormComponent} from '../promotion-management-form/promotion-management-form.component';
import {SweetAlertService} from '../../../../shared/services/sweet-alert.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class PromotionFormService {
  dialogComponentRef: ComponentRef<PromotionManagementFormComponent>;

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private appRef: ApplicationRef,
              private injector: Injector,
              private sweetAlertService: SweetAlertService,
              private translate: TranslateService) {
  }

  openForm(container, data?: any) {
    // close form if opened
    if (this.dialogComponentRef) {
      // tslint:disable-next-line:max-line-length
       if (this.dialogComponentRef.instance.promoForm.touched || this.dialogComponentRef.instance.smsFormControl.touched || this.dialogComponentRef.instance.mailFormControl.touched) {
         this.sweetAlertService.warning(
          this.translate.instant('PLANNING.CONFIRM_CANCELLATION')).then(e => {
          if (e.value) {
            this.appRef.detachView(this.dialogComponentRef.hostView);
            this.dialogComponentRef.destroy();
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(PromotionManagementFormComponent);
            componentFactory.create(this.injector);
            const componentRef = container.createComponent(componentFactory);
            componentRef.instance.promo = data?.promo;
            componentRef.instance.editMode = data?.editMode;
            componentRef.instance.appRef = this.appRef;
            componentRef.instance.dialogComponentRef = componentRef;
            this.dialogComponentRef = componentRef;
          }
        });
      } else {
        this.appRef.detachView(this.dialogComponentRef.hostView);
        this.dialogComponentRef.destroy();
        this.dialogComponentRef = this.createComponent(container, data);
      }
    } else {
      // open form component and pass data to it
      this.dialogComponentRef = this.createComponent(container, data);
    }
    // return a ref of the opened form component
    return this.dialogComponentRef;
  }

  createComponent(container, data?: any) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(PromotionManagementFormComponent);
    componentFactory.create(this.injector);
    const componentRef = container.createComponent(componentFactory);
    componentRef.instance.promo = data?.promo;
    componentRef.instance.editMode = data?.editMode;
    componentRef.instance.appRef = this.appRef;
    componentRef.instance.dialogComponentRef = componentRef;
    return componentRef;
  }
}

import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Tint} from '../models/Tint';

@Injectable()
export class TintService {
  TINT_API = environment.api + '/tints';

  constructor(private http: HttpClient) {
  }


  create(tint) {
    return this.http.post<Tint>(`${this.TINT_API}`, tint);
  }

  update(tint) {
    return this.http.put<Tint>(`${this.TINT_API}`, tint);
  }

  delete(id) {
    return this.http.delete(`${this.TINT_API}/${id}`, {observe: 'response'});
  }

  findByClient(clientId) {
    return this.http.get<Tint[]>(`${this.TINT_API}/get-tint-by-client/${clientId}`);
  }
}

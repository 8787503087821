import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CourtesyRtComponent} from './courtesy-rt.component';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {MatLegacyButtonModule} from '@angular/material/legacy-button';
import {SharedModule} from '../../../../shared/shared.module';
import { CurrencyMaskModule } from "ng2-currency-mask";



import { MatDatepickerModule } from '@angular/material/datepicker';
import {  MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';

import {  MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {NgbPopoverModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {  MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import { HeaderFooterCourtesyRtConfigComponent } from './header-footer-courtesy-rt-config/header-footer-courtesy-rt-config.component';
import {  MatCardModule} from "@angular/material/card";

const routes: Routes = [
  {path: '', component: CourtesyRtComponent}
];


@NgModule({
    declarations: [CourtesyRtComponent, HeaderFooterCourtesyRtConfigComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FormsModule,
        TranslateModule,
        MatLegacyButtonModule,
        ReactiveFormsModule,
        SharedModule.forChild(),
        CurrencyMaskModule,
        MatCardModule,
        MatProgressSpinnerModule,
        NgbPopoverModule,
        NgbTooltipModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        MatTabsModule
    ]
})
export class CourtesyRtModule { }

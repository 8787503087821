import {Component, OnInit, ɵConsole} from '@angular/core';
import {BreadcrumbService} from 'src/app/core/services/breadcrumb.service';
import {SmsService} from 'src/app/shared/services/sms-service.service';
import {SmsCompaign} from 'src/app/shared/models/sms-compaign';
import {UntypedFormControl} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {SmsHistory} from 'src/app/shared/models/sms-history';
import {ReminderType} from 'src/app/shared/enum/reminder-type';
import {  MatDialogRef,   MatDialog} from '@angular/material/dialog';
import {SmsCampaignClient} from 'src/app/shared/models/sms-campaign-client';
import {TranslateService} from '@ngx-translate/core';
import {DatePipe} from '@angular/common';
import {SweetAlertService} from 'src/app/shared/services/sweet-alert.service';
import {UserResponse} from '../../../../shared/models/user-response';
import {WhatsappServiceService} from "../../../../shared/services/whatsapp-service.service";

@Component({
  selector: 'app-sms-history',
  templateUrl: './sms-history.component.html',
  styleUrls: ['./sms-history.component.scss']
})
export class SmsHistoryComponent implements OnInit {

  public campaignPage = 1;
  public appointmentPage = 1;
  public birthdayPage = 1;
  public totalAppointmentRecords: number;
  public totalBirthdayRecords: number;
  public totalCampaignRecords: number;
  public pageSize = 10;
  loadCampaigns = true;
  loadBirthdays = true;
  loadAppointment = true;
  compaigns: SmsCompaign[];
  appointmentSmss: SmsHistory[];
  birthdaySmss: SmsHistory[];
  searchFormControl = new UntypedFormControl(null);
  dialogSearchFormControl = new UntypedFormControl(null);
  dialogRef: MatDialogRef<any, any>;
  receivers: SmsCampaignClient[] = [];
  receivs: SmsCampaignClient[] = [];
  orderAppointmentType = 'DESC';
  orderBirthdayType = 'DESC';
  orderCampaignType = 'DESC';
  searchValue = null;
  credits: UserResponse;
  columns = [
    'SMS.TEMPLATE',
    'SMS.TEMPLATE_NAME',
    'SMS.NOTIFICATION_TYPE',
    'SMS.RECEIVER_ID',
    'SMS.RECEIVER',
    'SMS.PHONE',
    'SMS.SENT_AT',
    'SMS.STATUS',

  ];

  dialog_columns = [
    'SMS.RECEIVER',
    'SMS.PHONE',
    'SMS.NOTIFICATION_TYPE',
    'SMS.SENT_AT',
    'SMS.STATUS'
  ];

  columns1 = [
    'SMS.SMS_TEMPLATE',
  'SMS.WhatsApp_TEMPLATE',
    'SMS.TEMPLATE_NAME',
  ];
  columns2 = [
    'SMS.SENT_AT',
    'SMS.RECEIVER'];



  constructor(private dialog: MatDialog,
              private breadcrumbService: BreadcrumbService,
              private smsService: SmsService,
              private whatsappService: WhatsappServiceService,
              private translate: TranslateService,
              public datepipe: DatePipe,
              private sweetAlertService: SweetAlertService) {
  }

  ngOnInit() {
    this.smsService.getCredits().subscribe(r => {
      this.credits = r;
    }, err => {
      this.credits = null;
    });
    this.sendBreadCrumb();
    this.getCompaignsHistory({page: 1, pageSize: this.pageSize});
    this.getBirthdayHistory({page: 1, pageSize: this.pageSize, reminderType: ReminderType.BIRTHDAY});
    this.getAppointmentHistory({page: 1, pageSize: this.pageSize, reminderType: ReminderType.APPOINTMENT});
    this.searchFormControl.valueChanges.pipe(debounceTime(500)).subscribe(s => {
      this.searchValue = s;
      this.campaignPage = 1;
      this.appointmentPage = 1;
      this.birthdayPage = 1;
      this.getCompaignsHistory({page: 1, pageSize: this.pageSize, textSearch: s});
      this.getBirthdayHistory({page: 1, pageSize: this.pageSize, reminderType: ReminderType.BIRTHDAY, textSearch: s});
      this.getAppointmentHistory({page: 1, pageSize: this.pageSize, reminderType: ReminderType.APPOINTMENT, textSearch: s});
    });

    this.dialogSearchFormControl.valueChanges.pipe(debounceTime(500)).subscribe(s => {
      this.receivs = this.receivers;
      this.receivs = this.receivs.filter(x => ((x.client.firstName.toLowerCase() + ' ' +
        x.client.lastName.toLowerCase()).includes(s.toLowerCase()))
        || (x.client.mobilePrefix + x.client.mobile).includes(s)
        || (this.translate.instant('SMS.' + (this.receivs[0].smsStatus))).toLowerCase().includes(s.toLowerCase())
        ||(this.translate.instant('SMS.' + (this.receivs[0].whatsAppStatus))).toLowerCase().includes(s.toLowerCase())
        || (x.sendAt && (this.datepipe.transform(x.sendAt, 'dd/MM/yyyy HH:mm')).includes(s))
      );
    });
  }

  sendBreadCrumb(): void {
    this.breadcrumbService.sendBreadcrumb(['SMS_WhatsApp', 'HISTORY']);
  }

  getCompaignsHistory(request) {
    this.loadCampaigns = true;
    return this.smsService.getCompaigns(request).subscribe(data => {
      this.compaigns = data.data;
      console.log(this.compaigns);
      this.loadCampaigns = false;
      this.totalCampaignRecords = data.totalRecords;
    });
  }

  getBirthdayHistory(request) {
    this.loadBirthdays = true;
    return this.smsService.getHistory(request).subscribe(data => {
      console.log(data.data);
      this.birthdaySmss = data.data;
      this.loadBirthdays = false;
      this.totalBirthdayRecords = data.totalRecords;
    });
  }

  getAppointmentHistory(request) {
    this.loadAppointment = true;
    return this.smsService.getHistory(request).subscribe(data => {
      this.loadAppointment = false;
      this.appointmentSmss = data.data;
      this.totalAppointmentRecords = data.totalRecords;
    });
  }

  campaignPageChange(page: number) {
    this.campaignPage = page;
    this.getCompaignsHistory({page, pageSize: this.pageSize, textSearch: this.searchValue});
  }

  appointmentPageChange(page: number) {
    this.appointmentPage = page;
    this.getAppointmentHistory({page, pageSize: this.pageSize, textSearch: this.searchValue, reminderType: ReminderType.APPOINTMENT});
  }

  birthdayPageChange(page: number) {
    this.birthdayPage = page;
    this.getBirthdayHistory({page, pageSize: this.pageSize, textSearch: this.searchValue, reminderType: ReminderType.BIRTHDAY});
  }


  sortAppointmentRows(c: string) {
    const sort = c.substr(4);
    console.log(sort);
    if (this.orderAppointmentType === 'ASC') {
      this.orderAppointmentType = 'DESC';
    } else {
      this.orderAppointmentType = 'ASC';
    }

    switch (sort) {
      case 'ID':
        this.getAppointmentHistory({
          page: 1, pageSize: this.pageSize, textSearch: this.searchValue, reminderType: ReminderType.APPOINTMENT, sort: {
            attribute: 'id',
            direction: this.orderAppointmentType
          }
        });
        break;
      case 'TEMPLATE':
        this.getAppointmentHistory({
          page: 1, pageSize: this.pageSize, textSearch: this.searchValue, reminderType: ReminderType.APPOINTMENT, sort: {
            attribute: 'template',
            direction: this.orderAppointmentType
          }
        });
        break;
      case 'RECEIVER_ID':
        this.getAppointmentHistory({
          page: 1, pageSize: this.pageSize, textSearch: this.searchValue, reminderType: ReminderType.APPOINTMENT, sort: {
            attribute: 'client.id',
            direction: this.orderAppointmentType
          }
        });
        break;
      case 'RECEIVER':
        this.getAppointmentHistory({
          page: 1, pageSize: this.pageSize, textSearch: this.searchValue, reminderType: ReminderType.APPOINTMENT, sort: {
            attribute: 'client.firstName',
            direction: this.orderAppointmentType
          }
        });
        break;
      case 'SENT_AT':
        this.getAppointmentHistory({
          page: 1, pageSize: this.pageSize, textSearch: this.searchValue, reminderType: ReminderType.APPOINTMENT, sort: {
            attribute: 'messageSendAt',
            direction: this.orderAppointmentType
          }
        });

        break;
      case 'STATUS':
        this.getAppointmentHistory({
          page: 1, pageSize: this.pageSize, textSearch: this.searchValue, reminderType: ReminderType.APPOINTMENT, sort: {
            attribute: 'smsStatus',
            direction: this.orderAppointmentType
          }
        });
        break;
    }
  }


  sortBirthdayRows(c: string) {
    const sort = c.substr(4);
    console.log(sort);
    if (this.orderBirthdayType === 'ASC') {
      this.orderBirthdayType = 'DESC';
    } else {
      this.orderBirthdayType = 'ASC';
    }

    switch (sort) {
      case 'ID':
        this.getBirthdayHistory({
          page: 1, pageSize: this.pageSize, textSearch: this.searchValue, reminderType: ReminderType.BIRTHDAY, sort: {
            attribute: 'id',
            direction: this.orderBirthdayType
          }
        });
        break;
      case 'TEMPLATE':
        this.getBirthdayHistory({
          page: 1, pageSize: this.pageSize, textSearch: this.searchValue, reminderType: ReminderType.BIRTHDAY, sort: {
            attribute: 'template',
            direction: this.orderBirthdayType
          }
        });
        break;

      case 'RECEIVER_ID':
        this.getBirthdayHistory({
          page: 1, pageSize: this.pageSize, textSearch: this.searchValue, reminderType: ReminderType.BIRTHDAY, sort: {
            attribute: 'client.id',
            direction: this.orderBirthdayType
          }
        });
        break;
      case 'RECEIVER':
        this.getBirthdayHistory({
          page: 1, pageSize: this.pageSize, textSearch: this.searchValue, reminderType: ReminderType.BIRTHDAY, sort: {
            attribute: 'client.firstName',
            direction: this.orderBirthdayType
          }
        });
        break;
      case 'SENT_AT':
        this.getBirthdayHistory({
          page: 1, pageSize: this.pageSize, textSearch: this.searchValue, reminderType: ReminderType.BIRTHDAY, sort: {
            attribute: 'messageSendAt',
            direction: this.orderBirthdayType
          }
        });
        break;
      case 'STATUS':
        this.getBirthdayHistory({
          page: 1, pageSize: this.pageSize, textSearch: this.searchValue, reminderType: ReminderType.BIRTHDAY, sort: {
            attribute: 'smsStatus',
            direction: this.orderBirthdayType
          }
        });
        break;
    }
  }


  sortCampaignsRows(c: string) {
    const sort = c.substr(4);
    console.log(sort);
    if (this.orderCampaignType === 'ASC') {
      this.orderCampaignType = 'DESC';
    } else {
      this.orderCampaignType = 'ASC';
    }

    switch (sort) {
      case 'ID':
        this.getCompaignsHistory({
          page: 1, pageSize: this.pageSize, textSearch: this.searchValue, sort: {
            attribute: 'id',
            direction: this.orderCampaignType
          }
        });
        break;
      case 'SMS_TEMPLATE':
        this.getCompaignsHistory({
          page: 1, pageSize: this.pageSize, textSearch: this.searchValue, sort: {
            attribute: 'templateCampaign',
            direction: this.orderCampaignType
          }
        });
        break;
      case 'WhatsApp_TEMPLATE':
        this.getCompaignsHistory({
          page: 1, pageSize: this.pageSize, textSearch: this.searchValue, sort: {
            attribute: 'templateCampaign',
            direction: this.orderCampaignType
          }
        });
        break;
      case 'SENT_AT':
        this.getCompaignsHistory({
          page: 1, pageSize: this.pageSize, textSearch: this.searchValue, sort: {
            attribute: 'sendCampaignAt',
            direction: this.orderCampaignType
          }
        });
        break;
      case 'STATUS':
        this.getCompaignsHistory({
          page: 1, pageSize: this.pageSize, textSearch: this.searchValue, sort: {
            attribute: 'status',
            direction: this.orderCampaignType
          }
        });
        break;
    }
  }


  view(content, comp) {
    this.receivers = comp;
    this.receivs = comp;
    this.dialogRef = this.dialog.open(content, {
      width: '1200px',
      maxHeight: '90vh'
    });
    this.dialogRef.afterClosed().subscribe(d => {
      this.receivers = null;
    });
  }

  getReceiversNumber(comp: SmsCompaign) {
    return comp.smsCampaignClients.filter(e => e.resent === false).length;
  }


  reSendBirthdaySMS(sms: SmsHistory) {
    // tslint:disable-next-line: max-line-length
    this.sweetAlertService.warning(this.translate.instant('SMS.RESEND', {
      nome: sms.client.firstName,
      cognome: sms.client.lastName,
      number: sms.client.mobilePrefix + sms.client.mobile
    })).then(e => {
      if (e.value) {
        this.smsService.reSendBirthdaySMS(sms.id).subscribe(r => {
          this.birthdayPage = 1;
          this.getBirthdayHistory({page: 1, pageSize: this.pageSize, textSearch: this.searchValue, reminderType: ReminderType.BIRTHDAY});
        });
      }
    });
  }

  reSendWhatsAppBirthday(sms: SmsHistory) {
    this.sweetAlertService.warning(this.translate.instant('SMS.RESEND', {
      nome: sms.client.firstName,
      cognome: sms.client.lastName,
      number: sms.client.mobilePrefix + sms.client.mobile
    })).then(e => {
      if (e.value) {
        this.whatsappService.reSendWhatsAppBirthday(sms.id).subscribe(r => {
          this.birthdayPage = 1;
          this.getBirthdayHistory({page: 1, pageSize: this.pageSize, textSearch: this.searchValue, reminderType: ReminderType.BIRTHDAY});
        });
      }
    });
  }

  reSendAppointmentSMS(sms: SmsHistory) {
    // tslint:disable-next-line: max-line-length
    this.sweetAlertService.warning(this.translate.instant('SMS.RESEND', {
      nome: sms.client.firstName,
      cognome: sms.client.lastName,
      number: sms.client.mobilePrefix + sms.client.mobile
    })).then(e => {
      if (e.value) {
        this.appointmentPage = 1;
        this.smsService.reSendAppointmentSMS(sms.id).subscribe(r => {
          this.getAppointmentHistory({
            page: 1,
            pageSize: this.pageSize,
            textSearch: this.searchValue,
            reminderType: ReminderType.APPOINTMENT
          });
        });
      }
    });
  }


  reSendAppointmentWhatsapp(sms: SmsHistory) {
    this.sweetAlertService.warning(this.translate.instant('SMS.RESEND', {
      nome: sms.client.firstName,
      cognome: sms.client.lastName,
      number: sms.client.mobilePrefix + sms.client.mobile
    })).then(e => {
      if (e.value) {
        this.appointmentPage = 1;
        this.whatsappService.reSendAppointmentWhatsapp(sms.id).subscribe(r => {
          this.getAppointmentHistory({
            page: 1,
            pageSize: this.pageSize,
            textSearch: this.searchValue,
            reminderType: ReminderType.APPOINTMENT
          });
        });
      }
    });
  }

  reSendCampaignSMS(sms: SmsCampaignClient) {
    // tslint:disable-next-line: max-line-length
    this.sweetAlertService.warning(this.translate.instant('SMS.RESEND', {
      nome: sms.client.firstName,
      cognome: sms.client.lastName,
      number: sms.client.mobilePrefix + sms.client.mobile
    })).then(e => {
      if (e.value) {
        this.campaignPage = 1;
        this.smsService.reSendCampaignSMS(sms.id).subscribe(r => {
          this.getCompaignsHistory({page: 1, pageSize: this.pageSize, textSearch: this.searchValue});
        });
      }
    });
  }

  reSendCampaignWhatsapp(sms: SmsCampaignClient) {
    this.sweetAlertService.warning(this.translate.instant('SMS.RESEND', {
      nome: sms.client.firstName,
      cognome: sms.client.lastName,
      number: sms.client.mobilePrefix + sms.client.mobile
    })).then(e => {
      if (e.value) {
        this.campaignPage = 1;
        this.whatsappService.reSendCampaignWhatsapp(sms.id).subscribe(r => {
          this.getCompaignsHistory({page: 1, pageSize: this.pageSize, textSearch: this.searchValue});
        });
      }
    });
  }

}

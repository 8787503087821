import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {LicenseConfigurationService} from '../../../../../../shared/services/license-configuration.service';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {HttpErrorResponse} from '@angular/common/http';
import {  MatSnackBar} from '@angular/material/snack-bar';
import {Module, MODULES} from './modules';
import _ from 'lodash';
import {SweetAlertService} from "../../../../../../shared/services/sweet-alert.service";
@Component({
  selector: 'app-license-config-form',
  templateUrl: './license-config-form.component.html',
  styleUrls: ['./license-config-form.component.scss']
})
export class LicenseConfigFormComponent implements OnInit {
  /*modules = [
    {label: 'Dashboard', value: 'DASHBOARD'},
    {label: 'Planning', value: 'PLANNING'},
    {label: 'SIDENAV.CONTACTS', value: 'CONTACTS'},
    {label: 'SIDENAV.PROD_SERVICE', value: 'PRODUCT_AND_SERVICES'},
    {label: 'SIDENAV.MOVEMENT_AND_STOCK', value: 'MOVEMENT_AND_STOCK'},
    {label: 'Sms', value: 'SMS'},
    {label: 'Promo', value: 'PROMO'},
    {label: 'Report', value: 'REPORT'},
    {label: 'SIDENAV.CONFIGURATIONS', value: 'CONFIGURATION_TABLE'},
    {label: 'SIDENAV.PAYMENT', value: 'PAYMENT'},
    {label: 'SIDENAV.SALES', value: 'SALES'},
    {label: 'SIDENAV.ADMINISTRATION', value: 'ADMINISTRATION'},
    {label: 'SIDENAV.FIRST_NOTE', value: 'FIRST_NOTE'},
  ];*/
  modules: Module[] = _.cloneDeep(MODULES);
  licenseForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private licenseConfigService: LicenseConfigurationService,
    private matDialogRef: MatDialogRef<LicenseConfigFormComponent>,
    private sweetAlertService: SweetAlertService,
    @Inject(MAT_DIALOG_DATA) public data,
    private matSnackbar: MatSnackBar
  ) {
  }

  ngOnInit() {
    this.licenseForm = this.fb.group({
      id: [null],
      name: [null, Validators.required],
      description: null,
      maxCabins: [null, Validators.compose([Validators.required, Validators.pattern(/^[0-9]*$/)])],
      maxOperators: [null, Validators.compose([Validators.required, Validators.pattern(/^[0-9]*$/)])],
      /*authorities: this.data.license ?
        this.fb.array(this.modules.map((u) => this.fb.control(this.data.license.authorities.some(v => v === u.value))))
        : this.fb.array(this.modules.map(() => this.fb.control(false)))*/
    });
    if (this.data.editMode) {
      this.licenseForm.patchValue({
        id: this.data.license.id,
        name: this.data.license.name,
        description: this.data.license.description,
        maxCabins: this.data.license.maxCabins,
        maxOperators: this.data.license.maxOperators
      });
      this.modules.forEach(u => {
        if (!u.children) {
          if (this.data.license.authorities.some(v => u.value === v)) {
            u.selected = true;
          }
        } else {
          u.children.forEach(w => {
            if (!w.children) {
              if (this.data.license.authorities.some(x => w.value === x)) {
                w.selected = true;
              } else {
                w.selected = false;
              }
            } else {
              w.children.forEach(z => {
                if (this.data.license.authorities.some(x => z.value === x)) {
                  z.selected = true;
                } else {
                  z.selected = false;
                }
              });
              if (w.children.filter(c => c.selected).length === w.children.length) {
                w.selected = true;
              } else {
                w.selected = false;
              }
            }
          });
          if (u.children.filter(c => c.selected).length === u.children.length) {
            u.selected = true;
          } else {
            u.selected = false;
          }
        }
      });
    }
  }

  save() {
    if (this.licenseForm.invalid) {
      return;
    }
    // const authorities = _.flatten(this.modules.map(u => {
    //   if (!u.children) {
    //     return u;
    //   }
    //   return [...u.children];
    // })).filter(u => u.selected).map(u => u.value);

    let authorities = [];
    _.flatten(this.modules.map(u => {
      if (!u.children) {
        if (u.selected) {
          authorities = [...authorities, u.value];
        }
      } else {
        u.children.forEach(c => {
          if (!c.children) {
            if (c.selected) {
              authorities = [...authorities, c.value];
            }
          } else {
            c.children.forEach(cc => {
              if (cc.selected) {
                authorities = [...authorities, cc.value];
              }
            });
          }
        });
      }
    }));
    const req = {...this.licenseForm.value, authorities};
    /*req.authorities = req.authorities.map((u, i) => {
      if (u) {
        return this.modules[i].value;
      }
    }).filter(u => u !== undefined);*/
    this.licenseConfigService.save(req).subscribe(() => {
      this.matDialogRef.close(true);
    }, (err: HttpErrorResponse) => {
      this.matSnackbar.open('Configuration name already exists', 'Ok', {duration: 3000});
    });
  }

  descendantsAllSelected(m: any) {
    if (!m.children) {
      return false;
    }
    return m.children.filter(u => u.selected).length === m.children.length;
  }

  descendantsPartiallySelected(m: any) {
    if (!m.children) {
      return false;
    }
    return m.children.some(u => u.selected) && !this.descendantsAllSelected(m);
  }

  selectedAllDescendants(m: any) {
    if (!m.children) {
      return;
    }
    if (m.selected) {
      m.children.forEach(u => {
        if (!u.disabled) {
          if (u.children) {
            u.children.forEach(c => {
              c.selected = true;
            });
          }
          u.selected = true;
        }
      });
    } else {
      m.children.forEach(u => {
        if (!u.disabled) {
          if (u.children) {
            u.children.forEach(c => {
              c.selected = false;
            });
          }
          u.selected = false;
        }
      });
    }
  }

  confirmAndCloseDialog(): void {
    if (this.licenseForm.dirty) {
     this.sweetAlertService.showConfirmationAlert()
      .then((confirmation) => {
        if (confirmation) {
          this.matDialogRef.close();

        }
      });}
    else{
      this.matDialogRef.close();
    }
  }
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.licenseForm.dirty) {
      $event.returnValue = true;
    }
  }
}

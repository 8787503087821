import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ForgetPasswordComponent} from './forget-password.component';
import {RouterModule, Routes} from '@angular/router';
import {  MatCardModule} from '@angular/material/card';
import {MatLegacyButtonModule} from '@angular/material/legacy-button';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';

const routes: Routes = [
  {path: '', component: ForgetPasswordComponent}
];

@NgModule({
  declarations: [ForgetPasswordComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatCardModule,
    MatLegacyButtonModule,
    ReactiveFormsModule,
    TranslateModule
  ]
})
export class ForgetPasswordModule {
}

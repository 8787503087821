import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {LazyRequest} from '../dto/lazy-request';
import {SmsCompaign} from '../models/sms-compaign';
import {SearchResponse} from '../dto/search-response';
import {SmsConfiguration} from '../models/sms-configuration';
import {CampaignStatusDto} from '../dto/campaign-status-dto';
import {SmsHistory} from '../models/sms-history';
import {ReminderType} from '../enum/reminder-type';
import {UserResponse} from '../models/user-response';

@Injectable({
  providedIn: 'root'
})
export class SmsService {

  SMS_COMPAIGN_API = environment.api + '/sms-compaign';
  SMS_CONFIGURATION_API = environment.api + '/sms-configuration';
  SMS_HISTORY_API = environment.api + '/sms-history';


  constructor(private http: HttpClient) {
  }

  addNewCompaign(request) {
    return this.http.post<SmsCompaign>(`${this.SMS_COMPAIGN_API}`, request);
  }

  updateCompaign(request) {
    return this.http.put<SmsCompaign>(`${this.SMS_COMPAIGN_API}`, request);
  }

  updateCampaignStatus(request: CampaignStatusDto) {
    console.log(request);
    return this.http.put<void>(`${this.SMS_COMPAIGN_API}/status`, request);
  }

  deleteCompaign(id: number) {
    return this.http.delete(`${this.SMS_COMPAIGN_API}/${id}`, { observe: 'response' });
  }

  getCompaigns(request: LazyRequest) {
    return this.http.post<SearchResponse<SmsCompaign>>(`${this.SMS_COMPAIGN_API}/filter`, request);
  }

  addNewConfiguration(request) {
    console.log(request)
    return this.http.post<SmsCompaign>(`${this.SMS_CONFIGURATION_API}`, request);
  }

  getConfiguration() {
    return this.http.get<SmsConfiguration>(`${this.SMS_CONFIGURATION_API}`);
  }

  testAuth(request) {
    return this.http.post<boolean>(`${this.SMS_CONFIGURATION_API}/auth`, request);
  }

  getCredits() {
    return this.http.get<UserResponse>(`${this.SMS_COMPAIGN_API}/user`);
  }



  getHistory(request: SmsConfigurationLazyRequest) {
    return this.http.post<SearchResponse<SmsHistory>>(`${this.SMS_HISTORY_API}/filter`, request);
  }

  sendImmediateCampaignSMS(request) {
    console.log(request)
    return this.http.post<any>(`${this.SMS_COMPAIGN_API}/immediate`, request);
  }
  sendGenericCampaignSMS(request) {
    console.log(request)
    return this.http.post<any>(`${this.SMS_COMPAIGN_API}/generic`, request);
  }




  reSendBirthdaySMS(request) {
    return this.http.post<any>(`${this.SMS_CONFIGURATION_API}/resend-birthday-sms`, request);
  }



  reSendAppointmentSMS(request) {
    return this.http.post<any>(`${this.SMS_CONFIGURATION_API}/resend-appointment-sms`, request);
  }

  reSendCampaignSMS(request) {
    return this.http.post<any>(`${this.SMS_COMPAIGN_API}/resend-campaign-sms`, request);
  }

}


class SmsConfigurationLazyRequest extends LazyRequest {
  textSearch?: string;
  reminderType?: ReminderType;
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {PromotionTemplateEditorComponent} from "./promotion-template-editor.component";
import {NgSelectModule} from "@ng-select/ng-select";
import {MatLegacyButtonModule} from "@angular/material/legacy-button";
import {MatIconModule} from "@angular/material/icon";
import {TranslateModule} from "@ngx-translate/core";
import {PromotionDetailsFormComponent} from "./promotion-details-form.component";
import {  MatLegacyDialogModule} from "@angular/material/legacy-dialog";
import {ReactiveFormsModule} from "@angular/forms";

const routes: Routes = [
  {path: '', component: PromotionTemplateEditorComponent}
];

@NgModule({
    declarations: [
        PromotionTemplateEditorComponent,
        PromotionDetailsFormComponent,
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        NgSelectModule,
        MatLegacyButtonModule,
        MatIconModule,
        TranslateModule,
        MatLegacyDialogModule,
        ReactiveFormsModule
    ]
})
export class PromotionTemplateEditorModule {
}

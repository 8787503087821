import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {SubscriptionManagementComponent} from './subscription-management.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatIconModule} from '@angular/material/icon';
import {SharedModule} from '../../../../shared/shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbDropdownModule, NgbPaginationModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {CurrencyMaskModule} from "ng2-currency-mask";
import {SubscriptionManagementFormComponent} from './subscription-management-form/subscription-management-form.component';
import {SubscriptionFormService} from './services/subscription-form.service';
import {SubscriptionFormModule} from './subscription-form/subscription-form.module';


const routes: Routes = [
  {path: '', component: SubscriptionManagementComponent}
];


@NgModule({
  declarations: [SubscriptionManagementComponent, SubscriptionManagementFormComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    RouterModule.forChild(routes),
    SharedModule.forChild(),
    ReactiveFormsModule,
    NgbPaginationModule,
    MatDatepickerModule,
    NgbDropdownModule,
    CurrencyMaskModule,
    MatCheckboxModule,
    FormsModule,
    SubscriptionFormModule,
    NgbTooltipModule
  ],
  providers: [
    SubscriptionFormService,
  ]
})
export class SubscriptionManagementModule {
}

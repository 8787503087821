// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.role-title {
  font-size: 16px;
  font-weight: 500;
}

.dropdown-toggle::after {
  display: none;
}

.role-container {
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.role-container:hover {
  background-color: #f4f6f7;
}

.active-role {
  background-color: var(--header-color) !important;
  border: none;
}
.active-role p {
  color: #b3b3b3 !important;
}
.active-role .ft-more-vertical, .active-role .role-title {
  color: white;
}

.select-role-msg {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: 400;
}
.select-role-msg div {
  width: 40%;
  text-align: center;
}

@media screen and (max-width: 470px) {
  .select-role-msg div {
    width: 100%;
  }
  .my-checkbox ::ng-deep .mat-checkbox-layout {
    white-space: normal !important;
  }
}
.modules-children {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.authorities-loader {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  background-color: rgba(23, 30, 51, 0.13);
}`, "",{"version":3,"sources":["webpack://./src/app/administrative-content/pages/admin-module/components/role-mgm/role-mgm.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;AACF;;AACA;EACE,aAAA;AAEF;;AAAA;EACE,eAAA;EACA,6CAAA;AAGF;;AADA;EACE,yBAAA;AAIF;;AAFA;EACE,gDAAA;EAIA,YAAA;AAEF;AALE;EACE,yBAAA;AAOJ;AAJE;EACE,YAAA;AAMJ;;AAHA;EACE,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,gBAAA;AAMF;AALE;EACE,UAAA;EACA,kBAAA;AAOJ;;AAHA;EACE;IACE,WAAA;EAMF;EAJA;IACE,8BAAA;EAMF;AACF;AAJA;EACE,gBAAA;EACA,aAAA;EACA,qCAAA;AAMF;;AAJA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,WAAA;EACA,WAAA;EACA,wCAAA;AAOF","sourcesContent":[".role-title {\r\n  font-size: 16px;\r\n  font-weight: 500;\r\n}\r\n.dropdown-toggle::after {\r\n  display:none;\r\n}\r\n.role-container {\r\n  cursor: pointer;\r\n  transition: background-color .2s ease-in-out;\r\n}\r\n.role-container:hover {\r\n  background-color: lighten(#2B333D, 76%);\r\n}\r\n.active-role {\r\n  background-color: var(--header-color) !important;\r\n  p {\r\n    color: lighten(black, 70%) !important;\r\n  }\r\n  border: none;\r\n  .ft-more-vertical, .role-title {\r\n    color: white;\r\n  }\r\n}\r\n.select-role-msg {\r\n  height: 200px;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  font-size: 2rem;\r\n  font-weight: 400;\r\n  div {\r\n    width: 40%;\r\n    text-align: center;\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 470px){\r\n  .select-role-msg div {\r\n    width: 100%\r\n  }\r\n  .my-checkbox ::ng-deep .mat-checkbox-layout {\r\n    white-space: normal !important;\r\n  }\r\n}\r\n.modules-children {\r\n  list-style: none;\r\n  display: grid;\r\n  grid-template-columns: repeat(3, 1fr);\r\n}\r\n.authorities-loader {\r\n  position: absolute;\r\n  top: 0;\r\n  left: 0;\r\n  height: 100%;\r\n  width: 100%;\r\n  z-index: 10;\r\n  background-color: rgba(23, 30, 51, 0.13);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

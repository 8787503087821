// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*

.form-content {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 67px - 26px);
}

 */
::ng-deep .modal-lg {
  max-width: 95% !important;
}`, "",{"version":3,"sources":["webpack://./src/app/content/pages/contacts/client-management/client-subscription/client-subscription.component.scss"],"names":[],"mappings":"AAAA;;;;;;;;EAAA;AASA;EACE,yBAAA;AACF","sourcesContent":["/*\r\n\r\n.form-content {\r\n  overflow-y: auto;\r\n  overflow-x: hidden;\r\n  max-height: calc(100vh - 67px - 26px);\r\n}\r\n\r\n */\r\n::ng-deep .modal-lg {\r\n  max-width: 95% !important;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-heading {
  padding-bottom: 0;
}

.alert-vertical-bar {
  width: 6px;
  height: 11px;
  background: white;
  transform: translate(22px, -27px);
  z-index: -1;
  position: absolute;
}

@media only screen and (max-width: 600px) {
  .alert-vertical-bar {
    width: 3px;
    height: 11px;
    background: white;
    transform: translate(21px, -27px);
    z-index: -1;
    position: absolute;
  }
}
@media only screen and (max-width: 400px) {
  .notifications-wrapper {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/content/layout/components/header/header.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;;AACA;EACE,UAAA;EACA,YAAA;EACA,iBAAA;EACA,iCAAA;EACA,WAAA;EACA,kBAAA;AAEF;;AAEA;EACE;IACE,UAAA;IACA,YAAA;IACA,iBAAA;IACA,iCAAA;IACA,WAAA;IACA,kBAAA;EACF;AACF;AAEA;EACE;IACE,aAAA;EAAF;AACF","sourcesContent":[".page-heading{\r\n    padding-bottom:0 ;\r\n}\r\n.alert-vertical-bar{\r\n  width: 6px;\r\n  height: 11px;\r\n  background: white;\r\n  transform: translate(22px,-27px);\r\n  z-index: -1;\r\n  position: absolute;\r\n}\r\n\r\n\r\n@media  only screen and (max-width: 600px){\r\n  .alert-vertical-bar {\r\n    width: 3px;\r\n    height: 11px;\r\n    background: white;\r\n    transform: translate(21px,-27px);\r\n    z-index: -1;\r\n    position: absolute;\r\n  }\r\n\r\n}\r\n@media  only screen and (max-width: 400px){\r\n  .notifications-wrapper{\r\n    display: none;\r\n  }\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import {Component, HostListener, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PromoType} from '../../../../shared/enum/promo-type';
import {ClientMgmService} from '../../../../shared/services/client-mgm.service';
import {FilterClientsComponent} from '../filter-clients/filter-clients.component';
import {PromotionMgmService} from '../../../../shared/services/promotion-mgm.service';
import {PromoModel} from '../../../../shared/models/promo-model';
import {debounceTime, take, takeUntil} from 'rxjs/operators';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {SweetAlertService} from '../../../../shared/services/sweet-alert.service';
import {Promotion} from '../../../../shared/models/promotion';
import moment from 'moment';
import {TemplateEditorComponent} from "../template-editor/template-editor.component";

@Component({
  selector: 'app-promotion-management-form',
  templateUrl: './promotion-management-form.component.html',
  styleUrls: ['./promotion-management-form.component.scss']
})
export class PromotionManagementFormComponent implements OnInit {
  onClose = new Subject();
  appRef;
  dialogComponentRef;
  editMode;
  editClicked = false;
  promo: Promotion;
  submitted = false;
  disableSave = false;
  promoForm: FormGroup;
  types = [];
  receivers;
  receiversId = [];
  models: PromoModel[] = [];
  template: SafeHtml = '';
  clientRows: any[];
  dialogRef: any;
  smsFormControl = new FormControl();
  mailFormControl = new FormControl();
  unsubscribe$ = new Subject();
  private design: string;
  private postText: string;
  private postImageUrl: string;
  private templateTxt: string;

  toggled: boolean = false;
  messageSize: number = 153;

  constructor(private translate: TranslateService,
              public snackBar: MatSnackBar,
              private matDialog: MatDialog,
              private clientService: ClientMgmService,
              private promoService: PromotionMgmService,
              private sanitizer: DomSanitizer,
              private sweetAlertService: SweetAlertService,
  ) {
  }

  ngOnInit() {
    this.promoService.getModels().subscribe(r => {
      this.models = r;
    });
    this.translateTypes();
    const sentDate = new Date();
    sentDate.setHours(sentDate.getHours() + 1);
    this.promoForm = new FormGroup({
      id: new FormControl(),
      seq: new FormControl(),
      type: new FormControl(null),
      promoModelId: new FormControl(null, Validators.required),
      object: new FormControl(null),
      message: new FormControl(null, Validators.required),
      sendAt: new FormControl(sentDate, Validators.required),
      clientsId: new FormArray([]),
      shareFacebook: new FormControl(false),
      shareInstagram: new FormControl(false),
    });
    this.promoForm.get('promoModelId').valueChanges.pipe(debounceTime(800)).subscribe(r => {
      if (r && this.models.length) {
        const obj = this.models.find(u => u.id === r);
        this.template = this.sanitizer.bypassSecurityTrustHtml(obj.template);
        this.templateTxt = obj.template;
        this.design = obj.design;
        this.postText = obj.postText;
        this.postImageUrl = obj.postImageUrl;
        return;
      }
      this.template = '';
    });

    this.promoForm.get('shareFacebook').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(r => {
      if (r) {
        this.promoForm.get('message').enable();
        this.promoForm.get('message').clearValidators();

        this.promoForm.get('promoModelId').enable();
        this.promoForm.get('promoModelId').setValidators(Validators.required);

      } else {
        if (this.mailFormControl.value && this.promoForm.get('shareInstagram').value === false) {
          this.promoForm.get('message').disable();
          this.promoForm.get('message').clearValidators();
          this.promoForm.get('promoModelId').enable();
          this.promoForm.get('promoModelId').setValidators(Validators.required);
        }
        if (this.smsFormControl.value && this.promoForm.get('shareInstagram').value === false) {
          this.promoForm.get('message').enable();
          this.promoForm.get('promoModelId').disable();
          this.promoForm.get('promoModelId').clearValidators();
        }
      }
      this.promoForm.get('promoModelId').updateValueAndValidity();
      this.promoForm.get('object').updateValueAndValidity();
      this.promoForm.get('message').updateValueAndValidity();

    });

    this.promoForm.get('shareInstagram').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(r => {
      if (r) {
        this.promoForm.get('message').enable();
        this.promoForm.get('message').setValidators(Validators.required);
        this.promoForm.get('message').updateValueAndValidity();
        this.promoForm.get('promoModelId').enable();
        this.promoForm.get('promoModelId').setValidators(Validators.required);
      } else {
        if (this.mailFormControl.value && this.promoForm.get('shareFacebook').value === false) {
          this.promoForm.get('message').disable();
          this.promoForm.get('message').clearValidators();
          this.promoForm.get('promoModelId').enable();
          this.promoForm.get('promoModelId').setValidators(Validators.required);
        }
        if (this.smsFormControl.value && this.promoForm.get('shareFacebook').value === false) {
          this.promoForm.get('message').enable();
          this.promoForm.get('promoModelId').disable();
          this.promoForm.get('promoModelId').clearValidators();
        }
      }
      this.promoForm.get('promoModelId').updateValueAndValidity();
      this.promoForm.get('object').updateValueAndValidity();
      this.promoForm.get('message').updateValueAndValidity();

    });
    this.smsFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(r => {
      if (r) {
        this.promoForm.get('object').disable();
        this.promoForm.get('object').clearValidators();
        this.promoForm.get('message').enable();
        this.promoForm.get('message').setValidators(Validators.required);
        this.promoForm.get('promoModelId').disable();
        this.promoForm.get('promoModelId').clearValidators();
        if (this.mailFormControl.value || this.promoForm.get('shareInstagram').value === true || this.promoForm.get('shareFacebook').value === true) {
          this.promoForm.get('promoModelId').enable();
          this.promoForm.get('promoModelId').setValidators(Validators.required);
        }
        if (this.mailFormControl.value) {
          this.promoForm.get('object').enable();

          // this.promoForm.get('type').setValue(PromoType.MAIL_AND_SMS);
        } else if (this.smsFormControl.value) {
          this.promoForm.get('type').setValue(PromoType.SMS);
        } else {
          this.promoForm.get('type').setValue(null);
        }
      } else {
        this.promoForm.get('promoModelId').enable();
        this.promoForm.get('promoModelId').setValidators(Validators.required);
        if (!this.mailFormControl.value) {
          this.promoForm.get('object').disable();
          this.promoForm.get('object').clearValidators();
          this.promoForm.get('type').setValue(null);
        } else {
          this.promoForm.get('object').enable();
          this.promoForm.get('message').disable();
          this.promoForm.get('message').clearValidators();
        }
        // this.promoForm.get('type').setValue(PromoType.MAIL);
        // this.mailFormControl.setValue(true, {emitEvent: false});
      }
      this.promoForm.get('promoModelId').updateValueAndValidity();
      this.promoForm.get('object').updateValueAndValidity();
      this.promoForm.get('message').updateValueAndValidity();
    });

    this.mailFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(r => {
      if (r) {
        this.promoForm.get('object').enable();
        this.promoForm.get('object').setValidators(Validators.required);
        this.promoForm.get('promoModelId').enable();
        this.promoForm.get('promoModelId').setValidators(Validators.required);

        if (this.smsFormControl.value) {
          this.promoForm.get('message').enable();
          this.promoForm.get('message').setValidators(Validators.required);
          this.promoForm.get('promoModelId').enable();
          this.promoForm.get('promoModelId').setValidators(Validators.required);


          // this.promoForm.get('type').setValue(PromoType.MAIL_AND_SMS);
        } else if (this.mailFormControl.value) {
          this.promoForm.get('type').setValue(PromoType.MAIL);
          if (!this.promoForm.get('shareFacebook').value && !this.promoForm.get('shareInstagram').value) {
            this.promoForm.get('message').disable();
            this.promoForm.get('message').clearValidators();
          }

        } else {
          this.promoForm.get('type').setValue(null);
        }
      } else {
        this.promoForm.get('object').disable();
        if (!this.smsFormControl.value) {
          this.promoForm.get('object').disable();
          this.promoForm.get('object').clearValidators();
          this.promoForm.get('type').setValue(null);
        } else {
          this.promoForm.get('promoModelId').disable();
          this.promoForm.get('promoModelId').clearValidators();
        }
        // this.promoForm.get('type').setValue(PromoType.SMS);
        // this.smsFormControl.setValue(true, {emitEvent: false});
      }
      this.promoForm.get('promoModelId').updateValueAndValidity();
      this.promoForm.get('object').updateValueAndValidity();
      this.promoForm.get('message').updateValueAndValidity();
    });
    if (!this.editMode) {
      this.countReceivers();
      this.mailFormControl.setValue(null, {emitEvent: true})
    }
    if (this.editMode) {
      if (this.promo.template) {
        this.promoForm.patchValue(this.promo, {emitEvent: false});
        this.template = this.sanitizer.bypassSecurityTrustHtml(this.promo.template);
        this.templateTxt = this.promo.template;
        this.design = this.promo.design;
        this.postText = this.promo.postText;
        this.postImageUrl = this.promo.postImageUrl;
      } else {
        this.promoForm.patchValue(this.promo);
      }
      this.receiversId = this.promo.clientsId;
      this.receivers = this.receiversId.length;
      if (this.promo.type === PromoType.SMS || this.promo.type === PromoType.MAIL_AND_SMS) {
        this.smsFormControl.setValue(true, {emitEvent: false})
      }
      if (this.promo.type === PromoType.MAIL || this.promo.type === PromoType.MAIL_AND_SMS) {
        this.mailFormControl.setValue(true, {emitEvent: false})
      }
      this.promoForm.disable({emitEvent: false});
      this.smsFormControl.disable();
      this.mailFormControl.disable();
    }
  }


  closeForm() {
    if (this.promoForm.dirty) {
      this.sweetAlertService.showConfirmationAlert()
        .then((confirmation) => {
          if (confirmation) {
            this.destroyForm();
          }
        });
    } else {
      this.closeFormSave()
    }
  }

  closeFormSave() {
    this.destroyForm();
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const clickedElement = event.target as HTMLElement;
    if (clickedElement && (clickedElement.tagName === 'TEXTAREA' || clickedElement.tagName === 'DIV')) {
      this.toggled = false;
    }
  }

  addEmoji(evt) {
    const content = [
      this.promoForm.get('message').value,
      evt.emoji.native].join('');
    this.promoForm.get('message').setValue(content);
  }

  destroyForm() {
    this.appRef.detachView(this.dialogComponentRef.hostView);
    this.dialogComponentRef.destroy();
  }

  save() {
    if (this.promoForm.get('type').value === null && this.promoForm.get('shareFacebook').value === false && this.promoForm.get('shareInstagram').value === false) {
      this.sweetAlertService.notification(this.translate.instant('DIALOG.YOU_MUST_SELECT_METHOD_PROMO')).then(e => {
      });
      return;
    }
    if (this.promoForm.invalid) {
      this.sweetAlertService.notification(this.translate.instant('DIALOG.INSERT_ALL_MANDATORY_INFORMATION')).then(e => {
      });
      return;
    }
    if (!this.receiversId.length) {
      this.sweetAlertService.notification(this.translate.instant('DIALOG.YOU_MUST_SELECT_CLIENTS')).then(e => {
      });
      return;
    }
    this.promoForm.get('sendAt').setValue(moment(this.promoForm.get('sendAt').value).format('YYYY-MM-DDTHH:mm:ss'));
    if (!this.editMode) {
      this.promoService.create({
        ...this.promoForm.getRawValue(),
        postText: this.postText,
        postImageUrl: this.postImageUrl,
        design: this.design,
        template: this.templateTxt,
      }).subscribe(r => {
        this.onClose.next(r);
        this.closeFormSave();
      });
    } else {
      this.promoService.update({
        ...this.promoForm.getRawValue(),
        postText: this.postText,
        postImageUrl: this.postImageUrl,
        design: this.design,
        template: this.templateTxt,
      }).subscribe(r => {
        this.onClose.next(r);
        this.closeFormSave();
      });
    }
  }


  translateTypes() {
    this.types = [{description: this.translate.instant('PROMO_FORM.' + PromoType.SMS), id: PromoType.SMS},
      {description: this.translate.instant('PROMO_FORM.' + PromoType.MAIL), id: PromoType.MAIL},
      {description: this.translate.instant('PROMO_FORM.' + PromoType.MAIL_AND_SMS), id: PromoType.MAIL_AND_SMS}];
  }

  countReceivers() {
    this.clientService.countReceivers().subscribe(r => {
      this.receivers = r.length;
      this.receiversId = r;
      this.setReceivers();
    });
  }

  openFilterClients() {
    const dialogRef = this.matDialog.open(FilterClientsComponent,
      {disableClose: true, autoFocus: true, width: '1400px', data: this.receiversId});
    dialogRef.afterClosed().subscribe(d => {
      if (d && d.length) {
        this.receivers = d.length;
        this.receiversId = d;
        const formArray: FormArray = this.promoForm.get('clientsId') as FormArray;
        while (formArray.length !== 0) {
          formArray.removeAt(0);
        }
        this.receiversId.forEach(id => {
          formArray.push(new FormControl(id));
        });
      }
    });
  }


  activateEdit() {
    this.promoForm.enable({emitEvent: false});
    this.smsFormControl.enable();
    this.mailFormControl.enable();
    this.editClicked = true;
  }

  openReceiversContent(content) {
    if (this.promo) {
      this.clientRows = this.promo.promoClients.reverse();
      if (this.dialogRef) {
        this.dialogRef.close();
      }
      this.dialogRef = this.matDialog.open(content, {
        autoFocus: false,
        maxHeight: '90vh'
      });
    } else {
      this.clientService.getClientsByIds(this.promo.clientsId).subscribe(r => {
        if (r) {
          this.clientRows = r;
          if (this.dialogRef) {
            this.dialogRef.close();
          }
          this.dialogRef = this.matDialog.open(content, {
            autoFocus: false,
            maxHeight: '90vh'
          });
        }
      });
    }
  }

  setReceivers() {
    const formArray: FormArray = this.promoForm.get('clientsId') as FormArray;
    this.receiversId.forEach(id => {
      formArray.push(new FormControl(id));
    });
  }

  openTemplateEditor() {
    this.matDialog.open(TemplateEditorComponent, {
      width: '100vw',
      panelClass: 'templateEditorDialog',
      data: {
        design: this.design
      }
    }).afterClosed().pipe(take((1)))
      .subscribe(res => {
        if (res) {
          this.template = this.sanitizer.bypassSecurityTrustHtml(res.template);
          this.templateTxt = res.template;
          this.design = res.design;
          this.postText = res.postText;
          this.postImageUrl = res.postImageUrl;
        }
      });
  }


  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.promoForm.dirty) {
      $event.returnValue = true;
    }
  }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card-header .action-btns button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 1px solid #9b9b9b;
}
.card-header .action-btns button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/content/pages/promotion-template-editor/promotion-template-editor.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AACF;AACI;EACE,0BAAA;EACA,6BAAA;EACA,+BAAA;AACN;AACI;EACE,yBAAA;EACA,4BAAA;AACN","sourcesContent":[".card-header {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  .action-btns {\r\n    button:first-child {\r\n      border-top-right-radius: 0;\r\n      border-bottom-right-radius: 0;\r\n      border-right: 1px solid #9b9b9b;\r\n    }\r\n    button:last-child {\r\n      border-top-left-radius: 0;\r\n      border-bottom-left-radius: 0;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

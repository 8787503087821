import { Injectable } from '@angular/core';
import {SmsCompaign} from "../models/sms-compaign";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {SmsConfiguration} from "../models/sms-configuration";
import {WhatsappConfiguration} from "../models/WhatsappConfiguration";
import {WhatsappCompaign} from "../models/WhatsappCompaign";
import {WhatsappMessage} from "../models/WhatsappMessage";
import {Observable} from "rxjs";
import {WhatsappSendResponse} from "../models/WhatsappSendResponse";

@Injectable({
  providedIn: 'root'
})
export class WhatsappServiceService {
  WhatsApp_CONFIGURATION_API = environment.api + '/whatsapp-configuration';
  constructor(private http: HttpClient) { }
  private readonly storageKey = 'whatsappConfiguration';
  private otherFormValues: any;

  setOtherFormValues(values: any): void {
    this.otherFormValues = values;
    localStorage.setItem(this.storageKey, JSON.stringify(values));
  }

  getOtherFormValues(): any {
    return this.otherFormValues || JSON.parse(localStorage.getItem(this.storageKey) || '{}');
  }
  addNewConfiguration(request) {
    return this.http.post<WhatsappConfiguration>(`${this.WhatsApp_CONFIGURATION_API}`, request);
  }
  getConfiguration() {
    return this.http.get<WhatsappConfiguration>(`${this.WhatsApp_CONFIGURATION_API}`);
  }

  sendWhatsAppMessage(whatsappmsg:WhatsappMessage): Observable<WhatsappSendResponse> {

    // @ts-ignore
    return this.http.post(`${this.WhatsApp_CONFIGURATION_API}/send`,whatsappmsg);
  }

  reSendWhatsAppBirthday(request) {
    return this.http.post<any>(`${this.WhatsApp_CONFIGURATION_API}/resend-birthday-whatsapp-message`, request);
  }

  reSendAppointmentWhatsapp(request) {
    return this.http.post<any>(`${this.WhatsApp_CONFIGURATION_API}/resend-appointment-whatsapp-message`, request);
  }

  reSendCampaignWhatsapp(request) {
    return this.http.post<any>(`${this.WhatsApp_CONFIGURATION_API}/resend-campaign-whatsapp-message`, request);
  }

  modifyTemplate(originalTemplate : String) {

    return this.http.post(`${this.WhatsApp_CONFIGURATION_API}/modifyTemplate`,originalTemplate, { responseType: 'text' });
  }
  modifyTemplateBirthday(originalTemplate : String) {

    return this.http.post(`${this.WhatsApp_CONFIGURATION_API}/modifyTemplateBirthday`,originalTemplate, { responseType: 'text' });
  }


}

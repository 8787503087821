// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.time-input {
  width: 4rem;
  text-align: center;
  border: aliceblue;
}

.checkbox {
  display: inline-block;
}

.subheader {
  display: flex;
  justify-content: space-between;
}

.mat-column-action {
  text-align: right;
}

.mat-dialog-content {
  max-height: 50vh;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/week-template-form/week-planning-form.component.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,kBAAA;EACA,iBAAA;AAAF;;AAGA;EACE,qBAAA;AAAF;;AAEA;EACE,aAAA;EACA,8BAAA;AACF;;AACA;EACE,iBAAA;AAEF;;AAAA;EACE,gBAAA;AAGF","sourcesContent":["\r\n.time-input {\r\n  width: 4rem;\r\n  text-align: center;\r\n  border: aliceblue;\r\n}\r\n\r\n.checkbox {\r\n  display: inline-block\r\n}\r\n.subheader {\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n.mat-column-action {\r\n  text-align: right;\r\n}\r\n.mat-dialog-content {\r\n  max-height: 50vh;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

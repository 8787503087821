import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of, Subject} from 'rxjs';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {MovementProductFormComponent} from '../../movement-management/movement-management-form/movement-product-form.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {  MatSnackBar} from '@angular/material/snack-bar';
import {catchError, debounceTime, distinctUntilChanged, map, switchMap, takeUntil} from 'rxjs/operators';
import {Movement} from '../../../../../shared/models/movement';
import {MovementProduct} from '../../../../../shared/models/movement-product';
import {PaymentMethodsType} from '../../../../../shared/enum/payment-methods-type';
import {MovementType} from '../../../../../shared/enum/movement-type';
import {TypeDocument} from '../../../../../shared/enum/type-document';
import {MovementMgmService} from '../../../../../shared/services/movement-mgm.service';
import {ProviderMgmService} from '../../../../../shared/services/provider-mgm.service';
import {ClientMgmService} from '../../../../../shared/services/client-mgm.service';
import {SweetAlertService} from '../../../../../shared/services/sweet-alert.service';
import {ProductMgmService} from '../../../../../shared/services/product-mgm.service';
import {ProductBarcodeType} from '../../../../../shared/enum/product-barcode-type';
import {BarCodeMgmService} from '../../../../../shared/services/bar-code-mgm.service';
import {Product} from '../../../../../shared/models/product';
// tslint:disable-next-line:max-line-length
import {ProductManagementFormComponent} from '../../../productsAndServices/product-management/product-management-form/product-management-form.component';
import {Provider} from '../../../../../shared/models/provider';
import {Brand} from '../../../../../shared/models/brand';
import {ConfigurationsService} from '../../../../../shared/services/configurations.service';
import {SellPointService} from '../../../../../shared/services/sell-point.service';
import {SessionStorageService} from 'ngx-webstorage';
import createPrefixedInputHandler from '../../../payment/create-prefixed-input-handler-config';
import {CustomSnackBarComponent} from '../../../../../shared/custom-snack-bar/custom-snack-bar.component';
import {ZipCode} from '../../../../../shared/models/zip-code';
import {ZipCodeService} from '../../../../../shared/services/zip-code.service';
import {UserService} from '../../../../../shared/services/user.service';
import {Account} from '../../../../../shared/models/account.model';

const MAX_NUMBER = 2147483646;

@Component({
  selector: 'app-transfer-movement-form',
  templateUrl: './transfer-movement-form.component.html',
  styleUrls: ['./transfer-movement-form.component.scss']
})
export class TransferMovementFormComponent implements OnInit, OnDestroy {
  @ViewChild('notFoundedProduct') notFoundedProduct: ElementRef;
  @ViewChild('file') inputFile: ElementRef;
  @ViewChild('prodTable') private prodTable: ElementRef;
  @ViewChild('selectProd') private selectProd: ElementRef;

  data;
  movement: Movement;
  appRef;
  dialogComponentRef;
  unsubscribe1$ = new Subject();
  unsubscribe2$ = new Subject();
  onClose = new Subject();
  editMode;
  editClicked = false;
  movementForm: UntypedFormGroup;
  columns = [' ', 'MOVEMENT_FORM.CODE', 'PRODUCT_FORM.DESCRIPTION', 'PRODUCT_FORM.MEASURE_UNIT', 'MOVEMENT_FORM.QUANTITY',
    'DATA_TABLE.PURCHASE_PRICE', 'MOVEMENT_FORM.IVA', 'MOVEMENT_FORM.TOTAL'];
  rows: MovementProduct[] = [];
  types = [];
  // documentTypes = [];
  unsubscribe$ = new Subject();
  disableSave = false;
  file: FormData = new FormData();
  fileToUpload: File = null;
  disableDownload = false;
  DEFAULT_LANG = 'IT';
  uploaded;
  dialogRef: any;
  printTicketSent = false;
  selectedType;
  ticketNumber = 1;
  printTypes = [];
  movementColumns = [];
  type;
  productCode = null;
  searchedProduct: Product = new Product();
  productQtt = 1;
  productGiftQtt = 0;
  productList: Product[];
  searchFormControl: UntypedFormGroup;
  page = 1;
  loading = false;
  providers: Provider[] = [];
  brands: Brand[] = [];
  productSearchList: Product[] = [];
  productColumns = [];
  totalRecords: number;
  pageSize = 10;
  product: Product;
  editProductMode = false;
  editProductClicked = false;
  saveEventsSubject: Subject<void> = new Subject<void>();
  editEventsSubject: Subject<void> = new Subject<void>();
  providerSearchList: Provider[] = [];
  searchProviderFormControl: UntypedFormGroup;
  zipCodeList: ZipCode[] = [];
  editProviderMode = false;
  editProviderClicked = false;
  provider: Provider;
  saveProviderEventsSubject: Subject<void> = new Subject<void>();
  editProviderEventsSubject: Subject<void> = new Subject<void>();
  newCode = '';
  sellPoints = [];
  sellPointsDest = [];
  allSellPoints = [];
  editSelectedProd = false;
  listener: any;
  productToReplace: any;
  providersPage = 1;
  brandPage = 1;
  unsubscribe = new Subject();
  user: Account;
  siegePdv = false;

  constructor(private http: HttpClient,
              private matDialog: MatDialog,
              private movementMgmService: MovementMgmService,
              private translate: TranslateService,
              private formBuilder: UntypedFormBuilder,
              private providerService: ProviderMgmService,
              private clientService: ClientMgmService,
              private productService: ProductMgmService,
              private configurationsService: ConfigurationsService,
              private sweetAlertService: SweetAlertService,
              private barcodeService: BarCodeMgmService,
              private sellPointService: SellPointService,
              public snackBar: MatSnackBar,
              private zipCodeService: ZipCodeService,
              private userService: UserService,
              private sessionStorage: SessionStorageService
  ) {
    this.initialiseListener();
    this.user = userService.getUser();

  }

  getTotal(price, quantity) {
    return (price * quantity) ? price * quantity : 0;
  }

  ngOnInit() {
    console.log(this.user);
    this.movementColumns = this.columns;
    this.types = [
      {description: this.translate.instant('MOVEMENT_FORM.' + MovementType.TRANSFER), id: MovementType.TRANSFER},
    ];
    // this.translateDocumentTypes();
    this.getProviders();
    this.getBrands();

    this.movementForm = new UntypedFormGroup({
      id: new UntypedFormControl(),
      date: new UntypedFormControl(new Date(), Validators.required),
      number: new UntypedFormControl(null),
      type: new UntypedFormControl(MovementType.TRANSFER),
      // documentType: new FormControl(this.documentTypes[0].id),
      // documentDate: new FormControl(new Date(), Validators.required),
      // documentNumber: new FormControl(null),
      totalQuantity: new UntypedFormControl(null),
      totalIva: new UntypedFormControl(null),
      totalTaxable: new UntypedFormControl(null),
      totalIvato: new UntypedFormControl(null),
      draft: new UntypedFormControl(false),
      createdAt: new UntypedFormControl(),
      updatedAt: new UntypedFormControl(),
      searchedProduct: new UntypedFormControl(null),
      productQtt: new UntypedFormControl(null),
      productGiftQtt: new UntypedFormControl(null),
      productPurchasePrice: new UntypedFormControl(null),
      productPrice: new UntypedFormControl(null),
      sellPointId: new UntypedFormControl(null),
      sellPointDestId: new UntypedFormControl(null),
      products: this.formBuilder.array([])
    });

    this.movementForm.get('sellPointId').valueChanges.pipe(debounceTime(500), takeUntil(this.unsubscribe)).subscribe(r => {
      if (r) {
        this.sellPointsDest = [...this.allSellPoints].filter(u => u.sellPointId !== r);
        return;
      }
      this.sellPointsDest = [...this.allSellPoints];
    });
    this.movementForm.get('sellPointId').disable();
    this.movementForm.get('sellPointDestId').valueChanges.pipe(debounceTime(500), takeUntil(this.unsubscribe)).subscribe(r => {
      if (r) {
        this.sellPoints = [...this.allSellPoints].filter(u => u.sellPointId !== r);
        return;
      }
      this.sellPoints = [...this.allSellPoints];
    });
    if (!this.editMode) {
      this.movementMgmService.getNextNumber().subscribe(d => {
        this.movementForm.get('number').setValue(('0000' + d).slice(-4), {emitEvent: false});
      });
    }
    this.getSellPointsFromAccount();
    if (this.editMode) {
      this.movementForm.patchValue(this.movement, {emitEvent: false});
      this.movement.products.forEach(p => {
        const control = this.formBuilder.group(p);
        this.productsForm.insert(this.movement.products.indexOf(p), control);
        this.movementForm.get('number').setValue(('0000' + this.movement.number).slice(-4));
      });
      this.movementForm.disable();
    }
    this.productsFormValueChange();
    this.zipCodeService.getAllZipCodesByCountry('IT').subscribe(d => {
      this.zipCodeList = d;
    });
  }

  saveMovementHeader() {
    this.movementMgmService.saveHeader(this.movementForm.value).subscribe(r => {
      console.log(r);
      this.movement = r;
      this.movementForm.patchValue(r, {emitEvent: false});
      this.movementForm.disable({emitEvent: false});
      this.enableAddProduct();
      this.addProductToList();
    });
  }

  enableAddProduct() {
    this.movementForm.get('searchedProduct').enable({emitEvent: false});
    this.movementForm.get('productQtt').enable({emitEvent: false});
    this.movementForm.get('productGiftQtt').enable({emitEvent: false});
    this.movementForm.get('productPurchasePrice').enable({emitEvent: false});
    this.movementForm.get('productPrice').enable({emitEvent: false});
  }

  saveMovementRow(productForm) {
    this.movementMgmService.saveRow(productForm).subscribe(r => {
      this.showSnackBar({
        type: this.translate.instant('HEADER.BREADCRUMB.MOVEMENT'),
        actionIcon: 'save',
        actionMsg: this.translate.instant('DIALOG.ADD_SUCCESS')
      });
      productForm.id = r.id;
      const control = this.formBuilder.group(productForm);
      this.productsForm.insert(this.productsForm.length, control);
      this.prodTable.nativeElement.scrollTop = this.prodTable.nativeElement.scrollHeight;
    });
  }

  getSellPointsFromAccount() {
    this.sellPointService.getSellPointsByAccountByCentre().subscribe(res => {
      this.allSellPoints = res.sellPoints;
      this.sellPoints = res.sellPoints;
      this.sellPointsDest = res.sellPoints;
      this.movementForm.get('sellPointId').setValue(this.user.sellPointId);
      const sp = res.sellPoints.filter(sp => sp.sellPointId == this.user.sellPointId).shift();
      if (sp && sp.siege) {
        this.siegePdv = true;
        this.movementForm.get('sellPointId').enable();
      }
    });
    return;
  }

  ngOnDestroy(): void {
    this.unsubscribe1$.next(void 0);
    this.unsubscribe1$.unsubscribe();
    this.unsubscribe2$.next(void 0);
    this.unsubscribe2$.unsubscribe();
  }

  get productsForm() {
    return this.movementForm.get('products') as UntypedFormArray;
  }

  destroyForm() {
    this.appRef.detachView(this.dialogComponentRef.hostView);
    this.dialogComponentRef.destroy();
  }

  closeForm() {
    this.destroyForm();
  }

  activateEdit() {
    if (this.editMode) {
      this.editClicked = true;
      this.movementForm.get('date').setValue(new Date());
      this.movementForm.get('products').enable();
      this.enableAddProduct();
      this.prodTable.nativeElement.scrollTop = this.prodTable.nativeElement.scrollHeight;
      this.focusInInput('searchedProduct');
    }
  }

  confirmMovement() {
    this.movementMgmService.confirmMovement(this.movementForm.getRawValue()).subscribe(r => {
      this.onClose.next(r);
      this.closeForm();
    });
  }

  save(draft: boolean) {
    this.movementForm.get('draft').setValue(draft);
    if (!this.productsForm.controls.length) {
      this.sweetAlertService.warning(this.translate.instant('DIALOG.NO_PRODUCT_INSERTED'))
        .then(res => {
        });
      return;
    }
    if (!this.movementForm.valid) {
      this.sweetAlertService.notification(this.translate.instant('DIALOG.INSERT_ALL_MANDATORY_INFORMATION')).then(e => {
      });
      return;
    }
    this.disableSave = true;
    if (this.movement && this.movement.draft) {
      this.confirmMovement();
      return;
    }
    if (!this.editMode) {
      this.movementMgmService.create(this.movementForm.getRawValue()).subscribe(d => {
        this.onClose.next(d);
        this.closeForm();
      }, error => this.disableSave = false);
      return;
    }
    this.movementMgmService.update(this.movementForm.getRawValue()).subscribe(d => {
      this.onClose.next(d);
      this.closeForm();
    }, error => this.disableSave = false);
  }

  delete($event: MouseEvent, product) {
    if (this.productsForm.controls.length === 1) {
      this.sweetAlertService.warning(
        this.translate.instant('MOVEMENT_FORM.DELETE_LAST_MOVEMENT_ROW')).then(e => {
        if (e.value) {
          this.deleteRow(product);
        }
      });
      return;
    }
    this.deleteRow(product);

  }

  deleteRow(product) {
    this.movementMgmService.deleteRow(product.get('id').value).subscribe(r => {
      this.showSnackBar({
        type: this.translate.instant('HEADER.BREADCRUMB.MOVEMENT'),
        actionIcon: 'delete',
        actionMsg: this.translate.instant('DIALOG.DELETE_SUCCESS')
      });
      this.productsForm.controls.splice(this.productsForm.controls.indexOf(product), 1);
      this.productsForm.value.splice(this.productsForm.value.indexOf(product), 1);
      this.productsForm.controls.forEach(p => {
        p.get('seq').setValue(this.productsForm.controls.indexOf(p) + 1);
      });
      this.calculateTotals();
      if (!this.productsForm.controls.length) {
        this.onClose.next(r);
        this.closeForm();
      }
    });
  }

  addProduct(editMode: boolean, product?) {
    const dialogRef = this.matDialog.open(MovementProductFormComponent, {
      width: '1200px',
      autoFocus: true,
      disableClose: true,
      data: {type: this.movementForm.get('type').value, editMode, product: product ? product.value : product}
    });
    dialogRef.afterClosed().subscribe((d: MovementProduct) => {
      if (d) {
        const index = this.productsForm.value.findIndex(u => u.productId === d.productId);
        if (!editMode) {
          if (index === -1) {
            d.seq = (this.productsForm.length + 1).toString();
            this.productsForm.insert(0, this.formBuilder.group(d));
            return;
          }
        }
        const control = this.productsForm.controls[index];
        control.get('quantity').setValue(d.quantity);
        control.get('gift').setValue(d.gift);
        control.get('purchaseCost').setValue(d.purchaseCost);
      }
    });
  }

  get quantityForm() {
    return this.movementForm.get('totalQuantity') as UntypedFormControl;
  }

  get ivaForm() {
    return this.movementForm.get('totalIva') as UntypedFormControl;
  }

  get taxableForm() {
    return this.movementForm.get('totalTaxable') as UntypedFormControl;
  }

  get ivatoForm() {
    return this.movementForm.get('totalIvato') as UntypedFormControl;
  }

  private productsFormValueChange() {
    this.productsForm.valueChanges.subscribe(cart => {
      this.calculateTotals();
    });
  }

  private calculateTotals() {
    const totalQuantity = this.productsForm.controls.reduce((acc, el) => acc + Number(el.value.quantity) + Number(el.value.gift), 0);
    this.quantityForm.setValue(totalQuantity);
    const totalTaxable = this.productsForm.controls.reduce((acc, el) => acc + (el.value.purchaseCost * (el.value.quantity)), 0);
    console.log(totalTaxable);
    this.taxableForm.setValue(totalTaxable);
  }

  translateTypes() {
    this.types = [
      {description: this.translate.instant('MOVEMENT_FORM.' + MovementType.TRANSFER), id: MovementType.TRANSFER},
    ];
  }

  handleFileInput(files: FileList, content) {
    this.fileToUpload = files.item(0);
    const data = new FormData();
    data.append('file', this.fileToUpload);
    this.disableSave = true;
    this.movementMgmService.uploadProductsFromExcel(data).subscribe(r => {
      this.disableSave = false;
      this.uploaded = r;
      if (r.rowException) {
        this.sweetAlertService.warning(this.translate.instant('MOVEMENT_FORM.INVALID_ROW') + ' : ' + r.rowException +
          ' ' + this.translate.instant('MOVEMENT_FORM.IMPORT_PREVIOUS_ROWS')).then(e => {
          if (e.value) {
            r.products.reverse().forEach(u => {
              u.seq = (this.productsForm.length + 1).toString();
              this.productsForm.insert(0, this.formBuilder.group(u));
            });
            if (r.notFounded.length) {
              this.matDialog.open(content, {width: '500px'});
            }
          }
        });
        this.inputFile.nativeElement.value = '';
        return;
      }
      if (r.notFounded.length) {
        this.matDialog.open(content, {width: '500px'});
      }
      r.products.reverse().forEach(u => {
        u.seq = (this.productsForm.length + 1).toString();
        this.productsForm.insert(0, this.formBuilder.group(u));
      });
      this.inputFile.nativeElement.value = '';
    }, error => {
      this.disableSave = false;
    });
  }

  downloadModel() {
    this.disableDownload = true;
    const lg = localStorage.getItem('language') || this.translate.currentLang || this.DEFAULT_LANG;
    this.movementMgmService.downloadExcelModel(lg.toUpperCase()).subscribe(r => {
      const downloadUrl = window.URL.createObjectURL(r);
      window.open(downloadUrl);
      this.disableDownload = false;
    }, error => this.disableDownload = false);
  }

  getPrintInterface(content) {
    this.dialogRef = this.matDialog.open(content, {
      autoFocus: false,
      maxHeight: '90vh'
    });
    this.dialogRef.afterClosed().subscribe(d => {
      if (d) {
        console.log(this.productsForm.controls);

        this.printTicketSent = true;
        const products = [];

        const promise = new Promise((resolve, reject) => {
          this.productsForm.controls.forEach((p, index) => {
            this.barcodeService.findAllByProductIdAndCenterId(p.get('productId').value).subscribe(r => {
              const barcodes = [];
              r.forEach(b => {
                barcodes.push(b.barcode);
              });
              products.push({
                description: p.get('description').value,
                price: p.get('purchaseCost').value,
                barCodes: barcodes
              });

              if (index === this.productsForm.controls.length - 1) {
                resolve(true);
              }

            });
          });
        });

        promise.then(r => {
          const request = {
            products,
            ticketQuantity: d,
            type: this.selectedType,
          };
          this.productService.printFacePlates(request).subscribe(data => {
            const downloadUrl = window.URL.createObjectURL(data.body);
            this.printTicketSent = false;
            window.open(downloadUrl);
          }, error => {
            this.printTicketSent = false;
            this.sweetAlertService.notification(this.translate.instant('PAYMENT.PRINT_FAILED'));
          });
          this.ticketNumber = 1;
        });

      }
    });
  }

  translatePrintTypes() {
    this.printTypes = [
      {description: this.translate.instant('PRODUCT_FORM.' + ProductBarcodeType.A4_62_32), id: ProductBarcodeType.A4_62_32},
      {
        description: this.translate.instant('PRODUCT_FORM.' + ProductBarcodeType.CONTINUOUS_63_37),
        id: ProductBarcodeType.CONTINUOUS_63_37
      }
    ];
  }

  searchProduct(addProductContent) {
    this.newCode = this.movementForm.get('searchedProduct').value;
    this.searchedProduct = this.movementForm.get('searchedProduct').value;
    if (!this.searchedProduct.id) {
      if (this.newCode && this.newCode.trim().length) {
        console.log(this.newCode);
        this.scanEAN(this.newCode, addProductContent);
      }
      return;
    }
    const index = this.productsForm.value.findIndex(u => u.productId === this.searchedProduct.id);
    if (index !== -1) {
      this.sweetAlertService.warning(this.translate.instant('PRODUCT_FORM.PROD_ALREADY_EXIST')).then(u => {
        if (!u.value) {
          this.resetSearch();
          return;
        }
        this.focusInInput('productQtt');
      });
    }
    this.focusInInput('productQtt');
  }

  confirmProductMvm() {
    if (!this.searchedProduct || !this.searchedProduct.id) {
      this.focusInInput('searchedProduct');
      return;
    }
    if (!this.movement || !this.movement.id) {
      this.saveMovementHeader();
    } else {
      this.addProductToList();
    }
  }

  confirmProductCarico() {
    if (!this.searchedProduct || !this.searchedProduct.id) {
      this.focusInInput('searchedProduct');
      return;
    }

    if (!this.movement || !this.movement.id) {
      this.saveMovementHeader();
    } else {
      this.addProductToList();
    }
  }

  addProductToList() {
    this.searchedProduct.seq = (this.productsForm.length + 1).toString();
    const productForm = {
      id: null,
      productId: this.searchedProduct.id,
      seq: this.productsForm.length + 1,
      // tslint:disable-next-line:max-line-length
      prodCode: this.searchedProduct.prodCode ? this.searchedProduct.prodCode : (this.searchedProduct.barCode ? this.searchedProduct.barCode : ''),
      description: this.searchedProduct.description,
      iva: this.searchedProduct.ivaValue,
      quantity: this.productQtt,
      gift: this.productGiftQtt,
      purchaseCost: this.searchedProduct.purchasePrice,
      costPrice: this.searchedProduct.purchasePrice,
      price: this.searchedProduct.price,
      measureUnit: this.searchedProduct.measureUnit,
      stock: this.searchedProduct.stock,
      movementId: this.movement ? this.movement.id : null,
      activeEdit: false,

    };
    // document.getElementById('prod-table').scrollTop = document.getElementById('prod-table').scrollHeight;
    this.resetSearch();
    this.saveMovementRow(productForm);
    // this.productsForm.insert(this.productsForm.length, this.formBuilder.group(productForm));
    // this.prodTable.nativeElement.scrollTop = this.prodTable.nativeElement.scrollHeight;
  }

  searchProd(term) {
    if (term === '' || term.length < 2) {
      this.productList = [];
      return of([]);
    }
    return this.productService.searchSpAndByCode(term, this.movementForm.get('sellPointId').value).pipe(
      map((response: Product[]) => {
        this.productList = response;
        return response;
      })
    );
  }

  search = (text$: Observable<Product>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(term =>
        this.searchProd(term).pipe(
          catchError(() => {
            return of([]);
          }))
      )
    );
  formatter = (result: Product) => (result.prodCode ? result.prodCode : (result.barCode ? result.barCode : result.description));


  focusInInput(inputName) {
    console.log(inputName);
    const inp = document.getElementsByName(inputName);
    if (inp && inp.length) {
      inp[0].focus();
    }
  }

  resetSearch() {
    this.movementForm.get('searchedProduct').setValue('');
    this.searchedProduct = new Product();
    this.productQtt = 1;
    this.productGiftQtt = 0;
    this.productCode = '';
    this.focusInInput('searchedProduct');
  }

  @HostListener('document:keydown.escape')
  onEscapeBtnClick() {
    if (this.searchedProduct && this.searchedProduct.id) {
      this.resetSearch();
    }
  }

  @HostListener('document:keydown.tab')
  onTabBtnClick($event) {
    // @ts-ignore
    if (event.srcElement.name) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  openProductModal() {
    const dialogRef = this.matDialog.open(ProductManagementFormComponent, {
      width: '1200px',
      autoFocus: true,
      disableClose: true,
      data: {editMode: false}
    });
  }

  openSearchProduct(content, product) {
    this.productToReplace = product;
    this.resetSearchForm();
    const dialogRef = this.matDialog.open(content, {
      width: '1200px',
      autoFocus: true,
      disableClose: true,
      height: '85vh'
    });
  }

  resetSearchForm() {
    this.searchFormControl = new UntypedFormGroup({
      textSearch: new UntypedFormControl(null),
      providerId: new UntypedFormControl(null),
      brandId: new UntypedFormControl(null),
    });
    this.filter();
  }

  filter() {
    this.page = 1;
    this.filterProducts();
  }

  filterProducts() {
    const request = {
      page: this.page,
      pageSize: 10,
      sellPointId: this.movementForm.get('sellPointId').value,
      providerId: this.searchFormControl.get('providerId').value,
      brandId: this.searchFormControl.get('brandId').value,
      textSearch: this.searchFormControl.get('textSearch').value
    };
    this.loading = true;
    this.productService.getLazyProductList(request).subscribe(r => {
      this.productSearchList = r.data;
      this.loading = false;
    });


  }

  pageChange(event: number) {
    this.page = event;
  }

  editSelectedProduct(product, content) {
    console.log(product);
    console.log(product.value);
    this.editSelectedProd = false;
    this.editProductMode = true;
    this.editProductClicked = false;
    this.productService.getProduct(product.value.productId).subscribe(r => {
      if (r) {
        this.editSelectedProd = true;
        this.editProduct(r, content);
      }
    });
  }

  editProduct(product, content) {
    this.product = product;
    this.editProductMode = true;
    this.dialogRef = this.matDialog.open(content, {
      width: '1300px',
      autoFocus: true,
      disableClose: true,
      height: '90vh'
    });
    this.editEventsSubject.next(void 0);
    this.editProductClicked = false;

  }

  addNewProduct(content, withCode?) {
    this.editProductMode = false;
    this.newCode = withCode;
    this.dialogRef = this.matDialog.open(content, {
      width: '1300px',
      autoFocus: true,
      disableClose: true,
      height: '90vh'
    });
  }

  savedEvent(product: Product, addProductContent?) {
    this.disableSave = false;
    if (this.editProductMode) {
      if (this.editSelectedProd) {
        this.updateSelectedProducts(product);
      } else {
        this.filterProducts();
      }
      this.dialogRef.close();
    } else {
      this.productCode = product.prodCode;
      this.movementForm.get('searchedProduct').setValue(product);
      if (product) {
        this.matDialog.closeAll();
        this.searchProduct(addProductContent);
        setTimeout(() => {
          this.searchedProduct = product;
          this.focusInInput('productQtt');
        }, 650);
      }

    }

  }

  saveNewProduct() {
    this.saveEventsSubject.next(void 0);
  }

  activateProductEdit() {
    if (this.editProductMode) {
      this.editProductClicked = true;
      this.editEventsSubject.next(void 0);
    }
  }

  selectProduct(product: Product, addProductContent) {
    this.matDialog.closeAll();
    if (this.productToReplace) {
      const control = this.productsForm.controls.filter(u => u.value.id === this.productToReplace.value.id).shift();
      if (control) {
        control.get('productId').setValue(product.id);
        control.get('prodCode').setValue(product.prodCode ? product.prodCode : (product.barCode ? product.barCode : ''));
        control.get('description').setValue(product.description);
        control.get('iva').setValue(product.ivaValue);
        control.get('purchaseCost').setValue(product.purchasePrice);
        control.get('costPrice').setValue(product.purchasePrice);
        control.get('price').setValue(product.price);
        control.get('measureUnit').setValue(product.measureUnit);
        control.get('stock').setValue(product.stock);
        control.get('movementId').setValue(this.movement ? this.movement.id : null);
        this.editRow(control);
        return;
      }
    }
    this.productCode = product.prodCode;
    this.movementForm.get('searchedProduct').setValue(product);
    this.searchProduct(addProductContent);
    setTimeout(() => {
      this.searchedProduct = product;
      this.focusInInput('productQtt');
    }, 650);
  }

  capSearch(term: string, item: ZipCode) {
    term = term.toLowerCase();
    return item.city.toLowerCase().startsWith(term) || item.cap.toLowerCase().startsWith(term);
  }

  checkSelectedProduct() {
    console.log('*************');
    if (!this.searchedProduct.id) {
      console.log('/*/*/*/*/*');
      this.focusInInput('searchedProduct');
    }

  }

  initialiseListener() {
    const handler = createPrefixedInputHandler()((c) => {
      console.log(c);
      if ((c.length > 11 || c.length === 8)) {
        this.scanEAN(c);
      }
    });
    this.listener = (event: Event): void => handler(event as KeyboardEvent);
  }

  scanEAN(ean, content?) {
    console.log(ean);
    if (ean === null || (ean.toString()).trim() === '') {
      return;
    }
    this.productService.findByBarCodeAndSp(ean, this.movementForm.get('sellPointId').value).subscribe(r => {
      if (r) {
        this.searchedProduct = r;
        return;
      } else {
        this.barcodeService.findProductIdByBarCodeAndCenterId(ean).subscribe(id => {
          if (id) {
            this.productService.getProductBySp(id, this.movementForm.get('sellPointId').value).subscribe(p => {
              if (p) {
                this.searchedProduct = p;
                this.focusInInput('productQtt');
                return;
              }
              if (content) {
                this.openCreateNewProdAlert(content);
              }
            });
            return;
          }
          if (content) {
            this.openCreateNewProdAlert(content);
          }
        }, error => {
          this.sweetAlertService.danger(this.translate.instant('DIALOG.INTERNAL_SERVER_ERROR'));
        });
      }

    });
  }

  openCreateNewProdAlert(addProductContent) {
    this.sweetAlertService.warning(this.translate.instant('PRODUCT_FORM.PRODUCT_NOT_FOUND_ADD_IT')).then(u => {
      if (u.value) {
        this.addNewProduct(addProductContent, this.newCode.trim());
        return;
      } else {
        this.productCode = '';
        this.movementForm.get('searchedProduct').setValue('');
        this.focusInInput('productQtt');
      }
    });
  }

  updateSelectedProducts(product: Product) {
    this.productsForm.controls.forEach(c => {
      if (c.get('productId').value == product.id) {
        c.get('prodCode').setValue(product.prodCode ? product.prodCode : (product.barCode ? product.barCode : ''));
        c.get('description').setValue(product.description);
        c.get('iva').setValue(product.ivaValue);
      }
    });
  }

  editRow(product, fromTab?) {
    if (this.movement && this.movement.draft) {
      this.movementMgmService.saveRow(product.value).subscribe(r => {
        this.showSnackBar({
          type: this.translate.instant('HEADER.BREADCRUMB.MOVEMENT'),
          actionIcon: 'save',
          actionMsg: this.translate.instant('DIALOG.UPDATE_SUCCESS')
        });
        if (!fromTab) {
          this.prodTable.nativeElement.scrollTop = this.prodTable.nativeElement.scrollHeight;
          this.focusInInput('searchedProduct');
        }
      });
      return;
    }
    this.movementMgmService.editRow(product.value).subscribe(r => {
      if (!fromTab) {
        this.prodTable.nativeElement.scrollTop = this.prodTable.nativeElement.scrollHeight;
        this.focusInInput('searchedProduct');
      }
      this.showSnackBar({
        type: this.translate.instant('HEADER.BREADCRUMB.MOVEMENT'),
        actionIcon: 'save',
        actionMsg: this.translate.instant('DIALOG.UPDATE_SUCCESS')
      });
    });
  }

  showSnackBar(data: any) {
    this.snackBar.openFromComponent(CustomSnackBarComponent, {
      data,
      duration: 5000,
      panelClass: 'white-snackbar'
    });
  }

  getProviders() {
    this.providerService.getLazyProviderList({page: this.providersPage, pageSize: MAX_NUMBER, visible: true}).subscribe(d => {
      this.providers = [...this.providers, ...d.data];
      this.providersPage++;
    });
  }

  getBrands() {
    this.configurationsService.getLazyBrands({page: this.brandPage, pageSize: MAX_NUMBER}).subscribe(d => {
      this.brands = [...this.brands, ...d.data];
      this.brandPage++;
    });
  }
}

import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Client} from '../../../../../shared/models/client';
import {AnamnesiService} from '../../../../../shared/services/anamnesi.service';
import {AbstractControl, Form, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {
  Anamnesi,
  backBodyZones, backBodyZonesArray,
  faceZonesArray,
  frontBodyZones,
  frontBodyZonesArray,
  QuestionType
} from '../../../../../shared/models/anamnesi/anamnesi.model';
import {  MatSnackBar} from '@angular/material/snack-bar';
import {HttpErrorResponse} from '@angular/common/http';
import {TreatmentMgmService} from '../../../../../shared/services/treatment-mgm.service';
import {Treatment} from '../../../../../shared/models/treatment';
import {  MatCheckboxChange} from '@angular/material/checkbox';
import moment from 'moment';
import * as UUID from 'uuid/v1';
import {Product} from '../../../../../shared/models/product';
import {ProductMgmService} from '../../../../../shared/services/product-mgm.service';
import {IMC} from './consts/imc-values';
import { MatLegacyTabGroup as MatTabGroup} from '@angular/material/legacy-tabs';
import * as Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';
import * as FileSaver from 'file-saver';
import {ClientFormService} from "../services/client-form.service";
@Component({
  selector: 'app-anamnesi',
  templateUrl: './anamnesi.component.html',
  styleUrls: ['./anamnesi.component.scss']
})
export class AnamnesiComponent implements OnInit {
  @ViewChild('matTabGroup', { static: false }) matTabGroup: MatTabGroup;
  @Input() client: Client;
  anamnesiForm: UntypedFormGroup;
  anamnesi: Anamnesi;
  faceZones = faceZonesArray;
  bodyZones = [...frontBodyZonesArray, ...backBodyZonesArray];
  frontBodyZones = frontBodyZones;
  backBodyZones = backBodyZones;
  treatments: Treatment[];
  treatmentsPerPage = 20;
  treatmentPage = 1;
  MAX_INT = Math.pow(2, 31) - 1;
  faceTreatmentRowForm: UntypedFormGroup;
  bodyTreatmentRowForm: UntypedFormGroup;
  showRecommendedTreatments = true;
  showEsameObiettivo = true;
  showTipoDiPelle = true;
  showCommentaryOnTreatments = true;
  showTreatmentEvaluation = true;
  showRecommendedProducts = true;
  faceProductRowForm: UntypedFormGroup;
  bodyProductRowForm: UntypedFormGroup;
  products: Product[];
  imcValues = IMC;
  clientIMC;
  bodyMeasurementsArr = Array(10).fill(0);
  disableHistoryMode = false;
  anamnesiList: any[] = [];
  previousAnamnesi: Anamnesi;
  loadingForm = false;
  loadingList = false;
  loadingSave = false;
  loadingQuoteAction = false;
  quoteForm: UntypedFormGroup;
  @Output() updatedNotSaved = new EventEmitter<boolean>();
  constructor(
    private anamnesiService: AnamnesiService,
    private fb: UntypedFormBuilder,
    private matSnackBar: MatSnackBar,
    private treatmentService: TreatmentMgmService,
    private productService: ProductMgmService,
    private translateService: TranslateService,
    private clientFormService: ClientFormService) {
  }

  ngOnInit() {
    // this.clientFormService.subject.next(false);
    this.anamnesiForm = this.fb.group({
      id: null,
      clientId: null,
      anamnesiGenerale: this.fb.array([]),
      esameObiettivo: this.fb.array([]),
      tipoDiPelle: this.fb.group({
        id: null,
        optionIds: null,
        note: null,
        questionConfigId: null
      }),
      tipoDiPelleCheckbox: this.fb.array([]), // checkbox formArray to choose for tipoDipelle above 👆👆
      viso: this.fb.array([]),
      faceTreatments: this.fb.group({
        id: null,
        recommendedTreatments: this.fb.array([])
      }),
      faceTreatmentsEvaluations: this.fb.group({
        id: null,
        firstEvaluation: null,
        secondEvaluation: null,
        thirdEvaluation: null
      }),
      faceProducts: this.fb.group({
        id: null,
        recommendedProducts: this.fb.array([])
      }),
      faceNote: null,
      height: null,
      weight: null,
      corpo: this.fb.array([]),
      bodyMeasurements: this.buildBodyMeasurementsFormGroup(),
      bodyTreatments: this.fb.group({
        id: null,
        recommendedTreatments: this.fb.array([])
      }),
      bodyTreatmentsEvaluations: this.fb.group({
        id: null,
        firstEvaluation: null,
        secondEvaluation: null,
        thirdEvaluation: null
      }),
      bodyProducts: this.fb.group({
        id: null,
        recommendedProducts: this.fb.array([])
      }),
      bodyNote: null
    });
    this.treatmentService.getLazyTreatmentList({page: this.treatmentPage, pageSize: this.MAX_INT}).subscribe(d => {
      this.treatments = d.data;
    });
    this.productService.getLazyProductList({page: this.treatmentPage, pageSize: this.MAX_INT}).subscribe(d => {
      this.products = d.data;
    });
    this.faceTreatmentRowForm = this.fb.group({
      quantity: [null, Validators.compose([Validators.required, Validators.min(1), Validators.pattern(/^[0-9]+$/)])],
      bodyZone: [null, Validators.required],
      treatmentId: [null, Validators.required],
      treatmentName: null,
    });
    this.bodyTreatmentRowForm = this.fb.group({
      quantity: [null, Validators.compose([Validators.required, Validators.min(1), Validators.pattern(/^[0-9]+$/)])],
      bodyZone: [null, Validators.required],
      treatmentId: [null, Validators.required],
      treatmentName: null,
    });
    this.faceProductRowForm = this.fb.group({
      quantity: [null, Validators.required],
      bodyZone: [null, Validators.required],
      productId: [null, Validators.required],
      productName: null,
    });
    this.bodyProductRowForm = this.fb.group({
      quantity: [null, Validators.required],
      bodyZone: [null, Validators.required],
      productId: [null, Validators.required],
      productName: null,
    });
    this.quoteForm = this.fb.group({
      clientId: this.client.id,
      anamnesiId: this.anamnesi ? this.anamnesi.id : null,
      faceTreatments: this.fb.array([]),
      faceProducts: this.fb.array([]),
      bodyTreatments: this.fb.array([]),
      bodyProducts: this.fb.array([]),
    });
    this.loadingList = true;
    this.anamnesiService.getAnamnesiList(this.client.id).subscribe(d => {
      this.anamnesiList = d;
      this.loadingList = false;
    });
    // this.loadAnamnesiConfig();
    this.anamnesiForm.valueChanges.subscribe(d => {
      if(this.anamnesiForm.dirty){
        this.updatedNotSaved.emit(true);
      }
    });
  }

  get anamnesiGeneraleForm() {
    return this.anamnesiForm.get('anamnesiGenerale') as UntypedFormArray;
  }

  get esameObiettivoForm() {
    return this.anamnesiForm.get('esameObiettivo') as UntypedFormArray;
  }

  get recommendedFaceTreatmentForm() {
    return this.anamnesiForm.get('faceTreatments').get('recommendedTreatments') as UntypedFormArray;
  }

  get recommendedFaceProductForm() {
    return this.anamnesiForm.get('faceProducts').get('recommendedProducts') as UntypedFormArray;
  }

  get recommendedBodyTreatmentForm() {
    return this.anamnesiForm.get('bodyTreatments').get('recommendedTreatments') as UntypedFormArray;
  }

  get recommendedBodyProductForm() {
    return this.anamnesiForm.get('bodyProducts').get('recommendedProducts') as UntypedFormArray;
  }

  buildBodyMeasurementsFormGroup() {
    return this.fb.group({
      id: null,
      measurementsPerHour1: null,
      leftMeasurement1: null,
      rightMeasurement1: null,
      measurementsPerHour2: null,
      leftMeasurement2: null,
      rightMeasurement2: null,
      measurementsPerHour3: null,
      leftMeasurement3: null,
      rightMeasurement3: null,
      measurementsPerHour4: null,
      leftMeasurement4: null,
      rightMeasurement4: null,
      measurementsPerHour5: null,
      leftMeasurement5: null,
      rightMeasurement5: null,
      measurementsPerHour6: null,
      leftMeasurement6: null,
      rightMeasurement6: null,
      measurementsPerHour7: null,
      leftMeasurement7: null,
      rightMeasurement7: null,
      measurementsPerHour8: null,
      leftMeasurement8: null,
      rightMeasurement8: null,
      measurementsPerHour9: null,
      leftMeasurement9: null,
      rightMeasurement9: null,
      measurementsPerHour10: null,
      leftMeasurement10: null,
      rightMeasurement10: null,
      leftSeno: null,
      rightSeno: null,
      distanzaSeni: null
    });
  }

  loadAnamnesiConfig(editMode?: boolean, anamnesiId?: number) {
    if (!editMode) {
      this.disableHistoryMode = true;
      this.matTabGroup.selectedIndex = 1;
      this.loadingForm = true;
      this.anamnesiService.loadAnamnesiForm().subscribe(d => {
        this.setUpAnamnesiForm(d);
        this.anamnesiForm.disable();
        this.anamnesiService.getPreviousAnamnesi(this.client.id).subscribe(u => {
          this.previousAnamnesi = u;
          if (u) {
            Swal.default.fire({
              icon: 'question',
              title: 'Desideri recuperare le informazioni dell\'ultima anamnesi effettuata?',
              showCancelButton: true,
              cancelButtonText: this.translateService.instant('BUTTONS.CANCEL'),
            }).then(e => {
              if (e.value) {
                this.setUpAnamnesiForm(u);
              }
            });
          }
          this.anamnesiForm.enable();
          this.loadingForm = false;
        });
      });
      return;
    }
    this.disableHistoryMode = true;
    this.matTabGroup.selectedIndex = 1;
    this.loadingForm = true;
    this.anamnesiService.getClientConfig(this.client.id, anamnesiId).subscribe(d => {
      this.setUpAnamnesiForm(d);
      this.anamnesiService.getPreviousAnamnesi(this.client.id, anamnesiId).subscribe(u => {
        this.previousAnamnesi = u;
        this.loadingForm = false;
      });
    });
  }


  // setup anamnesi generale and esame obiettivo questions form
  // key is the object key of the questions
  setUpQuestions(anamnesi: Anamnesi, key) {
    anamnesi[key].forEach((e, i) => {
      if (this.simpleOrComplexResponse(e.type) === 'simple') {
        return this[`${key}Form`].setControl(i, this.fb.group({
          id: e.questionAnswerId,
          value: e.required ? [e.value, Validators.required] : e.value,
          note: e.note,
          questionConfigId: e.id
        }));
      }
      if (e.type === 'CHECKBOX') {
        const control = this.fb.group({
          id: e.questionAnswerId,
          optionIds: e.required ?
            [e.optionAnswers ? e.optionAnswers.map(u => u.optionId).filter(u => u !== null) : null, Validators.required]
            : [e.optionAnswers ? e.optionAnswers.map(u => u.optionId).filter(u => u !== null) : null],
          note: e.note,
          questionConfigId: e.id,
          optionIdsCheckbox: this.fb.array([])
        });
        e.questionOptions.forEach((u, j) => {
          (control.get('optionIdsCheckbox') as UntypedFormArray)
            .setControl(j, new UntypedFormControl(e.optionAnswers && e.optionAnswers.findIndex(v => u.id === v.optionId) > -1));
        });
        this[`${key}Form`].setControl(i, control);
        return;
      }

      return this[`${key}Form`].setControl(i, this.fb.group({
        id: e.questionAnswerId,
        optionId: e.required ?
          [e.optionAnswers ? e.optionAnswers[0].optionId : null, Validators.required]
          : e.optionAnswers ? e.optionAnswers[0].optionId : null,
        note: e.note,
        questionConfigId: e.id
      }));
    });
  }

  save() {
    const request = {...this.anamnesiForm.value};
    this.anamnesiForm.disable();
    request.faceTreatments.recommendedTreatments = request.faceTreatments.recommendedTreatments.map(u => {
      u.date = u.minutes ? moment(u.date).format('YYYY-MM-DD') : null;
      return u;
    });
    request.bodyTreatments.recommendedTreatments = request.bodyTreatments.recommendedTreatments.map(u => {
      u.date = u.minutes ? moment(u.date).format('YYYY-MM-DD') : null;
      return u;
    });
    this.loadingSave = true;
    this.anamnesiService.saveAnamnesi(request).subscribe(d => {
      this.matSnackBar.open('Anamnesi Salvato con successo', 'Ok', {
        duration: 5000,
        panelClass: 'white-snackbar'
      });
      this.matTabGroup.selectedIndex = 0;
      this.disableHistoryMode = false;
      this.loadingList = true;
      this.anamnesiForm.enable();
      this.anamnesiForm.reset();
      this.anamnesiService.getAnamnesiList(this.client.id).subscribe(data => {
        this.anamnesiList = data;
        this.loadingSave = false;
        this.loadingList = false;
      });
      // this.loadAnamnesiConfig(true);
    }, (err: HttpErrorResponse) => {
      if (err.error.message.includes('REQUIRED')) {
        this.matSnackBar.open(err.error.message, 'Ok', {
          duration: 5000,
          panelClass: 'white-snackbar'
        });
      }
    });
  }

  addRecommendedTreatment(bodySection: 'face' | 'body') {
    const uuid = UUID();
    const formValue = this[`${bodySection}TreatmentRowForm`].value;
    for (let i = 0; i < formValue.quantity; i++) {
      const control = this.fb.group({
        uuid,
        bodyZone: formValue.bodyZone,
        treatmentId: formValue.treatmentId,
        treatmentName: formValue.treatmentName,
        performed: false,
        date: [{value: null, disabled: true}, Validators.required],
        minutes: [{value: null, disabled: true}, Validators.required],
        note: null
      });
      this[`recommended${bodySection[0].toUpperCase() + bodySection.slice(1)}TreatmentForm`].push(control);
    }
    this[`${bodySection}TreatmentRowForm`].reset();
  }

  getSelectedValuesFromCheckbox($event, formControlName) {
    this.anamnesiForm.get(formControlName).get('optionIds').setValue(
      this.anamnesiForm.get(`${formControlName}Checkbox`).value.map((u, i) => {
        if (u) {
          return this.anamnesi[formControlName].questionOptions[i].id;
        }
      }).filter(u => u !== undefined)
    );
  }

  getSelectedValuesFromQuestionCheckbox(formControl: AbstractControl, index, section, $event) {
    formControl.get('optionIds').setValue(
      formControl.get('optionIdsCheckbox').value.map((u, i) => {
        if (u) {
          return this.anamnesi[section][index].questionOptions[i].id;
        }
        return null;
      }).filter(u => u !== null)
    );
  }


  private simpleOrComplexResponse(type: QuestionType): 'simple' | 'complex' {
    if (type === 'DATE'
      || type === 'DATE_TIME'
      || type === 'INPUT_NUMBER'
      || type === 'INPUT_TEXT'
      || type === 'TIME') {
      return 'simple';
    }
    return 'complex';
  }

  treatmentChange($event, zone: 'face' | 'body') {
    this[`${zone}TreatmentRowForm`].get('treatmentName').setValue($event.description);
  }

  performedChange($event: MatCheckboxChange, i: number, zone: 'Face' | 'Body') {
    if ($event.checked) {
      this[`recommended${zone}TreatmentForm`].at(i).get('date').setValue(moment().format('YYYY-MM-DD'));
      this[`recommended${zone}TreatmentForm`].at(i).get('date').enable();
      this[`recommended${zone}TreatmentForm`].at(i).get('minutes').enable();
    } else {
      this[`recommended${zone}TreatmentForm`].at(i).get('date').disable();
      this[`recommended${zone}TreatmentForm`].at(i).get('minutes').disable();
    }
  }

  removeAllRowsByUuid(uuid: any, zone: 'Body' | 'Face') {
    while (this[`recommended${zone}TreatmentForm`].value.findIndex(u => u.uuid === uuid) > -1) {
      this[`recommended${zone}TreatmentForm`].removeAt(this[`recommended${zone}TreatmentForm`].value.findIndex(u => u.uuid === uuid));
    }
  }

  addRecommendedProduct(zone: 'face' | 'body') {
    const control = this.fb.group({
      quantity: null,
      bodyZone: null,
      productId: null,
      productName: null,
    });
    control.setValue(this[`${zone}ProductRowForm`].value);
    this[`recommended${zone.charAt(0).toUpperCase() + zone.slice(1)}ProductForm`].push(control);
    this[`${zone}ProductRowForm`].reset();
  }

  productChange($event, zone: 'face' | 'body') {
    this[`${zone}ProductRowForm`].get('productName').setValue($event.description);
  }

  calculateIMC() {
    this.clientIMC = this.anamnesiForm.get('weight').value / (Math.pow(this.anamnesiForm.get('height').value * Math.pow(10, -2), 2));
  }

  private setUpAnamnesiForm(anamnesi) {
    this.anamnesiForm.reset();
    this.anamnesi = anamnesi;
    this.quoteForm.get('anamnesiId').setValue(anamnesi.id);
    this.anamnesiForm.get('id').setValue(anamnesi.id);
    this.anamnesiForm.get('clientId').setValue(this.client.id);
    this.setUpQuestions(anamnesi, 'anamnesiGenerale');
    this.setUpQuestions(anamnesi, 'esameObiettivo');
    // VISO controls
    // tipo di pelle checkboxes
    this.anamnesiForm.get('faceNote').setValue(anamnesi.faceNote);
    this.anamnesiForm.get('tipoDiPelle').setValue({
      id: anamnesi.tipoDiPelle.questionAnswerId,
      optionIds: anamnesi.tipoDiPelle.optionAnswers ? anamnesi.tipoDiPelle.optionAnswers.map(u => u.optionId) : null,
      note: null,
      questionConfigId: anamnesi.tipoDiPelle.id
    });
    anamnesi.tipoDiPelle.questionOptions.forEach((u, i) => {
      if (anamnesi.tipoDiPelle.optionAnswers && anamnesi.tipoDiPelle.optionAnswers.findIndex(v => u.id === v.optionId) > -1) {
        return (this.anamnesiForm.get('tipoDiPelleCheckbox') as UntypedFormArray).setControl(i, new UntypedFormControl(true));
      }
      (this.anamnesiForm.get('tipoDiPelleCheckbox') as UntypedFormArray).setControl(i, new UntypedFormControl(false));
    });
    // tipo di pelle
    // viso zones
    if (anamnesi.viso) {
      anamnesi.viso.forEach((u, i) => {
        const visoFormArray = this.anamnesiForm.get('viso') as UntypedFormArray;
        visoFormArray.setControl(i, this.fb.group({
          id: u.id,
          bodyZone: u.bodyZone,
          tipoDiPelleId: u.tipoDiPelleId,
          inestetismoPresenteIds: [u.inestetismoPresenteIds],
          note: u.note
        }));
      });
    }
    if (anamnesi.faceTreatments) {
      this.anamnesiForm.get('faceTreatments').get('id').setValue(anamnesi.faceTreatments.id);
      anamnesi.faceTreatments.recommendedTreatments.forEach((u, i) => {
        const control = this.fb.group({
          uuid: u.uuid,
          quantity: u.quantity,
          bodyZone: u.bodyZone,
          treatmentId: u.treatmentId,
          treatmentName: u.treatmentName,
          performed: u.performed,
          date: [{value: u.date, disabled: !u.performed}, Validators.required],
          minutes: [{value: u.minutes, disabled: !u.performed}, Validators.required],
          note: u.note,
        });
        this.recommendedFaceTreatmentForm.setControl(i, control);
      });
    }
    if (anamnesi.faceTreatmentsEvaluations) {
      this.anamnesiForm.get('faceTreatmentsEvaluations').setValue(anamnesi.faceTreatmentsEvaluations);
    }
    if (anamnesi.faceProducts) {
      this.anamnesiForm.get('faceProducts').get('id').setValue(anamnesi.faceProducts.id);
      anamnesi.faceProducts.recommendedProducts.forEach((u, i) => {
        const control = this.fb.group({
          quantity: u.quantity,
          bodyZone: u.bodyZone,
          productId: u.productId,
          productName: u.productName,
          note: u.note,
        });
        this.recommendedFaceProductForm.setControl(i, control);
      });
    }
    if (anamnesi.corpo) {
      anamnesi.corpo.forEach((u, i) => {
        const corpoFormArray = this.anamnesiForm.get('corpo') as UntypedFormArray;
        corpoFormArray.setControl(i, this.fb.group({
          id: u.id,
          bodyZone: u.bodyZone,
          inestetismoPresenteIds: [u.inestetismoPresenteIds],
          note: u.note
        }));
      });
    }
    if (anamnesi.bodyMeasurements) {
      this.anamnesiForm.get('bodyMeasurements').patchValue(anamnesi.bodyMeasurements);
    }
    this.anamnesiForm.get('height').setValue(anamnesi.height);
    this.anamnesiForm.get('weight').setValue(anamnesi.weight);
    this.calculateIMC();
    if (anamnesi.bodyTreatments) {
      this.anamnesiForm.get('bodyTreatments').get('id').setValue(anamnesi.bodyTreatments.id);
      anamnesi.bodyTreatments.recommendedTreatments.forEach((u, i) => {
        const control = this.fb.group({
          uuid: u.uuid,
          quantity: u.quantity,
          bodyZone: u.bodyZone,
          treatmentId: u.treatmentId,
          treatmentName: u.treatmentName,
          performed: u.performed,
          date: [{value: u.date, disabled: !u.performed}, Validators.required],
          minutes: [{value: u.minutes, disabled: !u.performed}, Validators.required],
          note: u.note,
        });
        this.recommendedBodyTreatmentForm.setControl(i, control);
      });
    }
    if (anamnesi.bodyTreatmentsEvaluations) {
      this.anamnesiForm.get('bodyTreatmentsEvaluations').setValue(anamnesi.bodyTreatmentsEvaluations);
    }
    if (anamnesi.bodyProducts) {
      this.anamnesiForm.get('bodyProducts').get('id').setValue(anamnesi.bodyProducts.id);
      anamnesi.bodyProducts.recommendedProducts.forEach((u, i) => {
        const control = this.fb.group({
          quantity: u.quantity,
          bodyZone: u.bodyZone,
          productId: u.productId,
          productName: u.productName,
          note: u.note,
        });
        this.recommendedBodyProductForm.setControl(i, control);
      });
    }
  }

  deleteAnamnesi(id: number) {
    Swal.default.fire({
      icon: 'warning',
      titleText: 'Elimina anamnesi',
      text: 'Stai per eliminare questo anamnesi per sempre, sei sicuro?',
      showCancelButton: true,
      cancelButtonText: this.translateService.instant('BUTTONS.CANCEL')
    }).then(e => {
      if (e.value) {
        this.anamnesiService.delete(id).subscribe(() => {
          this.loadingList = true;
          this.anamnesiService.getAnamnesiList(this.client.id).subscribe(data => {
            this.anamnesiList = data;
            this.loadingList = false;
          });
        });
      }
    });
  }

  whenOnDevisTab(tabIndex: number) {
    if (tabIndex === 4) {
      this.clearFormArray(this.quoteForm.get('faceTreatments') as UntypedFormArray);
      this.clearFormArray(this.quoteForm.get('bodyTreatments') as UntypedFormArray);
      this.clearFormArray(this.quoteForm.get('faceProducts') as UntypedFormArray);
      this.clearFormArray(this.quoteForm.get('bodyProducts') as UntypedFormArray);
      console.log(this.quoteForm.value);
      const groupedFaceTreatments = this.groupByUuid(this.recommendedFaceTreatmentForm.value);
      groupedFaceTreatments.forEach(u => {
        const treatment = this.treatments.find(v => v.id === u.treatmentId);
        const crl = this.fb.group({
          id: treatment.id,
          name: treatment.description,
          comment: null,
          unitPrice: treatment.pdvPrice || treatment.masterPrice,
          quantity: u.quantity,
          discount: 0,
          exclude: false,
          total: (treatment.pdvPrice || treatment.masterPrice) * u.quantity
        });
        (this.quoteForm.get(`faceTreatments`) as UntypedFormArray).push(crl);
        crl.valueChanges.subscribe(v => {
          const unitPrice = v.unitPrice -
            (v.discount / 100) * v.unitPrice;
          crl.get('total').setValue(
            unitPrice * v.quantity, {emitEvent: false}
          );
        });
      });

      const groupedBodyTreatments = this.groupByUuid(this.recommendedBodyTreatmentForm.value);
      groupedBodyTreatments.forEach(u => {
        const treatment = this.treatments.find(v => v.id === u.treatmentId);
        const crl = this.fb.group({
          id: treatment.id,
          name: treatment.description,
          comment: null,
          unitPrice: treatment.pdvPrice || treatment.masterPrice,
          quantity: u.quantity,
          discount: 0,
          exclude: false,
          total: (treatment.pdvPrice || treatment.masterPrice) * u.quantity
        });
        (this.quoteForm.get(`bodyTreatments`) as UntypedFormArray).push(crl);
        crl.valueChanges.subscribe(v => {
          const unitPrice = v.unitPrice -
            (v.discount / 100) * v.unitPrice;
          crl.get('total').setValue(
            unitPrice * v.quantity, {emitEvent: false}
          );
        });
      });

      this.recommendedFaceProductForm.value.forEach(u => {
        const product = this.products.find(v => v.id === u.productId);
        const crl = this.fb.group({
          id: product.id,
          name: product.description,
          barcode: product.barCode,
          comment: null,
          unitPrice: product.pricePDV || product.price,
          quantity: u.quantity,
          discount: 0,
          exclude: false,
          total: (product.pricePDV || product.price) * u.quantity
        });
        (this.quoteForm.get(`faceProducts`) as UntypedFormArray).push(crl);
        crl.valueChanges.subscribe(v => {
          const unitPrice = v.unitPrice -
            (v.discount / 100) * v.unitPrice;
          crl.get('total').setValue(
            unitPrice * v.quantity, {emitEvent: false}
          );
        });
      });
      this.recommendedBodyProductForm.value.forEach(u => {
        const product = this.products.find(v => v.id === u.productId);
        const crl = this.fb.group({
          id: product.id,
          name: product.description,
          barcode: product.barCode,
          comment: null,
          unitPrice: product.pricePDV || product.price,
          quantity: u.quantity,
          discount: 0,
          exclude: false,
          total: (product.pricePDV || product.price) * u.quantity
        });
        (this.quoteForm.get(`bodyProducts`) as UntypedFormArray).push(crl);
        crl.valueChanges.subscribe(v => {
          const unitPrice = v.unitPrice -
            (v.discount / 100) * v.unitPrice;
          crl.get('total').setValue(
            unitPrice * v.quantity, {emitEvent: false}
          );
        });
      });
    }
  }
  clearFormArray(formArray: UntypedFormArray) {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  groupByUuid(arr) {
    const group = arr.reduce((r, a) => {
      r[a.uuid] = [...r[a.uuid] || [], a];
      return r;
    }, {});
    return Object.keys(group).map(u => {
      if (u && u.length > 0) {
        return {
          ...group[u][0],
          quantity: group[u].length
        };
      }
      return null;
    }).filter(u => u !== null);
  }

  generateQuote() {
    const request = {
      clientId: this.quoteForm.value.clientId,
      anamnesiId: this.quoteForm.value.anamnesiId,
      quoteProducts: [
        ...this.quoteForm.value.faceProducts.filter(e => !e.exclude),
        ...this.quoteForm.value.bodyProducts.filter(e => !e.exclude)
      ],
      quoteTreatments: [
        ...this.quoteForm.value.faceTreatments.filter(e => !e.exclude),
        ...this.quoteForm.value.bodyTreatments.filter(e => !e.exclude)
      ]
    };
    this.loadingQuoteAction = true;
    this.anamnesiService.generateAnamnesiQuote(request).subscribe(d => {
      const blob = new Blob([d], { type: 'application/pdf'});
      FileSaver.saveAs(blob, `${this.client.firstName} ${this.client.lastName}.pdf`);
      this.loadingQuoteAction = false;
      /*const url = window.URL.createObjectURL(blob);
      const pwa = window.open(url);
      if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
        alert( 'Please disable your Pop-up blocker and try again.');
      }*/
    }, error => {
      Swal.default.fire({
        icon: 'error',
        title: 'Errore durante la generazione del preventivo',
        showConfirmButton: true
      });
      this.loadingQuoteAction = false;
    });
  }

  sendQuoteByEmail() {
    const request = {
      clientId: this.quoteForm.value.clientId,
      anamnesiId: this.quoteForm.value.anamnesiId,
      quoteProducts: [
        ...this.quoteForm.value.faceProducts.filter(e => !e.exclude),
        ...this.quoteForm.value.bodyProducts.filter(e => !e.exclude)
      ],
      quoteTreatments: [
        ...this.quoteForm.value.faceTreatments.filter(e => !e.exclude),
        ...this.quoteForm.value.bodyTreatments.filter(e => !e.exclude)
      ]
    };
    this.loadingQuoteAction = true;
    this.anamnesiService.sendQuoteToClientEmail(request).subscribe(d => {
      Swal.default.fire({
        icon: 'success',
        title: 'E-mail inviata con successo',
        showConfirmButton: false,
        timer: 3000
      });
      this.loadingQuoteAction = false;
    }, error => {
      Swal.default.fire({
        icon: 'error',
        title: 'Errore durante l\'invio di e-mail',
        showConfirmButton: true
      });
      this.loadingQuoteAction = false;
    });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdateVersionListComponent } from './update-version-list/update-version-list.component';
import {RouterModule, Routes} from "@angular/router";
import {SharedModule} from "../../../shared/shared.module";
import {  MatCardModule} from "@angular/material/card";
import {MatExpansionModule} from '@angular/material/expansion';

const routes: Routes = [
  {path: '', component: UpdateVersionListComponent}
];


@NgModule({
  declarations: [
    UpdateVersionListComponent
  ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        SharedModule.forChild(),
        MatCardModule,
        MatExpansionModule,
    ]
})
export class UpdateVersionModule { }

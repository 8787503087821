import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CompareFilterDateComponent} from './compare-filter-date.component';
import {TranslateModule} from '@ngx-translate/core';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import {FormsModule} from '@angular/forms';

@NgModule({
    declarations: [CompareFilterDateComponent],
    imports: [
        CommonModule,
        TranslateModule,
        NgxDaterangepickerMd.forRoot(),
        FormsModule
    ],
    exports: [CompareFilterDateComponent]
})
export class CompareFilterDateModule { }

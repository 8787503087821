import {RtXmlVersion} from '../enum/rt-xml-version';
import {AdvanceType} from '../enum/advance-type';

export class ConfigRtDto {
  id: null;
  uuid: null;
  ip: string;
  fiscalCode: string;
  active: boolean;
  creationDate: null;
  modificationDate: null;
  vatList: any[];
  departmentRtList: any[];
  paymentRtList: any[];
  operatorRtList: any[];
  header: any[];
  xmlVersion: RtXmlVersion;
  enableLottery: boolean; // ENABLE/DISABLE LOTTERY CODE
  startLotteryDate: Date; // IF ENABLED, START DATE LOTTERY
  advanceType: AdvanceType;
  rtModel: string;
  demoPrinter: boolean;
  registerNumber: string;
  firmware: string;
}

export class DepartmentRtDto {
  sequence: number;
  description: string;
  price: number;
  halo: number;
  lalo: number;
  single: null;
  vatRtSequence: number;
  groupCode: number;
  updated: boolean;
}

export class HeaderRtDto {
  id: null;
  uuid: null;
  sequence: number;
  description?: string;
  updated: boolean;
}

export class PaymentRtDto {
  id: null;
  uuid: null;
  sequence: number;
  description: string;
  change: boolean;
  cash: boolean;
  credit: boolean;
  drawer: boolean;
  mandatoryAmount: null;
  updated: boolean;
}

export class VatRtDto {
  id: null;
  uuid: null;
  sequence: number;
  vat: number;
  updated: boolean;
}

export class OperatorRtDto {
  id: null;
  uuid: null;
  sequence: number;
  name: string;
  updated: boolean;
}

export class ErrorCodeInterceptor {
  errorCode: number;
  errorTitle: string;
  errorDescription: string;
}

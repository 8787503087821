import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'setImcCheck'
})
export class SetImcCheckPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) {
      return null;
    }
    if (value < 16) {
      return 0;
    }
    if (value >= 16 && value < 18.5) {
      return 1;
    }
    if (value >= 18.5 && value < 25) {
      return 2;
    }
    if (value >= 25 && value < 30) {
      return 3;
    }
    if (value >= 30 && value < 35) {
      return 4;
    }
    if (value >= 35 && value < 40) {
      return 5;
    }
    return 6;

  }

}

import {Component, forwardRef, Input, OnChanges, OnInit} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-dropdown-select',
  templateUrl: './dropdown-select.component.html',
  styleUrls: ['./dropdown-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownSelectComponent),
      multi: true
    }
  ]
})
export class DropdownSelectComponent implements OnInit, OnChanges, ControlValueAccessor {

  @Input() list: any[] = [];
  @Input() ref: string;
  @Input() label: string;
  filterList: any[] = [];
  searchControl = new UntypedFormControl();
  prefix;
  constructor() {
  }

  ngOnInit() {

    this.searchControl.valueChanges.pipe(debounceTime(500)).subscribe((d: string) => {
      this.filterList = this.list.filter(e => {
        const s = e[this.ref] as string;
        return s.toLowerCase().includes(d.toLowerCase()) || e[this.label].includes(d);
      });
    });
  }

  ngOnChanges(): void {
    this.filterList = this.list;
  }

  choosePrefix(prefix) {
  }

  registerOnChange(fn: any): void {
    this.choosePrefix = fn;
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(obj: any): void {
    this.prefix = obj;
  }

}

import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {FirstNote} from '../models/first-note';

@Injectable({
  providedIn: 'root'
})
export class FirstNoteService {
  private API = environment.api + '/first-notes';

  constructor(private http: HttpClient) {
  }

  addFirstNote(request) {
    return this.http.post<void>(this.API, request);
  }

  findAllByCurrentSellPointAndDate(date) {
    return this.http.get<FirstNote[]>(this.API + '/' + date,);
  }

  getFirstNoteDates() {
    return this.http.get<string[]>(this.API + '/dates');
  }

  delete(id: number) {
    return this.http.delete<void>(this.API + '/' + id);
  }
}

import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA,  MatDialogRef} from '@angular/material/dialog';
import { MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-how-did-you-meet-us-modal',
  template: `
      <h1 mat-dialog-title>
          {{'PLANNING_FORM.APPLICATION_MODE' | translate}}
      </h1>
      <hr>
      <div mat-dialog-content>
          <form [formGroup]="applicationModeForm" id="addForm" (ngSubmit)="save()">

              <div class="d-flex align-items-left pt-2">
                  <label class="radio radio-primary">
                      <input type="radio" formControlName="applicationMode" value="noOne">
                      <span>{{'PLANNING_FORM.JUST_THIS_WEEK' | translate}}</span>
                  </label>
              </div>
              <div class="d-flex align-items-left pt-2">
                  <label class="radio radio-primary mr-4">
                      <input type="radio" formControlName="applicationMode" value="thisMonth">
                      <span>{{'PLANNING_FORM.THIS_MONTH' | translate}}</span>
                  </label>
              </div>
              <div class="d-flex align-items-left pt-2">
                  <label class="radio radio-primary">
                      <input type="radio" formControlName="applicationMode" value="firstThree">
                      <span>{{'PLANNING_FORM.FIRST_QUARTER' | translate}}</span>
                  </label>
              </div>
              <div class="d-flex align-items-left pt-2">
                  <label class="radio radio-primary">
                      <input type="radio" formControlName="applicationMode" value="secondThree">
                      <span>{{'PLANNING_FORM.SECOND_QUARTER' | translate}}</span>
                  </label>
              </div>
              <div class="d-flex align-items-left pt-2">
                  <label class="radio radio-primary">
                      <input type="radio" formControlName="applicationMode" value="thirdThree">
                      <span>{{'PLANNING_FORM.THIRD_QUARTER' | translate}}</span>
                  </label>
              </div>
              <div class="d-flex align-items-left pt-2">
                  <label class="radio radio-primary">
                      <input type="radio" formControlName="applicationMode" value="forthThree">
                      <span>{{'PLANNING_FORM.FORTH_QUARTER' | translate}}</span>
                  </label>
              </div>
              <div class="d-flex align-items-left pt-2">
                  <label class="radio radio-primary">
                      <input type="radio" formControlName="applicationMode" value="all">
                      <span>{{'PLANNING_FORM.ALL_THE_YEAR' | translate}}</span>
                  </label>
              </div>
          </form>
      </div>
      <hr>
      <div mat-dialog-actions class="d-flex justify-content-end">
          <div>
              <button mat-button class="cancel-button"
                      [matDialogClose]="null"><mat-icon>close</mat-icon>{{'CONFIGURATION.CLOSE' | translate}}</button>
            <span class="button-space" ></span>
              <button mat-flat-button class="confirm-button"
                      form="addForm"
                      (click)="save()"><mat-icon>save</mat-icon>{{'CONFIGURATION.SAVE' | translate}}
              </button>
          </div>
      </div>
  `
})
export class WeekPlanningApplicationModeComponent implements OnInit {
  applicationModeForm: UntypedFormGroup;
  submitted = false;
  addedElement = false;

  constructor(
    private translate: TranslateService,
    public dialogRef: MatDialogRef<WeekPlanningApplicationModeComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit(): void {
    this.applicationModeForm = new UntypedFormGroup({
      applicationMode: new UntypedFormControl('noOne')
    });
  }

  save() {
    this.dialogRef.close(this.applicationModeForm.controls.applicationMode.value);
  }
}

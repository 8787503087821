// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editor {
  min-height: 100px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  background: white;
  color: black;
  padding: 10px;
  font-size: 0.9rem;
  resize: vertical;
  overflow: auto;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.3rem 0;
}

.active-btn {
  background: #1A2980;
  color: white;
}

.mat-icon-button {
  height: 30px;
  width: 30px;
  line-height: 30px;
}

.disable {
  pointer-events: none;
  background-color: #eaeaea;
}`, "",{"version":3,"sources":["webpack://./src/app/content/pages/configurations/app-configurations/editor/editor.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,WAAA;EACA,qCAAA;EACA,kBAAA;EACA,iBAAA;EACA,YAAA;EACA,aAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,gBAAA;AACF;;AACA;EACE,mBAAA;EACA,YAAA;AAEF;;AAAA;EACE,YAAA;EACA,WAAA;EACA,iBAAA;AAGF;;AADA;EACE,oBAAA;EACA,yBAAA;AAIF","sourcesContent":[".editor {\r\n  min-height: 100px;\r\n  width: 100%;\r\n  border: 1px solid rgba(0, 0, 0, 0.25);\r\n  border-radius: 5px;\r\n  background: white;\r\n  color: black;\r\n  padding: 10px;\r\n  font-size: .9rem;\r\n  resize: vertical;\r\n  overflow: auto;\r\n}\r\n\r\n.toolbar {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  margin: .3rem 0;\r\n}\r\n.active-btn {\r\n  background: #1A2980;\r\n  color: white;\r\n}\r\n.mat-icon-button {\r\n  height: 30px;\r\n  width: 30px;\r\n  line-height: 30px;\r\n}\r\n.disable {\r\n  pointer-events: none;\r\n  background-color: #eaeaea;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {   MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'dialog-overview-company-dialog',
  template: `
  <h1 mat-dialog-title> {{data.message}} {{data.type}} : {{data.text}}?</h1>
<div mat-dialog-content>
  <p></p>
</div>
<div mat-dialog-actions align="center">
  <button mat-button (click)="isOk(false)" >No Thanks</button>
  <button mat-button (click)="isOk(true)" cdkFocusInitial>Ok</button>
</div>
  `})
export class DialogComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  isOk(bool: boolean) {
    this.dialogRef.close(bool);
  }


}

export interface DialogData {
  message: string;
  type: string;
  text: string;
}

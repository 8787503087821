import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FormArray, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {  MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {UpdateVersionService} from '../../../../../../shared/services/update-version.service';
import {UpdateVersion, UpdateVersionTranslation} from '../../../../../../shared/models/update-version';
import customBuild from '../../../../../../config/ckeditor/build/ckeditor';

@Component({
  selector: 'app-update-version-details',
  templateUrl: './update-version-details.component.html',
  styleUrls: ['./update-version-details.component.scss']
})
export class UpdateVersionDetailsComponent implements OnInit {

  Editor = customBuild;
  config = {
    toolbar: {
      items: [
        'heading', '|',
        'fontfamily', 'fontsize',
        'alignment',
        'fontColor', 'fontBackgroundColor', '|',
        'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
        'link', '|',
        'outdent', 'indent', '|',
        'bulletedList', '-', 'numberedList', 'todoList', '|',
        'code', 'codeBlock', '|',
        'insertTable', '|',
        'imageUpload', 'blockQuote', '|',
        'todoList'
        ,
        'undo', 'redo',
      ],
      shouldNotGroupWhenFull: true,

    },
    image: {
      // Configure the available styles.
      styles: [
        'alignLeft', 'alignCenter', 'alignRight'
      ],

      // Configure the available image resize options.
      resizeOptions: [
        {
          name: 'resizeImage:original',
          label: 'Original',
          value: null
        },
        {
          name: 'resizeImage:5',
          label: '5%',
          value: '5'
        },
        {
          name: 'resizeImage:10',
          label: '10%',
          value: '10'
        },
        {
          name: 'resizeImage:25',
          label: '25%',
          value: '25'
        },
        {
          name: 'resizeImage:50',
          label: '50%',
          value: '50'
        },
        {
          name: 'resizeImage:75',
          label: '75%',
          value: '75'
        }
      ],

      // You need to configure the image toolbar, too, so it shows the new style
      // buttons as well as the resize buttons.
      toolbar: [
        'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight',
        '|',
        'ImageResize',
        '|',
        'imageTextAlternative'
      ]
    },
    language: 'it',
  };

  updateVersionId: number;
  updateVersion: UpdateVersion;

  updateVersionForm: UntypedFormGroup;
  selectedTab = 0;

  constructor(
    private updateVersionService: UpdateVersionService,
    private activatedRoute: ActivatedRoute,
    private matSnackBar: MatSnackBar,
    private translateService: TranslateService
  ) {
    this.initForms();
    // this.messageService.getVersionIdMessage().subscribe(message => {
    //   if (message) {
    //     this.updateVersionId = Number(message);
    //     this.getUpdateVersionDetails(this.updateVersionId);
    //   }
    // });
    this.activatedRoute.params.subscribe((params) => {
      if (params.updateVersionId) {
        this.updateVersionId = Number(params.updateVersionId);
        this.getUpdateVersionDetails(this.updateVersionId);
      }
    });
  }

  ngOnInit(): void {
    // this.selectedTab = this.languageList.findIndex(u => u.code === this.translateService.currentLang);
  }

  initForms() {
    this.updateVersionForm = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      title: new UntypedFormControl(null),
      active: new UntypedFormControl(false),
      content: new UntypedFormControl(null),
    });
  }

  getUpdateVersionDetails(updateVersionId: number) {
    this.updateVersionService.getUpdateVersionDetails(updateVersionId).subscribe((response) => {
      console.log('update version response  = ', response);
      this.updateVersion = response;
      this.updateVersionForm.patchValue(this.updateVersion);
    });
  }

  updateUpdateVersion() {
    const updateVersion: UpdateVersion = {
      ...this.updateVersionForm.getRawValue(),
    };
    console.log('update UpdateVersion request = ', updateVersion);
    this.updateVersionService.updateUpdateVersion(updateVersion).subscribe(() => {
      this.matSnackBar.open(this.translateService.instant('DIALOG.UPDATE_SUCCESS'), 'Ok', {duration: 5000});
      this.getUpdateVersionDetails(this.updateVersionId);
    }, () => {
      this.matSnackBar.open(this.translateService.instant('DIALOG.CANNOT_UPDATE'), 'Ok', {duration: 5000});
    });
  }

  onReady(editor): void {
    if (editor.model.schema.isRegistered('image')) {
      editor.model.schema.extend('image', {allowAttributes: 'blockIndent'});
    }
  }

}

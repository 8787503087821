import {Component, ElementRef, forwardRef, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  providers: [
  {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EditorComponent),
    multi: true
  }
]
})
export class EditorComponent implements OnInit, ControlValueAccessor {
  bold = false;
  italic = false;
  strikethrough = false;
  underline = false;
  input;
  disableEditor = false;
  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
  }

  format(command, value?) {
    document.execCommand(command, false, value);
    document.getElementById('editor').focus();
    this.check();
  }

  check() {
    this.bold = document.queryCommandState('bold');
    this.italic = document.queryCommandState('italic');
    this.strikethrough = document.queryCommandState('strikethrough');
    this.underline = document.queryCommandState('underline');
  }

  registerOnChange(fn: any): void {
    this.onInput = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.disableEditor = isDisabled;
  }

  writeValue(obj: any): void {
    this.input = obj;
  }

  onInput($event) {
  }
}

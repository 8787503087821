// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subheader {
  display: flex;
  justify-content: space-between;
}

.mat-column-action {
  text-align: right;
}

.table-container table .table-row:hover {
  background: #f4f4f4;
  color: white !important;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
}

@media screen and (max-width: 970px) {
  .subheader {
    flex-direction: column;
  }
  .table-container {
    margin-top: 20px;
    max-width: 100vw;
    overflow-x: scroll;
  }
  .table-container .mat-table {
    overflow: auto;
    width: 1123px;
  }
}
tr.mat-row {
  height: 40px;
}

.ft-check {
  color: #06B5B6;
}

.btn {
  float: right;
}

@media (max-width: 767.98px) {
  .blue {
    margin-right: 0px !important;
    width: 100%;
  }
  .blue:first-child {
    margin-bottom: 10px;
  }
}
.card.card-fullheight {
  height: unset !important;
}`, "",{"version":3,"sources":["webpack://./src/app/content/pages/reports/treatment-product-reports/treatment-product-reports.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;AACF;;AACA;EACE,iBAAA;AAEF;;AAAA;EACE,mBAAA;EACA,uBAAA;EACA,yBAAA;UAAA,iBAAA;EACA,eAAA;AAGF;;AADA;EACE;IACE,sBAAA;EAIF;EAFA;IACE,gBAAA;IACA,gBAAA;IACA,kBAAA;EAIF;EAHE;IACE,cAAA;IACA,aAAA;EAKJ;AACF;AAAA;EACE,YAAA;AAEF;;AACA;EACE,cAAA;AAEF;;AAIA;EACE,YAAA;AADF;;AAIA;EACA;IACE,4BAAA;IACA,WAAA;EADA;EAGF;IACE,mBAAA;EADA;AACF;AAGA;EACE,wBAAA;AADF","sourcesContent":[".subheader {\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n.mat-column-action {\r\n  text-align: right;\r\n}\r\n.table-container table .table-row:hover {\r\n  background: #f4f4f4;\r\n  color: white !important;\r\n  user-select: none;\r\n  cursor: pointer;\r\n}\r\n@media screen and (max-width: 970px){\r\n  .subheader {\r\n    flex-direction: column;\r\n  }\r\n  .table-container {\r\n    margin-top: 20px;\r\n    max-width: 100vw;\r\n    overflow-x: scroll;\r\n    .mat-table{\r\n      overflow: auto;\r\n      width: 1123px;\r\n    }\r\n  }\r\n\r\n}\r\n\r\ntr.mat-row {\r\n  height: 40px;\r\n}\r\n\r\n.ft-check{\r\n  color:#06B5B6;\r\n}\r\n\r\n\r\n\r\n\r\n.btn{\r\n  float: right;\r\n}\r\n\r\n@media (max-width: 767.98px){\r\n.blue{\r\n  margin-right: 0px !important;\r\n  width: 100%;\r\n}\r\n.blue:first-child{\r\n  margin-bottom: 10px;\r\n}\r\n}\r\n.card.card-fullheight {\r\n  height: unset !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

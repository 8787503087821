import {Component, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {   MatSnackBar } from '@angular/material/snack-bar';
import {SweetAlertService} from '../../../../shared/services/sweet-alert.service';
import {BreadcrumbService} from '../../../../core/services/breadcrumb.service';
import {TranslateService} from '@ngx-translate/core';
import {takeUntil} from 'rxjs/operators';
import {Cabin} from '../../../../shared/models/cabin';
import {CabinFormModalComponent} from './cabin-form/cabin-form-modal.component';
import {CabinService} from '../../../../shared/services/cabin.service';
import {YearPlanningListComponent} from './year-planning-list/year-planning-list.component';
import {CustomSnackBarComponent} from '../../../../shared/custom-snack-bar/custom-snack-bar.component';
import {LicenseConfigurationService} from '../../../../shared/services/license-configuration.service';

@Component({
  selector: 'app-cabin',
  templateUrl: './cabin.component.html',
  styleUrls: ['./cabin.component.scss']
})
export class CabinComponent implements OnInit {

  columns = [
    'DATA_TABLE.ID',
    'DATA_TABLE.CABIN_DESCRIPTION',
    'DATA_TABLE.CREATION_DATE',
    'DATA_TABLE.PLANING_POSITION',
  ];
  rows: Cabin[] = [];
  searchFormControl = new UntypedFormControl();
  totalRecords: number;
  pageSize = 20;
  page = 1;
  unsubscribe = new Subject();
  loading = true;
  firstCall = true;
  countCabins: any;
  maxCabins: any;
  orderType: 'ASC' | 'DESC' = 'DESC';

  constructor(private cabinService: CabinService,
              private snackBar: MatSnackBar,
              private matDialog: MatDialog,
              private sweetAlertService: SweetAlertService,
              private breadcrumbService: BreadcrumbService,
              private translate: TranslateService,
              private licenseService: LicenseConfigurationService) {
  }

  ngOnInit() {
    this.sendBreadCrumb();
    this.getLazyCabin();
    this.licenseService.getCurrentGroupMaxCabins().subscribe(d => {
      this.maxCabins = d.maxCabins;
      this.countCabins = d.countCabins;
    });
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.unsubscribe();
  }

  sendBreadCrumb(): void {
    this.breadcrumbService.sendBreadcrumb(['CONFIGURATION', 'CABIN']);
  }

  getLazyCabin() {
    this.cabinService.findBySellPointId().subscribe(data => {
      this.rows = data;
    });
  }

  openFormDialog() {
    const dialogRef = this.matDialog.open(CabinFormModalComponent, {
      autoFocus: false,
      maxHeight: '90vh',
      data: {editMode: false}
    });
    dialogRef.afterClosed().subscribe(d => {
      this.getLazyCabin();
    });
  }

  deleteCabin($event: MouseEvent, cabin: Cabin) {
    $event.cancelBubble = true;
    $event.stopPropagation();
    this.sweetAlertService.warning(this.translate.instant('DIALOG.YOU_WANT_TO_DELETE') + cabin.name).then(e => {
      if (e.value) {
        this.cabinService.delete(cabin.id).subscribe(d => {
          if (d.status === 200) {
            this.getLazyCabin();
            return;
          }
        }, err => {
          this.showSnackBar({
            actionMsg: this.translate.instant('DIALOG.CANNOT_DELETE') + ' ' + cabin.name
          });
        });
      }
    });
  }

  openEditCabin($event: MouseEvent, cab) {
    $event.cancelBubble = true;
    $event.stopPropagation();
    const dialogRef = this.matDialog.open(CabinFormModalComponent, {
      width: '700px',
      maxHeight: '90vh',
      disableClose: true,
      data: {editMode: 1, cabin: cab}
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(d => {
      this.getLazyCabin();
    });
  }


  openEditPlannings($event: MouseEvent, cabin: Cabin) {
    $event.cancelBubble = true;
    $event.stopPropagation();
    const dialogRef = this.matDialog.open(YearPlanningListComponent, {
      width: '900px',
      autoFocus: true,
      disableClose: true,
      data: {editMode: false, cabin}
    });
    dialogRef.afterClosed().subscribe(d => {
      this.getLazyCabin();
    });
  }

  enableDisable(cabin: Cabin) {
    const msg = cabin.visible ? 'DIALOG.YOU_WANT_TO_HIDE' : 'DIALOG.YOU_WANT_TO_SHOW';
    this.sweetAlertService.warning(this.translate.instant(msg) + cabin.name).then(e => {
      if (e.value) {
        this.cabinService.enableDisable(cabin.id).subscribe(r => {
          cabin.visible = !cabin.visible;
        });
      }
    });
  }


  showSnackBar(data: any) {
    this.snackBar.openFromComponent(CustomSnackBarComponent, {
      data,
      duration: 5000,
      panelClass: 'white-snackbar'
    });
  }

  sortRows(c: string) {
    const sort = c.substr(11);
    if (this.orderType === 'ASC') {
      this.orderType = 'DESC';
    } else {
      this.orderType = 'ASC';
    }
    switch (sort) {
      case 'ID':
        if (this.orderType === 'ASC') {
          this.rows.sort((a, b) => a.seq - b.seq);
          return;
        }
        this.rows.sort((a, b) => b.seq - a.seq);
        break;
      case 'CREATION_DATE':
        if (this.orderType === 'ASC') {
          this.rows.sort((a, b) => new Date(a.creatAt).getTime() - new Date(b.creatAt).getTime());
          return;
        }
        this.rows.sort((a, b) => new Date(b.creatAt).getTime() - new Date(a.creatAt).getTime());
        break;
      case 'CABIN_DESCRIPTION':
        if (this.orderType === 'ASC') {
          this.rows.sort((a, b) => a.name.localeCompare(b.name));
          return;
        }
        this.rows.sort((a, b) => b.name.localeCompare(a.name));
        break;
      case 'PLANING_POSITION':
        if (this.orderType === 'ASC') {
          this.rows.sort((a, b) => a.planningPosition - b.planningPosition);
          return;
        }
        this.rows.sort((a, b) => b.planningPosition - a.planningPosition);
        break;
    }
  }
}

import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {UntypedFormControl, FormGroup, UntypedFormArray} from '@angular/forms';
import {BreadcrumbService} from '../../../../core/services/breadcrumb.service';
import {MovementMgmService} from '../../../../shared/services/movement-mgm.service';
import {Stock} from '../../../../shared/models/stock';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {StockHistoryComponent} from './stock-history/stock-history.component';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {LocalStorageService} from 'ngx-webstorage';
import {ActivatedRoute} from '@angular/router';
import {ProviderMgmService} from '../../../../shared/services/provider-mgm.service';
import {TranslateService} from '@ngx-translate/core';
import FileSaver from 'file-saver';
import {SweetAlertService} from "../../../../shared/services/sweet-alert.service";

@Component({
  selector: 'app-stock-management',
  templateUrl: './stock-management.component.html',
  styleUrls: ['./stock-management.component.scss']
})
export class StockManagementComponent implements OnInit {
  @ViewChild('stockHistoryTemplate', { static: false }) stockHistoryTemplate: TemplateRef<StockHistoryComponent>;
  columns = [
    'STOCK_FORM.CODE',
    'STOCK_FORM.DESCRIPTION',
    'STOCK_FORM.CATEGORY',
    'STOCK_FORM.SUBCATEGORIES',
    'STOCK_FORM.PROVIDER',
    'STOCK_FORM.ML_GR',
    'STOCK_FORM.USE_TYPE',
    'STOCK_FORM.BRAND',
    'STOCK_FORM.LINEA',
    'STOCK_FORM.PURCHASE_COST',
    'STOCK_FORM.AVERAGE_COST',
    'STOCK_FORM.IVA',
    'STOCK_FORM.PRICE',
    'STOCK_FORM.MIN_STOCK',
    'STOCK_FORM.STOCK',
    'STOCK_FORM.STOCK_ALERT',
    'STOCK_FORM.CREATION_DATE',
    'STOCK_FORM.ACTIVE',
    'STOCK_FORM.PHOTO',
    'STOCK_FORM.COMMERCIAL_DESCRIPTION',
  ];
  loading = true;
  firstCall = true;
  rows: Stock[] = [];
  searchFormControl = new UntypedFormControl(null);
  page = 1;
  totalRecords: number;
  pageSize = 10;
  selectedStock: Stock = new Stock();
  dialogRef: any;
  colsFormArray: UntypedFormArray;
  unsubscribe = new Subject();
  editModal: MatDialogRef<unknown, any>;
  alertStockFormControl = new UntypedFormControl(false);
  providerFormControl = new UntypedFormControl(null);
  providersList: any[] = [];
  providersPage = 1;
  disableDownload = false;
  disableToZero = false;
  loadingInventoryPdf = false;

  constructor(
    private breadcrumbService: BreadcrumbService, private route: ActivatedRoute,
    private movementService: MovementMgmService,
    private matDialog: MatDialog,
    private sweetAlertService: SweetAlertService,
    private translateService: TranslateService,
    private providerService: ProviderMgmService, private translate: TranslateService,
    private localStorageService: LocalStorageService,
  ) {
  }


  ngOnInit() {
    this.getProviders();
    this.route.queryParams.subscribe(params => {
      if (params['stock-alert']) {
        this.alertStockFormControl.setValue(true, {emitEvent: false});
      }
    });
    this.breadcrumbService.sendBreadcrumb(['MOVEMENT_AND_STOCK', 'STOCK']);
    this.getLazyProduct({page: this.page, pageSize: this.pageSize, alertStock: this.alertStockFormControl.value});
    this.searchFormControl.valueChanges.pipe(debounceTime(500)).subscribe(s => {
      this.getLazyProduct({page: 1, pageSize: this.pageSize, textSearch: s, alertStock: this.alertStockFormControl.value});
    });
    this.alertStockFormControl.valueChanges.pipe(debounceTime(1000)).subscribe(s => {
      this.page = 1;
      this.getLazyProduct({page: 1, pageSize: this.pageSize, alertStock: s, textSearch: this.searchFormControl.value});
    });
    this.providerFormControl.valueChanges.pipe(debounceTime(1000)).subscribe(s => {
      this.page = 1;
      this.getLazyProduct({
        page: 1,
        pageSize: this.pageSize,
        alertStock: this.alertStockFormControl.value,
        textSearch: this.searchFormControl.value
      });
    });
    this.colsFormArray = this.createColumnsForm(this.columns);
    this.colsFormArray.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(s => {
      this.localStorageService.store('cols', s);
    });
  }


  private createColumnsForm(columns) {
    if (this.localStorageService.retrieve('cols')) {
      return new UntypedFormArray(this.localStorageService.retrieve('cols').map(au => new UntypedFormControl(au)));
    }
    return new UntypedFormArray(columns.map(au => new UntypedFormControl(true)));
  }

  pageChange(page: number) {
    this.page = page;
    this.getLazyProduct({
      page,
      pageSize: this.pageSize,
      alertStock: this.alertStockFormControl.value,
      textSearch: this.searchFormControl.value
    });
  }

  getLazyProduct(request) {
    this.loading = true;
    request.providerId = this.providerFormControl.value;
    return this.movementService.getLazyStockList(request).subscribe(data => {
      this.rows = data.data;
      this.loading = false;
      this.firstCall = false;
      this.totalRecords = data.totalRecords;
    });
  }

  openDetails(stock: Stock) {
    this.selectedStock = stock;
    this.dialogRef = this.matDialog.open(this.stockHistoryTemplate,
      {
        disableClose: false,
        autoFocus: true,
        width: '1300px',
        maxHeight: '80%',
        data: stock
      });
  }


  chooseColumns(content) {
    this.editModal = this.matDialog.open(content, {
      autoFocus: false,
      maxHeight: '90vh'
    });
  }

  getProviders() {
    this.providerService.getLazyProviderList({page: this.providersPage, pageSize: 10, visible: true}).subscribe(d => {
      this.providersList = [...this.providersList, ...d.data];
      this.providersPage++;
    });
  }

  getStockExcel(type) {
    this.disableDownload = true;
    this.movementService.getStockExcel({
      page: 1,
      pageSize: Math.pow(2, 31) - 1,
      alertStock: this.alertStockFormControl.value,
      textSearch: this.searchFormControl.value
    }, type).subscribe(r => {
      const blob: Blob = new Blob([r], {type: 'text/csv'});
      console.log(type === 'excel');
      const fileName = type === 'excel' ? 'giacenze.xlsx' : 'giacenze.csv';
      const objectUrl = URL.createObjectURL(blob);
      const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
      a.href = objectUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(objectUrl);
      this.disableDownload = false;
    }, error => this.disableDownload = false);
  }


  stockPdf() {
    const request = {
      page: 1,
      pageSize: Math.pow(2, 31) - 1,
      alertStock: this.alertStockFormControl.value,
      textSearch: this.searchFormControl.value,
      columns: this.colsFormArray.value,
    };
    this.loadingInventoryPdf = true;
    this.movementService.getStockPdf(request).subscribe(d => {
      console.log(d);
      const blob = new Blob([d], {type: 'application/pdf'});
      FileSaver.saveAs(blob, `giacenze.pdf`);
      this.loadingInventoryPdf = false;
    }, error => {
      this.sweetAlertService.error('Errore durante la generazione del pdf');
      this.loadingInventoryPdf = false;
    });
  }

  confirmAndClose(): void {
    this.sweetAlertService.showConfirmationAlert()
      .then((confirmation) => {
        if (confirmation) {
          this.editModal.close();
        }
      });
  }
}

import {ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector} from '@angular/core';
import {InventoryManagementFormComponent} from '../inventory-management-form/inventory-management-form.component';
import {SweetAlertService} from '../../../../../shared/services/sweet-alert.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class InventoryFormService {
  dialogComponentRef: ComponentRef<InventoryManagementFormComponent>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private sweetAlertService: SweetAlertService,
    private translate: TranslateService
  ) {
  }

  openForm(container, data?: any) {
    // close form if opened
    if (this.dialogComponentRef) {
      if (this.dialogComponentRef.instance.updated && !this.dialogComponentRef.instance.disableSave) {
        this.sweetAlertService.warning(
          this.translate.instant('PLANNING.CONFIRM_CANCELLATION')).then(e => {
          if (e.value) {
            this.appRef.detachView(this.dialogComponentRef.hostView);
            this.dialogComponentRef.destroy();
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(InventoryManagementFormComponent);
            componentFactory.create(this.injector);
            this.dialogComponentRef = this.createComponent(container, data);
          }
        });
      } else {
        this.appRef.detachView(this.dialogComponentRef.hostView);
        this.dialogComponentRef.destroy();
        this.dialogComponentRef = this.createComponent(container, data);
      }
    } else {
      // open form component and pass data to it
       this.dialogComponentRef = this.createComponent(container, data);
    }
    // return a ref of the opened form component
    return this.dialogComponentRef;
  }

  createComponent(container, data?: any) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(InventoryManagementFormComponent);
    componentFactory.create(this.injector);
    const componentRef = container.createComponent(componentFactory);
    componentRef.instance.inventory = data?.inventory;
    componentRef.instance.editMode = data?.editMode;
    componentRef.instance.loadAllPdts = data?.loadAllPdts;
    componentRef.instance.sellPointId = data?.sellPointId;
    componentRef.instance.date = data?.date;
    componentRef.instance.appRef = this.appRef;
    componentRef.instance.dialogComponentRef = componentRef;
    return componentRef;
  }
}

import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CourtesyRtService} from '../../../../shared/services/courtesy-rt.service';
import {ConfigRtDto, DepartmentRtDto, HeaderRtDto, OperatorRtDto, PaymentRtDto, VatRtDto} from '../../../../shared/dto/config-rt-dto';
import {  MatDialog,   MatDialogRef} from '@angular/material/dialog';
import {UntypedFormControl, FormGroup} from '@angular/forms';
import {SweetAlertService} from '../../../../shared/services/sweet-alert.service';
import {  MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {finalize, takeUntil} from 'rxjs/operators';
import {forkJoin, Subject} from 'rxjs';
import {BreadcrumbService} from '../../../../core/services/breadcrumb.service';
import {PaymentService} from '../../../../shared/services/payment.service';
import {environment} from '../../../../../environments/environment';
import {SellPointService} from '../../../../shared/services/sell-point.service';
import {Router} from '@angular/router';
import {MovementType} from '../../../../shared/enum/movement-type';
import {AdvanceType} from '../../../../shared/enum/advance-type';
import {RtXmlVersion} from '../../../../shared/enum/rt-xml-version';
import moment from 'moment';
import {IvaRtRepartoConfigComponent} from '../rt/iva-rt-reparto-config/iva-rt-reparto-config.component';
import {PaymentRtPagamentoConfigComponent} from '../rt/payment-rt-pagamento-config/payment-rt-pagamento-config.component';
import {HttpClient} from '@angular/common/http';
import {ConfigCourtesyRtDto} from '../../../../shared/dto/config-courtesy-rt-dto';
import {HeaderFooterCourtesyRtConfigComponent} from './header-footer-courtesy-rt-config/header-footer-courtesy-rt-config.component';

@Component({
  selector: 'app-courtesy-rt',
  templateUrl: './courtesy-rt.component.html',
  styleUrls: ['./courtesy-rt.component.scss']
})
export class CourtesyRtComponent implements OnInit {

  @ViewChild('content', { static: false }) rtContent: any;
  ip = '';
  port = '9100';
  disable = false;
  departmentColumn = [
    'DATA_TABLE.SEQUENCE',
    'DATA_TABLE.DESCRIPTION',
    'DATA_TABLE.PRICE',
    'DATA_TABLE.HALO',
    'DATA_TABLE.LALO',
    'DATA_TABLE.VAT',
    'DATA_TABLE.GROUP_CODE',
    ''
  ];
  vatColumn = ['DATA_TABLE.SEQUENCE',
    'DATA_TABLE.VAT',
    ''];
  paymentColumn = ['DATA_TABLE.SEQUENCE',
    'DATA_TABLE.DESCRIPTION',
    'DATA_TABLE.CASH',
    'DATA_TABLE.CREDIT',
    'DATA_TABLE.CHANGE',
    'DATA_TABLE.DRAWER',
    ''];
  operatorColumn = ['DATA_TABLE.SEQUENCE',
    'DATA_TABLE.NAME',
    ''];
  headerColumn = ['DATA_TABLE.SEQUENCE',
    'DATA_TABLE.DESCRIPTION',
    ''];
  configColumn = [
    'DATA_TABLE.IP',
    'DATA_TABLE.CREATION_DATE',
    'DATA_TABLE.MODIFICATION_DATE',
    'DATA_TABLE.STATUS',
    '',
    '',
  ];
  courtesyRtConfigList: ConfigCourtesyRtDto[] = [];
  courtesyRtConfig: ConfigCourtesyRtDto;

  editModal: MatDialogRef<unknown, any>;
  rtModal: MatDialogRef<unknown, any>;
  operationsModal: MatDialogRef<unknown, any>;
  loading = true;
  reset = null;
  receipt = null;
  smallLoading = false;
  payWithoutPrint = false;
  hasDark: boolean;
  rtWindow: Window;
  usePrintingPopup = new UntypedFormControl(false);

  private unsubscribe = new Subject();
  advanceTypes = [];
  selectedRt: ConfigRtDto;
  xmlVersions = [];
  startLotteryDate: UntypedFormControl;

  constructor(private courtesyRtService: CourtesyRtService, private modalService: MatDialog, private translate: TranslateService,
              private matDialog: MatDialog, private sellPointService: SellPointService,
              private breadcrumbService: BreadcrumbService, private paymentService: PaymentService,
              private sweetAlertService: SweetAlertService, private snackBar: MatSnackBar, private router: Router,
              private http: HttpClient
  ) {
  }

  ngOnInit() {

    this.courtesyRtService.getSalesWithoutPrint().subscribe((r: boolean) => {
      this.payWithoutPrint = r;
    });
    this.paymentService.hasDark().subscribe((r: boolean) => {
      this.hasDark = r;
    });
    this.breadcrumbService.sendBreadcrumb(['CONFIGURATION', 'RT']);

    this.getConfigList();
  }

  getConfigList() {
    this.courtesyRtService.getAllConfigs().subscribe((r: ConfigCourtesyRtDto[]) => {
      this.loading = false;
      this.courtesyRtConfigList = r;
      const activeRT = r.filter(u => u.active)[0];
      this.sellPointService.getUsePrintingPopup().subscribe(d => {
        this.usePrintingPopup.setValue(d, {emitEvent: false});
        if (this.router.url === '/courtesy-rt-conf/close') {
        }
      });
    });
  }

  getRtConf() {
    //Da fare controllo se la rt è gia stata registrata
    if (this.courtesyRtConfigList.filter(a => a.ip.replace(' ', '') === this.ip.replace(' ', '')).shift()) {
      this.sweetAlertService.notification(this.translate.instant('RT.THE_CONFIGURATION_OF_THIS_IP_ADDRESS_IS_ALREADY_SAVED')).then(e => {
      });
      return;
    }
    // @ts-ignore

    this.http.get<any>('http://localhost:3000/courtesy-rt/test?ip='+ this.ip + '&port=' + this.port,
      {responseType: 'json'}).subscribe(response => {}, error =>{})
    this.disable = true;
    this.sweetAlertService
      .warning(this.translate.instant('COURTESYRT.DID_THE_PRINTING_TEST_TOOK_PLACE'), this.translate.instant('COURTESYRT.YES'), this.translate.instant('COURTESYRT.NO'))
      .then(u => {
        if (!u.value) {
          this.sweetAlertService
            .notification(this.translate.instant('COURTESYRT.CHECK_IP'))
          return;
        }
        this.courtesyRtConfig = new ConfigCourtesyRtDto();
        this.courtesyRtConfig.active = true;
        this.courtesyRtConfig.header = [];
        this.courtesyRtConfig.footer = [];
        this.courtesyRtConfig.ip = this.ip;
        this.courtesyRtConfig.port = this.port;

        this.sweetAlertService
          .success(this.translate.instant('COURTESYRT.RT_ADDED'))

        this.courtesyRtService.saveCourtesyRtConfig(this.courtesyRtConfig).subscribe(() => {
          this.snackBar.open(this.translate.instant('DIALOG.ADD_SUCCESS'), 'Ok', {duration: 5000});
          this.getConfigList();
        });

      });
    this.disable = false;
  }

  deleteRtConfig(id) {
    this.sweetAlertService.warning(this.translate.instant('DIALOG.YOU_WANT_TO_DELETE')).then(e => {
      if (e.value) {
        this.courtesyRtService.deleteConfig(id).subscribe((r: any) => {
          if (r.status === '500') {
            this.sweetAlertService.danger(this.translate.instant('RT.DELETE_ERROR'));
          } else {
            this.snackBar.open(this.translate.instant('DIALOG.DELETE_SUCCESS'), 'Ok', {duration: 5000});
            this.getConfigList();
          }
        }, error => {
          this.sweetAlertService.notification(this.translate.instant('RT.DELETE_ERROR'));
        });
      }
    });
  }

  openEditCourtesyRtConfig(content, courtesyRtConfig) {
    this.courtesyRtConfig = courtesyRtConfig;
    // console.log(this.rtConfig.departmentRtList);
    this.rtModal = this.modalService.open(content, {
      maxWidth: '98%',
    });
    //this.synchronizeCourtesyRtConfig();
  }
  disableRtConfig(o, id) {
    this.courtesyRtService.editStatus(id).subscribe(r => {
      if (r === true) {
        this.getConfigList();
      }
    });
  }
  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.complete();
  }
  openHeaderFooterCourtesyRtConfig(o){
    const dialogRef = this.matDialog.open(HeaderFooterCourtesyRtConfigComponent,
      {
        disableClose: false,
        autoFocus: true,
        width: '1200px',
        data: {
          o:o,
          ip:o.ip,
          port:this.port
        },
      });
    dialogRef.afterClosed().subscribe(d => {
    });
  }
}

import {Component, OnInit} from '@angular/core';
import {Category} from '../../../../shared/models/category';
import {UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {ConfigurationsService} from '../../../../shared/services/configurations.service';
import {  MatDialog,   MatDialogRef} from '@angular/material/dialog';
import {SweetAlertService} from '../../../../shared/services/sweet-alert.service';
import {BreadcrumbService} from '../../../../core/services/breadcrumb.service';
import {TranslateService} from '@ngx-translate/core';
import {LazyRequest} from '../../../../shared/dto/lazy-request';
import {CategoryFormModalComponent} from './category-form-modal.component';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {CategoryType} from '../../../../shared/enum/category-type.enum';
import {SubCategoryFormModalComponent} from './sub-category-form-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SubCategory} from '../../../../shared/models/sub-category';

@Component({
  selector: 'app-category',
  templateUrl: './category-treatment.component.html',
  styleUrls: ['./category-treatment.component.scss']
})
export class CategoryTreatmentComponent implements OnInit {
  columns = [
    'DATA_TABLE.ID',
    'DATA_TABLE.CATEGORY_NAME',

  ];


  subColumns = [
    '',
    'DATA_TABLE.SUBCATEGORY_NAME',
    ''
  ];


  rows: Category[] = [];
  categoryRow: any[] = [];
  subRows: SubCategory[] = [];
  searchFormControl = new UntypedFormControl();
  totalRecords: number;
  pageSize = 10;
  page = 1;
  unsubscribe = new Subject();
  loading = true;
  firstCall = true;
  loadSubCategories: boolean;
  ct: CategoryType;
  orderType: 'ASC' | 'DESC' = 'DESC';

  constructor(private configurationsService: ConfigurationsService,
              private matDialog: MatDialog,
              private sweetAlertService: SweetAlertService,
              private breadcrumbService: BreadcrumbService,
              private translate: TranslateService,
              public modal: NgbModal) {
  }

  ngOnInit() {
    this.sendBreadCrumb();
    this.getLazyCategory({page: this.page, pageSize: this.pageSize, type: 'TREATMENT'});
    this.searchFormControl.valueChanges.pipe(debounceTime(500), takeUntil(this.unsubscribe)).subscribe(text => {
      this.getLazyCategory({page: 1, pageSize: this.pageSize, textSearch: text, type: 'TREATMENT'});
    });
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.unsubscribe();
  }

  sendBreadCrumb(): void {
    this.breadcrumbService.sendBreadcrumb(['CONFIGURATION', 'CATEGORIES_TREATMENT']);
  }

  getLazyCategory(request: LazyRequest) {
    this.loading = true;
    return this.configurationsService.getLazyCategories(request).subscribe(data => {
      this.categoryRow = [];
      this.rows = data.data;
      this.rows.forEach(row => {
        this.categoryRow.push({
          row,
          isCollapsed: false
        });
      });
      this.totalRecords = data.totalRecords;
      this.loading = false;
      this.firstCall = false;
    });
  }


  getSubCategories(id) {
    this.loadSubCategories = true;
    return this.configurationsService.getSubCategoriesByCategoryid(id).subscribe(data => {
      this.subRows = data;
      this.loadSubCategories = false;
    });
  }


  openFormDialog() {
    const dialogRef = this.matDialog.open(CategoryFormModalComponent, {
      width: '700px',
      autoFocus: true,
      disableClose: true,
      data: {editMode: false}
    });
    dialogRef.afterClosed().subscribe(d => {
      if (d) {
        this.page = 1;
        this.getLazyCategory({page: 1, pageSize: this.pageSize, type: 'TREATMENT'});
      }
    });
  }


  openSubCategoryFormDialog(id) {
    const dialogRef = this.matDialog.open(SubCategoryFormModalComponent, {
      width: '700px',
      autoFocus: true,
      disableClose: true,
      data: {
        editMode: false,
        categoryId: id
      }
    });
  }

  openEditSubCategory(subcategory: SubCategory) {
    const dialogRef = this.matDialog.open(SubCategoryFormModalComponent, {
      width: '700px',
      disableClose: true,
      data: {editMode: true, subCategory: subcategory}
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(d => {
      if (d) {
        this.getSubCategories(subcategory.category.id);
      }
    });
  }

  deleteSubCategory(subcategory: SubCategory) {
    this.sweetAlertService.warning(this.translate.instant('DIALOG.YOU_WANT_TO_DELETE') + subcategory.name).then(e => {
      if (e.value) {
        this.configurationsService.deleteSubCategory(subcategory.id).subscribe(d => {
          if (d.status === 200) {
            this.getSubCategories(subcategory.category.id);
          }
        });
      }
    });
  }

  deleteCategory(category: any, i: number) {
    this.sweetAlertService.warning(this.translate.instant('DIALOG.YOU_WANT_TO_DELETE') + category.name).then(e => {
      if (e.value) {
        this.configurationsService.deleteCategory(category.id).subscribe(d => {
          if (d.status === 200) {
            this.getLazyCategory({page: this.page, pageSize: this.pageSize, type: 'TREATMENT'});
          }
        });
      }
    });
  }

  openEditCategory(b: Category, i: number) {
    const dialogRef = this.matDialog.open(CategoryFormModalComponent, {
      width: '700px',
      disableClose: true,
      data: {editMode: true, category: b}
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(d => {
      if (d) {
        this.getLazyCategory({page: this.page, pageSize: this.pageSize, textSearch: this.searchFormControl.value, type: 'TREATMENT'});
      }
    });
  }

  pageChange(page: number) {
    this.page = page;
    this.getLazyCategory({page: this.page, pageSize: this.pageSize, textSearch: this.searchFormControl.value, type: 'TREATMENT'});
  }


  open(content, id) {
    this.getSubCategories(id);
    this.matDialog.open(content, {
      autoFocus: false,
      maxHeight: '90vh'
    });
  }


  sortRows(c: string) {
    const sort = c.substr(11);
    if (this.orderType === 'ASC') {
      this.orderType = 'DESC';
    } else {
      this.orderType = 'ASC';
    }
    switch (sort) {
      case 'ID':
        this.getLazyCategory({
          page: this.page, pageSize: this.pageSize, textSearch: this.searchFormControl.value, type: 'TREATMENT', sort: {
            attribute: 'id',
            direction: this.orderType
          }
        });
        break;
      case 'CATEGORY_NAME':
        this.getLazyCategory({
          page: this.page, pageSize: this.pageSize, textSearch: this.searchFormControl.value, type: 'TREATMENT', sort: {
            attribute: 'name',
            direction: this.orderType
          }
        });
        break;
    }
  }
}

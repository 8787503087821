import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OperatorsListComponent} from './operators-list.component';
import {MatLegacyButtonModule} from '@angular/material/legacy-button';
import {   MatFormFieldModule } from '@angular/material/form-field';
import {   MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {   MatTableModule } from '@angular/material/table';
import {  MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';

import {SharedModule} from '../../../../shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule, Routes} from '@angular/router';
import {ReactiveFormsModule} from '@angular/forms';
import {NgbPaginationModule, NgbTooltipModule, NgbTypeaheadModule} from '@ng-bootstrap/ng-bootstrap';
import {OperatorPlanningsComponent} from './plannings/operator-plannings.component';
import {OperatorPlanningFormComponent} from './plannings/planning-form/operator-planning-form.component';
import {YearPlanningComponent} from '../../../../shared/year-planning/year-planning.component';
import {YearPlanningModule} from '../../../../shared/year-planning/year-planning.module';
import {ColorPickerModule} from 'ngx-color-picker';
import {MatIconModule} from "@angular/material/icon";


const routes: Routes = [
  {
    path: '',
    component: OperatorsListComponent,
  }
];

@NgModule({
    declarations: [
        OperatorsListComponent,
        OperatorPlanningsComponent,
        OperatorPlanningFormComponent
    ],
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        MatLegacyButtonModule,
        SharedModule,
        RouterModule.forChild(routes),
        MatTabsModule,
        TranslateModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        NgbPaginationModule,
        NgbTypeaheadModule,
        MatTableModule,
        YearPlanningModule,
        ColorPickerModule,
        NgbTooltipModule,
        MatIconModule,
    ]
})
export class OperatorsPlanningModule {
}

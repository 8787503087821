import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {Abonnement} from '../../../../../shared/models/Abonnement';
import {ActionState} from '../../../../../shared/enum/action-state.enum';
import {SweetAlertService} from '../../../../../shared/services/sweet-alert.service';
import {SubscriptionService} from '../../../../../shared/services/subscription.service';

@Component({
  selector: 'app-subscription-management-form',
  templateUrl: './subscription-management-form.component.html',
  styleUrls: ['./subscription-management-form.component.scss']
})
export class SubscriptionManagementFormComponent implements OnInit, OnDestroy {

  actionState: Subject<ActionState>;
  subscription: Abonnement;
  onClose = new Subject();
  appRef;
  dialogComponentRef;
  unsubscribe$ = new Subject();
  dirty:boolean = false;
  constructor( private sweetAlertService: SweetAlertService,    private subscriptionService: SubscriptionService,) {
  }

  ngOnInit() {
    this.subscriptionService.formDirtyChanged.subscribe((isDirty: boolean) => {
      if (isDirty) {
        this.dirty = isDirty;
      }
    });
  }

  save() {
    this.actionState.next(ActionState.SAVE);
  }

  activateEdit() {
    this.actionState.next(ActionState.ENABLE_EDIT);
  }

  updateSubscriptions($event) {
    this.onClose.next($event);
    this.closeFormSave();
  }



  ngOnDestroy(): void {
    this.unsubscribe$.next(void 0);
    this.unsubscribe$.unsubscribe();
  }

  closeForm() {
    if(this.dirty){
     this.sweetAlertService.showConfirmationAlert()
      .then((confirmation) => {
        if (confirmation) {
          this.destroyForm();
        }
      });}
    else{
      this.closeFormSave()
    }
  }
  closeFormSave() {
    this.destroyForm();
  }

  destroyForm() {
    this.appRef.detachView(this.dialogComponentRef.hostView);
    this.dialogComponentRef.destroy();
  }


}

import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {PaymentService} from '../../../../shared/services/payment.service';
import {Payment} from '../../../../shared/models/payment';
import {BreadcrumbService} from '../../../../core/services/breadcrumb.service';
import {UntypedFormControl} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {CustomSnackBarComponent} from '../../../../shared/custom-snack-bar/custom-snack-bar.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {   MatSnackBar } from '@angular/material/snack-bar';
import {PaymentStatus} from '../../../../shared/enum/payment-status';
import {ActivatedRoute, Router} from '@angular/router';
import {SweetAlertService} from 'src/app/shared/services/sweet-alert.service';
import {TranslateService} from '@ngx-translate/core';
import {RtService} from 'src/app/shared/services/rt.service';
import {PaymentMode} from '../../../../shared/enum/payment-mode';
import {environment} from '../../../../../environments/environment';
import {SellPointService} from '../../../../shared/services/sell-point.service';
import {ErrorCodeInterceptor} from '../../../../shared/dto/config-rt-dto';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss']
})
export class PaymentHistoryComponent implements OnInit {
  @ViewChild('title', { static: false }) title: ElementRef;
  payments: Payment[] = [];
  load = false;
  searchFormControl = new UntypedFormControl(null);
  pageSize = 5;
  page = 1;
  firstCall = true;
  public totalRecords: number;
  selectedPayment: Payment;
  changingReceipt = false;

  reset: number = null;
  receipt: number = null;


  columns = [
    '',
    'ID',
    'Doc. N.',
    'DATA_TABLE.NAME',
    'DATA_TABLE.OPERATOR',
    'DATA_TABLE.TOTAL',
    'DATA_TABLE.AMOUNTPAYED',
    'DATA_TABLE.STATUS',
    'PAYMENT.FISCAL',
    'DATA_TABLE.DOCUMENT',
    ''
  ];
  showResoFormControl = new UntypedFormControl(false);
  private printingWindow: Window;
  private isUsingPopup;

  constructor(private paymentService: PaymentService,
              private breadcrumbService: BreadcrumbService,
              private matDialog: MatDialog,
              private snackBar: MatSnackBar,
              private route: ActivatedRoute,
              private router: Router,
              private sweetAlertService: SweetAlertService,
              private translate: TranslateService,
              private rtService: RtService,
              private sellPointService: SellPointService) {
  }

  ngOnInit() {
    // this.rtService.retryPrint(17998).subscribe(r => console.log(r));
    this.breadcrumbService.sendBreadcrumb(['PAYMENT_HISTORY']);
    this.getLazyPayments({page: this.page, pageSize: this.pageSize});
    this.searchFormControl.valueChanges.pipe(debounceTime(500)).subscribe(s => {
      this.getLazyPayments({page: 1, pageSize: this.pageSize, textSearch: s});
    });
    this.sellPointService.getUsePrintingPopup().subscribe(d => {
      this.isUsingPopup = d;
    });
    this.showResoFormControl.valueChanges.pipe(debounceTime(500)).subscribe(s => {
      this.payments = [];
      this.columns = s ?
        ['', 'ID', 'Ticket N.', 'DATA_TABLE.CLIENT', 'DATA_TABLE.OPERATOR', 'DATA_TABLE.TOTAL_RENDER', 'DATA_TABLE.RESO_TYPE',
          'DATA_TABLE.STATUS', 'DATA_TABLE.DOCUMENT'] :
        ['', 'ID', 'Doc. N.', 'DATA_TABLE.NAME', 'DATA_TABLE.OPERATOR', 'DATA_TABLE.TOTAL', 'DATA_TABLE.STATUS', 'PAYMENT.FISCAL',
          'DATA_TABLE.DOCUMENT', ''];
      this.page = 1;
      this.getLazyPayments({page: 1, pageSize: this.pageSize, textSearch: this.searchFormControl.value});
    });
  }

  getLazyPayments(request, snackBarConf?) {
    this.load = true;
    if (this.showResoFormControl.value) {
      return this.paymentService.getLazyReso(request).subscribe(data => {
        this.payments = data.data;
        this.load = false;
        this.firstCall = false;
        this.totalRecords = data.totalRecords;
        if (snackBarConf) {
          this.showSnackBar(snackBarConf);
        }
      });
    }

    return this.paymentService.getLazyPayments(request).subscribe(data => {
      this.payments = data.data;
      this.load = false;
      this.firstCall = false;
      this.totalRecords = data.totalRecords;
      if (snackBarConf) {
        this.showSnackBar(snackBarConf);
      }
    });
  }

  showSnackBar(data: any) {
    this.snackBar.openFromComponent(CustomSnackBarComponent, {
      data,
      duration: 5000,
      panelClass: 'white-snackbar'
    });
  }

  pageChange(page: number) {
    this.page = page;
    this.getLazyPayments({page, pageSize: this.pageSize, textSearch: this.searchFormControl.value});
  }

  showPaymentStatusHistory($event, p, content) {
    if (this.showResoFormControl.value) {
      return;
    }
    $event.cancelBubble = true;
    $event.stopPropagation();
    this.selectedPayment = p;
    const dialogRef = this.matDialog.open(content, {width: '700px'});
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  showTicketDetails($event, p, content) {
    $event.cancelBubble = true;
    $event.stopPropagation();
    this.selectedPayment = p;
    this.reset = this.selectedPayment.reset;
    this.receipt = this.selectedPayment.receipt;
    const dialogRef = this.matDialog.open(content, {width: '1400px', maxHeight: '92vh'});
    dialogRef.afterClosed().subscribe(result => {
      this.getLazyPayments({page: this.page, pageSize: this.pageSize});
    });
  }


  openReceiptForm() {
    this.changingReceipt = true;
  }

  cancelChange() {
    this.changingReceipt = false;
    this.reset = this.selectedPayment.reset;
    this.receipt = this.selectedPayment.receipt;
  }

  changeReceipt() {
    this.paymentService.updateTicketDetails({id: this.selectedPayment.id, reset: this.reset, receipt: this.receipt}).subscribe(() => {
      this.selectedPayment.reset = this.reset;
      this.selectedPayment.receipt = this.receipt;
      this.changingReceipt = false;
      if (this.reset && this.receipt && this.selectedPayment.currentStatus !== PaymentStatus.DONE) {
        this.paymentService.updateStatus({
          configRtId: null,
          errorCode: null,
          bookingId: null,
          paymentId: this.selectedPayment.id,
          status: PaymentStatus.DONE
        }).subscribe(s => {
          this.selectedPayment.currentStatus = PaymentStatus.DONE;
          this.sweetAlertService.success(this.translate.instant('DIALOG.SUCCESS_EMIT_TICKET')).then(e => {
          });
        }, err => {
          this.sweetAlertService.success(this.translate.instant('DIALOG.ERROR_EMIT_TICKET')).then(e => {
          });
        });
      }
    });
  }


  printSpecificTicketCommand(id) {
    console.log(id);
    if (this.isUsingPopup) {
      this.rtService.printSpecificTicketCommand(id).subscribe(r => {
        this.printingWindow = window.open(
          `${environment.printingUrl}?lang=${this.translate.currentLang}`,
          '_blank',
          // tslint:disable-next-line:max-line-length
          `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no,width=700,height=500,left=${(screen.width - 700) / 2},top=${(screen.height - 500) / 2}`
        );
        window.addEventListener('message', (x) => {
          if (x.data !== 'ready') {
            return;
          }
          this.printingWindow.postMessage({cmd: r.cmd, ip: r.ip}, '*');
          window.addEventListener('message', data => {
            const d = data.data;
            this.printingWindow.close();
            if (d.success) {
              this.rtService.interceptErrorCode(d.xml).subscribe(rr => {
                if (rr.errorCode !== 0) {
                  this.interceptPrinterError(rr);
                  return;
                }
                this.sweetAlertService.success(this.translate.instant('DIALOG.SUCCESS_EMIT_QUOTE'));
              });
            } else {
              this.sweetAlertService.danger(this.translate.instant('DIALOG.FAILED_EMIT_QUOTE'));
            }
          }, {once: true});
        }, {once: true});
      }, error => {
        this.sweetAlertService.danger(this.translate.instant('DIALOG.FAILED_EMIT_QUOTE'));
      });
    } else {
      this.rtService.printSpecificTicketCommand(id).subscribe(r => {
        this.rtService.updateRt(r.ip, r.cmd).subscribe(res => {
          this.rtService.interceptErrorCode(res.body).subscribe(rr => {
            if (rr.errorCode !== 0) {
              this.interceptPrinterError(rr);
              return;
            }
            this.sweetAlertService.success(this.translate.instant('DIALOG.SUCCESS_EMIT_QUOTE'));
          });
        }, error => {
          this.sweetAlertService.danger(this.translate.instant('DIALOG.FAILED_EMIT_QUOTE'));
        });
      }, error => {
        this.sweetAlertService.danger(this.translate.instant('DIALOG.FAILED_EMIT_QUOTE'));
      });
    }
  }

  checkIsFiscal(paymentsEntry) {
    return paymentsEntry.some(p => (p.discountMode !== null || !p.enableDiscount));
  }

  interceptPrinterError(rr: ErrorCodeInterceptor) {
    let msg = this.translate.instant('DIALOG.FAILED_EMIT_TICKET');
    if (null != rr.errorTitle) {
      msg = rr.errorTitle + '\n';
    }
    msg += rr.errorDescription ? rr.errorDescription : '';
    this.sweetAlertService.danger(msg);
  }

  getGiftCardPayment() {
    let total = 0;
    this.selectedPayment.paymentsEntry.forEach(p => {
      if (p.paymentMode == PaymentMode.GIFT_CARD || p.paymentMode == PaymentMode.RELOAD_CARD) {
        total += (p.unitPricing * p.quantity) - p.totalDiscounted - p.total;
      }
    });
    return total;
  }
  get getSubscriptionPayment() {
    let total = 0;
      this.selectedPayment.paymentsEntry.forEach(p => {
        if (p.paymentMode == PaymentMode.SUBSCRIPTION) {
          total += p.totalDiscounted;
        }
      });
    return total;
  }
  getTotalPayment() {
    let total = 0;
    this.selectedPayment.paymentsEntry.forEach(p => {
      total += (p.unitPricing * p.quantity) - p.totalDiscounted;
    });
    return total;
  }

}

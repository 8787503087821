import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {   MatPaginator } from '@angular/material/paginator';
import {   MatSnackBar } from '@angular/material/snack-bar';
import {Treatment} from '../../../../shared/models/treatment';
import {CustomSnackBarComponent} from '../../../../shared/custom-snack-bar/custom-snack-bar.component';
import {TranslateService} from '@ngx-translate/core';
import {BreadcrumbService} from '../../../../core/services/breadcrumb.service';
import {SweetAlertService} from '../../../../shared/services/sweet-alert.service';
import {TreatmentFormService} from './services/treatment-form.service';
import {TreatmentMgmService} from '../../../../shared/services/treatment-mgm.service';
import {debounceTime} from 'rxjs/operators';
import {Abonnement} from '../../../../shared/models/Abonnement';

@Component({
  selector: 'app-treatment-management',
  templateUrl: './treatment-management.component.html',
  styleUrls: ['./treatment-management.component.scss']
})
export class TreatmentManagementComponent implements OnInit {

  public page = 1;
  public totalRecords: number;
  public pageSize = 10;
  loading = true;
  firstCall = true;
  rows: Treatment[] = [];
  searchFormControl = new UntypedFormControl(null);
  orderType = 'DESC';
  textSearch = null;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('treatmentFormContainer', {read: ViewContainerRef ,static: false}) treatmentFormContainer: ViewContainerRef;

  columns = [
    'PRODUCT_FORM.CODE',
    'TREATMENT_FORM.DESCRIPTION',
    'TREATMENT_FORM.CATEGORY',
    'TREATMENT_FORM.SUBCATEGORY'
  ];

  constructor(private translate: TranslateService,
              public snackBar: MatSnackBar,
              private treatmentService: TreatmentMgmService,
              private treatmentFormService: TreatmentFormService,
              private breadcrumbService: BreadcrumbService,
              private sweetAlertService: SweetAlertService) {
  }

  ngOnInit() {
    this.sendBreadCrumb();
    this.getLazyTreatment({page: this.page, pageSize: this.pageSize});
    this.searchFormControl.valueChanges.pipe(debounceTime(500)).subscribe(s => {
      this.textSearch = s;
      this.getLazyTreatment({page: 1, pageSize: this.pageSize, textSearch: s});
    });
  }

  sendBreadCrumb(): void {
    this.breadcrumbService.sendBreadcrumb(['PRODUCT_AND_SERVICES', 'TREATMENTS']);
  }

  pageChange(page: number) {
    this.page = page;
    this.getLazyTreatment({page, pageSize: this.pageSize, textSearch: this.textSearch});
  }

  getLazyTreatment(request, snackBarConf?) {
    this.loading = true;
    return this.treatmentService.getLazyTreatmentList(request).subscribe(data => {
      this.rows = data.data;
      this.loading = false;
      this.firstCall = false;
      this.totalRecords = data.totalRecords;
      if (snackBarConf) {
        this.showSnackBar(snackBarConf);
      }
    });
  }

  showSnackBar(data: any) {
    this.snackBar.openFromComponent(CustomSnackBarComponent, {
      data,
      duration: 5000,
      panelClass: 'white-snackbar'
    });
  }

  addNewTreatment() {
    const formRef = this.treatmentFormService.openForm(this.treatmentFormContainer,{
      editMode: false
    });
    formRef.instance.onClose.subscribe((d: any)  => {
      if (d) {
        this.getLazyTreatment({page: this.page, pageSize: this.pageSize, textSearch: this.textSearch}, {
          text: `${d.description} `,
          actionIcon: 'save',
          actionMsg: this.translate.instant('DIALOG.ADD_SUCCESS')
        });
      }
    });
  }

  editTreatment(data, i) {
    const formRef = this.treatmentFormService.openForm(this.treatmentFormContainer,
      {
        treatment: data,
        editMode: true
      });
    formRef.instance.onClose.subscribe((d: any) => {
      if (d) {
        this.getLazyTreatment({page: this.page, pageSize: this.pageSize, textSearch: this.textSearch}, {
          text: `${d.description} `,
          actionIcon: 'save',
          actionMsg: this.translate.instant('DIALOG.UPDATE_SUCCESS')
        });
      }
    });
  }
  delete($event, el: Treatment): void {
    $event.cancelBubble = true;
    $event.stopPropagation();
    this.sweetAlertService.warning(this.translate.instant('DIALOG.YOU_WANT_TO_DELETE') + ' ' + el.description)
      .then(res => {
        if (res.value) {
          this.treatmentService.delete(el.id).subscribe(r => {
            this.showSnackBar({
              text: `${el.description}`,
              actionIcon: 'delete',
              actionMsg: this.translate.instant('DIALOG.DELETE_SUCCESS')
            });
            this.getLazyTreatment({page: this.page, pageSize: this.pageSize, textSearch: this.textSearch});
          }, err => {
            if (err.status === 500) {
              this.showSnackBar({
                text: `${el.description}`,
                actionIcon: 'failed',
                actionMsg: this.translate.instant('DIALOG.CANNOT_DELETE')
              });
            }
          });
        }
      });
  }


  sortRows(c: string) {
    const sort = c.substr(15);
    console.log(sort);
    if (this.orderType === 'ASC') {
      this.orderType = 'DESC';
    } else {
      this.orderType = 'ASC';
    }
    switch (sort) {
      case 'ID':
        this.getLazyTreatment({page: this.page, pageSize: this.pageSize, textSearch: this.textSearch, sort: {
          attribute: 'id',
          direction: this.orderType
        }});
        break;
      case 'DESCRIPTION':
        this.getLazyTreatment({page: this.page, pageSize: this.pageSize, textSearch: this.textSearch, sort: {
          attribute: 'description',
          direction: this.orderType
        }});
        break;
      case 'CATEGORY':
        this.getLazyTreatment({page: this.page, pageSize: this.pageSize, textSearch: this.textSearch, sort: {
          attribute: 'category.name',
          direction: this.orderType
        }});
        break;
      case 'SUBCATEGORY':
        this.getLazyTreatment({page: this.page, pageSize: this.pageSize, textSearch: this.textSearch, sort: {
          attribute: 'subCategory.name',
          direction: this.orderType
        }});
        break;
    }
  }



}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MovementManagementComponent} from './movement-management.component';
import {MovementManagementFormComponent} from './movement-management-form/movement-management-form.component';
import {MatLegacyButtonModule} from '@angular/material/legacy-button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatLegacyDialogModule as MatLegacyDialogModule} from '@angular/material/legacy-dialog';
import {   MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import {   MatInputModule } from '@angular/material/input';
import {   MatListModule } from '@angular/material/list';
import {   MatPaginatorModule } from '@angular/material/paginator';
import {   MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRippleModule } from '@angular/material/core';
import {   MatSnackBarModule } from '@angular/material/snack-bar';
import {   MatTableModule } from '@angular/material/table';
import {TranslateModule} from '@ngx-translate/core';
import {NgbDropdownModule, NgbModule, NgbPaginationModule, NgbTypeaheadModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import {MovementFormService} from './service/movement-form.service';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {NgSelectModule} from '@ng-select/ng-select';
import {MovementProductFormComponent} from './movement-management-form/movement-product-form.component';
import {CurrencyMaskModule} from 'ng2-currency-mask';
import {SubheaderModule} from '../../../layout/components/subheader/subheader.module';
import {ProductManagementFormComponent} from '../../productsAndServices/product-management/product-management-form/product-management-form.component';
import {ProductManagementModule} from '../../productsAndServices/product-management/product-management.module';
import {ProductCreateModule} from '../../../../shared/product-create/product-create.module';
import {ProviderCreateModule} from '../../../../shared/provider-create/provider-create.module';
import {  MatSlideToggleModule} from '@angular/material/slide-toggle';

const routes: Routes = [
  {path: '', component: MovementManagementComponent}
];

@NgModule({
    declarations: [MovementManagementComponent, MovementManagementFormComponent, MovementProductFormComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatLegacyButtonModule,
        TranslateModule,
        NgbModule,
        ReactiveFormsModule,
        SubheaderModule,
        NgxDatatableModule,
        MatTableModule,
        MatListModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatRippleModule,
        NgbDropdownModule,
        MatSnackBarModule,
        MatLegacyDialogModule,
        NgbPaginationModule,
        MatProgressSpinnerModule,
        NgSelectModule,
        MatIconModule,
        CurrencyMaskModule,
        FormsModule,
        ProductCreateModule,
        ProviderCreateModule,
        NgbTypeaheadModule,
        MatSlideToggleModule
    ],
    providers: [
        MovementFormService
    ]
})
export class MovementManagementModule {
}

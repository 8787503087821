import {Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CenterService} from '../../../../../../shared/services/center.service';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Center} from '../../../../../../shared/models/center';
import {takeUntil} from 'rxjs/operators';
import {ZipCodeService} from '../../../../../../shared/services/zip-code.service';
import {ZipCode} from '../../../../../../shared/models/zip-code';
import {Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import Inputmask from 'inputmask';
import {TranslateService} from '@ngx-translate/core';
import {SweetAlertService} from '../../../../../../shared/services/sweet-alert.service';
import {CompanyBusinessService} from '../../../../../../shared/services/company-business.service';
import {  MatSnackBar} from "@angular/material/snack-bar";
// tslint:disable-next-line:max-line-length
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// tslint:disable-next-line:max-line-length
const patternFiscal = /^([A-Za-z]{6}[0-9lmnpqrstuvLMNPQRSTUV]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9lmnpqrstuvLMNPQRSTUV]{2}[A-Za-z]{1}[0-9lmnpqrstuvLMNPQRSTUV]{3}[A-Za-z]{1})$|([0-9]{11})$/;

@Component({
  selector: 'app-center-mgm-form',
  templateUrl: './center-mgm-form.component.html',
  styleUrls: ['./center-mgm-form.component.scss']
})
export class CenterMgmFormComponent implements OnInit, OnDestroy {
  @ViewChild('fiscalCodeElm', { static: false }) fiscalCodeElm: ElementRef;
  centerForm: UntypedFormGroup;
  prefixList: any;
  submitted = false;
  zipCodeLoading = false;
  zipCodeList: ZipCode[] = [];
  unsubscribe$ = new Subject();
  countryFormControl = new UntypedFormControl(null);
  provinceFormControl = new UntypedFormControl();
  cityFormControl = new UntypedFormControl();
  activities = [];

  constructor(
    private centerService: CenterService,
    private zipCodeService: ZipCodeService,
    private sweetAlertService: SweetAlertService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) private data: { editMode: boolean, center?: Center },
    private dialogRef: MatDialogRef<CenterMgmFormComponent>,
    private http: HttpClient,
    private companyBusinessService: CompanyBusinessService,
    private matSnackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.buildCenterForm();
    this.zipCodeManagement();
    this.companyBusinessService.getAllCompanyBusinesses().subscribe(data => {
      this.activities = data;
    });
    if (this.data.editMode) {
      this.centerForm.patchValue(this.data.center);
      this.centerForm.get('businessId').setValue(this.data.center.business ? this.data.center.business.id : null);
      this.centerForm.get('phonePrefix').setValue(this.data.center.phonePrefix || '+39');
      this.centerForm.get('mobilePrefix').setValue(this.data.center.mobilePrefix || '+39');
      this.centerForm.get('faxPrefix').setValue(this.data.center.faxPrefix || '+39');
      // this.centerForm.get('zipCodeId').setValue(this.data.center.zipCode ? this.data.center.zipCode.id : null);
      if (this.data.center.zipCode) {
        this.countryFormControl.setValue(this.data.center.zipCode ? this.data.center.zipCode.country : null);
        this.zipCodeService.getAllZipCodesByCountry(this.data.center.zipCode.country).subscribe(d => {
          this.zipCodeList = d;
          const zc = d.filter(e => e.id === this.data.center.zipCode.id)[0];
          this.centerForm.get('zipCodeId').enable();
          this.centerForm.get('zipCodeId').setValue(this.data.center.zipCode.id);
          this.setProvinceAndCity(zc);
        });
      }
    }
    this.http.get<any[]>('/assets/TEL_PREFIX.json').subscribe(d => this.prefixList = d.map(e => {
      e.label = `${e.country}: ${e.prefix}`;
      return e;
    }));
    Inputmask({regex: '([a-zA-Z0-9]{4})'});
  }


  private buildCenterForm() {
    this.centerForm = new UntypedFormGroup({
      id: new UntypedFormControl(),
      companyName: new UntypedFormControl(null, Validators.required),
      vatNumber: new UntypedFormControl(null, Validators.required),
      fiscalCode: new UntypedFormControl(null, Validators.pattern(patternFiscal)),
      sdiCode: new UntypedFormControl(null),
      rea: new UntypedFormControl(),
      address: new UntypedFormControl(null),
      street: new UntypedFormControl(null),
      zipCodeId: new UntypedFormControl(null),
      phone: new UntypedFormControl(null, [Validators.pattern('^[0-9]{8}[0-9]*')]),
      phonePrefix: new UntypedFormControl('+39'),
      mobile: new UntypedFormControl(null, [Validators.pattern('^[0-9]{8}[0-9]*')]),
      mobilePrefix: new UntypedFormControl('+39'),
      fax: new UntypedFormControl(null, [Validators.pattern('^[0-9]{8}[0-9]*')]),
      faxPrefix: new UntypedFormControl('+39'),
      email: new UntypedFormControl(null, Validators.pattern(EMAIL_REGEX)),
      contact: new UntypedFormControl(),
      website: new UntypedFormControl(null, [Validators.pattern(`^(www.)?[a-z0-9]+.[a-z]+$`)]),
      bank: new UntypedFormControl(),
      branchBank: new UntypedFormControl(),
      iban: new UntypedFormControl(),
      cin: new UntypedFormControl(),
      abi: new UntypedFormControl(),
      cab: new UntypedFormControl(),
      swift: new UntypedFormControl(),
      note: new UntypedFormControl(),
      businessId: new UntypedFormControl()
    });
    Object.keys(this.centerForm.controls).forEach(e => {
      if (this.centerForm.get(e).getError('required')) {
        const el = document.querySelector('[formControlName="' + e + '"]');
        el.classList.add('required-field');
      }
    });
  }

  private zipCodeManagement() {
    this.centerForm.get('zipCodeId').disable();
    this.provinceFormControl.disable();
    this.cityFormControl.disable();
    this.countryFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if (c && !this.countryFormControl.disabled) {
        this.centerForm.get('zipCodeId').enable();
        this.zipCodeLoading = true;
        this.zipCodeService.getAllZipCodesByCountry(c).subscribe(d => {
          this.zipCodeList = d;
          this.zipCodeLoading = false;
        });
      } else if (!c) {
        this.provinceFormControl.setValue(null);
        this.cityFormControl.setValue(null);
        this.centerForm.get('zipCodeId').setValue(null);
        this.centerForm.get('zipCodeId').disable();
      }
    });
    this.centerForm.get('zipCodeId').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(d => {
      this.provinceFormControl.setValue(null);
      this.cityFormControl.setValue(null);
      if (d && this.zipCodeList.length > 0) {
        const zc = this.zipCodeList.filter(e => e.id === d)[0];
        this.setProvinceAndCity(zc);
      }
    });
  }

  /**
   * zip code setup
   */

  private setProvinceAndCity(zc) {
    this.provinceFormControl.enable();
    this.cityFormControl.enable();
    this.provinceFormControl.setValue(zc.province);
    this.cityFormControl.setValue(zc.city);
    this.provinceFormControl.disable();
    this.cityFormControl.disable();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(void 0);
    this.unsubscribe$.unsubscribe();
  }

  saveCenter() {
    this.submitted = true;
    if (!this.centerForm.valid || !this.countryFormControl.valid) {
      this.sweetAlertService.notification(this.translate.instant('DIALOG.INSERT_ALL_MANDATORY_INFORMATION')).then(e => {
      });
      return;
    }
    if (!this.data.editMode) {
      this.centerService.addNewCenter(this.centerForm.value).subscribe(d => {
        this.dialogRef.close(d);
      }, err => {
        if (err.error.message.includes('uc_vat_number')) {
          this.matSnackBar.open(this.translate.instant('ADMIN.CENTER.VAT_NUMBER_USED'), 'Ok', {duration: 3000});
        }
      });
    } else {
      this.centerService.editCenter(this.centerForm.value).subscribe(d => {
        this.dialogRef.close(d);
      }, err => {
        if (err.error.message.includes('uc_vat_number')) {
          this.matSnackBar.open(this.translate.instant('ADMIN.CENTER.VAT_NUMBER_USED'), 'Ok', {duration: 3000});
        }
      });
    }
  }

  checkPIVA(): boolean {
    const pi = this.centerForm.get('vatNumber').value;
    let i: number;
    let c: number;
    let s: number;
    if (pi === null || pi.length === 0) {
      return false;
    }
    if (pi.length !== 11) {
      this.centerForm.controls.vatNumber.setErrors({invalid: true});
      return false;
    }
    for (i = 0; i < 11; i++) {
      if ((x => x.charCodeAt == null ? x : x.charCodeAt(0))(pi.charAt(i)) < '0'.charCodeAt(0) || (x => x.charCodeAt == null ?
        x : x.charCodeAt(0))(pi.charAt(i)) > '9'.charCodeAt(0)) {
        this.centerForm.controls.vatNumber.setErrors({invalid: true});
        return false;
      }
    }
    s = 0;
    for (i = 0; i <= 9; i += 2) {
      s += (x => x.charCodeAt == null ? x : x.charCodeAt(0))(pi.charAt(i)) - '0'.charCodeAt(0);
    }
    for (i = 1; i <= 9; i += 2) {
      c = 2 * ((x => x.charCodeAt == null ? x : x.charCodeAt(0))(pi.charAt(i)) - '0'.charCodeAt(0));
      if (c > 9) {
        c = c - 9;
      }
      s += c;
    }
    if ((10 - s % 10) % 10 !== (x => x.charCodeAt == null ? x : x.charCodeAt(0))(pi.charAt(10)) - '0'.charCodeAt(0)) {
      this.centerForm.controls.vatNumber.setErrors({invalid: true});
      return false;
    }
    return true;
  }

  confirmAndClose(): void {
    if (this.centerForm.dirty) {
       this.sweetAlertService.showConfirmationAlert()
        .then((confirmation) => {
          if (confirmation) {
            this.dialogRef.close(null);
          }
        });}
    else{
      this.dialogRef.close(null);
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.centerForm.dirty) {
      $event.returnValue = true;
    }
  }
}

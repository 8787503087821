export const locale = {
  lang: 'it',
  data: {
    UNAUTHORIZED_MSG: 'Non siete autorizzati ad accedere a questa pagina',
    HEADER: {
      OPERATOR_INFORMATIONS: 'Informazioni per l\'operatore',
      MEGA_MENU: 'sd',
      MANAGE_OPERATORS: 'Gestione degli operatori',
      MANAGE_ROLES: 'Gestione dei ruoli',
      CONFIGURATIONS: 'Configurazioni',
      HOME: 'Home',
      PROFILE: 'Profilo',
      TYPES_OF_ACTIVITIES: 'Tipologie attività',
      LICENSE_CONFIG: 'Configurazioni della licenza',
      CONFIG:'Configurazioni',
      MAIL_CONFIG: 'Configurazioni e-mail',
      WHAT_NEW: 'Cosa c\'è di nuovo',
      CALENDAR: 'Calendario',
      VANITY_CLIENT: 'Clienti App',
      BREADCRUMB: {
        DAYS_LEFT: 'giorni rimasti alla scadenza della licenza',
        CONTACT: 'Contatti',
        COMPANY: 'Azienda',
        CLIENT: 'Clienti',
        PROVIDER: 'Fornitori',
        OPERATORS: 'Staff',
        CONFIGURATION: 'Configurazione',
        IVA_CODE: 'Codice IVA',
        HOW_DID_YOU_MEET_US: 'Come ci hai conosciuti',
        PAYMENT_TYPE: 'Tipo pagamento',
        PREF_CONTACT: 'Contatto preferito',
        PROFESSION: 'Professione',
        PRODUCT_AND_SERVICES: 'Prodotti e servizi',
        PRODUCT: 'Prodotti',
        PRODUCTS: 'Prodotti',
        BRANDS: 'Marche',
        CATEGORIES: 'Categorie',
        CATEGORIES_PRODUCT: 'Categorie Prodotti',
        CATEGORIES_TREATMENT: 'Categorie Servizi',
        CABIN: 'Cabine',
        WEEK_PLANNING: 'Week Planning',
        OPERATORS_PLANNING: 'Operators Planning',
        TREATMENTS: 'Servizi',
        FIDELITY: 'Fidelity',
        SUBSCRIPTIONS: 'Abbonamenti',
        CHECK_DATA: 'Controlla i dati',
        PAYMENT: 'Vendita al banco',
        PAYMENT_HISTORY: 'Scontrini',
        WANT_TO_DELETE_WHOLE_RECEIPT: 'Desideri eliminare l\' intero scontrino?',
        PLANNING: 'Pianificazione',
        SMS: 'SMS',
        WhatsApp: 'WhatsApp',
        SMS_WhatsApp:'SMS & WhatsApp',
        COMPAIGNS: 'Campagna',
        MOVEMENT: 'Magazzino',
        MOVEMENT_CARICO: 'Carico Magazzino',
        MOVEMENT_SCARICO: 'Scarico Magazzino',
        INVENTORY: 'Inventario',
        MOVEMENT_AND_STOCK: 'Movimento e Giacenze',
        STOCK: 'Giacenze',
        RT: 'Cassa',
        HISTORY: 'Storico',
        DASHBOARD: 'Dashboard',
        PRODUCT_REPORT: 'Report dei prodotti',
        TREATMENT_REPORT: 'Report dei servizi',
        OPERATOR_REPORT: 'Report degli operatori',
        LINEAS: 'Lineas',
        QUOTE: 'Preventivi',
        QUOTE_HISTORY: 'Preventivi',
        FISCAL_REPORT: 'Report finanziario',
        FIRST_NOTE: 'Prima nota',
        FIRST_NOTE_CAUSE: 'Prima nota causa',
        SATISPAY_CONFIG: 'Configurazione Satispay',
        PROMOTION: 'Promozione',
        MOBILE_APP_CONFIG: 'Configurazione APP',
        TEMPLATE_PROMO: 'Template promo',
        SOCIAL_POSTS: 'Pubblicazioni sociali',
        TRANSFER: 'Trasferimento merce',
        BOOKING: 'Booking',
      }
    },
    SUBHEADER: {
      SEARCH: 'Ricerca',
    },
    ALERT_DIALOG: {
      ARE_YOU_SURE: 'Ne sei sicuro?',
      MSG: 'Stai tornando alla home page'
    },
    BUTTONS: {
      SAVE: 'Salva',
      EDIT: 'Modifica',
      CANCEL: 'Annulla',
      CLOSE: 'Chiudi',
      DELETE: 'Elimina',
      ADD: 'Aggiungi',
      EDIT_PASSWORD: 'Cambia la password',
      PAYMENT: 'Pagamento',
      MODIFY: 'Gestisci Punti',
      RETURN_TO_PAYMENT: 'Torna al Pagamento',
      GO_TO_PLANNING: 'Vai alla Planning',
      COPY: 'Copia',
      CUT: 'tagliare',
      CHOOSE: 'Scegli',
      PRINT: 'Stampa',
      PRINT_NO_FISCAL: 'Ristampa non fiscale',
      RETRY: 'Riprova stampa',
      GO_HOME: 'Vai alla pagina iniziale',
      ADD_SUPER_ADMIN: 'Aggiungi super admin',
      SCHEDULE: 'Programma',
      SAVE_WITHOUT_PRINT: 'Salva senza stampa',
      SAVE_WITH_PRINT: 'Salva con stampa',
      SAVE_DRAFT: 'Salva Bozza',
      HISTORY: 'Storico',
      PRINT_TICKET: 'Etichette',
      PRINT_PLANNING: 'Stampa planning',
      DOWNLOAD: 'Scaricare',
      ADD_TINT: 'Tinta',
      CLEAR: 'Hide',
      FACE_PLATES: 'Frontalini',
      NEXT: 'Seguente',
      PREVIOUS: 'Indietro ',
      ADD_OPERATOR: 'Aggiungi operatore',
      ADD_CABIN: 'Aggiungi Cabina',
      SEND: 'Invia a',
      ONLY_SEND: 'Invia',
      GO_TO: 'Vai a',
      EXPIRED: 'Scaduto',
      VALID: 'Valido',
      ENABLED: 'Attivo',
      DISABLED: 'Inattivo',
      SEARCH: 'Ricerca',
      RESET_FILTER: 'Reimposta Filtri',
      ACTIVE: 'Attivo',
      DEACTIVATED: 'Disattivato',
      CREAT: 'Crea',
      SEARCH_TICKET: 'Recupera',
      ANNUL_TICKET: 'Annullare ticket',
      APPLY_RESO: 'Reso',
      RESTORE: 'Ripristina',
      CONFIRM: 'Conferma',
      APPLY: 'Applica',
      CONTINUE: 'Continua',
      IMPORT_FROM_FISCAL_RT: 'Importa da stampante fiscale',
      QR_CODE: 'Codice Qr',
      NO: 'No',
      YES: 'Si'
    },
    LOADING: 'Caricamento',
    DATA_TABLE: {
      ID: 'ID',
      FIRST_NAME: 'Nome',
      LAST_NAME: 'Cognome',
      FULL_NAME: 'Nome e cognome',
      EMAIL: 'E-mail',
      GENDER: 'Sesso',
      MOBILE: 'Cellulare',
      NO_MARKETING: 'No marketing',
      COMPANY: 'Azienda',
      DESCRIPTION: 'Descrizione',
      BUSINESS_NAME: 'Ragione sociale',
      VAT_NUMBER: 'Partita IVA',
      CREATION_DATE: 'Data creazione',
      TAX_DESCRIPTION: 'Descrizione IVA',
      TAX_RATE: 'Aliquota IVA',
      CLIENTE: 'Cliente',
      HOW_DID_YOU_MEET_US: 'Come ci hai conosciuti',
      NO_CONFIGURATION_FOUND: 'Nessuna configurazione trovata',
      USERNAME: 'Utente',
      ROLE: 'Ruolo',
      BRAND_DESCRIPTION: 'Descrizione del marchio',
      CATEGORY_DESCRIPTION: 'Descrizione della categoria',
      LINEA_DESCRIPTION: 'Descrizione della linea',
      CATEGORY_NAME: 'Nome della categoria',
      CATEGORY_TYPE_TREATMENT: 'Categoria di tipo di servizio',
      CATEGORY_TYPE_PRODUCT: 'Categoria di tipo di prodotto',
      CABIN_DESCRIPTION: 'Descrizione Cabina',
      PLANNING_POSITION: 'Posizione colonna nel planning',
      PLANNING_COLOR: 'Colore da associare nel planning',
      DAY: 'Giorno',
      DAY_OFF: 'Chiuso il',
      FROM: 'Da',
      TO: 'Alle',
      PAUSE: 'Pausa',
      YEAR: 'Anno',
      SELL_POINTS: 'Punti vendita',
      PLANING_NAME: 'Nome Planning',
      NAME: 'Nome',
      SUBCATEGORY_NAME: 'Nome della tipologia',
      COLOR: 'Colore',
      SUBCATEGORY_DESCRIPTION: 'Descrizione del tipologia',
      THRESHOLD: 'Soglia',
      OTHER_THRESHOLD: 'Altra soglia',
      TREATMENT: 'Servizio',
      PRODUCT: 'Prodotto',
      VALUE: 'Valore',
      DISCOUNT: 'Sconto',
      TYPE: 'Tipo',
      SOLD: 'Saldo',
      NUMBER: 'Numero',
      DATE: 'Data',
      AMOUNT_PAID: 'Totale Pagato',
      REST: 'Residuo',
      SEQUENCE: 'Sequence',
      PRICE: 'Prezzo',
      HALO: 'Prezzo massimo',
      LALO: 'Prezzo minimo',
      VAT: 'IVA',
      GROUP_CODE: 'Gruppo Merceologico',
      CASH: 'Somma In Cassa',
      CREDIT: 'Credito',
      CHANGE: 'Resto Abilitato',
      DRAWER: 'Apertura Cassetto',
      IP: 'IP',
      MODIFICATION_DATE: 'Data di modifica',
      MODEL: 'Modello',
      STATUS: 'Stato',
      AMOUNT: 'quantità',
      TOTAL: 'Totale',
      ERROR: 'Errore',
      POINTS: 'Punti',
      OPERATOR: 'Operatore',
      DATE_FORM: 'gg/mm/aaaa',
      PLANING: 'Planning',
      QUANTITY: 'Quantità',
      CONSUMED: 'Consumato',
      REMAINING_QUANTITY: 'Quantità rimanente',
      USE_TYPE: 'Tipo di uso',
      PURCHASE_COST: 'Costo acquisto',
      PURCHASE_PRICE: 'P. Acq.',
      AVERAGE_COST: 'Costo medio',
      UNDER_ESCORT: 'Sotto scorta',
      PHOTO: 'Foto',
      COMMERCIAL_DESCRIPTION: 'Descrizione commerciale',
      BAR_CODE: 'Codice a barre',
      CATEGORY: 'Categoria',
      SUBCATEGORIES: 'Sottocategoria',
      PROVIDER: 'Provider',
      ML_GR: 'ml/gr',
      BRAND: 'Marca',
      LINEA: 'Linea',
      STOCK: 'Giacenze',
      ACTIVE: 'Attivo',
      CODE: 'Codice',
      IVA: 'IVA',
      TOTAL_PURCHASE_COST: 'Totale Costo Acquisto',
      CAUSAL: 'Causale',
      GIFT: 'Omaggi',
      SUBCATEGORIES_LIST: 'Sottocategorie',
      DOCUMENT: 'Documento',
      PURCHASE_DATE: 'Data acquisto',
      TINT: 'Tinta',
      CLIENT: 'cliente',
      DATE_AND_HOUR: 'Data e ora',
      MAX_CABINS: 'Cabine max',
      MAX_OPERATORS: 'Operatori max',
      MODULES: 'Moduli',
      CAUSE: 'Causale',
      F_AMOUNT: 'Importo',
      AVAILABLE: 'Disponibile',
      VALID: 'Validità',
      SALES: 'Vendita',
      PLANING_POSITION: 'Posizione Planning',
      RESO_QUANTITY: 'Reso - annullo Qtà',
      RESO_AMOUNT: 'Tot Reso - annullo ',
      TOTAL_IN_CASE: 'Totale in cassa',
      TOTAL_RENDER: 'Totale',
      RESO_TYPE: 'Tipo',
      ANNULMENT: 'Annullo scontrino ',
      RESO: 'Reso',
      SUBSCRIPTION: 'Abbonamento',
      CARD: 'Carta',
      REGISTER_SOURCE: 'Fonte',
      CONFIRMED: 'Confermato',
      NOT_CONFIRMED: 'Non Confermato',
      AMOUNTPAYED: 'Importo Pagato',
      GROUPS: 'Gruppi',
      SALES_PRICE: 'P. vendita',
      DEMO_RT: 'Modalità demo',
      FIRMWARE: 'Firmware',
      CREATED: 'Creato a',
      UPDATED: 'Data modifica',
      TITLE: 'Titolo',
    },
    SIDENAV: {
      CONTACTS: 'Contatti',
      REGISTER: 'Register',
      COMPANY: 'Azienda',
      CLIENT: 'Clienti',
      PROVIDER: 'Fornitori',
      CONFIGURATIONS: 'Configurazioni',
      PREF_CONTACT: 'Contatto preferito',
      PROD_SERVICE: 'Prodotti e servizi',
      PROFESSION: 'Professione',
      PAYMENT_TYPE: 'Tipo pagamento',
      COUNTRY: 'Nazione',
      HOW_DID_YOU_MEET_US: 'Come ci hai conosciuti',
      OPERATORS: 'Staff',
      VAT_CODE: 'Codice IVA',
      CLIENTE: 'Cliente',
      PRODUCT: 'Prodotti',
      BRAND: 'Marca',
      LINEA: 'Linea',
      CATEGORY_PRODUCT: 'Categoria di tipo di prodotto',
      CATEGORY_TREATMENT: 'Categoria del tipo di servizio',
      CABIN: 'Cabine',
      WEEK_PLANNING: 'Settimana lavorativa',
      OPERATORS_PLANNING: 'Operatori Planning',
      TREATMENT: 'Servizi',
      ADMINISTRATION: 'Amministrazione',
      FIDELITY: 'Fidelity',
      SUBSCRIPTION: 'Abbonamenti',
      PAYMENT: 'Vendita al banco',
      PAYMENT_HISTORY: 'Scontrini',
      RT: 'Stampanti telematiche',
      SMS_BASIC_CONFIG: 'Impostazione SMS',
      WHATS_APP_BASIC_CONFIG: 'Impostazione WhatsApp',
      SMS_WhatsApp_COMPAIGNS: 'Campagna SMS e WhatsApp',
      SMS_WhatsApp_INSTANCE: 'Storico SMS e WhatsApp',
      SMS_COMPAIGNS: 'Campagna SMS',
      SMS_INSTANCE: 'Storico SMS',
      MOVEMENT: 'Magazzino',
      CARICO: 'Carico ',
      SCARICO: 'Scarico ',
      INVENTORY: 'Inventario',
      MOVEMENT_AND_STOCK: 'Movimento e Giacenze',
      STOCK: 'Giacenze',
      DASHBOARD: 'Dashboard',
      PRODUCT_REPORT: 'Report dei prodotti',
      TREATMENT_REPORT: 'Report dei servizi',
      OPERATOR_REPORT: 'Report degli operatori',
      QUOTE: 'Preventivi',
      SALES: 'Vendite',
      FISCAL_REPORT: 'Report finanziario',
      FIRST_NOTE: 'Prima nota',
      FIRST_NOTE_CAUSE_SETTING: 'Configurazione causali',
      PROMOTION: 'Promozione',
      DOCUMENT_MANAGEMENT: 'Gestione Documenti',
      STATISTICS: 'Statistiche',
      SETUP_SELL_POINT: 'Setup centro',
      WHATS_APP: 'Whats App',
      SETUP_CLIENTS: 'Setup clienti',
      CASH_DESK: 'Cassa',
      PRINTERS: 'Stampanti',
      CASH_REGISTER_CLOSURE: 'Chiusura e lettura cassa',
      MOBILE_APP_CONFIG: 'Configurazione APP',
      ENTER_PROMOTION: 'Inserisci promozione',
      SOCIAL_POSTS: 'Pubblicazioni sociali',
      TRANSFER: 'Trasferimento merce'
    },
    CLIENT_FORM: {
      SAVE: 'Salva',
      EDIT: 'Modifica',
      ADD_CLIENT: 'Aggiungi cliente',
      CLIENT_DETAILS: 'Dettaglio Cliente',
      CLIENT_TYPE: 'Fascia cliente',
      STATUS_OF_INACTIVITY: 'Stato',
      DEPT: 'Debito',
      FIRST_PASSAGE: 'Primo Passaggio',
      LAST_PASSAGE: 'Ultimo passaggio',
      ACTIVE: 'Attivo',
      INACTIVE: 'Inattivo',
      DEACTIVATED: 'Disattivato',
      VISIBLE: 'Visibile',
      HIDDEN: 'Nascosto',
      STATE: 'Stato',
      GENDER: 'Sesso',
      MALE: 'Uomo',
      FEMALE: 'Donna',
      OTHER: 'Altro',
      FIRST_NAME: 'Nome',
      LAST_NAME: 'Cognome',
      DATE_OF_BIRTH: 'Data di nascita',
      FISCAL_CODE: 'Codice Fiscale',
      SDI_CODE: 'Codice SDI',
      CITY: 'Città',
      STREET: 'Civico',
      COUNTRY: 'Nazione',
      ZIP: 'CAP',
      ADDRESS: 'Indirizzo',
      PROVINCE: 'Provincia',
      MOBILE: 'Cellulare',
      WORK_PHONE: 'Lavoro',
      HOME_PHONE: 'Fisso',
      EMAIL: 'E-mail',
      PREF_CONTACT: 'Contattami con',
      PROFESSION: 'Professione',
      COMPANY: 'Azienda',
      PREF_PAYMENT: 'Pagamento preferito',
      MEET_INSTITUTE: 'Come ci hai conosciuti?',
      CIVIL_STATE: 'Stato civile',
      MARRIED: 'Coniugata/o',
      SONS: 'Componenti nucleo familiari',
      NO_MARKETING: 'No marketing',
      Marketing_Notification:'Autorizzazioni',
      NOTIFY_BY:'Notifica tramite',
      PRIVACY: 'Privacy',
      NO_APPOINTMENT_REMINDER: 'No promemoria appuntamento',
      NOTE: 'Nota',
      NO_CLIENT_FOUND: 'Nessun cliente trovato',
      GENDER_REQUIRED: 'Il sesso è obbligatorio',
      FIRST_NAME_REQUIRED: 'Il nome è obbligatorio',
      LAST_NAME_REQUIRED: 'Il cognome è obbligatorio',
      MOBILE_REQUIRED: 'Il cellulare è obbligatorio',
      DATE_FORMAT: 'gg/mm/aaaa',
      PROGRESS_CODE: 'Codice di avanzamento',
      FIDELITY: 'Fidelity',
      SUBSCRIPTION: 'Abbonamento',
      CHOOSE_CLIENT: 'Scegli cliente',
      SELECT_CLIENT: 'Seleziona un cliente',
      ANAMNESI: 'Anamnesi',
      TOTAL_PRODUCT_PURCHASE: 'Acq prodotti',
      TOTAL_PRODUCT_RESO: 'Reso/annul prodotti',
      TOTAL_TREATMENT_PURCHASE: 'Acquisto servizi',
      TOTAL_TREATMENT_RESO: 'Reso/annul servizi',
      TOTAL_PURCHASE: 'Acquisto totale',
      TOTAL_RESO: 'Reso/annul totale',
      PURCHASE_HISTORY: 'Storico acquisti',
      TOTAL_DISCOUNT: 'Sconto totale',
      TINT: 'Tinta',
      ADD_TINT: 'Aggiungi tinta',
      EDIT_TINT: 'Modifica tinta',
      MOBILE_INVALID: 'Il numero di telefono è errato',
      WORK_PHONE_REQUIRED: 'Il numero professionale è obbligatorio',
      HOME_PHONE_REQUIRED: 'Il numero di casa è obbligatorio',
      LEVEL1: 'Vip',
      LEVEL2: 'Ottimo',
      LEVEL3: 'Buono',
      LEVEL4: 'Abituale',
      LEVEL5: 'No classificazione',
      YEAR: 'Anno',
      PERIOD: 'Periodo',
      RANGE_AMOUNT: 'Totale',
      RANGE_LEVEL: 'Livello',
      NO_CLASSIFICATION_FOUND: 'Nessun classificazione trovato',
      SHOW_DISABLED_CLIENT: 'Disattivati',
      SHOW_NOT_CONFIRMED_CLIENT: 'Non confermati',
      PREFERRED_OPERATOR: 'Operatore preferito',
      LOTTERY: 'Codice Lotteria',
      NO_TREATMENT_FOUND: 'Nessun service trovato',
      SMS: 'SMS',
      USING_SMS: 'Clienti utilisato SMS',
      USING_WHATS_APP: 'Clienti utilisato Whats App',
      NO_WHATS_APP_CONFIG: 'Per utilizzare Whatsapp occorre possedere un account Whatsapp Business, contattaci per maggiori informazioni'
    },
    COMPANY_FORM: {
      ACTIVE: 'Attivo',
      DEACTIVATED: 'Disattivato',
      VISIBLE: 'Visibile',
      HIDDEN: 'Nascosto',
      SAVE: 'Salva',
      EDIT: 'Modifica',
      ADD_COMPANY: 'Aggiungi azienda',
      COMPANY_DETAILS: 'Dettagli azienda',
      BUSINESS_NAME: 'Ragione sociale',
      VAT_NUMBER: 'Partita IVA',
      FISCAL_CODE: 'Codice Fiscale',
      CODE_SDI: 'Codice SDI',
      REA: 'R.E.A',
      REGISTERED_OFFICE: 'Sede Legale',
      ADDRESS: 'Indirizzo',
      STREET_NUMBER: 'Civico',
      NATION: 'Nazione',
      POSTAL_CODE: 'CAP',
      LOCATION: 'Località',
      PROVINCE: 'Provincia',
      STREET: 'Civico',
      COUNTRY: 'Nazione',
      ZIP: 'CAP',
      OPERATING_OFFICE: 'Sede Operativa',
      PHONE: 'Telefono',
      MOBILE_PHONE: 'Cellulare',
      FAX: 'Fax',
      EMAIL: 'E-mail',
      EMAIL_PEC: 'Email Pec',
      REFERENT: 'Referente',
      WEB_SITE: 'Sito web',
      BANK: 'Banca',
      BANK_BRANCH: 'Filiale Banca',
      IBAN: 'IBAN',
      CIN: 'CIN',
      BIC_SWIFT: 'Bic/Swift',
      ABI: 'Abi',
      CAB: 'Cab',
      VAT: 'IVA',
      PAYMENT: 'Pagamento',
      NOTE: 'Nota',
      NO_COMPANY_FOUND: 'Nessun aziende trovato',
      BUSINESS_NAME_REQUIRED: 'La ragione sociale è obbligatoria',
      VAT_REQUIRED: 'Il numero di partita IVA è obbligatorio',
      FISCAL_CODE_REQUIRED: 'Il codice fiscale è obbligatorio',
      SDI_REQUIRED: 'Il codice SDI è obbligatorio',
      ADDRESS_REQUIRED: 'L\'indirizzo è obbligatorio',
      STREET_NUMBER_REQUIRED: 'Il numero civico è obbligatorio',
      LOCATION_REQUIRED: 'La località è obbligatoria',
      PROVINCE_REQUIRED: 'La provincia è obbligatoria',
      PROGRESS_CODE: 'Codice di avanzamento',
      CITY: 'Città'
    },
    PROVIDER_FORM: {
      ACTIVE: 'Attivo',
      DEACTIVATED: 'Disattivato',
      VISIBLE: 'Visibile',
      HIDDEN: 'Nascosto',
      SAVE: 'Salva',
      EDIT: 'Modifica',
      ADD_PROVIDER: 'Aggiungi fornitori',
      EDIT_PROVIDER: 'Modifica fornitori',
      PROVIDER_DETAILS: 'Dettagli fornitori',
      BUSINESS_NAME: 'Ragione sociale',
      VAT_NUMBER: 'Partita IVA',
      FISCAL_CODE: 'Codice Fiscale',
      CODE_SDI: 'Codice SDI',
      REA: 'R.E.A',
      REGISTERED_OFFICE: 'Sede Legale',
      ADDRESS: 'Indirizzo',
      STREET_NUMBER: 'Civico',
      NATION: 'Nazione',
      POSTAL_CODE: 'CAP',
      LOCATION: 'Località',
      PROVINCE: 'Provincia',
      CITY: 'Città',
      STREET: 'Civico',
      COUNTRY: 'Nazione',
      ZIP: 'CAP',
      OPERATING_OFFICE: 'Sede Operativa',
      PHONE: 'Telefono',
      MOBILE_PHONE: 'Cellulare',
      FAX: 'Fax',
      EMAIL: 'E-mail',
      EMAIL_PEC: 'Email Pec',
      REFERENT: 'Referente',
      WEB_SITE: 'Sito web',
      BANK: 'Banca',
      BANK_BRANCH: 'Filiale Banca',
      IBAN: 'IBAN',
      CIN: 'CIN',
      BIC_SWIFT: 'Bic/Swift',
      ABI: 'Abi',
      CAB: 'Cab',
      VAT: 'IVA',
      PAYMENT: 'Pagamento',
      NOTE: 'Nota',
      NO_PROVIDER_FOUND: 'Nessun fornitori trovato',
      BUSINESS_NAME_REQUIRED: 'La ragione sociale è obbligatoria',
      VAT_REQUIRED: 'Il numero di partita IVA è obbligatorio',
      FISCAL_CODE_REQUIRED: 'Il codice fiscale è obbligatorio',
      SDI_REQUIRED: 'Il codice SDI è obbligatorio',
      ADDRESS_REQUIRED: 'L\'indirizzo è obbligatorio',
      STREET_NUMBER_REQUIRED: 'Il numero civico è obbligatorio',
      COUNTRY_REQUITED: 'Il nazione è obbligatorio',
      CAP_REQUIRED: 'Il cap è obbligatorio',
      LOCATION_REQUIRED: 'La località è obbligatoria',
      PROVINCE_REQUIRED: 'La provincia è obbligatoria',
      PROGRESS_CODE: 'Codice di avanzamento'
    },
    OPERATOR_FORM: {
      ACTIVE: 'Attivo',
      DEACTIVATED: 'Disattivato',
      SAVE: 'Salva',
      EDIT: 'Modifica',
      ADD_OPERATOR: 'Aggiungi operatore',
      OPERATOR_DETAILS: 'Dettagli operatori',
      VAT_NUMBER: 'Partita IVA',
      FISCAL_CODE: 'Codice Fiscale',
      STATE: 'Stato',
      ROLE: 'Ruolo',
      FIRST_NAME: 'Nome',
      LAST_NAME: 'Cognome',
      BIRTH_DATE: 'Data di nascita',
      USERNAME: 'Utente',
      OLD_PASSWORD: 'Vecchia password',
      NEW_PASSWORD: 'Nuova password',
      PASSWORD: 'Password',
      CONFIRM_PASSWORD: 'Conferma Password',
      BAR_CODE: 'Codice a barre',
      MOBILE: 'Cellulare',
      CITY: 'Indirizzo',
      STREET: 'Civico',
      COUNTRY: 'Nazione',
      ZIP: 'CAP',
      ADDRESS: 'Località',
      PROVINCE: 'Provincia',
      PLANING_NAME: 'Nome Planning',
      PLANING_POSITION: 'Posizione Planning',
      PLANING_COLOR: 'Colore Planning',
      IS_REQUIRED: 'Campo obbligatorio',
      MUST_MATCH: 'Le password devono coincidere',
      PHONE: 'Telefono',
      MOBILE_PHONE: 'Cellulare',
      EMAIL: 'E-mail',
      PROGRESS_CODE: 'Codice di avanzamento',
      NO_OPERATOR_FOUND: 'Nessun operatore trovato',
      ADD_NEW_OPERATOR: 'Aggiunti un nuovo operatore',
      EDIT_OPERATOR: 'Modifica operatore',
      VISIBLE: 'Visibile',
      HIDDEN: 'Nascosto',
      SELL_POINT: 'Punto vendita',
      PLANNING: 'Pianificazione',
      IS_INVALID_FISCAL: 'Codice Fiscale non valido',
      IS_INVALID_MAIL: 'E-mail non valido'
    },
    CONFIGURATION: {
      NEW_CONTACT: 'Aggiungi un nuovo contatto preferito',
      NEW_CONTACT_DESCRIPTION: 'Descrizione del contatto preferito',
      NEW_PAYMENT_TYPE: 'Aggiungi un nuovo tipo di pagamento',
      NEW_PAYMENT_TYPE_DESCRIPTION: 'Descrizione del tipo pagamento',
      NEW_PROFESSION: 'Aggiungi una nuova professione',
      NEW_PROFESSION_DESCRIPTION: 'Descrizione professione',
      NEW_IVA_CODE: 'Aggiungi una nuova aliquota',
      TVA_DESCRIPTION: 'Descrizione IVA',
      TVA_RATE: 'Aliquota IVA',
      HOW_DID_YOU_MEET_US_: 'Come ci hai conosciuto',
      CLOSE: 'Chiudi',
      SAVE: 'Salva',
      ADD_NEW_ELEMENT: 'Creazione multipla',
      ATTACH_COLOR_TO_ALL_PRODUCTS: 'Associa il colore a tutti prodotti',
      EDIT_CONTACT: 'Modifica il contatto preferito',
      EDIT_PAYMENT: 'Modifica il tipo pagamento',
      EDIT_PROFESSION: 'Modifica professione',
      EDIT_IVA_CODE: 'Modifica imposta',
      EMPTY_DESCRIPTION: 'La descrizione non può essere vuota',
      EMPTY_RATE: 'Il valore dell\'aliquota TVA non può essere vuoto',
      NEW_BRAND: 'Nuova marca',
      EDIT_BRAND: 'Modifica marchio',
      BRAND_DESCRIPTION: 'Descrizione del marchio',
      NEW_LINEA: 'Nuova linea',
      EDIT_LINEA: 'Modifica linea',
      LINEA_DESCRIPTION: 'Descrizione di línea',
      CATEGORY_NAME: 'Nome della categoria',
      EDIT_CATEGORY: 'Modifica la categoria',
      NEW_CATEGORY: 'Nuova categoria',
      CATEGORY_DESCRIPTION: 'Descrizione della categoria',
      EMPTY_NAME: 'Il nome non deve essere vuoto',
      EMPTY_TYPE: 'Il tipo non deve essere vuoto',
      SUB_CATEGORY_NAME: 'Nome della sottocategoria',
      SUB_CATEGORY_DESCRIPTION: 'Descrizione della sottocategoria',
      SUB_CATEGORY_TYPE: 'Il tipo di sottocategoria',
      NEW_CABIN: 'Aggiungi una nuova cabina',
      EDIT_CABIN: 'Modifica il cabina',
      CABIN_DESCRIPTION: 'Descrizione Cabina',
      PLANNING_POSITION: 'Posizione colonna nel planning',
      PLANNING_COLOR: 'Colore da associare nel planning',
      EDIT: 'Modifica',
      DELETE: 'Elimina',
      SUBCATEGORIES: 'Sottocategoria',
      ADD_SUBCATEGORIES: 'Aggiungi una sottocategoria',
      NEW_SUBCATEGORY: 'Nuova sottocategoria',
      EDIT_SUBCATEGORY: 'Modifica sottocategoria',
      PLANNING_YEAR: 'Anno',
      NEW_PLANNING_YEAR: 'Aggiungi un nuovo planning',
      EDIT_PLANNING_YEAR: 'Modifica il planning',
      NAME: 'Nome',
      DESCRIPTION: 'Descrizione',
      CABIN_LIST: '',
      NO_CABIN_FOUND: '',
      NO: 'No',
      APPLY_CATEGORY_COLOR_TO_ALL_PRODUCTS: 'Vuoi applicare il colore a tutti i prodotti della categoria?'
    },
    DIALOG: {
      Back_to_online:'Adesso sei connesso ad internet',
      Connection_lost :'Connessione internet non disponibile. Per favore verifica',
      NOTE_TO_BE_SOON: 'Sarà disponibile a breve',
      YOU_WANT_TO_SHOW: 'Sei sicuro di voler mostrare ',
      YOU_WANT_TO_ACTIVE: 'Sei sicuro di voler attivare ',
      YOU_WANT_TO_HIDE: 'Sei sicuro di voler nascondere ',
      YOU_WANT_TO_DESACTIVE: 'Sei sicuro di voler disattivare',
      YOU_WANT_TO_HIDE_BOOKING: 'Attenzione se procedi l\'appuntamento scompare definitivamente dal planning ma eventuali sms saranno ' +
        'comunque inviati al cliente. Se desideri cancellarlo definitivamente procedi con funzione elimina',
      YOU_WANT_TO_DELETE: 'Sei sicuro di voler eliminare ',
      YOU_WANT_TO_DELETE_LINE: 'Sei sicuro di voler eliminare questa riga',
      UPDATE_SUCCESS: 'Aggiornato con successo',
      CANNOT_UPDATE: 'Impossibile aggiornare',
      CANNOT_UPDATE_SUBSCRIPTION: 'Impossibile modificare un abbonamento precedentemente venduto',
      ADD_SUCCESS: 'Aggiunto con successo',
      SAVE_SUCCESS: 'Salva con successo',
      DELETE_SUCCESS: 'Eliminare con successo',
      YOU_WANT_DECAL_CABIN: 'La posizione indicata è già occupata, nel planning, da un\'altra cabina, procedere con l\'inserimento?\n' +
        'ATTENZIONE, le altre cabine verranno scalate di una posizione!',
      THIS_PLANNING_YEAR_ALREADY_EXIST: 'Questo planning è già esistente',
      THIS_POSITION_IS_ALREADY_TOKEN: 'Questa posizione è già stata assegnata',
      YOU_MUST_FILL_ALL_THE_PLANNING: 'Inserisci tutti i planning',
      NO_AVAILABLE_THRESHOLDS: 'Nessun omaggio disponibile',
      NO_AVAILABLE_CARD: 'Nessuna carta associata',
      YOU_WANT_TO_SYNCHRONIZE_RT: 'Sei sicuro di voler sincronizzare questa RT? Dovrai riconfigurare IVA e Reparti',
      SUCCESS_EMIT_TICKET: 'Scontrino Stampato con Successo',
      SUCCESS_PRINT_TEST: 'Prova di Stampa di Successo',
      NO_IVA_FOR_TREATMENT: 'Attenzione iva mancante per il servizio selezionato',
      NO_IVA_FOR_PRODUCT: 'Attenzione iva mancante per il prodotto selezionato',
      NO_PAYMENT_RT_CONFIG: 'Nessuna configurazione pagamento',
      NO_TREATMENT_RT_IVA_CONFIG: 'Attenzione Nessuna configurazione iva rt',
      NO_PRODUCT_IVA_RT_CONFIG: 'Attenzione Nessuna configurazione iva rt',
      NO_RT_CONFIG: 'Nessuna configurazione RT',
      YOU_WANT_TO_CLOSE_CASH: 'Desideri procedere con la chiusura cassa?',
      YOU_WANT_TO_READ_CASH: 'Vuoi lettura giornaliera',
      CLOSING_SUCCESS: 'Chiusura avvenuta con successo',
      CLOSING_FAILURE: 'Errore chiusura',
      READING_SUCCESS: 'Lettura avvenuta con successo',
      READING_FAILURE: 'Impossibile effettuare la lettura',
      CANNOT_DELETE: 'Attenzione non è possibile cancellare il record selezionato in quanto di già utilizzato ',
      FAILED_EMIT_TICKET: 'Impossibile stampare la ricevuta',
      CLOSE_CASH_REGISTER: 'Attenzione, effettua la chiusura cassa',
      PAYMENT_FAILED: 'Stampa fallita',
      PAYMENT_FAILED_PRINT_STATUS: 'Attenzione, controllare lo stato della stampante',
      RECEIPT_NOT_SENT: 'scontrino non inviata',
      DELETE_SUBSCRIPTION: 'Vuoi eliminare l\'abbonamento',
      YOU_MUST_ADD_PRODUCT: 'Aggiungi prodotto',
      INSERT_ALL_MANDATORY_INFORMATION: 'Per favore, inserisci tutti i dati obbligatori',
      FAILED_TO_CONNECT_TO_PRINT: 'Impossibile connettersi alla stampante',
      INTERNAL_SERVER_ERROR: 'Errore interno del server! Riprovare più tardi',
      NO_INFORMATION_FOUND: 'Nessuna informazione trovata',
      ATTENTION_WEEK_PLANNING_CHANGED: 'Attenzione, stai per modificare il week planning. Per rendere le modifiche effettive sui calendari'
        + ' degli operatori e cabine devi riassegnare il week planning',
      YOU_WANT_TO_ENABLE_SALES_WITHOUT_PRINT: 'Desideri attivare la vendita senza misuratore fiscale?',
      YOU_WANT_TO_DISABLE_SALES_WITHOUT_PRINT: 'Desideri disabilitare la modalità di vendita senza contatore delle tasse ?',
      PRICE_CANNOT_BE_ZERO: 'Attenzione, non è possibile inserire un prezzo inferiore o uguale a zero',
      YOU_WANT_TO_CLEAR_TEMPORARY_DATA: 'Desideri cancellare tutti i dati temporanei?',
      YOU_WANT_TO_REVOKE: 'Ritorna in modalità operatore',
      SUCCESS_EMIT_QUOTE: 'Preventivo emesso con successo',
      FAILED_EMIT_QUOTE: 'Impossibile stampare il preventivo',
      FAILED_EMIT_RESO: 'Impossibile stampare il reso',
      NO_EAN_FOR_THIS_OPERATOR: 'L\'operatore non possiede un codice ean, per favore assicurati di averlo generato',
      NON_EXISTENT_PRODUCT: 'Articolo non esistente',
      NON_CONFIGURED_PRODUCT: 'Articolo non configurato',
      INVALID_PASSWORD: 'Password non valida',
      NOT_ENOUGH_CREDIT: 'Attenzione, il credito sulla carta fidelity selezionata non è sufficiente per l\'intero importo di ' +
        'conseguenza verra\' scalato l\'importo disponibile e non il totale da pagare',
      NO_CREDIT: 'Attenzione, la carta fedeltà selezionata non ha credito!',
      RANGE_FORM_INVALID: 'Impossibile salvare in quanto uno o più valori inseriti non sono coerenti',
      LICENSE_CONFIG: 'Configurazione licenze',
      SELECT_ARTICLE_TO_GENERATE_DRAFT_MOVEMENT: 'Seleziona le spunte degli articoli per i quali desideri generare un movimento bozza',
      SUCCESS_CREAT_DRAFT_MOVEMENT: 'Il successo crea una bozza di movimento',
      YOU_WANT_TO_RESET: 'Attenzione, desideri procedere con la cancellazione definitiva dei dati?',
      ARE_YOU_SURE_TO_ANNUL_TICKET: 'Desideri annullare il pagamento?',
      CANNOT_FIND_PAYMENT: 'Pagamento non trovato',
      ARE_YOU_SURE_TO_RETURN_ENTRIES: 'Desideri effettuare il reso del pagamento?',
      YOU_WANT_TO_ENABLE: 'Sei sicuro di voler attivare ',
      YOU_WANT_TO_DISABLE: 'Sei sicuro di voler disabilitare ',
      YOU_MUST_SELECT_CLIENTS: 'devi selezionare i destinatari',
      YOU_MUST_SELECT_METHOD_PROMO: 'devi selezione almeno un metodo di invio della promozione (SMS, EMAIL, Facebook, Instagram)',
      INFO_TEMPLATE_PROMO: "Questo campo viene utilizzato nel caso dell'invio di un'email (viene preso in considerazione testo e immagine del modello) e nel caso di una pubblicazione Su Facebook e Instagram (viene presa in considerazione soltanto l'immagine)",
      INFO_SUBJECT: "Questo campo è utilizzato nel caso dell'invio di un'email e rappresenta l'oggetto dell'email",
      INFO_MESSAGE: "Questo campo è utilizzato nel caso dell'invio di un SMS. Rappresenta il testo del messaggio. Nel caso di Facebook e Instagram rappresenta la parte testuale del post.",
      INFO_DATE_HOUR: "Per tutte le tipologie di pubblicazione è possibile configurare la data e ora invio",
      INFO_DESTINATION: "Questo campo consente di specificare uno o più destinatari di un'email o di un sms",
      INFO_SMS: "Per creare una promozione sms vai su Marketing, seleziona la voce Promo SMS & WhatsApp e clicca su Campagna SMS & WhatsApp",
      YOU_WANT_TO_REACTIVE: 'Sei sicuro di voler riattivare ',
      YOU_WANT_TO_ANNUL: 'Sei sicuro di voler annulare ',
      YOU_MUST_SELECT_ELEMENTS: 'Deve selezionare elemento',
      ELEMENT_MUST_HAVE_THE_SAME_IVA: 'Attenzione puoi effettuare un acconto soltanto con prodotti o servizi aventi lo stesso codice IVA',
      SELECT_ALL_ELEMENT_WITH_IVA: 'Seleziona tutto l\'elemento con iva ',
      SUCCESS_SEND_RESET_PASSWORD: 'Invio email reset password effettuato con successo',
      FAILED_SEND_RESET_PASSWORD: 'Invio email rest password fallito',
      INVALID_MAIL: 'La email non è valida',
      CONFIRM_CLIENT: 'Sei sicuro di voler confermare il cliente ',
      SUCCESS_CONFIRM: 'Conferma cliente avvenuto con successo',
      FAILED_CONFIRM: 'Impossibile confermare Il cliente',
      ATTENTION_REQUIRED_EXCEL_FIELDS: 'Fai attenzione, assicurati di aver creato le tabelle secondarie come categorie, marchi, IVA, ecc.',
      ATTENTION_REQUIRED_CLIENT_EXCEL_FIELDS: 'Fai attenzione, assicurati di aver creato le tabelle secondarie ' +
        'come azienda, Professione, ecc.',
      CANNOT_IMPORT_PRODUCTS_CHECK_YOUR_FILE: 'Errore durante il caricamento dei prodotti! Per favore controlla il tuo file ',
      SUCCESS_CREAT_PRODUCTS: 'Aggiungi prodotti con successo',
      SUCCESS_CREAT_CLIENTS: 'Aggiungi clienti con successo',
      INVALID_LOTTERY: 'Codice lotteria invalido',
      CORRECT_LOTTERY: 'Codice lotteria inserito correttamente',
      NO_PRODUCT_INSERTED: 'Nessun articolo inserito nel documento',
      ATTENTION_REQUIRED_TREATMENTS_EXCEL_FIELDS: 'Fai attenzione, assicurati di aver creato le tabelle secondarie ' +
        'come categorie, linea, IVA, ecc.',
      SUCCESS_CREAT_TREATMENTS: 'Aggiungi Servizi con successo',
      ATTENTION: 'Attenzione',
      PLANNING_COMPLETE: 'Vuoi chiudere l\'appuntamento?',
      ATTENTION_INVALID_PLANNING: 'Attenzione, non è stato possibile ripristinare alcune settimane',
      INVALID_LOTTERY_EMPTY: 'Codice lotteria non può essere vuoto',
      INVALID_LOTTERY_NOT_EIGHT_CHARS: 'Codice lotteria deve essere di 8 caratteri',
      INVALID_LOTTERY_INVALID_CHARS: 'Codice lotteria non permette caratteri speciali',
      YOU_WANT_TO_DELETE_SELECTED_MVM: 'Sei sicuro di voler cancellare il movimento selezionato?',
      YOU_WANT_TO_DISPLAY_TUTTI_PDT: 'Desideri caricare la giacenza attuale di tutti i prodotti?',
      INVALID_FILE_TYPE: 'Tipo di file non valido',
      MAILING_ERROR: 'Server invio email non funzionante. Contatta l\'Amministratore',
      SUCCESS_SEND_NOTIFICATION: 'Notifica app inviata con successo al Cliente',
      FAILED_SEND_NOTIFICATION: 'Problema con l\'invio della notifica app al Cliente. L\'appuntamento è stato comunque creato con successo',
      ATTENTION_RT_DEMO_MODE: 'Attenzione! La stampante RT è in modalità Demo',
      ERROR_IN_PRINT_CONTACT_ASSISTANCE: 'Attenzione, non è stato possibile contattare correttament la stampante, per favore riavviala. Sel problema persiste contatta l\'Assistenza',
      USED_EMAIL_OR_MOBILE_PHONE: 'il E-mail / Cellulare è già in uso per per i seguenti clienti : ',
      UPDATE_VERSION_MSG: 'E\' stato effettuato un aggiornamento, la pagina verrà ricaricata fra 5 secondi',
      UPDATE_VERSION: 'Nuova versione',
      CLIENT_WITH_SAME_EMAIL_PHONE: 'Clienti con lo stesso indirizzo email/cellulare',
      NO_IVA_FOR_CARD: 'Per poter utilizzare in un pagamento una GIFT CARD o PREPAGATA occorre configurare correttamente la stampante nella sezione configurazioni di GAB',
      NO_CARD_RT_IVA_CONFIG: 'Per poter effettuare una vendita di una GIFT CARD o PREPAGATA occorre configurare correttamente la stampante nella sezione configurazioni di GAB',
      NO_IVA_FOR_SUBSCRIPTION: 'Per poter utilizzare in un pagamento un ABBONAMENTO occorre configurare correttamente la stampante nella sezione configurazioni di GAB',
      NO_SUBSCRIPTION_RT_IVA_CONFIG: 'Per poter effettuare una vendita di un ABBONAMENTO occorre configurare correttamente la stampante nella sezione configurazioni di GAB',
    },
    PRODUCT_FORM: {
      PROD_CODE: 'Codice articolo',
      CODE: 'Codice',
      ID: 'ID',
      NO_PRODUCT_FOUND: 'Nessun prodotto trovato',
      ADD_PRODUCT: 'Aggiungi prodotto',
      PRODUCT_DETAILS: 'Dettaglio del prodotto',
      SAVE: 'Salva',
      EDIT: 'Modifica',
      PROGRESS_CODE: 'Codice',
      CREATION_DATE: 'Data di inserimento',
      BAR_CODE: 'Codice a barre',
      DESCRIPTION: 'Descrizione',
      DESCRIPTION_REQUIRED: 'Descrizione richiesta',
      COMMERCIAL_DESCRIPTION: 'Descrizione  commerciale',
      CATEGORY: 'Categoria',
      CATEGORY_REQUIRED: 'Categoria richiesta',
      USE_TYPE_REQUIRED: 'Tipo uso richiesta',
      BARCODE_REQUIRED: 'Codice a barre richiesta',
      SUBCATEGORY: 'Sotto-categoria',
      BRAND: 'Marchio',
      LINEA: 'Linea',
      PROVIDER: 'Fornitore',
      USE_TYPE: 'Tipo uso',
      MEASURE_UNIT: 'Unità di misura',
      TAX: 'IVA',
      MIN_STOCK: 'Sottoscorta',
      PURCHASE_COST: 'Costo di acquisto',
      RECHARGE: 'Ricarica',
      PRICE: 'Prezzo',
      PRICE_PDV: 'Prezzo PDV',
      NOTE: 'Note prodotto',
      CANCEL: 'Cancella',
      MEDUIM_COST: 'Costo medio',
      LAST_LOADING_COST: 'Ultimo costo di carico',
      LAST_SALE_PRICE: 'Ultimo prezzo di vendita',
      STOCK: 'Giacenza',
      UNIT: 'Unità',
      INTERNAL_USE: 'Uso interno',
      IN_SALE: 'Vendita',
      INTERNAL_USE_OR_IN_SALE: 'Uso interno e Vendita',
      BARCODE_INCORRECT: 'Codice a barre deve essere valido',
      ACTIVATE_DETAILS_SALE: 'Prodotto attivo',
      WEIGHT_REQUIRED: 'Peso richiesto',
      UNIT_REQUIRED: 'Unità richiesta',
      ML: 'Ml',
      GR: 'Gr',
      QUANTITY: 'Peso',
      DESCRIPTION_PLU: 'Descrizione PLU',
      NEW_STOCK: 'Nuova Giacenza',
      OLD_STOCK: 'Giac. Precedente',
      ACTIVE: 'Attivo',
      LAST_PRICE: 'Ultimo prezzo di vendita',
      LAST_COST: 'Ultimo costo di carico',
      MEDIUM_COST: 'Costo medio',
      PRINT_TICKET: 'Stampa Etichette',
      PRINT_QUANTITY: 'Quantità da stampare',
      VALID_BARCODE: 'Codice a barre valido',
      INVALID_BARCODE: 'Codice a barre non valido',
      UNIQUE_BARCODE: 'Codice a barre univoco',
      NON_UNIQUE_BARCODE: 'Codice già utilizzato',
      PLU_REQUIRED: 'Codice obbligatorio',
      VALID_PLU: 'Codice valido',
      INVALID_PLU: 'Codice non valido',
      EXISTING_PLU: 'Codice già associato a un altro prodotto',
      UNIQUE_PLU: 'Codice univoco',
      NON_UNIQUE_PLU: 'Codice non univoco',
      ONE_PER_PAGE: 'Modulo Continuo 40 x 27 Termico Zebra',
      BARCODE_EMPTY: 'Barcode vuoti',
      CONTINUOUS_63_37: 'Modulo Continuo 63 x 37 Termico Zebra',
      CONTINUOUS_40_30: 'Modulo Continuo 40 * 30 Termico Zebra',
      A4_62_32: 'Modulo A4 con griglia 62 * 32',
      LIST: 'Modulo A4 Etichette Buffetti 47,5 x 35',
      ETIQUETTE_TYPE: 'Tipo etichetta',
      PRINT_FACE_PLATES: 'Stampa frontalini',
      STOCK_ALERT: 'Stock alert Prodotti',
      SHOW_STOCK_ALERT_LIST: 'Mostra articoli in stock alert',
      MIN_STOCK_INFO: 'Se il campo viene lasciato vuoto non sarà preso in considerazione dal software per il controllo del sottoscorta',
      INCLUDE_ACTIVATE_DETAILS_SALE: 'Visualizza prodotti disattivati',
      PRODUCT_DISABLED: 'Pr. disattivato',
      ERROR_MAX_DISCOUNT: 'Lo sconto non può essere maggiore dell\' importo del prodotto',
      PROD_ALREADY_EXIST: 'Prodotti già inserito. Desideri procedere?',
      PRODUCT_NOT_FOUND_ADD_IT: 'Nessun prodotto esistente. Desideri crearlo?',
      NOT_CODE_TO_PRINT: 'Nessun codice a barre da stampare',
      PRINT_FROM: 'Stampa da',
    },
    ADMIN: {
      CENTER: {
        LIST: 'Lista dei centri',
        ADD: 'Aggiungi',
        ADD_NEW: 'Aggiungi nuovo centro',
        SAVE: 'Salva',
        EDIT: 'Modifica',
        CLOSE: 'Chiudi',
        DELETE: 'Elimina',
        INFO: 'Info',
        COMPANY_NAME: 'Ragione sociale',
        VAT_NUMBER: 'Partita IVA',
        FISCAL_CODE: 'Codice Fiscale',
        CODE_SDI: 'Codice SDI',
        REA: 'R.E.A',
        ADDRESS: 'Indirizzo',
        STREET_NUMBER: 'Civico',
        COUNTRY: 'Nazione',
        ZIP_CODE: 'CAP',
        CITY: 'Città',
        PROVINCE: 'Provincia',
        CONTACT: 'Contatto',
        PHONE: 'Telefono',
        MOBILE_PHONE: 'Cellulare',
        FAX: 'Fax',
        EMAIL: 'E-mail',
        REFERENT: 'Referente',
        WEB_SITE: 'Sito web',
        BANK: 'Banca',
        BANK_BRANCH: 'Filiale Banca',
        IBAN: 'IBAN',
        CIN: 'CIN',
        BIC_SWIFT: 'Bic/Swift',
        ABI: 'Abi',
        CAB: 'Cab',
        NOTE: 'Nota',
        NO_CENTER_FOUND: 'Nessun centro trovato',
        GO_TO: 'Vai a',
        COMPANY_NAME_REQUIRED: 'La ragione sociale è obbligatoria',
        VAT_REQUIRED: 'Il numero di partita IVA è obbligatorio',
        VAT_INVALID: 'Il numero di partita IVA non è valido',
        FISCAL_CODE_REQUIRED: 'Il codice fiscale è obbligatorio',
        SDI_REQUIRED: 'Il codice SDI è obbligatorio',
        ADDRESS_REQUIRED: 'L\'indirizzo è obbligatorio',
        STREET_NUMBER_REQUIRED: 'Il numero civico è obbligatorio',
        COUNTRY_REQUIRED: 'Il nazione è obbligatorio',
        CAP_REQUIRED: 'Il CAP è obbligatorio',
        LOCATION_REQUIRED: 'La località è obbligatoria',
        PROVINCE_REQUIRED: 'La provincia è obbligatoria',
        PROGRESS_CODE: 'Codice di avanzamento',
        TYPE_OF_ACTIVITY: 'Tipo di attività',
        VAT_NUMBER_USED: 'Attenzione, la Partita Iva è già in uso.'
      },
      SELL_POINT: {
        LIST: 'Lista dei punti vendita',
        ADD: 'Aggiungi',
        NO_SELL_POINT: 'Nessun punto di vendita, fare clic su [aggiungi] per aggiungere un nuovo punto vendita a ',
        OPENING_HOUR: 'Orario iniziale',
        FINAL_HOUR: 'Orario Finale',
        REFERENT: 'Referente',
        MOBILE: 'Cellulare',
        ADD_NEW: 'Aggiungere un nuovo punto vendita a',
        EDIT: 'Modifica',
        INFO: 'Info',
        NAME: 'Nome',
        PLANNING_TYPE: 'Tipo di pianificazione',
        ADDRESS: 'Indirizzo',
        STREET: 'Numero civico',
        COUNTRY: 'Nazione',
        ZIP_CODE: 'CAP',
        PROVINCE: 'Provincia',
        CITY: 'Città',
        PHONE: 'Telefono',
        EMAIL: 'E-mail',
        DESCRIPTION: 'Descrizione',
        NOTE: 'Nota',
        SAVE: 'Salva',
        CLOSE: 'Chiudi',
        NO_CABIN_FOUND: 'Nessun Cabina',
        CABIN: 'Cabina',
        CANNOT_DELETE: 'Il punto vendita non può essere cancellato',
        ACTIVE_ON_MOBILE: 'Attivo su app',
        SIEGE_ALREADY_EXIST: 'Attenzione, esiste un il punto vendita <b>{{name}}</b> designato come Sede, desideri procedere?',
        SIEGE: 'Sede punto vendita',
      },
      ROLE: {
        ROLES: 'Ruoli',
        AUTHORITIES: 'Autorità',
        NAME: 'Nome',
        DESCRIPTION: 'Descrizione',
        ADD_ROLE: 'Aggiungere un nuovo ruolo',
        EDIT_ROLE: 'Modifica ruolo',
        NAME_REQUIRED: 'Il nome del ruolo è obbligatorio',
        DESC_REQUIRED: 'La descrizione del ruolo è richiesta',
        DELETE_ROLE: 'Cancella ruolo: {{roleName}}',
        DELETE_ROLE_MSG: 'Stai per cancellare il ruolo {{roleName}}. Sei sicuro?',
        DELETE_ERROR: 'Il ruolo ({{role.name}}) è legato a uno o più utenti!',
        ROLE_UPDATED: 'Ruolo aggiornato con successo',
        NO_ROLE_FOUND: 'Nessun ruolo trovato, clicca su aggiungi per aggiungere un nuovo ruolo',
        SELECT_ROLE: 'Selezionare un ruolo per modificare le sue autorità',
        CHANGES_NOT_SAVED: 'Attenzione, non hai salvato le modifiche, vuoi uscire?',
      },
      GROUP: {
        ATTENTION: 'Attenzione',
        DELETE_TITLE: 'Cancellare {{name}}',
        GROUP: 'Gruppo',
        GROUPS: 'Gruppi',
        YOU_ARE_ABOUT_TO_DELETE: 'Stai per cancellare <b>{{name}}</b>. Sei sicuro?',
        YOU_ARE_ABOUT_TO_RESTORE: 'Stai per ripristinare <b>{{name}}</b>. Sei sicuro?',
        CANNOT_DELETE_GROUP: 'Il cliente non può essere cancellato perché non è vuoto',
        CANNOT_DELETE_SUPER_ADMIN: 'L\'utente non può essere cancellato',
        ADD_GROUP: 'Nuovo cliente',
        EDIT_GROUP: 'Modifica cliente',
        GROUP_INFO: 'Info cliente',
        SUPER_ADMIN_INFO: 'Info super admin',
        USERNAME_EXISTS_ERROR: 'Utente già utilizzato',
        ADMINISTRATOR_PANEL: 'Pannello di Amministrazione Aziende',
        DATE_VALIDITY_TITLE: 'L\'installazione del gruppo di validità della licenza',
        LICENSE_DURATION: 'Durata della licenza',
        DAYS: 'giorni',
        CUSTOM: 'personalizzato',
        NUMBER_OF_DAYS: 'Numero di giorni',
        MONTH: 'mese',
        TRIMESTER: 'Trimestre',
        MONTHS: 'Mesi',
        YEAR: 'Anno',
        STARTS_AT: 'Inizio:',
        START_DATE: 'Data di inizio',
        DAYS_REMAINS: 'giorni rimane',
        EXPIRES_AT: 'scade il',
        EXPIRATION_DATE: 'data di scadenza',
        NO_GROUP_FOUND: 'Nessun gruppo trovato.',
        SEARCH: 'Ricerca',
        VALIDITY: 'Validità',
        ALL: 'Tutti',
        VALID: 'Valido',
        EXPIRED: 'Scaduto',
        STATE: 'Stato',
        ACTIVE: 'Attivo',
        INACTIVE: 'Inattivo',
        UPDATED_SUCCESS: 'Aggiornato con successo',
        QUOTE_DURATION: 'durata di preventivo',
        RANGE_DURATION: 'Intervallo di valutazione',
        SEMESTER: 'Semestre',
        RANGE_CONFIG: 'Classificazione clienti',
        FIRST_LEVEL: ' L1',
        SECOND_LEVEL: 'L2',
        THIRD_LEVEL: 'L3',
        FORTH_LEVEL: 'L4',
        VERIFICATION: 'Verifica',
        VERIFIED: 'Verificato ',
        NOT_VERIFIED: 'Non verificato',
        LICENSE: 'Licenza',
        FROM: 'Da',
        TO: 'A',
        VALUE_MUST_BE_GRATER_THAN: 'Il valore inserito deve essere più alto di ',
        INVALID_VALUE: 'Valore non valido',
        GENERAL: 'Generico',
        RANGE: 'Fascia di valutazione',
        GENERAL_CONFIG: 'Configurazione generale',
        NAME: 'Nome',
        LEVEL: 'Livello',
        EXPORT_AS_CSV: 'Esportazione come csv',
        UTILITY: 'Utility',
        UTILITY_CONFIG: 'Configurazioni varie',
        EXPORT_CLIENT: 'Clienti',
        RESET_FIDELITY: 'Reset dei punti fidelity',
        HARD_RESET_FIDELITY: 'Reset fidelity point e cancellazione storico',
        TRASH: 'Cestino',
        SMS_CONFIG: 'Configurazione Sms',
        PROMO_CONFIG: 'Configurazione di promo',
        PROMO: 'Marketing promozioni',
        DOWNLOAD_PRODUCT_EXCEL_MODEL: 'Scarica il modello Excel del prodotto',
        UPLOAD_PRODUCTS: 'Carica prodotti',
        UPLOADED_PRODUCTS: 'Prodotti caricati',
        DOWNLOAD_CLIENT_EXCEL_MODEL: 'Scarica modello Excel clienti',
        UPLOAD_CLIENTS: 'Carica clienti',
        UPLOADED_CLIENTS: 'Clienti caricati',
        PLANNING_NAME_EXISTS: 'Nome operatore già utilizzato, procedere comunque?',
        UPLOAD_TREATMENTS: 'Carica Servizi',
        UPLOADED_TREATMENTS: 'Servizi caricati',
        DESCRIPTION: 'Descrizione',
        GROUP_NAME: 'Nome gruppo',
        MODEL: 'Modello',
      },
      TYPES_ACTIVITIES: {
        ADD_NEW: 'Aggiungi una nuova attività',
        NO_ACTIVITY_FOUND: 'Nessuna attività trovata',
        DELETE_MSG: 'Stai per cancellare'
      },
      LICENSE_CONFIG: {
        DELETE_MSG: 'Stai per eliminare {{name}}!',
        ERROR_DELETE: 'Licenza già in uso!'
      }
    },
    PLANNING_FORM: {
      NO_PLANNING_YET: 'Nessun planning',
      PLANNING_LIST: 'Lista planning',
      EDIT_PLANNING: 'Modifica planning',
      NEW_PLANNING: 'Crea  planning',
      ADD: 'Aggiungi',
      MONDAY: 'Lunedì',
      TUESDAY: 'Martedì',
      WEDNESDAY: 'Mercoledì',
      THURSDAY: 'Giovedì',
      FRIDAY: 'Venerdì',
      SATURDAY: 'Sabato',
      SUNDAY: 'Domenica',
      ABBREVIATION_MONDAY: 'Lun',
      ABBREVIATION_TUESDAY: 'Mar',
      ABBREVIATION_WEDNESDAY: 'Mer',
      ABBREVIATION_THURSDAY: 'Gio',
      ABBREVIATION_FRIDAY: 'Ven',
      ABBREVIATION_SATURDAY: 'Sab',
      ABBREVIATION_SUNDAY: 'Dom',
      JANUARY: 'Gennaio',
      FEBRUARY: 'Febbraio',
      MARCH: 'Marzo',
      APRIL: 'Aprile',
      MAY: 'Maggio',
      JUNE: 'Giugno',
      JULY: 'Luglio',
      AUGUST: 'Agosto',
      SEPTEMBER: 'Settembre',
      OCTOBER: 'Ottobre',
      NOVEMBER: 'Novembre',
      DECEMBER: 'Dicembre',
      JUST_THIS_WEEK: 'Soltanto questa settimana',
      THIS_MONTH: 'Mese in corso',
      FIRST_QUARTER: 'Primo trimestre',
      SECOND_QUARTER: 'Secondo trimestre',
      THIRD_QUARTER: 'Terzo trimestre',
      FORTH_QUARTER: 'Quarto trimestre',
      ALL_THE_YEAR: 'Tutto l\'anno',
      APPLICATION_MODE: 'Desideri assegnare questo template a :',
      // tslint:disable-next-line:max-line-length
      ATTENTION_NON_AVAILABLE_OPERATOR: 'Attenzione si sta per inserire un appuntamento in una fascia oraria di non disponibilità per l\'operatore',
      // tslint:disable-next-line:max-line-length
      ATTENTION_NON_AVAILABLE_CABIN: 'Attenzione si sta per inserire un appuntamento in una fascia oraria di non disponibilità per la cabina',
      ATTENTION_NON_BEFORE_OPEN: 'Attenzione stai per prendere un appuntamento prima dell\'orario di apertura del punto vendita',
      ATTENTION_NON_AFTER_CLOSE: 'Attenzione si sta per inserire un appuntamento dopo l\'orario di chiusura del punto vendita',
      ATTENTION_DAY_OFF_OPERATOR: 'Attenzione si sta per entrare e appuntamento durante il giorno libero dell\'operatore',
      ATTENTION_DAY_OFF_CABIN: 'Attenzione si sta per entrare e appuntamento durante il giorno fuori dalla cabina',
      OPERATOR_OCCUPIED: 'L\'operatore <b> {{label}} </b> già occupato',
      CABIN_OCCUPIED: 'La cabina <b> {{label}} </b> già occupata',
      WARNING: 'Attenzione',
      WANT_TO_CONTINUE: 'Desideri continuare?',
    },
    TREATMENT_FORM: {
      ID: 'ID',
      TREATMENT_DETAILS: 'Dettaglio del servizio',
      ADD_TREATMENT: 'Aggiungi un servizio',
      CANCEL: 'Annulla',
      EDIT: 'Modifica',
      SAVE: 'Salva',
      CREATION_DATE: 'Data di creazione',
      PROGRESS_CODE: 'Codice',
      DESCRIPTION: 'Descrizione',
      DESCRIPTION_REQUIRED: 'Descrizione richiesta',
      CATEGORY: 'Categoria',
      CATEGORY_REQUIRED: 'Categoria richiesta',
      SUBCATEGORY: 'Tipologia',
      LINEA: 'Linea',
      TAX: 'Iva',
      CABINE: 'Cabina',
      TEMPO_CABINE: 'Tempo cabina',
      TEMPO_OPERATORE: 'Tempo operatore',
      PAUSE_TIME: 'Tempo di posa',
      PREZZO_MASTER: 'Prezzo',
      PREZZO_PDV: 'Prezzo PDV',
      ENABLE_PRICE: 'Abilita prezzo',
      NOTE: 'Note prodotto',
      RELATED_PRODUCTS: 'Prodotti correlati',
      ADD_PRODUCT: 'Aggiungi prodotto',
      NO_PRODUCT_FOUND: 'Nessun prodotto trovato',
      PRODUCT_REQUIRED: 'Articolo richiesto',
      QUANTITY_REQUIRED: 'Devi scegliere una quantità diversa da zero',
      PRODUCT_COST_IMPACT: 'Incide costo per prodotto',
      COST_BEAUTY_CENTER: 'Costo Centro',
      PROFIT_MARGIN: 'Guadagno',
      AVERAGE_OPERATOR_HOURLY_COST: 'Costo H. medio operatore',
      TREATMENT_COST: 'Incide costo Servizo',
      SUBCATEGORY_REQUIRED: 'Tipologia richiesta',
      DISABLE_CABINE: 'No cabina',
      DISABLE_OPERATOR: 'No operatore',
      ARTICLE: 'Articolo',
      ADD_RELATED_PRODUCTS: 'Aggiungi prodotto correlato',
      EDIT_RELATED_PRODUCTS: 'Modifica prodotti correlati',
      MEASURE_UNIT: 'Unità di misura',
      PRICE_QUANTITY: 'Costo prodotto',
      QUANTITY: 'Quantità prodotto',
      PRICE: 'Totale',
      QUANTITY_UNIT: 'Numero di pezzi',
      NO_TREATMENT_FOUND: 'Nessun servizio trovato',
      UNIT: 'Unità',
      YOU_WANT_TO_HIDE_RELATED_PRODUCT: 'Sei sicuro di voler eliminare questo prodotto',
      NO_UNIT_SELECTED: 'Nessuna unità di misura selezionata',
      NOTE_PRODUCT: 'Protocollo di lavorazione del Servizio',
      DESCRIPTION_PLU: 'Descrizione PLU',
    },
    FIDELITY_FORM: {
      QUANTITY: 'Quantità',
      PRICE: 'Prezzo',
      NAME: 'Nome',
      CREATION_DATE: 'Data creazione',
      DISCOUNT: 'Sconto',
      TOTAL_DISCOUNT: 'Sconto totale',
      CARD: 'Carta',
      POINT_WITH_GIFT: 'A punti con omaggio',
      ADD_RECHARGE: 'Aggiungi ricariche',
      RECHARGES: 'Richariche',
      CREATE_RECHARGE: 'Crea richariche',
      VALUE: 'Valore',
      PREPAID_WITH_DISCOUNT: 'Prepagata con scontistica',
      EURO_SPENT: 'Ogni euro spesa',
      LOAD_POINT: 'Carico punti',
      THRESHOLDS: 'Soglie',
      ADD_THRESHOLD: 'Aggiungi soglia',
      TREATMENT: 'Servizio',
      PRODUCT: 'Prodotto',
      CREATE_THRESHOLD: 'Crea soglia',
      THRESHOLD: 'Soglia',
      POINT: 'Punti',
      APPLY_ALTER_THRESHOLD: 'Applica altra soglia',
      NO_RECHARGE_FOUND: 'Nessuna ricariche trovata',
      SELECT_FIDELITY_TYPE: 'Seleziona la tipologia carta fidelity',
      GIFT_CARD: 'Carta regalo',
      SOLD: 'Saldo',
      NUMBER: 'Numero',
      TYPE: 'Tipo',
      HISTORY: 'Storico',
      NO_HISTORY_FOUND: 'Nessun movemento trovato',
      ASSOCIATION_DATE: 'Data di emissione',

      ASSOCIATION: 'ASSOCIATION',
      GAIN: 'Guadagno',
      CONSUMPTION: 'Consumo',
      MANUAL_ADD: 'Aggiunta manuale',
      MANUAL_SUBTRACT: 'Sottrazione manuale',
      PRODUCT_SELL: 'Prodotto',
      TREATMENT_SELL: 'Servizio',
      ABONMENT_SELL: 'abonamento',
      RELOAD: 'Ricaricare',
      SELECT_CARD: 'Seleziona una carta',
      IGNORE_PRINCIPAL_DISCOUNT: 'Attenzione lo sconto principale inserito verrà ignorato, vuoi continuare?',
      RELOAD_VALUE: 'Ricarica',
      DUE_DATE: 'Scadenza',
      POINT_CART_NAME: 'Descrizione tessera',
      PREPAID_CART_NAME: 'Descrizione tessera',
      THRESHOLD_CART_NAME: 'Descrizione soglia',
    },
    SUBSCRIPTION_FORM: {
      NAME: 'Nome',
      TYPE: 'Tipo',
      STARTS_AT: 'Inizio validità',
      ENDS_AT: 'Fine validità',
      PRICE: 'Prezzo',
      SUBSCRIPTION_DETAILS: 'dettagli del abbonamento',
      ADD_SUBSCRIPTION: 'Aggiungi abbonamento',
      EDIT: 'Modifica',
      SAVE: 'Salva',
      NO_SUBSCRIPTION_FOUND: 'Nessun abbonamento trovato',
      DESCRIPTION: 'Descrizione',
      FROM: 'Dal ',
      TO: 'Al',
      SALE_PERIOD: 'Durata vendita',
      SUBSCRIPTION_PERIOD: 'Durata abbonamento',
      DURATION: 'Durata vendita',
      ALWAYS_ACTIF: 'Vendita sempre attiva',
      PRODUCTS: 'Prodotto',
      TREATMENTS: 'Servizio',
      THREE_MONTHS: '3 mesi',
      SIX_MONTHS: '6 mesi',
      NINE_MONTHS: '9 mesi',
      TWELVE_MONTHS: '12 mesi',
      NUMBER_OF_DAYS: 'Numero di giorni',
      OTHER: 'Altra',
      PRODUCT: 'Prodotti',
      TREATMENT: 'Servizi',
      TOTAL: 'Incidenza c. tot',
      TOTAL_PRICE: 'Prezzo Totale',
      CATEGORY: 'Cat',
      WEIGHT: 'Peso',
      PURCHASE_COST: 'Costo',
      SALE_COST: 'Prz',
      QUANTITY: 'Qtà',
      DISCOUNT: 'Sconto',
      FINAL_PRICE: 'Prz fin',
      FREE: 'Omaggio',
      MINUTES: 'Min',
      COST: 'Incidenza costo',
      UNIT: 'Unità',
      NO_PRODUCT_FOUND: 'Nessun prodotto trovato',
      NO_TREATMENT_FOUND: 'Nessun servizio trovato',
      CREATION_DATE: 'Data creazione',
      ENTRIES: 'Dettagli',
      MY_SUBSCRIPTIONS: 'I miei abbonamenti',
      HISTORY: 'Storico',
      DESC: 'Descr',
      CHOOSE_PRODUCT_OR_TREATMENT: 'Scegli almeno un prodotto o un trattamento'
    },
    PAYMENT: {
      ANONYMOUS: 'Utente Anonimo',
      TOTAL: 'Totale',
      FEE_PAID: 'Corrispettivo pagato',
      FEE_NOT_PAID: 'Corrispettivo non pagato',
      CHECKOUT: 'Vai alla cassa',
      DESCRIPTION: 'Descrizione',
      OPERATOR: 'Operatore',
      CABIN: 'Cabina',
      SUBSCRIPTIONS: 'Abbonamenti',
      QUANTITY: 'Quantità',
      PRICE: 'Prezzo Uni.',
      DISCOUNT: 'Sconto',
      FINAL_PRICE: 'Prezzo finale',
      ASSOCIATED_COMPANY: 'Azienda Associeto',
      GENERAL_CARD: 'Carta generale',
      CARD: 'Tessera',
      AMOUNT: 'Importo',
      AMOUNT_TO_PAY: 'Resta da pagare',
      CHARGE: 'Addebito',
      BALANCE: 'Saldo',
      POINTS: 'Punti',
      POINTS_ACCRUED: 'Punti maturati',
      HOMAGE: 'Premi',
      PREVENTIVE: 'Preventivi',
      ALTERNATIVE_SELLING: 'fidelity/abbonamento',
      SHOPPING_CART_EMPTY: 'Carrello vuoto',
      START_ADDING_ITEMS: 'Iniziate aggiungendo un elemento dalla lista',
      ASSOCIATE_CARD: 'Associa carta',
      CASH: 'Contanti',
      CHEQUE: 'Assegno',
      BANK_CARD: 'Carta bancaria',
      CREDIT_CARD: 'Carta bancaria',
      PAYMENT: 'Pagamento',
      REST: 'Resto',
      GIFTS: 'Premi disponibili',
      PRODUCT: 'Prodotto',
      TREATMENT: 'Servizio',
      POINT: 'Punti',
      PRICE_GIFT: 'Prezzo',
      POINT_CARD: 'Carta a punti',
      GIFT_CARD: 'Carta regalo',
      RECHARGE_CARD: 'Carta ricaricabile',
      PAYMENT_MODE_ERROR: 'L\'importo inserito è maggiore del totale.',
      DO_YOU_WANT_TO_USE_YOUR_FREE_OBJECTS: 'Omaggio disponibile vuoi utilizzare i tuoi punti per riscuotere l’omaggio?',
      NO_PAYMENT_FOUND: 'Nessun pagamento trovato',
      NO_QUOTE_FOUND: 'Nessun preventivo trovato',
      STATUS: 'Stato',
      DONE: 'fatto',
      FAILED: 'Fallito',
      PENDING: 'Scont. non emesso',
      STATUS_HISTORY: 'Stato cronologia pagamenti',
      OPERATOR_DETAILS: 'Dettagli dell\'operatore',
      USERNAME: 'Utente',
      NAME: 'Nome',
      MOBILE: 'Cognome',
      EMAIL: 'Email',
      ROLE: 'Ruolo',
      PAY: 'Paga',
      PAYMENT_DETAILS: 'Dettagli di pagamento',
      SEARCH: 'ricerca',
      WAIT_FOR_RECEIPT: 'In attesa Doc. N.',
      RECEIPT_NOT_SENT: 'Scont. non emesso',
      PRINT_FAILED: 'Stampa scont. fallita',
      EAN_NON_VALID: 'Codice EAN non valido',
      FAILED_RESET: 'Scont. emesso senza azzeramento',
      FAILED_TICKET: 'Scont. emesso senza progressivo',
      RESET: 'N. azzeramento',
      RECEIPT: 'Nr scontrino',
      TICKET: 'Scontrino',
      TRANSFER: 'Bonifico',
      PAY_WITHOUT_PRINT: 'Paga senza stampa scontrino',
      SIMULATION: 'Conto chiuso',
      ANONYMOUS_OPERATOR: 'Senza operatore',
      PAYMENT_MODE: 'Modalità pagamento',
      QUOTE: 'Preventivi',
      SCAN: 'Scansiona',
      EAN_SCAN: 'Scansiona EAN',
      EAN_NUMBER: 'Numero EAN',
      GIFT: 'Premio',
      RELOAD_CARD: 'Ricarica carta',
      SUBSCRIPTION: 'Abbonamento',
      FISCAL: 'Fiscale',
      SATISPAY: 'Satispay',
      NO_SATISPAY_CONFIG: 'Account Satispay non configurato',
      WAITING_FOR_PAYMENT: 'In attesa di pagamento',
      ERROR_PRINT_QRCODE: 'Errore durante la stampa del codice QR',
      ERROR_CODE_SCANNED: 'Scansione del codice QR stampato con l\'applicazione satispay a pagamento',
      ANNUL_TICKET: 'Reso / Annulla',
      SEARCH_PAYMENT: 'Ricerca pagamento',
      SHOW_RESO_LIST: 'Visualizza Resi e Annullamenti',
      PAYMENT_ALREADY_ANNULLED: 'Scontrino già annullato',
      RT_REFERENCE: 'Matricola RT',
      PAYMENT_REFERENCE: 'Pagamento riferimento',
      PAYMENT_ALREADY_HAS_RESO: 'Hai già effettuato il reso',
      RETURNED_QUANTITY: 'Qtà Resa',
      AVAILABLE_QUANTITY: 'Qtà restante',
      QUANTITY_TO_RENDER: 'Qtà a rendere',
      QUANTITY_TOTAL: 'Qtà Totale',
      GROUPON: 'Groupon',
      RESO: 'Reso',
      TYPE: 'Tipo',
      ADVANCE: 'Acconto',
      ADVANCE_COMPLETED: 'Fatto con acconto',
      PAYMENTS: 'Pagamenti',
      CREATE_ADVANCE: 'Crea acconto',
      CONTINUE_PAYMENT: 'Continua pagamento',
      TOTAL_PAYMENT: 'Totale scontrino',
      CLIENT_NAME: 'Nome Cognome',
      SEARCH_ADVANCE: 'Ricerca acconti',
      PAYMENT_ADVANCE_COMPLETED: 'Saldo acconto',
      PAYMENT_ADVANCE: 'Acconto',
      ACTION: 'Azione',
      FAILED_ADVANCE_RESET: 'Acconto. emesso senza azzeramento',
      FAILED_ADVANCE_TICKET: 'Acconto. emesso senza progressivo',
      PRINT_ADVANCE_FAILED: 'Stampa acconto fallita',
      ADD_DISCOUNT: 'Aggiungi sconto',
      CARD_NAME: 'Nome tesserra',
      GIFTS_SOLD: 'Saldo',
      FIDELITY_CARD: 'Gift Card',
    },
    PURCHASE_FORM:{
      NO_PURCHASE_HISTORY_FOUND:'Nessun acquisto trovato',
    },
    PLANNING: {
      TODAY: 'Oggi',
      TOMORROW: 'Domani',
      YESTERDAY: 'Ieri',
      HOUR: 'Ora',
      CLIENT: 'Cliente',
      ADD_TREATMENT: 'Aggiungi servizio',
      TREATMENT: 'Servizio',
      CABIN: 'Cabina',
      OPERATOR: 'Operatore',
      DURATION: 'Durata',
      ALERT_TITLE: 'Cancellare la prenotazione',
      ALERT_TEXT: 'Stai per cancellare la prenotazione per sempre, sei sicuro?',
      FIRST_VISIT: 'Prima visita',
      LAST_VISIT: 'Ultima visita',
      NEW: 'Nuovo',
      NOTE: 'Nota',
      NO_NOTE: 'Nessuna nota contenuta',
      CONTACT: 'Contatto',
      FIRST_PASSAGE: 'Primo Passaggio',
      LAST_PASSAGE: 'Ultimo passaggio',
      HISTORY: 'Storico',
      PAID: 'CHIUSO',
      DURATION_MSG: 'La durata minima della cabina e dell\'operatore è di 10 minuti.',
      LOADING: 'Caricamento in corso',
      CONFIRM_CANCELLATION: 'Desideri uscite? Perderai le informazioni inserite',
      SELECT_CABIN_OR_OPERATOR: 'Seleziona almeno una cabina o un operatore per un trattamento',
      COMPANY: 'Azienda',
      DISABLE: 'Disattiva',
      NEW_WORKING_HOURS: 'Nuovo orario di lavoro',
      START_DATE: 'Data di inizio',
      END_DATE: 'Data di fine',
      SELECT_MIN_OPERATOR_OR_CABIN: 'Seleziona almeno un operatore o una cabina',
      WARNING_INVALID_ACTION: 'Attenzione, azione non valida',
      DEACTIVATED: 'Disattivato',
      START_HOUR_FROM: 'Ora da',
      START_HOUR_TO: 'Ora a',
      NO_BOOKING_FOUND: ' Nessun appuntamento trovato',
      DRAG_THE_APPOINTMENT: 'Trascina l\'appuntamento',
      APPOINTMENT_COPIED: 'Appuntamento copiato con successo',
      CLIENT_SEARCH: 'Ricerca nome e cognome',
      BOOKING_LOG: 'Log',
      CREATED_AT: 'Creato il',
      UPDATED_AT: 'Modifcato il',
      CANCELED_AT: 'Annullato il',
      CREATED_BY: 'Creato da',
      APP_APPOINTMENTS: 'Appuntamenti da App',
      HOURS_NOT_AVAILABLE: 'Orario non disponibile',
      WEEK: 'Settimana',
      DAY: 'Giorno',
      PREVIEW_NOT_AVAILABLE: 'Anteprima non disponibile, scarica il pdf per visualizzarlo',
      DISABLE_DAY_OPERATOR: 'Sono presenti degli appuntamenti assegnati a questo operatore. Ti consigliamo di verificare ed eventualmente riassegnarli. Desideri procedere comunque?',
      DISABLE_DAY_CABIN: 'Sono presenti degli appuntamenti assegnati a questa cabina. Ti consigliamo di verificare ed eventualmente riassegnarli. Desideri procedere comunque?',
      APPOINTMENT_OPERATOR_CHECK_WARNING: 'Attenzione, ci sono già appuntamenti per l\'operatore {{operator}}, desideri continuare?',
      APPOINTMENT_CABIN_CHECK_WARNING: 'Attenzione, ci sono già appuntamenti per la cabina {{cabin}}, desideri continuare?',
      APPOINTMENT_MIX_CHECK_WARNING: 'Attenzione, ci sono già appuntamenti per l\'operatore {{operator}} e la cabina {{cabin}}, desideri continuare?',
      CUSTOMER_IN_HURRY: 'Il cliente ha fretta',
    },
    RT: {
      RT_CONFIGURATION: 'Configurazione RT',
      ALL_CONFIGURATION: 'Tutta la Configurazione',
      GET_RT_CONFIG: 'Ottieni la Configurazione RT',
      THE_CONFIGURATION_OF_THIS_IP_ADDRESS_IS_ALREADY_SAVED: 'la configurazione di questo indirizzo IP è già stata salvata',
      EDIT_DEPARTMENT: 'Modifica Reparto',
      EDIT_VAT: 'Modifica IVA',
      DEPARTMENT: 'Reparto',
      VAT: 'IVA',
      PAYMENT: 'Pagamento',
      OPERATOR: 'Operatore',
      HEADER: 'Intestazione',
      EDIT_OPERATOR: 'Modifica Operatore',
      DESCRIPTION: 'Descrizione',
      EDIT_PAYMENT: 'Modifica Pagamento',
      DELETE_ERROR: 'La configurazione RT è collegata a uno o più pagamenti!',
      EDIT_HEADER: 'Modifica Intestazione',
      PRINT_TEST: 'Test di Stampa',
      CASHIER_OPERATIONS: 'Operazioni cassa',
      CASH_REGISTER_CLOSURE: 'Chiusura Cassa',
      DAILY_READING: 'Lettura giornaliera',
      DEPARTMENT_COUPLING: 'Associazione reparto',
      PAYMENT_COUPLING: 'Associazione pagamento',
      ENABLE_DISABLE_PAYMENT_WITHOUT_PRINT: 'Abilita / disabilita la modalità di vendita senza contatore delle tasse',
      CONFIGURATION: 'Visualizza Configurazione',
      RETRIEVE_CONFIGURATION: 'Richiama configurazione',
      DEPARTMENTS: 'Reparti',
      CLOSURES: 'Chiusure',
      ARE_YOU_SURE_POPUP: 'Sei sicuro di voler utilizzare la stampa tramite pop up?',
      YOU_ARE_USING_STANDARD: 'Adesso stai utilizzando il servizio standard di stampa. Assicurati di aver autorizzato i contenuti non ' +
        'sicuri sul tuo browser o di aver reso https la tua stampante',
      YOU_ARE_USING_POPUP: 'Stai utilizzando la stampa tramite pop up',
      IF_YOU_DONT_WANT_TO_USE: 'Se desideri non utilizzare la stampa tramite pop up devi autorizzare i contenuti non sicuri sul tuo ' +
        'browser o rendere https la tua stampante',
      USE_PRINTING_POPUP: 'Utilizzare la stampa popup',
      CONFIG_RT: 'Configurazione RT',
      ADVANCE_TYPE: 'Tipo acconto',
      XML_TYPE: 'Tipo XML',
      LOTTERY: 'Lotteria',
      ENABLE_LOTTERY: 'Attiva lotteria',
      DISABLED: 'Disattivato',
      USING_ONE_DOCUMENT: 'utilizzare uno scontrino',
      USING_TWO_DOCUMENT: 'Using two document',
      XML6: 'XML6',
      XML7: 'XML7',
      DISCOUNT_TO_PAY: 'Sconto a pagare',
      LOTTERY_START_DATE: 'Lotteria inizio data',
      SETTINGS: 'Impostazioni',
      DEMO: 'Demo print',
      RCH: 'RCH',
      RCH_NATIVE: 'RCH Nativa',
      EPSON: 'Epson',
      RETRIEVE_FIRMWARE: 'Richiama Firmware',
      RETRIEVE_REGISTER: 'Richiama Matricola RT',
    },
    COURTESYRT:{
      HEADERFOOTERCONFIGURATIONTOOLTIP:'Configura header e footer',
      HEADERFOOTERNOTUPDATED:'Per via di un errore del server, header e footer non sono stati aggiornati',
      HEADERFOOTERUPDATED:'Header e footer sono stati aggiornati',
      ONLYFORINDICATIVEPURPOSE:'L\'anteprima è puramente a scopo indicativo, clicca qui per eseguire la stampa di prova',
      DID_THE_PRINTING_TEST_TOOK_PLACE:'La stampa di prova è stata eseguita?',
      CHECK_IP:'Perfavore controlla l\'indirizzo ip',
      RT_ADDED:'La stampante è stata aggiunta con successo',
      YES:'SI',
      NO:'NO',
      HEADERFOOTERCONFIGURATION:"Configurare l'intestazione e piè di pagina",
      CONTENT:'CONTENUTO',
      ROWS:'RIGHE',
      ROWONE:'uno',
      ROWTWO:'due',
      ROWTHREE:'tre',
      ROWFOUR:'quattro',
      ROWFIVE:'cinque',
      ROWSIX:'sei',
      ROWTEXT:'testo',
      STYLE:'stile',
      CENTERTEXT:'centrare testo',
      DOUBLEHEIGHTTEXT:'testo doppia altezza',
      DOUBLEWIDTHTEXT:'testo doppia larghezza',

    },
    LOGIN: {
      USERNAME: 'Nome utente',
      PASSWORD: 'Password',
      FORGET_PASSWORD: 'Password dimenticata?',
      REMEMBER_ME: 'Ricordami',
      LOGIN: 'Login',
      WELCOME_BACK: 'Benvenuta/o',
      CHOOSE_SELLPOINT: 'Seleziona il tuo punto vendita',
      LOGOUT: 'Logout',
      CONFIRM: 'Conferma',
      SELL_POINTS: 'Punti Vendita',
      WRONG_USERNAME_PASSWORD: 'Nome utente o password sbagliata',
      ACCOUNT_LOCKED: 'Il tuo account è bloccato',
      AUTHENTICATION: 'Autenticazione',
      SIGN_IN_WITH_YOUR_BARCODE: 'Accedi con il tuo codice a barre',
      CODE_SUCCESSFULLY_IDENTIFIED: 'Codice identificato con successo',
      AWAITING_READING: 'In attesa di lettura',
      USE_BARCODE: 'Utilizzo del codice a barre',
      OWNER_OR_SUPEADMIN_BARCODE_ERROR: 'Il proprietario e il super amministratore non' +
        ' possono effettuare il login utilizzando un codice a barre, si prega di utilizzare il nome utente e la password',
      SWITCH_LICENSE_MSG: 'Gentile Cliente, il periodo di prova è terminato. Puoi comunque continuare a utilizzare ' +
        'l\'applicazione in modalità gratuita. Se desideri effettuare un upgrade contattaci.',
      YOU_DONT_HAVE_ACCOUNT: 'Non hai un conto?',
      SIGN_UP: 'Registrati.',
      LOGIN_PAGE: 'Torna al login',
    },
    SMS: {
      MESSAGE_TEST:'Un messaggio di prova è stato inviato con successo',
      MESSAGE_TEST_FAILED:'Si è verificato un problema durante l\'invio del messaggio di prova',
      PHONE_NUMBER:'Numero di telefono',
      TESTER:'Testare',
      TEMPLATE_NAME:'Nome del modello',
      LANGUAGE_CODE:'Codice lingua',
      COMPAIGN_NUMBER: 'N. campagna',
      TEMPLATE:'Modello',
      SMS_TEMPLATE: ' SMS Modello',
      WhatsApp_TEMPLATE:'WhatsApp Modello',
      WhatsApp_STATUS:'Stato WhatsApp ',
      CREATION_DATE: 'Data di creazione',
      SCHEDULED_AT: 'Programmato per',
      SUBSCRIBERS_NUMBER: 'Numero di abbonati',
      NEW_COMPAIGN: 'Nuova campagna',
      SELECT_ALL: 'Seleziona tutto',
      AT: 'A',
      DATE: 'Data',
      FIRST_NAME: 'Nome',
      LAST_NAME: 'Cognome',
      SETTINGS: 'Impostazione SMS',
      PROVIDER_CONFIGURATION: 'Configurazione del provider Smshosting',
      APPOINTMENT_REMINDER: 'Appuntamenti',
      GENERIC_SMS_TO_APPOINMENTS: 'SMS generico per i clienti con appuntamento',
      GENERIC_MESSAGE_FROM: 'Appuntamenti da',
      GENERIC_MESSAGE_TO: 'Fino a',
      SENT_DATE_HOUR: 'Data e ora invio',
      BEFORE: 'Invio Promemoria',
      HOURS: ' Ore Prima',
      TIME: 'Tempo',
      BIRTHDAY_REMINDER: 'Compleanni',
      CONFIGURATION_SUCCESS: 'Configurazione aggiornata correttamente',
      HOSTNAME: 'Nome host',
      PORT: 'Porta',
      USERNAME: 'Nome utente',
      PASSWORD: 'Password',
      PROXY: 'Proxy',
      PROXY_PORT: 'Porta proxy',
      ACTIVATE_PROXY: 'Attivare il proxy',
      STATUS: 'Stato',
      DO_YOU_WANT_TO_DELETE_CAMPAIGN: 'Vuoi eliminare la campagna ?',
      CAMPAIGN_ID: 'ID Campagna',
      RECEIVER_ID: 'ID dest',
      RECEIVER: 'Destinatario',
      SENT_AT: 'Inviato a',
      CAMPAIGN_REMINDER: 'Campagne',
      MOBILE: 'Cellulare',
      SENT: 'Inviato',
      FAILED: 'Invio fallito',
      SCHEDULED: 'Programmato',
      CANCELED: 'Annullato',
      DONE: 'Lanciato',
      INSERTED: 'Inviato',
      INSERTED_WHATSAPP: 'Pronto all\'invio',
      READ:'Letto',
      NOT_INSERTED: 'Invio fallito',
      DELIVERED: "Consegnato ",
      NOTDELIVERED: "Non consegnato",
      PENDING: "In attesa",
      //SENT: "SENT",
      NOSENT: "Non inviato",
      PHONE: 'Telefono',
      SENDER: 'Testo o numero sender',
      TEST: 'Validazione chiavi',
      KEY_VALIDATED: 'Validazione chiavi confermato',
      KEY_NOT_VALIDATED: 'Validazione chiavi fallita',
      INFO: 'In caso di programmazione il campo data è obbligatorio ed è preferibile posticipare ' +
        'l\'invio di almeno 10 minuti rispetto all\'ora attuale',
      SELECT_CLIENTS: 'Selezionare i clienti',
      CREDITS: 'Saldo SMS',
      BUY: 'Acquista SMS',
      SEND_NOW: 'Invia subito',
      SUCCESS: 'La campagna viene avviata, controlla el storico per seguire lo stato degli messaggio',
      SEND_FAILED: 'Errore durante l\'invio, riprova o contatta l\'assistenza',
      ERROR_PROVIDER: 'Errore nel tentativo di contattare il provider sms',
      BADNUMBERFORMAT: 'Numero di telefono errato',
      DUPLICATESMS: 'Sms duplicato: sms già inviato nelle ultime 24 ore',
      BLACKLIST: 'Numero in Black list',
      BAD_CREDIT: 'Credito insufficiente',
      BAD_TRANSACTIONID: 'Parametro transactionId non valido',
      BAD_DATE: 'Formato data non valido',
      BAD_TEXT: 'Testo non valido',
      BAD_TEXT_PLACEHOLDER: 'Testo contiene segnaposto non sostituibili',
      BAD_CALLBACK: 'Parametro statusCallback non valido',
      BAD_ENCODING: 'Parametro encoding non valido',
      BAD_FROM: 'Parametro from non valido',
      BAD_GROUP: 'Parametro group non valido',
      NO_CREDIT: 'Credito insufficiente',
      NO_VALID_RECIPIENT: 'Destinatario non valido',
      GENERIC_ERROR: 'Errore interno server',
      INVALID_CREDENTIALS: 'Credenziali non valide',
      METHOD_NOT_ALLOWED: 'Metodo non autorizzato',
      RESEND: 'Desideri inviare lo Messaggio a {{nome}} {{cognome}} al numero {{number}} ?',
      SEND_QUESTION: 'Confermare questa campagna mail?',
      MAX_ELEVEN: 'Caratteri consentiti 11, spazi compresi',
      NOTIFICATION_TYPE: 'Tipo notifica',

    },

    WHATS_APP: {
      GENERIC_MSG_TO_APPOINMENTS: 'WhatsApp messaggi generico per i clienti con appuntamento',
      SETTINGS: 'Impostazione WhatsApp',
      BASE_URL:'URL di base',
      Sender_Phone_Number_Id:'ID del numero di telefono del mittente',
      Token:'Token',
      Whatsapp_config:'Configurazione di WhatsApp Web',
      SENT: 'Inviato',
      FAILED: 'Invio fallito',
      SCHEDULED: 'Programmato',
      CANCELED: 'Annullato',
      DONE: 'Lanciato',
      INSERTED: 'Inviato',
      NOT_INSERTED: 'Invio fallito',
      DELIVERED: "Consegnato ",
      NOTDELIVERED: "Non consegnato",
      PENDING: "In attesa",
      SUCCESS: 'La campagna viene avviata, controlla el storico per seguire lo stato degli Whatsapp',
      SEND_FAILED: 'Errore durante l\'invio, riprova o contatta l\'assistenza',
      SEND_QUESTION: 'Conferma questa campagna di messaggi WhatsApp?',
      CONFIGURATION_SUCCESS: 'Configurazione aggiornata correttamente',
    },

    MOVEMENT_FORM: {
      CODE: 'Codice',
      NO_MOVEMENT_FOUND: 'Nessun movimento trovato',
      CREATE_MOVEMENT: 'Crea movimento',
      CREATE_TRANS: 'Crea trasferimento interno',
      DOCUMENT_NUMBER: 'Numero Documento',
      CLIENT: 'Cliente',
      PROVIDER: 'Fornitore',
      PAYMENT: 'Pagamento',
      DOCUMENT_DATE: 'Data doc',
      DOCUMENT_TYPE: 'Tipo Documento',
      TYPE: 'Causale',
      NUMBER: 'N°',
      DATE: 'Data Movimento',
      BAR_CODE: 'Codice a barre',
      PLU_DESCRIPTION: 'Descrizione Plu',
      PURCHASE_COAST: 'Costo di acquisto',
      IVA: 'IVA',
      QUANTITY: 'Quantità',
      CASH: 'Contanti',
      CHEQUE: 'Assegno',
      CREDIT_CARD: 'Carta bancaria',
      RECAP: 'Riepiloghi',
      TOTAL_QUANTITY: 'QT. Tot. Art.',
      TOTAL_IVA: 'Totale Iva',
      TOTAL_TAXABLE: 'Tot. Imp',
      SUPPLIER_LOAD: 'Carico fornitore',
      CUSTOMER_SALES: 'Vendita cliente',
      SELF_CONSUMPTION: 'Autoconsumo',
      VARIOUS_DISCHARGE: 'Scarico vario',
      VARIOUS_LOAD: 'Carico vario',
      TRANSFER: 'Trasferimemto interno',
      NONE: 'Nessuno',
      BILL: 'Fattura',
      DDT: 'DDT',
      ADD_PRODUCT: 'Aggiungi prodotto',
      TOTAL_IVATO: 'Totale Ivato',
      PRODUCT: 'Prodotto',
      DATE_FROM: 'Data da',
      DATE_TO: 'Data a',
      SAVE_DRAFT: 'Salva Bozza',
      MOVEMENT_DETAILS: 'Dettaglio movimento',
      INVENTORY_DETAILS: 'Dettaglio inventario',
      CREATE_INVENTORY: 'Crea inventario',
      NOTE: 'Nota',
      CLIENT_PROVIDER: 'Fornitore / Cliente',
      DRAFT: 'Bozza',
      EDIT_NAME: 'Modifica nome',
      EDIT_COST: 'Modifica costo acquisto',
      EDIT_PRICE: 'Modifica prezzo vendita',
      EDIT_DETAILS: 'Modifica dati',
      CREATION: 'Creazione prodotto',
      YES: 'Si',
      NO: 'No',
      SELECT_COLUMNS: 'Personalizzazione colonne',
      LOAD_PRODUCTS: 'Carica prodotti',
      UPLOAD_FILE: 'Carica file',
      DOWNLOAD_MODEL: 'Scarica modello',
      NOT_FOUNDED: 'Prodotto non fondato',
      EDIT_PRODUCT: 'Modifica Prodotto',
      INVALID_ROW: 'Attenzione, file di importazione non valido \n riga numero',
      IMPORT_PREVIOUS_ROWS: 'Importa le righe precedenti?',
      SALES_PRICE: 'Prezzo di vendita',
      RESO: 'Reso - annullamento',
      GIFT: 'Gift',
      RESULT_COUNT: 'Numero elementi visualizzati',
      ALL: 'Tutti',
      TOTAL: 'Totale',
      TRANSFER_DETAILS: 'Dettaglio Trasferimento merce',
      CREATE_TRANSFER: 'Crea Trasferimento merce',
      SELL_POINT_ORIGINE: 'Pdv Partenza',
      SELL_POINT_DEST: 'Pdv Destinazione',
      TOTAL_QUANTITY_TRANSFER: 'QT. Tot. Art.Tras',
      TO_ZERO: 'Azzerare giacenze',
      CONFIRM_STOCK_ZERO: 'Desideri portare la giacenza a zero di tutti i prodotti?',
      INVENTORY: 'Inventario',
      DELETE_LAST_MOVEMENT_ROW: 'Cancellando questa riga cancellerai l\'intero movimento in quanto è l\'unica presente',
      CHOOSE_CLIENT: 'Scegli cliente',
    },
    STOCK_FORM: {
      PROGRESS_CODE: 'Codice',
      DESCRIPTION: 'Descrizione',
      CREATION_DATE: 'Data Creazione',
      BAR_CODE: 'Barcode',
      PRODUCT_HISTORY: 'Storico del prodotto',
      OPERATOR: 'Descr.',
      QUANTITY: 'Quantità',
      REMAINING_QUANTITY: 'Quantità rimanente',
      USE_TYPE: 'Tipo uso',
      PURCHASE_COST: 'Costo acq.',
      PRICE: 'Prz.',
      AVERAGE_COST: 'Costo medio',
      UNDER_ESCORT: 'Sotto scorta',
      PHOTO: 'Foto',
      COMMERCIAL_DESCRIPTION: 'Descr. comm.',
      CATEGORY: 'Cat.',
      SUBCATEGORIES: 'Sottocat.',
      PROVIDER: 'Forn.',
      ML_GR: 'Ml / Gr',
      BRAND: 'Marca',
      LINEA: 'Linea',
      STOCK: 'Giac.',
      ACTIVE: 'Attivo',
      CODE: 'Cod.',
      IVA: 'IVA',
      TOTAL_PURCHASE_COST: 'Totale Costo Acquisto',
      CAUSAL: 'Causale',
      GIFT: 'Omaggi',
      MIN_STOCK: 'S.scorta',
      STOCK_ALERT: 'Stock alert Prodotti',
    },
    STATISTIC_FORM: {
      TIME_LAPS: 'Intervallo di tempo',
      CATEGORY: 'Categoria',
      CLIENT: 'Cliente',
      SEARCH: 'Ricerca',
      PRODUCT: 'Articolo',
      QTT_SALES: 'Quantità venduta',
      ALL_SALES: 'Totale venduto',
      QTT_RESO: 'Quantità Reso - annullamento',
      ALL_RESO: 'Totale Reso - annullamento',
      ALL_PROFIT: 'Totale utile',
      MONTH_SALES_VALUE: 'Valore vendite per mese',
      MONTH_SALES_QTT: 'Quantità vendite per mese',
      TREATMENT: 'Servizio',
      CURRENT_WEEK: 'Settimana in corso',
      LAST_WEEK: 'Ultima settimana',
      CURRENT_MONTH: 'Mese in corso',
      LAST_MONTH: 'Ultimo mese',
      FIRST_TRIMESTER: 'Primo trimestre',
      SECOND_TRIMESTER: 'Secondo trimestre',
      THIRD_TRIMESTER: 'Terzo trimestre',
      FORTH_TRIMESTER: 'Quarto trimestre',
      FIRST_SEMESTER: 'Primo semestre',
      SECOND_SEMESTER: 'Secondo semestre',
      CURRENT_YEAR: 'Anno in corso',
      LAST_YEAR: 'Anno passato',
      NO_TREATMENT_FOUND: 'Nessun servizio trovato',
      NO_PRODUCT_FOUND: 'Nessun prodotto trovato',
      CSV: 'CSV',
      EXCEL: 'EXCEL',
      USERNAME: 'Nome utente',
      OPERATOR: 'Operatore',
      SALES_TYPE: 'Tipo vendite',
      NO_OPERATOR_FOUND: 'Nessun operatore trovato',
    },
    DASHBOARD: {
      TODAYS_BOOKING: 'Appuntamenti del giorno',
      NEXT_BIRTHDAY: 'I PROSSIMI COMPLEANNI',
      INCASSA: 'Previsione incasso giornaliero',
      FICHE_MEDIA: 'Fiche media',
      SCONTRINO_MEDIO: 'Scontrino medio',
      TOP_CLIENT: 'TOP CLIENTI',
      LAST2WEEKS: 'ULTIME 2 SETTIMANE',
      TOP_SERVICE: 'TOP SERVIZI',
      GO_TO_CONFIG: 'Non hai ancora configurato il tuo account. Clicca qui',
      COLLECTION_TREND_IN_THE_LAST_2_WEEKS: 'Andamento incassi ultime 2 settimane',
      NO_DATA: 'Nessun informazione da visualizzare',
    },
    DAY: {
      MONDAY: 'Lunedì',
      TUESDAY: 'Martedì',
      WEDNESDAY: 'Mercoledì',
      THURSDAY: 'Giovedì',
      FRIDAY: 'Venerdì',
      SATURDAY: 'Sabato',
      SUNDAY: 'Domenica',
      SCONTRINO_MEDIO: 'Scontrino medio'
    },
    REGISTER: {
      SIGN_UP: 'Registrati',
      ACTIVITY_NAME: 'Nome Attività',
      FIRST_NAME: 'Nome',
      LAST_NAME: 'Cognome',
      EMAIL: 'Email',
      PASSWORD: 'Password',
      CONFIRM_PASSWORD: 'Conferma password',
      MOBILE: 'Cellulare',
      CONFIRM: 'Conferma',
      EMAIL_EXISTS: 'Questo indirizzo e-mail non è disponibile',
      THANKS_FOR_SIGNUP: 'Grazie per esserti registrato!',
      // tslint:disable-next-line:max-line-length
      REGISTER_SUCCESS: 'Grazie per esserti registrato! Registrazione avvenuta con successo. Ti è stata mandata una email al tuo indirizzo. L’attivazione sarà completata solo dopo aver cliccato sul link ricevuto nell’email. Se non hai ricevuto nulla, controlla nella sezione spam/indesiderati',
      LENGTH_ERROR: 'Lunghezza pari o superiore a 6',
      ONE_UPPERCASE_ERROR: 'Contenere almeno un carattere maiuscolo',
      ONE_LOWERCASE_ERROR: 'Contengono almeno un carattere minuscolo',
      ONE_NUMBER_ERROR: 'Contenere almeno un numero',
      VALIDATION: 'Convalida',
    },
    IMAGE: {
      ADD_PICTURE: 'Aggiungi un\'immagine',
      OR: 'O',
      CLICK: 'Clicca qui',
      DRAG: 'Trascina l\'immagine qui',
      ADD: 'Aggiungi',
      CANCEL: 'Indietro',
      ANOTHER: 'Seleziona un altro',
      OVERSIZE: 'Attenzione, l\'immagine selezionata supera la dimensione massima consentita 2 MB',
      NOT_IMAGE: 'Seleziona un\'altra photo',
      FILE_FORMAT_NON_VALID: 'Formato file non valido',
    },
    INIT_ACCOUNT: {
      YOUR_COMPANY: 'La tua azienda',
      SELL_POINT: 'Punto vendita',
      KEY_EXPIRED: 'La sua chiave e-mail di registrazione è scaduta, la preghiamo di registrarsi di nuovo',
      BUSINESS_NAME: 'Regione sociale',
      VAT: 'Partita Iva',
      YOUR_BUSINESS: 'Il nome della tua attività',
      ADDRESS: 'Indirizzo',
      STREET: 'Civico',
      COUNTRY: 'Nazione',
      ZIP_CODE: 'CAP',
      PROVINCE: 'Provincia',
      CITY: 'Città',
      DEALER_CODE: 'Codice rivenditore',
      YOUR_PLAN: 'Il tuo piano',
      PLANNING_START_TIME: 'Orario di inizio planning',
      END_OF_PLANNING_HOURS: 'Orario di fine planning',
      OPERATOR: 'operatore',
      FIRST_NAME: 'Nome',
      LAST_NAME: 'Cognome',
      PASSWORD: 'Password',
      CONFIRM_PASSWORD: 'Conferma Password',
      USERNAME: 'Utente',
      PLANNING_NAME: 'Nome Planning',
      CABIN_NAME: 'Nome della cabina',
      CABIN: 'Cabin',
      USERNAME_NOT_AVAILABLE: 'Nome utente {{username}} già utilizzato',
      COMPANY_NAME_NOT_AVAILABLE: 'Ragione sociale {{businessName}} già utilizzato',
      VAT_NUMBER_NOT_AVAILABLE: 'Partita IVA {{vatNumber}} già utilizzato',
      ADD_OPERATOR_OR_CABIN: 'Per procedere, bisogna inserire almeno un operatore o una cabina',
      DID_NOT_ADD_CABIN: 'Non hai inserito una cabina. Vuoi procedere comunque?',
      DID_NOT_ADD_OPERATOR: 'Non hai inserito un operatore. Vuoi procedere comunque?',
    },
    FORGET_PASSWORD: {
      EXPIRED: 'Il link è scaduto, per favore riprova',
      FORGOT_YOUR_PASSWORD: 'Password dimenticata?',
      ENTER_USERNAME: 'Inserisci il tuo nome utente associato al tuo account',
      WE_WILL_EMAIL_YOU: 'Ti invieremo un’email per recuperare la password',
      WRITE_YOUR_USERNAME: 'Scrivi il tuo nome utente qui',
      YOU_JUST_GOT_EMAIL: 'Riceverai un email contenente le istruzioni per la generazione della nuova password',
      CHANGE_YOUR_PASSWORD: 'Modifica la tua password',
      USERNAME_NOT_FOUND: 'Nome utente non valido',
    },
    FISCAL_REPORT: {
      FISCAL_REPORT: 'REPORT FINANZIARIO',
      TOTAL_CASSA: 'Totale in Cassa',
      TOTAL_BANCA: 'Totale in Banca',
      TOTAL_DOC: 'VENDITE ',
      WITHOUT_SCONTO: 'Vendite totali (senza sconto)',
      SCONTO: 'Sconti applicati',
      TOTAL: 'Totale scontato',
      ABANDFID: 'ABBONAMENTI & FIDELITY',
      TOTAL_AB_VEND: 'Totale abbonamenti venduti',
      TOTAL_GC_VEND: 'Totale giftcard vendute',
      TOTAL_LC_VEND: 'Totale Ricariche vendute',
      TOTAL_PAG: 'PAGAMENTI',
      SOLD: 'Vendite',
      CASH: 'Contanti',
      CREDIT: 'Carta di credito',
      ASSEGNO: 'Assegno',
      BONIFICO: 'Bonifico',
      TOT: 'Totale',
      QUOTE: 'Preventivi',
      TOTAL_RESO: 'Reso - annullamento totale',
    },
    FIRST_NOTE: {
      FIRST_NOTE: 'Prima nota',
      FIRST_NOTE_CAUSE: 'Configurazione causali',
      NAME: 'Nome',
      DATE: 'Data',
      INCOME: 'ENTRATA',
      OUTLAY: 'USCITA',
      DELETE_MSG: 'L\'eliminazione di una riga dalla prima nota non è reversibile, sicuro di voler continuare?',
      DELETE_CAUSE_MSG: 'L\'eliminazione di una riga dalla prima nota causale non è reversibile, sicuro di voler continuare?',
      TOTAL_INCOME: 'TOTALE ENTRATE',
      TOTAL_OUTLAY: 'TOTALE USCITE',
      BALANCE_FIRST_NOTE: 'SALDO PRIMA NOTA',
    },
    SATISPAY: {
      SATISPAY_CONFIG: 'Configurazione Satispay',
      INVALID_RSA_KEY: 'Chiave RSA non valida',
      TOKEN_ALREADY_PAIRED: 'Token già abbinato',
      DEVICE_TOKEN_NOT_FOUND: 'Token del dispositivo non trovato',
      GENERATE: 'Genera',
      TEST_AUTHENTICATION: 'Test di validazione',
      RESET_CONFIGURATION: 'Reset configurazione',
      ARE_YOU_SURE: 'Sei davvero sicuro?',
      YOU_CANNOT_USE_SATISPAY: 'Se procedi non potrai più utilizzare come metodo di pagamento <b>Satispay</b> fino a quando procederai ' +
        'con una nuova configurazione',
      PLEASE_TYPE: `Inserisci il seguente codice <b>{{data}}</b> per confermare la cancellazione`
    },
    PROMO_FORM: {
      ID: 'ID',
      NO_PROMO_FOUND: 'Nessun promozione trovato',
      ADD_PROMO: 'Aggiungi promozione',
      SMS: 'Sms',
      MAIL: 'E-mail',
      TYPE: 'Cosa vuoi inviare?',
      MODEL: 'Modello di promozione',
      OBJECT: 'Oggetto',
      MESSAGE: 'Messaggio',
      SENT_DATE_HOUR: 'Data e ora invio',
      SENT_HOUR: 'Ora invio',
      RECEIVER: 'Numero destinatari',
      RECEIVERS: 'Destinatari di promozione',
      FILTER_RECEIVERS: 'Filtro destinatari',
      CREATION_DATE: 'Data creazione',
      PROMO_DETAILS: 'Dettaglio promozione',
      LAST_MONTH: 'Mese scorso',
      LAST_THREE_MONTHS: 'Ultimi tre mesi',
      LAST_SIX_MONTHS: 'Ultimi sei mesi',
      LAST_NINE_MONTHS: 'Ultimi nove mesi',
      LAST_YEAR: 'L\'anno scorso',
      STATUS: 'Stato',
      SCHEDULED: 'In attesa',
      DONE: 'Inviato',
      CANCELED: 'Fallito',
      ANNULLED: 'Annullato',
      REACTIVE: 'Riattivare promozione',
      ANNUL: 'Annulato promozione',
      MAIL_AND_SMS: 'Mail e Sms',
      EDIT_TEMPLATE: 'Modifica template',
    },
    MOBILE_APP_CONFIG: {
      TOLERANCE_HOURS: 'Tolleranza cancellazione appuntamento (in ore)',
      UPDATED_SUCCESSFULLY: 'Configurazione app mobile effettuata con successo',
      NOTIFICATION_REMINDER_HOURS: 'Promemoria di notifica (in ore)',
      QR_CODE: 'Codice QR',
      DISABLE_RDV: 'Disattiva presa appuntamento'
    },
    QUICK_SIDEBAR: {
      SUBSCRIBE_TO_PUSH_NOTIFICATIONS: 'Abbonarsi alle notifiche push',
      UNSUBSCRIBE_FROM_PUSH_NOTIFICATIONS: 'Cancellazione delle notifiche push',
      NO_NOTIFICATION: 'Nessuna notifica presente',
    },
    PROMO_EDITOR: {
      NAME: 'Nome',
      DESCRIPTION: 'Descrizione',
      TEMPLATE_DETAILS: 'Dettagli del template',
      SELECT_TEMPLATE: 'Seleziona template',
      NO_TEMPLATE_FOUND: 'Nessun template trovato',
      DELETE_MSG: 'Stai per cancellare questo template! Sei sicuro?',
      TEMPLATE_USED: 'Questo template non può essere cancellato, perché è usato',
      TEMPLATE_SAVED: 'Il template è stato salvato con successo',
    },
    PROMO_SOCIAL: {
      STATUS: 'Stato',
      FAILED: 'Fallito',
      PENDING: 'In sospeso',
      SENT: 'Inviato',
      CREATED_AT: 'Creato in',
      NO_POST_FOUND: 'Nessuna pubblicazione trovata',
    },
    COMP_FILTER: {
      TODAY: 'Today',
      YESTERDAY: 'Yesterday',
      LAST_7_DAYS: 'Last 7 days',
      LAST_30_DAYS: 'Last 30 days',
      THIS_MONTH: 'This month',
      LAST_MONTH: 'Last month',
      LAST_3_MONTHS: 'Last 3 months',
      LAST_6_MONTHS: 'Last 6 months',
      CURRENT_YEAR: 'Current year',
      LAST_YEAR: 'Last year',
    },

  MULTIMEDIA: {
    THEME_UPDATED: 'Tema aggiornato',
    THEME: {
      MAIN_COLORS: 'Colori principali',
      MAIN_COLOR: 'Colore principale',
      PRIMARY_COLOR:'Colore primario',
      SECOND_COLOR: 'Colore secondario',
      HEADER_THEME: 'Tema Header 1',
      CHECKBOX_COLOR: 'Colore di Checkbox ',
      RADIO_COLOR:'Colore di Radio Button',
      HEADER_THEME_OWNER: 'Tema Header',
      SUB_HEADER_THEME: 'Tema Header 2',
      FOOTER_THEME: 'Tema Footer 1',
      FOOTER_THEME_OWNER: 'Tema Footer',
      SUB_FOOTER_THEME: 'Tema Footer 2',
      SOCIAL_ICONS_THEME: 'Tema icone sociali',
      HEADER_ICONS_THEME: 'Tema icone header',
      DARK: 'Oscura',
      LIGHT: 'Luce',
      CANCEL_ICONS: 'Colore pulsante Annulla',
      CONFIRM_ICONS: 'Colore pulsante Conferma',

    }
  },
  }
};

import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SatispayService {
  private API = environment.api + '/satispay-config';

  constructor(private http: HttpClient) {
  }

  addConfig(token) {
    return this.http.post<{ message: string, success: boolean }>(this.API, token);
  }

  getCurrentConfig() {
    return this.http.get<any>(this.API);
  }

  testAuthentication() {
    return this.http.get<any>(this.API + '/test-authentication');
  }

  checkForPayment(id) {
    return this.http.get<VerifyAndPayResponse>(this.API + '/verify-payment/' + id);
  }

  hasConfig() {
    return this.http.get<boolean>(this.API + '/has-config/');
  }

  resetConfig() {
    return this.http.delete<void>(this.API);
  }
}

interface VerifyAndPayResponse {
  status: 'PENDING' | 'ACCEPTED';
  paymentResponse: PaymentResponse;
  failed: boolean;
  failMsg: string;
}

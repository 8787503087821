// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fingerprint-icon {
  font-size: 10rem;
  color: #2f2fe1;
  display: inline-block;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(to right, #2eb05b, #2eb05b 50%, #F2384E 50%);
  background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 275ms ease-in-out;
  /*.animate {
    animation: flashing ease-in-out infinite 3s;
  }*/
}

.animate {
  background-position: 0 100%;
}

@keyframes flashing {
  0% {
    color: black;
  }
  50% {
    color: #878787;
  }
  100% {
    color: black;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/authentication/barcode-authentication/barcode-authentication.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,cAAA;EACA,qBAAA;EACA,6BAAA;EACA,oCAAA;EACA,8EAAA;EACA,0BAAA;EACA,yBAAA;EACA,iDAAA;EACA;;IAAA;AAGF;;AACA;EACE,2BAAA;AAEF;;AAAA;EACE;IACE,YAAA;EAGF;EADA;IACE,cAAA;EAGF;EADA;IACE,YAAA;EAGF;AACF","sourcesContent":[".fingerprint-icon {\r\n  font-size: 10rem;\r\n  color: #2f2fe1;\r\n  display: inline-block;\r\n  -webkit-background-clip: text;\r\n  -webkit-text-fill-color: transparent;\r\n  background-image: linear-gradient(to right, #2eb05b, #2eb05b 50%, #F2384E 50%);\r\n  background-size: 200% 100%;\r\n  background-position: 100%;\r\n  transition: background-position 275ms ease-in-out;\r\n  /*.animate {\r\n    animation: flashing ease-in-out infinite 3s;\r\n  }*/\r\n}\r\n.animate {\r\n  background-position: 0 100%;\r\n}\r\n@keyframes flashing {\r\n  0% {\r\n    color: black;\r\n  }\r\n  50% {\r\n    color: #878787;\r\n  }\r\n  100% {\r\n    color: black;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {QuickSidebarComponent} from './quick-sidebar.component';
import {TranslateModule} from "@ngx-translate/core";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {InfiniteScrollModule} from "ngx-infinite-scroll";


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    ScrollingModule,
    InfiniteScrollModule,
  ],
  declarations: [QuickSidebarComponent],
  exports: [QuickSidebarComponent]
})
export class QuickSidebarModule {
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
      .time-input {
          width: 4rem;
          text-align: center;
          border: aliceblue;
      }

      .checkbox {
          display: inline-block
      }

      ::ng-deep .position-ng-select.ng-dropdown-panel {
          z-index: 1500;
      }
  `, "",{"version":3,"sources":["webpack://./src/app/content/pages/configurations/operators-planning/plannings/planning-form/operator-planning-form.component.ts"],"names":[],"mappings":";MACM;UACI,WAAW;UACX,kBAAkB;UAClB,iBAAiB;MACrB;;MAEA;UACI;MACJ;;MAEA;UACI,aAAa;MACjB","sourcesContent":["\n      .time-input {\n          width: 4rem;\n          text-align: center;\n          border: aliceblue;\n      }\n\n      .checkbox {\n          display: inline-block\n      }\n\n      ::ng-deep .position-ng-select.ng-dropdown-panel {\n          z-index: 1500;\n      }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

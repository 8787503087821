import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthService} from '../shared/services/auth-jwt.service';
import {UserService} from '../shared/services/user.service';
import {  MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {ChooseSellPointResponse, SellPointService} from '../shared/services/sell-point.service';
import {TranslateService} from '@ngx-translate/core';
import {TranslationLoaderService} from '../core/services/translation-loader.service';
import {AuthResponse} from '../shared/models/auth-response';
import {DateAdapter} from '@angular/material/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {BarcodeAuthenticationComponent} from './barcode-authentication/barcode-authentication.component';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import * as Swal from 'sweetalert2';
import {environment} from '../../environments/environment.prod';
import {OwnerService} from "../administrative-content/pages/owner/services/owner.service";
import {FontColorPipe} from "font-color-pipe";
import {SweetAlertService} from "../shared/services/sweet-alert.service";
@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit, OnDestroy {

  images = [
    './assets/img/slides/slide-1.png',
    './assets/img/slides/slide-2.png'
  ];
  loginForm: UntypedFormGroup;
  response: ChooseSellPointResponse;
  @ViewChild('carousel', { static: true }) carousel: ElementRef;
  sellPointFormControl = new UntypedFormControl(null, Validators.required);
  private pendingToken: string;
  showChooseSellPoint = false;
  private unsubscribe$ = new Subject();
  version = environment.version;
  superHeader: any = null;
  colorText = "gray";

  constructor(
    private authService: AuthService,
    private sweetAlertService: SweetAlertService,
    private formBuilder: UntypedFormBuilder,
    private ownerService: OwnerService,
    private sellPointService: SellPointService,
    private userService: UserService,
    private matSnackBar: MatSnackBar,
    private router: Router,
    private translateService: TranslateService,
    public translationLoaderService: TranslationLoaderService,
    private dateAdapter: DateAdapter<any>,
    private matDialog: MatDialog,
    private colorPipe: FontColorPipe,
  ) {
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: [null, Validators.required],
      password: [null, Validators.required],
      rememberMe: false
    });
    this.getSuperHeaderDescription();
  }

  get username() {
    return this.loginForm.get('username') as UntypedFormControl;
  }

  get password() {
    return this.loginForm.get('password') as UntypedFormControl;
  }

  get rememberMe() {
    return this.loginForm.get('rememberMe') as UntypedFormControl;
  }

  login() {
    let username;
    let password;
    const loginRequest = {username, password} = this.loginForm.value;
    this.authService.login(loginRequest).subscribe((resp: AuthResponse) => {
      if (resp && resp.switchedLicense) {
        this.sweetAlertService.warning(this.translateService.instant('LOGIN.SWITCH_LICENSE_MSG'));
      }
      if (resp && resp.status.startsWith('PENDING')) { // Pending token
        this.pendingToken = resp.token;
        const header = new HttpHeaders().set('Authorization', `Bearer ${this.pendingToken}`);
        if (resp.status === 'PENDING_SUPER_ADMIN') {
          this.sellPointService.getSellPointsFromGroup(header).subscribe(data => {
            this.response = data;
            this.showChooseSellPoint = true;
          });
          return;
        }
        this.sellPointService.getSellPointsFromAccount(header).subscribe(data => {
          this.response = data;
          this.showChooseSellPoint = true;
        });
        return;
      }
      this.userService.identity().subscribe((u) => {
        this.userService.saveUser(u, this.rememberMe.value);
        if (u.authorities.some(e => e === 'OWNER')) {
          this.router.navigate(['/admin/owner']);
          return;
        }
        if (u.authorities.some(e => e === 'GROUP') && !u.sellPointId) {
          this.router.navigate(['/admin']);
          return;
        }
        if (u.authorities.some(e => e === 'GROUP') && u.sellPointId) {
          this.router.navigate(['/']);
          return;
        }
        this.router.navigate(['/']);
      });
    }, (error: HttpErrorResponse) => {
      if (error.error.message.includes('INVALID_CREDENTIALS')) {
        this.matSnackBar.open(this.translateService.instant('LOGIN.WRONG_USERNAME_PASSWORD'), 'Ok', {
          duration: 5000,
          panelClass: 'white-snackbar'
        });
      } else if (error.error.message.includes('ACCOUNT_LOCKED')) {
        this.matSnackBar.open(this.translateService.instant('LOGIN.ACCOUNT_LOCKED'), 'Ok', {
          duration: 5000,
          panelClass: 'white-snackbar'
        });
      }

    });
  }

  chooseSellPoint() {
    const header = new HttpHeaders().set('Authorization', `Bearer ${this.pendingToken}`);
    this.authService.chooseSellPoint(this.sellPointFormControl.value, this.rememberMe.value, header).subscribe(data => {
      this.userService.identity().subscribe(u => {
        this.userService.saveUser(u, this.rememberMe.value);
        this.router.navigate(['/']);
      });
    });
  }

  clear() {
    this.authService.logout().subscribe();
    this.response = null;
    this.showChooseSellPoint = false;
  }

  changeLang(lang: 'en' | 'it' | 'fr') {
    this.translationLoaderService.setLanguage(lang);
    this.dateAdapter.setLocale(lang);
  }

  openBarcodeAuthentication() {
    this.matDialog.open(BarcodeAuthenticationComponent, {
      width: '600px'
    }).afterClosed().pipe(takeUntil(this.unsubscribe$)).subscribe(code => {
      this.authService.loginWithBarcode(code, this.rememberMe.value).subscribe((resp: AuthResponse) => {
        if (resp && resp.status) { // Pending token
          this.pendingToken = resp.token;
          const header = new HttpHeaders().set('Authorization', `Bearer ${this.pendingToken}`);
          if (resp.status === 'PENDING_SUPER_ADMIN') {
            this.sellPointService.getSellPointsFromGroup(header).subscribe(data => {
              this.response = data;
              this.showChooseSellPoint = true;
            });
            return;
          }
          this.sellPointService.getSellPointsFromAccount(header).subscribe(data => {
            this.response = data;
            this.showChooseSellPoint = true;
          });
          return;
        }
        this.userService.identity().subscribe((u) => {
          this.userService.saveUser(u, this.rememberMe.value);
          this.router.navigate(['/']);
        });
      }, (error: HttpErrorResponse) => {
        if (error.error.message.includes('INVALID_CREDENTIALS')) {
          this.matSnackBar.open(this.translateService.instant('LOGIN.WRONG_USERNAME_PASSWORD'), 'Ok', {
            duration: 5000,
            panelClass: 'white-snackbar'
          });
          return;
        }
        if (error.error.message.includes('ACCOUNT_LOCKED')) {
          this.matSnackBar.open(this.translateService.instant('LOGIN.ACCOUNT_LOCKED'), 'Ok', {
            duration: 5000,
            panelClass: 'white-snackbar'
          });
          return;
        }
        if (error.error.message.includes('OWNER_OR_SUPEADMIN_BARCODE_ERROR')) {
          this.matSnackBar.open(this.translateService.instant('LOGIN.OWNER_OR_SUPEADMIN_BARCODE_ERROR'), 'Ok', {
            duration: 15000,
            panelClass: 'white-snackbar'
          });
          return;
        }
      });
    });
  }

  getSuperHeaderDescription() {
    this.ownerService.getSuperHeader().subscribe(res => {
      this.superHeader = res;
      if(this.superHeader.color) {
      this.colorText =  this.superHeader.color ? this.colorPipe.transform(this.superHeader.color) : '#ffffff';
      console.log('colorText = ', this.colorText);
      }
      console.log('superheader = ', res);
    }, error => {
      console.log('error in superheader call = ', error);
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(void 0);
    this.unsubscribe$.complete();
  }
}

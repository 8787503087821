import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ConfigCourtesyRtDto, PrintCourtesyRtDto} from '../dto/config-courtesy-rt-dto';

@Injectable({
  providedIn: 'root'
})
export class CourtesyRtService {

  CONFIGURATION_API = environment.api + '/courtesy-rt';

  constructor(private http: HttpClient) {}
  mapConfigurations(xml) {
    return this.http.post(`${this.CONFIGURATION_API}/config`, xml);
  }
  getHeader(id:any){
    console.log(id)
    return this.http.get(`${this.CONFIGURATION_API}/header/` + id)
  }
  getFooter(id:any){
    return this.http.get(`${this.CONFIGURATION_API}/footer/`,id)

  }
  getAllConfigs() {
    return this.http.get(`${this.CONFIGURATION_API}/config`);
  }
  getActiveConfig(){
    return this.http.get(`${this.CONFIGURATION_API}/active-config`, {responseType:'json'});
  }
  saveCourtesyRtConfig(courtesyRtConfig: ConfigCourtesyRtDto) {
    return this.http.post<ConfigCourtesyRtDto>(`${this.CONFIGURATION_API}`, courtesyRtConfig);
  }

  updateCourtesyRtConfig(rtConfig: ConfigCourtesyRtDto) {
    return this.http.post<ConfigCourtesyRtDto>(`${this.CONFIGURATION_API}/update-config`, rtConfig);
  }

  centerText(text:string, maxChars:number){
    let emptyChars = maxChars - text.trim().length;
    let spacesBefore;
    let spacesAfter;
    let charsToCenter = text.trim();
    if(emptyChars % 2 === 0){
      //SPACES LEFT ARE EVEN
      spacesBefore = emptyChars/2;
    }else{
      //SPACES LEFT ARE ODD
      spacesBefore = (emptyChars/2) - 0.5;
    }
    spacesAfter = maxChars - (spacesBefore + charsToCenter.length); //
    let res = '';
    for (let i = 0; i < spacesBefore; i++){
      res += ' ';
    }
    res += charsToCenter;
    for (let i = 0; i < spacesAfter; i++){
      res += ' ';
    }
    return res

  }
  deleteConfig(id) {
    return this.http.delete(`${this.CONFIGURATION_API}/` + id, {observe: 'response'});
  }
  saveHeader(header: PrintCourtesyRtDto[]){
      return this.http.post(`${this.CONFIGURATION_API}/header/`, header);
  }
  saveFooter(footer: PrintCourtesyRtDto[]){
    return this.http.post(`${this.CONFIGURATION_API}/footer/`, footer);

  }
  /*
  syncHeader(){

  }
  syncFooter(){

  }*/
  synchronizeConfig(body, id) {
    return this.http.put(`${this.CONFIGURATION_API}/sync/` + id, body);
  }

  editStatus(id: any) {
    return this.http.get(`${this.CONFIGURATION_API}/status/` + id);
  }

  getSalesWithoutPrint() {
    return this.http.get(`${this.CONFIGURATION_API}/pay-without-print`);
  }
  buildCourtesyBookingTicket(d: number) {
    return this.http.get<{ ip: string, cmd: string }>(`${this.CONFIGURATION_API}/build-courtesy-booking-ticket/${d}`,{responseType:'json'});
  }
  buildCourtesySubscriptionTicket(d: number) {
    return this.http.get<{ ip: string, cmd: string }>(`${this.CONFIGURATION_API}/build-courtesy-subscription-ticket/${d}`, {responseType:'json'});
  }
  buildSpecificTicketCommandCourtesyRt(id: number) {
    return this.http.post<any>(`${this.CONFIGURATION_API}/build-courteesy-specific-ticket`, id);
  }
  print(body: any) {
    // @ts-ignore
    return this.http.post<any>('http://localhost:3000/courtesy-rt/print', body, {responseType: 'text'});
  }
  buildCourtesyUseReloadCardTicket(reload_items){
    return this.http.post(`${this.CONFIGURATION_API}/build-courtesy-use-reload-card-ticket`, reload_items);
  }
  buildCourtesyUseGiftCardTicket(gift_items){
    return this.http.post(`${this.CONFIGURATION_API}/build-courtesy-use-gift-card-ticket`, gift_items);
  }
  buildCardSubscriptionTicket(d: number) {
    return this.http.get<{ ip: string, cmd: string }>(`${this.CONFIGURATION_API}/build-courtesy-card-ticket/${d}`, {responseType:'json'});
  }

  buildUseReloadCardWithCourtesyRtTicket(paymentId: number) {
    return this.http.get<{ ip: string, cmd: string }>(`${this.CONFIGURATION_API}/build-courtesy-use-reload-card-ticket/${paymentId}`, {responseType:'json'});
  }
  buildUseGiftCardWithCourtesyRtTicket(paymentId: number) {
    return this.http.get<{ ip: string, cmd: string }>(`${this.CONFIGURATION_API}/build-courtesy-use-gift-card-ticket/${paymentId}`, {responseType:'json'});
  }
}

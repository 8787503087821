// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subheader {
  display: flex;
  justify-content: space-between;
}

.mat-column-action {
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/app/administrative-content/pages/owner/components/update-version/update-version-list/update-version-list.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;AACF;;AACA;EACE,iBAAA;AAEF","sourcesContent":[".subheader {\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n.mat-column-action {\r\n  text-align: right;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

export enum PaymentMethodsType {
  SATISPAY = 'SATISPAY',
  CASH = 'CASH',
  CHEQUE = 'CHEQUE',
  CREDIT_CARD = 'CREDIT_CARD',
  TRANSFER = 'TRANSFER',
  GROUPON = 'GROUPON',
  FIDELITY_CARD = 'FIDELITY_CARD',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

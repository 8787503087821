import {FidelityCardType} from '../enum/fidelity-card-type';

export class MappedCard {
  amount: number;
  usage?: number;
  number: string;
  name: string;
  cardType: FidelityCardType;
  discount: string;
  emissionDate: string; //Data Emissione
  expirationDate: string; //Data di scadenza

}

export class MappedSubscription {
    amount: number;
    name: string;
    emissionDate: string;
    entries: {description: string, quantity: number}[];
}

export class MappedQuote {
  client?: string;
  dataNascita?: string;
  codiceFiscale?: string;
  telefono?: string;
  paymentEntry: {description: string, total: number, iva: number}[]
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {AppConfigurationsComponent} from "./app-configurations.component";
import {MatTabsModule} from "@angular/material/tabs";
import {MatButtonModule} from "@angular/material/button";
import {TranslateModule} from "@ngx-translate/core";
import {MatIconModule} from "@angular/material/icon";
import {MatDividerModule} from "@angular/material/divider";
import {MatRippleModule} from "@angular/material/core";
import {BannerEditComponent} from './banner-edit/banner-edit.component';
import {BannerListComponent} from './banner-list/banner-list.component';
import {ImageCropperModule} from "ngx-image-cropper";
import {ReactiveFormsModule} from "@angular/forms";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {EditorComponent} from './editor/editor.component';
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatLegacyDialogModule} from '@angular/material/legacy-dialog';
import {QRCodeModule} from 'angularx-qrcode';


const routes: Routes = [
  {
    path: '',
    component: AppConfigurationsComponent,
    children: [
      {path: '', component: BannerListComponent},
      {path: 'edit', component: BannerEditComponent},
    ]
  }
];

@NgModule({
  declarations: [AppConfigurationsComponent, BannerEditComponent, BannerListComponent, EditorComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatTabsModule,
    MatButtonModule,
    TranslateModule,
    MatIconModule,
    MatDividerModule,
    MatRippleModule,
    ImageCropperModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatLegacyDialogModule,
    QRCodeModule,
  ]
})
export class AppConfigurationsModule {
}

import {EventEmitter, Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {SearchResponse} from '../dto/search-response';
import {Abonnement} from '../models/Abonnement';
import {LazyRequest} from '../dto/lazy-request';
import {SubscriptionType} from '../enum/subscription-type.enum';
import {SubscriptionInstance} from '../models/subscriptionInstance';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  SUBSCRIPTION_API = environment.api + '/subscriptions';
  formDirtyChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(private http: HttpClient) {
  }
  emitFormDirtyChanged(isDirty: boolean) {
    this.formDirtyChanged.emit(isDirty);
  }
  add(subscription: Abonnement) {
    return this.http.post<Abonnement>(`${this.SUBSCRIPTION_API}`, subscription);
  }

  update(subscription: Abonnement) {
    return this.http.put<Abonnement>(`${this.SUBSCRIPTION_API}`, subscription);
  }

  getLazySubscriptionList(request: SusbcriptionLazyRequest) {
    return this.http.post<SearchResponse<Abonnement>>(`${this.SUBSCRIPTION_API}/filter`, request);
  }

  delete(id) {
    return this.http.delete(`${this.SUBSCRIPTION_API}/${id}`);
  }

  getByInstance(id) {
    return this.http.get<Abonnement>(`${this.SUBSCRIPTION_API}/${id}`);
  }

  getSubscriptionInstances(request: SusbcriptionLazyRequest) {
    return this.http.post<SearchResponse<SubscriptionInstance>>(`${this.SUBSCRIPTION_API}/instances/filter`, request);
  }

  getAvailableInstance(clientId) {
    return this.http.get<Abonnement[]>(`${this.SUBSCRIPTION_API}/availableInstance/${clientId}`);
  }


}


class SusbcriptionLazyRequest extends LazyRequest {
  textSearch?: string;
  type?: SubscriptionType;
  clientId?: number;
  available?: boolean;
}

import {Component, Input, OnInit} from '@angular/core';
import {Client} from '../../../../../shared/models/client';
import {Subject} from 'rxjs';
import {UntypedFormBuilder, UntypedFormControl} from '@angular/forms';
import {Tint} from '../../../../../shared/models/Tint';
import {TintService} from '../../../../../shared/services/tint.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {   MatSnackBar } from '@angular/material/snack-bar';
import {SweetAlertService} from '../../../../../shared/services/sweet-alert.service';
import {TranslateService} from '@ngx-translate/core';
import {MovementProduct} from '../../../../../shared/models/movement-product';

@Component({
  selector: 'app-client-tint',
  templateUrl: './client-tint.component.html',
  styleUrls: ['./client-tint.component.scss']
})
export class ClientTintComponent implements OnInit {
  @Input() client: Client;
  columns = [
    'DATA_TABLE.CREATION_DATE',
    'DATA_TABLE.COLOR',
    'CLIENT_FORM.NOTE',
    'DATA_TABLE.OPERATOR',
    ''
  ];
  rows: Tint[] = [];
  searchFormControl = new UntypedFormControl();
  private formBuilder = new UntypedFormBuilder();
  totalRecords: number;
  pageSize = 10;
  page = 1;
  unsubscribe = new Subject();
  loading = true;
  firstCall = true;
  editMode = false;
  selectedTint: Tint;

  constructor(private tintService: TintService,
              private matDialog: MatDialog,
              private snackBar: MatSnackBar,
              private sweetAlertService: SweetAlertService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.loadTints();
  }

  loadTints() {
    this.tintService.findByClient(this.client.id).subscribe(r =>
      this.rows = r
    );
  }


  addTint(content) {
    this.editMode = false;
    this.openTintForm(content);
  }

  openEdit(tint: Tint, content) {
    this.editMode = true;
    this.selectedTint = tint;
    this.openTintForm(content);
  }

  openTintForm(content) {
    const dialogRef = this.matDialog.open(content,
      {
        disableClose: true,
        autoFocus: true, width: '800px'
      });
    dialogRef.afterClosed().subscribe((d: MovementProduct) => {
      this.loadTints();
    });
  }

  delete(tint: Tint) {
    this.sweetAlertService.warning(this.translate.instant('DIALOG.YOU_WANT_TO_DELETE') + ' ' + tint.color)
      .then(res => {
        if (res.value) {
          this.tintService.delete(tint.id).subscribe(r => {
            this.snackBar.open(this.translate.instant('DIALOG.DELETE_SUCCESS'), 'Ok', {duration: 5000, panelClass: 'white-snackbar'});
            this.loadTints();
          }, error => this.sweetAlertService.danger(this.translate.instant('DIALOG.CANNOT_DELETE')));
        }
      });
  }
}

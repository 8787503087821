import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RtComponent} from './rt.component';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {MatLegacyButtonModule} from '@angular/material/legacy-button';
import {SharedModule} from '../../../../shared/shared.module';
import { CurrencyMaskModule } from "ng2-currency-mask";
import {IvaRtRepartoConfigComponent} from './iva-rt-reparto-config/iva-rt-reparto-config.component';
import {PaymentRtPagamentoConfigComponent} from './payment-rt-pagamento-config/payment-rt-pagamento-config.component';
import {   MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {  MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {NgbPopoverModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {  MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatIconModule} from "@angular/material/icon";

const routes: Routes = [
  {path: '', component: RtComponent}
];

@NgModule({
    declarations: [RtComponent, IvaRtRepartoConfigComponent, PaymentRtPagamentoConfigComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FormsModule,
        TranslateModule,
        MatLegacyButtonModule,
        ReactiveFormsModule,
        SharedModule.forChild(),
        CurrencyMaskModule,
        MatCardModule,
        MatProgressSpinnerModule,
        NgbPopoverModule,
        NgbTooltipModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        MatIconModule
    ]
})

export class RtModule {
}

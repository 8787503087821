import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {SellPoint} from '../models/sell-point';
import {map} from 'rxjs/operators';
import {AuthResponse} from '../models/auth-response';

@Injectable()
export class SellPointService {
  readonly API = environment.api + '/sell-points';

  constructor(private http: HttpClient) {
  }

  addSellPoint(sellPoint, centerId) {
    sellPoint.startHour = this.fixTimeFormat(sellPoint.startHour);
    sellPoint.finishHour = this.fixTimeFormat(sellPoint.finishHour);
    return this.http.post<SellPoint>(`${this.API}`, {...sellPoint, centerId});
  }

  getAllSellPoints() {
    return this.http.get<SellPoint[]>(`${this.API}`).pipe(map(el => {
      return el.map(u => {
        u.centerName = u.center.companyName;
        u.name = u.siege ? 'S-' + u.name : u.name;
        return u;
      });
    }));
  }

  getSellPointsByCenter(centerId) {
    return this.http.get<SellPoint[]>(`${this.API}/center/${centerId}`);
  }

  private fixTimeFormat(time: Date) {
    let hours = '' + time.getHours();
    let minutes = '' + time.getMinutes();
    if (time.getHours() < 10) {
      hours = '0' + hours;
    }
    if (time.getMinutes() < 10) {
      minutes = '0' + minutes;
    }
    return [hours, minutes].join(':');
  }

  editSellPoint(sellPoint) {
    sellPoint.startHour = this.fixTimeFormat(sellPoint.startHour);
    sellPoint.finishHour = this.fixTimeFormat(sellPoint.finishHour);
    return this.http.put<SellPoint>(`${this.API}`, sellPoint);
  }

  getSellPointsFromAccount(header?: HttpHeaders) {
    return this.http.get<ChooseSellPointResponse>(`${this.API}/account`, {headers: header});
  }

  getSellPointsFromGroup(header?: HttpHeaders) {
    return this.http.get<ChooseSellPointResponse>(`${this.API}/group`, {headers: header});
  }

  getWorkTime() {
    return this.http.get<{ startHour: string, finishHour: string }>(`${this.API}/get-work-time`);
  }

  getCurrentSellPoint() {
    return this.http.get<SellPoint>(`${this.API}/current-sell-point`);
  }

  deleteSellPoint(id: number) {
    return this.http.delete<void>(`${this.API}/${id}`);
  }

  chooseSellPoint(id) {
    return this.http.post<AuthResponse>(environment.api + '/auth/sell-point', id);
  }

  updateUsePrintingPopupForCurrentSellPoint(b) {
    return this.http.put<void>(`${this.API}/use-printing-popup`, {usePrintingPopup: b});
  }

  getUsePrintingPopup() {
    return this.http.get<boolean>(`${this.API}/use-printing-popup`);
  }

  getSellPointsByAccount() {
    return this.http.get<ChooseSellPointResponse>(`${this.API}/account`);
  }

  getSellPointsByAccountByCentre() {
    return this.http.get<ChooseSellPointResponse>(`${this.API}/account-by-centre`);
  }

  getSellPointsByGroup() {
    return this.http.get<ChooseSellPointResponse>(`${this.API}/group`);
  }

}

export class ChooseSellPointResponse {
  sellPoints: { sellPointId: number, sellPointLabel: string, siege: boolean }[];
  profile: string;
  fullName: string;
}

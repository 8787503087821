import {Component, ElementRef, HostListener, Inject, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {   MatSnackBar } from '@angular/material/snack-bar';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {WeekPlanningService} from '../services/week-planning.service';
import {WeekPlanningTemplate} from '../models/week-planning-template';
import {YearPlanningService} from '../services/year-planning.service';
import {SellPointService} from '../services/sell-point.service';
import {TranslateService} from '@ngx-translate/core';
import {SweetAlertService} from "../services/sweet-alert.service";

@Component({
  selector: 'app-week-planning-form',
  templateUrl: './week-planning-form.component.html',
  styleUrls: ['./week-planning-form.component.scss']
})
export class WeekPlanningFormComponent implements OnInit {
  @ViewChild('descInput', { static: true }) descInput: ElementRef;
  weekPlanningForm: UntypedFormGroup;
  unsubscribe$ = new Subject();
  startTime = '08:00:00';
  endTime = '20:00:00';
  submitted = false;
  addedElement = false;
  addMultipleCheckbox = new UntypedFormControl(false);
  columns = ['DATA_TABLE.DAY', 'DATA_TABLE.DAY_OFF', 'DATA_TABLE.FROM',
    'DATA_TABLE.TO', 'DATA_TABLE.PAUSE', 'DATA_TABLE.FROM', 'DATA_TABLE.TO'];
  weekPlanningTemplate: WeekPlanningTemplate = new WeekPlanningTemplate();
  patchedValue = false;

  constructor(public dialogRef: MatDialogRef<WeekPlanningFormComponent>,
              private planningService: WeekPlanningService,
              private yearPlanningService: YearPlanningService,
              private sweetAlertService: SweetAlertService,
              private snackBar: MatSnackBar, private sellPointService: SellPointService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.initForm();
    this.sellPointService.getWorkTime().subscribe(r => {
      this.startTime = r.startHour.toString();
      this.endTime = r.finishHour.toString();
      const starTimeDay = ['mondayMorFrom', 'tuesMorFrom', 'wedMorFrom', 'thurMorFrom', 'friMorFrom', 'satMorFrom', 'sunMorFrom'];
      const endTimeDay = ['mondayMorTo', 'tuesMorTo', 'wedMorTo', 'thurMorTo', 'friMorTo', 'satMorTo', 'sunMorTo'];
      starTimeDay.forEach(t => this.weekPlanningForm.get(t).setValue(this.startTime, {emitEvent: false}));
      endTimeDay.forEach(t => this.weekPlanningForm.get(t).setValue(this.endTime, {emitEvent: false}));
      if (this.data.editMode && this.data.destructed) {
        this.weekPlanningForm.controls.name.setValidators(null);
        this.weekPlanningForm.controls.id.setValue(this.data.planning.id);
        this.weekPlanningForm.patchValue(this.data.planning.workTimeDto, {emitEvent: false});
        this.patchedValue = true;
        return;
      }
      if (this.data.editMode) {
        this.weekPlanningForm.patchValue(this.data.planning, {emitEvent: false});
        this.weekPlanningForm.patchValue(this.data.planning.workTimeDto, {emitEvent: false});
      }
      this.patchedValue = true;
    });
  }

  initForm() {
    this.weekPlanningForm = new UntypedFormGroup({
      id: new UntypedFormControl(),
      name: new UntypedFormControl(null, [Validators.required]),
      description: new UntypedFormControl(null, [Validators.required]),


      mondayDayOff: new UntypedFormControl(false),
      mondayWithBreak: new UntypedFormControl(false),
      mondayMorFrom: new UntypedFormControl(this.startTime),
      mondayMorTo: new UntypedFormControl(this.endTime),
      mondayAfterFrom: new UntypedFormControl(null),
      mondayAfterTo: new UntypedFormControl(null),

      tuesDayOff: new UntypedFormControl(false),
      tuesWithBreak: new UntypedFormControl(false),
      tuesMorFrom: new UntypedFormControl(this.startTime),
      tuesMorTo: new UntypedFormControl(this.endTime),
      tuesAfterFrom: new UntypedFormControl(null),
      tuesAfterTo: new UntypedFormControl(null),

      wedDayOff: new UntypedFormControl(false),
      wedWithBreak: new UntypedFormControl(false),
      wedMorFrom: new UntypedFormControl(this.startTime),
      wedMorTo: new UntypedFormControl(this.endTime),
      wedAfterFrom: new UntypedFormControl(null),
      wedAfterTo: new UntypedFormControl(null),

      thurDayOff: new UntypedFormControl(false),
      thurWithBreak: new UntypedFormControl(false),
      thurMorFrom: new UntypedFormControl(this.startTime),
      thurMorTo: new UntypedFormControl(this.endTime),
      thurAfterFrom: new UntypedFormControl(null),
      thurAfterTo: new UntypedFormControl(null),

      friDayOff: new UntypedFormControl(false),
      friWithBreak: new UntypedFormControl(false),
      friMorFrom: new UntypedFormControl(this.startTime),
      friMorTo: new UntypedFormControl(this.endTime),
      friAfterFrom: new UntypedFormControl(null),
      friAfterTo: new UntypedFormControl(null),

      satDayOff: new UntypedFormControl(false),
      satWithBreak: new UntypedFormControl(false),
      satMorFrom: new UntypedFormControl(this.startTime),
      satMorTo: new UntypedFormControl(this.endTime),
      satAfterFrom: new UntypedFormControl(null),
      satAfterTo: new UntypedFormControl(null),

      sunDayOff: new UntypedFormControl(false),
      sunWithBreak: new UntypedFormControl(false),
      sunMorFrom: new UntypedFormControl(this.startTime),
      sunMorTo: new UntypedFormControl(this.endTime),
      sunAfterFrom: new UntypedFormControl(null),
      sunAfterTo: new UntypedFormControl(null),
      usedPosition: new UntypedFormControl(false)
    });
    /// monday day off change
    this.weekPlanningForm.get('mondayDayOff').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if (c) {
        this.weekPlanningForm.controls.mondayMorFrom.setValue(null);
        this.weekPlanningForm.controls.mondayMorTo.setValue(null);
        this.weekPlanningForm.controls.mondayWithBreak.setValue(false);
        this.weekPlanningForm.controls.mondayAfterFrom.setValue(null);
        this.weekPlanningForm.controls.mondayAfterTo.setValue(null);
      } else {
        this.weekPlanningForm.controls.mondayMorFrom.setValue(this.startTime);
        this.weekPlanningForm.controls.mondayMorTo.setValue(this.endTime);

      }
    });
    /// monday with break change
    this.weekPlanningForm.get('mondayWithBreak').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if (!c) {
        this.weekPlanningForm.controls.mondayAfterFrom.setValue(null);
        this.weekPlanningForm.controls.mondayAfterTo.setValue(null);
      } else {
        this.weekPlanningForm.controls.mondayAfterFrom.setValue(this.weekPlanningForm.get('mondayMorTo').value);
        this.weekPlanningForm.controls.mondayAfterTo.setValue(this.endTime);
      }
    });


    /// tuesday day-off change
    this.weekPlanningForm.get('tuesDayOff').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if (c) {
        this.weekPlanningForm.controls.tuesMorFrom.setValue(null);
        this.weekPlanningForm.controls.tuesMorTo.setValue(null);
        this.weekPlanningForm.controls.tuesWithBreak.setValue(false);
        this.weekPlanningForm.controls.tuesAfterFrom.setValue(null);
        this.weekPlanningForm.controls.tuesAfterTo.setValue(null);
      } else {
        this.weekPlanningForm.controls.tuesMorFrom.setValue(this.startTime);
        this.weekPlanningForm.controls.tuesMorTo.setValue(this.endTime);

      }
    });
    /// tuesday with break change
    this.weekPlanningForm.get('tuesWithBreak').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if (!c) {
        this.weekPlanningForm.controls.tuesAfterFrom.setValue(null);
        this.weekPlanningForm.controls.tuesAfterTo.setValue(null);
      } else {
        this.weekPlanningForm.controls.tuesAfterFrom.setValue(this.weekPlanningForm.get('tuesMorTo').value);
        this.weekPlanningForm.controls.tuesAfterTo.setValue(this.endTime);
      }
    });


    /// wednesday day-off change
    this.weekPlanningForm.get('wedDayOff').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if (c) {
        this.weekPlanningForm.controls.wedMorFrom.setValue(null);
        this.weekPlanningForm.controls.wedMorTo.setValue(null);
        this.weekPlanningForm.controls.wedWithBreak.setValue(false);
        this.weekPlanningForm.controls.wedAfterFrom.setValue(null);
        this.weekPlanningForm.controls.wedAfterTo.setValue(null);
      } else {
        this.weekPlanningForm.controls.wedMorFrom.setValue(this.startTime);
        this.weekPlanningForm.controls.wedMorTo.setValue(this.endTime);
      }
    });
    /// wednesday with break change
    this.weekPlanningForm.get('wedWithBreak').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if (!c) {
        this.weekPlanningForm.controls.wedAfterFrom.setValue(null);
        this.weekPlanningForm.controls.wedAfterTo.setValue(null);
      } else {
        this.weekPlanningForm.controls.wedAfterFrom.setValue(this.weekPlanningForm.get('wedMorTo').value);
        this.weekPlanningForm.controls.wedAfterTo.setValue(this.endTime);
      }
    });


    /// thursday day-off change
    this.weekPlanningForm.get('thurDayOff').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if (c) {
        this.weekPlanningForm.controls.thurMorFrom.setValue(null);
        this.weekPlanningForm.controls.thurMorTo.setValue(null);
        this.weekPlanningForm.controls.thurWithBreak.setValue(false);
        this.weekPlanningForm.controls.thurAfterFrom.setValue(null);
        this.weekPlanningForm.controls.thurAfterTo.setValue(null);
      } else {
        this.weekPlanningForm.controls.thurMorFrom.setValue(this.startTime);
        this.weekPlanningForm.controls.thurMorTo.setValue(this.endTime);

      }
    });
    /// thursday with break change
    this.weekPlanningForm.get('thurWithBreak').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if (!c) {
        this.weekPlanningForm.controls.thurAfterFrom.setValue(null);
        this.weekPlanningForm.controls.thurAfterTo.setValue(null);
      } else {
        this.weekPlanningForm.controls.thurAfterFrom.setValue(this.weekPlanningForm.get('thurMorTo').value);
        this.weekPlanningForm.controls.thurAfterTo.setValue(this.endTime);
      }
    });


    /// friday day-off change
    this.weekPlanningForm.get('friDayOff').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if (c) {
        this.weekPlanningForm.controls.friMorFrom.setValue(null);
        this.weekPlanningForm.controls.friMorTo.setValue(null);
        this.weekPlanningForm.controls.friWithBreak.setValue(false);
        this.weekPlanningForm.controls.friAfterFrom.setValue(null);
        this.weekPlanningForm.controls.friAfterTo.setValue(null);
      } else {
        this.weekPlanningForm.controls.friMorFrom.setValue(this.startTime);
        this.weekPlanningForm.controls.friMorTo.setValue(this.endTime);

      }
    });
    /// friday with break change
    this.weekPlanningForm.get('friWithBreak').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if (!c) {
        this.weekPlanningForm.controls.friAfterFrom.setValue(null);
        this.weekPlanningForm.controls.friAfterTo.setValue(null);
      } else {
        this.weekPlanningForm.controls.friAfterFrom.setValue(this.weekPlanningForm.get('friMorTo').value);
        this.weekPlanningForm.controls.friAfterTo.setValue(this.endTime);
      }
    });


    /// saturday day-off  change
    this.weekPlanningForm.get('satDayOff').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if (c) {
        this.weekPlanningForm.controls.satMorFrom.setValue(null);
        this.weekPlanningForm.controls.satMorTo.setValue(null);
        this.weekPlanningForm.controls.satWithBreak.setValue(false);
        this.weekPlanningForm.controls.satAfterFrom.setValue(null);
        this.weekPlanningForm.controls.satAfterTo.setValue(null);
      } else {
        this.weekPlanningForm.controls.satMorFrom.setValue(this.startTime);
        this.weekPlanningForm.controls.satMorTo.setValue(this.endTime);

      }
    });
    /// saturday with break change
    this.weekPlanningForm.get('satWithBreak').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if (!c) {
        this.weekPlanningForm.controls.satAfterFrom.setValue(null);
        this.weekPlanningForm.controls.satAfterTo.setValue(null);
      } else {
        this.weekPlanningForm.controls.satAfterFrom.setValue(this.weekPlanningForm.get('satMorTo').value);
        this.weekPlanningForm.controls.satAfterTo.setValue(this.endTime);
      }
    });


    /// sunday day-off change
    this.weekPlanningForm.get('sunDayOff').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if (c) {
        this.weekPlanningForm.controls.sunMorFrom.setValue(null);
        this.weekPlanningForm.controls.sunMorTo.setValue(null);
        this.weekPlanningForm.controls.sunWithBreak.setValue(false);
        this.weekPlanningForm.controls.sunAfterFrom.setValue(null);
        this.weekPlanningForm.controls.sunAfterTo.setValue(null);
      } else {
        this.weekPlanningForm.controls.sunMorFrom.setValue(this.startTime);
        this.weekPlanningForm.controls.sunMorTo.setValue(this.endTime);

      }
    });
    /// sunday with break change
    this.weekPlanningForm.get('sunWithBreak').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if (!c) {
        this.weekPlanningForm.controls.sunAfterFrom.setValue(null);
        this.weekPlanningForm.controls.sunAfterTo.setValue(null);
      } else {
        this.weekPlanningForm.controls.sunAfterFrom.setValue(this.weekPlanningForm.get('sunMorTo').value);
        this.weekPlanningForm.controls.sunAfterTo.setValue(this.endTime);
      }
    });

  }


  save() {
    this.submitted = true;
    if (!this.weekPlanningForm.valid && !this.data.destructed) {
      return;
    }
    this.weekPlanningTemplate.workTimeDto = this.weekPlanningForm.getRawValue();
    this.weekPlanningTemplate.id = this.weekPlanningForm.get('id').value;
    this.weekPlanningTemplate.name = this.weekPlanningForm.get('name').value;
    this.weekPlanningTemplate.description = this.weekPlanningForm.get('description').value;
    if (this.data.destructed) {
      if (this.weekPlanningTemplate.id) {
        this.yearPlanningService.saveWeekPlanning(this.weekPlanningTemplate).subscribe(r => {
          this.dialogRef.close(r.workTimeDto);
          this.snackBar.open(this.translate.instant('DIALOG.UPDATE_SUCCESS'), 'Ok', {duration: 2000});
        });
        return;
      }
      this.weekPlanningTemplate = this.weekPlanningForm.getRawValue();
      this.dialogRef.close(this.weekPlanningTemplate);
      return;
    }
    this.addedElement = true;
    this.weekPlanningForm.get('name').disable();
    this.weekPlanningForm.get('description').disable();
    if (!this.data.editMode) {
      this.planningService.addNewPlanning(this.weekPlanningTemplate).subscribe(d => {
        if (!this.addMultipleCheckbox.value) { // if "add other elements" not checked the dialog is closed
          this.dialogRef.close(true);
        } else { // if "add other elements" not checked the dialog is not closed and the element is added to the backend
          this.snackBar.open(this.translate.instant('DIALOG.ADD_SUCCESS'), 'Ok', {duration: 2000, panelClass: 'white-snackbar'});
        }
      }, (err) => {
        this.weekPlanningForm.get('name').enable();
        this.weekPlanningForm.get('description').enable();
        this.snackBar.open(this.translate.instant('DIALOG.INTERNAL_SERVER_ERROR'), 'Ok', {duration: 2000});
      }, () => {
        this.weekPlanningForm.get('name').enable();
        this.weekPlanningForm.get('description').enable();
        this.ngOnInit();
        this.submitted = false;
        this.descInput.nativeElement.focus();
      });
      return;
    }
    this.planningService.editWeekPlanning(this.weekPlanningTemplate).subscribe(d => {
    }, (err) => {
      this.weekPlanningForm.get('name').enable();
      this.weekPlanningForm.get('description').enable();
      this.weekPlanningForm.get('planningPosition').enable();
      this.snackBar.open(this.translate.instant('DIALOG.INTERNAL_SERVER_ERROR'), 'Ok', {duration: 2000});
    }, () => {
      this.dialogRef.close(true);
      this.snackBar.open(this.translate.instant('DIALOG.UPDATE_SUCCESS'), 'Ok', {duration: 2000});
    });

  }

  confirmAndClose(): void {
    if (this.weekPlanningForm.dirty) {
     this.sweetAlertService.showConfirmationAlert()
      .then((confirmation) => {
        if (confirmation) {
          this.dialogRef.close(this.addedElement);
        }
      });}
    else{
      this.dialogRef.close(this.addedElement);
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.weekPlanningForm.dirty) {
      $event.returnValue = true;
    }
  }
}

import {Component, OnInit} from '@angular/core';
import {Client} from '../../../../../shared/models/client';
import {VanityClientsService} from '../../../../../shared/services/vanity-clients.service';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {SweetAlertService} from '../../../../../shared/services/sweet-alert.service';
import {CustomSnackBarComponent} from '../../../../../shared/custom-snack-bar/custom-snack-bar.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {  MatSnackBar} from '@angular/material/snack-bar';
import {HttpClient} from '@angular/common/http';
import {CodiceIvaFormModalComponent} from '../../../../../content/pages/configurations/codice-iva/codice-iva-form-modal.component';
import moment from 'moment';

@Component({
  selector: 'app-vanity-client-mgm',
  templateUrl: './vanity-client-mgm.component.html',
  styleUrls: ['./vanity-client-mgm.component.scss']
})
export class VanityClientMgmComponent implements OnInit {
  searchFormControl = new UntypedFormControl(null);
  columns = [
    'DATA_TABLE.ID',
    'DATA_TABLE.FULL_NAME',
    'DATA_TABLE.CREATION_DATE',
    'DATA_TABLE.REGISTER_SOURCE',
    'DATA_TABLE.EMAIL',
    'DATA_TABLE.GENDER',
    'DATA_TABLE.MOBILE',
  ];
  rows: Client[] = [];
  loading = true;
  page = 1;
  totalRecords: number;
  pageSize = 10;
  textSearch = '';
  orderType = 'DESC';
  dialogRef: any;
  prefixList: any[] = [];
  clientForm: UntypedFormGroup;
  formBuilder = new UntypedFormBuilder();
  submitted = false;
  selectedClient: Client;
  groupsName = '';
  disableSave = false;

  constructor(private clientService: VanityClientsService,
              private translateService: TranslateService,
              private snackBar: MatSnackBar,
              private http: HttpClient,
              private matDialog: MatDialog,
              private sweetAlertService: SweetAlertService,
  ) {
  }

  ngOnInit() {
    this.getLazyClient({page: 1, pageSize: this.pageSize, textSearch: ''});
    this.searchFormControl.valueChanges.pipe(debounceTime(500)).subscribe(s => {
      this.textSearch = s;
      this.page = 1;
      this.getLazyClient({page: 1, pageSize: this.pageSize, textSearch: s});
    });
    this.initForm();
    this.http.get<any[]>('/assets/TEL_PREFIX.json').subscribe(d => this.prefixList = d.map(e => {
      e.label = `${e.country}: ${e.prefix}`;
      return e;
    }));

  }


  pageChange(page: number) {
    this.page = page;
    this.getLazyClient({page, pageSize: this.pageSize, textSearch: this.textSearch});
  }

  getLazyClient(request) {
    this.loading = true;
    return this.clientService.getLazy(request).subscribe(data => {
      this.rows = data.data;
      this.loading = false;
      this.totalRecords = data.totalRecords;
    });
  }

  call($event, client: Client) {
    $event.cancelBubble = true;
    $event.stopPropagation();
    window.open(
      'https://web.whatsapp.com/send?phone=' + client.mobilePrefix + client.mobile,
      '_blank'
    );
  }

  sortRows(c: string) {
    const sort = c.substr(11);
    if (this.orderType === 'ASC') {
      this.orderType = 'DESC';
    } else {
      this.orderType = 'ASC';
    }
    switch (sort) {
      case 'ID':
        this.getLazyClient({
          page: this.page, pageSize: this.pageSize, textSearch: this.textSearch, sort: {
            attribute: 'id',
            direction: this.orderType
          }
        });
        break;
      case 'FULL_NAME':
        this.getLazyClient({
          page: this.page, pageSize: this.pageSize, textSearch: this.textSearch, sort: {
            attribute: 'firstName',
            direction: this.orderType
          }
        });
        break;
      case 'GENDER':
        this.getLazyClient({
          page: this.page, pageSize: this.pageSize, textSearch: this.textSearch, sort: {
            attribute: 'gender',
            direction: this.orderType
          }
        });
        break;
      case 'EMAIL':
        this.getLazyClient({
          page: this.page, pageSize: this.pageSize, textSearch: this.textSearch, sort: {
            attribute: 'email',
            direction: this.orderType
          }
        });
        break;
      case 'MOBILE':
        this.getLazyClient({
          page: this.page, pageSize: this.pageSize, textSearch: this.textSearch, sort: {
            attribute: 'mobile',
            direction: this.orderType
          }
        });
        break;
    }
  }

  confirmClient(client) {
    this.sweetAlertService.warning(
      this.translateService.instant('DIALOG.CONFIRM_CLIENT') + client.firstName + ' ' + client.lastName).then(e => {
      if (e.value) {
        this.clientService.confirm(client.id).subscribe(d => {
          this.selectedClient.confirmed = true;
          this.sweetAlertService.notification(this.translateService.instant('DIALOG.SUCCESS_CONFIRM'));
        }, error => {
          this.sweetAlertService.danger(this.translateService.instant('DIALOG.FAILED_CONFIRM'));
        });
      }
    });
  }

  save() {
    this.submitted = true;
    if (!this.clientForm.valid) {
      this.sweetAlertService.notification(this.translateService.instant('DIALOG.INSERT_ALL_MANDATORY_INFORMATION')).then(e => {
      });
      return;
    }
    this.disableSave = true;
    this.clientService.update(this.clientForm.getRawValue()).subscribe(d => {
      this.submitted = false;
      this.disableSave = false;
      this.matDialog.closeAll();
      this.getLazyClient({page: this.page, pageSize: this.pageSize, textSearch: this.textSearch});
      this.showSnackBar({
        text: `${this.selectedClient.firstName} ${this.selectedClient.lastName}`,
        actionIcon: 'check',
        actionMsg: this.translateService.instant('DIALOG.UPDATE_SUCCESS')
      });
    }, (error) => {
      this.disableSave = false;
      this.showSnackBar({
        text: `${this.selectedClient.firstName} ${this.selectedClient.lastName}`,
        actionIcon: 'failed',
        actionMsg: this.translateService.instant('DIALOG.CANNOT_DELETE')
      });
    });
  }

  enableDisableClient($event: MouseEvent, client: Client) {
    $event.cancelBubble = true;
    $event.stopPropagation();
    let msg = '';
    if (client.blocked) {
      msg = this.translateService.instant('you want to disable');
    } else {
      msg = this.translateService.instant('DIALOG.YOU_WANT_TO_SHOW');
    }
    this.sweetAlertService.warning(msg + ' ' +
      client.firstName + ' ' + client.lastName)
      .then(res => {
        if (res.value) {
          this.clientService.enableDisable(client.id).subscribe(d => {
            if (d.status === 200) {
              client.blocked = !client.blocked;
              this.getLazyClient({page: this.page, pageSize: this.pageSize, textSearch: this.textSearch});
              this.showSnackBar({
                text: `${client.firstName} ${client.lastName}`,
                actionIcon: 'check',
                actionMsg: this.translateService.instant('DIALOG.UPDATE_SUCCESS')
              });
            }
          });
        }
      }, err => {
        this.showSnackBar({
          text: `${client.firstName} ${client.lastName}`,
          actionIcon: 'failed',
          actionMsg: this.translateService.instant('DIALOG.CANNOT_DELETE')
        });
      });
  }


  showSnackBar(data: any) {
    this.snackBar.openFromComponent(CustomSnackBarComponent, {
      data,
      duration: 5000,
      panelClass: 'white-snackbar'
    });
  }

  initForm() {
    this.clientForm = this.formBuilder.group({
      id: new UntypedFormControl(),
      blocked: new UntypedFormControl(null),
      confirmed: new UntypedFormControl(null),
      firstName: new UntypedFormControl(null, Validators.required),
      lastName: new UntypedFormControl(null, Validators.required),
      email: new UntypedFormControl(null, Validators.required),
      gender: new UntypedFormControl(null, Validators.required),
      mobile: new UntypedFormControl(null, [Validators.required, Validators.pattern('^[0-9]{8}[0-9]*')]),
      mobilePrefix: new UntypedFormControl('+39', [Validators.required]),
    });
  }

  editClient(client: Client, clientContent) {
    this.groupsName = '';
    this.initForm();
    this.selectedClient = client;
    this.clientForm.patchValue(client);
    client.groups.forEach(g => this.groupsName += g + '  |  ');
    this.groupsName = this.groupsName.substr(0, this.groupsName.length - 5);
    this.matDialog.open(clientContent, {
      width: '1200px',
      autoFocus: true,
      disableClose: true,
    });
  }

  // delete client
  deleteClient($event, element: Client, index) {
    $event.cancelBubble = true;
    $event.stopPropagation();
    this.sweetAlertService.warning(this.translateService.instant('DIALOG.YOU_WANT_TO_DELETE') + ' ' +
      element.firstName + ' ' + element.lastName)
      .then(res => {
        if (res.value) {
          this.clientService.delete(element.id).subscribe(d => {
            if (d.status === 200) {
              this.getLazyClient({page: this.page, pageSize: this.pageSize, textSearch: this.textSearch});
              this.showSnackBar({
                text: `${element.firstName} ${element.lastName}`,
                actionIcon: 'check',
                actionMsg: this.translateService.instant('DIALOG.UPDATE_SUCCESS')
              });
            }
          }, err => {
            if (err.status === 500) {
              this.showSnackBar({
                text: `${element.firstName} ${element.lastName}`,
                actionIcon: 'failed',
                actionMsg: this.translateService.instant('DIALOG.CANNOT_DELETE')
              });
            }
          });
        }
      }, err => {
      });

  }
}

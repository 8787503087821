// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 767.98px) {
  .card-mob {
    padding: 0.6rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/content/pages/stock-and-movement/stock-management/stock-history/stock-history.component.scss"],"names":[],"mappings":"AAAA;EACA;IACE,eAAA;EACA;AACF","sourcesContent":["@media (max-width: 767.98px){\r\n.card-mob{\r\n  padding: 0.6rem;\r\n}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

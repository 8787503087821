import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class DetailSellService {
  quantitySelectedNumber = '';
  selectedDollarSign = '';
  charsAfterComma = 4;
  value='';
  discountAmountOnSingle = 0;
  discountPercentOnSingle = 0;
  totalAmount = 0
  discountAmountOnTotal = 0;
  discountPercentOnTotal = 0;
  totalDiscountMode = 'AMOUNT';
  searchBar = false;
  tempoEsec: any;
  detailSellIsOpen = false;
  quantityOnSingle = 1;
  priceOnSingle = 1.00;
  lastModifiedDiscountType = 'PERCENT';
  lotteryCode: boolean = false;
  calculator: boolean = false;
  quantityJustSelected = false;
  dollarSignJustSelected = false;
  calculatorPosition = {
    x: 0,
    y: 0,
  }

  private keypadClearAll() {
    if (this.value.length > 0 && !(this.value.includes('X') || this.value.includes('$'))) {
      this.setNewValue('');
      return;
    }
    if (this.quantitySelectedNumber.length > 0) {
      this.clearTimesQuantity();
      return;
    }
    if (this.selectedDollarSign.length > 0) {
      this.clearDollarSignQuantity();
      return;
    }
  }

  clearAll(){
    this.setNewValue('');
    this.clearTimesQuantity();
    this.clearDollarSignQuantity();
    this.blurKeypadKey('clearAll');
  }

  private setNewValue(newValue) {
    this.value = newValue;
  }

  clearTimesQuantity() {
    this.quantitySelectedNumber = '';
    const x = ' X ';
    if (this.value.indexOf('$') !== -1 && this.value.indexOf('$') < this.value.indexOf(x)){
      this.value = this.value.replace(this.value.substr(this.value.indexOf('$') + 2, this.value.indexOf(x)), '');
      return
    }
    this.value = this.value.replace(this.value.substr(0, this.value.indexOf(x) + 2), '');
    return;
  }

  clearDollarSignQuantity() {
    this.selectedDollarSign = '';
    const dollarSign = ' $ ';
    if (this.value.indexOf('X') !== -1 && this.value.indexOf('X') < this.value.indexOf(dollarSign)){
      this.value = this.value.replace(this.value.substr(this.value.indexOf('X') + 2, this.value.indexOf(dollarSign)), '');
      return
    }
    this.value = this.value.replace(this.value.substr(0, this.value.indexOf(dollarSign) + 2), '');
    return
  }

  keypadClickTimes() {
    this.blurKeypadKey('quantitySign');
    const dollar = '$';
    //Controllo se l'ultimo tasto non sia '$' , ',' e che non sia vuoto
    if(this.value.trim().slice(this.value.length - 1) !== ','){
      if (this.value.length > 0){
        //Mi accerto che rimuovendo dalla stringa la selezione del dollarSign, essa non sia piu lunga di 6 caratteri
        if (this.value.length <= 6 ){
          this.quantitySelectedNumber = this.value.substring(this.value.indexOf(dollar)+1).trim();
          this.quantityJustSelected = true;
          this.clearValueWithTimeout(300);
          return
        }
      }
    }
  }

  keypadClickDollarSign() {
    this.blurKeypadKey('dollarSign');
    const x = 'X';
    //Controllo se l'ultimo tasto non sia '$' , ',' e che non sia vuoto
    if(this.value.trim().slice(this.value.length - 1) !== ','){
      if (this.value.length > 0){
        //Mi accerto che rimuovendo dalla stringa la selezione del dollarSign, essa non sia piu lunga di 6 caratteri
        if (this.value.length <= 6 ){
          this.selectedDollarSign = this.value.substring(this.value.indexOf(x)+1).trim();
          this.dollarSignJustSelected = true;
          this.clearValueWithTimeout(300);
          return;
        }
      }
    }
  }

  clearValueWithTimeout(timeToWait){
    setTimeout(()=>{
      this.value = ''
      this.dollarSignJustSelected = false;
      this.quantityJustSelected = false;
    },timeToWait)
  }

  keyboardClick(value){
    let newValue = this.value + value;
    this.setNewValue(newValue);
  }
  /*
  * Utilizzo questa funzione per prevenire un bug che si presenta quando inserisco il codice articolo dal tastierino sul display, e premo invio da tastiera,
  * avendo il focus sull' elemento html, una volta premuto enter viene dunque aggiunto nuovamente il numero.
  * Per evitare ciò, quando clicco sul tasto, cancello il focus sull'elemento premuto.
  * */
  blurKeypadKey(value){

    document.getElementById('desktop-keypad-value-'+ value).blur();
    document.getElementById('mobile-keypad-value-'+ value).blur();

  }
  keypadClick(value) {
    this.blurKeypadKey(value);
    let currentValue = this.value;
    if (value != null && value.length > 0) {
      if (!(this.value.includes('X') && this.value.includes('$')) || value === 'quantitySign' || value === 'dollarSign' ){
        switch (value ) {
          case '0': {
            this.setZero(currentValue);
            break;
          }
          case '1': {
            this.setOne(currentValue);
            break;
          }
          case '2': {
            this.setTwo(currentValue);
            break;
          }
          case '3': {
            this.setThree(currentValue);
            break;
          }
          case '4': {
            this.setFour(currentValue);
            break;
          }
          case '5': {
            this.setFive(currentValue);
            break;
          }
          case '6': {
            this.setSix(currentValue);
            break;
          }
          case '7': {
            this.setSeven(currentValue);
            break;
          }
          case '8': {
            this.setEight(currentValue);
            break;
          }
          case '9': {
            this.setNine(currentValue);
            break;
          }
          case ',': {
            this.setComma(currentValue);
            break;
          }

          case 'quantitySign':{
            this.keypadClickTimes();
            break;
          }
          case 'dollarSign':{
            this.keypadClickDollarSign();
            break;
          }

        }
      }

    }
    if(value === 'clearAll') {
      this.keypadClearAll();
    }
    if(value === 'backspace') {
      this.keypadBackspace(currentValue);
    }
  }

  public keypadBackspace(currentValue) {
    if (currentValue.length > 0 ) {
      let newValue = currentValue.substring(0, currentValue.length - 1);
      if(this.value.trim().slice(this.value.length - 2) === 'X' ||this.value.trim().slice(this.value.length - 2) === '$' ){
        newValue = currentValue.substring(0, currentValue.length - 3);
      }
      if (!newValue.includes('X')) {
        this.clearTimesQuantity()
      }
      if (!newValue.includes('$')) {
        this.clearDollarSignQuantity()
      }
      this.setNewValue(newValue);
    }
  }

  private setZero(currentValue) {
    if (this.checkCharsAfterComma(currentValue, this.charsAfterComma)) {
      let newValue = currentValue + '0';
      this.setNewValue(newValue);
    }
  }

  private setOne(currentValue) {
    if (this.checkCharsAfterComma(currentValue, this.charsAfterComma)) {
      let newValue = currentValue + '1';
      this.setNewValue(newValue);
    }
  }

  private setTwo(currentValue) {
    if (this.checkCharsAfterComma(currentValue, this.charsAfterComma)) {
      let newValue = currentValue + '2';
      this.setNewValue(newValue);
    }
  }

  private setThree(currentValue) {
    if (this.checkCharsAfterComma(currentValue, this.charsAfterComma)) {
      let newValue = currentValue + '3';
      this.setNewValue(newValue);
    }
  }

  private setFour(currentValue) {
    if (this.checkCharsAfterComma(currentValue, this.charsAfterComma)) {
      let newValue = currentValue + '4';
      this.setNewValue(newValue);
    }
  }

  private setFive(currentValue) {
    if (this.checkCharsAfterComma(currentValue, this.charsAfterComma)) {
      let newValue = currentValue + '5';
      this.setNewValue(newValue);
    }
  }

  private setSix(currentValue) {
    if (this.checkCharsAfterComma(currentValue, this.charsAfterComma)) {
      let newValue = currentValue + '6';
      this.setNewValue(newValue);
    }
  }

  private setSeven(currentValue) {
    if (this.checkCharsAfterComma(currentValue, this.charsAfterComma)) {
      let newValue = currentValue + '7';
      this.setNewValue(newValue);
    }
  }

  private setEight(currentValue) {
    if (this.checkCharsAfterComma(currentValue, this.charsAfterComma)) {
      let newValue = currentValue + '8';
      this.setNewValue(newValue);
    }
  }

  private setNine(currentValue) {
    if (this.checkCharsAfterComma(currentValue, this.charsAfterComma)) {
      let newValue = currentValue + '9';
      this.setNewValue(newValue);
    }
  }

  private setComma(currentValue) {
    let newValue = currentValue + ',';
    if (currentValue.length === 0) {
      newValue = '0,';
      this.setNewValue(newValue);
    } else if (currentValue.indexOf(',') > -1) {
    } else {
      this.setNewValue(newValue);
    }
  }

  checkCharsAfterComma(str: string, howManyCharsAfterComma: number) {
    let res: boolean;
    if (str.indexOf(',') > -1) {
      if (str.split(',')[1].length + 1 <= howManyCharsAfterComma) {
        res = true;
      } else {
        res = false;
      }
    } else {
      res = true;
    }
    return res;
  }


  formatPrice(price) {
    let priceNumber = Number(price);
    if (price.indexOf(',') > -1) {
      if (price.indexOf(',') === price.length - 1) {
        return price + '00';
      } else {
        return String(Number(price.replace(',', '.')).toFixed(2)).replace('.', ',');
      }
    } else {
      return priceNumber.toFixed(2).replace('.', ',');
    }
  }

  toggleCalculator() {

    this.calculator = !this.calculator;

  }
}

import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of, Subject} from 'rxjs';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {MovementProductFormComponent} from './movement-product-form.component';
import {  MatSnackBar} from '@angular/material/snack-bar';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {catchError, debounceTime, distinctUntilChanged, map, switchMap, takeUntil} from 'rxjs/operators';
import {Movement} from '../../../../../shared/models/movement';
import {MovementProduct} from '../../../../../shared/models/movement-product';
import {PaymentMethodsType} from '../../../../../shared/enum/payment-methods-type';
import {MovementType} from '../../../../../shared/enum/movement-type';
import {TypeDocument} from '../../../../../shared/enum/type-document';
import {MovementMgmService} from '../../../../../shared/services/movement-mgm.service';
import {ProviderMgmService} from '../../../../../shared/services/provider-mgm.service';
import {ClientMgmService} from '../../../../../shared/services/client-mgm.service';
import {SweetAlertService} from '../../../../../shared/services/sweet-alert.service';
import {ProductMgmService} from '../../../../../shared/services/product-mgm.service';
import {ProductBarcodeType} from '../../../../../shared/enum/product-barcode-type';
import {BarCodeMgmService} from '../../../../../shared/services/bar-code-mgm.service';
import {Product} from '../../../../../shared/models/product';
import {ProductManagementFormComponent} from '../../../productsAndServices/product-management/product-management-form/product-management-form.component';
import {Provider} from '../../../../../shared/models/provider';
import {Brand} from '../../../../../shared/models/brand';
import {ConfigurationsService} from '../../../../../shared/services/configurations.service';
import {SellPointService} from '../../../../../shared/services/sell-point.service';
import {SessionStorageService} from 'ngx-webstorage';
import createPrefixedInputHandler from '../../../payment/create-prefixed-input-handler-config';
import {CustomSnackBarComponent} from '../../../../../shared/custom-snack-bar/custom-snack-bar.component';
import {ZipCode} from '../../../../../shared/models/zip-code';
import {ZipCodeService} from '../../../../../shared/services/zip-code.service';

const MAX_NUMBER = 2147483646;

@Component({
  selector: 'app-movement-management-form',
  templateUrl: './movement-management-form.component.html',
  styleUrls: ['./movement-management-form.component.scss']
})
export class MovementManagementFormComponent implements OnInit, OnDestroy {
  @ViewChild('notFoundedProduct', { static: false }) notFoundedProduct: ElementRef;
  @ViewChild('file', { static: false }) inputFile: ElementRef;
  @ViewChild('prodTable') private prodTable: ElementRef;
  @ViewChild('selectProd') private selectProd: ElementRef;

  data;
  movement: Movement;
  appRef;
  dialogComponentRef;
  unsubscribe1$ = new Subject();
  unsubscribe2$ = new Subject();
  onClose = new Subject();
  editMode;
  editClicked = false;
  movementForm: UntypedFormGroup;
  columns = [' ', 'MOVEMENT_FORM.CODE', 'PRODUCT_FORM.DESCRIPTION', 'PRODUCT_FORM.MEASURE_UNIT', 'PRODUCT_FORM.STOCK', 'MOVEMENT_FORM.QUANTITY',
    'DATA_TABLE.PURCHASE_PRICE', 'DATA_TABLE.SALES_PRICE', 'MOVEMENT_FORM.IVA', 'MOVEMENT_FORM.TOTAL'];
  columnsSupplierLoad = [' ', 'MOVEMENT_FORM.CODE', 'PRODUCT_FORM.DESCRIPTION', 'PRODUCT_FORM.MEASURE_UNIT', 'PRODUCT_FORM.STOCK',
    'MOVEMENT_FORM.QUANTITY', 'MOVEMENT_FORM.PURCHASE_COAST', 'PRODUCT_FORM.PRICE', 'MOVEMENT_FORM.IVA', 'MOVEMENT_FORM.TOTAL'];
  dischargeColumns = [' ', 'MOVEMENT_FORM.CODE', 'PRODUCT_FORM.DESCRIPTION', 'PRODUCT_FORM.MEASURE_UNIT', 'PRODUCT_FORM.STOCK', 'MOVEMENT_FORM.QUANTITY',
    'MOVEMENT_FORM.IVA'];
  rows: MovementProduct[] = [];
  payments = [];
  clientProvider: any[] = [];
  types = [];
  documentTypes = [];
  unsubscribe$ = new Subject();
  clientProviderPage = 1;
  disableSave = false;
  file: FormData = new FormData();
  fileToUpload: File = null;
  disableDownload = false;
  DEFAULT_LANG = 'IT';
  uploaded;
  dialogRef: any;
  printTicketSent = false;
  selectedType;
  printMode;
  ticketNumber = 1;
  printTypes = [];
  movementColumns = [];
  type;
  productCode = null;
  searchedProduct: Product = new Product();
  productQtt = 1;
  productGiftQtt = 0;
  productList: Product[];
  searchFormControl: UntypedFormGroup;
  page = 1;
  loading = false;
  providers: Provider[] = [];
  brands: Brand[] = [];
  productSearchList: Product[] = [];
  productColumns = [];
  totalRecords: number;
  pageSize = 10;
  product: Product;
  editProductMode = false;
  editProductClicked = false;
  saveEventsSubject: Subject<void> = new Subject<void>();
  editEventsSubject: Subject<void> = new Subject<void>();
  providersPage = 1;
  brandPage = 1;
  providerSearchList: Provider[] = [];
  searchProviderFormControl: UntypedFormGroup;
  providerColumns = [
    'PROVIDER_FORM.BUSINESS_NAME',
    'PROVIDER_FORM.VAT_NUMBER',
    'PROVIDER_FORM.FISCAL_CODE',
    'PROVIDER_FORM.REGISTERED_OFFICE',
    'PROVIDER_FORM.CITY',
    'PROVIDER_FORM.OPERATING_OFFICE',
    'PROVIDER_FORM.CITY',
  ];
  zipCodeList: ZipCode[] = [];
  editProviderMode = false;
  editProviderClicked = false;
  provider: Provider;
  saveProviderEventsSubject: Subject<void> = new Subject<void>();
  editProviderEventsSubject: Subject<void> = new Subject<void>();
  newCode = '';
  sellPoints = [];
  editSelectedPv = false;
  editSelectedProd = false;
  listener: any;
  saveEachRowForm = new UntypedFormControl(false);
  productToReplace: any;
  spId: number;

  constructor(private http: HttpClient,
              private matDialog: MatDialog,
              private movementMgmService: MovementMgmService,
              private translate: TranslateService,
              private formBuilder: UntypedFormBuilder,
              private providerService: ProviderMgmService,
              private clientService: ClientMgmService,
              private productService: ProductMgmService,
              private configurationsService: ConfigurationsService,
              private sweetAlertService: SweetAlertService,
              private barcodeService: BarCodeMgmService,
              private sellPointService: SellPointService,
              public snackBar: MatSnackBar,
              private zipCodeService: ZipCodeService,
              private sessionStorage: SessionStorageService
  ) {
    this.initialiseListener();
  }

  getTotal(price, quantity) {
    return (price * quantity) ? price * quantity : 0;
  }

  ngOnInit() {
    this.movementColumns = this.type == 'CARICO' ? this.columns : this.dischargeColumns;
    this.types = [
      {description: this.translate.instant('MOVEMENT_FORM.' + MovementType.SUPPLIER_LOAD), id: MovementType.SUPPLIER_LOAD},
      {description: this.translate.instant('MOVEMENT_FORM.' + MovementType.CUSTOMER_SALES), id: MovementType.CUSTOMER_SALES},
      {description: this.translate.instant('MOVEMENT_FORM.' + MovementType.SELF_CONSUMPTION), id: MovementType.SELF_CONSUMPTION},
      {description: this.translate.instant('MOVEMENT_FORM.' + MovementType.VARIOUS_DISCHARGE), id: MovementType.VARIOUS_DISCHARGE},
      {description: this.translate.instant('MOVEMENT_FORM.' + MovementType.VARIOUS_LOAD), id: MovementType.VARIOUS_LOAD},
      {description: this.translate.instant('MOVEMENT_FORM.' + MovementType.RESO), id: MovementType.RESO}
    ];
    this.productColumns = ['MOVEMENT_FORM.CODE', 'PRODUCT_FORM.DESCRIPTION', 'PRODUCT_FORM.BRAND',
      'PRODUCT_FORM.PROVIDER', this.type == 'CARICO' ? 'DATA_TABLE.PURCHASE_PRICE' : 'DATA_TABLE.SALES_PRICE'];
    this.translatePayment();
    this.translateDocumentTypes();
    this.getProviders();
    this.getBrands();
    this.getSellPointsFromAccount();
    this.movementForm = new UntypedFormGroup({
      id: new UntypedFormControl(),
      date: new UntypedFormControl(new Date(), Validators.required),
      number: new UntypedFormControl(null),
      type: new UntypedFormControl(null, Validators.required),
      documentType: new UntypedFormControl(this.documentTypes[0].id),
      documentDate: new UntypedFormControl(new Date(), Validators.required),
      documentNumber: new UntypedFormControl(null),
      paymentMethod: new UntypedFormControl(this.payments[0].id, Validators.required),
      clientProviderId: new UntypedFormControl(null, Validators.required),
      totalQuantity: new UntypedFormControl(null),
      totalIva: new UntypedFormControl(null),
      totalTaxable: new UntypedFormControl(null),
      totalIvato: new UntypedFormControl(null),
      draft: new UntypedFormControl(false),
      createdAt: new UntypedFormControl(),
      updatedAt: new UntypedFormControl(),
      searchedProduct: new UntypedFormControl(null),
      productQtt: new UntypedFormControl(null),
      productGiftQtt: new UntypedFormControl(null),
      productPurchasePrice: new UntypedFormControl(null),
      productPrice: new UntypedFormControl(null),
      sellPointId: new UntypedFormControl(null, Validators.required),
      products: this.formBuilder.array([])
    });
    this.movementForm.get('clientProviderId').disable();
    if (!this.editMode) {
      this.movementMgmService.getNextNumber().subscribe(d => {
        this.movementForm.get('number').setValue(('0000' + d).slice(-4), {emitEvent: false});
      });
    }
    this.movementForm.get('type').valueChanges.pipe(debounceTime(90), takeUntil(this.unsubscribe$)).subscribe(c => {
      if (MovementType.VARIOUS_LOAD === c) {
        this.movementForm.get('documentType').setValue(TypeDocument.NONE);
        this.movementForm.get('documentType').disable();
      } else {
        this.movementForm.get('documentType').enable();
      }
      if (!this.editMode || c !== this.movement.type) {
        this.movementForm.get('clientProviderId').setValue(null);
      }
      this.movementForm.get('clientProviderId').disable();
      this.clientProviderPage = 1;
      this.clientProvider = [];
      if (MovementType.SUPPLIER_LOAD === c || MovementType.CUSTOMER_SALES === c) {
        this.loadClientProvider(c);
        this.movementForm.controls.clientProviderId.enable();
      }
      if (this.editMode && !this.editClicked) {
        this.movementForm.get('clientProviderId').disable();
      }
      if (!c) {
        return;
      }
      if (MovementType.CUSTOMER_SALES === c || MovementType.VARIOUS_DISCHARGE === c || MovementType.SELF_CONSUMPTION === c) {
        this.movementColumns = this.dischargeColumns;
        this.updatePrice(false);
      } else {
        this.movementColumns = MovementType.SUPPLIER_LOAD === c ? this.columnsSupplierLoad : this.columns;
        this.movementColumns = MovementType.SUPPLIER_LOAD === c ? this.columnsSupplierLoad : this.columns;
        this.updatePrice(true);
      }
    });
    this.movementForm.get('sellPointId').valueChanges.pipe(debounceTime(90), takeUntil(this.unsubscribe$)).subscribe(c => {
      if (c) {
        this.movementForm.get('sellPointId').disable();
        this.spId = this.movementForm.get('sellPointId').value;
      }
    });
    if (this.editMode) {
      this.movementForm.patchValue(this.movement, {emitEvent: false});
      this.movement.products.forEach(p => {
        const control = this.formBuilder.group(p);
        this.productsForm.insert(this.movement.products.indexOf(p), control);
        this.movementForm.get('number').setValue(('0000' + this.movement.number).slice(-4));
      });
      this.movementForm.disable();
    }
    this.productsFormValueChange();
    this.zipCodeService.getAllZipCodesByCountry('IT').subscribe(d => {
      this.zipCodeList = d;
    });
    document.getElementById('mvm-container').addEventListener('keydown', this.listener, {passive: true});
  }

  saveMovementHeader() {
    this.movementMgmService.saveHeader(this.movementForm.value).subscribe(r => {
      // this.sweetAlertService.success(this.translate.instant('the movement is saved as draft, click save to save it'));
      this.movement = r;
      this.movementForm.patchValue(r, {emitEvent: false});
      this.movementForm.disable({emitEvent: false});
      this.enableAddProduct();
      this.addProductToList();
    });

  }


  enableAddProduct() {
    this.movementForm.get('searchedProduct').enable({emitEvent: false});
    this.movementForm.get('productQtt').enable({emitEvent: false});
    this.movementForm.get('productGiftQtt').enable({emitEvent: false});
    this.movementForm.get('productPurchasePrice').enable({emitEvent: false});
    this.movementForm.get('productPrice').enable({emitEvent: false});
  }

  saveMovementRow(productForm) {
    this.movementMgmService.saveRow(productForm).subscribe(r => {
      this.showSnackBar({
        type: this.translate.instant('HEADER.BREADCRUMB.MOVEMENT'),
        actionIcon: 'save',
        actionMsg: this.translate.instant('DIALOG.ADD_SUCCESS')
      });
      productForm.id = r.id;
      const control = this.formBuilder.group(productForm);
      this.productsForm.insert(this.productsForm.length, control);
      this.prodTable.nativeElement.scrollTop = this.prodTable.nativeElement.scrollHeight;
    });
  }

  getSellPointsFromAccount() {
    this.sellPointService.getSellPointsByAccountByCentre().subscribe(res => {
      this.sellPoints = res.sellPoints;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe1$.next(void 0);
    this.unsubscribe1$.unsubscribe();
    this.unsubscribe2$.next(void 0);
    this.unsubscribe2$.unsubscribe();
    document.getElementById('mvm-container').removeEventListener('keydown', null, null);
  }

  get productsForm() {
    return this.movementForm.get('products') as UntypedFormArray;
  }

  destroyForm() {
    this.appRef.detachView(this.dialogComponentRef.hostView);
    this.dialogComponentRef.destroy();
  }

  closeFormSave() {
          this.destroyForm();
  }
  closeForm() {
    if (this.movementForm.dirty) {
     this.sweetAlertService.showConfirmationAlert()
      .then((confirmation) => {
        if (confirmation) {
          this.destroyForm();
        }
      });}
    else{
      this.destroyForm();
    }
  }

  activateEdit() {
    if (this.editMode) {
      this.editClicked = true;
      this.movementForm.get('date').setValue(new Date());
      // this.movementForm.get('clientProviderId').enable();
      this.movementForm.get('products').enable();
      this.enableAddProduct();
      if (this.movement.draft) {
        this.movementForm.get('clientProviderId').enable();
      }
      this.prodTable.nativeElement.scrollTop = this.prodTable.nativeElement.scrollHeight;
      this.focusInInput('searchedProduct');
    }
  }

  confirmMovement() {
    this.movementMgmService.confirmMovement(this.movementForm.getRawValue()).subscribe(r => {
      this.onClose.next(r);
      this.closeForm();
    });
  }

  save(draft: boolean) {
    this.movementForm.get('draft').setValue(draft);
    if (!this.productsForm.controls.length) {
      this.sweetAlertService.warning(this.translate.instant('DIALOG.NO_PRODUCT_INSERTED'))
        .then(res => {
        });
      return;
    }
    if (!this.movementForm.valid) {
      this.sweetAlertService.notification(this.translate.instant('DIALOG.INSERT_ALL_MANDATORY_INFORMATION')).then(e => {
      });
      return;
    }
    this.disableSave = true;
    if (this.movement && this.movement.draft) {
      this.confirmMovement();
      return;
    }
    if (!this.editMode) {
      this.movementMgmService.create(this.movementForm.getRawValue()).subscribe(d => {
        this.onClose.next(d);
        this.closeFormSave();
      }, error => this.disableSave = false);
      return;
    }
    this.movementMgmService.update(this.movementForm.getRawValue()).subscribe(d => {
      this.onClose.next(d);
      this.closeFormSave();
    }, error => this.disableSave = false);
  }

  delete($event: MouseEvent, product) {
    if (this.productsForm.controls.length == 1) {
      this.sweetAlertService.warning(
        this.translate.instant('MOVEMENT_FORM.DELETE_LAST_MOVEMENT_ROW')).then(e => {
        if (e.value) {
          this.deleteRow(product);
        }
      });
      return;
    }
    this.deleteRow(product);

  }

  deleteRow(product) {
    this.movementMgmService.deleteRow(product.get('id').value).subscribe(r => {
      this.showSnackBar({
        type: this.translate.instant('HEADER.BREADCRUMB.MOVEMENT'),
        actionIcon: 'delete',
        actionMsg: this.translate.instant('DIALOG.DELETE_SUCCESS')
      });
      this.productsForm.controls.splice(this.productsForm.controls.indexOf(product), 1);
      this.productsForm.value.splice(this.productsForm.value.indexOf(product), 1);
      this.productsForm.controls.forEach(p => {
        p.get('seq').setValue(this.productsForm.controls.indexOf(p) + 1);
      });
      this.calculateTotals();
      if (!this.productsForm.controls.length) {
        this.onClose.next(r);
        this.closeForm();
      }
    });
  }

  addProduct(editMode: boolean, product?) {
    const dialogRef = this.matDialog.open(MovementProductFormComponent, {
      width: '1200px',
      autoFocus: true,
      disableClose: true,
      data: {type: this.movementForm.get('type').value, editMode, product: product ? product.value : product}
    });
    dialogRef.afterClosed().subscribe((d: MovementProduct) => {
      if (d) {
        const index = this.productsForm.value.findIndex(u => u.productId === d.productId);
        if (!editMode) {
          if (index === -1) {
            d.seq = (this.productsForm.length + 1).toString();
            this.productsForm.insert(0, this.formBuilder.group(d));
            return;
          }
        }
        // const index = this.productsForm.controls.indexOf(product);
        const control = this.productsForm.controls[index];
        control.get('quantity').setValue(d.quantity);
        control.get('gift').setValue(d.gift);
        control.get('purchaseCost').setValue(d.purchaseCost);
      }
    });
  }

  updatePrice(charge) {
    this.productsForm.controls.forEach(c => {
      if (charge) {
        c.get('purchaseCost').setValue(c.get('costPrice').value, {emitEvent: false});
      } else {
        c.get('purchaseCost').setValue(c.get('price').value, {emitEvent: false});
      }
      this.calculateTotals();
    });
  }

  loadClientProvider(type: string) {
    if (MovementType.SUPPLIER_LOAD === type) {
      this.providerService.getLazyProviderList({page: this.clientProviderPage, pageSize: 10}).subscribe(data => {
        this.clientProvider = [...this.clientProvider, ...data.data];
        this.clientProviderPage++;
      });
      return;
    }
    if (MovementType.CUSTOMER_SALES === type) {
      this.clientService.getLazyClientList({
        page: this.clientProviderPage,
        pageSize: 10,
        visible: true,
        confirmed: true
      }).subscribe(data => {
        this.clientProvider = [...this.clientProvider, ...data.data];
        this.clientProvider.forEach(c => c.description = c.firstName + ' ' + c.lastName);
        this.clientProviderPage++;
      });
    }
  }

  get quantityForm() {
    return this.movementForm.get('totalQuantity') as UntypedFormControl;
  }

  get ivaForm() {
    return this.movementForm.get('totalIva') as UntypedFormControl;
  }

  get taxableForm() {
    return this.movementForm.get('totalTaxable') as UntypedFormControl;
  }

  get ivatoForm() {
    return this.movementForm.get('totalIvato') as UntypedFormControl;
  }

  private productsFormValueChange() {
    this.productsForm.valueChanges.subscribe(cart => {
      this.calculateTotals();
    });
  }

  private calculateTotals() {
    const totalQuantity = this.productsForm.controls.reduce((acc, el) => acc + Number(el.value.quantity) + Number(el.value.gift), 0);
    const totalIva = this.type == 'CARICO' ? this.productsForm.controls.reduce((acc, el) =>
      acc + ((el.value.purchaseCost / 100 * el.value.iva) * el.value.quantity), 0) : this.productsForm.controls.reduce((acc, el) =>
      acc + ((el.value.price / 100 * el.value.iva) * el.value.quantity), 0);
    this.quantityForm.setValue(totalQuantity);
    this.ivaForm.setValue(totalIva);
    const totalTaxable = this.type == 'CARICO' ?
      this.productsForm.controls.reduce((acc, el) => acc + (el.value.purchaseCost * (el.value.quantity)), 0) :
      this.productsForm.controls.reduce((acc, el) => acc + (el.value.price * (el.value.quantity)), 0);
    this.taxableForm.setValue(totalTaxable);
    this.ivatoForm.setValue(totalTaxable + totalIva);
  }


  translateTypes() {
    this.type === 'CARICO' ? this.translateTypesCarico() : this.translateTypesScarico();
  }

  translateTypesCarico() {
    this.types = !this.editMode ? [
      {description: this.translate.instant('MOVEMENT_FORM.' + MovementType.SUPPLIER_LOAD), id: MovementType.SUPPLIER_LOAD},
      {description: this.translate.instant('MOVEMENT_FORM.' + MovementType.VARIOUS_LOAD), id: MovementType.VARIOUS_LOAD},
    ] : [
      {description: this.translate.instant('MOVEMENT_FORM.' + MovementType.SUPPLIER_LOAD), id: MovementType.SUPPLIER_LOAD},
      {description: this.translate.instant('MOVEMENT_FORM.' + MovementType.VARIOUS_LOAD), id: MovementType.VARIOUS_LOAD},
      {description: this.translate.instant('MOVEMENT_FORM.' + MovementType.RESO), id: MovementType.RESO},
      {description: this.translate.instant('MOVEMENT_FORM.' + MovementType.INVENTORY), id: MovementType.INVENTORY},
    ];
  }

  translateTypesScarico() {
    this.types = [
      {description: this.translate.instant('MOVEMENT_FORM.' + MovementType.SELF_CONSUMPTION), id: MovementType.SELF_CONSUMPTION},
      {description: this.translate.instant('MOVEMENT_FORM.' + MovementType.VARIOUS_DISCHARGE), id: MovementType.VARIOUS_DISCHARGE},
    ];
  }

  translatePayment() {
    this.payments = [{description: this.translate.instant('MOVEMENT_FORM.' + PaymentMethodsType.CASH), id: PaymentMethodsType.CASH},
      {description: this.translate.instant('MOVEMENT_FORM.' + PaymentMethodsType.CHEQUE), id: PaymentMethodsType.CHEQUE},
      {description: this.translate.instant('MOVEMENT_FORM.' + PaymentMethodsType.CREDIT_CARD), id: PaymentMethodsType.CREDIT_CARD}];
  }

  translateDocumentTypes() {
    this.documentTypes = [{description: this.translate.instant('MOVEMENT_FORM.' + TypeDocument.NONE), id: TypeDocument.NONE},
      {description: this.translate.instant('MOVEMENT_FORM.' + TypeDocument.BILL), id: TypeDocument.BILL},
      {description: this.translate.instant('MOVEMENT_FORM.' + TypeDocument.DDT), id: TypeDocument.DDT}];
  }

  handleFileInput(files: FileList, content) {
    this.fileToUpload = files.item(0);
    const data = new FormData();
    data.append('file', this.fileToUpload);
    this.disableSave = true;
    this.movementMgmService.uploadProductsFromExcel(data).subscribe(r => {
      this.disableSave = false;
      this.uploaded = r;
      if (r.rowException) {
        this.sweetAlertService.warning(this.translate.instant('MOVEMENT_FORM.INVALID_ROW') + ' : ' + r.rowException +
          ' ' + this.translate.instant('MOVEMENT_FORM.IMPORT_PREVIOUS_ROWS')).then(e => {
          if (e.value) {
            r.products.reverse().forEach(u => {
              u.seq = (this.productsForm.length + 1).toString();
              this.productsForm.insert(0, this.formBuilder.group(u));
            });
            if (r.notFounded.length) {
              this.matDialog.open(content, {width: '500px'});
            }
          }
        });
        this.inputFile.nativeElement.value = '';
        return;
      }
      if (r.notFounded.length) {
        this.matDialog.open(content, {width: '500px', maxHeight: '85vh'});
      }
      r.products.reverse().forEach(u => {
        u.seq = (this.productsForm.length + 1).toString();
        this.productsForm.insert(0, this.formBuilder.group(u));
      });
      this.inputFile.nativeElement.value = '';
    }, error => {
      this.disableSave = false;
      if (error.error.message.includes('No valid entries or contents found')) {
        this.sweetAlertService.danger(this.translate.instant('DIALOG.INVALID_FILE_TYPE'));
      } else {
        this.sweetAlertService.danger(this.translate.instant('DIALOG.INTERNAL_SERVER_ERROR'));
      }
    });
  }

  downloadModel() {
    this.disableDownload = true;
    const lg = localStorage.getItem('language') || this.translate.currentLang || this.DEFAULT_LANG;
    this.movementMgmService.downloadExcelModel(lg.toUpperCase()).subscribe(r => {
      const downloadUrl = window.URL.createObjectURL(r);
      window.open(downloadUrl);
      this.disableDownload = false;
    }, error => this.disableDownload = false);
  }

  getPrintInterface(content) {
    this.dialogRef = this.matDialog.open(content, {
      autoFocus: false,
      maxHeight: '90vh'
    });
    this.dialogRef.afterClosed().subscribe(d => {
      if (d) {
        console.log(this.productsForm.controls);

        this.printTicketSent = true;
        let products = [];

        const promise = new Promise((resolve, reject) => {
          this.productsForm.controls.forEach((p, index) => {
            this.barcodeService.findAllByProductIdAndCenterId(p.get('productId').value).subscribe(r => {
              let barcodes = [];
              r.forEach(b => {
                if (b.barcode && b.barcode.length == 12) {
                  b.barcode = '0' + b.barcode;
                }
                barcodes.push(b.barcode);
              });
              products.push({
                description: p.get('description').value,
                price: p.get('purchaseCost').value,
                barCodes: barcodes
              });

              if (index === this.productsForm.controls.length - 1) {
                resolve(true);
              }

            });
          });
        });

        promise.then(r => {
          const request = {
            products,
            ticketQuantity: d,
            type: this.selectedType,
          };
          this.productService.printFacePlates(request).subscribe(data => {
            const downloadUrl = window.URL.createObjectURL(data.body);
            this.printTicketSent = false;
            window.open(downloadUrl);
          }, error => {
            this.printTicketSent = false;
            this.sweetAlertService.notification(this.translate.instant('PAYMENT.PRINT_FAILED'));
          });
          this.ticketNumber = 1;
        });

      }
    });
  }

  translatePrintTypes() {
    this.printTypes = [
      {description: this.translate.instant('PRODUCT_FORM.' + ProductBarcodeType.A4_62_32), id: ProductBarcodeType.A4_62_32},
      {
        description: this.translate.instant('PRODUCT_FORM.' + ProductBarcodeType.CONTINUOUS_63_37),
        id: ProductBarcodeType.CONTINUOUS_63_37
      }
    ];
  }

  searchProduct(addProductContent) {
    this.newCode = this.movementForm.get('searchedProduct').value;
    this.searchedProduct = this.movementForm.get('searchedProduct').value;
    if (!this.searchedProduct.id) {
      if (this.newCode && this.newCode.trim().length) {
        console.log(this.newCode);
        this.scanEAN(this.newCode, addProductContent);
      }
      return;
    }
    const index = this.productsForm.value.findIndex(u => u.productId === this.searchedProduct.id);
    if (index != -1) {
      this.sweetAlertService.warning(this.translate.instant('PRODUCT_FORM.PROD_ALREADY_EXIST')).then(u => {
        if (!u.value) {
          this.resetSearch();
          return;
        }
        this.focusInInput('productQtt');
      });
    }
    this.focusInInput('productQtt');
  }

  confirmProductMvm() {
    if (!this.searchedProduct || !this.searchedProduct.id) {
      this.focusInInput('searchedProduct');
      return;
    }
    if (this.type === 'CARICO') {
      this.focusInInput('productPrice');
      return;
    }
    if (!this.movement || !this.movement.id) {
      this.saveMovementHeader();
    } else {
      this.addProductToList();
    }
  }

  confirmProductCarico() {
    if (!this.searchedProduct || !this.searchedProduct.id) {
      this.focusInInput('searchedProduct');
      return;
    }

    if (!this.movement || !this.movement.id) {
      this.saveMovementHeader();
    } else {
      this.addProductToList();
    }
  }

  addProductToList() {
    this.searchedProduct.seq = (this.productsForm.length + 1).toString();
    const productForm = {
      id: null,
      productId: this.searchedProduct.id,
      seq: this.productsForm.length + 1,
      prodCode: this.searchedProduct.prodCode ? this.searchedProduct.prodCode : (this.searchedProduct.barCode ? this.searchedProduct.barCode : ''),
      description: this.searchedProduct.description,
      iva: this.searchedProduct.ivaValue,
      quantity: this.productQtt,
      gift: this.productGiftQtt,
      purchaseCost: this.searchedProduct.purchasePrice,
      costPrice: this.searchedProduct.purchasePrice,
      price: this.searchedProduct.price,
      measureUnit: this.searchedProduct.measureUnit,
      stock: this.searchedProduct.stock,
      movementId: this.movement ? this.movement.id : null,
      activeEdit: false,

    };
    // document.getElementById('prod-table').scrollTop = document.getElementById('prod-table').scrollHeight;
    this.resetSearch();
    this.saveMovementRow(productForm);
    // this.productsForm.insert(this.productsForm.length, this.formBuilder.group(productForm));
    // this.prodTable.nativeElement.scrollTop = this.prodTable.nativeElement.scrollHeight;
  }

  searchProd(term) {
    if (term === '' || term.length < 2) {
      this.productList = [];
      return of([]);
    }
    return this.productService.searchSpAndByCode(term, this.spId).pipe(
      map((response: Product[]) => {
        this.productList = response;
        return response;
      })
    );
  }

  search = (text$: Observable<Product>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(term =>
        this.searchProd(term).pipe(
          catchError(() => {
            return of([]);
          }))
      )
    );
  formatter = (result: Product) => (result.prodCode ? result.prodCode : (result.barCode ? result.barCode : result.description));

  passToGift() {
    if (this.type == 'SCARICO') {
      this.confirmProductCarico();
      return;
    }
    const inp = document.getElementsByName('productPurchasePrice');
    if (inp && inp.length) {
      inp[0].focus();
    }
  }

  focusInInput(inputName) {
    console.log(inputName);
    const inp = document.getElementsByName(inputName);
    if (inp && inp.length) {
      inp[0].focus();
    }
  }

  resetSearch() {
    this.movementForm.get('searchedProduct').setValue('');
    this.searchedProduct = new Product();
    this.productQtt = 1;
    this.productGiftQtt = 0;
    this.productCode = '';
    this.focusInInput('searchedProduct');
  }

  @HostListener('document:keydown.escape')
  onEscapeBtnClick() {
    if (this.searchedProduct && this.searchedProduct.id) {
      this.resetSearch();
    }
  }

  @HostListener('document:keydown.tab')
  onTabBtnClick($event) {
    // @ts-ignore
    if (event.srcElement.name) {
      event.preventDefault();
      event.stopPropagation();
    }
  }


  // @HostListener('document:keydown.enter')
  // onEnterBtnClick($event) {
  //   // @ts-ignore
  //   console.log(event.srcElement.name);
  // }

  openProductModal() {
    const dialogRef = this.matDialog.open(ProductManagementFormComponent, {
      width: '1200px',
      autoFocus: true,
      disableClose: true,
      data: {editMode: false}
    });
  }

  openSearchProduct(content, product) {
    this.productToReplace = product;
    this.resetSearchForm();
    const dialogRef = this.matDialog.open(content, {
      width: '1200px',
      autoFocus: true,
      disableClose: true,
      height: '85vh'
    });
  }

  resetSearchForm() {
    this.searchFormControl = new UntypedFormGroup({
      textSearch: new UntypedFormControl(null),
      providerId: new UntypedFormControl(null),
      brandId: new UntypedFormControl(null),
    });
    this.filter();
  }


  filter() {
    this.page = 1;
    this.filterProducts();
  }

  filterProducts() {
    const request = {
      sellPointId: this.spId,
      page: this.page,
      pageSize: 10,
      providerId: this.searchFormControl.get('providerId').value,
      brandId: this.searchFormControl.get('brandId').value,
      textSearch: this.searchFormControl.get('textSearch').value,
    };
    this.loading = true;
    this.productService.getLazyProductList(request).subscribe(r => {
      this.productSearchList = r.data;
      this.loading = false;
    });


  }

  getBrands() {
    this.configurationsService.getLazyBrands({page: this.brandPage, pageSize: MAX_NUMBER}).subscribe(d => {
      this.brands = [...this.brands, ...d.data];
      this.brandPage++;
    });
  }

  pageChange(event: number) {
    this.page = event;
  }

  editSelectedProduct(product, content) {
    console.log(product);
    console.log(product.value);
    this.editSelectedProd = false;
    this.editProductMode = true;
    this.editProductClicked = false;
    this.productService.getProduct(product.value.productId).subscribe(r => {
      if (r) {
        this.editSelectedProd = true;
        this.editProduct(r, content);
      }
    });
  }

  editProduct(product, content) {
    this.product = product;
    this.editProductMode = true;
    this.dialogRef = this.matDialog.open(content, {
      width: '1300px',
      autoFocus: true,
      disableClose: true,
      height: '90vh'
    });
    this.editEventsSubject.next(void 0);
    this.editProductClicked = false;

  }

  addNewProduct(content, withCode?) {
    this.editProductMode = false;
    this.newCode = withCode;
    this.dialogRef = this.matDialog.open(content, {
      width: '1300px',
      autoFocus: true,
      disableClose: true,
      height: '90vh'
    });
  }

  savedEvent(product: Product, addProductContent?) {
    this.disableSave = false;
    if (this.editProductMode) {
      if (this.editSelectedProd) {
        this.updateSelectedProducts(product);
      } else {
        this.filterProducts();
      }
      this.dialogRef.close();
    } else {
      this.productCode = product.prodCode;
      this.movementForm.get('searchedProduct').setValue(product);
      if (product) {
        this.matDialog.closeAll();
        this.searchProduct(addProductContent);
        setTimeout(() => {
          this.searchedProduct = product;
          this.focusInInput('productQtt');
        }, 650);
      }

    }

  }


  saveNewProduct() {
    this.saveEventsSubject.next(void 0);
  }

  activateProductEdit() {
    if (this.editProductMode) {
      this.editProductClicked = true;
      this.editEventsSubject.next(void 0);
    }
  }

  getProviders() {
    this.providerService.getLazyProviderList({page: this.providersPage, pageSize: MAX_NUMBER, visible: true}).subscribe(d => {
      this.providers = [...this.providers, ...d.data];
      this.providersPage++;
    });
  }

  selectProduct(product: Product, addProductContent) {
    this.matDialog.closeAll();
    if (this.productToReplace) {
      const control = this.productsForm.controls.filter(u => u.value.id == this.productToReplace.value.id).shift();
      if (control) {
        control.get('productId').setValue(product.id);
        control.get('prodCode').setValue(product.prodCode ? product.prodCode : (product.barCode ? product.barCode : ''));
        control.get('description').setValue(product.description);
        control.get('iva').setValue(product.ivaValue);
        control.get('purchaseCost').setValue(product.purchasePrice);
        control.get('costPrice').setValue(product.purchasePrice);
        control.get('price').setValue(product.price);
        control.get('measureUnit').setValue(product.measureUnit);
        control.get('movementId').setValue(this.movement ? this.movement.id : null);
        this.editRow(control);
        return;
      }
    }
    this.productCode = product.prodCode;
    this.movementForm.get('searchedProduct').setValue(product);
    this.searchProduct(addProductContent);
    setTimeout(() => {
      this.searchedProduct = product;
      this.focusInInput('productQtt');
    }, 650);
  }


  capSearch(term: string, item: ZipCode) {
    term = term.toLowerCase();
    return item.city.toLowerCase().startsWith(term) || item.cap.toLowerCase().startsWith(term);
  }


  resetProviderSearchForm() {
    this.searchProviderFormControl = new UntypedFormGroup({
      businessName: new UntypedFormControl(null),
      iva: new UntypedFormControl(null),
      fiscalCode: new UntypedFormControl(null),
      address: new UntypedFormControl(null),
      zipCodeId: new UntypedFormControl(null),
    });
    this.filterProvider();
  }


  filterProvider() {
    this.page = 1;
    this.filterProviders();
  }

  filterProviders() {
    const request = {
      page: this.page,
      pageSize: 10,
      businessName: this.searchProviderFormControl.get('businessName').value,
      iva: this.searchProviderFormControl.get('iva').value,
      fiscalCode: this.searchProviderFormControl.get('fiscalCode').value,
      address: this.searchProviderFormControl.get('address').value,
      zipCodeId: this.searchProviderFormControl.get('zipCodeId').value,

    };
    this.loading = true;
    this.providerService.getLazyProviderList(request).subscribe(r => {
      this.providerSearchList = r.data;
      this.loading = false;
    });


  }

  addNewProvider(addProviderContent) {
    this.editProviderMode = false;
    this.dialogRef = this.matDialog.open(addProviderContent, {
      width: '1300px',
      autoFocus: true,
      disableClose: true,
      height: '90vh'
    });

  }

  selectProvider(provider: Provider) {
    const prov = [...this.clientProvider];
    const index = prov.findIndex(p => p.id === provider.id);
    this.movementForm.get('clientProviderId').setValue(null);
    if (this.matDialog) {
      this.matDialog.closeAll();
    }
    this.editProviderMode = false;
    if (index != -1) {
      prov[index] = provider;
      this.clientProvider = [...prov];
    } else {
      this.clientProvider = [provider, ...prov];
    }
    this.movementForm.get('clientProviderId').setValue(provider.id);


  }

  editProvider(provider: Provider, content, editSelected?) {
    this.editSelectedPv = editSelected;
    this.provider = provider;
    this.editProviderMode = true;
    this.editProviderClicked = false;
    this.dialogRef = this.matDialog.open(content, {
      width: '1300px',
      autoFocus: true,
      disableClose: true,
      height: '90vh'
    });
  }

  openSearchProvider(searchProviderContent) {
    this.resetProviderSearchForm();
    const dialogRef = this.matDialog.open(searchProviderContent, {
      width: '95%',
      autoFocus: true,
      disableClose: true,
      height: '85vh'
    });
  }

  saveNewProvider() {
    this.saveProviderEventsSubject.next(void 0);
  }

  activateProviderEdit() {
    console.log(this.editProviderMode);
    if (this.editProviderMode) {
      this.editProviderClicked = true;
      this.editProviderEventsSubject.next(void 0);
    }
  }

  savedProviderEvent(provider: Provider) {
    if (this.editProviderMode) {
      if (this.editSelectedPv) {
        this.editSelectedPv = false;
        this.selectProvider(provider);
        return;
      }
      this.filterProvider();
      this.dialogRef.close();
    } else {
      if (provider) {
        console.log(provider);
        this.matDialog.closeAll();
        this.clientProvider = [provider, ...this.clientProvider];
        this.movementForm.get('clientProviderId').setValue(provider.id);
      }

    }
  }

  checkSelectedProduct() {
    console.log('*************');
    if (!this.searchedProduct.id) {
      console.log('/*/*/*/*/*');
      this.focusInInput('searchedProduct');
    }

  }

  editSelectedProvider(searchProviderContent) {
    this.editSelectedPv = false;
    this.editProviderMode = true;
    this.editProviderClicked = false;
    const id = this.movementForm.get('clientProviderId').value;
    if (!id) {
      return;
    }
    const provider = this.clientProvider.filter(p => p.id == id).shift();
    if (!provider) {
      return;
    }
    this.editProvider(provider, searchProviderContent, true);
  }


  initialiseListener() {
    const handler = createPrefixedInputHandler()((c) => {
      if (c.length > 11 || c.length === 8) {
        this.scanEAN(c);
      }
    });
    this.listener = (event: Event): void => handler(event as KeyboardEvent);
  }


  scanEAN(ean, content?) {
    console.log(ean);
    if (ean === null || (ean.toString()).trim() === '') {
      return;
    }
    this.productService.findByBarCodeAndSp(ean, this.spId).subscribe(r => {
      if (r) {
        this.searchedProduct = r;
        return;
      } else {
        this.barcodeService.findProductIdByBarCodeAndCenterId(ean).subscribe(id => {
          if (id) {
            this.productService.getProductBySp(id, this.spId).subscribe(p => {
              if (p) {
                this.searchedProduct = p;
                this.focusInInput('productQtt');
                return;
              }
              if (content) {
                this.openCreateNewProdAlert(content);
              }
            });
            return;
          }
          if (content) {
            this.openCreateNewProdAlert(content);
          }
        }, error => {
          this.sweetAlertService.danger(this.translate.instant('DIALOG.INTERNAL_SERVER_ERROR'));
        });
      }

    });
  }

  openCreateNewProdAlert(addProductContent) {
    this.sweetAlertService.warning(this.translate.instant('PRODUCT_FORM.PRODUCT_NOT_FOUND_ADD_IT')).then(u => {
      if (u.value) {
        this.addNewProduct(addProductContent, this.newCode.trim());
        return;
      } else {
        this.productCode = '';
        this.movementForm.get('searchedProduct').setValue('');
        this.focusInInput('productQtt');
      }
    });
  }

  updateSelectedProducts(product: Product) {
    this.productsForm.controls.forEach(c => {
      if (c.get('productId').value == product.id) {
        c.get('prodCode').setValue(product.prodCode ? product.prodCode : (product.barCode ? product.barCode : ''));
        c.get('description').setValue(product.description);
        c.get('iva').setValue(product.ivaValue);
      }
    });
  }

  editRow(product, fromTab?) {
    if (this.movement && this.movement.draft) {
      this.movementMgmService.saveRow(product.value).subscribe(r => {
        this.showSnackBar({
          type: this.translate.instant('HEADER.BREADCRUMB.MOVEMENT'),
          actionIcon: 'save',
          actionMsg: this.translate.instant('DIALOG.UPDATE_SUCCESS')
        });
        if (!fromTab) {
          this.prodTable.nativeElement.scrollTop = this.prodTable.nativeElement.scrollHeight;
          this.focusInInput('searchedProduct');
        }
      });
      return;
    }
    this.movementMgmService.editRow(product.value).subscribe(r => {
      if (!fromTab) {
        this.prodTable.nativeElement.scrollTop = this.prodTable.nativeElement.scrollHeight;
        this.focusInInput('searchedProduct');
      }
      this.showSnackBar({
        type: this.translate.instant('HEADER.BREADCRUMB.MOVEMENT'),
        actionIcon: 'save',
        actionMsg: this.translate.instant('DIALOG.UPDATE_SUCCESS')
      });
    });
  }


  showSnackBar(data: any) {
    this.snackBar.openFromComponent(CustomSnackBarComponent, {
      data,
      duration: 5000,
      panelClass: 'white-snackbar'
    });
  }
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.movementForm.dirty) {
      $event.returnValue = true;
    }
  }

}

import {WhatsappConfiguration} from "./WhatsappConfiguration";

export class WhatsappMessage {
  recipient:string;
  message:string;
  configuration:WhatsappConfiguration
 templateName:string;
  languageCode:string;
  textParameters: string[] = [];
}

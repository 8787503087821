// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subtitle {
  justify-content: center;
  text-align: center;
  margin: 20px;
  white-space: normal;
}

.content {
  margin: 16px;
  justify-content: center;
  text-align: justify;
}`, "",{"version":3,"sources":["webpack://./src/app/privacy/privacy.component.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,kBAAA;EACA,YAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;EACA,uBAAA;EACA,mBAAA;AACF","sourcesContent":[".subtitle {\r\n  justify-content: center;\r\n  text-align: center;\r\n  margin: 20px;\r\n  white-space: normal;\r\n}\r\n\r\n.content {\r\n  margin: 16px;\r\n  justify-content: center;\r\n  text-align: justify;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.active {
  background-color: #212529;
  border-color: #212529;
}

.table {
  font-size: 0.9rem;
}

.table thead th, .table thead td {
  min-width: unset;
}

.table th, .table td {
  padding-left: 0;
}

.list-group-item {
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .table th {
    padding-right: 0.5rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/content/pages/firstNote/first-note/first-note.component.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,qBAAA;AACF;;AACA;EACE,iBAAA;AAEF;;AAAA;EACE,gBAAA;AAGF;;AADA;EACE,eAAA;AAIF;;AAFA;EACE,eAAA;AAKF;;AAFA;EACE;IACE,qBAAA;EAKF;AACF","sourcesContent":[".active {\r\n  background-color: #212529;\r\n  border-color: #212529;\r\n}\r\n.table {\r\n  font-size: .9rem;\r\n}\r\n.table thead th, .table thead td {\r\n  min-width: unset;\r\n}\r\n.table th, .table td {\r\n  padding-left: 0;\r\n}\r\n.list-group-item {\r\n  cursor: pointer;\r\n}\r\n\r\n@media screen and (max-width: 600px) {\r\n  .table th {\r\n    padding-right: .5rem;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import {Injectable} from '@angular/core';
import * as Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class SweetAlertService {

  constructor(private translate: TranslateService) {
  }

  warning(msg: string, confirmButtonText?: string, cancelButtonText?: string, title?: string) {
    return Swal.default.fire({
      title: title,
      icon: 'warning',
      html: msg,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: confirmButtonText ? confirmButtonText : this.translate.instant('BUTTONS.CONFIRM'),
      cancelButtonText: cancelButtonText ? cancelButtonText : this.translate.instant('BUTTONS.CANCEL'),
      customClass: {
        confirmButton: 'confirm-button',
        cancelButton: 'cancel-button'
      }
    });
  }

  danger(msg: string) {
    return Swal.default.fire({
      icon: 'error',
      text: msg,
      showCancelButton: false,
      showConfirmButton: true,
      focusConfirm: false,
      confirmButtonText: 'OK',
      customClass: {
        confirmButton: 'btn cancel-button'
      }
    });
  }

  notification(msg: string) {
    return Swal.default.fire({
      icon: 'warning',
      text: msg,
      showCloseButton: false,
      showCancelButton: true,
      showConfirmButton: false,
      focusCancel: true,
      cancelButtonText: 'OK',
      customClass: {
        cancelButton: 'cancel-button'
      },
      timer: 7000
    });
  }

  success(msg: string) {
    return Swal.default.fire({
      icon: 'success',
      text: msg,
      showCloseButton: false,
      showCancelButton: true,
      cancelButtonText: 'OK',
      customClass: {
        cancelButton: 'confirm-button'
      },
      timer: 4000
    });
  }

  showConfirmationAlert(): Promise<boolean> {
    return Swal.default.fire({
      title: 'Attenzione',
      text: 'Sei sicuro di voler procedere?',
      icon: 'question',
      iconColor: 'black',
      showCancelButton: true,
      confirmButtonText: 'Conferma',
      cancelButtonText: 'Annulla',
      customClass: {
        confirmButton: 'confirm-button',
        cancelButton: 'cancel-button'
      }
    }).then((result) => {
      return result.value === true;
    });
  }

  questionAlert(msg, title?): Promise<boolean> {
    return Swal.default.fire({
      title: title ? title : 'Attenzione',
      text: msg,
      icon: 'question',
      iconColor: 'black',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('BUTTONS.CONFIRM'),
      cancelButtonText: this.translate.instant('BUTTONS.CANCEL'),
      customClass: {
        confirmButton: 'confirm-button',
        cancelButton: 'cancel-button'
      }
    }).then((result) => {
      return result.value === true;
    });
  }


  error(msg: string) {
    Swal.default.mixin({
      toast: true,
      position: 'bottom-end',
      timer: 4000,
      timerProgressBar: true,
    }).fire({
      icon: 'error',
      showConfirmButton: false,
      title: 'Error',
      text: msg
    });
  }

}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MobileAppConfigService} from "../../../../../shared/services/mobile-app-config.service";
import {MobileConfiguration} from "../../../../../shared/models/mobile-configuration";
import {map, switchMap, takeUntil} from "rxjs/operators";
import {Observable, Subject} from "rxjs";
import {DomSanitizer} from "@angular/platform-browser";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-banner-list',
  templateUrl: './banner-list.component.html',
  styleUrls: ['./banner-list.component.scss']
})
export class BannerListComponent implements OnInit, OnDestroy {
  currentConfig: MobileConfiguration;
  images = [];
  unsub = new Subject();
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private mobileConfigService: MobileAppConfigService,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
    this.mobileConfigService.getConfiguration().subscribe(d => {
      this.currentConfig = d;
    });
    this.mobileConfigService.getReloadObs().pipe(takeUntil(this.unsub)).subscribe(d => {
      this.mobileConfigService.getConfiguration().subscribe(d => {
        this.currentConfig = d;
      });
    });
  }

  navigateTo(id?) {
    this.router.navigate(['edit'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        action: id ? 'edit' : 'add',
        id,
        confId: this.currentConfig ? this.currentConfig.id : undefined,
      }
    })
  }

  delete(id, index) {
    this.mobileConfigService.deleteImage(id).subscribe(() => {
      this.currentConfig.bannerImages.splice(index, 1);
    });
  }

  ngOnDestroy(): void {
    this.unsub.next(void 0);
    this.unsub.complete();
  }
}

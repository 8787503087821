import {Injectable} from '@angular/core';
import {SearchResponse} from '../dto/search-response';
import {Cabin} from '../models/cabin';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {DayPlanningResponse, TimeSheetResponse} from './operator-mgm.service';

@Injectable()
export class CabinService {
  CABIN_API = environment.api + '/cabins';

  constructor(private http: HttpClient) {
  }


  addNew(cabin) {
    return this.http.post<Cabin>(`${this.CABIN_API}`, cabin);
  }


  edit(request) {
    return this.http.put<Cabin>(`${this.CABIN_API}`, request);
  }

  search(request) {
    return this.http.post<SearchResponse<Cabin>>(`${this.CABIN_API}/filter`, request);
  }

  findBySellPointId() {
    return this.http.get<Cabin[]>(`${this.CABIN_API}/find-cabins-by-sell-point-id`);
  }

  getCurrentCabinsByDate(date) {
    return this.http.post<Cabin[]>(`${this.CABIN_API}/get-current-cabins-by-date`, new Date(date));
  }

  delete(id: number) {
    return this.http.delete(`${this.CABIN_API}/${id}`, {observe: 'response'});
  }

  enableDisable(cabinId) {
    return this.http.get(`${this.CABIN_API}/enable-disable/${cabinId}`);
  }

  getActive() {
    return this.http.get<Cabin[]>(`${this.CABIN_API}/search-active`);
  }

  getById(cabinId) {
    return this.http.get<Cabin>(`${this.CABIN_API}/${cabinId}`);
  }

  getCabinsTimeSheet(request: { date: string; ids: number[] }) {
    return this.http.post<TimeSheetResponse[]>(`${this.CABIN_API}/cabins-time-sheets`, request);
  }

  getCabinDayPlanning(request) {
    return this.http.post<DayPlanningResponse>(`${this.CABIN_API}/get-cabin-day-planning`, request);
  }

  changeCabinDayPlanning(request: any) {
    return this.http.post<void>(`${this.CABIN_API}/change-cabin-day-planning`, request);
  }
  checkCabinDayPlanning(cabinId, date) {
    return this.http.get(`${this.CABIN_API}/check-cabin-day-planning`, {
      params: {
        cabinId,
        date
      },
      responseType: 'text'
    });
  }
}

import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {BarCodeRequest} from '../models/barCodeRequest';
import {Barcode} from '../models/barcode';

@Injectable()
export class BarCodeMgmService {

  BARCODE_API = environment.api + '/bar-code';

  constructor(private http: HttpClient) {
  }

  generateEAN13(request: BarCodeRequest) {
    return this.http.post<string>(`${this.BARCODE_API}`, request);
  }

  validateEAN13(request: BarCodeRequest) {
    return this.http.post<boolean>(`${this.BARCODE_API}/is-valid`, request);
  }

  uniqueProductEAN113(request: BarCodeRequest) {
    return this.http.post<boolean>(`${this.BARCODE_API}/is-unique`, request);
  }

  validateEAN(request: BarCodeRequest) {
    return this.http.post<boolean>(`${this.BARCODE_API}/is-valid-ean`, request);
  }
  addNewProductBarcode(barcode: Barcode) {
    return this.http.post<Barcode>(`${this.BARCODE_API}/add-new-product-barcode`, barcode);
  }
  deleteBarcode(id){
    return this.http.get<boolean>(`${this.BARCODE_API}/delete-barcode/${id}`);
  }
  findAllByProductIdAndCenterId(productId: number){
    return this.http.post<Barcode[]>(`${this.BARCODE_API}/find-all-barcodes-by-product-id-and-center-id`, productId);
  }
  findProductIdByBarCodeAndCenterId(barcode:String){
    return this.http.post<number>(`${this.BARCODE_API}/find-product-id-by-barcode-and-center-id`, barcode);
  }

}

import {Module} from '../../../owner/components/license-configuration/license-config-form/modules';

export const OPERATOR_MODULES: any[] = [
  {label: 'Dashboard', value: 'DASHBOARD', selected: false, disabled: false},
  {label: 'Planning', value: 'PLANNING', selected: false, disabled: false},
  {label: 'SIDENAV.PAYMENT', value: 'PAYMENT', selected: false, disabled: false},
  {
    label: 'SIDENAV.CONTACTS',
    value: 'CONTACTS',
    selected: false, disabled: false,
    children: [
      {label: 'SIDENAV.CLIENT', value: 'CLIENT', selected: false, disabled: false},
      {label: 'SIDENAV.COMPANY', value: 'COMPANY', selected: false, disabled: false},
      {label: 'SIDENAV.PROVIDER', value: 'PROVIDER', selected: false, disabled: false},
    ]
  },
  {
    label: 'BackOffice',
    value: 'BACK_OFFICE',
    selected: false, disabled: false,
    children: [
      {label: 'SIDENAV.PRODUCT', value: 'ARTICLE', selected: false, disabled: false},
      {label: 'SIDENAV.TREATMENT', value: 'TREATMENT', selected: false, disabled: false},
      {label: 'SIDENAV.STOCK', value: 'STOCK', selected: false, disabled: false},
      {label: 'SIDENAV.QUOTE', value: 'QUOTE', selected: false, disabled: false},
      {label: 'SIDENAV.SUBSCRIPTION', value: 'SUBSCRIPTION', selected: false, disabled: false},
      {label: 'SIDENAV.FIDELITY', value: 'FIDELITY', selected: false, disabled: false},
      {label: 'SIDENAV.DOCUMENT_MANAGEMENT', value: 'DOCUMENT_MANAGEMENT', selected: false, disabled: false},
      {label: 'SIDENAV.CASH_DESK', value: 'CASH_DESK', selected: false, disabled: false},
      { label: 'SIDENAV.MOVEMENT',
        value: 'MOVEMENT',
        selected: false, disabled: false,
        children: [
          {label: 'SIDENAV.CARICO', value: 'CARICO', selected: false, disabled: false},
          {label: 'SIDENAV.SCARICO', value: 'SCARICO', selected: false, disabled: false},
          {label: 'SIDENAV.TRANSFER', value: 'TRANSFER', selected: false},
          {label: 'SIDENAV.INVENTORY', value: 'INVENTORY', selected: false, disabled: false},
        ]
      },
      {label: 'SIDENAV.FIRST_NOTE', value: 'FIRST_NOTE', selected: false, disabled: false},

    ]
  },
  {
    label: 'Marketing',
    value: 'MARKETING',
    selected: false, disabled: false,
    children: [
      {label: 'SIDENAV.PROMOTION', value: 'PROMOTION', selected: false, disabled: false},
      {label: 'Promo SMS', value: 'PROMO_SMS', selected: false, disabled: false},
    ]
  },
  {
    label: 'SIDENAV.STATISTICS',
    value: 'STATISTICS',
    selected: false, disabled: false,
    children: [
      {label: 'SIDENAV.PRODUCT_REPORT', value: 'PRODUCT_REPORTS', selected: false, disabled: false},
      {label: 'SIDENAV.TREATMENT_REPORT', value: 'TREATMENT_REPORTS', selected: false, disabled: false},
      {label: 'SIDENAV.OPERATOR_REPORT', value: 'OPERATOR_REPORTS', selected: false, disabled: false},
    ]
  },
  {
    label: 'SIDENAV.CONFIGURATIONS',
    value: 'CONFIGURATIONS',
    selected: false, disabled: false,
    children: [
      {label: 'SIDENAV.SETUP_SELL_POINT', value: 'SETUP_SELL_POINT', selected: false, disabled: false},
      {label: 'SIDENAV.SETUP_CLIENTS', value: 'SETUP_CLIENTS', selected: false, disabled: false},
      {label: 'SIDENAV.WHATS_APP', value: 'WHATS_APP', selected: false, disabled: false},
      {label: 'SIDENAV.PROD_SERVICE', value: 'PRODUCTS_AND_SERVICES', selected: false, disabled: false},
      {label: 'SIDENAV.PRINTERS', value: 'PRINTERS', selected: false, disabled: false},
      {label: 'Satispay', value: 'SATISPAY', selected: false, disabled: false},
    ]
  }
];

import { Component, OnInit } from '@angular/core';
import {Linea} from '../../../../shared/models/linea';
import {UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {ConfigurationsService} from '../../../../shared/services/configurations.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {BreadcrumbService} from '../../../../core/services/breadcrumb.service';
import {TranslateService} from '@ngx-translate/core';
import {SweetAlertService} from '../../../../shared/services/sweet-alert.service';
import {LazyRequest} from '../../../../shared/dto/lazy-request';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {LineaFormModalComponent} from './linea-form-modal.component';

@Component({
  selector: 'app-linea',
  templateUrl: './linea.component.html',
  styleUrls: ['./linea.component.scss']
})
export class LineaComponent implements OnInit {

  columns = [
    'DATA_TABLE.ID',
    'DATA_TABLE.LINEA_DESCRIPTION',
  ];

  rows: Linea[] = [];
  searchFormControl = new UntypedFormControl();
  totalRecords: number;
  pageSize = 10;
  page = 1;
  unsubscribe = new Subject();
  loading = true;
  firstCall = true;
  orderType: 'ASC' | 'DESC' = 'DESC';

  constructor(private configurationsService: ConfigurationsService,
              private matDialog: MatDialog,
              private sweetAlertService: SweetAlertService,
              private breadcrumbService: BreadcrumbService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.sendBreadCrumb();
    this.getLazyLinea({page: this.page, pageSize: this.pageSize});
    this.searchFormControl.valueChanges.pipe(debounceTime(500), takeUntil(this.unsubscribe)).subscribe(text => {
      this.getLazyLinea({page: 1, pageSize: this.pageSize, textSearch: text});
    });
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy(): void {
    this.unsubscribe.next(void 0);
    this.unsubscribe.unsubscribe();
  }

  sendBreadCrumb(): void {
    this.breadcrumbService.sendBreadcrumb(['CONFIGURATION', 'LINEAS']);
  }

  getLazyLinea(request: LazyRequest) {
    this.loading = true;
    return this.configurationsService.getLazyLineas(request).subscribe(data => {
      this.rows = data.data;
      this.totalRecords = data.totalRecords;
      this.loading = false;
      this.firstCall = false;
    });
  }

  openFormDialog() {
    const dialogRef = this.matDialog.open(LineaFormModalComponent, {
      width: '400px',
      autoFocus: true,
      disableClose: true,
      data: {editMode: false}
    });
    dialogRef.afterClosed().subscribe(d => {
      if (d) {
        this.page = 1;
        this.getLazyLinea({page: 1, pageSize: this.pageSize});
      }
    });
  }

  deleteLinea(linea: any, i: number) {
    this.sweetAlertService.warning(this.translate.instant('DIALOG.YOU_WANT_TO_DELETE') +  linea.description).then(e => {
      if (e.value) {
        this.configurationsService.deleteLinea(linea.id).subscribe(d => {
          if (d.status === 200) {
            this.getLazyLinea({page: this.page, pageSize: this.pageSize});
          }
        });
      }
    });
  }

  openEditLinea(b: Linea, i: number) {
    const dialogRef = this.matDialog.open(LineaFormModalComponent, {
      width: '400px',
      disableClose: true,
      data: {editMode: true, linea: b}
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(d => {
      if (d) {
        this.getLazyLinea({page: this.page, pageSize: this.pageSize, textSearch: this.searchFormControl.value});
      }
    });
  }

  pageChange(page: number) {
    this.page = page;
    this.getLazyLinea({page: this.page, pageSize: this.pageSize, textSearch: this.searchFormControl.value});
  }

  sortRows(c: string) {
    const sort = c.substr(11);
    if (this.orderType === 'ASC') {
      this.orderType = 'DESC';
    } else {
      this.orderType = 'ASC';
    }
    switch (sort) {
      case 'ID':
        this.getLazyLinea({
          page: this.page, pageSize: this.pageSize, textSearch: this.searchFormControl.value, sort: {
            attribute: 'id',
            direction: this.orderType
          }
        });
        break;
      case 'LINEA_DESCRIPTION':
        this.getLazyLinea({
          page: this.page, pageSize: this.pageSize, textSearch: this.searchFormControl.value, sort: {
            attribute: 'description',
            direction: this.orderType
          }
        });
        break;
    }
  }
}

import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {ReportsMgmService} from '../../../../shared/services/reports-mgm.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ClientMgmService} from '../../../../shared/services/client-mgm.service';
import {Router} from '@angular/router';
import {BreadcrumbService} from '../../../../core/services/breadcrumb.service';
import {TranslateService} from '@ngx-translate/core';
import {OperatorReport} from '../../../../shared/models/operator-report';
import {TimeLaps} from '../../../../shared/enum/time-laps';
import {OperatorMgmService} from '../../../../shared/services/operator-mgm.service';
import {Operator} from '../../../../shared/models/operator';

@Component({
  selector: 'app-operator-reports',
  templateUrl: './operator-reports.component.html',
  styleUrls: ['./operator-reports.component.scss']
})
export class OperatorReportsComponent implements OnInit {
  searchFormControl: FormGroup;
  types: any;
  page = 1;
  columns = [
    'STATISTIC_FORM.USERNAME',
    'STATISTIC_FORM.QTT_SALES',
    'STATISTIC_FORM.QTT_RESO',
    'STATISTIC_FORM.ALL_SALES',
    'STATISTIC_FORM.ALL_RESO',
    'STATISTIC_FORM.ALL_PROFIT',
  ];
  loading = true;
  firstCall = true;
  rows: OperatorReport[] = [];
  public totalRecords: number;
  public pageSize = 10;
  type = 'PRODUCT';
  timeLaps = [];
  currentOrder = 'USERNAME';
  orderType = 'ASC';
  operatorList: Operator[];
  disableCsv = false;
  disableExcel = false;
  chartType = 'pie';
  title = '';

  chartLabels = [];

  chartColors: Array<any> = [
    {
      backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C', '#949FB1', '#4D5360', '#6e6fc5'],
      hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870', '#A8B3C5', '#616774', '#6e6fc5'],
      borderWidth: 2,
    }
  ];


  chartDataSets: Array<any> = [
    {data: [], label: 'sales chart', backgroundColor: this.chartColors[0].backgroundColor,
      hoverBackgroundColor: this.chartColors[0].hoverBackgroundColor, borderWidth: 2}
  ];

  public chartOptions: any = {
    responsive: true
  };

  constructor(private reportService: ReportsMgmService, protected matDialog: MatDialog,
              private clientService: ClientMgmService,
              private router: Router,
              private breadcrumbService: BreadcrumbService,
              private translate: TranslateService,
              private operatorService: OperatorMgmService) {
  }

  ngOnInit() {
    this.breadcrumbService.sendBreadcrumb(['OPERATOR_REPORT']);
    this.resetSearchForm();
    this.operatorService.getAllOperatorsBySpId().subscribe(d => {
      this.operatorList = d;
    });
  }


  filter() {
    this.page = 1;
    this.search();
  }

  search() {
    this.loading = true;
    this.reportService.getOperatorReport(this.applyFilter()).subscribe(data => {
      this.rows = data.data.sort((a, b) => b.salesQuantity - a.salesQuantity);
      this.loading = false;
      this.firstCall = false;
      this.totalRecords = data.totalRecords;
    });
  }

  pageChange(page: number) {
    this.page = page;
    this.search();
  }

  sortRows(c: string) {
    const sort = c.substr(15);
    switch (sort) {
      case 'USERNAME':
        if (this.currentOrder === sort) {
          if (this.orderType === 'ASC') {
            this.orderType = 'DES';
            this.rows.sort((a, b) => b.username.localeCompare(a.username));
            break;
          }
        }
        this.currentOrder = sort;
        this.orderType = 'ASC';
        this.rows.sort((a, b) => a.username.localeCompare(b.username));
        break;
      case 'QTT_SALES':
        if (this.currentOrder === sort) {
          if (this.orderType === 'ASC') {
            this.orderType = 'DES';
            this.rows.sort((a, b) => b.salesQuantity - a.salesQuantity);
            break;
          }
        }
        this.currentOrder = sort;
        this.orderType = 'ASC';
        this.rows.sort((a, b) => a.salesQuantity - b.salesQuantity);
        break;
      case 'ALL_SALES':
        if (this.currentOrder === sort) {
          if (this.orderType === 'ASC') {
            this.orderType = 'DES';
            this.rows.sort((a, b) => b.salesValue - a.salesValue);
            break;
          }
        }
        this.currentOrder = sort;
        this.orderType = 'ASC';
        this.rows.sort((a, b) => a.salesValue - b.salesValue);
        break;
      case 'QTT_RESO':
        if (this.currentOrder === sort) {
          if (this.orderType === 'ASC') {
            this.orderType = 'DES';
            this.rows.sort((a, b) => b.resoQuantity - a.resoQuantity);
            break;
          }
        }
        this.currentOrder = sort;
        this.orderType = 'ASC';
        this.rows.sort((a, b) => a.resoQuantity - b.resoQuantity);
        break;
      case 'ALL_RESO':
        if (this.currentOrder === sort) {
          if (this.orderType === 'ASC') {
            this.orderType = 'DES';
            this.rows.sort((a, b) => b.resoValue - a.resoValue);
            break;
          }
        }
        this.currentOrder = sort;
        this.orderType = 'ASC';
        this.rows.sort((a, b) => a.resoValue - b.resoValue);
        break;
      case 'ALL_PROFIT':
        if (this.currentOrder === sort) {
          if (this.orderType === 'ASC') {
            this.orderType = 'DES';
            this.rows.sort((a, b) => b.profitValue - a.profitValue);
            break;
          }
        }
        this.currentOrder = sort;
        this.orderType = 'ASC';
        this.rows.sort((a, b) => a.profitValue - b.profitValue);
        break;
    }
  }


  resetSearchForm() {
    this.searchFormControl = new FormGroup({
      dateFrom: new FormControl(null),
      dateTo: new FormControl(null),
      timeLaps: new FormControl(null),
      clientId: new FormControl(null),
      textSearch: new FormControl(null),
      operator: new FormControl(null),
      treatment: new FormControl(true),
      product: new FormControl(true),
    });
    this.filter();
  }

  applyFilter() {
    let dateTo = null;
    if (this.searchFormControl.get('dateTo').value) {
      dateTo = new Date(this.searchFormControl.get('dateTo').value);
      dateTo.setHours(dateTo.getHours() + 23);
    }
    return {
      page: this.page,
      pageSize: this.pageSize,
      dateFrom: this.searchFormControl.get('dateFrom').value,
      dateTo,
      timeLaps: this.searchFormControl.get('timeLaps').value,
      type: this.searchFormControl.get('treatment').value && this.searchFormControl.get('product').value ? null :
        this.searchFormControl.get('treatment').value ? 'TREATMENT' :
          this.searchFormControl.get('product').value ? 'PRODUCT' : null,
      operator: this.searchFormControl.get('operator').value,
      textSearch: this.searchFormControl.get('textSearch').value,
    };
  }

  translateTimeLaps() {
    this.timeLaps = [
      {id: TimeLaps.CURRENT_WEEK, value: this.translate.instant('STATISTIC_FORM.' + TimeLaps.CURRENT_WEEK)},
      {id: TimeLaps.LAST_WEEK, value: this.translate.instant('STATISTIC_FORM.' + TimeLaps.LAST_WEEK)},
      {id: TimeLaps.CURRENT_MONTH, value: this.translate.instant('STATISTIC_FORM.' + TimeLaps.CURRENT_MONTH)},
      {id: TimeLaps.LAST_MONTH, value: this.translate.instant('STATISTIC_FORM.' + TimeLaps.LAST_MONTH)},
      {id: TimeLaps.CURRENT_YEAR, value: this.translate.instant('STATISTIC_FORM.' + TimeLaps.CURRENT_YEAR)},
      {id: TimeLaps.LAST_YEAR, value: this.translate.instant('STATISTIC_FORM.' + TimeLaps.LAST_YEAR)},
      {id: TimeLaps.FIRST_TRIMESTER, value: this.translate.instant('STATISTIC_FORM.' + TimeLaps.FIRST_TRIMESTER)},
      {id: TimeLaps.SECOND_TRIMESTER, value: this.translate.instant('STATISTIC_FORM.' + TimeLaps.SECOND_TRIMESTER)},
      {id: TimeLaps.THIRD_TRIMESTER, value: this.translate.instant('STATISTIC_FORM.' + TimeLaps.THIRD_TRIMESTER)},
      {id: TimeLaps.FORTH_TRIMESTER, value: this.translate.instant('STATISTIC_FORM.' + TimeLaps.FORTH_TRIMESTER)},
      {id: TimeLaps.FIRST_SEMESTER, value: this.translate.instant('STATISTIC_FORM.' + TimeLaps.FIRST_SEMESTER)},
      {id: TimeLaps.SECOND_SEMESTER, value: this.translate.instant('STATISTIC_FORM.' + TimeLaps.SECOND_SEMESTER)},
    ];
  }

  downloadCsv() {
    const request = this.applyFilter();
    request.pageSize = 100000;
    this.disableCsv = true;
    this.reportService.downloadOperatorCsv(this.applyFilter()).subscribe(r => {
      this.download(r);
      this.disableCsv = false;
    }, error => this.disableCsv = false);
  }


  downloadExcel() {
    const request = this.applyFilter();
    request.pageSize = 100000;
    this.disableExcel = true;
    this.reportService.downloadOperatorExcel(this.applyFilter()).subscribe(r => {
      this.download(r);
      this.disableExcel = false;
    }, error => this.disableExcel = false);
  }

  getTotalQuantity() {
    return this.rows.reduce((acc, el) => acc + el.salesQuantity, 0);
  }

  getTotalQuantityReso() {
    return this.rows.reduce((acc, el) => acc + el.resoQuantity, 0);
  }

  getTotalSales() {
    return this.rows.reduce((acc, el) => acc + el.salesValue, 0);
  }

  getTotalReso() {
    return this.rows.reduce((acc, el) => acc + el.resoValue, 0);
  }

  getTotalProfit() {
    return this.rows.reduce((acc, el) => acc + el.profitValue, 0);
  }

  download(r) {
    const contentDisposition = r.headers.get('content-disposition');
    const fileName = contentDisposition.replace('attachment; filename=', '');
    const blob = r.body;
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.download = fileName;
    anchor.href = url;
    anchor.click();
  }

  showCharts(reportChart) {
    this.matDialog.open(reportChart, {width: '1200px'});
  }

  translateLabels() {
    this.chartLabels = [];
    this.rows.forEach(op => {
      if (op.username !== 'unknown') {
        this.chartLabels = [...this.chartLabels, op.username];
      } else {
        this.chartLabels = [...this.chartLabels, this.translate.instant('PAYMENT.ANONYMOUS_OPERATOR')];
      }
    });
  }

  showSpecificChart(c, reportChart) {
    this.translateLabels();
    const sort = c.substr(15);
    let sales = [];
    switch (sort) {
      case 'QTT_SALES':
        this.rows.forEach(op => {
          sales = [...sales, op.salesQuantity.toFixed(2)];
        });
        break;
      case 'QTT_RESO':
        this.rows.forEach(op => {
          sales = [...sales, op.resoQuantity.toFixed(2)];
        });
        break;
      case 'ALL_RESO':
        this.rows.forEach(op => {
          sales = [...sales, op.resoValue.toFixed(2)];
        });
        break;
      case 'ALL_SALES':
        this.rows.forEach(op => {
          sales = [...sales, op.salesValue.toFixed(2)];
        });
        break;
      case 'ALL_PROFIT':
        this.rows.forEach(op => {
          sales = [...sales, op.profitValue.toFixed(2)];
        });
        break;
    }
    this.chartDataSets = [
      {data: sales}
    ];
    this.title = this.translate.instant(c);
    this.matDialog.open(reportChart, {minWidth: '90%', minHeight: '70%'});
  }
}

import {
  Component,
  EventEmitter, HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Abonnement} from '../../../../../shared/models/Abonnement';
import {Observable, Subject} from 'rxjs';
import {Product} from '../../../../../shared/models/product';
import {Treatment} from '../../../../../shared/models/treatment';
import {SubscriptionProducts} from '../../../../../shared/models/subscriptionProducts';
import {SubscriptionTreatments} from '../../../../../shared/models/subscriptionTreatments';
import {ProductMgmService} from '../../../../../shared/services/product-mgm.service';
import {TreatmentMgmService} from '../../../../../shared/services/treatment-mgm.service';
import {SubscriptionService} from '../../../../../shared/services/subscription.service';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {SubscriptionType} from '../../../../../shared/enum/subscription-type.enum';
import {ActionState} from '../../../../../shared/enum/action-state.enum';
import {TranslateService} from '@ngx-translate/core';
import {SweetAlertService} from '../../../../../shared/services/sweet-alert.service';
import moment from 'moment';


@Component({
  selector: 'app-subscription-form',
  templateUrl: './subscription-form.component.html',
  styleUrls: ['./subscription-form.component.scss']
})
export class SubscriptionFormComponent implements OnInit, OnDestroy {

  @Input() action;
  @Input() subscription: Abonnement;
  @Input() type: SubscriptionType;
  @Input() clientId: number;
  @Output() updateSubscriptions = new EventEmitter();


  saving = false;

  editMode: boolean;
  subscriptionForm: UntypedFormGroup;
  productsForm: UntypedFormGroup;
  treatmentsForm: UntypedFormGroup;
  preventValueChange = false;
  unsubscribe$ = new Subject();
  productList: Product[] = [];
  treatmentList: Treatment[] = [];
  public productPage = 1;
  public treatmentPage = 1;
  public pageSize = 10;
  public totalRecords: number;

  selectedProduct: Product = null;
  selectedTreatment: Treatment = null;
  selectedProductFormControl = new UntypedFormControl();
  selectedTreatmentFormControl = new UntypedFormControl();
  productFormControl = new UntypedFormControl();
  treatmentFormControl = new UntypedFormControl();

  quantityFormControl = new UntypedFormControl();
  productQuantityFormControl = new UntypedFormControl();
  giftFormControl = new UntypedFormControl();
  discountValueFormControl = new UntypedFormControl();
  productGiftFormControl = new UntypedFormControl();
  productDiscountValueFormControl = new UntypedFormControl();

  products: SubscriptionProducts[] = [];
  treatments: SubscriptionTreatments[] = [];
  searchText: string = '';

  constructor(private productService: ProductMgmService,
              public treatmentService: TreatmentMgmService,
              private subscriptionService: SubscriptionService,
              private sweetAlertService: SweetAlertService,
              private translate: TranslateService,
              private fb: UntypedFormBuilder) {

  }


  ngOnInit() {
    this.editMode = this.subscription !== undefined;
    this.subscriptionForm = this.fb.group({
      id: null,
      product: null,
      treatment: null,
      description: [null, Validators.required],
      alwaysActif: true,
      saleDurationStartsAt: null,
      saleDurationEndsAt: null,
      durationType: null,
      durationStartsAt: null,
      durationEndsAt: null,
      numberOfDays: null,
      products: null,
      treatments: null,
      totalCosts: 0,
      price: 0,
      type: this.type
    });
    this.subscriptionForm.valueChanges.pipe(debounceTime(300)).subscribe(() => {
      this.onFormChange();
    });
    this.productsForm = this.fb.group({
      id: null,
      productId: null,
      quantity: 1,
      quantityUnit: 'Unit',
      discountValue: null,
      discountUnit: '€',
      gift: null,
      totalCosts: null,
      price: null
    });

    this.treatmentsForm = this.fb.group({
      id: null,
      treatmentId: null,
      quantity: 1,
      discountValue: null,
      discountUnit: '€',
      gift: null,
      totalCosts: null,
      price: null
    });


    this.productFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      this.subscriptionForm.get('product').setValue(c);
      if (c) {
        this.productList = [];
        this.productPage = 1;
        this.getLazyProducts();
      } else {
        this.products = [];
      }
    });

    this.treatmentFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      this.subscriptionForm.get('treatment').setValue(c);
      this.treatmentPage = 1;
      this.treatmentList = [];
      this.getLazyTreatment();
      if (!c) {
        this.treatments = [];
      }
    });

    this.treatmentFormControl.setValue(true);

    this.selectedProductFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if (!c) {
        this.initializeProductForm();
        return;
      }
      this.productsForm.get('productId').setValue(c);
      this.selectedProduct = this.productList.find(x => x.id === c);
      if (this.selectedProduct) {
        this.productsForm.get('totalCosts').setValue(this.calculateCost(this.selectedProduct.purchasePrice,
          this.productsForm.get('quantity').value,
          this.productsForm.get('gift').value));

        this.productsForm.get('price').setValue(this.calculateFinalPrice(this.selectedProduct.price,
          this.productsForm.get('quantity').value,
          this.productsForm.get('discountValue').value,
          this.productsForm.get('discountUnit').value));
      }
    });

    this.selectedTreatmentFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if (!c) {
        this.initializeTreatmentForm();
        return;
      }
      this.treatmentsForm.get('treatmentId').setValue(c);
      this.selectedTreatment = this.treatmentList.find(x => x.id === c);
      if (this.selectedTreatment !== null) {
        this.treatmentsForm.get('totalCosts').setValue(this.calculateCost(this.selectedTreatment.treatmentCost,
          this.treatmentsForm.get('quantity').value,
          this.treatmentsForm.get('gift').value));

        this.treatmentsForm.get('price').setValue(this.calculateFinalPrice(this.selectedTreatment.masterPrice,
          this.treatmentsForm.get('quantity').value,
          this.treatmentsForm.get('discountValue').value,
          this.treatmentsForm.get('discountUnit').value));
      }
    });

    this.quantityFormControl.patchValue(1);
    this.productQuantityFormControl.patchValue(1);
    this.quantityFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      this.treatmentsForm.get('quantity').setValue(c ? c : 0);
      if (this.selectedTreatment !== null) {
        this.treatmentsForm.get('totalCosts').setValue(
          this.calculateCost(this.selectedTreatment.treatmentCost,
            this.treatmentsForm.get('quantity').value,
            this.treatmentsForm.get('gift').value));

        this.treatmentsForm.get('price').setValue(this.calculateFinalPrice(this.selectedTreatment.masterPrice,
          this.treatmentsForm.get('quantity').value,
          this.treatmentsForm.get('discountValue').value,
          this.treatmentsForm.get('discountUnit').value));
      }
    });

    this.productQuantityFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      this.productsForm.get('quantity').setValue(c ? c : 0);
      if (this.selectedProduct !== null) {
        this.productsForm.get('totalCosts').setValue(
          this.calculateCost(this.selectedProduct.purchasePrice,
            this.productsForm.get('quantity').value,
            this.productsForm.get('gift').value));

        this.productsForm.get('price').setValue(this.calculateFinalPrice(this.selectedProduct.price,
          this.productsForm.get('quantity').value,
          this.productsForm.get('discountValue').value,
          this.productsForm.get('discountUnit').value));

      }
    });

    this.giftFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      this.treatmentsForm.get('gift').setValue(c ? c : 0);
      if (this.selectedTreatment !== null) {
        this.treatmentsForm.get('totalCosts').setValue(
          this.calculateCost(this.selectedTreatment.treatmentCost,
            this.treatmentsForm.get('quantity').value,
            this.treatmentsForm.get('gift').value)
        );

      }
    });

    this.productGiftFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      this.productsForm.get('gift').setValue(c ? c : 0);
      if (this.selectedProduct !== null) {
        this.productsForm.get('totalCosts').setValue(
          this.calculateCost(this.selectedProduct.purchasePrice,
            this.productsForm.get('quantity').value,
            this.productsForm.get('gift').value)
        );

      }
    });

    this.discountValueFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      this.treatmentsForm.get('discountValue').setValue(c ? c : 0);
      if (this.selectedTreatment !== null) {
        this.treatmentsForm.get('price').setValue(this.calculateFinalPrice(this.selectedTreatment.masterPrice,
          this.treatmentsForm.get('quantity').value,
          this.treatmentsForm.get('discountValue').value,
          this.treatmentsForm.get('discountUnit').value));

      }
    });

    this.productDiscountValueFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      this.productsForm.get('discountValue').setValue(c ? c : 0);
      if (this.selectedProduct !== null) {
        this.productsForm.get('price').setValue(this.calculateFinalPrice(this.selectedProduct.price,
          this.productsForm.get('quantity').value,
          this.productsForm.get('discountValue').value,
          this.productsForm.get('discountUnit').value));
      }
    });


    if (this.editMode) {
      this.preventValueChange = true;
      this.subscriptionForm.patchValue(this.subscription);
      this.products = this.subscription.products.map(e => {
        e.productId = e.product.id;
        return e;
      });
      this.treatments = this.subscription.treatments.map(e => {
        e.treatmentId = e.treatment.id;
        return e;
      });
      this.productFormControl.patchValue(this.subscription.product);
      this.treatmentFormControl.patchValue(this.subscription.treatment);
      this.subscriptionForm.disable();
      this.productFormControl.disable();
      this.treatmentFormControl.disable();
      this.selectedProductFormControl.disable();
      this.productQuantityFormControl.disable();
      this.productDiscountValueFormControl.disable();
      this.productGiftFormControl.disable();
      this.selectedTreatmentFormControl.disable();
      this.quantityFormControl.disable();
      this.discountValueFormControl.disable();
      this.giftFormControl.disable();
    }

    this.action.subscribe(r => {
      if ('SAVE' === r) {
        this.save();
      }
      if ('ENABLE_EDIT' === r) {
        this.activateEdit();
      }
    });

  }


  initializeProductForm() {
    this.productsForm.reset();
    this.selectedProduct = null;
    this.selectedProductFormControl.patchValue(null, {
      onlySelf: true,
      emitEvent: false
    });
    this.productsForm.get('quantity').setValue(1);
    this.productsForm.get('discountUnit').setValue('€');
    this.productQuantityFormControl.patchValue(1);
    this.productDiscountValueFormControl.patchValue(null);
    this.productGiftFormControl.patchValue(null);
  }

  initializeTreatmentForm() {
    this.treatmentsForm.reset();
    this.selectedTreatment = null;
    this.selectedTreatmentFormControl.patchValue(null, {
      onlySelf: true,
      emitEvent: false
    });
    this.quantityFormControl.patchValue(1);
    this.discountValueFormControl.patchValue(null);
    this.giftFormControl.patchValue(null);
    this.treatmentsForm.get('quantity').setValue(1);
    this.treatmentsForm.get('discountUnit').setValue('€');

  }

  calculateCost(cost, quantity, gift) {
    if (cost === null || cost === 0) {
      return null;
    }
    return ((quantity + (gift !== null ? gift : 0)) * cost);
  }

  calculateFinalPrice(masterPrice, quantity, discountValue, unit) {
    if (masterPrice === null || masterPrice === 0) {
      return null;
    }
    if (unit === '%') {
      return (masterPrice * quantity) - (masterPrice * quantity * discountValue / 100);
    }
    if (unit === '€') {
      return (masterPrice * quantity) - discountValue;
    }

  }

  calculateSubscriptionCost() {
    let total = 0;
    this.products.forEach(x => total += x.totalCosts);
    this.treatments.forEach(x => total += x.totalCosts);
    this.subscriptionForm.get('totalCosts').setValue(total);
  }

  calculateSubscriptionPrice() {
    let total = 0;
    this.products.forEach(x => total += x.price);
    this.treatments.forEach(x => total += x.price);
    this.subscriptionForm.get('price').setValue(total);
  }

  ChangeProductDiscountUnit(c: string) {
    this.productsForm.get('discountUnit').setValue(c);
    if (this.selectedProduct !== null) {
      this.productsForm.get('price').setValue(this.calculateFinalPrice(this.selectedProduct.price,
        this.productsForm.get('quantity').value,
        this.productsForm.get('discountValue').value,
        this.productsForm.get('discountUnit').value));
    }
  }

  ChangeTreatmentDiscountUnit(c: string) {
    this.treatmentsForm.get('discountUnit').setValue(c);
    if (this.selectedTreatment !== null) {
      this.treatmentsForm.get('price').setValue(this.calculateFinalPrice(this.selectedTreatment.masterPrice,
        this.treatmentsForm.get('quantity').value,
        this.treatmentsForm.get('discountValue').value,
        this.treatmentsForm.get('discountUnit').value));
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(void 0);
    this.unsubscribe$.unsubscribe();
  }


  activateEdit() {
    if (this.editMode) {
      this.subscriptionForm.enable();
      this.subscriptionForm.enable();
      this.productFormControl.enable();
      this.treatmentFormControl.enable();
      this.selectedProductFormControl.enable();
      this.productQuantityFormControl.enable();
      this.productDiscountValueFormControl.enable();
      this.productGiftFormControl.enable();
      this.selectedTreatmentFormControl.enable();
      this.quantityFormControl.enable();
      this.discountValueFormControl.enable();
      this.giftFormControl.enable();
      if (this.subscriptionForm.get('alwaysActif').value) {
        this.subscriptionForm.get('saleDurationStartsAt').disable();
        this.subscriptionForm.get('saleDurationEndsAt').disable();
      }
      this.preventValueChange = false;
    }
  }

  save() {
    if (!this.saving) {
      if (!this.subscriptionForm.valid) {
        this.sweetAlertService.notification(this.translate.instant('DIALOG.INSERT_ALL_MANDATORY_INFORMATION')).then(e => {
        });
        return;
      }
      if (this.products.length === 0 && this.treatments.length === 0) {
        this.sweetAlertService.notification(this.translate.instant('SUBSCRIPTION_FORM.CHOOSE_PRODUCT_OR_TREATMENT')).then(e => {
        });
        return;
      }
      let date;
      if (!this.subscriptionForm.get('alwaysActif').value) {
        if (this.subscriptionForm.get('saleDurationEndsAt').value) {
          date = new Date(moment(this.subscriptionForm.get('saleDurationEndsAt').value).format('YYYY-MM-DD'));
          date.setHours(23);
          this.subscriptionForm.get('saleDurationEndsAt').setValue(date);
        }
        if (this.subscriptionForm.get('saleDurationStartsAt').value) {
          date = new Date(moment(this.subscriptionForm.get('saleDurationStartsAt').value).format('YYYY-MM-DD'));
          this.subscriptionForm.get('saleDurationStartsAt').setValue(date);
        }
      }
      if (this.subscriptionForm.get('durationStartsAt').value) {
        date = new Date(moment(this.subscriptionForm.get('durationStartsAt').value).format('YYYY-MM-DD'));
        this.subscriptionForm.get('durationStartsAt').setValue(date);
      }
      if (this.subscriptionForm.get('durationEndsAt').value) {
        date = new Date(moment(this.subscriptionForm.get('durationEndsAt').value).format('YYYY-MM-DD'));
        date.setHours(23);
        this.subscriptionForm.get('durationEndsAt').setValue(date);
      }
      if (this.subscriptionForm.valid) {
        this.saving = true;
        if (!this.editMode) {
          this.subscriptionService.add({
            ...this.subscriptionForm.getRawValue(),
            products: this.products, treatments: this.treatments, clientId: this.clientId
          }).subscribe(d => {
            this.saving = false;
            this.updateSubscriptions.emit(this.subscriptionForm.get('description').value);
          });
        } else {
          this.subscriptionService.update({
            ...this.subscriptionForm.getRawValue(),
            products: this.products, treatments: this.treatments, clientId: this.clientId
          }).subscribe(d => {
            this.saving = false;
            this.updateSubscriptions.emit(this.subscriptionForm.get('description').value);
          }, err => {
            this.saving = false;
            this.sweetAlertService.notification(this.translate.instant('DIALOG.CANNOT_UPDATE_SUBSCRIPTION')).then(e => {
              this.action.next(ActionState.DISABLE_EDIT);

              // RIPRISTINO FORM


            });
            return;
          });
        }
      } else {
        if (this.editMode) {
          this.action.next(ActionState.ENABLE_EDIT);
        } else {
          this.action.next(ActionState.ADD);
        }
      }
    }
  }

  radioChange(e) {
    this.subscriptionForm.get('durationStartsAt').setValue(null);
    this.subscriptionForm.get('durationEndsAt').setValue(null);
    this.subscriptionForm.get('numberOfDays').setValue(null);
    if (this.subscriptionForm.get('durationType').value === 'OTHER') {
      this.subscriptionForm.get('durationStartsAt').setValidators(Validators.required);
      this.subscriptionForm.get('durationEndsAt').setValidators(Validators.required);
    } else {
      this.subscriptionForm.get('durationStartsAt').setValidators(null);
      this.subscriptionForm.get('durationEndsAt').setValidators(null);
    }
    this.subscriptionForm.get('durationStartsAt').updateValueAndValidity();
    this.subscriptionForm.get('durationEndsAt').updateValueAndValidity();
  }

  checkboxChange(e) {
    this.subscriptionForm.get('saleDurationStartsAt').setValue(null);
    this.subscriptionForm.get('saleDurationEndsAt').setValue(null);
    if (!this.subscriptionForm.get('alwaysActif').value) {
      this.subscriptionForm.get('saleDurationStartsAt').setValidators(Validators.required);
      this.subscriptionForm.get('saleDurationEndsAt').setValidators(Validators.required);
    } else {
      this.subscriptionForm.get('saleDurationStartsAt').setValidators(null);
      this.subscriptionForm.get('saleDurationEndsAt').setValidators(null);
    }
    this.subscriptionForm.get('saleDurationStartsAt').updateValueAndValidity();
    this.subscriptionForm.get('saleDurationEndsAt').updateValueAndValidity();
  }

  getLazyProducts() {
    this.productService.getLazyProductList({
      page: this.productPage, pageSize: this.pageSize, checkActiveDetailSale: true, checkToSellProduct: true
    }).subscribe(d => {
      this.productList = [...this.productList, ...d.data];
      this.productPage++;
    });
  }

  getLazyTreatment() {
    this.treatmentService.getLazyTreatmentList({page: this.treatmentPage, pageSize: 100, priceNotNul: true})
      .subscribe(data => {
        this.treatmentList = [...this.treatmentList, ...data.data];
        this.treatmentPage++;
      });
  }


  saveProduct() {
    if (this.checkDiscount(this.productsForm)){
      if (null !== this.selectedProduct) {
        this.products.unshift({...this.productsForm.getRawValue(), product: this.selectedProduct});
        this.initializeProductForm();
        this.calculateSubscriptionCost();
        this.calculateSubscriptionPrice();
      }
    } else {
      this.sweetAlertService.danger(this.translate.instant('HEADER.BREADCRUMB.CHECK_DATA'));
      return
    }
  }

  saveTreatment() {
    if (this.checkDiscount(this.treatmentsForm)) {
      if (null !== this.selectedTreatment) {
        this.treatments.unshift({...this.treatmentsForm.getRawValue(), treatment: this.selectedTreatment});
        this.initializeTreatmentForm();
        this.calculateSubscriptionCost();
        this.calculateSubscriptionPrice();
      }
    } else {
      this.sweetAlertService.danger(this.translate.instant('HEADER.BREADCRUMB.CHECK_DATA'));
      return
    }
  }

  deleteProduct(index) {
    this.products.splice(index, 1);
    this.calculateSubscriptionCost();
    this.calculateSubscriptionPrice();
  }

  deleteTreatment(index) {
    this.treatments.splice(index, 1);

    this.calculateSubscriptionCost();
    this.calculateSubscriptionPrice();
  }

  checkDiscount(form) {
    if (form.value.discountUnit === "€") {
      if (form.value.price < 0) {
        return false;
      }
    } else {
      if (form.value.discountValue >= 100) {
        return false;
      }
    }
    return true;
  }

  onFormChange() {
    this.subscriptionService.emitFormDirtyChanged(this.subscriptionForm.dirty);
  }
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.subscriptionForm.dirty) {
      $event.returnValue = true;
    }
  }
}

import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Client} from '../models/client';
import {SearchResponse} from '../dto/search-response';
import {LazyRequest} from '../dto/lazy-request';

@Injectable()
export class VanityClientsService {

  VANITY_CLIENT_API = environment.api + '/vanity-clients';

  constructor(private http: HttpClient) {
  }


  update(client: Client) {
    return this.http.put<Client>(`${this.VANITY_CLIENT_API}`, client);
  }

  delete(clientId) {
    return this.http.delete(`${this.VANITY_CLIENT_API}/${clientId}`, {observe: 'response'});
  }

  getLazy(request: ClientLazyRequest) {
    return this.http.post<SearchResponse<Client>>(`${this.VANITY_CLIENT_API}/filter`, request);
  }

  enableDisable(clientId) {
    return this.http.put(`${this.VANITY_CLIENT_API}/enable-disable/${clientId}`, {}, {observe: 'response'});
  }

  confirm(id) {
    return this.http.get(`${this.VANITY_CLIENT_API}/confirm/${id}`);
  }
}

class ClientLazyRequest extends LazyRequest {
  confirmed?: boolean;
}

import { Component, OnInit } from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {FirstNoteFormComponent} from './first-note-form/first-note-form.component';
import {FirstNoteService} from '../../../../shared/services/first-note.service';
import {FirstNote} from '../../../../shared/models/first-note';
import moment from 'moment';
import {SweetAlertService} from '../../../../shared/services/sweet-alert.service';
import {TranslateService} from '@ngx-translate/core';
import {BreadcrumbService} from '../../../../core/services/breadcrumb.service';

@Component({
  selector: 'app-first-note',
  templateUrl: './first-note.component.html',
  styleUrls: ['./first-note.component.scss']
})
export class FirstNoteComponent implements OnInit {
  dates: string[];
  firstNotes: FirstNote[];
  selectedIndex;
  income;
  outlay;
  constructor(
    private matDialog: MatDialog,
    private firstNoteService: FirstNoteService,
    private sweetAlertService: SweetAlertService,
    private translateService: TranslateService,
    private breadcrumbService: BreadcrumbService) { }

  ngOnInit() {
    this.breadcrumbService.sendBreadcrumb(['FIRST_NOTE']);
    this.firstNoteService.getFirstNoteDates().subscribe(dates => {
      this.dates = dates;
      if (dates.length > 0) {
        this.loadFirstNoteByDate(dates[0]);
        this.selectedIndex = dates[0];
      }
    });
  }

  openFirstNoteForm(type: 'INCOME' | 'OUTLAY') {
    this.matDialog.open(FirstNoteFormComponent, {width: '600px', disableClose: true, data: {type}})
      .afterClosed().subscribe(v => {
        if (v) {
          this.loadFirstNoteDates();
          const date = moment().format('YYYY-MM-DD');
          this.loadFirstNoteByDate(date);
          this.selectedIndex = date;
        }
    });
  }

  loadFirstNoteDates() {
    this.firstNoteService.getFirstNoteDates().subscribe(dates => {
      this.dates = dates;
    });
  }

  loadFirstNoteByDate(date) {
    this.firstNoteService.findAllByCurrentSellPointAndDate(date).subscribe(r => {
      this.firstNotes = r;
      this.income = r.filter(u => u.noteType === 'INCOME').reduce((acc, curr) => acc + curr.amount, 0);
      this.outlay = r.filter(u => u.noteType === 'OUTLAY').reduce((acc, curr) => acc + curr.amount, 0);
    });
  }

  delete(id, index) {
    this.sweetAlertService.warning(this.translateService.instant('FIRST_NOTE.DELETE_MSG'))
      .then(e => {
        if (e.value) {
          this.firstNoteService.delete(id).subscribe(() => {
            this.loadFirstNoteDates();
            this.firstNotes.splice(index, 1);
            this.income = this.firstNotes.filter(u => u.noteType === 'INCOME').reduce((acc, curr) => acc + curr.amount, 0);
            this.outlay = this.firstNotes.filter(u => u.noteType === 'OUTLAY').reduce((acc, curr) => acc + curr.amount, 0);
          });
        }
      });
  }
}

import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {FirstNoteCause} from '../models/first-note';

@Injectable({
  providedIn: 'root'
})
export class FirstNoteCauseService {
  API = environment.api + '/first-note-causes';

  constructor(private http: HttpClient) {
  }

  add(request) {
    return this.http.post<void>(this.API, request);
  }

  getAll() {
    return this.http.get<FirstNoteCause[]>(this.API);
  }

  delete(id: number) {
    return this.http.delete<void>(this.API + '/' + id);
  }
}

import {Component, Inject, OnInit} from '@angular/core';
import {   MAT_DIALOG_DATA,   MatDialog,   MatDialogRef } from '@angular/material/dialog';
import {   MatSnackBar } from '@angular/material/snack-bar';
// import {YearPlanningService} from '../../../../../../../shared/services/year-planning.service';
import {TranslateService} from '@ngx-translate/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {YearPlanningService} from '../../../../../../shared/services/year-planning.service';
import {SweetAlertService} from '../../../../../../shared/services/sweet-alert.service';
import {BreadcrumbService} from '../../../../../../core/services/breadcrumb.service';
import {TypePlanning} from '../../../../../../shared/enum/type-planning';
import {CheckEditPlanningResponse, CheckPlanningError} from '../../../../../../shared/models/check-edit-planning-response';
import {YearPlanningComponent} from '../../../../../../shared/year-planning/year-planning.component';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-cabin-form-modal',
  templateUrl: './operator-planning-form.component.html',
  styles: [`
      .time-input {
          width: 4rem;
          text-align: center;
          border: aliceblue;
      }

      .checkbox {
          display: inline-block
      }

      ::ng-deep .position-ng-select.ng-dropdown-panel {
          z-index: 1500;
      }
  `]

})

export class OperatorPlanningFormComponent implements OnInit {
  opPlanningForm: UntypedFormGroup;
  submitted = false;
  addedElement = false;
  addMultipleCheckbox = new UntypedFormControl(false);
  planningColor = '#642222';
  unsubscribe$ = new Subject();
  positions = [];
  currentYear = new Date().getFullYear();
  generatingError: { week: number; month: number }[] = [];

  constructor(public dialogRef: MatDialogRef<OperatorPlanningFormComponent>,
              private yearPlanningService: YearPlanningService,
              private snackBar: MatSnackBar,
              private translate: TranslateService,
              private sweetAlertService: SweetAlertService,
              private matDialog: MatDialog,
              private breadcrumbService: BreadcrumbService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.opPlanningForm = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      operatorId: new UntypedFormControl(null),
      name: new UntypedFormControl(null, [Validators.required]),
      planningColor: new UntypedFormControl(this.planningColor, [Validators.required]),
      planningPosition: new UntypedFormControl(null, [Validators.required, Validators.min(1)]),
      year: new UntypedFormControl(this.currentYear,
        [Validators.required, Validators.min(this.data.planning ? this.data.planning.year : this.currentYear)]),
      type: new UntypedFormControl(TypePlanning.OPERATOR)
    });
    if (this.data.editMode) {
      this.opPlanningForm.patchValue(this.data.planning);
      this.planningColor = this.data.planning.planningColor;
    }
    if (this.data.generate) {
      // this.generatingError = this.data.generatingError;
      this.opPlanningForm.get('year').setValue(this.data.year.name);
      this.opPlanningForm.get('planningColor').setValue(this.data.color);
      this.opPlanningForm.get('planningPosition').setValue(this.data.pos);
      this.planningColor = this.data.color;
      // if (this.generatingError && this.generatingError.length) {
      //   this.sweetAlertService.notification(this.translate.instant('DIALOG.ATTENTION_INVALID_PLANNING'))
      // }
    }
    this.yearPlanningService.getLastOperatorPosition(this.opPlanningForm.get('year').value).subscribe(r => {
      for (let i = 1; i <= r; i++) {
        this.positions = [...this.positions, i];
      }
      if (this.data.editMode) {
        this.positions.splice(this.positions.length - 1, 1);
      } else {
        if (this.data.generate && this.positions.indexOf(this.data.pos) > -1) {
          this.opPlanningForm.get('planningPosition').setValue(this.data.pos);
        } else {
          this.opPlanningForm.get('planningPosition').setValue(r);
        }
      }
    });

    this.opPlanningForm.controls.name.setValue(this.data.operator.username + ' - ' + this.data.operator.planningName);
    this.opPlanningForm.controls.operatorId.setValue(this.data.operator.id);
    this.opPlanningForm.get('year').valueChanges.pipe(debounceTime(500), takeUntil(this.unsubscribe$)).subscribe(c => {
      if (!this.data.editMode && c) {
        this.positions = [];
        if (c < this.currentYear) {
          this.opPlanningForm.get('planningPosition').setValue(null);
          return;
        }
        this.opPlanningForm.get('planningPosition').setValue(null);
        this.yearPlanningService.getLastOperatorPosition(c).subscribe(r => {
          for (let i = 1; i <= r; i++) {
            this.positions = [...this.positions, i];
          }
          this.opPlanningForm.get('planningPosition').setValue(r);
        });
      }
    });
  }

  openForPlanningDialog() {

    this.yearPlanningService.checkCabinPositionAndYear({
      id: this.opPlanningForm.get('operatorId').value,
      planningId: this.opPlanningForm.get('id').value,
      planningType: TypePlanning.OPERATOR,
      year: this.opPlanningForm.get('year').value,
      position: this.opPlanningForm.get('planningPosition').value,
    }).subscribe((r: CheckEditPlanningResponse) => {
      if (r.result === CheckPlanningError.YEAR) {
        this.sweetAlertService.notification(this.translate.instant('DIALOG.THIS_PLANNING_YEAR_ALREADY_EXIST')).then(e => {
        });
        return;
      }
      this.openYearPlanning();
    }, err => {
    });
  }

  genYearPlanning() {
    this.opPlanningForm.controls.planningColor.setValue(this.planningColor);
    const dialogRef = this.matDialog.open(YearPlanningComponent, {
      width: '90%',
      maxHeight: '88vh',
      autoFocus: true,
      disableClose: true,
      data: {
        // editMode: this.data.editMode,
        form: this.opPlanningForm.value,
        type: TypePlanning.OPERATOR,
        planning: this.data.planning,
        year: this.data.year
      }
    });
    dialogRef.afterClosed().subscribe(d => {
      if (d) {
        this.dialogRef.close(true);

      }
    });
  }

  openYearPlanning() {
    this.opPlanningForm.controls.planningColor.setValue(this.planningColor);
    const dialogRef = this.matDialog.open(YearPlanningComponent, {
      width: '90%',
      maxHeight: '88vh',
      autoFocus: true,
      disableClose: true,
      data: {
        editMode: this.data.editMode,
        form: this.opPlanningForm.value,
        type: TypePlanning.OPERATOR,
        planning: this.data.planning
      }
    });
    dialogRef.afterClosed().subscribe(d => {
      if (d) {
        this.dialogRef.close(true);

      }
    });
  }
}

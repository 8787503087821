// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subheader {
  display: flex;
  justify-content: space-between;
}

.mat-column-action {
  text-align: right;
}

.list-group .active {
  background-color: #2B333D;
  border: none;
}
.list-group .active button {
  color: white !important;
}
.list-group .mat-icon {
  font-size: 18px !important;
}
.list-group button {
  color: #2B333D !important;
}
.list-group .list-group-item {
  cursor: pointer;
}

.col-title {
  font-size: 15px;
  font-weight: 600;
}

.active:focus {
  outline: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/content/pages/configurations/cabin/cabin.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;AACF;;AACA;EACE,iBAAA;AAEF;;AACE;EACE,yBAAA;EACA,YAAA;AAEJ;AADI;EACE,uBAAA;AAGN;AAAE;EACE,0BAAA;AAEJ;AAAE;EACE,yBAAA;AAEJ;AAAE;EACE,eAAA;AAEJ;;AAEA;EACE,eAAA;EACA,gBAAA;AACF;;AACA;EACE,UAAA;AAEF","sourcesContent":[".subheader {\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n.mat-column-action {\r\n  text-align: right;\r\n}\r\n.list-group {\r\n  .active {\r\n    background-color: #2B333D;\r\n    border: none;\r\n    button {\r\n      color: white !important;\r\n    }\r\n  }\r\n  .mat-icon {\r\n    font-size: 18px !important;\r\n  }\r\n  button {\r\n    color: #2B333D !important;\r\n  }\r\n  .list-group-item {\r\n    cursor: pointer;\r\n  }\r\n}\r\n\r\n.col-title {\r\n  font-size: 15px;\r\n  font-weight: 600;\r\n}\r\n.active:focus {\r\n  outline: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

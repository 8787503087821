import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {SearchResponse} from '../dto/search-response';
import {WeekPlanningTemplate} from '../models/week-planning-template';

@Injectable({
  providedIn: 'root'
})
export class WeekPlanningService {
  WEEK_PLANNING_API = environment.api + '/weeks-plannings';

  constructor(private http: HttpClient) {
  }


  addNewPlanning(planning) {
    return this.http.post<WeekPlanningTemplate>(`${this.WEEK_PLANNING_API}`, planning);
  }


  editWeekPlanning(request) {
    return this.http.put<WeekPlanningTemplate>(`${this.WEEK_PLANNING_API}`, request);
  }

  lazyWeekPlanning(request) {
    return this.http.post<SearchResponse<WeekPlanningTemplate>>(`${this.WEEK_PLANNING_API}/filter`, request);
  }
  getAllWeekPlanning() {
    return this.http.get<WeekPlanningTemplate[]>(`${this.WEEK_PLANNING_API}/find-all`);
  }

  deleteWeekPlanning(id: number) {
    return this.http.delete(`${this.WEEK_PLANNING_API}/${id}`, {observe: 'response'});
  }
}

import {Injectable} from '@angular/core';
import {LocalStorageService} from "ngx-webstorage";
import {  MatSnackBar} from "@angular/material/snack-bar";
import {PromotionMgmService} from "./promotion-mgm.service";
import {UntypedFormControl} from "@angular/forms";

declare var FB;

@Injectable({
  providedIn: 'root'
})
export class SocialConfigurationService {

  constructor(
    private localStorage: LocalStorageService,
    private matSnackbar: MatSnackBar,
    private promotionMgmService: PromotionMgmService
  ) {
  }

  loadFacebookToken(appId, appSecret, pageId, igAccountId: UntypedFormControl) {

    FB.init({
      appId: appId,
      status: true,
      xfbml: true,
      version: 'v16.0'
    });

    (function (d) {
      var js, id = 'facebook-jssdk', ref = d.getElementsByTagName('script')[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement('script');
      js.id = id;
      js.async = true;
      js.src = `https://connect.facebook.net/en_US/sdk.js#version=v16.0&appId=${appId}&xfbml=true&autoLogAppEvents=true`;
      ref.parentNode.insertBefore(js, ref);
    }(document));
    console.log('after init fb script ... ');
    // Call FB.login or your function for token generation here
    FB.getLoginStatus(function(response) {
      console.log('respons login status = ', response);
    });
    this.facebookInstagramGenToken(appId, appSecret, pageId, igAccountId);
  }


  facebookInstagramGenToken(appId, appSecret, pageId, igAccountId: UntypedFormControl) {
    FB.login((authResponse) => {
      console.log('authResponse = ', authResponse);
      if (authResponse.authResponse) {
        FB.api('/me', (meResponse) => {
          console.log(meResponse);
          FB.api('/oauth/access_token', 'get',
            {
              grant_type: 'fb_exchange_token',
              client_id: appId,
              client_secret: appSecret,
              fb_exchange_token: authResponse.authResponse.accessToken,
            },
            (response) => {
            console.log('login fb response = ', response);
              if (response.error) {
                this.matSnackbar.open(response.error.message, null, {duration: 2500});
              }
              else {
                FB.api('/' + pageId, 'get',
                  {
                    fields: 'access_token',
                    access_token: response.access_token
                  },
                  (pageTokenResp) => {
                    if (pageTokenResp.error) {
                      this.matSnackbar.open('Wrong PAGE_ID', null, {duration: 2500});
                    } else {
                      FB.api('/' + pageId, 'get', {
                        fields: 'instagram_business_account',
                        access_token: pageTokenResp.access_token
                      }, (igIdResp) => {
                        let igUserId;
                        if (igIdResp.instagram_business_account && igIdResp.instagram_business_account.id) {
                          igUserId = igIdResp.instagram_business_account.id;
                          igAccountId.setValue(igUserId);
                        }
                        this.promotionMgmService
                          .saveFacebookConfig({
                            appId, appSecret, pageId, pageToken: pageTokenResp.access_token, instagramUserId: igUserId
                          })
                          .subscribe(res => {
                            this.matSnackbar.open('Token Loaded', null, {duration: 2500});
                          }, error => {
                            this.matSnackbar.open('Internal server error', null, {duration: 2500});
                          });
                      });
                    }
                  });
              }
            });
        });
      } else {
        console.log('User cancelled login or did not fully authorize.');
      }
    }, {
      scope: 'email, openid, pages_manage_cta, pages_show_list, business_management, pages_read_engagement, pages_manage_metadata, pages_manage_ads, pages_manage_posts, instagram_basic, instagram_content_publish, public_profile',
      return_scopes: true
    });
  }
}

import {Component, Inject, OnInit} from '@angular/core';
import {RtService} from '../../../../../shared/services/rt.service';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {ConfigurationsService} from '../../../../../shared/services/configurations.service';
import {IvaCode} from '../../../../../shared/models/ivaCode';
import {PaymentMode} from '../../../../../shared/enum/payment-mode';
import {PaymentMethods} from '../../../../../shared/models/payment-methods';
import {PaymentMethodsType} from '../../../../../shared/enum/payment-methods-type';

@Component({
  selector: 'app-payment-rt-pagamento-config',
  templateUrl: './payment-rt-pagamento-config.component.html',
  styleUrls: ['./payment-rt-pagamento-config.component.scss']
})
export class PaymentRtPagamentoConfigComponent implements OnInit {
  paymentModeList = [];

  constructor(private rtService: RtService, @Inject(MAT_DIALOG_DATA) public data,
              public dialogRef: MatDialogRef<PaymentRtPagamentoConfigComponent>) {
  }

  ngOnInit() {
    this.paymentModeList = [
      {paymentMethodsType: PaymentMethodsType.CASH, paymentRtId: null, configRtId: this.data.id},
      {paymentMethodsType: PaymentMethodsType.CHEQUE, paymentRtId: null, configRtId: this.data.id},
      {paymentMethodsType: PaymentMethodsType.CREDIT_CARD, paymentRtId: null, configRtId: this.data.id},
      {paymentMethodsType: PaymentMethodsType.TRANSFER, paymentRtId: null, configRtId: this.data.id},
      {paymentMethodsType: PaymentMethodsType.SATISPAY, paymentRtId: null, configRtId: this.data.id},
      {paymentMethodsType: PaymentMethodsType.GROUPON, paymentRtId: null, configRtId: this.data.id},
      {paymentMethodsType: PaymentMethodsType.FIDELITY_CARD, paymentRtId: null, configRtId: this.data.id},
      {paymentMethodsType: PaymentMethodsType.SUBSCRIPTION, paymentRtId: null, configRtId: this.data.id},
    ];
    this.rtService.getPaymentTypeConfig(this.data.id).subscribe(r => {
      if (!r.length) {
        return;
      }
      r.forEach(u => {
        if (PaymentMethodsType.CASH === u.paymentMethodsType) {
          this.paymentModeList[0].paymentRtId = u.paymentRtId;
          return;
        }
        if (PaymentMethodsType.CHEQUE === u.paymentMethodsType) {
          this.paymentModeList[1].paymentRtId = u.paymentRtId;
          return;
        }
        if (PaymentMethodsType.CREDIT_CARD === u.paymentMethodsType) {
          this.paymentModeList[2].paymentRtId = u.paymentRtId;
          return;
        }
        if (PaymentMethodsType.TRANSFER === u.paymentMethodsType) {
          this.paymentModeList[3].paymentRtId = u.paymentRtId;
          return;
        }
        if (PaymentMethodsType.SATISPAY === u.paymentMethodsType) {
          this.paymentModeList[4].paymentRtId = u.paymentRtId;
          return;
        }
        if (PaymentMethodsType.GROUPON === u.paymentMethodsType) {
          this.paymentModeList[5].paymentRtId = u.paymentRtId;
          return;
        }
        if (PaymentMethodsType.FIDELITY_CARD === u.paymentMethodsType) {
          this.paymentModeList[6].paymentRtId = u.paymentRtId;
          return;
        }
        if (PaymentMethodsType.SUBSCRIPTION === u.paymentMethodsType) {
          this.paymentModeList[7].paymentRtId = u.paymentRtId;
          return;
        }
      });
    });
  }

  save() {
    this.rtService.savePaymentPagamentoConfig(this.paymentModeList).subscribe(r => {
      this.dialogRef.close(null);
    });
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OwnerComponent} from './owner.component';
import {RouterModule, Routes} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {GroupMgmComponent} from './components/group-mgm/group-mgm.component';
import {MatIconModule} from '@angular/material/icon';
import {MatTreeModule} from '@angular/material/tree';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {TreeViewComponent} from './components/group-mgm/treeview/tree-view.component';
import {GroupFormModalComponent} from './components/group-mgm/group-form-modal/group-form-modal.component';
import {MatLegacyDialog as MatDialog, MatLegacyDialogModule} from '@angular/material/legacy-dialog';
import {MatDividerModule} from '@angular/material/divider';
import {OwnerService} from './services/owner.service';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {MatExpansionModule} from '@angular/material/expansion';
import {SuperAdminMgmFormComponent} from './components/group-mgm/super-admin-mgm-form/super-admin-mgm-form.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {MatListModule} from '@angular/material/list';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {DateMgmFormModalComponent} from './components/group-mgm/date-mgm-form-modal/date-mgm-form-modal.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatMenuModule} from '@angular/material/menu';
import {TypesOfActivitiesComponent} from './components/types-of-activities/types-of-activities.component';
import {TypesOfActivitiesFormComponent} from './components/types-of-activities/types-of-activities-form.component';
import {LicenseConfigurationComponent} from './components/license-configuration/license-configuration.component';
import {LicenseConfigFormComponent} from './components/license-configuration/license-config-form/license-config-form.component';
import {MatChipsModule} from '@angular/material/chips';
import {VanityClientMgmComponent} from './components/vanity-client-mgm/vanity-client-mgm.component';
import {VanityClientsService} from '../../../shared/services/vanity-clients.service';
import {MailConfigComponent} from './components/mail-config/mail-config.component';
import {UpdateVersionListComponent} from './components/update-version/update-version-list/update-version-list.component';
import {UpdateVersionDetailsComponent} from './components/update-version/update-version-details/update-version-details.component';
import {SharedModule} from '../../../shared/shared.module';
import {  MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';

import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {ColorPickerModule} from "ngx-color-picker";
import {NgbDropdownModule, NgbModule, NgbTooltipModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';


const routes: Routes = [
  {
    path: '',
    component: OwnerComponent,
    children: [
      {
        path: '',
        component: GroupMgmComponent
      },
      {
        path: 'vanity-clients',
        component: VanityClientMgmComponent
      },
      {
        path: 'types-of-activities',
        component: TypesOfActivitiesComponent
      },
      {
        path: 'license-config',
        component: LicenseConfigurationComponent
      },
      {
        path: 'mail-config',
        component: MailConfigComponent
      },
      {
        path: 'update-version/list',
        component: UpdateVersionListComponent
      },
      {
        path: 'update-version/details/:updateVersionId',
        component: UpdateVersionDetailsComponent
      },
    ]
  }
];

@NgModule({
  declarations: [
    OwnerComponent,
    GroupMgmComponent,
    TreeViewComponent,
    GroupFormModalComponent,
    SuperAdminMgmFormComponent,
    DateMgmFormModalComponent,
    TypesOfActivitiesComponent,
    TypesOfActivitiesFormComponent,
    LicenseConfigurationComponent,
    LicenseConfigFormComponent,
    VanityClientMgmComponent,
    MailConfigComponent,
    UpdateVersionListComponent,
    UpdateVersionDetailsComponent,
  ],
  providers: [
    OwnerService,
    VanityClientsService
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatButtonModule,
    MatIconModule,
    MatTreeModule,
    MatCheckboxModule,
    MatLegacyDialogModule,
    MatDividerModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    TranslateModule,
    MatExpansionModule,
    NgbDropdownModule,
    NgSelectModule,
    MatSnackBarModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatMenuModule,
    NgbTypeaheadModule,
    MatChipsModule,
    NgbTooltipModule,
    FormsModule,
    NgbModule,
    SharedModule,
    MatTabsModule,
    CKEditorModule,
    ColorPickerModule
  ]
})
export class OwnerModule {
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prefix-list {
  list-style: none;
  padding: 0;
  max-height: 10rem;
  overflow-y: scroll;
  margin: 0;
}
.prefix-list li {
  padding: 10px;
  font-size: 12px;
  border-bottom: 1px solid #e6e6e6;
}
.prefix-list li:hover {
  background-color: #6966ff;
  color: white;
  cursor: pointer;
}

.dropdown-toggle {
  padding: 0.42rem 1.1rem;
  font-size: 0.85rem;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/dropdown-select/dropdown-select.component.scss"],"names":[],"mappings":"AACA;EACE,gBAAA;EACA,UAAA;EACA,iBAAA;EACA,kBAAA;EACA,SAAA;AAAF;AACE;EACE,aAAA;EACA,eAAA;EACA,gCAAA;AACJ;AACE;EACE,yBAAA;EACA,YAAA;EACA,eAAA;AACJ;;AAIA;EACE,uBAAA;EACA,kBAAA;EACA,qCAAA;EACA,wCAAA;AADF","sourcesContent":["// search dropdown for prefix number style\r\n.prefix-list {\r\n  list-style: none;\r\n  padding: 0;\r\n  max-height: 10rem;\r\n  overflow-y: scroll;\r\n  margin: 0;\r\n  li {\r\n    padding: 10px;\r\n    font-size: 12px;\r\n    border-bottom: 1px solid #e6e6e6;\r\n  }\r\n  li:hover {\r\n    background-color: #6966ff;\r\n    color: white;\r\n    cursor: pointer;\r\n  }\r\n}\r\n\r\n\r\n.dropdown-toggle {\r\n  padding: .42rem 1.1rem;\r\n  font-size: .85rem;\r\n  border-top-right-radius: 0 !important;\r\n  border-bottom-right-radius: 0 !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

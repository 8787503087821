export enum MovementType {
  SUPPLIER_LOAD = 'SUPPLIER_LOAD',
  VARIOUS_LOAD = 'VARIOUS_LOAD',
  CUSTOMER_SALES = 'CUSTOMER_SALES',
  VARIOUS_DISCHARGE = 'VARIOUS_DISCHARGE',
  SELF_CONSUMPTION = 'SELF_CONSUMPTION',
  EDIT_NAME = 'EDIT_NAME',
  EDIT_COST = 'EDIT_COST',
  EDIT_PRICE = 'EDIT_PRICE',
  EDIT_DETAILS = 'EDIT_DETAILS',
  CREATION = 'CREATION',
  RESO = 'RESO',
  TRANSFER = 'TRANSFER',
  INVENTORY = 'INVENTORY'
}

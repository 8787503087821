import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StockManagementComponent} from './stock-management.component';
import {NgbDropdownModule, NgbModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule, Routes} from '@angular/router';
import {MatLegacyButtonModule} from '@angular/material/legacy-button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatLegacyDialogModule as MatLegacyDialogModule} from '@angular/material/legacy-dialog';
import {   MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import {   MatInputModule } from '@angular/material/input';
import {   MatListModule } from '@angular/material/list';
import {   MatPaginatorModule } from '@angular/material/paginator';
import {   MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRippleModule } from '@angular/material/core';
import {   MatSnackBarModule } from '@angular/material/snack-bar';
import {   MatTableModule } from '@angular/material/table';
import {MatLegacyCheckboxModule} from '@angular/material/legacy-checkbox';
import {TranslateModule} from '@ngx-translate/core';
import {ReactiveFormsModule} from '@angular/forms';
import {SubheaderModule} from '../../../layout/components/subheader/subheader.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {NgSelectModule} from '@ng-select/ng-select';
import { CurrencyMaskModule } from "ng2-currency-mask";

import { StockHistoryComponent } from './stock-history/stock-history.component';

const routes: Routes = [
];
@NgModule({
    declarations: [StockManagementComponent, StockHistoryComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatLegacyButtonModule,
        TranslateModule,
        NgbModule,
        ReactiveFormsModule,
        SubheaderModule,
        NgxDatatableModule,
        MatTableModule,
        MatListModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatRippleModule,
        NgbDropdownModule,
        MatSnackBarModule,
        MatLegacyDialogModule,
        NgbPaginationModule,
        MatProgressSpinnerModule,
        NgSelectModule,
        MatIconModule,
        CurrencyMaskModule,
        MatLegacyCheckboxModule
    ],
    exports: [
        StockHistoryComponent
    ]
})
export class StockManagementModule { }

import {Component, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {  MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {ImageCroppedEvent, ImageTransform} from "ngx-image-cropper";
import {ActivatedRoute, Router} from "@angular/router";
import {MobileAppConfigService} from "../../../../../shared/services/mobile-app-config.service";
import {HttpEventType} from "@angular/common/http";

@Component({
  selector: 'app-banner-edit',
  templateUrl: './banner-edit.component.html',
  styleUrls: ['./banner-edit.component.scss']
})
export class BannerEditComponent implements OnInit {
  imageChangeEvent: any;
  unsubscribe$ = new Subject();
  imageLoad = false;
  imageFile: any = null;
  selectedImage = false;
  croppedImage: string;
  transform: ImageTransform;
  scale = 1;
  canvasRotation = 0;
  loadImg = false;
  loadProgress = 0;
  uploadProgress = 0;
  constructor(
    private matSnackbar: MatSnackBar,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private mobileConfigService: MobileAppConfigService
  ) {
  }

  ngOnInit() {
    const edit = this.activatedRoute.snapshot.queryParamMap.get('action');
    if (edit === 'edit') {
      this.loadImg = true;
      const imgId = this.activatedRoute.snapshot.queryParamMap.get('id') as unknown as number;
      this.mobileConfigService.loadImage(imgId).subscribe(e => {
        if (e.type === HttpEventType.DownloadProgress) {
          this.loadProgress = (e.loaded / e.total) * 100;
        }
        if (e.type === HttpEventType.Response) {
          this.imageFile = new Blob([e.body], {type: 'image/jpg'});
          this.selectedImage = true;
          this.loadImg = false
        }
      })
    }
  }

  fileChange($event: Event) {
    this.imageLoad = true;
    this.selectedImage = true;
    if (($event.target as HTMLInputElement).files[0].type !== 'image/jpeg'
      && ($event.target as HTMLInputElement).files[0].type !== 'image/png') {
      this.matSnackbar.open(this.translateService.instant('IMAGE.NOT_IMAGE'), 'Ok', {duration: 5000});
      this.imageLoad = false;
      return;
    }
    if (($event.target as HTMLInputElement).files[0].size > 2000000) {
      this.matSnackbar.open(this.translateService.instant('IMAGE.OVERSIZE'), 'Ok', {duration: 5000});
      this.imageLoad = false;
      return;
    }
    this.imageFile = ($event.target as HTMLInputElement).files[0];
    this.imageChangeEvent = $event;
  }

  onDrop($event: DragEvent) {
    this.imageLoad = true;
    this.selectedImage = true;
    $event.preventDefault();
    if ($event.dataTransfer.files[0].type !== 'image/jpeg' && $event.dataTransfer.files[0].type !== 'image/png') {
      this.matSnackbar.open(this.translateService.instant('IMAGE.NOT_IMAGE'), 'Ok', {duration: 5000});
      this.imageLoad = false;
      return;
    }
    if ($event.dataTransfer.files[0].size > 2000000) {
      this.matSnackbar.open(this.translateService.instant('IMAGE.OVERSIZE'), 'Ok', {duration: 5000});
      this.imageLoad = false;
      return;
    }
    this.imageFile = $event.dataTransfer.files[0];
  }

  imageCropped($event: ImageCroppedEvent) {
    this.croppedImage = $event.base64;
  }

  imageLoaded() {
    this.imageLoad = false;
  }

  zoom(action: 'in' | 'out') {
    switch (action) {
      case 'in': {
        this.scale += .1;
        this.transform = {
          ...this.transform,
          scale: this.scale
        };
        break;
      }
      case 'out': {
        this.scale -= .1;
        this.transform = {
          ...this.transform,
          scale: this.scale
        };
      }
    }
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }


  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  save() {
    const action = this.activatedRoute.snapshot.queryParamMap.get("action");
    const confId = this.activatedRoute.snapshot.queryParamMap.get("confId");
    const id = this.activatedRoute.snapshot.queryParamMap.get("id");
    if (action === 'add') {
      this.mobileConfigService.addImage({image: this.croppedImage, mobileConfigId: confId}).subscribe((e) => {
        if (e.type === HttpEventType.UploadProgress) {
          this.uploadProgress = (e.loaded / e.total) * 100;
        }
        if (e.type === HttpEventType.Response) {
          if (!confId) {
            this.router.navigate(['..'], {relativeTo: this.activatedRoute, queryParams: {saved: true}});
          } else {
            this.router.navigate(['..'], {relativeTo: this.activatedRoute});
          }
        }
      });
    } else if (action === 'edit') {
      this.mobileConfigService.updateImage({image: this.croppedImage, mobileConfigId: confId, id}).subscribe((e) => {
        if (e.type === HttpEventType.UploadProgress) {
          this.uploadProgress = (e.loaded / e.total) * 100;
        }
        if (e.type === HttpEventType.Response) {
          this.router.navigate(['..'], {relativeTo: this.activatedRoute});
        }
      });
    }
  }

  getImageSize() {
    const el = document.getElementById("croppedImage");
    if (!el){
      return;
    }
    // @ts-ignore
    return  el.naturalWidth + 'x' + el.naturalHeight;
  }
}

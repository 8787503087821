export const locale = {
  lang: 'fr',
  data: {
    UNAUTHORIZED_MSG: 'Vous n\'êtes pas autorisé à accéder à cette page',
    HEADER: {
      OPERATOR_INFORMATIONS: 'Informations opérateur',
      MEGA_MENU: 'sd',
      MANAGE_OPERATORS: 'Gestion des opérateurs',
      MANAGE_ROLES: 'Gestion des rôles',
      CONFIGURATIONS: 'Configurations',
      HOME: 'Accueil',
      PROFILE: 'Profile',
      TYPES_OF_ACTIVITIES: 'Les types d\'activités',
      LICENSE_CONFIG: 'Configurations des licences',
      CALENDAR: 'Calendrier',
      VANITY_CLIENT: 'Clients vanity',
      BREADCRUMB: {
        DAYS_LEFT: 'jours jours avant l\'expiration de la licence',
        CONTACT: 'Contact',
        COMPANY: 'Société',
        CLIENT: 'Client',
        PROVIDER: 'Fournisseur',
        OPERATORS: 'Opérateur',
        CONFIGURATION: 'Configuration',
        IVA_CODE: 'Tax',
        PAYMENT_TYPE: 'Type de paiement',
        PREF_CONTACT: 'Contact préféré',
        PROFESSION: 'Profession',
        PRODUCT_AND_SERVICES: 'Produits et services',
        PRODUCT: 'Articles',
        PRODUCTS: 'Produits',
        BRANDS: 'Marques',
        CATEGORIES_PRODUCT: 'Catégories des produits',
        CATEGORIES_TREATMENT: 'Catégories des services',
        CABIN: 'Cabine',
        LINEAS: 'Lignes',
        FIDELITY: 'Fidélité',
        TREATMENTS: 'Traitements',
        SUBSCRIPTIONS: 'Abonnements',
        CHECK_DATA: 'Vérifier les données',
        PAYMENT: 'Paiement',
        PAYMENT_HISTORY: 'Reçus',
        WANT_TO_DELETE_WHOLE_RECEIPT: 'Voulez-vous supprimer tout le reçu?',
        PLANNING: 'Planification',
        SMS: 'SMS',
        WhatsApp: 'WhatsApp',
        COMPAIGNS: 'Campagne',
        MOVEMENT: 'Entrepôt',
        MOVEMENT_CARICO: 'Charge d\'entrepôt',
        MOVEMENT_SCARICO: 'D\'echarge d\'entrepôt',
        INVENTORY: 'Inventaire',
        MOVEMENT_AND_STOCK: 'Mouvement et Stockage',
        STOCK: 'Stockage',
        RT: 'Caisse',
        HOW_DID_YOU_MEET_US: 'Comment nous avez-vous connus',
        HISTORY: 'Historique',
        DASHBOARD: 'Dashboard',
        PRODUCT_REPORT: 'Rapports des produits',
        TREATMENT_REPORT: 'Rapports des services',
        OPERATOR_REPORT: 'Rapports des opérateurs',
        QUOTE: 'Quote',
        QUOTE_HISTORY: 'Historiqe des quotes',
        FISCAL_REPORT: 'Rapport financier',
        FIRST_NOTE: 'Première note',
        FIRST_NOTE_CAUSE: 'Première note cause',
        SATISPAY_CONFIG: 'Configuration de Satispay',
        PROMOTION: 'Promotion',
        MOBILE_APP_CONFIG: 'Configuration de l\'APP',
        TEMPLATE_PROMO: 'Template promo',
        SOCIAL_POSTS: 'Publications sociaux',
      }
    },
    SUBHEADER: {
      SEARCH: 'Chercher',
    },
    ALERT_DIALOG: {
      ARE_YOU_SURE: 'Vous êtes sûr ?',
      MSG: 'Vous allez retouerner à la page d\'accueil'
    },
    BUTTONS: {
      SAVE: 'Sauvegarder',
      EDIT: 'Modifier',
      CANCEL: 'Cancel',
      CLOSE: 'Fermer',
      DELETE: 'Supprimer',
      ADD: 'Ajouter',
      EDIT_PASSWORD: 'Changer le mot de passe',
      PAYMENT: 'Paiement',
      MODIFY: 'Modifier',
      RETURN_TO_PAYMENT: 'Retour au paiement',
      GO_TO_PLANNING: 'Aller au planning',
      COPY: 'Copier',
      CUT: 'Couper',
      CHOOSE: 'Sélectionner',
      PRINT: 'Impression',
      PRINT_NO_FISCAL: 'Réimpression non fiscale',
      RETRY: 'Réessayer l\'impression',
      GO_HOME: 'Aller à la page d\'accueil',
      ADD_SUPER_ADMIN: 'Ajouter super admin',
      SCHEDULE: 'Programmer',
      SAVE_DRAFT: 'Enregistrer brouillon',
      SAVE_WITHOUT_PRINT: 'Sauvegarder sans imprimer',
      SAVE_WITH_PRINT: 'Sauvegarder avec l\'impression',
      HISTORY: 'Historique',
      PRINT_TICKET: 'Étiquette',
      PRINT_PLANNING: 'Imprimer le planning',
      DOWNLOAD: 'Télécharger',
      ADD_TINT: 'Teinte',
      CLEAR: 'Nettoyer',
      FACE_PLATES: 'Étiquettes d\'étagère',
      NEXT: 'Suivant',
      PREVIOUS: 'Retour',
      ADD_OPERATOR: 'Ajouter un opérateur',
      ADD_CABIN: 'Ajouter une cabine',
      SEND: 'Envoyer',
      GO_TO: 'Aller à',
      EXPIRED: 'Expiré',
      VALID: 'Valide',
      ENABLED: 'Activé',
      DISABLED: 'Désactivé',
      SEARCH: 'Chercher',
      RESET_FILTER: 'Réinitialiser le filtre',
      ACTIVE: 'Activé',
      DEACTIVATED: 'Désactivé',
      CREAT: 'Créer',
      SEARCH_TICKET: 'Rechercher un ticket',
      ANNUL_TICKET: 'Annuler le ticket',
      RESTORE: 'Restaurer',
      APPLY: 'Appliquer',
      CONTINUE: 'Continuer',
      IMPORT_FROM_FISCAL_RT: 'Importation depuis la rt fiscale',
      NO: 'Non',
      YES: 'Oui',
    },
    LOADING: 'Chargement',
    DATA_TABLE: {
      ID: 'ID',
      FIRST_NAME: 'Prénom',
      LAST_NAME: 'Nom',
      FULL_NAME: 'Nom complet',
      EMAIL: 'E-mail',
      GENDER: 'Sexe',
      DESCRIPTION: 'Description',
      MOBILE: 'Mobile',
      NO_MARKETING: 'Pas de marketing',
      COMPANY: 'Société',
      TAX_DESCRIPTION: 'Description de la TVA',
      TAX_RATE: 'Taux de TVA',
      HOW_DID_YOU_MEET_US: 'Comment nous avez-vous connus',
      CLIENTE: 'Client',
      NO_CONFIGURATION_FOUND: 'Aucune configuration trouvée',
      BUSINESS_NAME: 'Nom de la société',
      VAT_NUMBER: 'Numéro de TVA',
      CREATION_DATE: 'Date de création',
      ROLE: 'Role',
      BRAND_DESCRIPTION: 'Description de la marque',
      CABIN_DESCRIPTION: 'Description de la cabine',
      PLANNING_POSITION: 'Position dans le planning',
      PLANNING_COLOR: 'Couleur dans le planning',
      LINEA_DESCRIPTION: 'Description de la Ligne',
      CATEGORY_DESCRIPTION: 'Description de la categorie',
      DAY: 'Jour',
      DAY_OFF: 'Jour ferié',
      FROM: 'De',
      TO: 'A',
      PAUSE: 'Pause',
      YEAR: 'Année',
      SELL_POINTS: 'Points de vente',
      PLANING_NAME: 'Nom du Planning',
      NAME: 'Nom',
      CATEGORY_NAME: 'Nom de la categorie',
      SUBCATEGORY_NAME: 'Nom de la sous-catégorie',
      SUBCATEGORY_DESCRIPTION: 'Description de la sous-catégorie',
      THRESHOLD: 'Seuil',
      OTHER_THRESHOLD: 'Autre seuil',
      TREATMENT: 'Traitement',
      PRODUCT: 'Produit',
      VALUE: 'Valeur',
      DISCOUNT: 'Remise',
      TYPE: 'Type',
      SEQUENCE: 'Sequence',
      PRICE: 'Prix',
      HALO: 'Prix Max',
      LALO: 'Prix Min',
      VAT: 'TVA',
      GROUP_CODE: 'Groupe de produits',
      CASH: 'Somme en espèces',
      CREDIT: 'Crédit',
      CHANGE: 'Reste activé',
      DRAWER: 'Ouverture de tiroir',
      IP: 'IP',
      MODIFICATION_DATE: 'Date de modification',
      MODEL: 'Model',
      STATUS: 'Statut',
      AMOUNT: 'Quantité',
      TOTAL: 'Totale',
      ERROR: 'Erreur',
      OPERATOR: 'Operateur',
      AMOUNT_PAID: 'Montant payé',
      DATE_FORM: 'jj/mm/aaaa',
      PLANING: 'Planning',
      USERNAME: 'Username',
      SOLD: 'Solde',
      NUMBER: 'Number',
      DATE: 'Date',
      REST: 'Reste',
      POINTS: 'Points',
      QUANTITY: 'Quantité',
      CONSUMED: 'Consommé',
      REMAINING_QUANTITY: 'Quantité restante',
      USE_TYPE: 'Type d\'utilisation',
      PURCHASE_COST: 'Coût d\'achat',
      AVERAGE_COST: 'Coût moyen',
      UNDER_ESCORT: 'Sous escorte',
      PHOTO: 'Photos',
      COMMERCIAL_DESCRIPTION: 'Description commerciale',
      BAR_CODE: 'Barcode',
      CATEGORY: 'Catégorie',
      SUBCATEGORIES: 'Sous-catégorie',
      PROVIDER: 'Fournisseur',
      ML_GR: 'ml / gr',
      BRAND: 'Marque',
      LINEA: 'Ligne',
      STOCK: 'Stock',
      ACTIVE: 'Active',
      CODE: 'Code',
      IVA: 'TVA',
      TOTAL_PURCHASE_COST: 'Coût d\'achat total',
      CAUSAL: 'Causal',
      GIFT: 'Cadeaux',
      SUBCATEGORIES_LIST: 'Sous-categories',
      DOCUMENT: 'Document',
      PURCHASE_DATE: 'Date d\'achat',
      COLOR: 'Couleur',
      TINT: 'Teinte',
      CLIENT: 'Client',
      DATE_AND_HOUR: 'Date et heure',
      MAX_CABINS: 'Max cabines',
      MAX_OPERATORS: 'Max opérateurs',
      MODULES: 'Modules',
      CAUSE: 'Cause',
      F_AMOUNT: 'Montant',
      AVAILABLE: 'Valable',
      VALID: 'Valable',
      SALES: 'Vente',
      PLANING_POSITION: 'Position du Planning',
      RESO_QUANTITY: 'Quantité de retour',
      RESO_AMOUNT: 'Total du retour',
      TOTAL_IN_CASE: 'Total en caisse',
      TOTAL_RENDER: 'total rendu',
      RESO_TYPE: 'Type du retour',
      ANNULMENT: 'Annulation',
      RESO: 'Retour',
      SUBSCRIPTION: 'Abonnement',
      CARD: 'Carte',
      REGISTER_SOURCE: 'Source',
      CONFIRMED: 'Confirmé',
      NOT_CONFIRMED: 'Non Confirmé',
      AMOUNTPAYED: 'Montant Payé',
      SALES_PRICE: 'Prix vente',
      DEMO_RT: 'Imprément de Demo',
    },
    SIDENAV: {
      LINEA: 'Ligne',
      CONTACTS: 'Contacts',
      REGISTER: 'Register',
      COMPANY: 'Société',
      CLIENT: 'Client',
      PROVIDER: 'Fournisseur',
      CONFIGURATIONS: 'Configurations',
      PREF_CONTACT: 'Contact préféré',
      PROD_SERVICE: 'Produits et services',
      PROFESSION: 'Profession',
      PAYMENT_TYPE: 'Type de paiement',
      COUNTRY: 'Pays',
      OPERATORS: 'Opérateurs',
      VAT_CODE: 'Code VAT',
      HOW_DID_YOU_MEET_US: 'Comment nous avez-vous connus',
      CLIENTE: 'Client',
      PRODUCT: 'Article',
      BRAND: 'Marque',
      CABIN: 'Cabine',
      WEEK_PLANNING: 'Semaine de travail',
      CATEGORY_PRODUCT: 'Categorie de type produit',
      CATEGORY_TREATMENT: 'Categorie de type traitement',
      OPERATORS_PLANNING: 'Planning des opérateurs',
      ADMINISTRATION: 'Administration',
      FIDELITY: 'Fidélité',
      TREATMENT: 'Traitement',
      SUBSCRIPTION: 'Abonnement',
      PAYMENT: 'Paiement',
      PAYMENT_HISTORY: 'Reçus',
      RT: 'RT',
      SMS_BASIC_CONFIG: 'Configuration de base SMS',
      WHATS_APP_BASIC_CONFIG: 'Configuration de base WhatsApp',
      SMS_COMPAIGNS: 'Campagnes',
      SMS_INSTANCE: 'Historique',
      MOVEMENT: 'Entrepôt',
      CARICO: 'Charge',
      SCARICO: 'Décharge',
      INVENTORY: 'Inventaire',
      MOVEMENT_AND_STOCK: 'Mouvement et Stockage',
      STOCK: 'Stockage',
      PRODUCT_REPORT: 'Rapports des produits',
      TREATMENT_REPORT: 'Rapports des services',
      OPERATOR_REPORT: 'Rapports des opérateurs',
      QUOTE: 'Quote',
      SALES: 'Vente',
      FISCAL_REPORT: 'Rapport financier',
      FIRST_NOTE: 'Première note',
      FIRST_NOTE_CAUSE_SETTING: 'Paramètres de la cause de la première note',
      PROMOTION: 'Promotion',
      DOCUMENT_MANAGEMENT: 'Gestion Des Documents',
      STATISTICS: 'Statistiques',
      SETUP_SELL_POINT: 'Configuration du point de vente',
      SETUP_CLIENTS: 'Configuration des clients',
      CASH_DESK: 'Caisse',
      PRINTERS: 'Imprimantes',
      CASH_REGISTER_CLOSURE: 'Fermeture de Caisse',
      MOBILE_APP_CONFIG: 'Configuration de l\'APP',
      ENTER_PROMOTION: 'Saisir la promotion',
      SOCIAL_POSTS: 'Publications sociaux'

    },
    CLIENT_FORM: {
      ADD_CLIENT: 'Ajouter un client',
      CLIENT_DETAILS: 'Détails du client',
      CLIENT_TYPE: 'Type de client',
      SAVE: 'Sauvegarder',
      EDIT: 'Editer',
      STATUS_OF_INACTIVITY: 'Statut',
      DEPT: 'Dept',
      LAST_PASSAGE: 'Dernier passage',
      FIRST_PASSAGE: 'Première passage',
      ACTIVE: 'Actif',
      INACTIVE: 'Inactif',
      DEACTIVATED: 'Désactivé',
      VISIBLE: 'Visible',
      HIDDEN: 'Caché',
      STATE: 'Etat',
      MALE: 'Homme',
      FEMALE: 'Femme',
      GENDER: 'Sexe',
      FIRST_NAME: 'Prénom',
      LAST_NAME: 'Nom',
      DATE_OF_BIRTH: 'Dtae de naissance',
      FISCAL_CODE: 'Code fiscal',
      SDI_CODE: 'code SDI',
      CITY: 'Ville',
      STREET: 'Rue',
      COUNTRY: 'Pays',
      ZIP: 'code ZIP',
      ADDRESS: 'Adresse',
      PROVINCE: 'Province',
      MOBILE: 'Mobile',
      WORK_PHONE: 'Téléphone de travail',
      HOME_PHONE: 'Téléphone fixe',
      EMAIL: 'E-mail',
      PREF_CONTACT: 'Contact préféré',
      PROFESSION: 'Profession',
      COMPANY: 'Société',
      PREF_PAYMENT: 'Paiement préféré',
      MEET_INSTITUTE: 'Comment avez-vous rencontré l.institut?',
      CIVIL_STATE: 'Etat civil',
      MARRIED: 'Marrié',
      SONS: 'Fils',
      NO_MARKETING: 'Pas de marketing',
      PRIVACY: 'Privacy',
      NO_APPOINTMENT_REMINDER: 'Pas de rappel de rendez-vous',
      NOTE: 'Note',
      NO_CLIENT_FOUND: 'Aucun client trouvé',
      GENDER_REQUIRED: 'Le sexe est obligatoire',
      FIRST_NAME_REQUIRED: 'Prénom est obligatoire',
      LAST_NAME_REQUIRED: 'Nom est obligatoire',
      MOBILE_REQUIRED: 'Numéro téléphone est obligatoire',
      DATE_FORMAT: 'jj/mm/aaaa',
      PROGRESS_CODE: 'Code de progression',
      SUBSCRIPTION: 'Abonnement',
      CHOOSE_CLIENT: 'Choisir un client',
      FIDELITY: 'Fidélité',
      SELECT_CLIENT: 'Selectioner un client',
      OTHER: 'Autre',
      ANAMNESI: 'Antécédents médicaux',
      PURCHASE_HISTORY: 'Historique d\'achat',
      TOTAL_PRODUCT_PURCHASE: 'Achat tot prod',
      TOTAL_TREATMENT_PURCHASE: 'Achat tot tr',
      TOTAL_PURCHASE: 'Achat totale',
      TOTAL_DISCOUNT: 'Remise totale',
      ADD_TINT: 'Ajouter une teinte',
      EDIT_TINT: 'Changer la teinte',
      TINT: 'Teinte',
      MOBILE_INVALID: 'Le numéro de télephone est erroné',
      WORK_PHONE_REQUIRED: 'Le numéro de télephone est obligatoire',
      HOME_PHONE_REQUIRED: 'Le numéro de télephone est obligatoire',
      TOTAL_TREATMENT_RESO: 'Retour totale du service',
      TOTAL_RESO: 'Retour totale',
      TOTAL_PRODUCT_RESO: 'Retour totale du produit',
      SHOW_DISABLED_CLIENT: 'Désactivés',
      SHOW_NOT_CONFIRMED_CLIENT: 'Non confirmé',
      PREFERRED_OPERATOR: 'Opérateur préféré',
      LOTTERY: 'Loterie',
    },
    COMPANY_FORM: {
      ACTIVE: 'Activé',
      DEACTIVATED: 'Désactivé',
      VISIBLE: 'Visible',
      HIDDEN: 'Caché',
      SAVE: 'Sauvegarder',
      EDIT: 'Editer',
      ADD_COMPANY: 'Ajouter une société',
      COMPANY_DETAILS: 'Détails de la société',
      BUSINESS_NAME: 'Nom de la société',
      VAT_NUMBER: 'Numéro de TVA',
      FISCAL_CODE: 'Code fiscal',
      CODE_SDI: 'Code SDI',
      REA: 'R.E.A',
      REGISTERED_OFFICE: 'Bureau d\'inscription',
      ADDRESS: 'Adresse',
      STREET_NUMBER: 'Numéro de rue',
      NATION: 'Nation',
      POSTAL_CODE: 'Code postale',
      LOCATION: 'Emplacement',
      PROVINCE: 'Province',
      OPERATING_OFFICE: 'Bureau d\'exploitation',
      PHONE: 'Téléphone',
      MOBILE_PHONE: 'Téléphone portable',
      FAX: 'Fax',
      EMAIL: 'E-mail',
      EMAIL_PEC: 'Email Pec',
      REFERENT: 'Référent',
      WEB_SITE: 'Site web',
      BANK: 'Banque',
      BANK_BRANCH: 'Succursale bancaire',
      IBAN: 'IBAN',
      CIN: 'CIN',
      BIC_SWIFT: 'Bic/Swift',
      ABI: 'Abi',
      CAB: 'Cab',
      VAT: 'T.V.A.',
      PAYMENT: 'Paiement',
      NOTE: 'Note',
      NO_COMPANY_FOUND: 'Aucune entreprise trouvée',
      BUSINESS_NAME_REQUIRED: 'Le nom de l\'entreprise est obligatoire',
      VAT_REQUIRED: 'Un numéro de TVA est obligatoire',
      FISCAL_CODE_REQUIRED: 'Le code fiscal est obligatoire',
      SDI_REQUIRED: 'Le code SDI est obligatoire',
      ADDRESS_REQUIRED: 'L\'adresse est obligatoire',
      STREET_NUMBER_REQUIRED: 'Le numéro de rue est obligatoire',
      LOCATION_REQUIRED: 'L\'emplacement est obligatoire',
      PROVINCE_REQUIRED: 'La province est obligatoire',
      PROGRESS_CODE: 'Code de progression',
      CITY: 'Ville',
      STREET: 'Rue',
      COUNTRY: 'Pays',
    },
    PROVIDER_FORM: {
      ACTIVE: 'Activé',
      DEACTIVATED: 'Désactivé',
      VISIBLE: 'Visible',
      HIDDEN: 'Caché',
      SAVE: 'Sauvegarder',
      EDIT: 'Editer',
      ADD_PROVIDER: 'Ajouter un fournisseur',
      EDIT_PROVIDER: 'Modifier un fournisseur',
      PROVIDER_DETAILS: 'Détails du fournisseur',
      BUSINESS_NAME: 'Nom de la société',
      VAT_NUMBER: 'Numéro de TVA',
      FISCAL_CODE: 'Code fiscal',
      CODE_SDI: 'Code SDI',
      REA: 'R.E.A',
      REGISTERED_OFFICE: 'Bureau d\'inscription',
      ADDRESS: 'Adresse',
      STREET_NUMBER: 'Numéro de rue',
      NATION: 'Nation',
      POSTAL_CODE: 'Code postale',
      LOCATION: 'Emplacement',
      PROVINCE: 'Province',
      OPERATING_OFFICE: 'Bureau d\'exploitation',
      PHONE: 'Téléphone',
      MOBILE_PHONE: 'Téléphone portable',
      FAX: 'Fax',
      EMAIL: 'E-mail',
      EMAIL_PEC: 'Email Pec',
      REFERENT: 'Référent',
      WEB_SITE: 'Site web',
      BANK: 'Banque',
      BANK_BRANCH: 'Succursale bancaire',
      IBAN: 'IBAN',
      CIN: 'CIN',
      BIC_SWIFT: 'Bic/Swift',
      ABI: 'Abi',
      CAB: 'Cab',
      VAT: 'T.V.A.',
      PAYMENT: 'Paiement',
      NOTE: 'Note',
      NO_PROVIDER_FOUND: 'Aucun fournisseur trouvé',
      BUSINESS_NAME_REQUIRED: 'Le nom de l\'entreprise est obligatoire',
      VAT_REQUIRED: 'Un numéro de TVA est obligatoire',
      FISCAL_CODE_REQUIRED: 'Le code fiscal est obligatoire',
      SDI_REQUIRED: 'Le code SDI est obligatoire',
      ADDRESS_REQUIRED: 'L\'adresse est obligatoire',
      STREET_NUMBER_REQUIRED: 'Le numéro de rue est obligatoire',
      COUNTRY_REQUITED: 'Le pays est obligatoire',
      CAP_REQUIRED: 'Le CAP est obligatoire',
      LOCATION_REQUIRED: 'L\'emplacement est obligatoire',
      PROVINCE_REQUIRED: 'La province est obligatoire',
      PROGRESS_CODE: 'Code de progression',
      STREET: 'Rue',
      COUNTRY: 'Pays',
    },
    OPERATOR_FORM: {
      ACTIVE: 'Activé',
      DEACTIVATED: 'Désactivé',
      SAVE: 'Sauvegarder',
      EDIT: 'Editer',
      ADD_OPERATOR: 'Ajouter un opérateur',
      OPERATOR_DETAILS: 'Détails d opérateur',
      VAT_NUMBER: 'Numéro de TVA',
      FISCAL_CODE: 'Code fiscal',
      STATE: 'Etat',
      ROLE: 'Role',
      FIRST_NAME: 'Prénom',
      LAST_NAME: 'Nom',
      BIRTH_DATE: 'Date de naissance',
      USERNAME: 'Username',
      OLD_PASSWORD: 'Ancien Mot de Passe',
      NEW_PASSWORD: 'Nouveau Mot de Passe',
      PASSWORD: 'Mot de Passe',
      CONFIRM_PASSWORD: 'Confirmer Password',
      BAR_CODE: 'Code à bar',
      MOBILE: 'Téléphone',
      CITY: 'Ville',
      STREET: 'Rue',
      COUNTRY: 'Pays',
      ZIP: 'code ZIP',
      PROVINCE: 'Provincia',
      ADDRESS: 'Adresse',
      STREET_NUMBER: 'Numéro de rue',
      NATION: 'Nation',
      POSTAL_CODE: 'Code postale',
      LOCATION: 'Emplacement',
      PLANING_NAME: 'Nom du Planning',
      PLANING_POSITION: 'Position du Planning',
      PLANING_COLOR: 'Couleur du Planning',
      IS_REQUIRED: 'Champ obligatoire',
      MUST_MATCH: 'Les mots de passe doivent correspondre',
      PHONE: 'Téléphone',
      MOBILE_PHONE: 'Téléphone portable',
      EMAIL: 'E-mail',
      PROGRESS_CODE: 'Code de progression',
      VISIBLE: 'Visible',
      HIDDEN: 'Caché',
      SELL_POINT: 'Point de vente',
      PLANNING: 'Planning',
      IS_INVALID_FISCAL: 'Code Fiscale Invalide',
      NO_OPERATOR_FOUND: 'Aucun opérateur trouvé',
      ADD_NEW_OPERATOR: 'Ajouter un nouvel opérateur ',
      EDIT_OPERATOR: 'Editer l\'opérateur',
      IS_INVALID_MAIL: 'Email invalide',
    },
    CONFIGURATION: {
      NEW_CONTACT: 'Ajouter un nouveau contact préféré',
      NEW_CONTACT_DESCRIPTION: 'Description du contact préféré',
      NEW_PAYMENT_TYPE: 'Ajouter un nouveau type de paiement',
      NEW_PAYMENT_TYPE_DESCRIPTION: 'Description du paiement',
      NEW_PROFESSION: 'Ajouter une nouvelle profession',
      NEW_PROFESSION_DESCRIPTION: 'Description de la profession',
      NEW_IVA_CODE: 'Ajouter une nouvelle taxe',
      TVA_DESCRIPTION: 'Description de TVA',
      TVA_RATE: 'Taux de TVA',
      HOW_DID_YOU_MEET_US_: 'Comment nous avez-vous rencontré',
      CLOSE: 'Fermer',
      SAVE: 'Sauvegarder',
      ADD_NEW_ELEMENT: 'Ajouter un nouvel élément',
      ATTACH_COLOR_TO_ALL_PRODUCTS: 'Associer la couleur à tous les produits',
      EDIT_CONTACT: 'Modifier le contact favori',
      EDIT_PAYMENT: 'Modifier le type de paiement',
      EDIT_PROFESSION: 'Modifier la profession',
      EDIT_IVA_CODE: 'Modifier le tax',
      EMPTY_DESCRIPTION: 'La description ne peut pas être vide',
      EMPTY_RATE: 'La valeur du tarif TVA ne peut être vide',
      NEW_BRAND: 'Nouvelle marque',
      EDIT_BRAND: 'Modifier la marque',
      BRAND_DESCRIPTION: 'Description de la marque',
      NEW_CABIN: 'Ajouter une nouvelle cabine',
      EDIT_CABIN: 'Modifier la cabine',
      CABIN_DESCRIPTION: 'Description de la cabine',
      PLANNING_POSITION: 'Position dans le planning',
      PLANNING_COLOR: 'Couleur dans le planning',
      PLANNING_YEAR: 'Année',
      NEW_PLANNING_YEAR: 'Ajouter un nouveau planning',
      EDIT_PLANNING_YEAR: 'Modifier planning',
      DESCRIPTION: 'Description',
      NEW_LINEA: 'Nouvelle ligne',
      EDIT_LINEA: 'Modifier la ligne',
      LINEA_DESCRIPTION: 'Description de la ligne',
      CATEGORY_NAME: 'Nom de la catégorie',
      EDIT_CATEGORY: 'Modifier la catégorie',
      NEW_CATEGORY: 'Nouvelle catégorie',
      CATEGORY_DESCRIPTION: 'Description de la catégorie',
      EMPTY_NAME: 'Veuiller insérer le nom',
      EMPTY_TYPE: 'Veuiller insérer le type',
      SUB_CATEGORY_NAME: 'Nom de la sous-catégorie',
      SUB_CATEGORY_DESCRIPTION: 'Description de la sous-catégorie',
      SUB_CATEGORY_TYPE: 'Le type de la sous-catégorie',
      EDIT: 'Modifier',
      DELETE: 'Supprimer',
      SUBCATEGORIES: 'Sous-categories',
      ADD_SUBCATEGORIES: 'Ajouter une sous-categorie',
      NEW_SUBCATEGORY: 'Nouvelle sous-catégorie',
      EDIT_SUBCATEGORY: 'Modifier la sous-catégorie',
      NO: 'Non',
      APPLY_CATEGORY_COLOR_TO_ALL_PRODUCTS: 'Voulez-vous appliquer la couleur à tous les produits de la catégorie?'
    },
    DIALOG: {
      Back_to_online:'Maintenant vous êtes connecté à Internet',
      Connection_lost :'Connexion Internet non disponible. Veuillez vérifier',
      NOTE_TO_BE_SOON: 'Sera bientôt disponible',
      YOU_WANT_TO_SHOW: 'Etes-vous sûr de vouloir montrer ',
      YOU_WANT_TO_HIDE: 'Etes-vous sûr de vouloir cacher ',
      YOU_WANT_TO_HIDE_BOOKING: 'Attention si vous continuez le rendez-vous disparaît définitivement de la planification mais tout sms ' +
        'sera quand même envoyé au client. Si vous souhaitez le supprimer définitivement, poursuivez avec la fonction de suppression',
      YOU_WANT_TO_DELETE: 'Etes-vous sûr que vous voulez supprimer ',
      YOU_WANT_TO_DELETE_LINE: 'Etes-vous sûr que vous voulez supprimer cette ligne',
      UPDATE_SUCCESS: 'Mise à jour réussie',
      CANNOT_UPDATE: 'impossible de mettre à jour',
      CANNOT_UPDATE_SUBSCRIPTION: 'Impossible de modifier un abonnement déjà vendu',
      ADD_SUCCESS: 'Ajouté avec succès',
      YOU_WANT_DECAL_CABIN: 'La position indiquée est déjà occupée, dans le planning, par une autre cabine, procéder à l\'insertion?\n' +
        'ATTENTION, les autres cabines seront redimensionnées d\'une position !',
      THIS_PLANNING_YEAR_ALREADY_EXIST: 'Ce planning existe déjà',
      THIS_POSITION_IS_ALREADY_TOKEN: 'Ce poste a déjà été attribué',
      YOU_MUST_FILL_ALL_THE_PLANNING: 'Entrez tous les horaires',
      YOU_WANT_TO_SYNCHRONIZE_RT: 'Vous voulez synchroniser avec RT? Vous devrez reconfigurer la VTA et les Départements',
      NO_AVAILABLE_THRESHOLDS: 'Aucun cadeau disponible',
      NO_AVAILABLE_CARD: 'Aucune carte associée',
      SAVE_SUCCESS: 'Enregistrer avec succès',
      DELETE_SUCCESS: 'Supprimer avec succès',
      SUCCESS_EMIT_TICKET: 'Reçu imprimé avec succès',
      SUCCESS_PRINT_TEST: 'Test d\'impression Succès',
      NO_IVA_FOR_TREATMENT: 'Pas de TVA associée au service',
      NO_IVA_FOR_PRODUCT: 'Pas de TVA associée au produit',
      NO_PAYMENT_RT_CONFIG: 'Aucune configuration de paiement',
      NO_PAYMENT_RT_CONFIG_FOR: 'Aucune configuration de paiement',
      NO_TREATMENT_RT_IVA_CONFIG: 'Pas de configuration TVA RT',
      NO_PRODUCT_IVA_RT_CONFIG: 'Pas de configuration TVA RT',
      NO_RT_CONFIG: 'Pas de configuration RT',
      YOU_WANT_TO_CLOSE_CASH: 'Vous souhaitez fermer la caisse',
      YOU_WANT_TO_READ_CASH: 'Vous voulez lire le jour',
      CLOSING_SUCCESS: 'Succès de Clôture',
      CLOSING_FAILURE: 'Manqué de Clôture',
      READING_SUCCESS: 'Lecture Avec Succée',
      READING_FAILURE: 'Lecture Avec Manqué',
      CANNOT_DELETE: 'Attention : il n\'est pas possible de supprimer l\'enregistrement sélectionné car il est déjà utilisé.',
      FAILED_EMIT_TICKET: 'Impossible d\'imprimer le reçu',
      CLOSE_CASH_REGISTER: 'Attention, fermez la caisse enregistreuse',
      PAYMENT_FAILED: 'Èchec de l\'impression',
      PAYMENT_FAILED_PRINT_STATUS: 'Attention, vérifiez l\'état de l\'imprimante',
      RECEIPT_NOT_SENT: 'reçu non envoyé',
      DELETE_SUBSCRIPTION: 'Voulez-vous supprimer l\'abonnement',
      YOU_MUST_ADD_PRODUCT: 'Ajouter un produit',
      INSERT_ALL_MANDATORY_INFORMATION: 'Veuillez saisir toutes les données requises',
      FAILED_TO_CONNECT_TO_PRINT: 'Impossible de se connecter à l\'imprémante',
      INTERNAL_SERVER_ERROR: 'Erreur Interne du Serveur! Réessayez plus tard',
      NO_INFORMATION_FOUND: 'Aucune information trouvée',
      ATTENTION_WEEK_PLANNING_CHANGED: 'Attention, vous êtes sur le point de changer le planning de la semaine. Pour que les changements '
        + 'soient effectifs sur les calendriers des opérateurs et des cabines, vous devez réaffecter la planification de la semaine',
      YOU_WANT_TO_ENABLE_SALES_WITHOUT_PRINT: 'Voulez-vous activer la vente sans compteur de taxe?',
      YOU_WANT_TO_DISABLE_SALES_WITHOUT_PRINT: 'Voulez-vous désactiver le mode de vente sans compteur de taxe ?',
      PRICE_CANNOT_BE_ZERO: 'Attention, il n\'est pas possible de saisir un prix inférieur ou égal à zéro',
      YOU_WANT_TO_CLEAR_TEMPORARY_DATA: 'Voulez-vous effacer toutes les données temporaires?',
      YOU_WANT_TO_REVOKE: 'Retour en mode opérateur',
      SUCCESS_EMIT_QUOTE: 'Imprimer le devis avec succès',
      FAILED_EMIT_QUOTE: 'Le devis ne peut pas être imprimé',
      NO_EAN_FOR_THIS_OPERATOR: 'L\'opérateur n\'a pas de code ean, assurez-vous de l\'avoir généré',
      NON_EXISTENT_PRODUCT: 'Article inexistant',
      NON_CONFIGURED_PRODUCT: 'Élément non configuré',
      INVALID_PASSWORD: 'Mot de passe incorrect',
      NOT_ENOUGH_CREDIT_RELOAD: 'Attention, le crédit sur la carte de fidélité sélectionnée n\'est pas suffisant pour le montant total, ' +
        'donc le montant disponible sera déduit et non le total à payer',
      RANGE_FORM_INVALID: 'Impossible d\'enregistrer car une ou plusieurs valeurs saisies ne sont pas cohérentes',
      LICENSE_CONFIG: 'Configuration de la licence',
      SELECT_ARTICLE_TO_GENERATE_DRAFT_MOVEMENT: 'Sélectionnez les coches des articles pour lesquels vous souhaitez générer un ' +
        'mouvement de type brouillon',
      SUCCESS_CREAT_DRAFT_MOVEMENT: 'Réussir la création du mouvement de brouillon',
      YOU_WANT_TO_RESET: 'Attention, voulez-vous procéder à la suppression définitive des données?',
      ARE_YOU_SURE_TO_ANNUL_TICKET: 'Souhaitez-vous annuler le paiement?',
      CANNOT_FIND_PAYMENT: 'Paiement non trouvé',
      ARE_YOU_SURE_TO_RETURN_ENTRIES: 'Voulez-vous retourner le paiement?',
      YOU_WANT_TO_ENABLE: 'Êtes-vous sûr de vouloir l\'activer',
      YOU_WANT_TO_DISABLE: 'Êtes-vous sûr de vouloir désactiver',
      YOU_MUST_SELECT_CLIENTS: 'vous devez sélectionner des destinataires',
      YOU_MUST_SELECT_METHOD_PROMO: 'vous devez sélectionner au moins une méthode d`envoi de promotion (SMS, EMAIL, Facebook, Instagram)',
      INFO_TEMPLATE_PROMO : "Ce champ est utilisé dans le cas d'un envoi d'email (le texte et l'image du modèle sont pris en compte) et dans le cas d'une publication sur Facebook et Instagram (seule l'image est prise en compte)",
      INFO_SUBJECT : "Ce champ est utilisé lors de l'envoi d'un email et représente le sujet de l'email",
      INFO_MESSAGE: "Ce champ est utilisé lors de l'envoi d'un SMS. Il représente le texte du message. Dans le cas de Facebook et Instagram, il représente la partie textuelle de la publication.",
      INFO_DATE_HOUR: "pour tout type de publication il est possible de configurer la date et l'heure d'envoi",
      INFO_DESTINATION: "Ce champ permet de préciser un ou plusieurs destinataires d'un email ou d'un SMS",
      INFO_SMS: "Pour créer une promotion SMS, allez dans Marketing, sélectionnez l'élément Promo SMS & WhatsApp et cliquez sur Campagne SMS & WhatsApp",
      YOU_WANT_TO_REACTIVE: 'Voulez-vous vraiment réactiver',
      YOU_WANT_TO_ANNUL: 'Êtes-vous sûr de vouloir annuler',
      YOU_MUST_SELECT_ELEMENTS: 'Doit sélectionner l\'élément',
      ELEMENT_MUST_HAVE_THE_SAME_IVA: 'Attention, vous ne pouvez effectuer un dépôt qu\'avec des produits ou services ' +
        'avec le même code TVA',
      SELECT_ALL_ELEMENT_WITH_IVA: 'Sélectionner tous les articles avec TVA ',
      SUCCESS_SEND_RESET_PASSWORD: 'E-mail de réinitialisation du mot de passe envoyé avec succès',
      FAILED_SEND_RESET_PASSWORD: 'Impossible d\'envoyer le mot de passe de repos de l\'e-mail',
      INVALID_MAIL: 'L\'e-mail n\'est pas valide',
      CONFIRM_CLIENT: 'Êtes-vous sûr de vouloir confirmer le client',
      SUCCESS_CONFIRM: 'Confirmation client réussie',
      FAILED_CONFIRM: 'Impossible de confirmer le client',
      ATTENTION_REQUIRED_EXCEL_FIELDS: 'Attention, assurez-vous d\'avoir créé les tableaux secondaires comme ' +
        'les catégories, les marques, la TVA, etc.',
      CANNOT_IMPORT_PRODUCTS_CHECK_YOUR_FILE: 'Erreur lors du chargement des produits! Veuillez vérifier votre fichier ',
      SUCCESS_CREAT_PRODUCTS: 'Ajout de produits avec succès',
      SUCCESS_CREAT_CLIENTS: 'Ajouter des clients avec succès',
      INVALID_LOTTERY: 'code lotterie non valide',
      CORRECT_LOTTERY: 'code de loterie entré correctement',
      NO_PRODUCT_INSERTED: 'Aucun article inséré dans le document',
      ATTENTION_REQUIRED_TREATMENTS_EXCEL_FIELDS: 'Attention, assurez-vous d\'avoir créé les tables secondaires' +
        'comme les catégories, la ligne, la TVA, etc.',
      SUCCESS_CREAT_TREATMENTS: 'Ajoutez-nous le service avec succès',
      ATTENTION: 'Attention',
      PLANNING_COMPLETE: 'Voulez-vous clôturer le rendez-vous?',
      INVALID_LOTTERY_EMPTY: 'Le code de loterie ne peut pas être vide',
      INVALID_LOTTERY_NOT_EIGHT_CHARS: 'Le code de loterie doit comporter 8 caractères',
      INVALID_LOTTERY_INVALID_CHARS: 'Le code de loterie n\'autorise pas les caractères spéciaux\n',
      YOU_WANT_TO_DISPLAY_TUTTI_PDT: 'Voulez-vous charger le stock actuel de tous les produits ?',
      INVALID_FILE_TYPE: 'Type de fichier non valide',
      MAILING_ERROR: 'Le serveur d\'envoi d\'e-mails ne fonctionne pas. Contactez l\'administrateur',
      ATTENTION_RT_DEMO_MODE : 'Attention ! L\'imprimante RT est en mode Démo',
      ERROR_IN_PRINT_CONTACT_ASSISTANCE : 'Attention, l\'imprimante n\'a pas pu être contactée correctement, veuillez la redémarrer. Si le problème persiste, contactez le support'
    },
    ADMIN: {
      CENTER: {
        LIST: 'Liste des centres',
        ADD: 'Ajouter',
        ADD_NEW: 'Ajouter un nouveau centre',
        SAVE: 'Enregistrer',
        EDIT: 'Modifier',
        CLOSE: 'Fermer',
        DELETE: 'Supprimer',
        INFO: 'Info',
        COMPANY_NAME: 'Nom de la société',
        VAT_NUMBER: 'Numéro de TVA',
        FISCAL_CODE: 'Code Fiscal',
        CODE_SDI: 'SDI Code',
        REA: 'R. E. A',
        ADDRESS: 'Adresse',
        STREET_NUMBER: 'Rue',
        COUNTRY: 'Pays',
        ZIP_CODE: 'Code POSTAL',
        CITY: 'Ville',
        PROVINCE: 'Province',
        CONTACT: 'Contact',
        PHONE: 'Téléphone',
        MOBILE_PHONE: 'Téléphone Mobile',
        FAX: 'Fax',
        EMAIL: 'E-mail',
        REFERENT: 'Référent',
        WEB_SITE: 'Site Web',
        BANK: 'Banque',
        BANK_BRANCH: 'Succursale de la banque',
        IBAN: 'IBAN',
        CIN: 'CIN',
        BIC_SWIFT: 'Bic/Swift',
        ABI: 'Abi',
        CAB: 'Cabine',
        NOTE: 'Note',
        NO_CENTER_FOUND: 'Pas de centre trouvé',
        Go_TO: 'Aller à',
        COMPANY_NAME_REQUIRED: 'Nom de l\'entreprise est nécessaire',
        VAT_REQUIRED: 'Le numéro de TVA est nécessaire',
        VAT_INVALID: 'Le numéro de TVA n\'est pas valide',
        FISCAL_CODE_REQUIRED: 'Le code Fiscal est nécessaire',
        SDI_REQUIRED: 'Code SDI est nécessaire',
        ADDRESS_REQUIRED: 'Adresse',
        STREET_NUMBER_REQUIRED: 'Le numéro de la rue est requis',
        COUNTRY_REQUIRED: 'Les pays doivent',
        CAP_REQUIRED: 'Le code POSTAL est requis',
        LOCATION_REQUIRED: 'L\'emplacement est nécessaire',
        PROVINCE_REQUIRED: 'La Province est nécessaire',
        PROGRESS_CODE: 'Code d\'avancement',
        TYPE_OF_ACTIVITY: 'Type d\'activité',
        VAT_NUMBER_USED: 'Attention, le numéro de TVA est déjà utilisé.'
      },
      SELL_POINT: {
        LIST: 'Liste des points de vente',
        ADD: 'Ajouter',
        NO_SELL_POINT: 'Aucun point de vente trouvé, cliquez sur [ajouter] pour ajouter un nouveau point de vente à ',
        OPENING_HOUR: 'Heure d\'ouverture',
        FINAL_HOUR: 'Dernière Heure',
        REFERENT: 'Référent',
        MOBILE: 'Mobile',
        ADD_NEW: 'Ajouter un nouveau point de vente à',
        EDIT: 'Modifier',
        INFO: 'Info',
        NAME: 'Nom',
        PLANNING_TYPE: 'Type de planification',
        ADDRESS: 'Adresse',
        STREET: 'Rue',
        COUNTRY: 'Pays',
        ZIP_CODE: 'Code postal',
        PROVINCE: 'Province',
        CITY: 'Ville',
        PHONE: 'Téléphone',
        EMAIL: 'E-mail',
        DESCRIPTION: 'Description',
        NOTE: 'Note',
        SAVE: 'Enregistrer',
        CLOSE: 'Fermer',
        NO_CABIN_FOUND: 'Acune cabine trouvée',
        CABIN: 'Cabine',
        CANNOT_DELETE: 'Le point de vente ne peut pas être supprimé',
        ACTIVE_ON_MOBILE: 'Actif sur l\'application',
        SIEGE_ALREADY_EXIST: 'Attention, il existe un point de vente <b> {{name}} </b> désigné comme siège social, souhaitez-vous continuer ?',
      },
      ROLE: {
        ROLES: 'Rôles',
        AUTHORITIES: 'Autorités',
        NAME: 'Nom',
        DESCRIPTION: 'Description',
        ADD_ROLE: 'Ajouter un nouveau role',
        EDIT_ROLE: 'Modifier role',
        NAME_REQUIRED: 'Le nom du rôle est obligatoire',
        DESC_REQUIRED: 'Une description du rôle est requise',
        DELETE_ROLE: 'Supprimer le rôle: {{roleName}}',
        DELETE_ROLE_MSG: 'Vous allez supprimer le role {{roleName}}. Vous êtes sûr?',
        DELETE_ERROR: 'Le rôle ({{role.name}}) est lié à un ou plusieurs utilisateurs!',
        ROLE_UPDATED: 'Rôle mis à jour avec succès',
        NO_ROLE_FOUND: 'Aucun rôle trouvé, cliquez sur ajouter pour ajouter un nouveau rôle',
        SELECT_ROLE: 'Sélectionnez un rôle pour éditer ses autorités',
        CHANGES_NOT_SAVED: 'Attention, vous n\'avez pas gardé vos changements, voulez-vous quitter?',
      },
      GROUP: {
        ATTENTION: 'Attention',
        DELETE_TITLE: 'Supprimer {{name}}',
        GROUP: 'groupe',
        GROUPS: 'Groupes',
        YOU_ARE_ABOUT_TO_DELETE: 'Vous allez supprimer <b>{{name}}</b>. Êtes-vous sûr ?',
        YOU_ARE_ABOUT_TO_RESTORE: 'Vous allez restaurer <b>{{name}}</b>. Êtes-vous sûr ?',
        CANNOT_DELETE_GROUP: 'Le groupe ne peut pas être supprimé car il n\'est pas vide',
        CANNOT_DELETE_SUPER_ADMIN: 'Le super-admin ne peut pas être supprimé directement',
        ADD_GROUP: 'Nouveau client',
        EDIT_GROUP: 'Modifier client',
        GROUP_INFO: 'Infos client',
        SUPER_ADMIN_INFO: 'Infos super admin',
        USERNAME_EXISTS_ERROR: 'Le nom d\'utilisateur est pris',
        ADMINISTRATOR_PANEL: 'Panneau d\'administration de l\'entreprise',
        DATE_VALIDITY_TITLE: 'Installation du groupe de validité de licence',
        LICENSE_DURATION: 'De la période de licence',
        DAYS: 'jours',
        CUSTOM: 'personnalisé',
        NUMBER_OF_DAYS: 'Nombre de jours',
        MONTH: 'mois',
        TRIMESTER: 'trimestre',
        MONTHS: 'mois',
        YEAR: 'année',
        STARTS_AT: 'Commence à:',
        START_DATE: 'Date de début de',
        DAYS_REMAINS: 'jours reste',
        EXPIRES_AT: 'expire à',
        EXPIRATION_DATE: 'date d\'expiration',
        NO_GROUP_FOUND: 'Aucun groupe trouvé.',
        SEARCH: 'Chercher',
        VALIDITY: 'Validité',
        ALL: 'Tous',
        VALID: 'Valide',
        EXPIRED: 'Expiré',
        STATE: 'État',
        ACTIVE: 'Actif',
        INACTIVE: 'Inactif',
        UPDATED_SUCCESS: 'Mise à jour réussie',
        QUOTE_DURATION: 'durée de devis',
        VERIFICATION: 'Vérification',
        VERIFIED: 'Vérifié',
        NOT_VERIFIED: 'Non vérifié',
        LICENSE: 'Licence',
        FROM: 'De',
        TO: 'Jusqu\'à',
        RANGE_DURATION: 'Intervalle d\'évaluation',
        SEMESTER: 'Semestre',
        RANGE_CONFIG: 'Classification client',
        FIRST_LEVEL: 'L1',
        SECOND_LEVEL: 'L2',
        THIRD_LEVEL: 'L3',
        FORTH_LEVEL: 'L4',
        VALUE_MUST_BE_GRATER_THAN: 'La valeur entrée doit être supérieure à',
        INVALID_VALUE: 'Valeur non valide',
        GENERAL: 'Generic',
        RANGE: 'Plage de notation',
        GENERAL_CONFIG: 'Configuration générale',
        NAME: 'Nom',
        LEVEL: 'Niveau',
        EXPORT_AS_CSV: 'Exporter sous forme de csv',
        UTILITY: 'Utilité',
        UTILITY_CONFIG: 'Configuration de l\'utilitaire',
        EXPORT_CLIENT: 'Clients',
        RESET_FIDELITY: 'Réinitialiser les points de fidélité',
        HARD_RESET_FIDELITY: 'Réinitialiser le point de fidélité et la suppression historique',
        TRASH: 'Corbeille',
        SMS_CONFIG: 'Configuration Sms',
        PROMO_CONFIG: 'Configuration des promotions',
        PROMO: 'Promotions marketing',
        DOWNLOAD_PRODUCT_EXCEL_MODEL: 'Télécharger le modèle Excel du produit',
        UPLOAD_PRODUCTS: 'Télécharger des produits',
        UPLOADED_PRODUCTS: 'Produits téléchargés',
        DOWNLOAD_CLIENT_EXCEL_MODEL: 'Télécharger le modèle Excel du client',
        UPLOAD_CLIENTS: 'Télécharger des clients',
        UPLOADED_CLIENTS: 'Clients téléchargés',
        PLANNING_NAME_EXISTS: 'Le nom de l\'opérateur est déjà utilisé, continuez quand même ?',
        UPLOAD_TREATMENTS: 'Télécharger des services',
        UPLOADED_TREATMENTS: 'Services chargés',
        DESCRIPTION: 'Description',
        GROUP_NAME: 'Nom du groupe',
        MODEL: 'Modèle',
      },
      TYPES_ACTIVITIES: {
        ADD_NEW: 'Ajouter une nouvelle activité',
        NO_ACTIVITY_FOUND: 'Aucune activité trouvée',
        DELETE_MSG: 'Vous allez supprimer'
      },
      LICENSE_CONFIG: {
        DELETE_MSG: 'Vous allez supprimer {{name}}!',
        ERROR_DELETE: 'Licence déjà utilisée!'
      }
    },
    PRODUCT_FORM: {
      PROD_CODE: 'Numéro d\'article\n',
      CODE: 'Code',
      ID: 'ID',
      NO_PRODUCT_FOUND: 'Aucun produit trouvé',
      ADD_PRODUCT: 'Ajouter un produit',
      PRODUCT_DETAILS: 'Détails du produit',
      SAVE: 'Sauvegarder',
      EDIT: 'Modifier',
      PROGRESS_CODE: 'Code',
      CREATION_DATE: 'Date d\'insértion',
      BAR_CODE: 'Code à barre',
      DESCRIPTION: 'Description',
      DESCRIPTION_REQUIRED: 'Description requise',
      COMMERCIAL_DESCRIPTION: 'Description  commerciale',
      CATEGORY: 'Categorie',
      CATEGORY_REQUIRED: 'Categorie requise',
      USE_TYPE_REQUIRED: 'Type d\'utilisation requis',
      BARCODE_REQUIRED: 'Code à barre requis',
      SUBCATEGORY: 'Sous catégorie',
      BRAND: 'Marque',
      LINEA: 'Ligne',
      PROVIDER: 'Fournisseur',
      USE_TYPE: 'Type d\'utilisation',
      MEASURE_UNIT: 'Unité de mesure',
      TAX: 'Tax',
      MIN_STOCK: 'Min. stock',
      PURCHASE_COST: 'Coût d\'achat',
      RECHARGE: 'Recharge',
      PRICE: 'Prix',
      PRICE_PDV: 'Prix PDV',
      NOTE: 'Notes de produit',
      CANCEL: 'Eliminer',
      MEDUIM_COST: 'Coût moyen',
      LAST_LOADING_COST: 'Dernier coût de chargement',
      LAST_SALE_PRICE: 'Dernier prix de vente',
      STOCK: 'Stock',
      UNIT: 'Unité',
      INTERNAL_USE: 'Usage interne',
      IN_SALE: 'En vente',
      INTERNAL_USE_OR_IN_SALE: 'Usage interne ou en vente',
      BARCODE_INCORRECT: 'Le code à barre doit être correcte',
      ACTIVATE_DETAILS_SALE: 'Activer le produit',
      WEIGHT_REQUIRED: 'Poids requis',
      UNIT_REQUIRED: 'Unité requise',
      ML: 'Ml',
      GR: 'Gr',
      QUANTITY: 'Poids',
      DESCRIPTION_PLU: 'Description PLU',
      NEW_STOCK: 'Nouveau stock',
      OLD_STOCK: 'dernier stock',
      ACTIVE: 'Actif',
      LAST_PRICE: 'Dernier prix de vente',
      LAST_COST: 'Dernier coût de chargement',
      MEDIUM_COST: 'Coût moyen',
      PRINT_TICKET: 'Imprimer les étiquettes',
      PRINT_QUANTITY: 'Quantité à imprimer',
      VALID_BARCODE: 'Code à barre valide',
      INVALID_BARCODE: 'Code à barre non valide',
      UNIQUE_BARCODE: 'Code à barre unique',
      NON_UNIQUE_BARCODE: 'Code à barre non unique',
      PLU_REQUIRED: 'Code requis',
      VALID_PLU: 'Code valide',
      INVALID_PLU: 'Code non valide',
      EXISTING_PLU: 'Code déjà associé à un autre produit',
      UNIQUE_PLU: 'Code unique',
      NON_UNIQUE_PLU: 'Code non unique',
      BARCODE_EMPTY: 'Codes-barres vides',
      ONE_PER_PAGE: 'Forme continue 40 x 27 Zèbre thermique',
      CONTINUOUS_63_37: 'Forme continue 63 x 37 Termico Zebra',
      CONTINUOUS_40_30: 'Forme continue 40 * 30 Termico Zebra',
      A4_62_32: 'Forme A4 Étiquette 62 * 32',
      LIST: 'Forme A4 Étiquette Buffetti 47,5 x 35',
      ETIQUETTE_TYPE: 'Type d\'étiquette',
      PRINT_FACE_PLATES: 'Imprimer Étiquettes d\'étagère',
      FACE_PLATES: 'Étiquettes d\'étagère',
      STOCK_ALERT: 'Produits d\'alerte de stock',
      SHOW_STOCK_ALERT_LIST: 'Afficher les articles en alerte de stock',
      MIN_STOCK_INFO: 'Si le champ est laissé vide, il ne sera pas pris en compte par le logiciel pour le contrôle du sous-stock',
      INCLUDE_ACTIVATE_DETAILS_SALE: 'Afficher les produits désactivés',
      PRODUCT_DISABLED: 'Pr. désactivés',
      ERROR_MAX_DISCOUNT: 'La remise ne peut pas être supérieure au montant du produit'

    },
    TREATMENT_FORM: {
      ID: 'ID',
      TREATMENT_DETAILS: 'Détail du traitement',
      ADD_TREATMENT: 'Ajouter un traitement',
      CANCEL: 'Annuler',
      EDIT: 'Modifier',
      SAVE: 'Sauvegarder',
      CREATION_DATE: 'Date de création',
      PROGRESS_CODE: 'Code',
      DESCRIPTION: 'Description',
      DESCRIPTION_REQUIRED: 'Description obligatoire',
      CATEGORY: 'Categorie',
      CATEGORY_REQUIRED: 'Categorie obligatoire',
      SUBCATEGORY: 'Sous-catégorie',
      LINEA: 'Ligne',
      TAX: 'Tax',
      CABINE: 'Cabine',
      TEMPO_CABINE: 'Temps cabine',
      TEMPO_OPERATORE: 'Temps opérateur',
      PAUSE_TIME: 'Temps de pause',
      PREZZO_MASTER: 'Prix',
      PREZZO_PDV: 'Prix par PDV',
      ENABLE_PRICE: 'Activer le prix',
      NOTE: 'Note produit',
      RELATED_PRODUCTS: 'Produits relatifs',
      ADD_PRODUCT: 'Ajouter un produit',
      NO_PRODUCT_FOUND: 'Aucun produit trouvé',
      PRODUCT_REQUIRED: 'Produit requis',
      QUANTITY_REQUIRED: 'Vous devez choisir une quantité autre que zéro',
      PRODUCT_COST_IMPACT: 'Incidence des coûts des produits',
      COST_BEAUTY_CENTER: 'Coût du centre',
      PROFIT_MARGIN: 'Marge bénéficiaire',
      AVERAGE_OPERATOR_HOURLY_COST: 'Coût horaire moyen de l\'opérateur',
      TREATMENT_COST: 'Coût des traitements',
      SUBCATEGORY_REQUIRED: 'Sous-catégorie obligatoire',
      DISABLE_CABINE: 'Pas de cabine',
      DISABLE_OPERATOR: 'Aucun opérateur',
      ARTICLE: 'Produit',
      ADD_RELATED_PRODUCTS: 'Ajouter un produit relatif',
      EDIT_RELATED_PRODUCTS: 'Modifier un produit relatif',
      MEASURE_UNIT: 'Unité de mesure',
      PRICE_QUANTITY: 'Coût de la pièce',
      QUANTITY: 'Quantité de pièce',
      PRICE: 'Totale',
      QUANTITY_UNIT: 'Nombre de pièces',
      NO_TREATMENT_FOUND: 'Aucun traitement trouvé',
      UNIT: 'Unité',
      YOU_WANT_TO_HIDE_RELATED_PRODUCT: 'Êtes-vous sûr de vouloir supprimer ce produit',
      NO_UNIT_SELECTED: 'Aucune unité de mesure sélectionnée',
      DESCRIPTION_PLU: 'Description PLU',
      NOTE_PRODUCT: 'Protocole de traitement des services',
    },
    PLANNING_FORM: {
      NO_PLANNING_YET: 'Pas de planning',
      PLANNING_LIST: 'Liste des plannings',
      EDIT_PLANNING: 'Modifier le planning',
      NEW_PLANNING: 'Créer un planning',
      ADD: 'Ajouter',
      MONDAY: 'Lundi',
      TUESDAY: 'Mardi',
      WEDNESDAY: 'Mercredi',
      THURSDAY: 'Jeudi',
      FRIDAY: 'Vendredi',
      SATURDAY: 'Samedi',
      SUNDAY: 'Dimanche',
      ABBREVIATION_MONDAY: 'L',
      ABBREVIATION_TUESDAY: 'M',
      ABBREVIATION_WEDNESDAY: 'M',
      ABBREVIATION_THURSDAY: 'J',
      ABBREVIATION_FRIDAY: 'V',
      ABBREVIATION_SATURDAY: 'S',
      ABBREVIATION_SUNDAY: 'D',
      JANUARY: 'Janvier',
      FEBRUARY: 'Février',
      MARCH: 'Mars',
      APRIL: 'Avril',
      MAY: 'Mai',
      JUNE: 'Juin',
      JULY: 'Juillet',
      AUGUST: 'Août',
      SEPTEMBER: 'Septembre',
      OCTOBER: 'Octobre',
      NOVEMBER: 'Novembre',
      DECEMBER: 'Décembre',
      JUST_THIS_WEEK: 'Seulement cette semaine',
      THIS_MONTH: 'Mois en cours',
      FIRST_QUARTER: 'Premier trimestre',
      SECOND_QUARTER: 'Deuxième trimestre',
      THIRD_QUARTER: 'Troisième trimestre',
      FORTH_QUARTER: 'Quatrième trimestre',
      ALL_THE_YEAR: 'Toute l\'année',
      APPLICATION_MODE: 'Vous souhaitez attribuer ce modèle à:',
      // tslint:disable-next-line:max-line-length
      ATTENTION_NON_AVAILABLE_OPERATOR: 'Attention vous êtes sur le point d\'entrer un rendez-vous dans un créneau horaire de non disponibilité de l\'opérateur',
      // tslint:disable-next-line:max-line-length
      ATTENTION_NON_AVAILABLE_CABIN: 'Attention vous êtes sur le point d\'inscrire un rendez-vous dans un créneau horaire de non disponibilité de la cabine',
      ATTENTION_NON_BEFORE_OPEN: 'Attention, vous êtes sur le point de prendre rendez-vous avant l\'ouverture du point de vente',
      ATTENTION_NON_AFTER_CLOSE: 'Attention vous êtes sur le point d\'entrer en rendez-vous après l\'heure de fermeture du point de vente',
      ATTENTION_DAY_OFF_OPERATOR: 'Attention vous êtes sur le point d\'entrer et rendez-vous pendant la journée de repos de l\'opérateur',
      ATTENTION_DAY_OFF_CABIN: 'Attention vous êtes sur le point d\'entrer et rendez-vous pendant la journée hors de la cabine',
      OPERATOR_OCCUPIED: 'L\'opérateur <b> {{label}} </b> est déjà occupé',
      CABIN_OCCUPIED: 'La cabine <b> {{label}} </b> est déjà occupée',
      WARNING: 'Avertissement',
      WANT_TO_CONTINUE: 'Souhaitez-vous continuer?',
    },
    FIDELITY_FORM: {
      QUANTITY: 'Quantité',
      PRICE: 'Prix',
      NOM: 'Nom',
      CREATION_DATE: 'Date de création',
      DISCOUNT: 'Remise',
      TOTAL_DISCOUNT: 'Le total discount',
      CARD: 'Carte',
      POINT_WITH_GIFT: 'Points avec cadeau',
      ADD_RECHARGE: 'Ajouter recharge',
      RECHARGES: 'Recharges',
      CREATE_RECHARGE: 'Créer recharge',
      VALUE: 'Valeur',
      PREPAID_WITH_DISCOUNT: 'Prépayée avec remises',
      EURO_SPENT: 'Chaque euro dépensé',
      LOAD_POINT: 'Points de chargement',
      THRESHOLDS: 'Seuils',
      ADD_THRESHOLD: 'Ajouter un seuil',
      TREATMENT: 'Traitement',
      PRODUCT: 'Produit',
      CREATE_THRESHOLD: 'Créer un seuil',
      THRESHOLD: 'Seuil',
      POINT: 'Points',
      APPLY_ALTER_THRESHOLD: 'Appliquer un autre seuil',
      NO_RECHARGE_FOUND: 'Aucune recharge trouvée',
      SELECT_FIDELITY_TYPE: 'Sélectionner le type de fidélité',
      GIFT_CARD: 'Carte cadeau',
      NAME: 'Nom',
      SOLD: 'Solde',
      NUMBER: 'Numéro',
      TYPE: 'Type',
      HISTORY: 'Histoire',
      NO_HISTORY_FOUND: 'Aucun mouvement trouvé',
      ASSOCIATION_DATE: 'Date d\'émission',
      ASSOCIATION: 'ASSOCIATION',
      GAIN: 'Gains',
      CONSUMPTION: 'Consommation',
      MANUAL_ADD: 'Ajout manuel',
      MANUAL_SUBTRACT: 'Soustraire le manuel',
      PRODUCT_SELL: 'Produit',
      TREATMENT_SELL: 'Traitement',
      ABONMENT_SELL: 'abonnement',
      RELOAD: 'Recharger',
      SELECT_CARD: 'Sélectionnez une carte',
      IGNORE_PRINCIPAL_DISCOUNT: 'Attention la remise principale saisie sera ignorée, voulez-vous continuer?',
      RELOAD_VALUE: 'Recharge',
      DUE_DATE: 'Date d\'échéance',
      POINT_CART_NAME: 'Description de la carte',
      PREPAID_CART_NAME: 'Description de la carte',
      THRESHOLD_CART_NAME: 'Description du seuil',

    },
    PAYMENT: {
      ANONYMOUS: 'Client anonyme',
      TOTAL: 'Total',
      FEE_PAID: 'Frais payés',
      FEE_NOT_PAID: 'Des frais non payés',
      CHECKOUT: 'La caisse',
      DESCRIPTION: 'Description',
      OPERATOR: 'Opérateur',
      CABIN: 'Cabine',
      SUBSCRIPTIONS: 'Les abonnements',
      QUANTITY: 'Quantité',
      PRICE: 'Prix',
      DISCOUNT: 'Discount',
      FINAL_PRICE: 'Prix Final',
      ASSOCIATED_COMPANY: 'Associés de la société',
      GENERAL_CARD: 'Générale de la carte',
      CARD: 'Carte',
      AMOUNT: 'Montant',
      AMOUNT_TO_PAY: 'Reste à payer',
      CHARGE: 'Charge',
      BALANCE: 'Solde',
      POINTS: 'Points',
      POINTS_ACCRUED: 'Les Points accumulés',
      HOMAGE: 'Hommage',
      PREVENTIVE: 'De prévention',
      ALTERNATIVE_SELLING: 'fidélité/abonnement',
      SHOPPING_CART_EMPTY: 'Panier d\'achat vide',
      START_ADDING_ITEMS: 'Commencez par ajouter un élément de la liste',
      GIFTS: 'Cadeaux disponibles',
      CREDIT_CARD: 'Carte bancaire',
      PRODUCT: 'Produit',
      TREATMENT: 'Traitement',
      POINT: 'Points',
      PRICE_GIFT: 'Prix',
      ASSOCIATE_CARD: 'Carte d\'associé',
      CASH: 'Cash',
      CHEQUE: 'Chèque',
      BANK_CARD: 'Carte bancaire',
      PAYMENT: 'Paiement',
      REST: 'Reste',
      POINT_CARD: 'Carte à point',
      GIFT_CARD: 'Carte cadeaux',
      RECHARGE_CARD: 'Carte à recharge',
      PAYMENT_MODE_ERROR: 'Le montant saisi est supérieur au total.',
      DO_YOU_WANT_TO_USE_YOUR_FREE_OBJECTS: 'Voulez vous utiliser vos cadeaux?',
      NO_PAYMENT_FOUND: 'Aucun paiement trouvé',
      NO_QUOTE_FOUND: 'Aucun devis trouvé',
      STATUS: 'Status',
      DONE: 'Réussie',
      FAILED: 'Échoué',
      PENDING: 'En attente',
      STATUS_HISTORY: 'État de Historique des états du paiement',
      OPERATOR_DETAILS: 'Détails de l\'opérateur',
      USERNAME: 'Username',
      NAME: 'Nom',
      MOBILE: 'Mobile',
      EMAIL: 'Email',
      ROLE: 'Role',
      PAYMENT_DETAILS: 'Détails de paiement',
      SEARCH: 'chercher',
      WAIT_FOR_RECEIPT: 'En attente du Doc. N.',
      EAN_NON_VALID: 'Code EAN non valide',
      RESET: 'Reset',
      RECEIPT: 'Receipt',
      TICKET: 'Ticket',
      TRANSFER: 'Virement bancaire',
      PAY: 'Payer',
      RECEIPT_NOT_SENT: 'Remise. non délivré ',
      PRINT_FAILED: 'Réduction d\'impression. échoué',
      FAILED_RESET: 'Remise. émis sans réinitialisation ',
      FAILED_TICKET: 'Remise. délivré sans progressif ',
      PAY_WITHOUT_PRINT: 'Payer sans impression de ticket',
      SIMULATION: 'Compte fermé',
      ANONYMOUS_OPERATOR: 'Anonyme',
      PAYMENT_MODE: 'Méthode de paiement',
      QUOTE: 'Quote',
      SCAN: 'Scan',
      EAN_SCAN: 'Scanner barcode',
      EAN_NUMBER: 'barcode',
      GIFT: 'Cadeau',
      RELOAD_CARD: 'Carte à recharge',
      SUBSCRIPTION: 'Abonnement',
      FISCAL: 'Fiscal',
      SATISPAY: 'Satispay',
      NO_SATISPAY_CONFIG: 'Compte Satispay non configuré',
      WAITING_FOR_PAYMENT: 'En attente de paiement',
      ERROR_PRINT_QRCODE: 'Erreur lors de l\'impression du code QR',
      ERROR_CODE_SCANNED: 'Scanner le code QR imprimé avec l\'application satispay pour payer',
      ANNUL_TICKET: 'Annuler le ticket',
      SEARCH_PAYMENT: 'Rechercher un paiement',
      SHOW_RESO_LIST: 'Afficher les retours et les annulations',
      PAYMENT_ALREADY_ANNULLED: 'Reçu déjà annulé',
      RT_REFERENCE: 'Numéro de série RT',
      PAYMENT_REFERENCE: 'Référence de paiement',
      PAYMENT_ALREADY_HAS_RESO: 'Le reçu est déjà retourné',
      RETURNED_QUANTITY: 'Quantité de retour',
      AVAILABLE_QUANTITY: 'Qté restante',
      QUANTITY_TO_RENDER: 'Qté à retourner',
      QUANTITY_TOTAL: 'Quantité totale',
      GROUPON: 'Groupon',
      RESO: 'Retour',
      TYPE: 'Type',
      ADVANCE: 'Avance',
      ADVANCE_COMPLETED: 'Avance terminée',
      PAYMENTS: 'Paiements',
      CREATE_ADVANCE: 'Créer une avance',
      CONTINUE_PAYMENT: 'Continuer le paiement',
      TOTAL_PAYMENT: 'Paiement total',
      CLIENT_NAME: 'Nom du client',
      SEARCH_ADVANCE: 'Recherche avancée',
      PAYMENT_ADVANCE_COMPLETED: 'Avance terminée',
      PAYMENT_ADVANCE: 'Paiement anticipé',
      ACTION: 'Action',
      FAILED_ADVANCE_RESET: 'Remise. émis sans réinitialisation ',
      FAILED_ADVANCE_TICKET: 'Remise. délivré sans progressif ',
      PRINT_ADVANCE_FAILED: 'Remise. non délivré ',
      ADD_DISCOUNT: 'Ajouter une remise',
      CARD_NAME: 'Nom de la carte',
      GIFTS_SOLD: 'Sold',
    },
    SUBSCRIPTION_FORM: {
      NAME: 'Nom',
      TYPE: 'Type',
      STARTS_AT: 'Début de validité',
      ENDS_AT: 'Fin de validité',
      PRICE: 'Prix',
      SUBSCRIPTION_DETAILS: 'détails de l\'abonnement',
      ADD_SUBSCRIPTION: 'Ajouter un abonnement',
      EDIT: 'Modifier',
      SAVE: 'Sauvegarder',
      NO_SUBSCRIPTION_FOUND: 'Aucun abonnement trouvé',
      DESCRIPTION: 'Description',
      FROM: 'De ',
      TO: 'à',
      SALE_PERIOD: 'Durée de vente',
      SUBSCRIPTION_PERIOD: 'Durée d\'abonnement',
      DURATION: 'Durée de vente',
      ALWAYS_ACTIF: 'Vente toujours active',
      PRODUCTS: 'Produits',
      TREATMENTS: 'Traitements',
      THREE_MONTHS: '3 mois',
      SIX_MONTHS: '6 mois',
      NINE_MONTHS: '9 mois',
      TWELVE_MONTHS: '12 mois',
      NUMBER_OF_DAYS: 'Nombre de jours',
      OTHER: 'Autre',
      PRODUCT: 'Produit',
      TREATMENT: 'Traitement',
      TOTAL: 'Incidence des coûts totaux',
      TOTAL_PRICE: 'Prix Total',
      CATEGORY: 'Categorie',
      WEIGHT: 'Poids',
      PURCHASE_COST: 'Coût',
      SALE_COST: 'Prix',
      QUANTITY: 'Quantité',
      DISCOUNT: 'Réduction',
      FINAL_PRICE: 'Prix final',
      FREE: 'Gratuit',
      MINUTES: 'Minutes',
      COST: 'Incidence des coûts',
      UNIT: 'Unité',
      NO_PRODUCT_FOUND: 'Aucun produit trouvé',
      NO_TREATMENT_FOUND: 'Aucun traitement trouvé',
      CREATION_DATE: 'Date de création',
      ENTRIES: 'Détails',
      MY_SUBSCRIPTIONS: 'Mes abonnements',
      HISTORY: 'Historique',
      DESC: 'Descr',
      CHOOSE_PRODUCT_OR_TREATMENT: 'Veuillez choisir au moins un produit ou un traitement'
    },
    PLANNING: {
      TODAY: 'Aujourd\'hui',
      TOMORROW: 'Demain',
      YESTERDAY: 'Hier',
      HOUR: 'Heure',
      CLIENT: 'client',
      ADD_TREATMENT: 'Ajouter traitement',
      TREATMENT: 'Traitement',
      CABIN: 'Cabine',
      OPERATOR: 'Opérateur',
      DURATION: 'Durée',
      ALERT_TITLE: 'Supprimer une réservation',
      ALERT_TEXT: 'Vous êtes sur le point de supprimer cette réservation, êtes-vous sûr?',
      FIRST_VISIT: 'Première visite',
      LAST_VISIT: 'Dernière visite',
      NEW: 'Nouveau',
      NOTE: 'Note',
      NO_NOTE: 'Aucune note',
      CONTACT: 'Contact',
      FIRST_PASSAGE: 'Première passage',
      LAST_PASSAGE: 'Dernière passage',
      HISTORY: 'Historique',
      PAID: 'PAYÉ',
      DURATION_MSG: 'La durée minimale de la cabine et de l\'opérateur est de 10 minutes.',
      LOADING: 'Chargement en cours',
      CONFIRM_CANCELLATION: 'Confirmez-vous l\'annulation?',
      SELECT_CABIN_OR_OPERATOR: 'Sélectionnez au moins une cabine ou un opérateur pour un traitement',
      COMPANY: 'Société',
      DISABLE: 'Désactiver',
      NEW_WORKING_HOURS: 'Nouveaux horaires de travail',
      START_DATE: 'Date de début',
      END_DATE: 'Date de fin',
      SELECT_MIN_OPERATOR_OR_CABIN: 'Sélectionner au moins un opérateur ou une cabine',
      WARNING_INVALID_ACTION: 'Avertissement, action invalide!',
      DEACTIVATED: 'Désactivé',
      START_HOUR_FROM: 'Heure de',
      START_HOUR_TO: 'Heure à',
      NO_BOOKING_FOUND: 'Aucun rendez-vous trouvé',
      DRAG_THE_APPOINTMENT: 'Faire glisser le rendez-vous',
      APPOINTMENT_COPIED: 'Copie réussie du rendez-vous',
      CLIENT_SEARCH: 'Recherche par nom et prénom',
      BOOKING_LOG: 'Log',
      CREATED_AT: 'Créé le',
      UPDATED_AT: 'Mis à jour le',
      CANCELED_AT: 'Annulé le',
      CREATED_BY: 'Créé par',
      APP_APPOINTMENTS: 'les rendez-vous de l\'app',
      HOURS_NOT_AVAILABLE: 'Horaire non disponible',
      WEEK: 'Semaine',
      DAT: 'Jour',
      PREVIEW_NOT_AVAILABLE: 'L\'aperçu n\'est pas disponible, téléchargez le pdf pour le visualiser',
      DISABLE_DAY_OPERATOR: 'Il y a des rendez-vous assignés à cet opérateur. Nous vous recommandons de les vérifier et de les réaffecter si nécessaire. Souhaitez-vous quand même poursuivre?',
      DISABLE_DAY_CABIN: 'Il y a des rendez-vous assignés à cette cabine. Nous vous recommandons de les vérifier et de les réaffecter si nécessaire. Souhaitez-vous quand même poursuivre?',
      APPOINTMENT_OPERATOR_CHECK_WARNING: 'Attention, il y a déjà des rendez-vous pour l\'opérateur {{operator}}, voulez-vous continuer?',
      APPOINTMENT_CABIN_CHECK_WARNING: 'Attention, il y a déjà des rendez-vous pour la cabine {{cabin}}, voulez-vous continuer?',
      APPOINTMENT_MIX_CHECK_WARNING: 'Attention, il y a déjà des rendez-vous pour l\'opérateur {{operator}} et la cabine {{cabin}}, voulez-vous continuer?',
      CUSTOMER_IN_HURRY: 'Le client est pressé',
    },
    RT: {
      RT_CONFIGURATION: 'Configuration RT',
      ALL_CONFIGURATION: 'Toutes les configurations',
      GET_RT_CONFIG: 'Obtenez la configuration RT',
      THE_CONFIGURATION_OF_THIS_IP_ADDRESS_IS_ALREADY_SAVED: 'la configuration de cette adresse ip est deja enregistré',
      EDIT_DEPARTMENT: 'Modifier le Département',
      EDIT_VAT: 'Modifier la TVA',
      DEPARTMENT: 'Departement',
      VAT: 'TVA',
      PAYMENT: 'Payement',
      OPERATOR: 'Operateur',
      HEADER: 'Entête',
      EDIT_OPERATOR: 'Modifier l`Operateur',
      DESCRIPTION: 'Description',
      EDIT_PAYMENT: 'Modifier le Payement',
      DELETE_ERROR: 'La RT Config est liée à un ou plusieurs paiements!',
      EDIT_HEADER: 'Modifier l Entête',
      PRINT_TEST: 'Test d\'impression',
      CASHIER_OPERATIONS: 'Opérations de caisse',
      CASH_REGISTER_CLOSURE: 'Fermeture de Caisse',
      DAILY_READING: 'lecture quotidienne',
      DEPARTMENT_COUPLING: 'Dcouplage de département',
      PAYMENT_COUPLING: 'Couplage de paiement',
      USE_BARCODE: 'Utiliser le code-barres',
      ENABLE_DISABLE_PAYMENT_WITHOUT_PRINT: 'Activer / désactiver le mode de vente sans compteur de taxe',
      CONFIGURATION: 'Voir Configuration',
      RETRIEVE_CONFIGURATION: 'Récupérer la configuration',
      DEPARTMENTS: 'Departements',
      CLOSURES: 'Fermetures',
      ARE_YOU_SURE_POPUP: 'Êtes-vous sûr de vouloir utiliser le popup d\'impression?',
      YOU_ARE_USING_STANDARD: 'Vous utilisez maintenant le service d\'impression standard. Assurez-vous que vous avez autorisé les contenus dangereux sur votre navigateur ou que vous avez fait de https votre imprimante',
      YOU_ARE_USING_POPUP: 'Vous utilisez l\'impression de pop-up',
      IF_YOU_DONT_WANT_TO_USE: 'Si vous ne souhaitez pas utiliser le pop-up d\'impression, vous devez autoriser les contenus dangereux sur votre navigateur ou faire de https votre imprimante',
      USE_PRINTING_POPUP: 'Utiliser le popup d\'impression',
      SETTINGS: 'Paramètres',
      DEMO: 'Imprémente du Demo'
    },
    COURTESYRT: {
      HEADERFOOTERCONFIGURATIONTOOLTIP: 'Configurer l\'en-tête et le pied de page',
      HEADERFOOTERNOTUPDATED: 'En raison d\'une erreur de serveur, l\'en-tête et le pied de page n\'ont pas été mis à jour\n',
      HEADERFOOTERUPDATED: 'L\'en-tête et le pied de page ont été mis à jour',
      ONLYFORINDICATIVEPURPOSE: 'L\'aperçu est à titre indicatif seulement, cliquez ici pour effectuer le test d\'impression',
      DID_THE_PRINTING_TEST_TOOK_PLACE: 'Le test d\'impression a-t-il eu lieu?',
      HEADERFOOTERCONFIGURATION: 'configurer l\'en-tête et le pied de page',
      CHECK_IP: 'veuillez vérifier l\'adresse IP saisie',
      RT_ADDED: 'L\'imprimante a été ajoutée avec succès',
      YES: 'OUI',
      NO: 'NON',
      CONTENT: 'CONTENU',
      ROWS: 'LIGNES',
      ROWONE: 'un',
      ROWTWO: 'deux',
      ROWTHREE: 'trois',
      ROWFOUR: 'quatre',
      ROWFIVE: 'cinq',
      ROWSIX: 'six',
      ROWTEXT: 'texte',
      STYLE: 'style',
      CENTERTEXT: 'texte central',
      DOUBLEHEIGHTTEXT: 'texte double hauteur',
      DOUBLEWIDTHTEXT: 'texte double largeur',
    },
    LOGIN: {
      USERNAME: 'nom d\'utilisateur',
      PASSWORD: 'mot de passe',
      FORGET_PASSWORD: 'Mot de passe oublié?',
      REMEMBER_ME: 'Se souvenir de moi ',
      LOGIN: 'Connexion',
      WELCOME_BACK: 'Bienvenue à nouveau',
      CHOOSE_SELLPOINT: 'Choisissez votre point de vente',
      LOGOUT: 'Déconnexion',
      CONFIRM: 'Confirmer',
      SELL_POINTS: 'Points de vente',
      WRONG_USERNAME_PASSWORD: 'Nom d\'utilisateur ou mot de passe erroné',
      ACCOUNT_LOCKED: 'Votre compte est bloqué',
      AUTHENTICATION: 'Authentification',
      SIGN_IN_WITH_YOUR_BARCODE: 'Connectez-vous avec votre code barre',
      CODE_SUCCESSFULLY_IDENTIFIED: 'Code identifié avec succès',
      AWAITING_READING: 'En attente de lecture',
      USE_BARCODE: 'Utiliser le code-barres',
      OWNER_OR_SUPEADMIN_BARCODE_ERROR: 'Le propriétaire et le super-administrateur ne peuvent pas se ' +
        'connecter à l\'aide d\'un code-barres, veuillez utiliser votre nom d\'utilisateur et votre mot de passe',
      SWITCH_LICENSE_MSG: 'Cher client, la période d\'essai est terminée. Vous pouvez toujours continuer à utiliser l\'application en mode libre. Si vous souhaitez vous mettre à niveau, veuillez nous contacter.',
      YOU_DONT_HAVE_ACCOUNT: 'Vous n\'avez pas de compte?',
      SIGN_UP: 'S\'inscrire.',
      LOGIN_PAGE: 'Page de connexion',
    },
    SMS: {
      TEMPLATE_NAME: 'Nom du modèle',
      LANGUAGE_CODE: 'Code de langue',
      COMPAIGN_NUMBER: 'Numéro de campagne',
      TEMPLATE: 'Modèle',
      CREATION_DATE: 'Date de création',
      SCHEDULED_AT: 'Prévu à',
      SUBSCRIBERS_NUMBER: 'Numéro d\'abonné',
      NEW_COMPAIGN: 'Nouvelle campagne',
      SELECT_ALL: 'Tout sélectionner',
      AT: 'À',
      DATE: 'Date',
      FIRST_NAME: 'Nom',
      LAST_NAME: 'Prénom',
      SETTINGS: 'Configuration SMS',
      PROVIDER_CONFIGURATION: 'Configuration du fournisseur Smshosting',
      APPOINTMENT_REMINDER: 'Rappel de rendez-vous',
      GENERIC_SMS_TO_APPOINMENTS: 'SMS générique pour les clients de rendez-vous',
      GENERIC_MESSAGE_FROM: 'Rendez-vous de',
      GENERIC_MESSAGE_TO: 'au',
      BEFORE: 'Avant',
      HOURS: 'Heures',
      TIME: 'Temps',
      BIRTHDAY_REMINDER: 'Rappel d\'anniversaire',
      CONFIGURATION_SUCCESS: 'Configuration mis à jour avec succés',
      HOSTNAME: 'Nom de l\'hôte',
      PORT: 'Port',
      USERNAME: 'Username',
      PASSWORD: 'Mot de passe',
      PROXY: 'Proxy',
      PROXY_PORT: 'Port du proxy',
      ACTIVATE_PROXY: 'Activer le proxy',
      STATUS: 'Status',
      DO_YOU_WANT_TO_DELETE_CAMPAIGN: 'Voulez-vous supprimer la campagne ?',
      CAMPAIGN_ID: 'ID Campagne',
      RECEIVER_ID: 'ID récepteur',
      RECEIVER: 'Récepteur',
      SENT_AT: 'Envoyé à',
      CAMPAIGN_REMINDER: 'Rappel de campagne',
      MOBILE: 'Mobile',
      SENT: 'Envoyé',
      FAILED: 'Echoué',
      INSERTED: 'Envoyé',
      NOT_INSERTED: 'Echoué',
      DELIVERED: "Délivéré ",
      NOTDELIVERED: "Non délivéré",
      PENDING: "En attente",
      //SENT: "SENT",
      NOSENT: "Non envoyé",
      SCHEDULED: 'Programmé',
      CANCELED: 'Annulé',
      DONE: 'Lancé',
      PHONE: 'Téléphone',
      SENDER: 'Numéro de texte ou d\'expéditeur',
      TEST: 'Validation des clés',
      KEY_VALIDATED: 'Clé de validation confirmée',
      KEY_NOT_VALIDATED: 'Clé de validation non valides',
      INFO: 'Attention, pour assurer l\'envoi de tous les SMS aux destinataires, ' +
        'reporter au moins 10 minutes par rapport à l\'heure actuelle d\'envoi',
      SELECT_CLIENTS: 'Sélectionner des clients',
      CREDITS: 'Solde SMS',
      BUY: 'Acheter SMS',
      BADNUMBERFORMAT: 'Mauvais numéro de téléphone',
      DUPLICATESMS: 'SMS en double: SMS déjà envoyés au cours des dernières 24 heures',
      BLACKLIST: 'Numéro de liste noire',
      BAD_CREDIT: 'Crédit insuffisant',
      SEND_NOW: 'Envoi instantané',
      ONLY_SEND: 'Envoi',
      SUCCESS: 'Envoi réussi',
      SEND_FAILED: 'Erreur d\'envoi, réessayez ou contactez l\'assistance',
      ERROR_PROVIDER: 'Erreur lors de la tentative de contact avec le fournisseur SMS',
      BAD_TRANSACTIONID: 'Paramètre transactionId non valide',
      BAD_DATE: 'Format date non valide',
      BAD_TEXT: 'Paramètre texte non valide',
      BAD_TEXT_PLACEHOLDER: 'Le texte contient des espaces réservés non remplaçables',
      BAD_CALLBACK: 'Paramètre callBack non valide',
      BAD_ENCODING: 'Paramètre encoding non valide',
      BAD_FROM: 'Paramètre from non valide',
      BAD_GROUP: 'Paramètre group non valide',
      NO_CREDIT: 'Crédit insuffisant',
      NO_VALID_RECIPIENT: 'Destinataire invalide',
      GENERIC_ERROR: 'Erreur Interne du Serveur',
      INVALID_CREDENTIALS: 'Informations d\'identification invalides',
      METHOD_NOT_ALLOWED: 'Méthode HTTP non autorisée',
      RESEND: 'Voulez-vous envoyer le message a {{nome}} {{cognome}} sur le {{number}} ?',
      SEND_QUESTION: 'Confirmer la création de cette campagne mail?',
      MAX_ELEVEN: 'Caractères autorisés 11, y compris les espaces',
      NOTIFICATION_TYPE: 'Type de notification'
    },
    WHATS_APP: {
      GENERIC_MSG_TO_APPOINMENTS: 'Messages génériques WhatsApp pour les clients avec rendez-vous',
      SETTINGS: 'Paramètres WhatsApp',
      BASE_URL: 'URL de base',
      Sender_Phone_Number_Id: 'ID du numéro de téléphone de l\'expéditeur',
      Token: 'Jeton',
      Whatsapp_config: 'Configuration WhatsApp Web',
    },
    MOVEMENT_FORM: {
      CODE: 'Code',
      NO_MOVEMENT_FOUND: 'Aucun mouvement trouvé',
      CREATE_MOVEMENT: 'Créer un mouvement',
      CREATE_TRANS: 'Créer un transfer',
      DOCUMENT_NUMBER: 'Numéro de document',
      CLIENT: 'Client',
      PROVIDER: 'Fournisseur',
      PAYMENT: 'Paiement',
      DOCUMENT_DATE: 'Date du document',
      DOCUMENT_TYPE: 'Type de document',
      TYPE: 'Type',
      NUMBER: 'N°',
      DATE: 'Date mouvement',
      BAR_CODE: 'code nu',
      PLU_DESCRIPTION: 'Description Plu',
      PURCHASE_COAST: 'Coût d\'achat',
      IVA: 'TVA',
      QUANTITY: 'Quantité',
      CASH: 'Cash',
      CHEQUE: 'Vérifier',
      CREDIT_CARD: 'Carte bancaire',
      RECAP: 'Résumés',
      TOTAL_QUANTITY: 'QT. Tot. Art.',
      TOTAL_IVA: 'TVA totale',
      TOTAL_TAXABLE: 'Total taxable',
      SUPPLIER_LOAD: 'Chargement fournisseur',
      CUSTOMER_SALES: 'Vente client',
      SELF_CONSUMPTION: 'Auto-consomation',
      VARIOUS_DISCHARGE: 'Déchargement varié',
      VARIOUS_LOAD: 'Chargement varié',
      NONE: 'Aucun',
      BILL: 'Facture',
      DDT: 'DDT',
      ADD_PRODUCT: 'Ajouter un produit',
      TOTAL_IVATO: 'TVA totale',
      PRODUCT: 'Produit',
      DATE_FROM: 'Date de',
      DATE_TO: 'Date à',
      SAVE_DRAFT: 'Enregistrer le brouillon',
      MOVEMENT_DETAILS: 'Détails du mouvement',
      INVENTORY_DETAILS: 'Détails de l\'inventaire',
      CREATE_INVENTORY: 'Créer un inventaire',
      NOTE: 'Note',
      CLIENT_PROVIDER: 'Fournisseur / Client',
      DRAFT: 'Brouillon',
      EDIT_NAME: 'Modifier le nom',
      EDIT_COST: 'Modifier le coût d\'achat',
      EDIT_PRICE: 'Modifier le prix de vente',
      EDIT_DETAILS: 'Modifier les données',
      CREATION: 'Création de produit',
      YES: 'Oui',
      NO: 'Non',
      SELECT_COLUMNS: 'Personalisation des colonnes',
      LOAD_PRODUCTS: 'Charger des produits',
      EDIT_PRODUCT: 'Modifier produit',
      UPLOAD_FILE: 'Télécharger fichier',
      DOWNLOAD_MODEL: 'Télécharger le modèle',
      NOT_FOUNDED: 'Produit non trouvé',
      INVALID_ROW: 'Attention, fichier d\'importation non valide, \n ligne numéro  ',
      IMPORT_PREVIOUS_ROWS: 'Importer les lignes précédentes?',
      SALES_PRICE: 'Prix de vente',
      RESO: 'Retour',
      GIFT: 'Gift',
      RESULT_COUNT: 'Nombre d\'éléments affichés',
      ALL: 'Tous',
      TOTAL: 'Totale',
      TRANSFER_DETAILS: 'Détail Transfert de marchandises',
      CREATE_TRANSFER: 'Créer un transfert de marchandises',
      SELL_POINT_ORIGINE: 'Pdv Départ',
      SELL_POINT_DEST: 'Pdv Arrivé',
      TOTAL_QUANTITY_TRANSFER: 'QT. Tot. Art.Tras',
    },
    STOCK_FORM: {
      PROGRESS_CODE: 'Code',
      CREATION_DATE: 'historique',
      BAR_CODE: 'Barcode',
      PRODUCT_HISTORY: 'Historique du produit',
      OPERATOR: 'Operateur',
      QUANTITY: 'Quantité',
      CONSUMED: 'Consommé',
      REMAINING_QUANTITY: 'Quantité restante',
      USE_TYPE: 'Type d\'utilisation',
      PURCHASE_COST: 'Coût d\'achat',
      AVERAGE_COST: 'Coût moyen',
      UNDER_ESCORT: 'Sous escorte',
      PHOTO: 'Photos',
      COMMERCIAL_DESCRIPTION: 'Description commerciale',
      CATEGORY: 'Catégorie',
      SUBCATEGORIES: 'Sous-catégorie',
      PROVIDER: 'Fournisseur',
      ML_GR: 'Ml / Gr',
      BRAND: 'Marque',
      LINEA: 'Ligne',
      STOCK: 'Stock',
      ACTIVE: 'Active',
      CODE: 'Code',
      IVA: 'TVA',
      TOTAL_PURCHASE_COST: 'Coût d\'achat total',
      CAUSAL: 'Causal',
      GIFT: 'Cadeaux',
      DESCRIPTION: 'Description',
      PRICE: 'Prix',
      MIN_STOCK: 'Min. stock',
      STOCK_ALERT: 'Produits d\'alerte de stock',
    },
    STATISTIC_FORM: {
      TIME_LAPS: 'Intervalle de temps',
      CATEGORY: 'Catégorie',
      CLIENT: 'Client',
      SEARCH: 'Recherche',
      PRODUCT: 'Article',
      QTT_SALES: 'Quantité vendue',
      QTT_RESO: 'Quantité rendue - annulée',
      ALL_RESO: 'Total rendu - annulation',
      ALL_SALES: 'Total vendu',
      ALL_PROFIT: 'Bénéfice total',
      MONTH_SALES_VALUE: 'Valeur des ventes par mois',
      MONTH_SALES_QTT: 'Quantité de ventes par mois',
      TREATMENT: 'Service',
      CURRENT_WEEK: 'Semaine en cours',
      LAST_WEEK: 'La semaine dernière',
      CURRENT_MONTH: 'Mois en cours',
      LAST_MONTH: 'Le mois dernier',
      FIRST_TRIMESTER: 'Premier trimestre',
      SECOND_TRIMESTER: 'Deuxième trimestre',
      THIRD_TRIMESTER: 'Troisième trimestre',
      FORTH_TRIMESTER: 'Quatrième trimestre',
      FIRST_SEMESTER: 'Premier semestre',
      SECOND_SEMESTER: 'Second semestre',
      CURRENT_YEAR: 'Année en cours',
      LAST_YEAR: 'dernière année',
      NO_TREATMENT_FOUND: 'Aucun traitement trouvé',
      NO_PRODUCT_FOUND: 'Aucun produit trouvé',
      CSV: 'CSV',
      EXCEL: 'EXCEL',
      USERNAME: 'Username',
      OPERATOR: 'Opérateur',
      SALES_TYPE: 'Tipe de vente',
      NO_OPERATOR_FOUND: 'Aucun opérateur trouvé',
    },
    DASHBOARD: {
      TODAYS_BOOKING: 'Rendez-vous du jour',
      NEXT_BIRTHDAY: 'LES PROCHAINS ANNIVERSAIRES',
      INCASSA: 'Prévisions de collecte quotidiennes',
      FICHE_MEDIA: 'Puce moyenne',
      SCONTRINO_MEDIO: 'Reçu moyen',
      TOP_CLIENT: 'TOP CLIENTS',
      LAST2WEEKS: '2 DERNIERS MOIS',
      TOP_SERVICE: 'TOP SERVICES',
      GO_TO_CONFIG: 'Vous n\'avez pas encore configuré votre compte. Cliquez ici',
      COLLECTION_TREND_IN_THE_LAST_2_WEEKS: 'Tendance de la collection au cours des 2 dernières semaines'
    },
    DAY: {
      MONDAY: 'Lundi',
      TUESDAY: 'Mardi',
      WEDNESDAY: 'Mercredi',
      THURSDAY: 'Jeudi',
      FRIDAY: 'Vendredi',
      SATURDAY: 'Samedi',
      SUNDAY: 'Dimanche'
    },
    REGISTER: {
      SIGN_UP: 'S\'inscrire',
      ACTIVITY_NAME: 'Nom de l\'activité',
      FIRST_NAME: 'Prénom',
      LAST_NAME: 'Nom',
      EMAIL: 'Email',
      PASSWORD: 'mot de passe',
      CONFIRM_PASSWORD: 'Confirmer le mot de passe',
      MOBILE: 'Mobile',
      CONFIRM: 'Confirmer',
      EMAIL_EXISTS: 'Cette adresse email n\'est pas disponible',
      THANKS_FOR_SIGNUP: 'Merci pour votre inscription!',
      // tslint:disable-next-line:max-line-length
      REGISTER_SUCCESS: 'Merci d\'avoir pris rendez-vous ! L\'inscription a été réussie. Un courriel a été envoyé à votre adresse. L\'activation ne sera terminée qu\'après avoir cliqué sur le lien reçu dans le courriel. Si vous n\'avez rien reçu, veuillez consulter la section spam/désinscription.',
      LENGTH_ERROR: 'Longueur égale ou supérieure à 6',
      ONE_UPPERCASE_ERROR: 'Contenir au moins un caractère majuscule',
      ONE_LOWERCASE_ERROR: 'Contenir au moins un caractère minuscule',
      ONE_NUMBER_ERROR: 'Contenir au moins un numéror',
      VALIDATION: 'Validation',
    },
    IMAGE: {
      ADD_PICTURE: 'Ajouter une photo',
      OR: 'Ou',
      CLICK: 'Cliquer ici',
      DRAG: 'Faites glisser une photo ici',
      ADD: 'Ajouter',
      CANCEL: 'Annuler',
      ANOTHER: 'Choisir une autre',
      OVERSIZE: 'Attention, l\'image sélectionnée dépasse la taille maximale autorisée 2 Mo',
      NOT_IMAGE: 'Sélectionnez une autre photo'
    },
    INIT_ACCOUNT: {
      YOUR_COMPANY: 'Votre entreprise',
      SELL_POINT: 'Point de vente',
      KEY_EXPIRED: 'Votre clé électronique d\'enregistrement est expirée, veuillez vous inscrire à nouveau',
      BUSINESS_NAME: 'Nom commercial',
      VAT: 'Numéro de TVA',
      YOUR_BUSINESS: 'Le nom de votre entreprise',
      ADDRESS: 'Adresse',
      STREET: 'Civique',
      COUNTRY: 'La nation',
      ZIP_CODE: 'CAP',
      PROVINCE: 'La province',
      CITY: 'La ville',
      DEALER_CODE: 'Code revendeur',
      YOUR_PLAN: 'Votre plan',
      PLANNING_START_TIME: 'Temps de commencer à planifier',
      END_OF_PLANNING_HOURS: 'Heure de fin de la planification',
      OPERATOR: 'opérateur',
      FIRST_NAME: 'Nom',
      LAST_NAME: 'Nom de famille',
      PASSWORD: 'Mot de passe',
      CONFIRM_PASSWORD: 'Confirmer le mot de passe',
      USERNAME: 'L\'utilisateur',
      PLANNING_NAME: 'Nom de la planification',
      CABIN_NAME: 'Nom de cabine',
      CABIN: 'Cabine',
      USERNAME_NOT_AVAILABLE: 'Nom d\'utilisateur {{username}} déjà utilisé',
      COMPANY_NAME_NOT_AVAILABLE: 'Nom commercial {{businessName}} déjà utilisé',
      VAT_NUMBER_NOT_AVAILABLE: 'Numéro de tva {{vatNumber}} déjà utilisé',
      ADD_OPERATOR_OR_CABIN: 'Pour continuer, vous devez entrer dans au moins un opérateur ou une cabine',
      DID_NOT_ADD_CABIN: 'Vous n\'avez pas inséré de cabine. Voulez-vous quand même procéder?',
      DID_NOT_ADD_OPERATOR: 'Vous n\'avez pas inséré d\'opérateur. Voulez-vous quand même procéder?',
    },
    FORGET_PASSWORD: {
      EXPIRED: 'Votre token est expiré, essayez à nouveau',
      FORGOT_YOUR_PASSWORD: 'Vous avez oublié votre mot de passe?',
      ENTER_USERNAME: 'Entrez le nom d\'utilisateur associé à votre compte',
      WE_WILL_EMAIL_YOU: 'Nous vous enverrons par e-mail un lien pour réinitialiser votre mot de passe',
      WRITE_YOUR_USERNAME: 'Inscrivez votre nom d\'utilisateur ici',
      YOU_JUST_GOT_EMAIL: 'Vous venez de recevoir un courriel contenant les instructions pour réinitialiser votre mot de passe',
      CHANGE_YOUR_PASSWORD: 'Changer votre mot de passe',
      USERNAME_NOT_FOUND: 'Nom d\'utilisateur non valide',
    },
    FISCAL_REPORT: {
      FISCAL_REPORT: 'RAPPORT FINANCIER',
      TOTAL_CASSA: 'Total en espèces',
      TOTAL_BANCA: 'Total en banque',
      TOTAL_DOC: 'VENTES ',
      WITHOUT_SCONTO: 'Ventes totales (sans remise)',
      SCONTO: 'Remises appliquées',
      TOTAL: 'Total actualisé',
      ABANDFID: 'ABONNEMENTS & FIDÉLITÉ',
      TOTAL_AB_VEND: 'Total des abonnements vendus',
      TOTAL_GC_VEND: 'Nombre total de cartes-cadeaux vendues',
      TOTAL_LC_VEND: 'Total des recharges vendues',
      TOTAL_PAG: 'PAIEMENTS',
      SOLD: 'Ventes',
      CASH: 'En espèces',
      CREDIT: 'Carte de crédit',
      ASSEGNO: 'Chéque',
      BONIFICO: 'Transfert',
      TOT: 'Total',
      QUOTE: 'Quote'
    },
    FIRST_NOTE: {
      FIRST_NOTE: 'Première note',
      FIRST_NOTE_CAUSE: 'Première note cause',
      DATE: 'Date',
      NAME: 'Nom',
      INCOME: 'REVENUS',
      OUTLAY: 'DÉPENSE',
      DELETE_MSG: 'La suppression d\'une ligne de la première note n\'est pas réversible, êtes-vous sûr de vouloir continuer?',
      DELETE_CAUSE_MSG: 'La suppression d\'une ligne de cause de la première note n\'est pas réversible, êtes-vous sûr de vouloir continuer?',
      TOTAL_INCOME: 'REVENU TOTAL',
      TOTAL_OUTLAY: 'DÉPENSE TOTAL',
      BALANCE_FIRST_NOTE: 'BALANCE PREMIÈRE NOTE',
    },
    SATISPAY: {
      SATISPAY_CONFIG: 'Configuration de Satispay',
      INVALID_RSA_KEY: 'Clé RSA invalide',
      TOKEN_ALREADY_PAIRED: 'Token déjà appairé',
      DEVICE_TOKEN_NOT_FOUND: 'Token d\'appareil non trouvé',
      GENERATE: 'Générer',
      TEST_AUTHENTICATION: 'Test d\'authentification',
      RESET_CONFIGURATION: 'Réinitialiser la configuration',
      ARE_YOU_SURE: 'En êtes-vous absolument sûr ?',
      // tslint:disable-next-line:max-line-length
      YOU_CANNOT_USE_SATISPAY: `Si vous continuez, vous ne pourrez plus utiliser <b>Satispay</b> comme méthode de paiement tant que vous n'aurez pas procédé à une nouvelle configuration.`,
      PLEASE_TYPE: `Entrez le code suivant <b>{{data}}</b> pour confirmer l'annulation.`
    },
    PROMO_FORM: {
      ID: 'ID',
      NO_PROMO_FOUND: 'Aucune promotion trouvée',
      ADD_PROMO: 'Ajouter une promotion',
      SMS: 'Sms',
      MAIL: 'E-mail',
      TYPE: 'Que voulez-vous envoyer?',
      MODEL: 'Modèle de promotion',
      OBJECT: 'Objet',
      MESSAGE: 'Message',
      SENT_DATE_HOUR: 'Date et heure d\'envoi',
      SENT_HOUR: 'Maintenant, j\'envoie',
      RECEIVER: 'Nombre de destinataires',
      FILTER_RECEIVERS: 'Filtre des destinataires',
      CREATION_DATE: 'Date de création',
      PROMO_DETAILS: 'Détail de la promotion',
      LAST_MONTH: 'Le mois dernier',
      LAST_THREE_MONTHS: 'Trois derniers mois',
      LAST_SIX_MONTHS: 'Six derniers mois',
      LAST_NINE_MONTHS: 'Neuf derniers mois',
      LAST_YEAR: 'L\'année dernière',
      STATUS: 'Status',
      EDIT_TEMPLATE: 'Modifier le modèle',
    },
    MOBILE_APP_CONFIG: {
      TOLERANCE_HOURS: 'Tolérance d\'annulation de réservation (en heures)',
      UPDATED_SUCCESSFULLY: 'La configuration de l\'application mobile a été mise à jour avec succès',
      NOTIFICATION_REMINDER_HOURS: 'Rappel de notification (en heures)',
      QR_CODE: 'Code QR',
      DISABLE_RDV: 'Désactiver la prise de rendez-vous'
    },
    QUICK_SIDEBAR: {
      SUBSCRIBE_TO_PUSH_NOTIFICATIONS: 'S\'abonner aux notifications push',
      UNSUBSCRIBE_FROM_PUSH_NOTIFICATIONS: 'Se désabonner des notifications "push',
      NO_NOTIFICATION: 'Pas de notification présente',
    },
    PROMO_EDITOR: {
      NAME: 'Nom',
      DESCRIPTION: 'Description',
      TEMPLATE_DETAILS: 'Détails du template',
      SELECT_TEMPLATE: 'Sélectionner un template',
      NO_TEMPLATE_FOUND: 'Aucun template trouvé',
      DELETE_MSG: 'Vous allez supprimer ce template! En êtes-vous sûr?',
      TEMPLATE_USED: 'Ce template ne peut pas être supprimé, car il est utilisé',
      TEMPLATE_SAVED: 'Le template a été sauvegardé avec succès',
    },
    PROMO_SOCIAL: {
      STATUS: 'Statut',
      FAILED: 'Echec',
      PENDING: 'En attente',
      SENT: 'Envoyé',
      CREATED_AT: 'Créé à',
      NO_POST_FOUND: 'Aucune publication trouvée',
    },
    COMP_FILTER: {
      TODAY: 'Today',
      YESTERDAY: 'Yesterday',
      LAST_7_DAYS: 'Last 7 days',
      LAST_30_DAYS: 'Last 30 days',
      THIS_MONTH: 'This month',
      LAST_MONTH: 'Last month',
      LAST_3_MONTHS: 'Last 3 months',
      LAST_6_MONTHS: 'Last 6 months',
      CURRENT_YEAR: 'Current year',
      LAST_YEAR: 'Last year',
    },
  }
};


import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FidelityComponent} from './fidelity.component';
import {FidelityFormComponent} from './fidelity-form/fidelity-form.component';
import {RouterModule, Routes} from '@angular/router';
import {MatLegacyButtonModule} from '@angular/material/legacy-button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {  MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';

import {TranslateModule} from '@ngx-translate/core';
import {ReactiveFormsModule} from '@angular/forms';
import {FidelityWithDiscountFormComponent} from './fidelity-with-discount-form/fidelity-with-discount-form.component';
import {FidelityPointFormComponent} from './fidelity-point-form/fidelity-point-form.component';
import {SharedModule} from '../../../../shared/shared.module';
import {RechargeFormComponent} from './fidelity-with-discount-form/recharge-form/recharge-form.component';
import {ThresholdFormComponent} from './fidelity-point-form/threshold-form/threshold-form.component';
import {NgbPaginationModule, NgbTooltipModule, NgbTypeaheadModule} from '@ng-bootstrap/ng-bootstrap';
import {MatIconModule} from "@angular/material/icon";

const routes: Routes = [
];

@NgModule({
    declarations: [
        FidelityComponent,
        FidelityFormComponent,
        FidelityWithDiscountFormComponent,
        FidelityPointFormComponent,
        RechargeFormComponent,
        ThresholdFormComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatTabsModule,
        TranslateModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatLegacyButtonModule,
        SharedModule.forChild(),
        NgbTypeaheadModule,
        NgbPaginationModule,
        NgbTooltipModule,
        MatIconModule,
    ],
    exports: [
        FidelityComponent
    ]
})
export class FidelityModule {
}

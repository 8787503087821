import {Component, OnInit} from '@angular/core';
import {Operator} from '../../../../shared/models/operator';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {OperatorMgmService} from '../../../../shared/services/operator-mgm.service';
import {BreadcrumbService} from '../../../../core/services/breadcrumb.service';
import {OperatorPlanningsComponent} from './plannings/operator-plannings.component';
import {OperatorFormService} from '../../operator-management/operator-management-form/services/operator-form.service';
import {TranslateService} from '@ngx-translate/core';
import {SweetAlertService} from '../../../../shared/services/sweet-alert.service';
import {YearPlanningService} from '../../../../shared/services/year-planning.service';

@Component({
  selector: 'app-operators-list',
  templateUrl: './operators-list.component.html',
  styleUrls: ['./operators-list.component.scss']
})
export class OperatorsListComponent implements OnInit {
  columns = [
    'DATA_TABLE.ID',
    'DATA_TABLE.USERNAME',
    'DATA_TABLE.FIRST_NAME',
    'DATA_TABLE.LAST_NAME',
    'DATA_TABLE.MOBILE',
    'DATA_TABLE.PLANING_NAME',
    'DATA_TABLE.PLANING_POSITION',
  ];
  operatorList: Operator[] = [];
  loading = false;
  orderType: 'ASC' | 'DESC' = 'DESC';

  constructor(
    private matDialog: MatDialog,
    private operatorService: OperatorMgmService,
    private breadcrumbService: BreadcrumbService,
    private yearPlanningService: YearPlanningService,
    private translate: TranslateService, private sweetAlertService: SweetAlertService,
  ) {
  }

  ngOnInit() {
    // this.yearPlanningService.associateTemplates().subscribe(r => {});
    this.sendBreadCrumb();
    this.loading = true;
    this.loading = false;
    this.operatorService.getAllOperatorsBySpId().subscribe(d => {
      this.operatorList = d;
      this.loading = false;
    }, error => this.loading = false);
  }

  openEDitPlannings(operatorSellPoint) {
    const dialogRef = this.matDialog.open(OperatorPlanningsComponent, {
      width: '1200px',
      maxHeight: '88vh',
      autoFocus: true,
      disableClose: true,
      data: {editMode: false, operator: operatorSellPoint}
    });
    dialogRef.afterClosed().subscribe(d => {
      this.operatorService.getAllOperatorsBySpId().subscribe(op => {
        this.operatorList = op;
      });
    });
  }

  sendBreadCrumb(): void {
    this.breadcrumbService.sendBreadcrumb(['CONFIGURATION', 'OPERATORS']);
  }


  deleteOperator(el: any) {
    let msg = '';
    if (el.visible) {
      msg = this.translate.instant('DIALOG.YOU_WANT_TO_HIDE');
    } else {
      msg = this.translate.instant('DIALOG.YOU_WANT_TO_SHOW');
    }
    this.sweetAlertService.warning(msg + el.firstName + ' ' + el.lastName)
      .then(res => {
          if (res.value) {
            this.operatorService.hideOperator(el.id).subscribe(d => {
              if (d.status === 200) {
                this.operatorService.getAllOperatorsBySpId().subscribe(op => {
                  this.operatorList = op;
                  this.loading = false;
                });
              }
            });
          }
        }, err => {
        }
      );
  }

  sortRows(c: string) {
    const columns = [
      'DATA_TABLE.ID',
    ];
    const sort = c.substr(11);
    if (this.orderType === 'ASC') {
      this.orderType = 'DESC';
    } else {
      this.orderType = 'ASC';
    }
    switch (sort) {
      case 'USERNAME':
        if (this.orderType === 'ASC') {
          this.operatorList.sort((a, b) => a.username.localeCompare(b.username));
          return;
        }
        this.operatorList.sort((a, b) => b.username.localeCompare(a.username));
        break;
      case 'FIRST_NAME':
        if (this.orderType === 'ASC') {
          this.operatorList.sort((a, b) => a.firstName.localeCompare(b.firstName));
          return;
        }
        this.operatorList.sort((a, b) => b.firstName.localeCompare(a.firstName));
        break;
      case 'LAST_NAME':
        if (this.orderType === 'ASC') {
          this.operatorList.sort((a, b) => a.lastName.localeCompare(b.lastName));
          return;
        }
        this.operatorList.sort((a, b) => b.lastName.localeCompare(a.lastName));
        break;
      case 'PLANING_NAME':
        if (this.orderType === 'ASC') {
          this.operatorList.sort((a, b) => a.planningName.localeCompare(b.planningName));
          return;
        }
        this.operatorList.sort((a, b) => b.planningName.localeCompare(a.planningName));
        break;
      case 'MOBILE':
        if (this.orderType === 'ASC') {
          this.operatorList.sort((a, b) => Number(a.mobile) - Number(b.mobile));
          return;
        }
        this.operatorList.sort((a, b) => Number(b.mobile) - Number(a.mobile));
        break;
      case 'PLANING_POSITION':
        if (this.orderType === 'ASC') {
          this.operatorList.sort((a, b) => a.planningPosition - b.planningPosition);
          return;
        }
        this.operatorList.sort((a, b) => b.planningPosition - a.planningPosition);
        break;
      case 'ID':
        if (this.orderType === 'ASC') {
          this.operatorList.sort((a, b) => a.seq - b.seq);
          return;
        }
        this.operatorList.sort((a, b) => b.seq - a.seq);
        break;
    }
  }
}

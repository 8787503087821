// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-sidebar{
  background-color: white !important;
}


.sidebar-background{
  /*background-image: url('../../../../../assets/img/backgnd.jpeg');*/
  /*background-color: #212529;*/
  background-color: var(--header-color);
  position: absolute;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: 50%;
}

/*.sidebar-background::after {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  display: block;
  background: #fff;
  opacity: .90;
}*/

`, "",{"version":3,"sources":["webpack://./src/app/content/layout/components/sidebar/sidebar.component.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;AACpC;;;AAGA;EACE,mEAAmE;EACnE,6BAA6B;EAC7B,qCAAqC;EACrC,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,cAAc;EACd,MAAM;EACN,OAAO;EACP,sBAAsB;EACtB,wBAAwB;AAC1B;;AAEA;;;;;;;;EAQE","sourcesContent":[".page-sidebar{\r\n  background-color: white !important;\r\n}\r\n\r\n\r\n.sidebar-background{\r\n  /*background-image: url('../../../../../assets/img/backgnd.jpeg');*/\r\n  /*background-color: #212529;*/\r\n  background-color: var(--header-color);\r\n  position: absolute;\r\n  height: 100%;\r\n  width: 100%;\r\n  display: block;\r\n  top: 0;\r\n  left: 0;\r\n  background-size: cover;\r\n  background-position: 50%;\r\n}\r\n\r\n/*.sidebar-background::after {\r\n  position: absolute;\r\n  width: 100%;\r\n  height: 100%;\r\n  content: \"\";\r\n  display: block;\r\n  background: #fff;\r\n  opacity: .90;\r\n}*/\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import {Component, ElementRef, HostListener, Inject, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {ConfigurationsService} from '../../../../shared/services/configurations.service';
import {  MatSnackBar} from '@angular/material/snack-bar';
import {SweetAlertService} from '../../../../shared/services/sweet-alert.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-profession-modal',
  template: `
      <h1 mat-dialog-title *ngIf="!data.editMode">
          {{'CONFIGURATION.NEW_PROFESSION' | translate}}
      </h1>
      <h1 mat-dialog-title *ngIf="data.editMode">
          {{'CONFIGURATION.EDIT_PROFESSION' | translate}}
      </h1>
      <hr>
      <div mat-dialog-content>
          <form [formGroup]="professionForm" id="addForm" (ngSubmit)="save()">
              <div class="form-group">
                  <label for="description">{{'CONFIGURATION.NEW_PROFESSION_DESCRIPTION' | translate}}:</label>
                  <div class="input-group-icon input-group-icon-left">
                    <span class="input-icon input-icon-left">
                        <i aria-hidden="true" class="ti-info"></i>
                    </span>
                      <input type="text" class="form-control" formControlName="description"
                             [ngClass]="{'is-invalid': submitted && professionForm.get('description').invalid}"
                             #descInput>
                  </div>
                  <div *ngIf="submitted && professionForm.get('description').invalid;" class="d-block invalid-feedback">
                      {{'CONFIGURATION.EMPTY_DESCRIPTION' | translate}}
                  </div>
              </div>
          </form>
      </div>
      <hr>
      <div mat-dialog-actions class="d-flex justify-content-between"
           [ngClass]="{'justify-content-between': !data.editMode, 'justify-content-end': data.editMode}">
          <label class="checkbox checkbox-success" *ngIf="!data.editMode">
              <input [formControl]="addMultipleCheckbox" type="checkbox"><span
                  style="font-size: 10px">{{'CONFIGURATION.ADD_NEW_ELEMENT' | translate}}</span>
          </label>
          <div>
              <button mat-button class="cancel-button"
                      (click)="confirmAndClose()"><mat-icon>close</mat-icon>{{'BUTTONS.CLOSE' | translate}}</button>
            <span class="button-space" ></span>
              <button mat-flat-button class="confirm-button" [disabled]="professionForm.get('description').disabled"
                      form="addForm"
                      (click)="save()"><mat-icon>save</mat-icon>{{'BUTTONS.SAVE' | translate}}
              </button>
          </div>
      </div>
  `
})
export class ProfessionFormModalComponent implements OnInit {
  professionForm: UntypedFormGroup;
  submitted = false;
  addedElement = false;
  addMultipleCheckbox = new UntypedFormControl(false);
  @ViewChild('descInput', { static: false }) descInput: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<ProfessionFormModalComponent>,
    private configurationsService: ConfigurationsService,
    private snackBar: MatSnackBar,
    private sweetAlertService: SweetAlertService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit(): void {
    this.professionForm = new UntypedFormGroup({
      id: new UntypedFormControl(),
      description: new UntypedFormControl(null, [Validators.required])
    });
    if (this.data.editMode) {
      this.professionForm.patchValue(this.data.prefContact);
    }
  }

  save() {
    this.submitted = true;
    if (!this.professionForm.valid) {
      this.sweetAlertService.notification(this.translate.instant('DIALOG.INSERT_ALL_MANDATORY_INFORMATION')).then(e => {
      });
      return;
    }
    this.professionForm.get('description').disable();
    this.addedElement = true;
    if (!this.data.editMode) { // add new preferred contact
      this.configurationsService.addNewProfession(this.professionForm.getRawValue()).subscribe(d => {
        if (!this.addMultipleCheckbox.value) { // if "add other elements" not checked the dialog is closed
          this.dialogRef.close(d);
        } else { // if "add other elements" not checked the dialog is not closed and the element is added to the backend
          this.snackBar.open(this.translate.instant('DIALOG.ADD_SUCCESS'), 'Ok', {duration: 5000, panelClass: 'white-snackbar'});
        }
      }, (err) => {
        this.professionForm.get('description').enable();
        this.snackBar.open(this.translate.instant('DIALOG.INTERNAL_SERVER_ERROR'), 'Ok', {duration: 5000});
      }, () => {
        this.professionForm.get('description').enable();
        this.professionForm.get('description').setValue(null);
        this.submitted = false;
        this.descInput.nativeElement.focus();
      });
    } else {
      this.configurationsService.editProfession(this.professionForm.getRawValue()).subscribe(d => {
      }, (err) => {
        this.professionForm.get('description').enable();
        this.snackBar.open(this.translate.instant('DIALOG.INTERNAL_SERVER_ERROR'), 'Ok', {duration: 5000});
      }, () => {
        this.dialogRef.close(true);
        this.snackBar.open(this.translate.instant('DIALOG.UPDATE_SUCCESS'), 'Ok', {duration: 5000});
      });
    }
  }

  @HostListener('document:keydown.escape')
  onEscapeBtnClick() {
    this.dialogRef.close(this.addedElement);
  }

  confirmAndClose(): void {
    if (this.professionForm.dirty) {
     this.sweetAlertService.showConfirmationAlert()
      .then((confirmation) => {
        if (confirmation) {
          this.dialogRef.close(this.addedElement);
        }
      });}
    else{
      this.dialogRef.close(this.addedElement);
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.professionForm.dirty) {
      $event.returnValue = true;
    }
  }
}

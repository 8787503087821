import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TranslationLoaderService} from '../core/services/translation-loader.service';
import {DateAdapter} from '@angular/material/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {finalize, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {RegisterService} from '../shared/services/register.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {EmailSentDialogComponent} from './email-sent-dialog.component';
import { MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
// tslint:disable-next-line:max-line-length
const emailREGX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit, OnDestroy {
  images = [
    './assets/img/slides/slide-1.png',
    './assets/img/slides/slide-2.png'
  ];
  registerForm: UntypedFormGroup;
  prefixList;
  passwordValidators = { upper: false, lower: false, length: false, number: false };
  loading = false;
  private unsub$ = new Subject();

  constructor(
    private translateService: TranslateService,
    public translationLoaderService: TranslationLoaderService,
    private dateAdapter: DateAdapter<any>,
    private fb: UntypedFormBuilder,
    private http: HttpClient,
    private registerService: RegisterService,
    private matDialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.registerForm = this.fb.group({
      activityName: [null, Validators.required],
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      email: [null, Validators.compose([Validators.required, Validators.pattern(emailREGX)])],
      password: [null, Validators.compose([
        Validators.required,
        Validators.minLength(6),
        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/)
      ])],
      confirmPassword: [null, Validators.required],
      mobilePrefix: '+39',
      mobile: [null, Validators.compose([Validators.required, Validators.minLength(8), Validators.pattern(/^[0-9]+$/)])]
    }, {validators: this.matchPasswords('password', 'confirmPassword')});
    this.http.get<any[]>('/assets/TEL_PREFIX.json').subscribe(d => this.prefixList = d.map(e => {
      e.label = `${e.country}: ${e.prefix}`;
      return e;
    }));
    this.setUpPasswordValidation();
  }

  changeLang(lang: 'en' | 'it' | 'fr') {
    this.translationLoaderService.setLanguage(lang);
    this.dateAdapter.setLocale(lang);
  }

  matchPasswords(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({mustMatch: true});
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  private setUpPasswordValidation() {
    this.registerForm.get('password').valueChanges.pipe(takeUntil(this.unsub$)).subscribe(v => {
      this.passwordValidators.length = v !== null && v.length >= 6;
      this.passwordValidators.lower = /^(?=.*[a-z])/.test(v);
      this.passwordValidators.upper = /^(?=.*[A-Z])/.test(v);
      this.passwordValidators.number = /^(?=.*[0-9])/.test(v);
    });
  }

  register() {
    if (this.registerForm.invalid) {
      return;
    }
    this.loading = true;
    this.registerService.register(this.registerForm.value).pipe(finalize(() => this.loading = false)).subscribe((res) => {
      if (!res) {
        this.matSnackBar.open(this.translateService.instant('REGISTER.EMAIL_EXISTS'), '✔', {duration: 2000});
      } else {
        this.matDialog.open(EmailSentDialogComponent, {
          width: '450px'
        }).afterClosed().subscribe(() => {
          this.router.navigate(['/login']);
        });
      }
    }, error => {
      this.matSnackBar.open('Server error, contact support.', '❌', {duration: 2000});
    });
  }

  ngOnDestroy(): void {
    this.unsub$.next(void 0);
    this.unsub$.complete();
  }
}

import {Component, OnInit} from '@angular/core';
import {SearchResponse} from '../../../../shared/dto/search-response';
import {BreakpointObserver} from '@angular/cdk/layout';
import {UpdateVersion, UpdateVersionPageRequest} from '../../../../shared/models/update-version';
import {UpdateVersionService} from '../../../../shared/services/update-version.service';

@Component({
  selector: 'app-update-version-list',
  templateUrl: './update-version-list.component.html',
  styleUrls: ['./update-version-list.component.scss']
})
export class UpdateVersionListComponent implements OnInit {

  request: UpdateVersionPageRequest = new UpdateVersionPageRequest();
  updateVersionResponse: SearchResponse<UpdateVersion>;

  selectedUpdateVersion: UpdateVersion;
  isSmallScreen = false;

  constructor(
    private updateVersionService: UpdateVersionService,
    public breakpointObserver: BreakpointObserver
  ) {
    console.log('************************////////////////////////////////////////**********************')
    this.breakpointObserver.observe(['(max-width: 567px)']).subscribe(result => {
      this.isSmallScreen = result.matches;
    });
  }

  ngOnInit(): void {
    this.initRequest();
    this.getUpdateVersions();
  }

  initRequest() {
    this.request = new UpdateVersionPageRequest();
    this.request.page = 1;
    this.request.pageSize = 1000;
    this.request.textSearch = '';
  }

  getUpdateVersions() {
    this.updateVersionService.getLazyUpdateVersions(this.request).subscribe(
      (response) => {
        this.updateVersionResponse = response;
        // tslint:disable-next-line:max-line-length
        this.updateVersionResponse.data = this.updateVersionResponse.data.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()) ;
        console.log('update versions response = ', this.updateVersionResponse.data);

        this.selectedUpdateVersion = this.updateVersionResponse.data[0];
      }
    );
  }

  selectUpdateVersion(updateVersion: UpdateVersion) {
    this.selectedUpdateVersion = updateVersion;
  }

}

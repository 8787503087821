import {Component, OnDestroy, OnInit} from '@angular/core';
import {RegisterService} from '../shared/services/register.service';
import {ActivatedRoute, Router} from '@angular/router';
import {  MatSnackBar} from '@angular/material/snack-bar';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Day, Month, Week, Year} from '../shared/models/week-planning-template';
import moment from 'moment';
import {WorkTime} from '../shared/models/workTime';
import {finalize, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ZipCodeService} from '../shared/services/zip-code.service';
import {ZipCode} from '../shared/models/zip-code';
import {CompanyBusinessService} from '../shared/services/company-business.service';
import {AuthService} from '../shared/services/auth-jwt.service';
import {UserService} from '../shared/services/user.service';
import {TranslationLoaderService} from '../core/services/translation-loader.service';
import {DateAdapter} from '@angular/material/core';
import {TranslateService} from '@ngx-translate/core';
import {SweetAlertService} from "../shared/services/sweet-alert.service";
// tslint:disable-next-line:max-line-length
const patternFiscal = `^([A-Za-z]{6}[0-9lmnpqrstuvLMNPQRSTUV]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9lmnpqrstuvLMNPQRSTUV]{2}[A-Za-z]{1}[0-9lmnpqrstuvLMNPQRSTUV]{3}[A-Za-z]{1})$|([0-9]{11})$`;

@Component({
  selector: 'app-init-account',
  templateUrl: './init-account.component.html',
  styleUrls: ['./init-account.component.scss']
})
export class InitAccountComponent implements OnInit, OnDestroy {
  companyForm: UntypedFormGroup;
  sellPointForm: UntypedFormGroup;
  year: Year;
  months = ['PLANNING_FORM.JANUARY',
    'PLANNING_FORM.FEBRUARY',
    'PLANNING_FORM.MARCH',
    'PLANNING_FORM.APRIL',
    'PLANNING_FORM.MAY',
    'PLANNING_FORM.JUNE',
    'PLANNING_FORM.JULY',
    'PLANNING_FORM.AUGUST',
    'PLANNING_FORM.SEPTEMBER',
    'PLANNING_FORM.OCTOBER',
    'PLANNING_FORM.NOVEMBER',
    'PLANNING_FORM.DECEMBER'];
  private unsubscribe$ = new Subject();
  zipCodeLoading = false;
  zipCodeList: ZipCode[];
  cityFormControl = new UntypedFormControl();
  provinceFormControl = new UntypedFormControl();
  companyBusinesses = [];
  yearPlanning: any;
  loadingInit = false;

  constructor(
    private registerService: RegisterService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private matSnackBar: MatSnackBar,
    private fb: UntypedFormBuilder,
    private zipCodeService: ZipCodeService,
    private companyBusinessService: CompanyBusinessService,
    private authService: AuthService,
    private userService: UserService,
    public translationLoaderService: TranslationLoaderService,
    private dateAdapter: DateAdapter<any>,
    private translateService: TranslateService,
    private sweetAlertService: SweetAlertService
  ) {
  }

  get operatorsForm() {
    return this.sellPointForm.get('operators') as UntypedFormArray;
  }

  get cabinsForm() {
    return this.sellPointForm.get('cabins') as UntypedFormArray;
  }

  ngOnInit() {
    this.initForm();
    this.companyBusinessService.getAllCompanyBusinesses().subscribe(data => {
      this.companyBusinesses = data;
    });
  }

  get countryFormControl() {
    return this.companyForm.get('country');
  }

  private initForm() {
    this.companyForm = this.fb.group({
      businessName: [this.activatedRoute.snapshot.queryParams.companyName, Validators.required],
      ivaCode: [null, Validators.compose([Validators.required, Validators.pattern(patternFiscal)])],
      yourBusiness: [null, Validators.required],
      address: [null, Validators.required],
      street: [null, Validators.required],
      country: [null, Validators.required],
      zipCodeId: [null, Validators.required],
      dealerCode: [null],
      plan: [null]
    });
    this.companyForm.get('zipCodeId').disable();
    this.provinceFormControl.disable();
    this.cityFormControl.disable();
    this.sellPointForm = this.fb.group({
      openingHour: [null, Validators.required],
      closingHour: [null, Validators.required],
      operators: this.fb.array([]),
      cabins: this.fb.array([])
    });
    this.countryFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if (c && !this.countryFormControl.disabled) {
        this.companyForm.get('zipCodeId').enable();
        this.zipCodeLoading = true;
        this.zipCodeService.getAllZipCodesByCountry(c).subscribe(d => {
          this.zipCodeList = d;
          this.zipCodeLoading = false;
        });
      } else if (!c) {
        this.provinceFormControl.setValue(null);
        this.cityFormControl.setValue(null);
        this.companyForm.get('zipCodeId').setValue(null);
        this.companyForm.get('zipCodeId').disable();
      }
    });
    this.companyForm.get('zipCodeId').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(d => {
      this.provinceFormControl.setValue(null);
      this.cityFormControl.setValue(null);
      if (d && this.zipCodeList.length > 0) {
        const zc = this.zipCodeList.filter(e => e.id === d)[0];
        this.setProvinceAndCity(zc);
      }
    });
  }

  setProvinceAndCity(zc) {
    this.provinceFormControl.enable();
    this.cityFormControl.enable();
    this.provinceFormControl.setValue(zc.province);
    this.cityFormControl.setValue(zc.city);
    this.provinceFormControl.disable();
    this.cityFormControl.disable();
  }

  addOperator() {
    if (this.operatorsForm.length === 6) {
      return;
    }
    const ctrl = this.fb.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      planningName: [null, Validators.required],
      username: [null, Validators.required],
      password: [null, Validators.required],
      confirmPassword: [null, Validators.required],
    }, {validators: this.matchPasswords('password', 'confirmPassword')});
    this.operatorsForm.push(ctrl);
  }

  addCabin() {
    if (this.cabinsForm.length === 6) {
      return;
    }
    const ctrl = this.fb.group({
      name: [null, Validators.required],
    });
    this.cabinsForm.push(ctrl);
  }

  setUpYearPlanning() {
    this.year = new Year();
    const year = moment().year();
    this.year.name = year;
    this.year.months = [];

    this.months.forEach((m, index) => {
      const month = new Month();
      month.name = m;
      month.weeks = [];

      const monthNumber = index + 1;
      const firstOfMonth = new Date(year, monthNumber - 1, 1);
      const lastOfMonth = new Date(year, monthNumber, 0);

      let startMonthDay = Number(firstOfMonth.getDay());
      if (startMonthDay === 0) {
        startMonthDay = 7;
      }
      const used = startMonthDay + lastOfMonth.getDate();
      const weekNumber = Math.ceil(used / 7);

      let q = 0;
      for (let i = 0; i < weekNumber; i++) {
        month.weeks[i] = new Week();
        month.weeks[i].month = monthNumber;
        month.weeks[i].year = year;
        month.weeks[i].order = i + 1;
        month.weeks[i].days = [];
        for (let j = 0; j < 7; j++) {
          month.weeks[i].days[j] = new Day();
          if (i === 0) {
            month.weeks[0].days[j].number = j < startMonthDay - 1 ? null : (q += 1);
          } else if (i === weekNumber - 1) {
            month.weeks[weekNumber - 1].days[j].number = j < lastOfMonth.getDay() ? (q += 1) : null;
          } else {
            q += 1;
            month.weeks[i].days[j].number = q;
          }
        }
      }
      if (month.weeks[weekNumber - 1].days[0].number === null) {
        month.weeks.splice(weekNumber - 1, 1);
      }
      this.year.months[index] = month;
    });
    this.year.months.forEach(mont => {
      mont.weeks.forEach(w => {
        w.workTimeDto = this.getPlanning(this.setUpWorkTime(), w);
      });
    });
    this.yearPlanning = {};
    this.yearPlanning.year = this.year.name;
    this.yearPlanning.weeksPlanning = [];
    this.year.months.forEach(m => {
      m.weeks.forEach(w => {
        // @ts-ignore
        this.yearPlanning.weeksPlanning.push(w);
      });
    });
  }

  getPlanning(workTime1: WorkTime, week: Week) {
    const workTime = Object.assign({}, workTime1);
    if (week.days[0].number === null) {
      workTime.mondayDayOff = null;
      workTime.mondayWithBreak = null;
      workTime.mondayMorFrom = null;
      workTime.mondayMorTo = null;
      workTime.mondayAfterFrom = null;
      workTime.mondayAfterTo = null;
    }

    if (week.days[1].number === null) {
      workTime.tuesDayOff = null;
      workTime.tuesWithBreak = null;
      workTime.tuesMorFrom = null;
      workTime.tuesMorTo = null;
      workTime.tuesAfterFrom = null;
      workTime.tuesAfterTo = null;
    }

    if (week.days[2].number === null) {
      workTime.wedDayOff = null;
      workTime.wedWithBreak = null;
      workTime.wedMorFrom = null;
      workTime.wedMorTo = null;
      workTime.wedAfterFrom = null;
      workTime.wedAfterTo = null;
    }

    if (week.days[3].number === null) {
      workTime.thurDayOff = null;
      workTime.thurWithBreak = null;
      workTime.thurMorFrom = null;
      workTime.thurMorTo = null;
      workTime.thurAfterFrom = null;
      workTime.thurAfterTo = null;
    }

    if (week.days[4].number === null) {
      workTime.friDayOff = null;
      workTime.friWithBreak = null;
      workTime.friMorFrom = null;
      workTime.friMorTo = null;
      workTime.friAfterFrom = null;
      workTime.friAfterTo = null;
    }

    if (week.days[5].number === null) {
      workTime.satDayOff = null;
      workTime.satWithBreak = null;
      workTime.satMorFrom = null;
      workTime.satMorTo = null;
      workTime.satAfterFrom = null;
      workTime.satAfterTo = null;
    }

    if (week.days[6].number === null) {
      workTime.sunDayOff = null;
      workTime.sunWithBreak = null;
      workTime.sunMorFrom = null;
      workTime.sunMorTo = null;
      workTime.sunAfterFrom = null;
      workTime.sunAfterTo = null;
    }

    return workTime;
  }

  setUpWorkTime() {
    const from = this.sellPointForm.get('openingHour').value;
    const to = this.sellPointForm.get('closingHour').value;
    const workTime: any = {};
    workTime.mondayMorFrom = from;
    workTime.mondayMorTo = to;
    workTime.tuesMorFrom = from;
    workTime.tuesMorTo = to;
    workTime.wedMorFrom = from;
    workTime.wedMorTo = to;
    workTime.thurMorFrom = from;
    workTime.thurMorTo = to;
    workTime.friMorFrom = from;
    workTime.friMorTo = to;
    workTime.satMorFrom = from;
    workTime.satMorTo = to;
    workTime.sunMorFrom = from;
    workTime.sunMorTo = to;
    return workTime;
  }

  initAccount() {
    if (this.sellPointForm.invalid) {
      return;
    }
    if (this.sellPointForm.value.operators.length === 0 && this.sellPointForm.value.cabins.length === 0) {
      this.matSnackBar.open(this.translateService.instant('INIT_ACCOUNT.ADD_OPERATOR_OR_CABIN'), 'Ok');
      return;
    }
    let msg;
    if (this.sellPointForm.value.cabins.length === 0) {
      msg = 'INIT_ACCOUNT.DID_NOT_ADD_CABIN';
    } else if (this.sellPointForm.value.operators.length === 0) {
      msg = 'INIT_ACCOUNT.DID_NOT_ADD_OPERATOR';
    }
    console.log(msg, this.sellPointForm.value);
    this.sweetAlertService.warning(this.translateService.instant(msg)).then(u => {
      if (u.value) {
        this.setUpYearPlanning();
        const request = {
          key: this.activatedRoute.snapshot.paramMap.get('mailKey'),
          ...this.companyForm.value,
          ...this.sellPointForm.value,
          yearPlanning: this.yearPlanning
        };
        this.loadingInit = true;
        this.registerService.initAccount(request).pipe(finalize(() => this.loadingInit = false)).subscribe((resp) => {
          if (!resp.success) {
            this.matSnackBar.open(
              this.translateService.instant('INIT_ACCOUNT.USERNAME_NOT_AVAILABLE', {username: resp.username}),
              'Ok',
              {duration: 5000}
            );
            return;
          }
          this.authService.loginWithEmailKey(this.activatedRoute.snapshot.paramMap.get('mailKey')).subscribe(() => {
            this.userService.identity().subscribe((u) => {
              this.userService.saveUser(u, false);
              if (u.authorities.some(e => e === 'OWNER')) {
                this.router.navigate(['/admin/owner']);
                return;
              }
              if (u.authorities.some(e => e === 'GROUP') && !u.sellPointId) {
                this.router.navigate(['/admin']);
                return;
              }
              if (u.authorities.some(e => e === 'GROUP') && u.sellPointId) {
                this.router.navigate(['/']);
                return;
              }
              this.router.navigate(['/']);
            });
          });
        }, err => {
          if(err.error.message.includes("uc_vat_number")) {
            this.matSnackBar.open(this.translateService.instant('INIT_ACCOUNT.VAT_NUMBER_NOT_AVAILABLE',
              {vatNumber: this.companyForm.value.ivaCode}), 'Ok', {duration: 5000});
          } else {
            this.matSnackBar.open(this.translateService.instant('INIT_ACCOUNT.COMPANY_NAME_NOT_AVAILABLE',
              {businessName: this.companyForm.value.businessName}), 'Ok', {duration: 5000});
          }
        });
      }
    });
  }

  capSearch(term: string, item: ZipCode) {
    term = term.toLowerCase();
    return item.city.toLowerCase().startsWith(term) || item.cap.toLowerCase().startsWith(term);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(void 0);
    this.unsubscribe$.complete();
  }

  matchPasswords(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({mustMatch: true});
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  changeLang(lang: 'en' | 'it' | 'fr') {
    this.translationLoaderService.setLanguage(lang);
    this.dateAdapter.setLocale(lang);
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthenticationComponent} from './authentication.component';
import {RouterModule, Routes} from '@angular/router';
import {MatLegacyButtonModule} from '@angular/material/legacy-button';
import {MatLegacyCheckboxModule} from '@angular/material/legacy-checkbox';
import {NgbCarouselModule} from '@ng-bootstrap/ng-bootstrap';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {BarcodeAuthenticationComponent} from './barcode-authentication/barcode-authentication.component';
import {MatIconModule} from '@angular/material/icon';

const routes: Routes = [
  {
    path: '',
    component: AuthenticationComponent
  }
];

@NgModule({
  declarations: [AuthenticationComponent, BarcodeAuthenticationComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    SharedModule.forChild(),
    MatLegacyButtonModule,
    MatLegacyCheckboxModule,
    NgbCarouselModule,
    TranslateModule,
    MatSnackBarModule,
    MatIconModule
  ]
})
export class AuthenticationModule {
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SmsCompaignComponent} from './sms-compaign.component';
import {Routes, RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {MatLegacyButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatLegacyDialog as MatDialog, MatLegacyDialogModule} from '@angular/material/legacy-dialog';
import {SubheaderModule} from 'src/app/content/layout/components/subheader/subheader.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {DateTimeModule, DateTimeIntl} from 'nxt-pick-datetime';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {DefaultIntl} from './default-intl';
import {PickerModule} from '@ctrl/ngx-emoji-mart';
import {NativeDateTimeModule} from "nxt-pick-datetime/native-adapter";

const routes: Routes = [
  {path: '', component: SmsCompaignComponent}
];

@NgModule({
  declarations: [
    SmsCompaignComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgbModule,
    TranslateModule,
    MatLegacyButtonModule,
    MatIconModule,
    SubheaderModule,
    NgxDatatableModule,
    MatTableModule,
    FormsModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    NgSelectModule,
    MatDatepickerModule,
    MatLegacyDialogModule,
    DateTimeModule,
    NativeDateTimeModule,
    PickerModule,
  ], providers: [
    {provide: DateTimeIntl, useClass: DefaultIntl},
  ]
})
export class SmsCompaignModule {
}

import {Injectable, ApplicationRef, ComponentFactoryResolver, ComponentRef, Injector} from '@angular/core';
import {MovementManagementFormComponent} from '../movement-management-form/movement-management-form.component';

@Injectable()
export class MovementFormService {
  dialogComponentRef: ComponentRef<MovementManagementFormComponent>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {
  }

  openForm(container, data?: any) {
    // close form if opened
    if (this.dialogComponentRef) {
      this.appRef.detachView(this.dialogComponentRef.hostView);
      this.dialogComponentRef.destroy();
    }
    // open form component and pass data to it
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(MovementManagementFormComponent);
    componentFactory.create(this.injector);
    const componentRef = container.createComponent(componentFactory);
    componentRef.instance.data = data;
    componentRef.instance.appRef = this.appRef;
    componentRef.instance.dialogComponentRef = componentRef;

    // return a ref of the opened form component
    return this.dialogComponentRef = componentRef;
  }
}

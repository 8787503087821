import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { PaymentService } from 'src/app/shared/services/payment.service';
import { FidelityService } from 'src/app/shared/services/fidelity.service';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import moment from 'moment';
import { QuoteService } from 'src/app/shared/services/quote.service';

@Component({
  selector: 'app-fiscal-reports',
  templateUrl: './fiscal-reports.component.html',
  styleUrls: ['./fiscal-reports.component.scss']
})
export class FiscalReportsComponent implements OnInit {

  searchFormControl: UntypedFormGroup;
  salesWthtDiscount = null;
  discount = null;
  cash = null;
  creditCard = null;
  transfer = null;
  cheque = null;
  giftCard = null;
  reloadCard = null;
  subscriptions = null;
  sumSubscriptions = 0;
  sumGiftCard = 0;
  sumReloadCard = 0;
  sumQuotes = 0;
  reso = 0;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private paymentService: PaymentService,
    private fidelityService: FidelityService,
    private quoteService: QuoteService) { }

  ngOnInit() {
    this.searchFormControl = new UntypedFormGroup({
      dateFrom: new UntypedFormControl(null),
      dateTo: new UntypedFormControl(null)
    });
    this.breadcrumbService.sendBreadcrumb(['FISCAL_REPORT']);
    this.filter();
  }

  filter() {

    let dateFrom = null;
    let dateTo = null;

    if (this.searchFormControl.get('dateFrom').value) {
      dateFrom = this.searchFormControl.get('dateFrom').value ?
        moment(this.searchFormControl.get('dateFrom').value).format('YYYY-MM-DDTHH:mm:ss') : null;
    }
    if (this.searchFormControl.get('dateTo').value) {
      dateTo = this.searchFormControl.get('dateTo').value ?
        moment(this.searchFormControl.get('dateTo').value).format('YYYY-MM-DDTHH:mm:ss') : null;
    }

    this.paymentService.salesWithoutDiscount({
      d1: dateFrom,
      d2: dateTo
    }).subscribe(r => {
      this.salesWthtDiscount = r;
    });
    this.paymentService.discount({
      d1: dateFrom,
      d2: dateTo
    }).subscribe(r => {
      this.discount = r;
    });
    this.paymentService.paymentPerX({
      d1: dateFrom,
      d2: dateTo,
      p: 'CASH'
    }).subscribe(r => {
      this.cash = r;
    });
    this.paymentService.paymentPerX({
      d1: dateFrom,
      d2: dateTo,
      p: 'CHEQUE'
    }).subscribe(r => {
      this.cheque = r;
    });
    this.paymentService.paymentPerX({
      d1: dateFrom,
      d2: dateTo,
      p: 'CREDIT_CARD'
    }).subscribe(r => {
      this.creditCard = r;
    });
    this.paymentService.paymentPerX({
      d1: dateFrom,
      d2: dateTo,
      p: 'TRANSFER'
    }).subscribe(r => {
      this.transfer = r;
    });
    this.fidelityService.totalCardSold({
      d1: dateFrom,
      d2: dateTo,
      p: 'GIFT_CARD'
    }).subscribe(r => {
      this.giftCard = r;
    });
    this.fidelityService.totalCardSold({
      d1: dateFrom,
      d2: dateTo,
      p: 'PREPAID_WITH_DISCOUNT'
    }).subscribe(r => {
      this.reloadCard = r;
    });
    this.paymentService.totalSubscription({
      d1: dateFrom,
      d2: dateTo
    }).subscribe(r => {
      this.subscriptions = r;
    });
    this.paymentService.sumTotalSubscription({
      d1: dateFrom,
      d2: dateTo
    }).subscribe(r => {
      this.sumSubscriptions = r;
    });

    this.fidelityService.sumCardSold({
      d1: dateFrom,
      d2: dateTo,
      p: 'GIFT_CARD'
    }).subscribe(r => {
      this.sumGiftCard = r;
    });
    this.fidelityService.sumCardSold({
      d1: dateFrom,
      d2: dateTo,
      p: 'PREPAID_WITH_DISCOUNT'
    }).subscribe(r => {
      this.sumReloadCard = r;
    });
    this.quoteService.sumQuotes({
      d1: dateFrom,
      d2: dateTo
    }).subscribe(r => {
      this.sumQuotes = r;
    });
    this.paymentService.totalReso({
      d1: dateFrom,
      d2: dateTo,
    }).subscribe(r => {
      this.reso = r;
    });
  }

}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ng-select.ng-select-single .ng-select-containe {
  height: 100% !important;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 2500;
  background-color: rgba(0, 2, 17, 0.22);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.loading-overlay h1 {
  font-size: 2rem;
}

.operation-card {
  height: 170px;
  background: #dfe4fd;
}

.operation-card:hover {
  cursor: pointer;
  background: #99A8D5 !important;
}

.ip-card {
  display: flex;
  align-items: flex-end;
}
.ip-card button {
  margin-left: 10px;
}

@media screen and (max-width: 450px) {
  .ip-card {
    flex-direction: column;
    align-items: unset;
  }
  .ip-card button {
    margin-top: 10px;
    margin-left: 0;
  }
  .operation-card h4 {
    font-size: 1.2rem !important;
  }
}
::ng-deep .ng-select-rt-config.ng-dropdown-panel {
  z-index: 1500;
}`, "",{"version":3,"sources":["webpack://./src/app/content/pages/configurations/rt/rt.component.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF;;AACA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,aAAA;EACA,YAAA;EACA,aAAA;EACA,sCAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;AAEF;AAAE;EACE,eAAA;AAEJ;;AAEA;EACE,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,8BAAA;AACF;;AACA;EACE,aAAA;EACA,qBAAA;AAEF;AADE;EACE,iBAAA;AAGJ;;AACA;EACE;IACE,sBAAA;IACA,kBAAA;EAEF;EADE;IACE,gBAAA;IACA,cAAA;EAGJ;EAAA;IACE,4BAAA;EAEF;AACF;AAAA;EACE,aAAA;AAEF","sourcesContent":[".ng-select.ng-select-single .ng-select-containe{\r\n  height: 100% !important;\r\n}\r\n.loading-overlay {\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  height: 100vh;\r\n  width: 100vw;\r\n  z-index: 2500;\r\n  background-color: rgba(0, 2, 17, 0.22);\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  flex-direction: column;\r\n\r\n  h1 {\r\n    font-size: 2rem;\r\n  }\r\n}\r\n\r\n.operation-card {\r\n  height: 170px;\r\n  background: #dfe4fd;\r\n}\r\n\r\n.operation-card:hover {\r\n  cursor: pointer;\r\n  background: #99A8D5 !important;\r\n}\r\n.ip-card {\r\n  display: flex;\r\n  align-items: flex-end;\r\n  button {\r\n    margin-left: 10px;\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 450px){\r\n  .ip-card {\r\n    flex-direction: column;\r\n    align-items: unset;\r\n    button {\r\n      margin-top: 10px;\r\n      margin-left: 0;\r\n    }\r\n  }\r\n  .operation-card h4{\r\n    font-size: 1.2rem !important;\r\n  }\r\n}\r\n::ng-deep .ng-select-rt-config.ng-dropdown-panel {\r\n  z-index: 1500;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

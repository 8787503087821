import {Pipe, PipeTransform} from '@angular/core';
import {GeneralOption, SectionType} from "../../../../../../shared/models/anamnesi/anamnesi.model";

@Pipe({
  name: 'filterGeneralOptions'
})
export class FilterGeneralOptionsPipe implements PipeTransform {

  transform(value: GeneralOption[], section: 'TIPO_DI_PELLE' | 'LEGENDA_INESTETISMI' | 'LEGENDA_DEGLI_INESTETISMI'): any {
    return value.filter(u => u.section === section);
  }

}

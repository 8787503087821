import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminLayoutComponent } from './admin-layout.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { RouterModule } from '@angular/router';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '../../shared/shared.module';
import {MatIconModule} from "@angular/material/icon";

@NgModule({
  declarations: [
    AdminLayoutComponent,
    NavbarComponent
  ],
  exports: [
    NavbarComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        NgbDropdownModule,
        TranslateModule,
        SharedModule,
        MatIconModule
    ]
})
export class AdminLayoutModule { }

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table thead th {
  padding-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/administrative-content/pages/owner/components/license-configuration/license-configuration.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF","sourcesContent":["table thead th {\r\n  padding-right: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

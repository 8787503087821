import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Payment} from '../models/payment';
import {HttpClient} from '@angular/common/http';
import {SearchResponse} from '../dto/search-response';
import {LazyRequest} from '../dto/lazy-request';
import {Quote} from '../models/quote';
import {PaymentResponse} from '../models/payment-response';
import {QuoteEntry} from '../models/quote-entry';

@Injectable()
export class QuoteService {
  QUOTE_API = environment.api + '/quotes';

  constructor(private http: HttpClient) {
  }

  createWithRt(quote: Payment) {
    return this.http.post<PaymentResponse>(`${this.QUOTE_API}/fiscalrtticket`, quote);
  }
  createWithCourtesyRt(quote: Payment) {
    return this.http.post<PaymentResponse>(`${this.QUOTE_API}/courtesyrtticket`, quote);
  }

  search(request: QuotePageRequest) {
    return this.http.post<SearchResponse<Quote>>(`${this.QUOTE_API}/filter`, request);
  }


  getReport(id) {
    return this.http.get(`${this.QUOTE_API}/report/${id}`, {responseType: 'arraybuffer'});
  }

  getTicket(id) {
    return this.http.get<PaymentResponse>(`${this.QUOTE_API}/fiscalrtticket/${id}`);
  }

  getQuoteList(page) {
    return this.http.get<any>(`${this.QUOTE_API}/quote-list/${page}`);
  }

  getQuoteEntries(id, type) {
    if ('payment' === type) {
      return this.http.get<QuoteEntry[]>(`${this.QUOTE_API}/get-entries/${id}`);
    }
    return this.http.get<QuoteEntry[]>(`${this.QUOTE_API}/quote-entries/${id}`);
  }

  sumQuotes(request: any) {
    return this.http.post<number>(`${this.QUOTE_API}/sum-quote`, request);
  }
}

class QuotePageRequest extends LazyRequest {

}

import {Component, Inject, Input, OnInit} from '@angular/core';
import {StockHistory} from '../../../../../shared/models/stock-history';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Stock} from '../../../../../shared/models/stock';

@Component({
  selector: 'app-stock-history',
  templateUrl: './stock-history.component.html',
  styleUrls: ['./stock-history.component.scss']
})
export class StockHistoryComponent implements OnInit {
  @Input() data: Stock;
  rows: StockHistory[] = [];
  columns = [
    'DATA_TABLE.DATE',
    'DATA_TABLE.DESCRIPTION',
    'DATA_TABLE.PURCHASE_COST',
    'DATA_TABLE.PRICE',
    'DATA_TABLE.QUANTITY',
    'DATA_TABLE.TOTAL_PURCHASE_COST',
    // 'DATA_TABLE.GIFT',
    'DATA_TABLE.CAUSAL',
  ];

  constructor() {
  }

  ngOnInit() {
    this.rows = this.data.histories;
  }

}

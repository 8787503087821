import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ZipCode} from '../models/zip-code';
import {environment} from '../../../environments/environment';

@Injectable()
export class ZipCodeService {
  private API = `${environment.api}/zip-codes`;

  constructor(private http: HttpClient) {
  }

  getAllZipCodesByCountry(country) {
    return this.http.get<ZipCode[]>(`${this.API}/${country}`);
  }

  getLazyZipCodesByCountry(request) {
    return this.http.post<ZipCode[]>(`${this.API}/filter`, request);
  }

  getProvinces() {
    return this.http.get<string[]>(`${this.API}/get-provinces`);
  }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ng-select {
  font-size: 14px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/administrative-content/pages/admin-module/components/operator-mgm/operator-mgm-form-dialog/operator-mgm-form-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;AACF","sourcesContent":[".ng-select {\r\n  font-size: 14px !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProviderManagementFormComponent} from './provider-management-form/provider-management-form.component';
import {MatLegacyDialogModule as MatLegacyDialogModule} from '@angular/material/legacy-dialog';
import {   MatSnackBarModule } from '@angular/material/snack-bar';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {   MatInputModule } from '@angular/material/input';
import {   MatFormFieldModule } from '@angular/material/form-field';
import {   MatPaginatorModule } from '@angular/material/paginator';
import {   MatListModule } from '@angular/material/list';
import {   MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import {MatLegacyButtonModule} from '@angular/material/legacy-button';
import {   MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {NgbDropdownModule, NgbPaginationModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {RouterModule, Routes} from '@angular/router';
import {ProviderManagementComponent} from './provider-management.component';
import {ProviderFormService} from './services/provider-form.service';
import {SubheaderModule} from '../../../layout/components/subheader/subheader.module';
import {SharedModule} from '../../../../shared/shared.module';
import {NgSelectModule} from '@ng-select/ng-select';
import {ProviderCreateModule} from '../../../../shared/provider-create/provider-create.module';

const routes: Routes = [
  {path: '', component: ProviderManagementComponent}
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatLegacyButtonModule,
        MatIconModule,
        SubheaderModule,
        NgxDatatableModule,
        MatTableModule,
        MatListModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatRippleModule,
        NgbDropdownModule,
        TranslateModule,
        ReactiveFormsModule,
        MatSnackBarModule,
        MatLegacyDialogModule,
        SharedModule.forChild(),
        NgbPaginationModule,
        MatProgressSpinnerModule,
        NgSelectModule,
        NgbTooltipModule,
        ProviderCreateModule
    ],
    providers: [
        ProviderFormService
    ],
    declarations: [
        ProviderManagementComponent,
        ProviderManagementFormComponent
    ]
})
export class ProviderManagementModule {
}

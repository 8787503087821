import {Component, ElementRef, HostListener, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ZipCode} from '../../../../../../../shared/models/zip-code';
import {Subject} from 'rxjs';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Center} from '../../../../../../../shared/models/center';
import {HttpClient} from '@angular/common/http';
import IMask from 'imask';
import {ZipCodeService} from '../../../../../../../shared/services/zip-code.service';
import {takeUntil} from 'rxjs/operators';
import Inputmask from 'inputmask';
import {SellPointService} from '../../../../../../../shared/services/sell-point.service';
import {SellPoint} from '../../../../../../../shared/models/sell-point';
import {TranslateService} from '@ngx-translate/core';
import {SweetAlertService} from '../../../../../../../shared/services/sweet-alert.service';
// tslint:disable-next-line:max-line-length
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@Component({
  selector: 'app-sell-point-form',
  templateUrl: './sell-point-form.component.html',
  styleUrls: ['./sell-point-form.component.scss']
})
export class SellPointFormComponent implements OnInit {
  @ViewChild('startTimeElm', { static: true }) startTimeElm: ElementRef;
  @ViewChild('finishTimeElm', { static: true }) finishTimeElm: ElementRef;
  sellPointForm: UntypedFormGroup;
  prefixList: any;
  submitted = false;
  zipCodeLoading = false;
  zipCodeList: ZipCode[] = [];
  unsubscribe$ = new Subject();
  countryFormControl = new UntypedFormControl(null);
  provinceFormControl = new UntypedFormControl();
  cityFormControl = new UntypedFormControl();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { editMode: boolean, center?: Center, sellPoint: SellPoint, siegeId: number, siegeName: string },
    private http: HttpClient,
    private dialogRef: MatDialogRef<SellPointFormComponent>,
    private sellPointService: SellPointService,
    private sweetAlertService: SweetAlertService,
    private translate: TranslateService,
    private zipCodeService: ZipCodeService
  ) {
  }

  ngOnInit() {
    this.buildSellPointForm();
    this.zipCodeManagement();
    if (this.data.editMode) {
      this.sellPointForm.patchValue(this.data.sellPoint);
      this.sellPointForm.get('phonePrefix').setValue(this.data.sellPoint.phonePrefix || '+39');
      this.sellPointForm.get('mobilePrefix').setValue(this.data.sellPoint.mobilePrefix || '+39');
      this.sellPointForm.get('startHour').setValue(this.setTimeFromString(this.data.sellPoint.startHour));
      this.sellPointForm.get('finishHour').setValue(this.setTimeFromString(this.data.sellPoint.finishHour));
      if (this.data.sellPoint.zipCode) {
        this.countryFormControl.setValue(this.data.sellPoint.zipCode ? this.data.sellPoint.zipCode.country : null);
        this.zipCodeService.getAllZipCodesByCountry(this.data.sellPoint.zipCode.country).subscribe(d => {
          this.zipCodeList = d;
          const zc = d.filter(e => e.id === this.data.sellPoint.zipCode.id)[0];
          this.sellPointForm.get('zipCodeId').enable();
          this.sellPointForm.get('zipCodeId').setValue(this.data.sellPoint.zipCode.id);
          this.setProvinceAndCity(zc);
        });
      }
    }
    this.http.get<any[]>('/assets/TEL_PREFIX.json').subscribe(d => this.prefixList = d.map(e => {
      e.label = `${e.country}: ${e.prefix}`;
      return e;
    }));

    if (!this.data.editMode) {
      this.sellPointForm.get('name').setValue(this.data.center.companyName);
      this.sellPointForm.get('address').setValue(this.data.center.address);
      this.sellPointForm.get('street').setValue(this.data.center.street);
      this.sellPointForm.get('email').setValue(this.data.center.email);
      this.sellPointForm.get('phonePrefix').setValue(this.data.center.phonePrefix);
      this.sellPointForm.get('phone').setValue(this.data.center.phone);
      this.sellPointForm.get('mobilePrefix').setValue(this.data.center.mobilePrefix);
      this.sellPointForm.get('mobile').setValue(this.data.center.mobile);
      this.sellPointForm.get('contact').setValue(this.data.center.contact);

      if (this.data.center.zipCode) {
        this.countryFormControl.setValue(this.data.center.zipCode ? this.data.center.zipCode.country : null);
        this.zipCodeService.getAllZipCodesByCountry(this.data.center.zipCode.country).subscribe(d => {
          this.zipCodeList = d;
          const zc = d.filter(e => e.id === this.data.center.zipCode.id)[0];
          this.sellPointForm.get('zipCodeId').enable();
          this.sellPointForm.get('zipCodeId').setValue(this.data.center.zipCode.id);
          this.setProvinceAndCity(zc);
        });
      }
    }

    this.setTimerMasks();
  }


  private zipCodeManagement() {
    this.sellPointForm.get('zipCodeId').disable();
    this.provinceFormControl.disable();
    this.cityFormControl.disable();
    this.countryFormControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(c => {
      if (c && !this.countryFormControl.disabled) {
        this.sellPointForm.get('zipCodeId').enable();
        this.zipCodeLoading = true;
        this.zipCodeService.getAllZipCodesByCountry(c).subscribe(d => {
          this.zipCodeList = d;
          this.zipCodeLoading = false;
        });
      } else if (!c) {
        this.provinceFormControl.setValue(null);
        this.cityFormControl.setValue(null);
        this.sellPointForm.get('zipCodeId').setValue(null);
        this.sellPointForm.get('zipCodeId').disable();
      }
    });
    this.sellPointForm.get('zipCodeId').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(d => {
      this.provinceFormControl.setValue(null);
      this.cityFormControl.setValue(null);
      if (d && this.zipCodeList.length > 0) {
        const zc = this.zipCodeList.filter(e => e.id === d)[0];
        this.setProvinceAndCity(zc);
      }
    });
  }

  /**
   * zip code setup
   */
  private setProvinceAndCity(zc) {
    this.provinceFormControl.enable();
    this.cityFormControl.enable();
    this.provinceFormControl.setValue(zc.province);
    this.cityFormControl.setValue(zc.city);
    this.provinceFormControl.disable();
    this.cityFormControl.disable();
  }

  private buildSellPointForm() {
    const sDate = new Date();
    sDate.setHours(8);
    sDate.setMinutes(30);
    const fDate = new Date();
    fDate.setHours(19);
    fDate.setMinutes(30);
    this.sellPointForm = new UntypedFormGroup({
      id: new UntypedFormControl(),
      name: new UntypedFormControl(null, Validators.required),
      address: new UntypedFormControl(null),
      street: new UntypedFormControl(null),
      zipCodeId: new UntypedFormControl(null),
      phone: new UntypedFormControl(null, Validators.pattern('^[0-9]{8}[0-9]*')),
      phonePrefix: new UntypedFormControl('+39'),
      mobile: new UntypedFormControl(null, Validators.pattern('^[0-9]{8}[0-9]*')),
      mobilePrefix: new UntypedFormControl('+39'),
      email: new UntypedFormControl(null, Validators.pattern(EMAIL_REGEX)),
      contact: new UntypedFormControl(null),
      planningType: new UntypedFormControl(null),
      startHour: new UntypedFormControl(sDate),
      finishHour: new UntypedFormControl(fDate),
      description: new UntypedFormControl(null),
      note: new UntypedFormControl(),
      visibleForMobile: new UntypedFormControl(false),
      siege: new UntypedFormControl(false),
    });


    Object.keys(this.sellPointForm.controls).forEach(e => {
      if (this.sellPointForm.get(e).getError('required')) {
        const el = document.querySelector('[formControlName="' + e + '"]');
        el.classList.add('required-field');
      }
    });
  }

  private setTimerMasks() {
    Inputmask('datetime', {
      inputFormat: 'HH:MM',
      placeholder: '--:--'
    }).mask(this.startTimeElm.nativeElement);
    Inputmask('datetime', {
      inputFormat: 'HH:MM',
      placeholder: '--:--'
    }).mask(this.finishTimeElm.nativeElement);
  }

  saveSellPoint() {
    this.submitted = true;
    if (!this.sellPointForm.valid) {
      this.sweetAlertService.notification(this.translate.instant('DIALOG.INSERT_ALL_MANDATORY_INFORMATION')).then(e => {
      });
      return;
    }
    if (this.sellPointForm.value.siege && this.data.siegeId && this.sellPointForm.value.id !== this.data.siegeId) {
      this.sweetAlertService.warning(this.translate.instant('ADMIN.SELL_POINT.SIEGE_ALREADY_EXIST', {name: `${this.data.siegeName}`})).then(u => {
        if (u.value) {
          this.save();
        }
      });
    } else {
      this.save();
    }
  }

  save() {
    if (!this.data.editMode) {
      this.sellPointService.addSellPoint(this.sellPointForm.value, this.data.center.id).subscribe(d => {
        this.dialogRef.close(d);
      });
    } else {
      this.sellPointService.editSellPoint(this.sellPointForm.value).subscribe(d => {
        this.dialogRef.close(d);
      });
    }
  }

  /*@HostListener('document:keypress', ['$event'])
  SubmitToFormOnEnter($event: KeyboardEvent) {
    console.log($event);
  }*/
  setTimeFromString(str) {
    if (!str) {
      return;
    }
    const s = str.substr(0, 5).split(':');
    const d = new Date();
    d.setHours(Number(s[0]));
    d.setMinutes(Number(s[1]));
    return d;
  }
}

import {Component, OnInit} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {OperatorMgmFormDialogComponent} from './operator-mgm-form-dialog/operator-mgm-form-dialog.component';
import {OperatorMgmService} from '../../../../../shared/services/operator-mgm.service';
import {SellPoint} from '../../../../../shared/models/sell-point';
import {Operator} from '../../../../../shared/models/operator';
import {TranslateService} from '@ngx-translate/core';
import {SweetAlertService} from '../../../../../shared/services/sweet-alert.service';
import {ProductBarcodeType} from '../../../../../shared/enum/product-barcode-type';
import {LicenseConfigurationService} from '../../../../../shared/services/license-configuration.service';

@Component({
  selector: 'app-user-mgm-dialog',
  templateUrl: './operator-mgm.component.html',
  styleUrls: ['./operator-mgm.component.scss']
})
export class OperatorMgmComponent implements OnInit {
  columns = [
    '',
    'DATA_TABLE.USERNAME',
    'DATA_TABLE.FIRST_NAME',
    'DATA_TABLE.LAST_NAME',
    'DATA_TABLE.MOBILE',
    'DATA_TABLE.ROLE',
    'DATA_TABLE.SELL_POINTS',
    'DATA_TABLE.STATUS'
  ];
  operatorList: { account: Operator, sellPoints: SellPoint[] }[] = [];
  loading = false;
  private selectedType: any;
  types = [];
  dialogRef: any;
  maxOperators: number;
  countOperators: number;
  checkOpCount = true;

  constructor(
    private matDialog: MatDialog,
    private translate: TranslateService,
    private sweetAlertService: SweetAlertService,
    private operatorService: OperatorMgmService,
    private licenseService: LicenseConfigurationService
  ) {
  }

  ngOnInit() {
    this.loading = true;
    this.checkOpCount = true;
    this.operatorService.getAllOperators().subscribe(d => {
      this.operatorList = d;
      this.operatorList.forEach(op => {
        op.sellPoints.map(s => s.name = s.siege ? 'S-' + s.name : s.name);
      });
      this.loading = false;
    });
    this.licenseService.getCurrentGroupMaxOperators().subscribe(d => {
      this.maxOperators = d.maxOperators;
      this.countOperators = d.countOperators;
      this.checkOpCount = false;
    });
  }

  addNewOperator() {
    const dialogRef = this.matDialog.open(OperatorMgmFormDialogComponent, {
      width: '1200px',
      disableClose: true,
      data: {editMode: false}
    });
    dialogRef.afterClosed().subscribe(v => {
      if (v) {
        this.ngOnInit()
      }
    });
  }

  editOperator(operatorSellPoint) {
    const dialogRef = this.matDialog.open(OperatorMgmFormDialogComponent, {
      width: '1200px',
      disableClose: true,
      data: {editMode: true, operator: operatorSellPoint.account, sellPoints: operatorSellPoint.sellPoints}
    });
    dialogRef.afterClosed().subscribe(v => {
      if (v) {
        this.loading = true;
        this.operatorService.getAllOperators().subscribe(d => {
          this.operatorList = d;
          this.loading = false;
        });
      }
    });
  }

  printOperatorEan(op, content) {
    if (!op.account.barCode) {
      this.sweetAlertService.danger(this.translate.instant('DIALOG.NO_EAN_FOR_THIS_OPERATOR'));
      return;
    }
    this.dialogRef = this.matDialog.open(content, {minWidth: 400});
    this.dialogRef.afterClosed().subscribe(d => {
      const request = {
        description: op.account.firstName + ' ' + op.account.lastName,
        barCodes: [op.account.barCode],
        ticketQuantity: 1,
        type: this.selectedType,
      };
      this.operatorService.printEan(request).subscribe(data => {
        const downloadUrl = window.URL.createObjectURL(data.body);
        window.open(downloadUrl);
      }, error => {
        this.sweetAlertService.notification(this.translate.instant('PAYMENT.PRINT_FAILED'));
      });
    });
  }

  translateTypes() {
    this.types = [
      {description: this.translate.instant('PRODUCT_FORM.' + ProductBarcodeType.CONTINUOUS_40_30), id: ProductBarcodeType.CONTINUOUS_40_30},
      {
        description: this.translate.instant('PRODUCT_FORM.' + ProductBarcodeType.CONTINUOUS_63_37),
        id: ProductBarcodeType.CONTINUOUS_63_37
      }
    ];
  }

  delete(op: Operator) {
    this.sweetAlertService.warning(this.translate.instant('DIALOG.YOU_WANT_TO_DELETE') + ' ' + op.username)
      .then(res => {
        if (res.value) {
          this.operatorService.deleteOperator(op.id).subscribe(d => {
            if (d.status === 200) {
              this.sweetAlertService.success(this.translate.instant('DIALOG.DELETE_SUCCESS'));
              this.ngOnInit();
            }
          }, err => {
            this.sweetAlertService.danger(this.translate.instant('DIALOG.CANNOT_DELETE') + ' ' + op.username);
          });
        }
      });
  }


  changeOperatorState($event: MouseEvent, element: any) {
    $event.cancelBubble = true;
    $event.stopPropagation();
    let msg = '';
    if (element.active) {
      msg = this.translate.instant('DIALOG.YOU_WANT_TO_DESACTIVE');
    } else {
      msg = this.translate.instant('DIALOG.YOU_WANT_TO_ACTIVE');
    }
    this.sweetAlertService.warning(msg + ' ' +
      element.firstName + ' ' + element.lastName)
      .then(res => {
        if (res.value) {
          this.operatorService.changeOperatorActiveState(element.id).subscribe(d => {
            if (d.status === 200) {
              element.active = !element.active;
              this.operatorService.getAllOperators();
            }
          });
        }
      }, err => {
      });
  }

}

export class Anamnesi {
  id: number;
  uuid: string;
  generalOptions: GeneralOption[];
  anamnesiGenerale: Question[];
  tipoDiPelle: Question;
  esameObiettivo: Question[];
  viso: GeneralOptionAnswer[];
  faceTreatments: AnamnesiTreatments;
  faceTreatmentsEvaluations: TreatmentsEvaluations;
  faceProducts: AnamnesiProducts;
  faceNote: string;
  corpo: GeneralOptionAnswer[];
  bodyMeasurements: BodyMeasurements;
  height: number;
  weight: number;
  bodyTreatments: AnamnesiTreatments;
  bodyTreatmentsEvaluations: TreatmentsEvaluations;
  bodyProducts: AnamnesiProducts;
}

export  interface BodyMeasurements {
  id: number;

  measurementsPerHour1: number;
  leftMeasurement1: number;
  rightMeasurement1: number;

  measurementsPerHour2: number;
  leftMeasurement2: number;
  rightMeasurement2: number;

  measurementsPerHour3: number;
  leftMeasurement3: number;
  rightMeasurement3: number;

  measurementsPerHour4: number;
  leftMeasurement4: number;
  rightMeasurement4: number;

  measurementsPerHour5: number;
  leftMeasurement5: number;
  rightMeasurement5: number;

  measurementsPerHour6: number;
  leftMeasurement6: number;
  rightMeasurement6: number;

  measurementsPerHour7: number;
  leftMeasurement7: number;
  rightMeasurement7: number;

  measurementsPerHour8: number;
  leftMeasurement8: number;
  rightMeasurement8: number;

  measurementsPerHour9: number;
  leftMeasurement9: number;
  rightMeasurement9: number;

  measurementsPerHour10: number;
  leftMeasurement10: number;
  rightMeasurement10: number;

  leftSeno: number;
  rightSeno: number;
  distanzaSeni: number;
}

export interface AnamnesiProducts {
  id: number;
  recommendedProducts: RecommendedProduct[];
}
export interface RecommendedProduct {
  bodyZone: BodyZones;
  productId: number;
  productName: string;
  quantity: number;
  note: string;
}
export interface TreatmentsEvaluations {
  id: number;
  firstEvaluation: string;
  secondEvaluation: string;
  thirdEvaluation: string;
}

export interface AnamnesiTreatments {
  id: number;
  recommendedTreatments: RecommendedTreatment[];
}

export interface RecommendedTreatment {
  bodyZone: BodyZones;
  treatmentId: number;
  treatmentName: string;
  quantity: number;
  note: string;
  performed: boolean;
  date: Date;
  minutes: number;
  uuid: string;
}
export class GeneralOption {
  id: number;
  uuid: string;
  section: SectionType;
  label: string;
}

export interface GeneralOptionAnswer {
  id: number;
  tipoDiPelleId: number;
  bodyZone: BodyZones;
  inestetismoPresenteIds: number[];
  note: string;
}

export interface Question {
  id: number;
  uuid: string;
  label: string;
  type: QuestionType;
  required: boolean;
  questionOrder: number;
  withNote: boolean;
  noteLabel: string;
  questionOptions: QuestionOption[];
  questionAnswerId: number;
  value: string;
  note: string;
  optionAnswers: OptionAnswer[];
}

export enum SectionType {
  TIPO_DI_PELLE = 'TIPE_DI_PELLE',
  LEGENDA_INESTETISMI = 'LEGENDA_INESTETISMI',
  LEGENDA_DEGLI_INESTETIMI = 'LEGENDA_DEGLI_INESTETIMI'
}

export enum QuestionType {
  INPUT_TEXT = 'INPUT_TEXT',
  INPUT_NUMBER = 'INPUT_NUMBER',
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME',
  TIME = 'TIME',
  RADIO = 'RADIO',
  CHECKBOX = 'CHECKBOX',
  SELECT = 'SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  BOOLEAN = 'BOOLEAN'
}
export class QuestionOption {
  id: number;
  uuid: string;
  label: string;
}
export class OptionAnswer {
  id: number;
  value: string;
  optionId: number;
}

export enum BodyZones {
  FRONTE = 'FRONTE',
  PERIOCULARE = 'PERIOCULARE',
  ZIGOMI = 'ZIGOMI',
  NAZO_LABIALE$CODICE_A_BARRE = 'NAZO_LABIALE$CODICE_A_BARRE',
  OVALE_DEL_VISO = 'OVALE_DEL_VISO',
  DIPPIO_MENTO = 'DIPPIO_MENTO',
  COLLO = 'COLLO',
  DECOLLETE = 'DECOLLETE',


  // body front (left and right)
  BRACCIA_FL = 'BRACCIA_FL',
  BRACCIA_FR = 'BRACCIA_FR',
  SENO_FL = 'SENO_FL',
  SENO_FR = 'SENO_FR',
  VITA_ADDOME_FL = 'VITA_ADDOME_FL',
  VITA_ADDOME_FR = 'VITA_ADDOME_FR',
  FIANCHI_FL = 'FIANCHI_FL',
  FIANCHI_FR = 'FIANCHI_FR',
  BACINO_FL = 'BACINO_FL',
  BACINO_FR = 'BACINO_FR',
  COSCIA_FL= 'COSCIA_FL',
  COSCIA_FR = 'COSCIA_FD',
  GINOCCHIO_FL = 'GINOCCHIO_FL',
  GINOCCHIO_FR = 'GINOCCHIO_FR',
  POLPACCIO_FL = 'POLPACCIO_FL',
  POLPACCIO_FR = 'POLPACCIO_FR',
  CAVIGLIA_FL = 'CAVIGLIA_FL',
  CAVIGLIA_FR = 'CAVIGLIA_FR',

  // body back (left and right)
  TORACE_BL = 'TORACE_BL',
  TORACE_BR = 'TORACE_BR',
  FIANCHI_BL = 'FIANCHI_BL',
  FIANCHI_BR = 'FIANCHI_BR',
  GLUTEI_BL = 'GLUTEI_BL',
  GLUTEI_BR = 'GLUTEI_BR',
  COSCIA_BL = 'COSCIA_BL',
  COSCIA_BR = 'COSCIA_BR',
  POLPACCIO_BL = 'POLPACCIO_BL',
  POLPACCIO_BR = 'POLPACCIO_BR'
}

export const faceZonesArray = [
  {zone: BodyZones.FRONTE, value: 'FRONTE'},
  {zone: BodyZones.PERIOCULARE, value: 'PERIOCULARE'},
  {zone: BodyZones.ZIGOMI, value: 'ZIGOMI'},
  {zone: BodyZones.NAZO_LABIALE$CODICE_A_BARRE, value: 'NAZO LABIALE/CODICE A BARRE'},
  {zone: BodyZones.OVALE_DEL_VISO, value: 'OVALE DEL VISO'},
  {zone: BodyZones.DIPPIO_MENTO, value: 'DIPPIO MENTO'},
  {zone: BodyZones.COLLO, value: 'COLLO'},
  {zone: BodyZones.DECOLLETE, value: 'DECOLLETE'},
];
export const frontBodyZonesArray = [
  {zone: BodyZones.BRACCIA_FL, value: 'BRACCIA sx'},
  {zone: BodyZones.BRACCIA_FR, value: 'BRACCIA dx'},
  {zone: BodyZones.SENO_FL, value: 'SENO sx'},
  {zone: BodyZones.SENO_FR, value: 'SENO dx'},
  {zone: BodyZones.VITA_ADDOME_FL, value: 'VITA/ADDOME sx'},
  {zone: BodyZones.VITA_ADDOME_FR, value: 'VITA/ADDOME dx'},
  {zone: BodyZones.FIANCHI_FL, value: 'FIANCHI fronte sx'},
  {zone: BodyZones.FIANCHI_FR, value: 'FIANCHI fronte dx'},
  {zone: BodyZones.BACINO_FL, value: 'BACINO sx'},
  {zone: BodyZones.BACINO_FR, value: 'BACINO dx'},
  {zone: BodyZones.COSCIA_FL, value: 'COSCIA fronte sx'},
  {zone: BodyZones.COSCIA_FR, value: 'COSCIA fronte dx'},
  {zone: BodyZones.GINOCCHIO_FL, value: 'GINOCCHIO sx'},
  {zone: BodyZones.GINOCCHIO_FR, value: 'GINOCCHIO dx'},
  {zone: BodyZones.POLPACCIO_FL, value: 'POLPACCIO fronte sx'},
  {zone: BodyZones.POLPACCIO_FR, value: 'POLPACCIO fronte dx'},
  {zone: BodyZones.CAVIGLIA_FL, value: 'CAVIGLIA sx'},
  {zone: BodyZones.CAVIGLIA_FR, value: 'CAVIGLIA dx'},
];
export const backBodyZonesArray = [
  {zone: BodyZones.TORACE_BL, value: 'TORACE sx'},
  {zone: BodyZones.TORACE_BR, value: 'TORACE dx'},
  {zone: BodyZones.FIANCHI_BL, value: 'FIANCHI retro sx'},
  {zone: BodyZones.FIANCHI_BR, value: 'FIANCHI retro dx'},
  {zone: BodyZones.GLUTEI_BL, value: 'GLUTEI sx'},
  {zone: BodyZones.GLUTEI_BR, value: 'GLUTEI dx'},
  {zone: BodyZones.COSCIA_BL, value: 'COSCIA retro sx'},
  {zone: BodyZones.COSCIA_BR, value: 'COSCIA retro dx'},
  {zone: BodyZones.POLPACCIO_BL, value: 'POLPACCIO retro sx'},
  {zone: BodyZones.POLPACCIO_BR, value: 'POLPACCIO retro dx'},
];

export const frontBodyZones = [
  'BRACCIA',
  'SENO',
  'VITA/ADDOME',
  'FIANCHI',
  'BACINO',
  'COSCIA',
  'GINOCCHIO',
  'POLPACCIO',
  'CAVIGLIA'
];
export const backBodyZones = [
  'TORACE',
  'FIANCHI',
  'GLUTEI',
  'COSCIA',
  'POLPACCIO',
];

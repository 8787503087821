export enum SequenceType {
  CLIENT,
  COMPANY,
  PROVIDER,
  OPERATOR,
  PRODUCT,
  TREATMENT,
  CABIN,
  PLANNING
}


import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {SearchResponse} from '../dto/search-response';
import {UpdateVersion, UpdateVersionPageRequest} from '../models/update-version';

@Injectable({
  providedIn: 'root'
})
export class UpdateVersionService {

  private PRIVATE_API = environment.api + '/update-versions';

  constructor(
    private http: HttpClient
  ) {
  }

  getLazyUpdateVersions(request: UpdateVersionPageRequest) {
    return this.http.post<SearchResponse<UpdateVersion>>(`${this.PRIVATE_API}/filter`, request);
  }

  getUpdateVersionDetails(updateVersionId: number) {
    return this.http.get<UpdateVersion>(`${this.PRIVATE_API}/${updateVersionId}`);
  }

  addUpdateVersion(updateVersion: UpdateVersion) {
    return this.http.post<UpdateVersion>(`${this.PRIVATE_API}`, updateVersion);
  }

  updateUpdateVersion(updateVersion: UpdateVersion) {
    return this.http.put<UpdateVersion>(`${this.PRIVATE_API}/${updateVersion.id}`, updateVersion);
  }

  deleteUpdateVersion(updateVersionId: number) {
    return this.http.delete(`${this.PRIVATE_API}/${updateVersionId}`, {observe: 'response'});
  }

  // show or hide  Cosa c'è di nuovo content for client
  changeUpdateVersionState(id: number) {
    return this.http.get(`${this.PRIVATE_API}/update-version-state/${id}`);
  }

}

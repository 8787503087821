import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Booking, PlanningBookingElement} from '../models/booking.model';
import {SearchResponse} from '../dto/search-response';

@Injectable()
export class BookingService {
  private readonly API = environment.api + '/booking';

  constructor(private http: HttpClient) {
  }

  addBooking(request) {
    return this.http.post<number>(`${this.API}`, request);
  }

  getBookings(request) {
    return this.http.post<PlanningBookingElement[]>(`${this.API}/planing-elements`, request);
  }

  getBookingByOperator(request) {
    return this.http.post<PlanningBookingElement[]>(`${this.API}/operator-planing-elements`, request);
  }
  getBookingByCabin(request) {
    return this.http.post<PlanningBookingElement[]>(`${this.API}/cabin-planing-elements`, request);
  }
  getBookingsBetweenDates(request) {
    return this.http.post<PlanningBookingElement[]>(`${this.API}/get-booking-between-dates`, request);
  }

  getBookingsByWeek(request: { dates: string[], planningType: 'OPERATOR' | 'CABIN', ids: number[]}) {
    return this.http.post<PlanningBookingElement[][]>(`${this.API}/planing-elements-of-week`, request);
  }

  getBookingsByWeekByDay(request: { date: string, planningType: 'OPERATOR' | 'CABIN', ids: number[]}) {
    return this.http.post<PlanningBookingElement[]>(`${this.API}/planing-elements-of-week-by-day`, request);
  }

  getBookingById(idBooking: number) {
    return this.http.get<Booking>(`${this.API}/${idBooking}`);
  }

  deleteBooking(idBooking: number) {
    return this.http.delete<any>(`${this.API}/${idBooking}`);
  }

  move(request) {
    return this.http.post<any>(`${this.API}/move`, request);
  }

  paste(request: { clientId: any; targetId?: number; targetDate: string; ids: number[]; row: number; type?: 'OPERATOR' | 'CABIN' }) {
    return this.http.post<any>(`${this.API}/paste`, request);
  }

  getBookingsOfToday() {
    return this.http.get<number>(`${this.API}/total`);
  }

  getApproxTotalBookings() {
    return this.http.get<number>(`${this.API}/total-price-booking`);
  }


  downloadOperatorCabinPlanning(request) {
    return this.http.post(`${this.API}/download-planning`, request, {responseType: 'arraybuffer'});
  }

  getSellPointClosingAndOpeningHours(date) {
    return this.http.post<{ opening, closing }>(`${this.API}/opening-closing-time`, `"${date}"`, {
      headers: {
        'content-type': 'application/json'
      }
    });
  }

  getSellPointClosingAndOpeningHoursForWeek(dates) {
    console.log({dates});
    return this.http.post<{ opening, closing }>(`${this.API}/opening-closing-time-week`, dates, {
      headers: {
        'content-type': 'application/json'
      }
    });
  }

  checkBookingBeforeAdd(request) {
    return this.http.post<CheckBookingResponse[]>(`${this.API}/check-planning-elements`, request);
  }

  clear(id) {
    return this.http.get(`${this.API}/clear/${id}`);
  }

  search(request) {
    return this.http.post<SearchResponse<PlanningBookingElement>>(`${this.API}/search`, request);
  }

  getBookingLog(bookingId) {
    return this.http.get<{createdAt, updatedAt, cancelledAt, createdBy}>(`${this.API}/booking-log/${bookingId}`);
  }

  moveInWeek(param: { row: number; bookingId: number; day: string, week: string[] }) {
    return this.http.post<any>(`${this.API}/move-in-week`, param);
  }

  pasteInWeek(request: { clientId: any; week: string[]; targetDate: string; ids: any; row: number; bookingId: number }) {
    return this.http.post<void>(`${this.API}/paste-in-week`, request);
  }

  checkBeforeMove(request) {
    return this.http.post<{cabinName: string, operatorName: string}>(`${this.API}/check-before-move`, request);
  }

  getWeekDaysOff(dates: string[]) {
    return this.http.post<boolean[]>(`${this.API}/week-days-off`, dates);
  }

  setBookingPayed(bookingId: number) {
    return this.http.get<boolean>(`${this.API}/set-payed/${bookingId}`);
  }

  getBookingPayed(bookingId: number) {
    return this.http.get<boolean>(`${this.API}/get-payed/${bookingId}`);
  }


  updateCoreff(request: { dates: string[], planningType: 'OPERATOR' | 'CABIN', ids: number[]}) {
    return this.http.post(`${this.API}/update-coeff`, request);
  }

  sendCreateBookingNotification(id) {
    return this.http.get<number>(`${this.API}/send-create-booking-notification/${id}`);
  }

  sendUpdateBookingNotification(id) {
    return this.http.get<number>(`${this.API}/send-update-booking-notification/${id}`);
  }

}

export enum CheckType {
  BEFORE_OPENING = 'BEFORE_OPENING',
  AFTER_CLOSING = 'AFTER_CLOSING',
  AFTER_CLOSING_OPERATOR = 'AFTER_CLOSING_OPERATOR',
  AFTER_CLOSING_CABIN = 'AFTER_CLOSING_CABIN',
  OPERATOR_BREAK = 'OPERATOR_BREAK',
  BEFORE_OPERATOR = 'BEFORE_OPERATOR',
  AFTER_OPERATOR = 'AFTER_OPERATOR',
  CABIN_BREAK = 'CABIN_BREAK',
  BEFORE_CABIN = 'BEFORE_CABIN',
  AFTER_CABIN = 'AFTER_CABIN',
  DAY_OFF = 'DAY_OFF',
  OPERATOR_OVERLAP = 'OPERATOR_OVERLAP',
  CABIN_OVERLAP = 'CABIN_OVERLAP'
}

export interface CheckBookingResponse {
  checkType: CheckType;
  label: string;
  who: 'CABIN' | 'OPERATOR';
  hasWarning: boolean;
  treatmentName: string;
}
